import { LabelledTextArea } from '../labelled-text-area';
import { Control, Controller } from 'react-hook-form';
import { BroadcastFormInputData } from './use-broadcast-form';
import { useTranslate } from 'util/i18n';
import { MESSAGE_MAX_CHARS } from './constants';

interface BroadcastMessageFieldProps {
  control: Control<BroadcastFormInputData>;
}

export const BroadcastMessageField = ({ control }: BroadcastMessageFieldProps) => {
  const { t } = useTranslate('broadcastForm.message');

  return (
    <Controller
      control={control}
      name="message"
      render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
        <LabelledTextArea
          name={name}
          label={t('label')}
          size="lg"
          placeholder={t('placeholder') ?? ''}
          onChange={onChange}
          onBlur={onBlur}
          errorMessage={error?.message}
          maxCharacters={MESSAGE_MAX_CHARS}
          defaultValue={value}
          rows={5}
        />
      )}
    />
  );
};
