import { Label, Dropdown, Button, Modal, Text, TextField } from '@goosechase/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslate } from 'util/i18n';
import { z } from 'zod';

export interface WorkspaceInvitationData {
  roleId: string;
  email: string;
}

interface WorkspaceInviteModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: (params: WorkspaceInvitationData) => void;
}

const defaultValues = {
  email: '',
  roleId: 'member',
};

export const WorkspaceInviteModal = ({ show, onClose, onSubmit }: WorkspaceInviteModalProps) => {
  const { t } = useTranslate('pages.workspace.tabs.members');

  const { handleSubmit, control, formState, reset } = useForm<WorkspaceInvitationData>({
    mode: 'onBlur',
    defaultValues,
    resolver: zodResolver(
      z.object({
        email: z
          .string({ required_error: t('inviteModal.fields.email.errors.invalid') ?? 'Required' })
          .email({ message: t('inviteModal.fields.email.errors.invalid') ?? 'Invalid email' }),
        roleId: z.enum(['admin', 'member']),
      }),
    ),
  });

  const options = [
    { id: 'admin', label: t('roles.admin') },
    { id: 'member', label: t('roles.member') },
  ];

  const customRenderOption = (option: { id: string; label: string }) => (
    <Text className="p-3">{t(`roles.${option.id}`)}</Text>
  );

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isSubmitSuccessful]);

  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[592px] overflow-visible"
      show={show}
      onClose={onClose}>
      <Modal.Header title={t('inviteModal.header') ?? ''} />
      <Modal.Body className="px-4 py-5">
        <Text className="mb-5">{t('inviteModal.body')}</Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Label className="block uppercase mb-2" size="lg">
            {t('inviteModal.fields.email.label')}
          </Label>
          <div className="flex gap-5 flex-col tablet-narrow:flex-row">
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => (
                <TextField
                  placeholder={t('inviteModal.fields.email.placeholder') ?? ''}
                  type="text"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  autoComplete="email"
                  disabled={formState.isSubmitting}
                  className="flex-1"
                  responseState={fieldState.error ? 'error' : undefined}
                  value={field.value}
                />
              )}
            />
            <Controller
              name="roleId"
              control={control}
              render={({ field }) => {
                return (
                  <Dropdown
                    label={t(`roles.${field.value}`)}
                    onSelect={field.onChange}
                    options={options}
                    customRenderOption={customRenderOption}
                    disabled={formState.isSubmitting}
                    selectedOptionId={field.value}
                    buttonClassName="w-full tablet-narrow:w-[133px]"
                  />
                );
              }}
            />
            <Button label={t('inviteModal.send')} size="lg" />
          </div>
          {formState.errors.email ? (
            <Text className="mt-1 text-vibrantRed" size="sm">
              {formState.errors.email.message}
            </Text>
          ) : null}
        </form>
      </Modal.Body>
    </Modal>
  );
};
