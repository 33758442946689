import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Text } from '@goosechase/ui';

import { Card } from '../../components/card.component';
import { ErrorState } from '../../components/error-state';
import { LoadingSpinner } from '../../components/loading-spinner';
import { PageHeadline } from '../../components/page-headline.component';
import { setActiveTeamId, useTeamSubmissionsQuery } from '../../data/submissions';
import { useTranslate } from '../../util/i18n';
import { SubmissionBreadcrumbs } from '../../components/submission-breadcrumbs';
import { PointsPill } from '../../components/points-pill';
import { SubmissionGrid } from '../../components/submission-grid';
import { convertGqlTeamSubmissionToUITeamSubmission } from './util';
import { SubmissionFocusedViewModal } from '../../components/submission-focused-view-modal';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { useSubmissionFocusedViewModal } from 'hooks/use-submission-focused-view-modal-hook';

export const TeamSubmissionsPage = () => {
  const { teamId } = useParams();
  const { experienceId } = useExperienceInfo();
  const dispatch = useDispatch();
  const { t } = useTranslate('pages.teamSubmissions');
  const { data, isLoading, isUninitialized, isError } = useTeamSubmissionsQuery(
    { teamId: teamId ?? '' },
    { skip: teamId === undefined },
  );
  const {
    submissionId,
    bonusFocus,
    setBonusFocus,
    handleSubmissionClick,
    handleBonusClick,
    setFocusedSubmissionId,
  } = useSubmissionFocusedViewModal();

  useEffect(() => {
    dispatch(setActiveTeamId(teamId ?? null));

    return () => {
      // Clears the activeTeamId when navigating away from this page
      dispatch(setActiveTeamId(null));
    };
    // Only want to run when teamId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const team = data?.team;

  if (isLoading || isUninitialized) {
    return (
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (isError || team == null) {
    return (
      <Card>
        <div className="flex justify-center py-16 px-8">
          <ErrorState />
        </div>
      </Card>
    );
  }

  const submissions = team.submissions.map(
    convertGqlTeamSubmissionToUITeamSubmission({
      teamId: team.id,
      teamName: team.displayName,
      teamImageUrl: team.photoUrl ?? undefined,
      teamMode: team.mode,
    }),
  );

  const currentFocusedSubmission = submissions.find(
    (submission) => submission.submissionId === submissionId,
  );

  return (
    <>
      <div className="mb-6">
        <div className="flex flex-col gap-6">
          <SubmissionBreadcrumbs
            experienceId={experienceId}
            activeBreadcrumbLabel={team.displayName}
          />
          <div className="flex justify-between">
            <div className="flex flex-col gap-2">
              <PageHeadline>{team.displayName}</PageHeadline>
              <div className="flex flex-row gap-3">
                <PointsPill className="bg-black-12" points={team.points} />
                <Text size="md">{t('numSubmissions', { count: team.numSubmissions })}</Text>
              </div>
            </div>
            {/* TODO: Add Download button - https://app.asana.com/0/0/1205549485090264/f */}
          </div>
        </div>
      </div>
      <SubmissionGrid
        experienceId={experienceId}
        submissions={submissions}
        onSubmissionClick={handleSubmissionClick}
        onSubmissionBonus={handleBonusClick}
      />
      <SubmissionFocusedViewModal
        experienceId={experienceId}
        setCurrentFocusedSubmissionId={setFocusedSubmissionId}
        currentFocusedSubmission={currentFocusedSubmission}
        submissions={submissions}
        autoFocusBonus={bonusFocus}
        setAutoFocusBonus={setBonusFocus}
      />
    </>
  );
};
