import { api as _leaderboardApi } from './graphql/__generated__/leaderboard.query.generated';
export type { LeaderboardQuery } from './graphql/__generated__/leaderboard.query.generated';

export const leaderboardApi = _leaderboardApi.enhanceEndpoints({
  endpoints: {
    Leaderboard: {
      providesTags: ['Leaderboard'],
    },
  },
});
