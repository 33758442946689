import { useActiveCohortId, useLoadStatisticsQuery } from '../../data/experiences';
import { useTranslate } from '../../util/i18n';
import { LoadingSpinner } from '../../components/loading-spinner';
import { ErrorState } from 'components/error-state';
import { StatisticsCard } from './statistics-card.component';
import { StatisticsBarChartCard } from './statistics-bar-chart-card.component';
import { useMemo } from 'react';
import { SubmissionPopularityBarChartCard } from './submission-popularity-bar-chart-card.component';
import { Card } from '../card.component';

export const MainStatistics = () => {
  const activeCohortId = useActiveCohortId();
  const { data, isLoading, isError } = useLoadStatisticsQuery(
    { id: activeCohortId || '' },
    { skip: !activeCohortId },
  );
  const statistics = data?.cohort?.statistics ?? undefined;
  const { t } = useTranslate('pages.stats');

  const missionPopularityData = useMemo(() => {
    return data?.submissionCountByMission
      ? data.submissionCountByMission.map((val) => {
          return {
            id: val.id,
            name: val.displayName,
            dataValue: Math.round(val.submissionCount),
          };
        })
      : [];
  }, [data?.submissionCountByMission]);

  const teamEngagementData = useMemo(() => {
    return data?.submissionCountByTeam
      ? data.submissionCountByTeam.map((val) => {
          return {
            id: val.team.id,
            name: val.team.displayName,
            dataValue: val.submissionCount,
          };
        })
      : [];
  }, [data?.submissionCountByTeam]);

  const submissionPopularityData = useMemo(() => {
    return data?.likeCountBySubmission
      ? data.likeCountBySubmission.map((val) => {
          return {
            id: val.submission.id,
            missionName: val.missionName,
            teamName: val.teamName,
            likeCount: val.likeCount,
          };
        })
      : [];
  }, [data?.likeCountBySubmission]);

  if (isError) {
    return (
      <div className="flex justify-center py-16 px-8">
        <ErrorState />
      </div>
    );
  }

  if (isLoading) {
    return (
      <Card className="flex items-center justify-center p-10 col-span-4 space-y-2">
        <LoadingSpinner />
      </Card>
    );
  }

  return (
    <>
      <StatisticsCard
        stat={statistics?.activeTeams?.toString() ?? ''}
        label={t('activeTeams.label')}
        help={t('activeTeams.help')}
      />
      <StatisticsCard
        stat={statistics?.totalTeams?.toString() ?? ''}
        label={t('totalTeams.label')}
        help={t('totalTeams.help')}
      />
      <StatisticsCard
        stat={statistics?.totalSubmissions?.toString() ?? ''}
        label={t('totalSubmissions.label')}
        help={t('totalSubmissions.help')}
      />
      <StatisticsCard
        stat={statistics?.averageMissionCompletion ?? '' + '%' ?? ''}
        label={t('averageMissionCompletion.label')}
        help={t('averageMissionCompletion.help')}
      />
      <StatisticsBarChartCard
        data={missionPopularityData}
        headers={['ID', 'Mission Name', 'Submissions']}
        fileName={t('submissionCountByMission.fileName')}
        label={t('submissionCountByMission.label')}
        xAxisLabel={t('submissionCountByMission.xAxisLabel')}
        yAxisLabel={t('submissionCountByMission.yAxisLabel')}
        ariaLabel={t('submissionCountByMission.ariaLabel')}
        tooltip={true}
      />
      <StatisticsBarChartCard
        data={teamEngagementData}
        headers={['ID', 'Team Name', 'Submissions']}
        fileName={t('submissionCountByTeam.fileName')}
        label={t('submissionCountByTeam.label')}
        xAxisLabel={t('submissionCountByTeam.xAxisLabel')}
        yAxisLabel={t('submissionCountByTeam.yAxisLabel')}
        ariaLabel={t('submissionCountByTeam.ariaLabel')}
        tooltip={true}
      />
      <SubmissionPopularityBarChartCard
        data={submissionPopularityData}
        headers={['ID', 'Mission Name', 'Team Name', 'Likes']}
        fileName={t('submissionPopularity.fileName')}
        label={t('submissionPopularity.label')}
        xAxisLabel={t('submissionPopularity.xAxisLabel')}
        yAxisLabel={t('submissionPopularity.yAxisLabel')}
        ariaLabel={t('submissionPopularity.ariaLabel')}
      />
    </>
  );
};
