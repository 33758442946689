import {
  FeedVisibility,
  MediaType,
  MissionTriggerTiming,
  MissionType,
  SubmissionSource,
  TriggerTimeAnchorV1,
  TriggerTimeUnit,
} from '../../data/models';

export const DISPLAY_NAME_MAX_CHARS = 100;
export const DESCRIPTION_MAX_CHARS = 2000;

export const BASE_MISSION_DEFAULTS = {
  ID: '',
  DISPLAY_NAME: '',
  DESCRIPTION: '',
  POINTS: '',
  IMAGE: '',
  LINK: '',
  DRAFT: false,
  MISSION_TRIGGER: {
    TIMING: 'DEFAULT' as MissionTriggerTiming,
    TIME_UNIT: TriggerTimeUnit.Hours,
    ANCHOR: TriggerTimeAnchorV1.AfterStart,
  },
};

export const CAMERA_MISSION_DEFAULTS = {
  FEED_VISIBILITY: 'ALWAYS_PUBLIC' as FeedVisibility,
  ALLOW_CAMERA_ROLL_UPLOADS: 'LIVE_AND_LIBRARY' as SubmissionSource,
  MEDIA_TYPE: 'PHOTO_AND_VIDEO' as MediaType,
};

export const TEXT_MISSION_DEFAULTS = {
  FEED_VISIBILITY: 'ALWAYS_PRIVATE' as FeedVisibility,
  ANSWERS: [],
  ALLOW_APPROXIMATE_ANSWERS: false,
};

export const GPS_MISSION_DEFAULTS = {
  FEED_VISIBILITY: 'ALWAYS_PRIVATE' as FeedVisibility,
  LOCATION_NAME: '',
  LATITUDE: '',
  LONGITUDE: '',
  RADIUS: 100,
};

export const DEFAULT_FEED_VISIBILITY: Record<MissionType, FeedVisibility> = {
  CAMERA: CAMERA_MISSION_DEFAULTS.FEED_VISIBILITY,
  TEXT: TEXT_MISSION_DEFAULTS.FEED_VISIBILITY,
  GPS: GPS_MISSION_DEFAULTS.FEED_VISIBILITY,
};
