import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from 'types/util';

type SelectedWorkspace = { displayName: string; photoUrl: Nullable<string> };

export interface WorkspacesState {
  acceptedWorkspaceInvitationModalOpen: boolean;
  selectedWorkspace: Nullable<SelectedWorkspace>;
}

const initialState: WorkspacesState = {
  acceptedWorkspaceInvitationModalOpen: false,
  selectedWorkspace: null,
};

export const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    toggleAcceptedWorkspaceInvitationModal: (state) => {
      state.acceptedWorkspaceInvitationModalOpen = !state.acceptedWorkspaceInvitationModalOpen;
    },
    setSelectedWorkspace: (state, payload: PayloadAction<Nullable<SelectedWorkspace>>) => {
      state.selectedWorkspace = payload.payload;
    },
  },
});
