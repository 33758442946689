import { experiencesSlice, type ExperienceListFilter } from './experiences.slice';
import {
  type MyExperiencesQuery,
  myExperiencesApi,
  type ExperienceFormDetailsQuery,
  experienceFormDetailsApi,
  updateExperienceApi,
  createExperienceApi,
  deleteExperienceApi,
  duplicateExperienceApi,
  type LoadExperienceAddonDetailsQuery,
  loadExperienceAddonDetailsApi,
  experienceInfoApi,
  endCohortApi,
  startCohortApi,
  type ExperienceInfoQuery,
  experienceStartAndEndApi,
  type LoadExperienceStartAndEndQuery,
  scheduleExperienceApi,
  resetExperienceApi,
  updateCohortEndApi,
  workspaceExperiencesApi,
  experienceFormBrandingApi,
  experienceManagersApi,
  inviteExperienceManagerApi,
  deleteExperienceInvitationApi,
  deleteExperienceManagerApi,
  pendingExperienceInvitationsApi,
  resendExperienceInvitationApi,
  experienceInvitationApi,
  acceptExperienceInvitationApi,
  leaveExperienceAsManagerApi,
  statisticsApi,
  shareExperienceApi,
  unshareExperienceApi,
  sharedExperienceApi,
  copySharedExperienceApi,
  submissionInfoStatsPaginatedApi,
  submissionInfoStatsApi,
  type ExperienceManagersQuery,
  myPersonalExperiencesApi,
  mySharedExperiencesApi,
  myWorkspaceExperiencesApi,
  type ExperienceFragmentFragment,
} from './experiences.api';

export const { useMyExperiencesQuery } = myExperiencesApi;
export const { useExperienceFormDetailsQuery } = experienceFormDetailsApi;
export const { useUpdateExperienceMutation } = updateExperienceApi;
export const { useCreateExperienceMutation } = createExperienceApi;
export const { useDeleteExperienceMutation } = deleteExperienceApi;
export const { useDuplicateExperienceMutation } = duplicateExperienceApi;
export const { useLoadExperienceAddonDetailsQuery } = loadExperienceAddonDetailsApi;
export const { useExperienceInfoQuery } = experienceInfoApi;
export const { useStartCohortMutation } = startCohortApi;
export const { useEndCohortMutation } = endCohortApi;
export const { useLoadExperienceStartAndEndQuery } = experienceStartAndEndApi;
export const { useScheduleExperienceMutation } = scheduleExperienceApi;
export const { useResetExperienceMutation } = resetExperienceApi;
export const { useUpdateCohortEndMutation } = updateCohortEndApi;
export const { useWorkspaceExperiencesQuery } = workspaceExperiencesApi;
export const { useExperienceFormBrandingQuery } = experienceFormBrandingApi;
export const { useExperienceManagersQuery } = experienceManagersApi;
export const { useInviteExperienceManagerMutation } = inviteExperienceManagerApi;
export const { usePendingExperienceInvitationsQuery } = pendingExperienceInvitationsApi;
export const { useDeleteExperienceInvitationMutation } = deleteExperienceInvitationApi;
export const { useDeleteExperienceManagerMutation } = deleteExperienceManagerApi;
export const { useResendExperienceInvitationMutation } = resendExperienceInvitationApi;
export const { useExperienceInvitationQuery } = experienceInvitationApi;
export const { useAcceptExperienceInvitationMutation } = acceptExperienceInvitationApi;
export const { useLeaveExperienceAsManagerMutation } = leaveExperienceAsManagerApi;
export const { useLoadStatisticsQuery } = statisticsApi;
export const { useShareExperienceMutation } = shareExperienceApi;
export const { useUnshareExperienceMutation } = unshareExperienceApi;
export const { useSharedExperienceQuery } = sharedExperienceApi;
export const { useCopySharedExperienceMutation } = copySharedExperienceApi;
export const { useSubmissionInfoStatisticsPaginatedQuery } = submissionInfoStatsPaginatedApi;
export const { useLazySubmissionInfoStatisticsQuery } = submissionInfoStatsApi;
export const { useMyPersonalExperiencesQuery } = myPersonalExperiencesApi;
export const { useMySharedExperiencesQuery } = mySharedExperiencesApi;
export const { useMyWorkspaceExperiencesQuery } = myWorkspaceExperiencesApi;

export type {
  MyExperiencesQuery,
  ExperienceFormDetailsQuery,
  ExperienceInfoQuery,
  LoadExperienceStartAndEndQuery,
  ExperienceManagersQuery,
  LoadExperienceAddonDetailsQuery,
  ExperienceListFilter,
  ExperienceFragmentFragment,
};

export const {
  setActiveExperienceId,
  setActiveCohortId,
  toggleUpgradeExperienceModal,
  setSelectedExperience,
  toggleAcceptedExperienceInvitationModal,
  toggleCopyExperienceModal,
  setExperienceListFilter,
} = experiencesSlice.actions;

export const experiencesReducer = experiencesSlice.reducer;

export * from './experiences.hooks';
