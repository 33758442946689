/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type SubmissionsOverviewQueryVariables = Types.Exact<{
  experienceId: Types.Scalars['ID']['input'];
  cohortId: Types.Scalars['ID']['input'];
}>;


export type SubmissionsOverviewQuery = { __typename: 'Query', experience: { __typename: 'Experience', numMissions: number } | null, experienceMissions: Array<{ __typename: 'CameraMission', id: string, displayName: string, points: number, numSubmissions: number, submissions: Array<{ __typename: 'CameraSubmission', caption: string | null, id: string, assets: Array<{ __typename: 'SubmissionAsset', id: string, url: string, role: Types.SubmissionAssetRole }> } | { __typename: 'GpsSubmission', id: string } | { __typename: 'TextSubmission', id: string }> } | { __typename: 'GpsMission', id: string, displayName: string, points: number, numSubmissions: number } | { __typename: 'TextMission', id: string, displayName: string, points: number, numSubmissions: number }>, cohort: { __typename: 'Cohort', id: string, numSubmissions: number, teams: Array<{ __typename: 'Team', id: string, displayName: string, numSubmissions: number, photoUrl: string | null, points: number, bonusPoints: number, mode: Types.TeamMode }>, preview: { __typename: 'CohortPreview', numberOfTeams: number } } | null };


export const SubmissionsOverviewDocument = `
    query SubmissionsOverview($experienceId: ID!, $cohortId: ID!) {
  experience(id: $experienceId) {
    numMissions
  }
  experienceMissions(id: $experienceId) {
    __typename
    id
    displayName
    points
    ... on CameraMission {
      submissions(cohortId: $cohortId, first: 1) {
        __typename
        id
        ... on CameraSubmission {
          caption
          assets {
            id
            url
            role
          }
        }
      }
    }
    numSubmissions(cohortId: $cohortId)
  }
  cohort(id: $cohortId) {
    id
    numSubmissions
    teams {
      id
      displayName
      numSubmissions
      photoUrl
      points
      bonusPoints
      mode
    }
    preview {
      numberOfTeams
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    SubmissionsOverview: build.query<SubmissionsOverviewQuery, SubmissionsOverviewQueryVariables>({
      query: (variables) => ({ document: SubmissionsOverviewDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSubmissionsOverviewQuery, useLazySubmissionsOverviewQuery } = injectedRtkApi;

