var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { mergeClasses } from '../util/merge-classes';
import { TextInput } from '../text-input';
import { Icon } from '../icon';
import { getTestIDProp } from '../util/test-id';
import { getResponseStateBorderClassname, TextContainer, } from '../util/field-styling';
var RightIcon = function (_a) {
    var isSuccess = _a.isSuccess, iconName = _a.iconName;
    if (isSuccess) {
        return _jsx(Icon, { color: "vibrantGreen", name: "Checkmark", className: "ml-2.5" });
    }
    else if (iconName) {
        return _jsx(Icon, { name: iconName, className: "ml-2.5" });
    }
    return null;
};
export var TextField = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, className = _a.className, inputClasses = _a.inputClasses, responseState = _a.responseState, _c = _a.rounded, rounded = _c === void 0 ? false : _c, leftIcon = _a.leftIcon, rightIcon = _a.rightIcon, testID = _a.testID, inputRef = _a.inputRef, autoFocusInput = _a.autoFocusInput, htmlProps = __rest(_a, ["disabled", "className", "inputClasses", "responseState", "rounded", "leftIcon", "rightIcon", "testID", "inputRef", "autoFocusInput"]);
    return (_jsxs(TextContainer, __assign({ className: mergeClasses('p-4', className, getResponseStateBorderClassname(responseState)), disabled: disabled, rounded: rounded }, { children: [leftIcon && _jsx(Icon, { name: leftIcon, className: "mr-2.5" }), _jsx(TextInput, __assign({ disabled: disabled, className: inputClasses, ref: inputRef, autoFocusInput: autoFocusInput }, htmlProps, getTestIDProp(testID, 'input'))), _jsx(RightIcon, { isSuccess: responseState === 'success', iconName: rightIcon })] })));
};
