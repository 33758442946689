import { mergeClasses } from '@goosechase/ui';

import { ReactComponent as GooseForgotPassword } from 'media/goose-forgot-password.svg';
import { PANEL_CLASSNAME } from 'components/account-form-layout.component';

export const ForgotPasswordPanel = () => (
  <div className={mergeClasses(PANEL_CLASSNAME, 'p-24 bg-paleGreen')}>
    <div className="flex w-full">
      <GooseForgotPassword className="flex grow max-w-[332px] fill-black" />
    </div>
  </div>
);
