import { useState } from 'react';

export const useSubmissionFocusedViewModal = () => {
  const [submissionId, setSubmissionId] = useState<string | undefined>(undefined);
  const [bonusFocus, setBonusFocus] = useState(false);

  const setFocusedSubmissionId = (id?: string) => {
    setSubmissionId(id);
  };

  const handleSubmissionClick = (id: string) => {
    setFocusedSubmissionId(id);
  };

  const handleBonusClick = (id: string) => {
    setFocusedSubmissionId(id);
    setBonusFocus(true);
  };

  return {
    submissionId,
    bonusFocus,
    setBonusFocus,
    handleSubmissionClick,
    handleBonusClick,
    setFocusedSubmissionId,
  };
};
