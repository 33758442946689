import { useTranslate } from '../../util/i18n';
import { type SyntheticEvent } from 'react';
import { RadioBlockSelector } from 'components/radio-block-selector/radio-block-selector.component';

export type TeamModeInputProps = {
  value: string;
  name: string;
  onChange: (e: SyntheticEvent) => void;
  shouldDisableIndividualMode?: boolean;
};

export const TeamModeInput = ({
  value,
  name,
  onChange,
  shouldDisableIndividualMode,
}: TeamModeInputProps) => {
  const { t } = useTranslate('pages.participants.teamForm');

  const options = [
    {
      name: 'team',
      value: 'TEAM',
      optionLabel: t('mode.options.team.label'),
      description: t('mode.options.team.description'),
    },
    {
      name: 'solo',
      value: 'SOLO',
      optionLabel: t('mode.options.solo.label'),
      description: shouldDisableIndividualMode
        ? t('mode.options.solo.disabledDescription')
        : t('mode.options.solo.description'),
      disabled: shouldDisableIndividualMode,
    },
  ];

  return (
    <RadioBlockSelector
      options={options}
      name={name}
      label={t('mode.label') ?? undefined}
      onSelect={onChange}
      selectedValue={value}
    />
  );
};
