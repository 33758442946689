import { Avatar, Headline, Text, Button } from '@goosechase/ui';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { LoadingSpinner } from '../../components/loading-spinner';
import { Card } from '../../components/card.component';
import {
  setSelectedWorkspace,
  toggleAcceptedWorkspaceInvitationModal,
  useAcceptWorkspaceInvitationMutation,
  useWorkspaceInvitationQuery,
} from 'data/workspaces';
import { AppRoutes } from 'constants/routes';
import { Trans } from 'react-i18next';
import { useLoadMyUserQuery } from 'data/auth';
import { displayToast } from 'services/toast';
import { useAppDispatch } from 'data/hooks';

const NotFound = () => {
  const { t } = useTranslate('pages.joinWorkspace.notFound');
  return (
    <Card className="p-6 flex gap-4 flex-col items-center text-center max-w-[664px]">
      <Avatar placeholder="workspace" alt="Workspace" size="xl2" squared />
      <Headline type="primary" size="sm">
        {t('headline')}
      </Headline>
      <Text>{t('body')}</Text>
    </Card>
  );
};

const InviteDoesNotMatch = (props: {
  workspaceInvitation: {
    email: string;
    workspace: { displayName: string; photoUrl: string | null };
  };
}) => {
  const { t } = useTranslate('pages.joinWorkspace.inviteDoesNotMatch');

  return (
    <Card className="p-6 flex gap-6 flex-col items-center text-center max-w-[664px]">
      <Avatar
        placeholder="workspace"
        alt="Workspace"
        squared
        size="xl2"
        src={props.workspaceInvitation.workspace.photoUrl ?? undefined}
      />
      <Headline type="primary" size="sm">
        {t('headline')}
      </Headline>
      <Text>
        <Trans
          t={t}
          i18nKey="body"
          values={{
            email: props.workspaceInvitation.email,
          }}
        />
      </Text>
      <a href={AppRoutes.LOGOUT}>
        <Button label={t('logOut')} />
      </a>
    </Card>
  );
};

export const JoinWorkspacePage = () => {
  const { t } = useTranslate('pages.joinWorkspace');
  const { invitationId } = useParams();
  const { data, isLoading, isError } = useWorkspaceInvitationQuery(
    { invitationId: invitationId ?? '' },
    { skip: !invitationId },
  );
  const { data: userData } = useLoadMyUserQuery();
  const location = useLocation();
  const [acceptWorkspaceInvitation] = useAcceptWorkspaceInvitationMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  if (isLoading) {
    return (
      <div className="flex grow justify-center items-center p-4">
        <Card className="p-6 flex gap-4 flex-col items-center justify-center text-center max-w-[664px] h-[364px] w-full">
          <LoadingSpinner />
        </Card>
      </div>
    );
  }

  if (isError || !data || !data?.workspaceInvitation) {
    return (
      <div className="flex grow justify-center items-center p-4">
        <Card className="p-6 flex gap-4 flex-col items-center justify-center text-center max-w-[664px] w-full">
          <NotFound />
        </Card>
      </div>
    );
  }

  if (
    userData &&
    userData?.myUser.profile?.email.toLowerCase() !== data.workspaceInvitation.email.toLowerCase()
  ) {
    return (
      <div className="flex grow justify-center items-center p-4">
        <Card className="p-6 flex gap-4 flex-col items-center justify-center text-center max-w-[664px] w-full">
          <InviteDoesNotMatch workspaceInvitation={data.workspaceInvitation} />
        </Card>
      </div>
    );
  }

  const handleJoinWorkspace = async () => {
    try {
      await acceptWorkspaceInvitation({ invitationId: invitationId ?? '' }).unwrap();
      dispatch(
        setSelectedWorkspace({
          displayName: data.workspaceInvitation.workspace.displayName,
          photoUrl: data.workspaceInvitation.workspace.photoUrl,
        }),
      );
      dispatch(toggleAcceptedWorkspaceInvitationModal());
      navigate(AppRoutes.MY_EXPERIENCES);
    } catch {
      displayToast({
        id: invitationId ?? '',
        body: t('toasts.joinError.body'),
        title: t('toasts.joinError.title'),
        type: 'error',
      });
    }
  };

  return (
    <Page title={t('meta.title')}>
      <div className="flex grow justify-center items-center p-4 w-full">
        <Card className="p-6 flex gap-6 flex-col items-center text-center w-full max-w-[664px]">
          <Avatar
            placeholder="workspace"
            alt="Workspace"
            size="xl2"
            src={data.workspaceInvitation.workspace.photoUrl ?? undefined}
          />
          <Headline type="primary" size="sm" className="break-words w-full max-w-[664px]">
            {t('joinWorkspace.headline', {
              displayName: data.workspaceInvitation.workspace.displayName,
            })}
          </Headline>
          <Text className="break-words max-w-[664px] w-full">
            <Trans
              t={t}
              i18nKey={'joinWorkspace.body'}
              values={{
                email: data.workspaceInvitation.email,
                displayName: data.workspaceInvitation.workspace.displayName,
                ownerEmail: data.workspaceInvitation.workspace.owner.email,
              }}
            />
          </Text>
          {userData?.myUser.profile?.email ? (
            <Button label={t('joinWorkspace.accept')} onClick={handleJoinWorkspace} />
          ) : (
            <a href={`${AppRoutes.LOGIN}?redirectTo=${location.pathname}`}>
              <Button label={t('joinWorkspace.logIn')} />
            </a>
          )}
        </Card>
      </div>
    </Page>
  );
};
