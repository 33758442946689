import { useState } from 'react';
import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { getExperienceSubPageTitleOptions } from '../utils';
import { SubmissionGroupDropdown } from './submission-group-dropdown.component';
import { SubmissionGroup, SUBMISSION_GROUPS } from './constants';
import { SubmissionsOverviewList } from './submissions-overview-list.component';
import { SubmissionsOverviewHeadline } from './submissions-overview-headline.component';
import { useSubmissionsOverview } from './hooks';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { DownloadSubmissions } from './download-submissions-modal.component';

export const SubmissionsPage = () => {
  const [selectedGroup, setSelectedGroup] = useState<SubmissionGroup>(SUBMISSION_GROUPS[0]);
  const { t } = useTranslate('pages.submissions');
  const { data: experienceInfoData } = useExperienceInfo();
  const { data: submissionsOverviewData } = useSubmissionsOverview();

  const displayName = experienceInfoData?.experience?.displayName;
  const numSubmissions = submissionsOverviewData?.cohort?.numSubmissions ?? 0;

  return (
    <Page title={t('meta.title', getExperienceSubPageTitleOptions(displayName))}>
      <div className="flex flex-col w-full mb-4 tablet-wide:mb-5 tablet-wide:flex-row gap-4 tablet-wide:gap-5">
        <SubmissionsOverviewHeadline submissionGroup={selectedGroup} />
        <div>
          <SubmissionGroupDropdown
            disabled={numSubmissions === 0}
            selectedGroup={selectedGroup}
            onChange={setSelectedGroup}
          />
        </div>
        <DownloadSubmissions />
      </div>
      <SubmissionsOverviewList submissionGroup={selectedGroup} />
    </Page>
  );
};
