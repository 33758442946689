import { Headline } from '@goosechase/ui';

import { Card } from '../card.component';
import { BonusPointsPill, MissionPointsPill } from '../points-pill';
import { MissionTypeName } from '../../data/models';
import { LikeButton } from '../like-button';
import {
  SubmissionCardDropdown,
  SubmissionCardDropdownProps,
} from './submission-card-dropdown.component';
import { SubmissionContent } from './submission-content.component';
import { SubmissionTeamHeader, SubmissionTeamHeaderProps } from '../submission-team-header';

export type SubmissionCardUIProps = {
  submissionImageSrc?: string;
  submissionContent?: string;
  missionName: string;
  missionType: MissionTypeName;
  missionPoints: number;
  bonusPoints: number;
  isLiked: boolean;
  numLikes: number;
  onClick: () => void;
  onClickLike: () => void;
  onBonus: (submissionId: string) => void;
} & SubmissionCardDropdownProps &
  SubmissionTeamHeaderProps;

export const SubmissionCardUI = ({
  experienceId,
  teamId,
  teamName,
  teamImageUrl,
  teamMode,
  submissionDate,
  submissionImageSrc,
  submissionContent,
  missionName,
  missionType,
  missionPoints,
  bonusPoints,
  isLiked,
  numLikes,
  onClick,
  onClickLike,
  onCopy,
  onDelete,
  onDownload,
  onBonus,
}: SubmissionCardUIProps) => {
  return (
    <Card className="flex flex-col gap-3 p-5 w-full">
      <SubmissionTeamHeader
        experienceId={experienceId}
        teamId={teamId}
        teamImageUrl={teamImageUrl}
        teamMode={teamMode}
        teamName={teamName}
        submissionDate={submissionDate}
      />
      <button className="flex flex-col gap-3 outline-black" onClick={onClick}>
        <div className="relative w-full aspect-square rounded-lg overflow-hidden">
          <SubmissionContent
            missionType={missionType}
            imageSrc={submissionImageSrc}
            content={submissionContent}
          />
          {bonusPoints !== 0 && (
            <BonusPointsPill className="absolute right-3 bottom-3" points={bonusPoints} />
          )}
        </div>
        <div className="truncate w-full text-left">
          <Headline className="truncate" type="secondary" size="xs">
            {missionName}
          </Headline>
        </div>
      </button>
      <MissionPointsPill type={missionType} points={missionPoints} />
      <div className="flex flex-row-reverse justify-between">
        <SubmissionCardDropdown
          onCopy={onCopy}
          onDownload={onDownload}
          onDelete={onDelete}
          onBonus={onBonus}
        />
        <LikeButton isLiked={isLiked} numLikes={numLikes} onClick={onClickLike} />
      </div>
    </Card>
  );
};
