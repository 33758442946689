import * as React from 'react';
import classNames from 'classnames';
import { Headline, Text } from '@goosechase/ui';

import { ExternalRoutes } from 'constants/routes';
import { ReactComponent as GoosechaseIconDark } from 'media/goosechase-icon-dark.svg';
import { useScreenSize } from 'hooks/use-screen-size';
import { Link } from './link.component';
import { useTranslate } from '../util/i18n';

interface AccountFormLayoutProps {
  children: React.ReactNode;
}

const AccountFormLayout = ({ children }: AccountFormLayoutProps) => (
  <div className="flex h-full">{children}</div>
);

interface LayoutBodyProps {
  children: React.ReactNode;
}

export const LayoutBody = ({ children }: LayoutBodyProps) => {
  const { t } = useTranslate('accountFormLayout');

  return (
    <div className="flex desktop:block flex-col grow py-5 desktop:pt-8 bg-white overflow-auto">
      <Link href={ExternalRoutes.MARKETING_SITE} aria-label={t('goosechaseIconAriaLabel')}>
        <GoosechaseIconDark className="ml-5 tablet-narrow:ml-12" height="32px" />
      </Link>
      <div className="w-full px-5 tablet-narrow:px-12 desktop:px-24 max-w-[842px] desktop:ml-auto self-center">
        <div className="w-full mx-auto desktop:mx-0 max-w-[509px] desktop:max-w-[488px] pt-14">
          {children}
        </div>
      </div>
    </div>
  );
};

export const PANEL_CLASSNAME = 'hidden desktop:flex desktop:basis-2/5 justify-center items-center';

interface LayoutPanelProps {
  children: React.ReactNode;
  className?: string;
}

export const LayoutPanel = ({ children, className }: LayoutPanelProps) => (
  <div className={classNames(PANEL_CLASSNAME, className)}>
    <div className="flex flex-col gap-11 max-w-[322px]">{children}</div>
  </div>
);

interface LayoutTitleProps {
  children: string;
}

export const LayoutTitle = ({ children }: LayoutTitleProps) => {
  const { isMobile } = useScreenSize();

  return (
    <Headline
      className="mb-2 tablet-narrow:mb-4 font-bold"
      type={isMobile ? 'secondary' : 'primary'}
      size="sm">
      {children}
    </Headline>
  );
};

interface LayoutDescriptionProps {
  children: React.ReactNode;
}

export const LayoutDescription = ({ children }: LayoutDescriptionProps) => (
  <Text className="mb-10 text-paragraph-md tablet-narrow:text-paragraph-lg">{children}</Text>
);

AccountFormLayout.Body = LayoutBody;
AccountFormLayout.Panel = LayoutPanel;
AccountFormLayout.Title = LayoutTitle;
AccountFormLayout.Description = LayoutDescription;

export { type AccountFormLayoutProps, AccountFormLayout };
