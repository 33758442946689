import { Card } from 'components/card.component';
import { HelpCircle } from 'components/help-circle.component';

interface StatisticsCardProps {
  stat: string;
  label: string;
  help: string;
}

export const StatisticsCard = ({ stat, label, help }: StatisticsCardProps) => {
  return (
    <Card
      className="col-span-4 tablet-narrow:col-span-2 tablet-wide:col-span-2 desktop:col-span-1
                row-span-1 tablet-narrow:row-span-1 desktop:row-span-2
                flex flex-col items-center align-center justify-center
                h-64 tablet-narrow:h-40 desktop:h-full
                p-2
                text-base desktop:text-lg text-black-64 font-bold text-center
                group">
      <span className="text-6xl leading-none">{stat}</span>
      {label}
      <HelpCircle id="statistics-tooltip" variant="light" tooltipText={help} />
    </Card>
  );
};
