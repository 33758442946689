import { Label, IconButton, Headline, Text, mergeClasses } from '@goosechase/ui';
import { MissionTypeName } from 'data/models';
import { Card } from '../card.component';
import { MissionIcon } from '../mission-icon';
import { MissionPointsPill } from '../points-pill';
import {
  InformationTags,
  type TagValue,
  type TagValueWithTooltip,
} from '../information-tags/information-tags.component';
import { createRef, useEffect, useState } from 'react';
import { useTranslate } from 'util/i18n';
import { Link } from 'components/link.component';
import { Nullable } from 'types/util';

export interface MissionSummaryCardProps {
  type: MissionTypeName;
  title: string;
  description: string;
  points: number;
  infoTitle?: string;
  infoTags: TagValue[];
  secondaryInfoTags: TagValueWithTooltip[];
  image?: Nullable<string>;
  link?: Nullable<string>;
  renderTopRight?: () => React.ReactNode;
  renderBottomRight?: () => React.ReactNode;
}

const MIN_HEIGHT_AFTER_LINE_CLAMP = 48;

export const MissionSummaryCard = ({
  description,
  title,
  type,
  points,
  infoTags,
  secondaryInfoTags,
  infoTitle,
  link,
  image,
  renderBottomRight,
  renderTopRight,
}: MissionSummaryCardProps) => {
  const { t } = useTranslate('submissionFocusedView');
  const [open, setOpen] = useState(false);
  const [shouldDisplayCaret, setShouldDisplayCaret] = useState(false);
  const ref = createRef<HTMLParagraphElement>();

  const shouldRenderInformationTags =
    infoTitle || infoTags.length > 0 || secondaryInfoTags.length > 0;

  const toggleDescription = () => {
    setOpen((value) => !value);
  };

  useEffect(() => {
    if (ref.current && ref.current.scrollHeight >= MIN_HEIGHT_AFTER_LINE_CLAMP) {
      setShouldDisplayCaret(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return (
    <Card className="flex border border-black-24 p-4">
      <div className="flex grow flex-col gap-4">
        <div className="flex items-center gap-4">
          <MissionIcon className="h-[36px] w-[36px]" type={type} />
          <Headline type="secondary" size="2xs" className="flex-1">
            {title}
          </Headline>
          {!renderTopRight && !renderBottomRight ? (
            <MissionPointsPill type={type} points={points} />
          ) : null}
        </div>
        <div className="flex gap-4">
          <Text
            size="xs"
            className={mergeClasses('flex-1 whitespace-pre-line', { 'line-clamp-2': !open })}
            ref={ref}>
            {description}
          </Text>
          <div className="w-6">
            {shouldDisplayCaret ? (
              <IconButton
                icon="CaretDown"
                className="h-6 w-6"
                iconColor="black"
                iconClassName={mergeClasses({
                  'rotate-180': open,
                })}
                onClick={toggleDescription}
                aria-label={t('ariaExpandLabel') ?? undefined}
              />
            ) : null}
          </div>
        </div>
        <div className="flex flex-col">
          {shouldRenderInformationTags && (
            <InformationTags
              title={infoTitle}
              primaryValues={infoTags}
              secondaryValues={secondaryInfoTags}
            />
          )}
          {image && (
            <img
              className="mt-2 rounded w-full max-w-[100px] max-h-[100px]"
              alt={title}
              src={image}
            />
          )}
          {link && (
            <div className="mt-2">
              <Link
                className="w-fit text-vibrantBlue hover:text-[#0013c1]"
                href={link}
                target="_blank">
                <Label className="w-fit cursor-pointer hover:underline" size="lg">
                  {link}
                </Label>
              </Link>
            </div>
          )}
        </div>
      </div>
      {renderTopRight || renderBottomRight ? (
        <div className="flex gap-3 flex-col items-end justify-between">
          {renderTopRight ? renderTopRight() : null}
          <MissionPointsPill type={type} points={points} />
          {renderBottomRight ? renderBottomRight() : null}
        </div>
      ) : null}
    </Card>
  );
};
