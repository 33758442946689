import { TextField, Button } from '@goosechase/ui';
import { EmptyState } from 'components/empty-state';
import { getAdditionalMissionInfo } from 'components/information-tags/information-tags.util';
import { LoadingSpinner } from 'components/loading-spinner';
import { MissionSummaryCard } from 'components/mission-summary-card';
import { useMySavedMissionsQuery } from 'data/missions';
import { FeedVisibility } from 'data/models';
import { ReactComponent as GeeseWithMagnifyingGlass } from 'media/goose-with-magnifying-glass.svg';
import { useState } from 'react';
import { useTranslate } from 'util/i18n';
import { SavedMissionDropdown } from './saved-mission-dropdown.component';
import { filterMissionBySearchValue } from './util';

type SavedMissionsListProps = {
  onAddClick: (missionId: string, displayName: string) => void;
};

export const SavedMissionsList = (props: SavedMissionsListProps) => {
  const { t } = useTranslate('missionLibrary');
  const { t: t2 } = useTranslate('missionInformationTags');
  const { data, isError, isLoading } = useMySavedMissionsQuery();
  const [searchValue, setSearchValue] = useState('');

  if (isLoading) {
    return (
      <div className="p-10">
        <LoadingSpinner />
      </div>
    );
  }

  if (isError || !data?.mySavedMissions.length) {
    return (
      <EmptyState
        subtext={t('saved.empty.subtext')}
        text={t('saved.empty.text')}
        renderGraphic={() => <GeeseWithMagnifyingGlass />}
      />
    );
  }

  const handleSearchOnChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchValue(event.target.value);
  };

  const missions = data.mySavedMissions.filter(filterMissionBySearchValue(searchValue));

  return (
    <div className="flex flex-col gap-3 w-full">
      <div>
        <TextField
          className="flex-1"
          placeholder={t('saved.search') ?? undefined}
          value={searchValue}
          onChange={handleSearchOnChange}
          rounded
          leftIcon="Search"
        />
      </div>
      <div className="flex flex-col gap-3 overflow-y-auto pb-16">
        {missions.map((mission) => {
          const additionalMissionInfo = getAdditionalMissionInfo(t2, {
            ...mission,
            feedVisibility: FeedVisibility.PublicAtExperienceEnd,
          });

          return (
            <MissionSummaryCard
              description={mission.description}
              infoTags={additionalMissionInfo.infoTags}
              points={mission.points}
              secondaryInfoTags={additionalMissionInfo.secondaryInfoTags}
              title={mission.displayName}
              type={mission.__typename}
              infoTitle={additionalMissionInfo.infoTitle}
              key={mission.id}
              image={mission.image}
              link={mission.link}
              renderBottomRight={() => (
                <Button
                  outlined
                  leftIcon="Plus"
                  label={t('add')}
                  size="sm"
                  onClick={() => props.onAddClick(mission.id, mission.displayName)}
                />
              )}
              renderTopRight={() => (
                <SavedMissionDropdown displayName={mission.displayName} missionId={mission.id} />
              )}
            />
          );
        })}
      </div>
    </div>
  );
};
