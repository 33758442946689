import { Outlet } from 'react-router-dom';

import { Card } from 'components/card.component';

export const OnboardingPage = () => {
  return (
    <div className="flex min-h-full justify-center items-center bg-darkGreen py-4  px-4">
      <Card className="max-w-3xl py-8 px-16">
        <Outlet />
      </Card>
    </div>
  );
};
