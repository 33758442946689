// Note: These values are also used the tailwind theme. Make sure to update the theme if changes are made.
const TABLET_NARROW_MIN_WIDTH = 600;
const TABLET_WIDE_MIN_WIDTH = 880;
const DESKTOP_MIN_WIDTH = 1280;

export type ScreenSize = 'mobile' | 'tablet-narrow' | 'tablet-wide' | 'desktop';

export interface ScreenSizeAPI {
  screen: ScreenSize;
  isMobile: boolean;
  isTabletNarrow: boolean;
  isTabletWide: boolean;
  isDesktop: boolean;
}
export const convertWidthToScreenSize = (width: number): ScreenSizeAPI => {
  let screen: ScreenSize = 'desktop';
  if (width < TABLET_NARROW_MIN_WIDTH) {
    screen = 'mobile';
  } else if (width < TABLET_WIDE_MIN_WIDTH) {
    screen = 'tablet-narrow';
  } else if (width < DESKTOP_MIN_WIDTH) {
    screen = 'tablet-wide';
  }

  return {
    screen,
    isMobile: width < TABLET_NARROW_MIN_WIDTH,
    isTabletNarrow: width >= TABLET_NARROW_MIN_WIDTH,
    isTabletWide: width >= TABLET_WIDE_MIN_WIDTH,
    isDesktop: width >= DESKTOP_MIN_WIDTH,
  };
};
