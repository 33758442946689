import { Headline, Text, Button } from '@goosechase/ui';
import { useLoadMyUserQuery } from 'data/auth';
import { useTranslate } from 'util/i18n';
import createYourExperience from 'media/create-your-experience.png';
import { Trans } from 'react-i18next';
import { AppRoutes } from 'constants/routes';
import { useLocation } from 'react-router';

type SideBarProps = {
  onCopyExperience: () => Promise<void>;
};

export const SideBar = (props: SideBarProps) => {
  const { t } = useTranslate('pages.sharedExperience.sideBar');
  const { data } = useLoadMyUserQuery();
  const location = useLocation();

  return (
    <div className="w-full">
      <div className="flex flex-col gap-4">
        <Headline type="secondary" size="sm" className="font-bold">
          {t('use.title')}
        </Headline>
        <Text>{t('use.description')}</Text>
        <div>
          {data?.myUser ? (
            <Button label={t('use.loggedInCta')} leftIcon="Copy" onClick={props.onCopyExperience} />
          ) : (
            <a href={`${AppRoutes.LOGIN}?redirectTo=${location.pathname}`}>
              <Button label={t('use.loggedOutCta')} leftIcon="Copy" />
            </a>
          )}
        </div>
      </div>
      <div className="h-px bg-black-24 w-full my-6" />
      <div className="flex flex-col gap-4">
        <Headline type="secondary" size="sm" className="font-bold">
          {t('about.title')}
        </Headline>
        <Text>
          <Trans
            t={t}
            i18nKey="about.description"
            components={[
              <a key="goosechase" href="https://goosechase.com" className="text-vibrantBlue" />,
            ]}
          />
        </Text>
        <img src={createYourExperience} />
      </div>
    </div>
  );
};
