import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { AccountFormLayout } from 'components/account-form-layout.component';

import { AppRoutes } from '../../constants/routes';
import { ResetPasswordPanel } from './reset-password-panel.component';
import { ResetPasswordForm } from './reset-password-form';
import { useRedirectFromExpiredLink } from '../../hooks/use-forgot-password-redirect.hook';
import { useValidatePasswordResetId } from './use-validate-password-reset-id.hook';
import { NotFound } from '../../components/not-found';

export const ResetPasswordPage = () => {
  const redirectFromExpiredLink = useRedirectFromExpiredLink();
  const navigate = useNavigate();
  const { t } = useTranslate('pages.resetPassword');
  const passwordResetIdValidState = useValidatePasswordResetId();

  useEffect(() => {
    if (passwordResetIdValidState === 'expired') {
      redirectFromExpiredLink();
    }
  }, [passwordResetIdValidState, redirectFromExpiredLink]);

  const onSubmitSuccess = () => {
    navigate(AppRoutes.RESET_PASSWORD_DONE);
  };

  return (
    <Page title={t('meta.title')} description={t('meta.description') as string}>
      {passwordResetIdValidState === 'valid' && (
        <AccountFormLayout>
          <AccountFormLayout.Body>
            <AccountFormLayout.Title>{t('heading')}</AccountFormLayout.Title>
            <AccountFormLayout.Description>{t('subheading')}</AccountFormLayout.Description>
            <ResetPasswordForm
              onSubmitSuccess={onSubmitSuccess}
              redirectFromExpiredLink={redirectFromExpiredLink}
            />
          </AccountFormLayout.Body>
          <ResetPasswordPanel />
        </AccountFormLayout>
      )}
      {passwordResetIdValidState === 'invalid' && (
        <div className="flex grow h-full justify-center items-center">
          <NotFound />
        </div>
      )}
    </Page>
  );
};
