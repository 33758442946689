import { StyledAnchor, Avatar, Label, Headline, Text, Icon, Button } from '@goosechase/ui';
import { CreditCard } from 'components/credit-card.component';
import { ErrorState } from 'components/error-state';
import { Link } from 'components/link.component';
import { LoadingSpinner } from 'components/loading-spinner';
import { DISPLAY_UPCOMING_BILLING } from 'constants/plans';
import { AppRoutes } from 'constants/routes';
import { useActiveWorkspaceWithSubscriptionQuery, useUpcomingBillingQuery } from 'data/workspaces';
import { format } from 'date-fns';
import { Trans } from 'react-i18next';
import { useTranslate } from 'util/i18n';
import { PlanBadge } from '../../components/plans/plan-badge.component';
import { BillingDetails } from './billing-details.component';

const UpgradablePlan = ({ planId }: { planId: string }) => {
  const { t } = useTranslate('currentPlan');

  return (
    <div className="flex flex-col gap-3">
      <div className="bg-yellowOrange-10 rounded-lg border border-yellowOrange p-4 flex gap-3 flex-col">
        <div className="flex gap-2">
          <Icon name="Zap" color="yellowOrange" />
          <Text className="font-bold">{t('upgradeYourPlan')}</Text>
        </div>
        <ul className="list-disc ml-6">
          {(t(`${planId}.upgrades`, { returnObjects: true }) as string[]).map((upgrade) => (
            <li key={upgrade}>
              <Text size="sm">
                <span dangerouslySetInnerHTML={{ __html: upgrade }} />
              </Text>
            </li>
          ))}
        </ul>
        <div className="w-min">
          <Link href={AppRoutes.PLANS} useRouter target="_blank">
            <Button label={t('upgradeNow')} />
          </Link>
        </div>
      </div>
    </div>
  );
};

type UpcomingBillingProps = {
  workspaceId: string;
};

const UpcomingBilling = (props: UpcomingBillingProps) => {
  const { data, isLoading } = useUpcomingBillingQuery({ workspaceId: props.workspaceId });
  const { t } = useTranslate('currentPlan');

  const upcomingBilling = data?.upcomingBilling;

  if (isLoading) {
    return (
      <div className="flex justify-center p-8">
        <LoadingSpinner />
      </div>
    );
  }

  if (!upcomingBilling) {
    return null;
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col p-4 rounded-lg border border-black12 bg-black-4 gap-3">
        <Label className="uppercase" size="lg">
          {t('upcomingBill')}
        </Label>
        <Text>
          <Trans
            i18nKey="nextBilling"
            t={t}
            values={{
              price: (upcomingBilling.priceUsdCents / 100).toFixed(0),
              date: format(new Date(upcomingBilling.nextBillingAt), 'LLL d yyyy'),
            }}
          />
        </Text>
        <Text className="flex gap-2">
          <CreditCard type={upcomingBilling.card.type} />
          <Trans
            i18nKey="cardInfo"
            t={t}
            values={{
              last4: upcomingBilling.card.last4,
            }}
          />
        </Text>
        <BillingDetails>
          <Button
            label={t('billing')}
            rightIcon="ExternalLink"
            outlined
            size="sm"
            className="!bg-transparent"
          />
        </BillingDetails>
      </div>
      <div>
        <StyledAnchor
          href={AppRoutes.PLANS}
          underlineClassName="hidden"
          className="flex gap-2"
          target="_blank">
          <Icon name="Edit" color="vibrantBlue" />
          {t('changeYourPlan')}
        </StyledAnchor>
      </div>
    </div>
  );
};

export const CurrentPlan = () => {
  const { t } = useTranslate('currentPlan');

  const { data, isLoading, isError } = useActiveWorkspaceWithSubscriptionQuery();

  if (isLoading) {
    return (
      <div className="flex justify-center p-8">
        <LoadingSpinner />
      </div>
    );
  }

  if (isError || !data?.myActiveWorkspace) {
    return (
      <div className="flex justify-center p-8">
        <ErrorState />
      </div>
    );
  }

  const workspace = data.myActiveWorkspace;
  const subscription = workspace.subscription;
  const currentPlan = subscription.plan;
  const shouldRenderUpcomingBilling = DISPLAY_UPCOMING_BILLING.includes(currentPlan.id);

  return currentPlan?.upgradable ? (
    <>
      <Label className="uppercase" size="lg">
        {t('currentPlan')}
      </Label>
      <Headline type="secondary" size="sm">
        {t(`${currentPlan.id}.title`)}
      </Headline>
      <ul className="list-disc ml-4">
        {(t(`${currentPlan.id}.features`, { returnObjects: true }) as string[]).map((feature) => (
          <li key={feature}>
            <Text size="sm">{feature}</Text>
          </li>
        ))}
      </ul>
      {shouldRenderUpcomingBilling ? (
        <UpcomingBilling workspaceId={workspace.id} />
      ) : (
        <>
          <UpgradablePlan planId={currentPlan.id} />
          <BillingDetails>
            <StyledAnchor underlineClassName="hidden">{t('billingDetails')}</StyledAnchor>
          </BillingDetails>
        </>
      )}
    </>
  ) : (
    <div className="flex flex-col gap-3">
      <Label className="uppercase" size="lg">
        {t('workspace')}
      </Label>
      <Link href={AppRoutes.WORKSPACE} useRouter>
        <div className="rounded-lg p-3 border border-black-24 flex gap-4">
          <Avatar
            placeholder="workspace"
            alt={workspace?.displayName ?? ''}
            size="xs"
            src={workspace.photoUrl ?? ''}
            className="flex-none"
          />
          <div className="flex flex-col gap-2">
            <Headline type="secondary" size="xs" className="break-all">
              {workspace?.displayName ?? ''}
            </Headline>
            {currentPlan.id ? (
              <PlanBadge
                planId={currentPlan.id}
                maxConcurrentExperiences={currentPlan.maxConcurrentExperiences}
                maxParticipantsPerExperience={currentPlan.maxParticipantsPerExperience}
                maxTeamsPerExperience={currentPlan.maxTeamsPerExperience}
              />
            ) : null}
          </div>
        </div>
      </Link>
      {shouldRenderUpcomingBilling ? <UpcomingBilling workspaceId={workspace.id} /> : null}
    </div>
  );
};
