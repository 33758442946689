/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

module.hot?.accept();
export type SubmissionMissionFragment_CameraMission_Fragment = { __typename: 'CameraMission', mediaType: Types.MediaType, allowCameraRollUploads: boolean, id: string, displayName: string, points: number, description: string, image: string | null, feedVisibility: Types.FeedVisibility };

export type SubmissionMissionFragment_GpsMission_Fragment = { __typename: 'GpsMission', locationName: string | null, latitude: string, longitude: string, id: string, displayName: string, points: number, description: string, image: string | null, feedVisibility: Types.FeedVisibility };

export type SubmissionMissionFragment_TextMission_Fragment = { __typename: 'TextMission', answers: Array<string>, allowApproximateAnswers: boolean, id: string, displayName: string, points: number, description: string, image: string | null, feedVisibility: Types.FeedVisibility };

export type SubmissionMissionFragmentFragment = SubmissionMissionFragment_CameraMission_Fragment | SubmissionMissionFragment_GpsMission_Fragment | SubmissionMissionFragment_TextMission_Fragment;

export const SubmissionMissionFragmentFragmentDoc = `
    fragment SubmissionMissionFragment on Mission {
  __typename
  id
  displayName
  points
  description
  image
  feedVisibility
  ... on CameraMission {
    mediaType
    allowCameraRollUploads
  }
  ... on TextMission {
    answers
    allowApproximateAnswers
  }
  ... on GpsMission {
    locationName
    latitude
    longitude
  }
}
    `;
