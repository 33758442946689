import { AnalyticsBrowser } from '@segment/analytics-next';
import { getConfig } from '../constants/config';

const mockAnalyticsClient = () => {
  return {
    identify: () => undefined,
    page: () => undefined,
  };
};

const createAnalyticsClient = (writeKey: string) => {
  return AnalyticsBrowser.load({ writeKey });
};

const initAnalyticsClient = () => {
  const config = getConfig();
  if (config.COMMUNICATIONS_DISABLED) {
    return mockAnalyticsClient();
  }

  return createAnalyticsClient(config.SEGMENT_WRITE_KEY);
};

export const analytics = initAnalyticsClient();
