/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type UpdateUserProfileMutationVariables = Types.Exact<{
  params: Types.UpdateUserProfileParams;
}>;


export type UpdateUserProfileMutation = { __typename: 'Mutation', updateUserProfile: { __typename: 'UserProfile', id: string } };


export const UpdateUserProfileDocument = `
    mutation UpdateUserProfile($params: UpdateUserProfileParams!) {
  updateUserProfile(params: $params) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    UpdateUserProfile: build.mutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>({
      query: (variables) => ({ document: UpdateUserProfileDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateUserProfileMutation } = injectedRtkApi;

