/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type ExperienceWorkspaceQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ExperienceWorkspaceQuery = { __typename: 'Query', experience: { __typename: 'Experience', workspace: { __typename: 'Workspace', type: Types.WorkspaceType, myWorkspaceMember: { __typename: 'WorkspaceMember', userId: string, roleId: string } | null, subscription: { __typename: 'Subscription', id: string, plan: { __typename: 'WorkspacePlan', chargebeeSku: string | null, id: string, displayName: string, upgradable: boolean, maxTeamsPerExperience: number | null, maxParticipantsPerExperience: number | null, individualModeEnabled: boolean, showUpgradeBanner: Types.ShowUpgradeBanner } } } } | null };


export const ExperienceWorkspaceDocument = `
    query ExperienceWorkspace($id: ID!) {
  experience(id: $id) {
    workspace {
      type
      myWorkspaceMember {
        userId
        roleId
      }
      subscription {
        id
        plan {
          chargebeeSku
          id
          displayName
          upgradable
          maxTeamsPerExperience
          maxParticipantsPerExperience
          individualModeEnabled
          showUpgradeBanner
        }
      }
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ExperienceWorkspace: build.query<ExperienceWorkspaceQuery, ExperienceWorkspaceQueryVariables>({
      query: (variables) => ({ document: ExperienceWorkspaceDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useExperienceWorkspaceQuery, useLazyExperienceWorkspaceQuery } = injectedRtkApi;

