/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type InviteExperienceManagerMutationVariables = Types.Exact<{
  params: Types.CreateExperienceInvitationParams;
}>;


export type InviteExperienceManagerMutation = { __typename: 'Mutation', createExperienceInvitation: { __typename: 'ExperienceInvitation', id: string, email: string, roleId: string, status: string, lastSentAt: string } };


export const InviteExperienceManagerDocument = `
    mutation InviteExperienceManager($params: CreateExperienceInvitationParams!) {
  createExperienceInvitation(params: $params) {
    id
    email
    roleId
    status
    lastSentAt
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    InviteExperienceManager: build.mutation<InviteExperienceManagerMutation, InviteExperienceManagerMutationVariables>({
      query: (variables) => ({ document: InviteExperienceManagerDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useInviteExperienceManagerMutation } = injectedRtkApi;

