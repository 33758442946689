/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type CreateSubmissionArchiveMutationVariables = Types.Exact<{
  params: Types.CreateSubmissionArchiveInput;
}>;


export type CreateSubmissionArchiveMutation = { __typename: 'Mutation', createSubmissionArchive: { __typename: 'SubmissionArchive', state: Types.SubmissionArchiveState, id: string } };


export const CreateSubmissionArchiveDocument = `
    mutation CreateSubmissionArchive($params: CreateSubmissionArchiveInput!) {
  createSubmissionArchive(params: $params) {
    state
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    CreateSubmissionArchive: build.mutation<CreateSubmissionArchiveMutation, CreateSubmissionArchiveMutationVariables>({
      query: (variables) => ({ document: CreateSubmissionArchiveDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateSubmissionArchiveMutation } = injectedRtkApi;

