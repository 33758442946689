/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type ExperienceFormBrandingQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ExperienceFormBrandingQuery = { __typename: 'Query', experience: { __typename: 'Experience', termsOfServiceUrl: string | null, assets: Array<{ __typename: 'ExperienceAsset', id: string, url: string, role: Types.ExperienceAssetRole }> } | null };


export const ExperienceFormBrandingDocument = `
    query ExperienceFormBranding($id: ID!) {
  experience(id: $id) {
    termsOfServiceUrl
    assets {
      id
      url
      role
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ExperienceFormBranding: build.query<ExperienceFormBrandingQuery, ExperienceFormBrandingQueryVariables>({
      query: (variables) => ({ document: ExperienceFormBrandingDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useExperienceFormBrandingQuery, useLazyExperienceFormBrandingQuery } = injectedRtkApi;

