/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type DeleteTeamBonusMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteTeamBonusMutation = { __typename: 'Mutation', deleteTeamBonus: { __typename: 'TeamBonus', id: string } };


export const DeleteTeamBonusDocument = `
    mutation DeleteTeamBonus($id: ID!) {
  deleteTeamBonus(id: $id) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    DeleteTeamBonus: build.mutation<DeleteTeamBonusMutation, DeleteTeamBonusMutationVariables>({
      query: (variables) => ({ document: DeleteTeamBonusDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteTeamBonusMutation } = injectedRtkApi;

