var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Podium = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M8.92773 3.51245C8.92773 3.09824 9.26352 2.76245 9.67773 2.76245H14.3048C14.719 2.76245 15.0548 3.09824 15.0548 3.51245V19.2625H8.92773V3.51245Z", stroke: "inherit", strokeWidth: "1.5" }), _jsx("path", { d: "M2.7998 12.4887C2.7998 12.0745 3.13559 11.7387 3.5498 11.7387H8.17686C8.59107 11.7387 8.92686 12.0745 8.92686 12.4887V19.2624H3.5498C3.13559 19.2624 2.7998 18.9266 2.7998 18.5124V12.4887Z", stroke: "inherit", strokeWidth: "1.5" }), _jsx("path", { d: "M15.0732 8.78961C15.0732 8.3754 15.409 8.03961 15.8232 8.03961H20.4503C20.8645 8.03961 21.2003 8.3754 21.2003 8.78961V18.5124C21.2003 18.9266 20.8645 19.2624 20.4503 19.2624H15.0732V8.78961Z", stroke: "inherit", strokeWidth: "1.5" })] }))); };
