/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type LoadTeamsForExperienceQueryVariables = Types.Exact<{
  experienceId: Types.Scalars['ID']['input'];
}>;


export type LoadTeamsForExperienceQuery = { __typename: 'Query', experience: { __typename: 'Experience', studioDefaultTeamMode: Types.TeamMode, maxTeamsPerExperience: number | null, maxParticipantsPerExperience: number | null, workspace: { __typename: 'Workspace', subscription: { __typename: 'Subscription', plan: { __typename: 'WorkspacePlan', maxTeamsPerExperience: number | null, maxParticipantsPerExperience: number | null, individualModeEnabled: boolean, showUpgradeBanner: Types.ShowUpgradeBanner } } }, cohorts: Array<{ __typename: 'Cohort', id: string, numberOfTeams: number, numberOfParticipants: number, teams: Array<{ __typename: 'Team', id: string, displayName: string, mode: Types.TeamMode, passcode: string | null, maxSize: number | null, isPreCreated: boolean, photoUrl: string | null, participants: Array<{ __typename: 'Participant', id: string }> }> }> } | null };


export const LoadTeamsForExperienceDocument = `
    query LoadTeamsForExperience($experienceId: ID!) {
  experience(id: $experienceId) {
    studioDefaultTeamMode
    maxTeamsPerExperience
    maxParticipantsPerExperience
    workspace {
      subscription {
        plan {
          maxTeamsPerExperience
          maxParticipantsPerExperience
          individualModeEnabled
          showUpgradeBanner
        }
      }
    }
    cohorts {
      id
      teams {
        id
        displayName
        mode
        passcode
        maxSize
        participants {
          id
        }
        isPreCreated
        photoUrl
      }
      numberOfTeams
      numberOfParticipants
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    LoadTeamsForExperience: build.query<LoadTeamsForExperienceQuery, LoadTeamsForExperienceQueryVariables>({
      query: (variables) => ({ document: LoadTeamsForExperienceDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLoadTeamsForExperienceQuery, useLazyLoadTeamsForExperienceQuery } = injectedRtkApi;

