import { Button, Label, Text } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';

import { PhotoUploadUIProps } from 'components/photo-upload';

export const PhotoUploadUI = ({
  label,
  photoUrl,
  uploading,
  onClick,
  onDelete,
  errorMessage,
  disabled,
}: PhotoUploadUIProps) => {
  const { t } = useTranslate('pages.branding.form');

  return (
    <div>
      <Label size="sm" className="block mb-2">
        {label.toUpperCase()}
      </Label>
      <div className="flex gap-5 items-center">
        {disabled ? null : (
          <div className="flex flex-col gap-3">
            <div className="flex flex-col tablet-narrow:flex-row gap-3">
              <Button
                label={photoUrl ? t('splashScreen.replace') : t('splashScreen.upload')}
                leftIcon="Plus"
                size="sm"
                outlined
                onClick={onClick}
                disabled={uploading}
                className="w-fit"
              />
              {photoUrl ? (
                <Button
                  label={t('splashScreen.delete')}
                  leftIcon="Delete"
                  size="sm"
                  outlined
                  onClick={onDelete}
                  disabled={uploading}
                  className="w-fit"
                />
              ) : null}
            </div>
            <Text>{t('splashScreen.hint')}</Text>
          </div>
        )}
      </div>
      <div className="flex justify-between mt-2">
        <Text size="xs" className="text-vibrantRed">
          {errorMessage ?? ''}
        </Text>
      </div>
    </div>
  );
};
