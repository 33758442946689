import { TimeInput, Text } from '@goosechase/ui';
import { Controller, Control, useFormState } from 'react-hook-form';

import type { MissionFormInputData } from './use-mission-form';
import { NumberInput } from '../number-input';
import { useTranslate } from 'util/i18n';
import { convertSpecificTimeToDateObj } from '../../util/time-util';
import { MissionTriggerInfo } from '../mission-trigger-time';

interface MissionTriggerSpecificFieldsProps {
  control: Control<MissionFormInputData>;
  triggerType: 'releaseTrigger' | 'expireTrigger';
}

export const MissionTriggerSpecificFields = ({
  control,
  triggerType,
}: MissionTriggerSpecificFieldsProps) => {
  const { t } = useTranslate('missionForm.missionTrigger');
  const { errors } = useFormState({ control });

  return (
    <div className="flex flex-col gap-3 w-full">
      <div className="flex gap-2 items-center w-full">
        <div className="w-[40px] h-[40px]">
          <svg
            width="40"
            height="36"
            viewBox="0 0 40 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7 5C7 4.44772 6.55228 4 6 4C5.44772 4 5 4.44772 5 5H7ZM34.7071 24.7071C35.0976 24.3166 35.0976 23.6834 34.7071 23.2929L28.3431 16.9289C27.9526 16.5384 27.3195 16.5384 26.9289 16.9289C26.5384 17.3195 26.5384 17.9526 26.9289 18.3431L32.5858 24L26.9289 29.6569C26.5384 30.0474 26.5384 30.6805 26.9289 31.0711C27.3195 31.4616 27.9526 31.4616 28.3431 31.0711L34.7071 24.7071ZM34 23H14V25H34V23ZM7 16V5H5V16H7ZM14 23C10.134 23 7 19.866 7 16H5C5 20.9706 9.02944 25 14 25V23Z"
              fill="#B1B1B1"
            />
          </svg>
        </div>
        <Text>{t('at')}</Text>
        <div className="flex flex-row">
          <Controller
            control={control}
            name={`${triggerType}.specificTime`}
            render={({ field: { name, value, onChange }, fieldState: { isDirty, error } }) => {
              let initialValue;
              if (typeof value === 'string') {
                initialValue = convertSpecificTimeToDateObj(value);
              } else {
                initialValue = value ? new Date(value) : undefined;
              }
              return (
                <TimeInput
                  className="w-max-[120px]"
                  placeholder={t('specificTime.placeholder') ?? undefined}
                  error=""
                  responseState={
                    error || Boolean(errors[triggerType]?.message) ? 'error' : undefined
                  }
                  isDirty={isDirty}
                  name={name}
                  onChangeValue={onChange}
                  value={initialValue}
                />
              );
            }}
          />
        </div>
        <Text className="whitespace-nowrap">{t('onDay')}</Text>
        <Controller
          name={`${triggerType}.specificDay`}
          control={control}
          render={({ field: { name, value, onChange, onBlur }, fieldState: { error } }) => (
            <NumberInput
              label=""
              placeholder={t('specificDay.placeholder') ?? undefined}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              value={value ?? null}
              error={Boolean(error) || Boolean(errors[triggerType]?.message)}
              min={1}
            />
          )}
        />
      </div>
      {errors[triggerType] ? (
        <div className="pl-[72px]">
          <Text size="xs" className="text-vibrantRed">
            <span
              dangerouslySetInnerHTML={{
                __html:
                  errors[triggerType]?.specificTime?.message ||
                  errors[triggerType]?.specificDay?.message ||
                  errors[triggerType]?.message ||
                  '',
              }}
            />
          </Text>
        </div>
      ) : null}
      <MissionTriggerInfo control={control} triggerType={triggerType} />
    </div>
  );
};
