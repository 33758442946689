import { api as _scheduleBroadcastApi } from './graphql/__generated__/schedule-broadcast.mutation.generated';
import { api as _sendBroadcastApi } from './graphql/__generated__/send-broadcast.mutation.generated';
import {
  api as _loadBroadcastsForCohortApi,
  LoadBroadcastsForCohortQuery as BroadcastsQuery,
} from './graphql/__generated__/load-broadcasts-for-cohort.query.generated';
import { api as _deleteBroadcastApi } from './graphql/__generated__/delete-broadcast.mutation.generated';
import { api as _updateScheduledBroadcastApi } from './graphql/__generated__/update-scheduled-broadcast.mutation.generated';

export type { BroadcastsQuery };

export const scheduleBroadcastApi = _scheduleBroadcastApi.enhanceEndpoints({
  endpoints: {
    ScheduleBroadcast: {
      invalidatesTags: ['Broadcasts'],
    },
  },
});

export const sendBroadcastApi = _sendBroadcastApi.enhanceEndpoints({
  endpoints: {
    SendBroadcast: {
      invalidatesTags: ['Broadcasts'],
    },
  },
});

export const loadBroadcastTriggersApi = _loadBroadcastsForCohortApi.enhanceEndpoints({
  endpoints: {
    LoadBroadcastsForCohort: {
      providesTags: ['Broadcasts'],
    },
  },
});

export const deleteBroadcastApi = _deleteBroadcastApi.enhanceEndpoints({
  endpoints: {
    DeleteBroadcast: {
      invalidatesTags: ['Broadcasts'],
    },
  },
});

export const updateScheduledBroadcastApi = _updateScheduledBroadcastApi.enhanceEndpoints({
  endpoints: {
    UpdateScheduledBroadcast: {
      invalidatesTags: ['Broadcasts'],
    },
  },
});
