import { Label } from '@goosechase/ui';
import { useTranslate } from '../../util/i18n';
import { type SyntheticEvent } from 'react';
import { RadioBlockSelector } from 'components/radio-block-selector/radio-block-selector.component';

export type SearchVisibilityProps = {
  value: string;
  name: string;
  onChange: (e: SyntheticEvent) => void;
};

export const SearchVisibility = ({ value, name, onChange }: SearchVisibilityProps) => {
  const { t } = useTranslate('pages.createExperience.createExperienceForm');

  const options = [
    {
      name: 'hidden',
      value: 'hidden',
      optionLabel: t('searchVisibility.options.hidden.title'),
      description: t('searchVisibility.options.hidden.description'),
    },
    {
      name: 'visible',
      value: 'visible',
      optionLabel: t('searchVisibility.options.visible.title'),
      description: t('searchVisibility.options.visible.description'),
    },
  ];

  return (
    <div>
      <Label size="sm" className="block mb-3" htmlFor={name}>
        {t('searchVisibility.label').toUpperCase()}
      </Label>
      <RadioBlockSelector options={options} name={name} onSelect={onChange} selectedValue={value} />
    </div>
  );
};
