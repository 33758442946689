import { useState } from 'react';

import { useTranslate } from 'util/i18n';
import { useSignUpMutation, SignUpMutation } from 'data/auth';
import { InvalidInputErrorIssue, isInvalidInputError } from 'data/errors';
import { SignUpFormUI, ServerErrors, SubmissionData } from './sign-up-form-ui';

// eslint-disable-next-line
export const isEmailInUseError = (e: any) => e.code === 'EMAIL_IN_USE';

export interface SignUpFormProps {
  onSignUpSuccess: (response: SignUpMutation['register']) => void;
}

export const SignUpForm = ({ onSignUpSuccess }: SignUpFormProps) => {
  const [serverErrors, setServerErrors] = useState<ServerErrors | undefined>();
  const [signUp, { isLoading }] = useSignUpMutation();
  const { t } = useTranslate('pages.signUp');

  const canSubmit = !(serverErrors?.email || serverErrors?.password);

  const handleGenericError = () => {
    setServerErrors({ general: t('signUpForm.generalError') as string });
  };

  const handleInvalidInputError = (issues: InvalidInputErrorIssue[]) => {
    const errors: ServerErrors = {};
    issues.forEach(({ path, code }) => {
      switch (path) {
        case 'email':
          if (code === 'EMAIL_IN_USE') {
            errors.email = t('signUpForm.email.errors.alreadyExists') as string;
          } else if (code === 'INVALID_INPUT') {
            errors.email = t('signUpForm.email.errors.invalid') as string;
          } else {
            // TODO: Sentry - unsupported error code
          }
          break;
        case 'password':
          if (code === 'INVALID_INPUT') {
            errors.password = t('signUpForm.email.errors.invalid') as string;
          } else {
            // TODO: Sentry - unsupported error code
          }
          break;
        default:
          // TODO: Sentry - unsupported path
          break;
      }
    });
    setServerErrors(Object.keys(errors).length > 0 ? errors : undefined);
  };

  const clearServerErrors = () => {
    setServerErrors(undefined);
  };

  const handleSignUp = async (data: SubmissionData) => {
    clearServerErrors();

    try {
      const response = await signUp(data).unwrap();
      onSignUpSuccess(response.register);
    } catch (e) {
      // TODO: Sentry - network error
      if (isInvalidInputError(e)) {
        handleInvalidInputError(e.issues);
      } else if (isEmailInUseError(e)) {
        setServerErrors({ email: t('signUpForm.email.errors.alreadyExists') ?? undefined });
      } else {
        handleGenericError();
      }
    }
  };

  return (
    <SignUpFormUI
      loading={isLoading}
      serverErrors={serverErrors}
      canSubmit={canSubmit}
      onSubmit={handleSignUp}
      clearServerErrors={clearServerErrors}
    />
  );
};
