import { Icon, Text } from '@goosechase/ui';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { useTranslate } from 'util/i18n';

type TeamBonusActionsProps = {
  handleEdit: () => void;
  handleDelete: () => void;
};

export const TeamBonusActions = ({ handleEdit, handleDelete }: TeamBonusActionsProps) => {
  const { t } = useTranslate('teamBonusHistory');

  return (
    <Menu>
      <MenuButton
        className="flex rounded-full data-[active]:bg-black-12"
        aria-label={t('meatball') ?? ''}>
        <Icon name="More" className="rounded-full hover:bg-black-12 data-[active]:bg-black-12" />
      </MenuButton>
      <MenuItems
        anchor="left start"
        className="bg-white border border-black-24 rounded-lg shadow-md shadow-black-24 font-semibold whitespace-nowrap z-[1000]">
        <MenuItem>
          <button
            className="cursor-pointer text-left w-full px-3 py-2 flex flex-row items-center rounded-t-lg hover:bg-black-4"
            type="button"
            onClick={handleEdit}>
            <Icon className="shrink-0" name="EditBold" size={16} />
            <Text className="ml-2">{t('editButton')}</Text>
          </button>
        </MenuItem>
        <MenuItem>
          <button
            className="text-vibrantRed cursor-pointer text-left w-full px-3 py-2 flex flex-row items-center rounded-b-lg hover:bg-black-4"
            type="button"
            onClick={handleDelete}>
            <Icon className="shrink-0" name="DeleteBold" size={16} color="vibrantRed" />
            <Text className="ml-2">{t('deleteButton')}</Text>
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
};
