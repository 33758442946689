var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var List = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("rect", { x: "12.75", y: "17.5952", width: "8.5", height: "4.15476", rx: "1.25", stroke: "inherit", strokeWidth: "1.5" }), _jsx("rect", { x: "2.75", y: "2.25", width: "18.5", height: "5.5", rx: "1.25", stroke: "inherit", strokeWidth: "1.5" }), _jsx("rect", { x: "12.75", y: "10.636", width: "8.5", height: "4.15476", rx: "1.25", stroke: "inherit", strokeWidth: "1.5" }), _jsx("path", { d: "M6.54297 11.1758V11.1758C6.54297 12.1368 7.32203 12.9159 8.28306 12.9159L10.539 12.9159", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round" }), _jsx("path", { d: "M6.54297 10.7719L6.54297 16.265C6.54297 17.9219 7.88612 19.265 9.54297 19.265L10.5391 19.265", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round" })] }))); };
