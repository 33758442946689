import { Headline, Icon, StyledLink, Text, mergeClasses } from '@goosechase/ui';

import { ReactComponent as NotFoundSVG } from 'media/404.svg';
import { ExternalRoutes } from '../../constants/routes';
import { useScreenSize } from '../../hooks/use-screen-size';
import { useTranslate } from '../../util/i18n';
import { Page } from '../page.component';

export const NotFound = () => {
  const { t } = useTranslate('notFound');
  const { isTabletWide } = useScreenSize();

  return (
    <Page title={t('meta.title')}>
      <div className="flex flex-col items-center w-full tablet-narrow:w-[508px] desktop:w-[751px] text-center">
        <NotFoundSVG className="w-full mb-8 tablet-wide:mb-12" />
        <Headline
          className="mb-2 tablet-wide:mb-4"
          type={isTabletWide ? 'primary' : 'secondary'}
          size="sm">
          {t('title')}
        </Headline>
        <Text className="mb-6" size={isTabletWide ? 'lg' : 'md'}>
          {t('body')}
        </Text>
        <a
          href={ExternalRoutes.MARKETING_SITE}
          className={mergeClasses('group flex flex-row items-center', {
            'text-lg': isTabletWide,
            'text-base': !isTabletWide,
          })}>
          <StyledLink className="group-hover:text-black" underlineClassName="group-hover:bg-black">
            {t('link')}
          </StyledLink>
          <Icon
            name="Arrow"
            size={24}
            color="vibrantBlue"
            className="group-hover:fill-black group-hover:stroke-black"
          />
        </a>
      </div>
    </Page>
  );
};
