import { NavLink } from 'react-router-dom';
import { Icon, IconName, mergeClasses, Text } from '@goosechase/ui';
import { EXPERIENCE_PATH } from '../../constants/routes';
import { forwardRef } from 'react';
import { useTranslate } from '../../util/i18n';

type NavLinkVariant = 'sidebar' | 'header';

interface ExperienceNavLinkProps {
  variant: NavLinkVariant;
  experienceId: string;
  path: string;
  activeIcon: IconName;
  inactiveIcon: IconName;
  name: string;
}

export const ExperienceNavLink = forwardRef<HTMLAnchorElement, ExperienceNavLinkProps>(
  function ExperienceNavLink(
    { variant, experienceId, path, activeIcon, inactiveIcon, name, ...props },
    ref,
  ) {
    const { t } = useTranslate('experienceSectionTabs.sections.subsections');

    return (
      <NavLink
        ref={ref}
        to={`${EXPERIENCE_PATH}/${experienceId}/${path}`}
        className={({ isActive }) =>
          mergeClasses(
            'flex h-12 min-w-[180px] max-w-[220px] items-center px-3 border-l-4 border-transparent border-t border-t-black-12 font-bold uppercase gap-4',
            {
              'border-l-orange': isActive,
              'bg-black-64': isActive && variant === 'header',
              'bg-offWhite': isActive && variant === 'sidebar',
              'text-white ui-active:bg-black-64': variant === 'header',
              'text-black ui-active:bg-offWhite hover:bg-offWhite': variant === 'sidebar',
            },
          )
        }
        {...props}>
        {({ isActive }) => (
          <>
            <Icon
              name={isActive ? activeIcon : inactiveIcon}
              color={variant === 'header' ? 'white' : 'black'}
              size={24}
            />
            <Text size="sm">{t(name)}</Text>
          </>
        )}
      </NavLink>
    );
  },
);
