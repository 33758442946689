import { forwardRef } from 'react';
import classNames from 'classnames';
import { Label, mergeClasses, Text } from '@goosechase/ui';

import { type ExperienceListFilter } from 'data/experiences';
import { useTranslate } from '../../util/i18n';
import { MenuItem } from '@headlessui/react';
import { PersonalExperienceFilter } from '../../data/experiences/experiences.slice';

interface PersonalFilterOptionProps {
  className?: string;
  label: string;
  isActive: boolean;
  onClick: () => void;
}

/*
  Note: The use of forwardRef and prop spreading is present to satisfy HeadlessUI props and functionality
*/
const PersonalFilterOption = forwardRef<HTMLButtonElement, PersonalFilterOptionProps>(
  function PersonalOption({ className, label, isActive, onClick, ...props }, ref) {
    return (
      <button
        ref={ref}
        className={mergeClasses('text-left w-fit', className)}
        onClick={onClick}
        {...props}>
        <Text
          className={classNames('cursor-pointer w-fit', {
            'text-vibrantBlue font-semibold': isActive,
          })}>
          {label}
        </Text>
      </button>
    );
  },
);

const isFilterActive = (
  filterValue: PersonalExperienceFilter,
  selectedFilter: ExperienceListFilter,
): boolean => {
  if (selectedFilter.type === 'personal') {
    return selectedFilter.value === filterValue;
  }
  return false;
};

interface PersonalFiltersProps {
  isDropdown: boolean;
  selectedFilter: ExperienceListFilter;
  onSetFilter: (filter: ExperienceListFilter) => void;
}

export const PersonalFilters = ({
  isDropdown,
  selectedFilter,
  onSetFilter,
}: PersonalFiltersProps) => {
  const { t } = useTranslate('experienceListFilter');

  const handleClick = (value: PersonalExperienceFilter) => {
    onSetFilter({ type: 'personal', value });
  };

  if (isDropdown) {
    return (
      <div className="flex flex-col pb-4">
        <Label className="uppercase mb-5">{t('personal.title')}</Label>
        <div className="flex flex-col gap-3">
          <MenuItem>
            <PersonalFilterOption
              className="w-full ui-active:bg-black-12"
              label={t('personal.myExperiences')}
              isActive={isFilterActive('personal', selectedFilter)}
              onClick={() => handleClick('personal')}
            />
          </MenuItem>
          <MenuItem>
            <PersonalFilterOption
              className="w-full ui-active:bg-black-12"
              label={t('personal.sharedWithMe')}
              isActive={isFilterActive('shared', selectedFilter)}
              onClick={() => handleClick('shared')}
            />
          </MenuItem>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col pb-5">
      <Label className="uppercase mb-5">{t('personal.title')}</Label>
      <div className="flex flex-col gap-3">
        <PersonalFilterOption
          label={t('personal.myExperiences')}
          isActive={isFilterActive('personal', selectedFilter)}
          onClick={() => handleClick('personal')}
        />
        <PersonalFilterOption
          label={t('personal.sharedWithMe')}
          isActive={isFilterActive('shared', selectedFilter)}
          onClick={() => handleClick('shared')}
        />
      </div>
    </div>
  );
};
