import { useToggleLikeSubmissionMutation } from 'data/submissions';
import { Card } from 'components/card.component';
import { LoadingSpinner } from 'components/loading-spinner';
import { ErrorState } from 'components/error-state';
import { SubmissionsEmptyState } from 'components/submissions-empty-states.component';
import { FeedSubmissionCard } from './feed-submission-card.component';
import { displayToast } from 'services/toast';
import { useTranslate } from 'util/i18n';
import { useDeleteSubmissionBonus } from 'hooks/use-delete-submission-bonus.hook';
import { useDeleteSubmissionModal } from 'hooks/use-delete-submission-modal.hook';
import { DeleteSubmissionModal } from 'components/delete-submission-modal';
import { convertGqlFeedSubmissionToUIFeedSubmission } from './feed.util';
import { useSubmissionFeed } from 'hooks/use-submission-feed';
import { SubmissionFocusedViewModal } from '../../components/submission-focused-view-modal';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { useSubmissionFocusedViewModal } from 'hooks/use-submission-focused-view-modal-hook';

export const Feed = () => {
  const {
    isLoading,
    isFetching,
    data,
    totalCount,
    isError,
    thresholdElementRef,
    toggleSubmissionLike,
    deleteSubmission,
    handleAddSubmissionBonus,
  } = useSubmissionFeed();
  const [toggleLike] = useToggleLikeSubmissionMutation();
  const { experienceId } = useExperienceInfo();
  const deleteSubmissionBonus = useDeleteSubmissionBonus();
  const {
    show,
    submissionId: deleteSubmissionId,
    missionName,
    onClose,
    onShow,
  } = useDeleteSubmissionModal();
  const {
    submissionId,
    bonusFocus,
    setBonusFocus,
    handleSubmissionClick,
    handleBonusClick,
    setFocusedSubmissionId,
  } = useSubmissionFocusedViewModal();

  const { t } = useTranslate('likeButton');

  if (isLoading) {
    return (
      <Card className="flex justify-center p-12">
        <LoadingSpinner />
      </Card>
    );
  }

  if (totalCount === 0) {
    return <SubmissionsEmptyState />;
  }

  if (isError) {
    return (
      <Card className="flex justify-center p-12">
        <ErrorState />
      </Card>
    );
  }

  const handleClickLike = (id: string, isLiked: boolean) => async () => {
    try {
      await toggleLike({ id }).unwrap();
      toggleSubmissionLike(id);
    } catch (e) {
      const toastKey = isLiked ? 'removeLikeError' : 'likeError';
      displayToast({
        id,
        title: t(`toasts.${toastKey}.title`),
        type: 'error',
        body: t(`toasts.${toastKey}.body`),
      });
    }
  };

  const handleClose = (isDeleted: boolean) => {
    if (isDeleted) {
      deleteSubmission(deleteSubmissionId);
    }
    onClose();
  };

  const submissions = convertGqlFeedSubmissionToUIFeedSubmission(data);

  const currentFocusedSubmission = submissions.find(
    (submission) => submission.submissionId === submissionId,
  );

  return (
    <Card className="flex flex-col gap-6 p-5">
      {submissions.map((submission, index) => (
        <div key={submission.submissionId} className="flex flex-col gap-6 submission-card">
          <FeedSubmissionCard
            {...submission}
            onClickLike={handleClickLike(submission.submissionId, submission.isLiked)}
            onDeleteSubmissionBonus={deleteSubmissionBonus}
            onDelete={() => onShow(submission.submissionId, submission.missionName)}
            onSubmissionClick={handleSubmissionClick}
            onClickBonus={handleBonusClick}
          />
          {index !== submissions.length - 1 ? <div className="w-full h-px bg-black-12" /> : null}
        </div>
      ))}
      {isFetching ? (
        <>
          <div className="w-full h-px bg-black-12" />
          <div className="flex justify-center p-12">
            <LoadingSpinner />
          </div>
        </>
      ) : null}
      <div ref={thresholdElementRef} />
      <DeleteSubmissionModal
        show={show}
        submissionId={deleteSubmissionId}
        missionName={missionName}
        onClose={handleClose}
      />
      <SubmissionFocusedViewModal
        experienceId={experienceId}
        setCurrentFocusedSubmissionId={setFocusedSubmissionId}
        currentFocusedSubmission={currentFocusedSubmission}
        submissions={submissions}
        autoFocusBonus={bonusFocus}
        setAutoFocusBonus={setBonusFocus}
        onAddBonus={handleAddSubmissionBonus}
      />
    </Card>
  );
};
