import { SplitButtonWithDropdown } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import { MissionTypeDropdownOption } from '../../components/mission-type-dropdown-option';
import { MissionType, MISSION_TYPES } from '../../data/models';

interface MissionTypeOption {
  id: string;
  bordered?: boolean;
}

const renderMissionTypeDropdownOption = (option: MissionTypeOption) => (
  <MissionTypeDropdownOption className="whitespace-nowrap" missionType={option.id as MissionType} />
);

const options: MissionTypeOption[] = [
  ...MISSION_TYPES.map((type) => ({
    id: type,
    bordered: false,
  })),
  { id: 'LIBRARY', bordered: true },
];

interface CreateMissionButtonProps {
  onSelectMissionType: (type: MissionType) => void;
  onMissionLibraryClick: () => void;
  defaultMissionType: MissionType;
}

export const CreateMissionButton = (props: CreateMissionButtonProps) => {
  const { t } = useTranslate('pages.missions');

  const handleOnClick = () => {
    props.onSelectMissionType(props.defaultMissionType);
  };

  const handleOptionClick = (type: string) => {
    if (type === 'LIBRARY') {
      props.onMissionLibraryClick();
    } else {
      props.onSelectMissionType(type as MissionType);
    }
  };

  return (
    <SplitButtonWithDropdown
      label={t('addAMission')}
      dropdownAriaLabel={t('dropdownAriaLabel')}
      options={options}
      renderOption={renderMissionTypeDropdownOption}
      onClick={handleOnClick}
      onOptionClick={handleOptionClick}
    />
  );
};
