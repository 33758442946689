import { mergeClasses, Label, Text } from '@goosechase/ui';
import { BroadcastSendOption, BroadcastTriggerTiming } from 'data/models';
import { Trans } from 'react-i18next';
import {
  formatSpecificTime,
  TIME_ANCHOR_TO_TRANSLATION_KEY_MAP,
  TIME_UNIT_TO_TRANSLATION_KEY_MAP,
} from '../../../util/time-util';
import { Control, useFormState, useWatch } from 'react-hook-form';
import { useTranslate } from 'util/i18n';
import { BroadcastFormInputData } from '../use-broadcast-form';

type BroadcastTriggerSummaryProps = {
  control: Control<BroadcastFormInputData>;
  isInFuture: boolean;
};

// eslint-disable-next-line complexity
export const BroadcastTriggerSummary = ({ control, isInFuture }: BroadcastTriggerSummaryProps) => {
  const { t } = useTranslate('broadcastForm');

  const { errors } = useFormState({ control });
  const sendTiming = useWatch({ control, name: `sendTiming` });

  const beforeExperienceDuration = useWatch({ control, name: `beforeExperienceDuration` });
  const beforeExperienceUnit = useWatch({ control, name: `beforeExperienceUnit` });
  const duringTiming = useWatch({ control, name: `duringExperienceTiming` });
  const relativeDuration = useWatch({ control, name: `relativeDuration` });
  const relativeUnit = useWatch({ control, name: `relativeUnit` });
  const relativeAnchor = useWatch({ control, name: `relativeAnchor` });
  const specificDay = useWatch({ control, name: `specificDay` });
  const specificTime = useWatch({ control, name: `specificTime` });
  const afterExperienceDuration = useWatch({ control, name: `afterExperienceDuration` });
  const afterExperienceUnit = useWatch({ control, name: `afterExperienceUnit` });

  const isRelative = duringTiming === BroadcastTriggerTiming.Relative;
  const isSpecific = duringTiming === BroadcastTriggerTiming.Specific;

  let summaryMessage;
  let errorMessage;

  const hasErrors =
    errors.beforeExperienceDuration?.message ||
    errors.beforeExperienceUnit?.message ||
    errors.relativeAnchor?.message ||
    errors.relativeDuration?.message ||
    errors.relativeUnit?.message ||
    errors.specificDay?.message ||
    errors.specificTime?.message ||
    errors.afterExperienceDuration?.message ||
    errors.afterExperienceUnit?.message ||
    errors.sendTiming?.message;

  if (sendTiming === BroadcastSendOption.BeforeExperience) {
    if (typeof beforeExperienceDuration === 'number' && beforeExperienceUnit) {
      summaryMessage = (
        <Text>
          <Trans
            t={t}
            i18nKey={`broadcastTrigger.relativeSummary`}
            values={{
              anchor: t(`broadcastTrigger.relativeAnchor.beforeStart`),
              time: t(
                `broadcastTrigger.relativeUnit.${TIME_UNIT_TO_TRANSLATION_KEY_MAP[
                  beforeExperienceUnit
                ].toLowerCase()}_${beforeExperienceDuration === 1 ? 'one' : 'other'}`,
                {
                  count: beforeExperienceDuration,
                },
              ),
            }}
          />
        </Text>
      );
    }
    errorMessage =
      errors.beforeExperienceDuration?.message || errors.beforeExperienceUnit?.message ? (
        <Text size="md">
          <span
            dangerouslySetInnerHTML={{
              __html:
                errors.beforeExperienceDuration?.message ||
                errors.beforeExperienceUnit?.message ||
                '',
            }}
          />
        </Text>
      ) : null;
  }

  if (sendTiming === BroadcastSendOption.AtStartOfExperience) {
    summaryMessage = (
      <Text>
        <Trans
          t={t}
          i18nKey={`broadcastTrigger.atStartOrEndSummary`}
          values={{
            anchor: t(`broadcastTrigger.relativeAnchor.atStart`),
          }}
        />
      </Text>
    );
  }

  if (isRelative) {
    if (typeof relativeDuration === 'number' && relativeUnit && relativeAnchor) {
      summaryMessage = (
        <Text>
          <Trans
            t={t}
            i18nKey={`broadcastTrigger.relativeSummary`}
            values={{
              anchor: t(
                `broadcastTrigger.relativeAnchor.${TIME_ANCHOR_TO_TRANSLATION_KEY_MAP[relativeAnchor]}`,
              ),
              time: t(
                `broadcastTrigger.relativeUnit.${TIME_UNIT_TO_TRANSLATION_KEY_MAP[
                  relativeUnit
                ].toLowerCase()}_${relativeDuration === 1 ? 'one' : 'other'}`,
                {
                  count: relativeDuration,
                },
              ),
            }}
          />
        </Text>
      );
    }
    errorMessage =
      errors.relativeUnit?.message ||
      errors.relativeDuration?.message ||
      errors.relativeAnchor?.message ? (
        <Text size="md">
          <span
            dangerouslySetInnerHTML={{
              __html:
                errors.relativeUnit?.message ||
                errors.relativeDuration?.message ||
                errors.relativeAnchor?.message ||
                '',
            }}
          />
        </Text>
      ) : null;
  }

  if (isSpecific) {
    if (typeof specificDay === 'number' && specificTime) {
      summaryMessage = (
        <Text>
          <Trans
            t={t}
            i18nKey={`broadcastTrigger.specificSummary`}
            values={{
              day: specificDay,
              time: formatSpecificTime(specificTime),
            }}
          />
        </Text>
      );
    }
    errorMessage =
      errors.specificDay?.message || errors.specificTime?.message ? (
        <Text size="md">
          <span
            dangerouslySetInnerHTML={{
              __html: errors.specificDay?.message || errors.specificTime?.message || '',
            }}
          />
        </Text>
      ) : null;
  }

  if (sendTiming === BroadcastSendOption.AtEndOfExperience) {
    summaryMessage = (
      <Text>
        <Trans
          t={t}
          i18nKey={`broadcastTrigger.atStartOrEndSummary`}
          values={{
            anchor: t(`broadcastTrigger.relativeAnchor.atEnd`),
          }}
        />
      </Text>
    );
  }

  if (sendTiming === BroadcastSendOption.AfterExperience) {
    if (typeof afterExperienceDuration === 'number' && afterExperienceUnit) {
      summaryMessage = (
        <Text>
          <Trans
            t={t}
            i18nKey={`broadcastTrigger.relativeSummary`}
            values={{
              anchor: t(`broadcastTrigger.relativeAnchor.afterEnd`),
              time: t(
                `broadcastTrigger.relativeUnit.${TIME_UNIT_TO_TRANSLATION_KEY_MAP[
                  afterExperienceUnit
                ].toLowerCase()}_${afterExperienceDuration === 1 ? 'one' : 'other'}`,
                {
                  count: afterExperienceDuration,
                },
              ),
            }}
          />
        </Text>
      );
    }
    errorMessage =
      errors.afterExperienceDuration?.message || errors.afterExperienceUnit?.message ? (
        <Text size="md">
          <span
            dangerouslySetInnerHTML={{
              __html:
                errors.afterExperienceDuration?.message ||
                errors.afterExperienceUnit?.message ||
                '',
            }}
          />
        </Text>
      ) : null;
  }

  return (
    <div
      className={mergeClasses('flex flex-col justify-between gap-3', {
        'border-vibrantRed': hasErrors,
      })}>
      <Label size="lg">{t(`sendTiming.title`).toUpperCase()}</Label>
      {summaryMessage}

      {isInFuture || hasErrors ? null : (
        <div className="bg-yellowOrange-10 border border-yellowOrange rounded-lg p-4">
          <Trans>{t('broadcastTrigger.warnings.past')}</Trans>
        </div>
      )}

      {hasErrors ? (
        <div className="bg-vibrantRed-10 border border-vibrantRed rounded-lg p-4">
          {errorMessage}
        </div>
      ) : null}
    </div>
  );
};
