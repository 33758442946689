import { ExperienceCard } from '../../components/experience-card';
import { CohortState } from '../../data/models';
import { Nullable } from '../../types/util';
import { LiveExperienceLimitBanner } from './live-experience-limit-banner.component';

export interface ExperienceFields {
  id: string;
  displayName: string;
  shareLink: Nullable<string>;
  description: string;
  photoUrl?: string;
  status: CohortState;
  addons: { id: string; maxTeamsPerExperience: Nullable<number> }[];
  roleId?: string;
  workspacePlanUpgradable: boolean;
}

type ExperienceListProps = {
  experiences: ExperienceFields[];
};
export const ExperienceList = ({ experiences }: ExperienceListProps) => {
  const activeExperiences = experiences.filter(
    (experience) => experience.status === CohortState.Active,
  );
  return (
    <div className="flex flex-col gap-6 mb-28">
      {activeExperiences.map(
        ({
          id,
          displayName,
          description,
          photoUrl,
          status,
          addons,
          roleId,
          shareLink,
          workspacePlanUpgradable,
        }) => {
          return (
            <ExperienceCard
              className="w-full"
              key={id}
              experienceId={id}
              title={displayName}
              description={description}
              srcImage={photoUrl}
              status={status}
              addons={addons}
              roleId={roleId}
              canUpgradeWorkspace={workspacePlanUpgradable}
              shareLink={shareLink}
            />
          );
        },
      )}
      <LiveExperienceLimitBanner liveExperiencesCount={activeExperiences.length} />
      {experiences
        .filter((experience) => experience.status !== CohortState.Active)
        .map(
          ({
            id,
            displayName,
            description,
            photoUrl,
            status,
            addons,
            roleId,
            shareLink,
            workspacePlanUpgradable,
          }) => {
            return (
              <ExperienceCard
                className="w-full"
                key={id}
                experienceId={id}
                title={displayName}
                description={description}
                srcImage={photoUrl}
                status={status}
                addons={addons}
                roleId={roleId}
                canUpgradeWorkspace={workspacePlanUpgradable}
                shareLink={shareLink}
              />
            );
          },
        )}
    </div>
  );
};
