import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoutes } from '../constants/routes';

interface ForgotPasswordRedirectState {
  isExpiredLinkRedirect?: true;
}

export const useRedirectFromExpiredLink = (): (() => void) => {
  const navigate = useNavigate();

  const redirectFromExpiredLink = useCallback(() => {
    navigate(AppRoutes.FORGOT_PASSWORD, {
      state: {
        isExpiredLinkRedirect: true,
      },
    });
  }, [navigate]);

  return redirectFromExpiredLink;
};

export const useIsRedirectedFromExpiredLink = (): boolean => {
  const location = useLocation();
  const state = (location.state as ForgotPasswordRedirectState) ?? {};
  return state.isExpiredLinkRedirect ?? false;
};
