/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

module.hot?.accept();
export type SubmissionFragment_CameraSubmission_Fragment = { __typename: 'CameraSubmission', caption: string | null, id: string, createdAt: string, bonusPoints: number, numLikes: number, liked: boolean, assets: Array<{ __typename: 'SubmissionAsset', id: string, url: string, role: Types.SubmissionAssetRole }>, bonuses: Array<{ __typename: 'SubmissionBonus', id: string, value: number, note: string | null, createdAt: string }> };

export type SubmissionFragment_GpsSubmission_Fragment = { __typename: 'GpsSubmission', caption: string | null, latitude: string, longitude: string, id: string, createdAt: string, bonusPoints: number, numLikes: number, liked: boolean, bonuses: Array<{ __typename: 'SubmissionBonus', id: string, value: number, note: string | null, createdAt: string }> };

export type SubmissionFragment_TextSubmission_Fragment = { __typename: 'TextSubmission', answer: string, id: string, createdAt: string, bonusPoints: number, numLikes: number, liked: boolean, bonuses: Array<{ __typename: 'SubmissionBonus', id: string, value: number, note: string | null, createdAt: string }> };

export type SubmissionFragmentFragment = SubmissionFragment_CameraSubmission_Fragment | SubmissionFragment_GpsSubmission_Fragment | SubmissionFragment_TextSubmission_Fragment;

export const SubmissionFragmentFragmentDoc = `
    fragment SubmissionFragment on Submission {
  __typename
  id
  createdAt
  ... on CameraSubmission {
    caption
    assets {
      id
      url
      role
    }
  }
  ... on TextSubmission {
    answer
  }
  ... on GpsSubmission {
    caption
    latitude
    longitude
  }
  bonusPoints
  numLikes
  liked
  bonuses {
    id
    value
    note
    createdAt
  }
}
    `;
