import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { getExperienceSubPageTitleOptions } from '../utils';
import { PageHeadline } from '../../components/page-headline.component';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { Statistics } from 'components/statistics';

export const StatsPage = () => {
  const { t } = useTranslate('pages.stats');
  const { data } = useExperienceInfo();
  const displayName = data?.experience?.displayName;

  return (
    <Page title={t('meta.title', getExperienceSubPageTitleOptions(displayName))}>
      <PageHeadline>{t('title')}</PageHeadline>
      <div className="flex flex-col tablet-wide:flex-row gap-4">
        <div className="w-full h-fit my-6">
          <Statistics />
        </div>
      </div>
    </Page>
  );
};
