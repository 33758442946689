import classNames from 'classnames';

import { ReactComponent as GoosechaseTextLogo } from 'media/goosechase-logo-dark.svg';
import { ReactComponent as GoosechaseIconLogo } from 'media/goosechase-icon-dark.svg';
import { AppRoutes } from 'constants/routes';
import { mergeClasses } from '@goosechase/ui';
import { type NavBarVariant } from './header-nav-bar.component';
import { useScreenSize } from '../../hooks/use-screen-size';
import { Link } from '../link.component';
import { useTranslate } from '../../util/i18n';

export interface ExperienceManagerLogoProps {
  className?: string;
  variant?: NavBarVariant;
}

export const GoosechaseLogo = ({ className, variant = 'default' }: ExperienceManagerLogoProps) => {
  const { isDesktop } = useScreenSize();
  const IconComponent = isDesktop ? GoosechaseTextLogo : GoosechaseIconLogo;
  const { t } = useTranslate('headerNavBar.logo');

  return (
    <Link
      className={classNames('flex flex-col items-end', className)}
      useRouter
      href={AppRoutes.MY_EXPERIENCES}
      aria-label={t('ariaLabel')}>
      <IconComponent
        className={mergeClasses('h-8 desktop:h-[26px]', {
          'stroke-white fill-white': variant === 'experience',
        })}
      />
    </Link>
  );
};
