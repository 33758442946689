import { api as _experienceMissionsApi } from './graphql/__generated__/experience-missions.query.generated';
import { api as _createCameraMissionApi } from './graphql/__generated__/create-camera-mission.mutation.generated';
import { api as _createTextMissionApi } from './graphql/__generated__/create-text-mission.mutation.generated';
import { api as _createGpsMissionApi } from './graphql/__generated__/create-gps-mission.mutation.generated';
import { api as _updateCameraMissionApi } from './graphql/__generated__/update-camera-mission.mutation.generated';
import { api as _updateTextMissionApi } from './graphql/__generated__/update-text-mission.mutation.generated';
import { api as _updateGpsMissionApi } from './graphql/__generated__/update-gps-mission.mutation.generated';
import { api as _deleteMissionApi } from './graphql/__generated__/delete-mission.mutation.generated';
import { api as _updateMissionCustomOrderPositionApi } from './graphql/__generated__/update-mission-custom-order-position.mutation.generated';
import {
  api as _missionTriggersApi,
  MissionTriggersQuery,
} from './graphql/__generated__/mission-triggers.query.generated';
import { AppState } from '../store';
import { api as _myPreviousMissionsApi } from './graphql/__generated__/my-previous-missions.query.generated';
import { api as _addMissionFromMissionLibraryApi } from './graphql/__generated__/add-mission-from-mission-library.mutation.generated';
import { api as _mySavedMissionsApi } from './graphql/__generated__/my-saved-missions.query.generated';
import { api as _saveMissionApi } from './graphql/__generated__/save-mission.mutation.generated';
import { api as _deleteSavedMissionApi } from './graphql/__generated__/delete-saved-mission.mutation.generated';

export type { MissionTriggersQuery };

export const experienceMissionsApi = _experienceMissionsApi.enhanceEndpoints({
  endpoints: {
    ExperienceMissions: {
      providesTags: ['ExperienceMissions'],
    },
  },
});

export const missionTriggersApi = _missionTriggersApi.enhanceEndpoints({
  endpoints: {
    MissionTriggers: {
      providesTags: ['MissionTriggers'],
    },
  },
});

export const createCameraMissionApi = _createCameraMissionApi.enhanceEndpoints({
  endpoints: {
    CreateCameraMission: {
      invalidatesTags: ['ExperienceMissions'],
      async onQueryStarted({ params: { experienceId } }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const activeCohortId = (getState() as AppState).experiences.activeCohortId ?? '';

          dispatch(
            experienceMissionsApi.util.updateQueryData(
              'ExperienceMissions',
              { id: experienceId, cohortId: activeCohortId },
              (draft) => {
                if (draft) {
                  return {
                    ...draft,
                    experienceMissions: [
                      ...draft.experienceMissions,
                      {
                        ...data.createCameraMission,
                        numSubmissions: 0,
                        status: null,
                        releaseAt: null,
                        expireAt: null,
                      },
                    ],
                  };
                }
              },
            ),
          );
        } catch {
          // Do nothing. Try/catch required since we're awaiting on queryFulfilled.
          // Error handling should be done at the call site.
        }
      },
    },
  },
});

export const createTextMissionApi = _createTextMissionApi.enhanceEndpoints({
  endpoints: {
    CreateTextMission: {
      invalidatesTags: ['ExperienceMissions'],
      async onQueryStarted({ params: { experienceId } }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const activeCohortId = (getState() as AppState).experiences.activeCohortId ?? '';

          dispatch(
            experienceMissionsApi.util.updateQueryData(
              'ExperienceMissions',
              { id: experienceId, cohortId: activeCohortId },
              (draft) => {
                if (draft) {
                  return {
                    ...draft,
                    experienceMissions: [
                      ...draft.experienceMissions,
                      {
                        ...data.createTextMission,
                        numSubmissions: 0,
                        status: null,
                        releaseAt: null,
                        expireAt: null,
                      },
                    ],
                  };
                }
              },
            ),
          );
        } catch {
          // Do nothing. Try/catch required since we're awaiting on queryFulfilled.
          // Error handling should be done at the call site.
        }
      },
    },
  },
});

export const createGpsMissionApi = _createGpsMissionApi.enhanceEndpoints({
  endpoints: {
    CreateGpsMission: {
      invalidatesTags: ['ExperienceMissions'],
      async onQueryStarted({ params: { experienceId } }, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const activeCohortId = (getState() as AppState).experiences.activeCohortId ?? '';

          dispatch(
            experienceMissionsApi.util.updateQueryData(
              'ExperienceMissions',
              { id: experienceId, cohortId: activeCohortId },
              (draft) => {
                if (draft) {
                  return {
                    ...draft,
                    experienceMissions: [
                      ...draft.experienceMissions,
                      {
                        ...data.createGpsMission,
                        numSubmissions: 0,
                        status: null,
                        releaseAt: null,
                        expireAt: null,
                      },
                    ],
                  };
                }
              },
            ),
          );
        } catch {
          // Do nothing. Try/catch required since we're awaiting on queryFulfilled.
          // Error handling should be done at the call site.
        }
      },
    },
  },
});

export const updateCameraMissionApi = _updateCameraMissionApi.enhanceEndpoints({
  endpoints: {
    UpdateCameraMission: {
      invalidatesTags: ['ExperienceMissions'],
      async onQueryStarted(
        { params: { id: updatedMissionId } },
        { dispatch, queryFulfilled, getState },
      ) {
        try {
          const {
            data: { updateCameraMission },
          } = await queryFulfilled;

          const activeExperienceId = (getState() as AppState).experiences.activeExperienceId ?? '';
          const activeCohortId = (getState() as AppState).experiences.activeCohortId ?? '';

          dispatch(
            experienceMissionsApi.util.updateQueryData(
              'ExperienceMissions',
              { id: activeExperienceId, cohortId: activeCohortId },
              (draft) => {
                if (draft) {
                  const experienceMissions = [...draft.experienceMissions];

                  const updatedIndex = experienceMissions.findIndex(
                    (mission) => mission.id === updatedMissionId,
                  );
                  if (updatedIndex > -1) {
                    experienceMissions[updatedIndex] = {
                      ...experienceMissions[updatedIndex],
                      ...updateCameraMission,
                    };
                  }

                  return {
                    ...draft,
                    experienceMissions,
                  };
                }
              },
            ),
          );
        } catch {
          // Do nothing. Try/catch required since we're awaiting on queryFulfilled.
          // Error handling should be done at the call site.
        }
      },
    },
  },
});

export const updateTextMissionApi = _updateTextMissionApi.enhanceEndpoints({
  endpoints: {
    UpdateTextMission: {
      invalidatesTags: ['ExperienceMissions'],
      async onQueryStarted(
        { params: { id: updatedMissionId } },
        { dispatch, queryFulfilled, getState },
      ) {
        try {
          const {
            data: { updateTextMission },
          } = await queryFulfilled;

          const activeExperienceId = (getState() as AppState).experiences.activeExperienceId ?? '';
          const activeCohortId = (getState() as AppState).experiences.activeCohortId ?? '';

          dispatch(
            experienceMissionsApi.util.updateQueryData(
              'ExperienceMissions',
              { id: activeExperienceId, cohortId: activeCohortId },
              (draft) => {
                if (draft) {
                  const experienceMissions = [...draft.experienceMissions];

                  const updatedIndex = experienceMissions.findIndex(
                    (mission) => mission.id === updatedMissionId,
                  );
                  if (updatedIndex > -1) {
                    experienceMissions[updatedIndex] = {
                      ...experienceMissions[updatedIndex],
                      ...updateTextMission,
                    };
                  }

                  return {
                    ...draft,
                    experienceMissions,
                  };
                }
              },
            ),
          );
        } catch {
          // Do nothing. Try/catch required since we're awaiting on queryFulfilled.
          // Error handling should be done at the call site.
        }
      },
    },
  },
});

export const updateGpsMissionApi = _updateGpsMissionApi.enhanceEndpoints({
  endpoints: {
    UpdateGpsMission: {
      invalidatesTags: ['ExperienceMissions'],
      async onQueryStarted(
        { params: { id: updatedMissionId } },
        { dispatch, queryFulfilled, getState },
      ) {
        try {
          const {
            data: { updateGpsMission },
          } = await queryFulfilled;

          const activeExperienceId = (getState() as AppState).experiences.activeExperienceId ?? '';
          const activeCohortId = (getState() as AppState).experiences.activeCohortId ?? '';

          dispatch(
            experienceMissionsApi.util.updateQueryData(
              'ExperienceMissions',
              { id: activeExperienceId, cohortId: activeCohortId },
              (draft) => {
                if (draft) {
                  const experienceMissions = [...draft.experienceMissions];

                  const updatedIndex = experienceMissions.findIndex(
                    (mission) => mission.id === updatedMissionId,
                  );
                  if (updatedIndex > -1) {
                    experienceMissions[updatedIndex] = {
                      ...experienceMissions[updatedIndex],
                      ...updateGpsMission,
                    };
                  }

                  return {
                    ...draft,
                    experienceMissions,
                  };
                }
              },
            ),
          );
        } catch {
          // Do nothing. Try/catch required since we're awaiting on queryFulfilled.
          // Error handling should be done at the call site.
        }
      },
    },
  },
});

export const deleteMissionApi = _deleteMissionApi.enhanceEndpoints({
  endpoints: {
    DeleteMission: {
      async onQueryStarted({ id: missionId }, { dispatch, getState, queryFulfilled }) {
        const activeExperienceId = (getState() as AppState).experiences.activeExperienceId ?? '';
        const activeCohortId = (getState() as AppState).experiences.activeCohortId ?? '';
        dispatch(
          experienceMissionsApi.util.updateQueryData(
            'ExperienceMissions',
            { id: activeExperienceId, cohortId: activeCohortId },
            (draft) => {
              if (draft) {
                return {
                  ...draft,
                  experienceMissions: draft.experienceMissions.filter(({ id }) => id !== missionId),
                };
              }
              return draft;
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          dispatch(experienceMissionsApi.util.invalidateTags(['ExperienceMissions']));
        }
      },
    },
  },
});

export const updateMissionCustomOrderPositionApi =
  _updateMissionCustomOrderPositionApi.enhanceEndpoints({
    endpoints: {
      UpdateMissionCustomOrderPosition: {
        async onQueryStarted(
          { updateMissionCustomOrderPositionId, newPosition },
          { dispatch, getState, queryFulfilled },
        ) {
          const activeExperienceId = (getState() as AppState).experiences.activeExperienceId ?? '';
          const activeCohortId = (getState() as AppState).experiences.activeCohortId ?? '';
          dispatch(
            experienceMissionsApi.util.updateQueryData(
              'ExperienceMissions',
              { id: activeExperienceId, cohortId: activeCohortId },
              (draft) => {
                if (draft) {
                  const fromIndex = draft.experienceMissions.findIndex(
                    (mission) => mission.id === updateMissionCustomOrderPositionId,
                  );
                  const element = draft.experienceMissions[fromIndex];
                  draft.experienceMissions.splice(fromIndex, 1);
                  // mission positions are not 0-based
                  draft.experienceMissions.splice(newPosition - 1, 0, element);
                }
                return draft;
              },
            ),
          );
          try {
            await queryFulfilled;
          } catch {
            dispatch(experienceMissionsApi.util.invalidateTags(['ExperienceMissions']));
          }
        },
      },
    },
  });

export const myPreviousMissionsApi = _myPreviousMissionsApi.enhanceEndpoints({
  endpoints: {
    MyPreviousMissions: {
      providesTags: ['MyPreviousMissions'],
    },
  },
});

export const addMissionFromMissionLibraryApi = _addMissionFromMissionLibraryApi.enhanceEndpoints({
  endpoints: {
    AddMissionFromMissionLibrary: {
      invalidatesTags: ['ExperienceMissions'],
    },
  },
});

export const mySavedMissionsApi = _mySavedMissionsApi.enhanceEndpoints({
  endpoints: {
    MySavedMissions: {
      providesTags: ['MySavedMissions'],
    },
  },
});

export const saveMissionApi = _saveMissionApi.enhanceEndpoints({
  endpoints: {
    SaveMission: {
      invalidatesTags: ['MySavedMissions'],
    },
  },
});

export const deleteSavedMissionApi = _deleteSavedMissionApi.enhanceEndpoints({
  endpoints: {
    DeleteSavedMission: {
      invalidatesTags: ['MySavedMissions'],
    },
  },
});
