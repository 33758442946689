import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useActiveExperienceId, useActiveCohortId } from '../../data/experiences';
import { useSelectedMission, useExperienceMissionsQuery } from '../../data/missions';
import { MissionTriggerTiming, MISSION_TRIGGER_TIMINGS } from '../../data/models';
import { useGatedMissionsFeatureFlag } from '../../hooks/use-feature-flag.hook';
import { useTranslate } from '../../util/i18n';
import { DropdownOptionType } from './dropdown-option.component';

export type TriggerType = 'releaseTrigger' | 'expireTrigger';

export type MissionTriggerType = MissionTriggerTiming | 'DEFAULT' | 'GATED'; // TODO: use enum from schema

const MISSION_TRIGGER_TIMING_TO_TRANSLATION_KEY_MAP: Record<MissionTriggerType, string> = {
  DEFAULT: 'default',
  RELATIVE: 'relative',
  SPECIFIC: 'specific',
  GATED: 'gated',
};

const getTranslationKey = (timing: MissionTriggerType, triggerType: TriggerType) =>
  `timing.${triggerType}.${MISSION_TRIGGER_TIMING_TO_TRANSLATION_KEY_MAP[timing]}`;

const convertMissionTriggerTimingToDropdownOption = (
  t: TFunction,
  triggerType: TriggerType,
  missionTriggerTiming: MissionTriggerType,
  isGatedTriggerValid: boolean,
): DropdownOptionType => {
  const baseKey = getTranslationKey(missionTriggerTiming, triggerType);
  const title = t(`${baseKey}.title`);
  const description = t(`${baseKey}.description`);

  if (missionTriggerTiming === 'GATED') {
    const descriptionWhenDisabled = t(`${baseKey}.disabledDescription`);
    return {
      id: missionTriggerTiming,
      title,
      description: isGatedTriggerValid ? description : descriptionWhenDisabled,
      disabled: !isGatedTriggerValid,
      icon: !isGatedTriggerValid ? 'PrivacyLock' : undefined,
    };
  }

  return { id: missionTriggerTiming, title, description };
};

type UseGatedMissionDropdownOptionsProps = {
  triggerType: TriggerType;
};

export const useMissionTriggerDropdownOptions = ({
  triggerType,
}: UseGatedMissionDropdownOptionsProps) => {
  const { t } = useTranslate('missionForm.missionTrigger');
  const selectedMission = useSelectedMission();
  const experienceId = useActiveExperienceId() ?? '';
  const cohortId = useActiveCohortId() ?? '';
  const { data } = useExperienceMissionsQuery(
    {
      id: experienceId,
      cohortId,
    },
    { skip: !cohortId || !experienceId },
  );
  const gatedMissionsEnabled = useGatedMissionsFeatureFlag();

  const missions = data?.experienceMissions ?? [];
  const isCreatingMission = selectedMission === null;
  const isEditingMission = Boolean(selectedMission?.id);

  const isGatedConditionMet = gatedMissionsEnabled && triggerType === 'releaseTrigger';

  const isGatedTriggerValid = useMemo(() => {
    return (
      (isCreatingMission && missions.length > 0) || (isEditingMission && missions.length > 1)
    );
  }, [isCreatingMission, isEditingMission, missions.length]);

  const optionKeys = useMemo(() => {
    const keys: MissionTriggerType[] = ['DEFAULT', ...MISSION_TRIGGER_TIMINGS];
    if (isGatedConditionMet) {
      keys.splice(1, 0, 'GATED');
    }
    return keys;
  }, [isGatedConditionMet]);

  const options = useMemo(
    () =>
      optionKeys.map((key) =>
        convertMissionTriggerTimingToDropdownOption(t, triggerType, key, isGatedTriggerValid),
      ),
    [optionKeys, t, triggerType, isGatedTriggerValid],
  );

  return options;
};
