import { Menu } from '@headlessui/react';
import { FloatingPortal, useFloating } from '@floating-ui/react';
import { ThemeColor, Icon, IconName, Text, mergeClasses } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import {
  useDeleteExperienceInvitationMutation,
  useDeleteExperienceManagerMutation,
  useResendExperienceInvitationMutation,
} from 'data/experiences';
import { displayToast } from 'services/toast';
import { Trans } from 'react-i18next';

interface DropdownOptionProps {
  label: string;
  icon: IconName;
  onClick: () => void;
  className?: string;
  iconColor?: ThemeColor;
}

const DropdownOption = ({ label, icon, onClick, className, iconColor }: DropdownOptionProps) => {
  return (
    <Menu.Item>
      {({ close }) => {
        const handleOnClick: React.HTMLAttributes<HTMLButtonElement>['onClick'] = (e) => {
          e.preventDefault();
          onClick();
          close();
        };

        return (
          <button
            className={mergeClasses(
              'w-full outline-0 pt-2 pb-2 text-left hover:bg-black-4 ui-active:bg-black-4 flex justify-start items-center',
              className,
            )}
            onClick={handleOnClick}>
            <Icon className="ml-3" name={icon} color={iconColor} />
            <Text className="px-3 py-1 pl-2 whitespace-nowrap font-semibold">{label}</Text>
          </button>
        );
      }}
    </Menu.Item>
  );
};

export interface CollaboratorDropdownProps {
  experienceId: string;
  invitationId?: string;
  experienceManagerUserId?: string;
  email: string;
}

export const CollaboratorDropdown = ({
  experienceId,
  invitationId,
  experienceManagerUserId,
  email,
}: CollaboratorDropdownProps) => {
  const { t } = useTranslate('experienceCard.inviteCollaboratorsModal');
  const [resendExperienceInvitation] = useResendExperienceInvitationMutation();
  const [deleteExperienceInvitation] = useDeleteExperienceInvitationMutation();
  const [deleteExperienceManager] = useDeleteExperienceManagerMutation();
  const { refs, floatingStyles } = useFloating({ placement: 'bottom-end' });

  const handleResendInvite = async () => {
    if (invitationId) {
      try {
        await resendExperienceInvitation({ invitationId }).unwrap();
        displayToast({
          body: <Trans t={t} i18nKey="toasts.resentSuccess.body" values={{ email }} />,
          id: invitationId,
          title: t('toasts.resentSuccess.title'),
          type: 'success',
        });
      } catch (error) {
        displayToast({
          body: <Trans t={t} i18nKey="toasts.resentError.body" values={{ email }} />,
          id: invitationId,
          title: t('toasts.resentError.title'),
          type: 'error',
        });
      }
    }
  };

  const handleRemoveInvite = async () => {
    if (invitationId) {
      try {
        await deleteExperienceInvitation({ invitationId }).unwrap();
        displayToast({
          body: <Trans t={t} i18nKey="toasts.removedSuccess.body" values={{ email }} />,
          id: invitationId,
          title: t('toasts.removedSuccess.title'),
          type: 'success',
        });
      } catch (error) {
        displayToast({
          body: <Trans t={t} i18nKey="toasts.removedError.body" values={{ email }} />,
          id: invitationId,
          title: t('toasts.removedError.title'),
          type: 'error',
        });
      }
    }
  };

  const handleRemoveCollaborator = async () => {
    if (experienceManagerUserId) {
      try {
        await deleteExperienceManager({ experienceId, experienceManagerUserId }).unwrap();
        displayToast({
          body: <Trans t={t} i18nKey="toasts.removedSuccess.body" values={{ email }} />,
          id: experienceManagerUserId,
          title: t('toasts.removedSuccess.title'),
          type: 'success',
        });
      } catch (error) {
        displayToast({
          body: <Trans t={t} i18nKey="toasts.removedError.body" values={{ email }} />,
          id: experienceManagerUserId,
          title: t('toasts.removedError.title'),
          type: 'error',
        });
      }
    }
  };

  return (
    <Menu as="div" className="relative" onClick={(event) => event.stopPropagation()}>
      <Menu.Button
        aria-label={t('collaborators.ariaIconLabel') ?? ''}
        className="group flex items-center justify-center rounded-full h-6 w-6 shrink-0 hover:bg-black-12"
        ref={refs.setReference}>
        <Icon
          name="More"
          color="black"
          className="group-hover:fill-black group-hover:stroke-black"
          size={24}
        />
      </Menu.Button>
      <FloatingPortal>
        <Menu.Items
          ref={refs.setFloating}
          className="cursor-pointer bg-white border border-black-24 rounded-lg outline-0 overflow-hidden z-[1000]"
          style={floatingStyles}>
          {invitationId ? (
            <DropdownOption
              label={t('collaborators.resend')}
              icon="Email"
              onClick={handleResendInvite}
            />
          ) : null}
          <DropdownOption
            label={t('collaborators.remove')}
            icon="DeleteBold"
            onClick={experienceManagerUserId ? handleRemoveCollaborator : handleRemoveInvite}
            className="text-vibrantRed"
            iconColor="vibrantRed"
          />
        </Menu.Items>
      </FloatingPortal>
    </Menu>
  );
};
