import { Card } from 'components/card.component';
import { ErrorState } from 'components/error-state';
import { LoadingSpinner } from 'components/loading-spinner';
import LiveForm from './live-form';
import EndedForm from './ended-form';
import DraftForm from './draft-form';
import ScheduledForm from './scheduled-form';
import { CohortState, ExperienceTaskType } from 'data/models';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { useActiveCohortId, useLoadExperienceStartAndEndQuery } from 'data/experiences';
import { useMemo } from 'react';
import { useMissionTriggersQuery } from 'data/missions';
import {
  convertGqlBroadcastsToUIBroadcastTriggers,
  convertGqlMissionTriggersToUIMissionTriggers,
} from './trigger.util';
import { useLoadBroadcastsForCohortQuery } from 'data/broadcasts';

// eslint-disable-next-line complexity
export const StartEndForm = () => {
  const { experienceId, data, isLoading, isError } = useExperienceInfo();

  const cohortId = useActiveCohortId();
  const experienceTimezone = data?.experience?.timezone ?? 'UTC';

  const {
    isLoading: isLoadingStartAndEndData,
    isError: isErrorStartAndEndData,
    data: startAndEndData,
  } = useLoadExperienceStartAndEndQuery({ id: cohortId ?? '' }, { skip: !cohortId });
  const { data: missionTriggersData } = useMissionTriggersQuery({
    id: experienceId,
    cohortId: cohortId ?? '',
  });
  const { data: broadcastTriggersData } = useLoadBroadcastsForCohortQuery(
    { cohortId: cohortId ?? '' },
    { skip: !cohortId },
  );

  const startTrigger = useMemo(
    () =>
      startAndEndData?.cohort?.triggers.find(
        (trigger) => trigger.taskType === ExperienceTaskType.StartExperience,
      ),
    [startAndEndData],
  );
  const endTrigger = useMemo(
    () =>
      startAndEndData?.cohort?.triggers.find(
        (trigger) => trigger.taskType === ExperienceTaskType.EndExperience,
      ),
    [startAndEndData],
  );

  const startDateTime = useMemo(
    () =>
      startAndEndData?.cohort?.startDate ? new Date(startAndEndData?.cohort?.startDate) : null,
    [startAndEndData],
  );

  const endDateTime = useMemo(
    () => (startAndEndData?.cohort?.endDate ? new Date(startAndEndData?.cohort?.endDate) : null),
    [startAndEndData],
  );

  if (isError || isErrorStartAndEndData) {
    return (
      <Card className="mt-4 p-5 w-full">
        <ErrorState />
      </Card>
    );
  }

  if (
    !data?.experience ||
    !startAndEndData?.cohort ||
    !cohortId ||
    isLoading ||
    (startAndEndData.cohort &&
      startAndEndData.cohort.status !== CohortState.Draft &&
      isLoadingStartAndEndData)
  ) {
    return (
      <Card className="mt-4 p-5 w-full">
        <div className="flex justify-center py-16 px-8">
          <LoadingSpinner />
        </div>
      </Card>
    );
  }

  const missionTriggers = convertGqlMissionTriggersToUIMissionTriggers(missionTriggersData);
  const broadcastTriggers = convertGqlBroadcastsToUIBroadcastTriggers(broadcastTriggersData);

  const workspace = data.experience.workspace;

  return (
    <>
      {startAndEndData.cohort.status === CohortState.Draft ? (
        <DraftForm
          experienceId={experienceId}
          cohortId={cohortId}
          missionTriggers={missionTriggers}
          broadcastTriggers={broadcastTriggers}
          workspace={workspace}
          experienceTimezone={experienceTimezone}
        />
      ) : null}
      {startAndEndData.cohort.status === CohortState.Active && startDateTime && endTrigger ? (
        <LiveForm
          cohortId={cohortId}
          startDateTime={startDateTime}
          startTrigger={startTrigger}
          endTrigger={endTrigger}
          missionTriggers={missionTriggers}
          broadcastTriggers={broadcastTriggers}
          experienceTimezone={experienceTimezone}
        />
      ) : null}
      {startAndEndData.cohort.status === CohortState.Scheduled && startTrigger && endTrigger ? (
        <ScheduledForm
          experienceId={experienceId}
          cohortId={cohortId}
          startTrigger={startTrigger}
          endTrigger={endTrigger}
          missionTriggers={missionTriggers}
          broadcastTriggers={broadcastTriggers}
          workspace={workspace}
          experienceTimezone={experienceTimezone}
        />
      ) : null}
      {startAndEndData.cohort.status === CohortState.Ended && startDateTime && endDateTime ? (
        <EndedForm
          experienceId={experienceId}
          startDateTime={startDateTime}
          endDateTime={endDateTime}
        />
      ) : null}
    </>
  );
};
