var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var ExperiencePlaceholder = function () { return (_jsxs("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 114 114", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("rect", { width: "114", height: "114", rx: "12", fill: "white" }), _jsx("path", { opacity: "0.06", d: "M102 0H12C5.37258 0 0 5.37258 0 12V102C0 108.627 5.37258 114 12 114H102C108.627 114 114 108.627 114 102V12C114 5.37258 108.627 0 102 0Z", fill: "#0C0A0C" }), _jsx("path", { d: "M75.8922 69.5514C75.8922 75.2457 74.1207 79.939 70.5947 83.6463C67.0607 87.3627 62.5326 89.2084 57.0036 89.2084C51.4746 89.2084 46.9385 87.3627 43.4125 83.6463C40.9877 81.0973 39.3894 78.0843 38.6279 74.6084L73.0263 61.6717L73.6225 61.4482L74.3008 61.1917C75.3599 63.6918 75.89 66.4802 75.89 69.5514H75.8922Z", fill: "white" }), _jsx("path", { d: "M55.8625 24.7904C50.7143 24.7904 46.4678 26.5289 43.1242 30.0219C39.7805 33.5068 38.1082 37.9015 38.1082 43.19C38.1082 48.4785 39.7714 52.9484 43.0911 56.4414C46.4096 59.9263 50.6732 61.6728 55.8625 61.6728C61.0518 61.6728 65.3234 59.9263 68.6419 56.4414C71.9605 52.9484 73.6249 48.5366 73.6249 43.19V24.7904H55.8625ZM63.5518 52.3271C61.4576 54.5125 58.9006 55.6046 55.8625 55.6046C52.8244 55.6046 50.2754 54.5125 48.1812 52.3271C46.087 50.1337 45.0359 47.0877 45.0359 43.1889C45.0359 39.2901 46.087 36.3272 48.1812 34.1338C50.2754 31.9405 52.8324 30.8484 55.8625 30.8484C58.8926 30.8484 61.4576 31.9405 63.5518 34.1338C65.646 36.3272 66.6971 39.3402 66.6971 43.1889C66.6971 47.0375 65.646 50.1337 63.5518 52.3271Z", fill: "white" }), _jsx("path", { d: "M73.0286 61.6728L73.6236 61.4482V61.6728H73.0286Z", fill: "white" })] }))); };
