import { useDeleteTeamBonusMutation } from 'data/teams';
import { displayToast } from 'services/toast';
import { useTranslate } from 'util/i18n';

export const useDeleteTeamBonus = () => {
  const { t } = useTranslate('teamBonusForm');
  const [deleteTeamBonus] = useDeleteTeamBonusMutation();

  return async (id: string) => {
    try {
      await deleteTeamBonus({
        id,
      }).unwrap();
      displayToast({
        id,
        title: t(`toasts.deleteSuccess.title`),
        type: 'success',
        body: t(`toasts.deleteSuccess.body`),
      });
    } catch {
      displayToast({
        id,
        title: t(`toasts.deleteError.title`),
        type: 'error',
        body: t(`toasts.deleteError.body`),
      });
    }
  };
};
