/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type SubmitEducatorProfileMutationVariables = Types.Exact<{
  params: Types.InputMaybe<Types.CreateEducatorProfileInput>;
}>;


export type SubmitEducatorProfileMutation = { __typename: 'Mutation', createEducatorProfile: { __typename: 'EducatorProfile', id: string, firstName: string, lastName: string, position: Types.EducatorPosition, schoolName: string, schoolLevel: Types.SchoolLevel | null, schoolDistrict: string } };


export const SubmitEducatorProfileDocument = `
    mutation SubmitEducatorProfile($params: CreateEducatorProfileInput) {
  createEducatorProfile(params: $params) {
    __typename
    id
    firstName
    lastName
    position
    schoolName
    schoolLevel
    schoolDistrict
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    SubmitEducatorProfile: build.mutation<SubmitEducatorProfileMutation, SubmitEducatorProfileMutationVariables | void>({
      query: (variables) => ({ document: SubmitEducatorProfileDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSubmitEducatorProfileMutation } = injectedRtkApi;

