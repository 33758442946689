import { Tooltip, Label, mergeClasses } from '@goosechase/ui';
import { Trans } from 'react-i18next';
import { Nullable } from 'types/util';
import { useTranslate } from 'util/i18n';

type PlanBadgeProps = {
  planId: string;
  maxConcurrentExperiences: Nullable<number>;
  maxTeamsPerExperience: Nullable<number>;
  maxParticipantsPerExperience: Nullable<number>;
};

const Badge = (props: PlanBadgeProps) => {
  const { t } = useTranslate('currentPlan');
  return (
    <div
      className={mergeClasses('block px-2 py-1 rounded-md w-max bg-black-4', {
        'bg-cyan':
          props.planId === 'department-wide-direct' || props.planId === 'department-wide-ecommerce',
        'bg-paleGreen': props.planId === 'enterprise',
        'bg-lilac': props.planId === 'npo',
        'bg-yellowOrange': props.planId === 'edu-plus-40' || props.planId === 'edu-plus-50',
        'bg-limeGreen': props.planId === 'school-500',
        'bg-orange': props.planId === 'edu-unlimited',
      })}>
      <Label className="uppercase whitespace-nowrap" size="sm">
        {t(`${props.planId}.title`)}
      </Label>
    </div>
  );
};

export const PlanBadge = (props: PlanBadgeProps) => {
  const { t } = useTranslate('currentPlan');

  return (
    <Tooltip
      body={
        <span>
          {t('tooltip.title')}
          <br />
          <br />

          {typeof props.maxTeamsPerExperience === 'number' ? (
            <span className="block">
              <Trans
                t={t}
                i18nKey="tooltip.teams"
                values={{ count: props.maxTeamsPerExperience }}
              />
            </span>
          ) : null}
          {typeof props.maxParticipantsPerExperience === 'number' ? (
            <span className="block">
              <Trans
                t={t}
                i18nKey="tooltip.participants"
                values={{ count: props.maxParticipantsPerExperience }}
              />
            </span>
          ) : null}

          {props.maxTeamsPerExperience === null && props.maxParticipantsPerExperience === null ? (
            <span className="block">
              <Trans t={t} i18nKey="tooltip.participantsUnlimited" />
            </span>
          ) : null}

          <span className="block">
            <Trans
              t={t}
              i18nKey={
                typeof props.maxConcurrentExperiences === 'number'
                  ? 'tooltip.experiences'
                  : 'tooltip.experiencesUnlimited'
              }
              values={{ count: props.maxConcurrentExperiences }}
            />
          </span>
        </span>
      }
      className="max-w-[272px] w-max">
      <Badge {...props} />
    </Tooltip>
  );
};
