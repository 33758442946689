/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type LoadStatisticsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type LoadStatisticsQuery = { __typename: 'Query', cohort: { __typename: 'Cohort', statistics: { __typename: 'CohortStatistics', activeTeams: number | null, totalTeams: number | null, totalSubmissions: number | null, averageMissionCompletion: string | null } | null } | null, submissionCountByMission: Array<{ __typename: 'MissionSubmissionCount', id: string, displayName: string, submissionCount: number }>, submissionCountByTeam: Array<{ __typename: 'TeamSubmissionCount', submissionCount: number, team: { __typename: 'Team', id: string, displayName: string } }>, likeCountBySubmission: Array<{ __typename: 'SubmissionLikeCount', missionName: string, teamName: string, likeCount: number, submission: { __typename: 'CameraSubmission', id: string } | { __typename: 'GpsSubmission', id: string } | { __typename: 'TextSubmission', id: string } }> };


export const LoadStatisticsDocument = `
    query LoadStatistics($id: ID!) {
  cohort(id: $id) {
    statistics {
      activeTeams
      totalTeams
      totalSubmissions
      averageMissionCompletion
    }
  }
  submissionCountByMission(cohortId: $id) {
    id
    displayName
    submissionCount
  }
  submissionCountByTeam(cohortId: $id) {
    team {
      id
      displayName
    }
    submissionCount
  }
  likeCountBySubmission(cohortId: $id) {
    submission {
      id
    }
    missionName
    teamName
    likeCount
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    LoadStatistics: build.query<LoadStatisticsQuery, LoadStatisticsQueryVariables>({
      query: (variables) => ({ document: LoadStatisticsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLoadStatisticsQuery, useLazyLoadStatisticsQuery } = injectedRtkApi;

