import { useShowWelcomeBanner } from 'data/preferences/preferences.hooks';
import { useAppDispatch } from 'data/hooks';
import { dismissWelcomeBanner } from 'data/preferences';
import { WelcomeBannerUI } from './welcome-banner-ui';

export const WelcomeBanner = () => {
  const isOpen = useShowWelcomeBanner();
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(dismissWelcomeBanner());
  };

  return <WelcomeBannerUI isOpen={isOpen} onClose={onClose} />;
};
