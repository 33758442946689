import { Avatar, Dropdown, Label, Text } from '@goosechase/ui';
import { Control, Controller } from 'react-hook-form';
import { BroadcastFormInputData } from './use-broadcast-form';
import { useTranslate } from 'util/i18n';
import { useLoadTeamsForExperienceQuery } from 'data/teams';
import { TeamMode } from 'data/models';
import { Nullable } from 'types/util';

interface BroadcastSendToDropdownOptionProps {
  id: string;
  value: string;
  title: string;
  photoUrl: Nullable<string>;
  mode: TeamMode;
}

const BroadcastSendToDropdownOption = ({
  value,
  title,
  photoUrl,
  mode,
}: BroadcastSendToDropdownOptionProps) => {
  return (
    <div className="p-4">
      <div className="flex flex-row gap-3 items-center">
        {value !== 'ALL' ? (
          <div className="self-start tablet-narrow:self-center">
            <Avatar
              size="xs3"
              placeholder={mode === 'TEAM' ? 'team-duo' : 'single'}
              src={photoUrl ?? undefined}
              alt={title}></Avatar>
          </div>
        ) : null}
        <div className="flex flex-col gap-1">
          <Text className="" size="md">
            {title}
          </Text>
        </div>
      </div>
    </div>
  );
};

interface BroadcastSendToDropdownProps {
  experienceId: string;
  control: Control<BroadcastFormInputData>;
}

export const BroadcastSendToDropdown = ({
  experienceId,
  control,
}: BroadcastSendToDropdownProps) => {
  const { t } = useTranslate('broadcastForm.sendTo');
  const { data } = useLoadTeamsForExperienceQuery({ experienceId });
  const teams = data?.experience?.cohorts[0].teams ?? [];

  const options: BroadcastSendToDropdownOptionProps[] = [
    {
      id: 'ALL',
      value: 'ALL',
      title: t('options.all'),
      mode: TeamMode.Team,
      photoUrl: null,
    },
  ];

  for (const team of teams) {
    options.push({
      id: team.id,
      value: team.id,
      title: team.displayName,
      mode: team.mode,
      photoUrl: team.photoUrl,
    });
  }

  return (
    <Controller
      control={control}
      name="teamName"
      render={({ field: { onChange: onChangeName } }) => {
        return (
          <Controller
            control={control}
            name="sendTo"
            render={({ field: { onChange, value, name } }) => {
              const selectedDropdownOption =
                options.find((option) => option.value === value) ?? options[0];

              return (
                <div>
                  <Label htmlFor={name} size="lg" className="block mb-2">
                    {t('label').toUpperCase()}
                  </Label>
                  <Dropdown
                    label={selectedDropdownOption.title}
                    options={options}
                    selectedOptionId={value ?? undefined}
                    onSelect={(val: string) => {
                      onChange(val);
                      onChangeName(options.find((o) => o.id === val)?.title);
                    }}
                    buttonClassName="w-full"
                    containerClassName="w-full"
                    customRenderOption={BroadcastSendToDropdownOption}
                  />
                </div>
              );
            }}
          />
        );
      }}
    />
  );
};
