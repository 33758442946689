import { useNavigate } from 'react-router-dom';
import { Headline } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { useScreenSize } from 'hooks/use-screen-size';
import { AppRoutes, EXPERIENCE_PATH } from 'constants/routes';
import { Page } from 'components/page.component';
import { Card } from 'components/card.component';

import { CreateExperienceForm } from './create-experience-form';

export const CreateExperiencePage = () => {
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  const { t } = useTranslate('pages.createExperience');

  const handleCreateExperienceSuccess = async (id: string) => {
    navigate(`${EXPERIENCE_PATH}/${id}/${AppRoutes.SUB_PATHS.MISSIONS}`);
  };

  return (
    <Page title={t('meta.title')}>
      <div className="flex justify-center w-full">
        <div className="flex flex-col w-full tablet-narrow:max-w-[600px] desktop:max-w-[1200px] p-3.5 tablet-narrow:p-8">
          <Headline className="font-bold self-start" type="secondary" size={isMobile ? 'sm' : 'md'}>
            {t('title')}
          </Headline>
          <Card className="mt-4 p-5 w-full">
            <CreateExperienceForm onCreateExperienceSuccess={handleCreateExperienceSuccess} />
          </Card>
        </div>
      </div>
    </Page>
  );
};
