import { Modal, Button } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import SplashScreenPreview from './splash-screen-preview.component';

type SplashScreenPreviewModalProps = {
  show: boolean;
  onClose: () => void;
  photoUrl?: string;
};

export const SplashScreenPreviewModal = ({
  show,
  onClose,
  photoUrl,
}: SplashScreenPreviewModalProps) => {
  const { t } = useTranslate('pages.branding.form');

  return (
    <Modal
      className="w-[344px] bg-transparent border-0"
      overlayClassName="bg-black/90"
      show={show}
      onClose={onClose}>
      <Button
        label={t('close')}
        outlined
        size="sm"
        className="absolute top-4 right-4 !bg-transparent text-white border-white !hover:bg-transparent hover:text-white hover:border-white"
        onClick={onClose}
      />
      <SplashScreenPreview id="mobile" photoUrl={photoUrl} />
    </Modal>
  );
};
