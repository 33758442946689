import { Button, TextButton } from '@goosechase/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { LabelledField } from 'components/labelled-field.component';
import { Link } from 'components/link.component';
import { PhotoUploadField, PhotoUploadUIProps } from 'components/photo-upload';
import { AppRoutes } from 'constants/routes';
import { useDeleteAccountMutation, useUpdateUserProfileMutation } from 'data/auth';
import { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { displayToast } from 'services/toast';
import { Nullable } from 'types/util';
import { transformEmptyInputToNull } from 'util/form-util';
import { useTranslate } from 'util/i18n';
import { z } from 'zod';
import { DeleteAccountModal } from 'components/delete-account-modal';

import { PhotoUploadUI } from './photo-upload-ui.component';
import { useNavigate } from 'react-router';

export interface UserProfileData {
  photoId: Nullable<string>;
  email: string;
}

const useUserProfileForm = (defaultData?: UserProfileData) => {
  const { t } = useTranslate('pages.accountInfo.editAccount.updateUserProfile');

  return useForm<UserProfileData>({
    mode: 'onTouched',
    defaultValues: {
      photoId: defaultData?.photoId,
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string({ required_error: t('email.errors.required') ?? 'Email is required' })
          .email(t('email.errors.invalid') ?? 'Email should be valid'),
        photoId: z.string().optional().nullable().transform(transformEmptyInputToNull),
      }),
    ),
  });
};

type UpdateUserProfileFormProps = {
  photoUrl?: Nullable<string>;
  email: string;
};

export const UpdateUserProfileForm = (props: UpdateUserProfileFormProps) => {
  const { control, formState, handleSubmit, setValue, setError } = useUserProfileForm();
  const { t } = useTranslate('pages.accountInfo.editAccount');
  const [uploading, setUploading] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [updateUserProfile, { isLoading }] = useUpdateUserProfileMutation();
  const [deleteAccount, { isLoading: isLoadingDeleteAccount }] = useDeleteAccountMutation();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<UserProfileData> = async (values) => {
    try {
      const data = await updateUserProfile({
        params: { photoId: values.photoId, email: values.email },
      }).unwrap();

      displayToast({
        body: t('updateUserProfile.toasts.success.body'),
        id: data.updateUserProfile.id,
        title: t('updateUserProfile.toasts.success.title'),
      });
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.code === 'EMAIL_IN_USE') {
        setError('email', { message: t('updateUserProfile.email.errors.alreadyExists') ?? '' });
      } else {
        throw error;
      }
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await deleteAccount().unwrap();
      navigate(AppRoutes.LOGOUT);
    } catch (e) {
      displayToast({
        id: '',
        type: 'error',
        title: t('deleteAccountErrorToast.title'),
        body: t('deleteAccountErrorToast.body'),
      });
    }
  };

  useEffect(() => {
    if (props.email) {
      setValue('email', props.email, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.email]);

  return (
    <>
      <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="photoId"
          render={({ fieldState: { error } }) => (
            <PhotoUploadField
              label=""
              setValue={(photoId) => setValue('photoId', photoId)}
              setError={() => {}}
              errorMessage={error?.message}
              defaultSrc={props?.photoUrl ?? ''}
              setUploading={setUploading}
              uploading={uploading}
              photoUploadUI={(data: PhotoUploadUIProps) => <PhotoUploadUI {...data} />}
            />
          )}
        />

        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <LabelledField
              label={t('updateUserProfile.email.label')}
              type="text"
              onChange={field.onChange}
              onBlur={field.onBlur}
              autoComplete="email"
              errorMessage={fieldState.error?.message}
              disabled={isLoading}
              defaultValue={field.value}
            />
          )}
        />
        <div className="flex justify-between flex-wrap gap-6">
          <div className="flex flex-row gap-3">
            <Button
              className="w-fit"
              label={t('updateUserProfile.submit')}
              disabled={!formState.isValid || uploading || isLoading}
            />
            <Link href={AppRoutes.CHANGE_PASSWORD} useRouter>
              <Button className="w-fit" label={t('updateUserProfile.changePassword')} outlined />
            </Link>
          </div>
          <TextButton
            type="button"
            label={t('updateUserProfile.deleteAccount')}
            onClick={() => {
              setShowDeleteAccountModal(true);
            }}
            className="text-vibrantRed"
            disabled={isLoadingDeleteAccount}
          />
        </div>
      </form>
      <DeleteAccountModal
        show={showDeleteAccountModal}
        onClose={() => {
          setShowDeleteAccountModal(false);
        }}
        onSubmit={handleDeleteAccount}
      />
    </>
  );
};
