import { Icon, IconName, type ThemeColor } from '@goosechase/ui';

export interface DropdownOptionType {
  id: string;
  icon?: IconName;
  iconColor?: ThemeColor;
  title: string;
  description: string;
  disabled?: boolean;
}

export const renderSelectedLeftIcon =
  ({ icon, iconColor = 'black' }: DropdownOptionType) =>
  // eslint-disable-next-line react/display-name
  () =>
    icon ? <Icon size={24} name={icon} color={iconColor} /> : null;
