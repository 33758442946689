import { Controller, Control, useWatch, UseFormTrigger, useFormState } from 'react-hook-form';
import { Text, mergeClasses } from '@goosechase/ui';

import type { MissionFormInputData } from './use-mission-form';
import { MissionTriggerTimingDropdown } from './mission-trigger-timing-dropdown.component';
import { MissionTriggerRelativeFields } from './mission-trigger-relative-fields.component';
import { MissionTriggerTiming } from 'data/models';
import { MissionTriggerSpecificFields } from './mission-trigger-specific-fields.component';
import { useTranslate } from 'util/i18n';
import { useToggle } from 'hooks/use-toggle';
import { MissionTriggerSummary } from './mission-trigger-summary.component';
import { Nullable } from 'types/util';
import { useEffect } from 'react';
import { DraftMode } from './draft-mode.component';
import { MissionTriggerTime } from '../mission-trigger-time/mission-trigger-time.component';

interface MissionAvailabilityProps {
  control: Control<MissionFormInputData>;
  cohortStartDate?: Nullable<string>;
  cohortEndDate?: Nullable<string>;
  trigger: UseFormTrigger<MissionFormInputData>;
}

type TriggerType = 'releaseTrigger' | 'expireTrigger';

// eslint-disable-next-line complexity
const MissionTriggerForm = ({
  triggerType,
  control,
  trigger,
}: MissionAvailabilityProps & { triggerType: TriggerType }) => {
  const { t } = useTranslate('missionForm.missionTrigger');
  const { errors } = useFormState({ control });
  const timing = useWatch({ control, name: `${triggerType}.timing` });
  const relativeDuration = useWatch({ control, name: `${triggerType}.relativeDuration` });
  const relativeUnit = useWatch({ control, name: `${triggerType}.relativeUnit` });
  const relativeAnchor = useWatch({ control, name: `${triggerType}.relativeAnchor` });
  const specificDay = useWatch({ control, name: `${triggerType}.specificDay` });
  const specificTime = useWatch({ control, name: `${triggerType}.specificTime` });

  const isRelative = timing === MissionTriggerTiming.Relative;
  const isSpecific = timing === MissionTriggerTiming.Specific;

  const displaySummary = Boolean(
    (isRelative && typeof relativeDuration === 'number' && relativeUnit && relativeAnchor) ||
      (isSpecific && typeof specificDay === 'number' && specificTime),
  );

  const [triggerCollapsed, toggleTriggerCollapse] = useToggle(displaySummary);

  useEffect(() => {
    if (typeof relativeDuration === 'number' || typeof specificDay === 'number' || specificTime) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      trigger(triggerType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timing, relativeAnchor, relativeDuration, relativeUnit, specificDay, specificTime]);

  return (
    <div
      className={mergeClasses(
        'border border-black-24 rounded-md p-4 flex flex-col gap-2 relative',
        { 'border-vibrantRed': errors[triggerType]?.message && triggerCollapsed },
      )}>
      {displaySummary ? (
        <button
          className="absolute top-0 right-0 p-4"
          type="button"
          onClick={() => toggleTriggerCollapse()}>
          <Text className="text-vibrantBlue">{t(triggerCollapsed ? 'edit' : 'collapse')}</Text>
        </button>
      ) : null}
      {triggerCollapsed && displaySummary ? (
        <>
          <MissionTriggerSummary
            t={t}
            timing={timing}
            relativeAnchor={relativeAnchor}
            relativeDuration={relativeDuration}
            relativeUnit={relativeUnit}
            specificDay={specificDay}
            specificTime={specificTime}
            triggerType={triggerType}
          />
          {errors?.[triggerType]?.message ? (
            <Text size="xs" className="text-vibrantRed">
              <span
                dangerouslySetInnerHTML={{
                  __html: errors?.[triggerType]?.message ?? '',
                }}
              />
            </Text>
          ) : (
            <Text size="sm" className="text-black-64">
              <MissionTriggerTime
                timing={timing}
                relativeAnchor={relativeAnchor}
                relativeDuration={relativeDuration}
                relativeUnit={relativeUnit}
                specificDay={specificDay}
                specificTime={specificTime}
              />
            </Text>
          )}
        </>
      ) : (
        <>
          <Controller
            control={control}
            name={`${triggerType}.timing`}
            render={({ field: { value, onChange } }) => (
              <MissionTriggerTimingDropdown
                value={value}
                onChange={onChange}
                triggerType={triggerType}
              />
            )}
          />
          {isRelative ? (
            <div className="flex gap-2">
              <MissionTriggerRelativeFields control={control} triggerType={triggerType} />
            </div>
          ) : null}
          {isSpecific ? (
            <div className="flex gap-2">
              <MissionTriggerSpecificFields control={control} triggerType={triggerType} />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export const MissionAvailability = ({
  control,
  cohortEndDate,
  cohortStartDate,
  trigger,
}: MissionAvailabilityProps) => {
  return (
    <div className="flex flex-col grow gap-6">
      <MissionTriggerForm
        control={control}
        cohortEndDate={cohortEndDate}
        cohortStartDate={cohortStartDate}
        trigger={trigger}
        triggerType="releaseTrigger"
      />
      <MissionTriggerForm
        control={control}
        cohortEndDate={cohortEndDate}
        cohortStartDate={cohortStartDate}
        trigger={trigger}
        triggerType="expireTrigger"
      />
      <DraftMode control={control} />
    </div>
  );
};
