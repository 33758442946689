import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  setAuthState,
  useAuthenticateImpersonationSessionMutation,
  setImpersonationActive,
  useIsImpersonationActive,
} from '../data/auth';
import { useAppDispatch } from '../data/hooks';

interface Props {
  children: React.ReactNode;
}
export const ImpersonationWrapper = ({ children }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [fetched, setFetched] = useState<boolean>(false);
  const [impersonateUser, { isLoading, error }] = useAuthenticateImpersonationSessionMutation();
  const impersonating = useIsImpersonationActive();
  const dispatch = useAppDispatch();

  const impersonationId = searchParams.get('impersonation_session');

  useEffect(() => {
    const impersonateAndSetAccessToken = async () => {
      if (impersonationId && !fetched) {
        setFetched(true);
        const result = await impersonateUser({ sessionId: impersonationId }).unwrap();
        const token = result.claimImpersonationSession?.accessToken;
        if (token) {
          dispatch(setAuthState({ accessToken: token, intercomHmac: null, userId: '' })); // userId not needed for impersonated sessions
          setSearchParams(undefined);
          dispatch(setImpersonationActive(true));
        }
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    impersonateAndSetAccessToken();
  }, [impersonationId, error, dispatch, impersonateUser, setSearchParams, fetched]);

  if (error) {
    return <div>Failed to impersonate</div>;
  }

  if (impersonationId || isLoading) {
    return <div>Impersonating user...</div>;
  }

  return (
    <div className={classNames({ 'border-8 border-red-600': impersonating }, 'h-full')}>
      {impersonating && (
        <div className="py-4 bg-red-600 text-white text-center block">IMPERSONATION ACTIVE</div>
      )}
      {children}
    </div>
  );
};
