import { UseFormSetValue } from 'react-hook-form';
import { Label, TagInput, Text } from '@goosechase/ui';

import { useTranslate } from '../../util/i18n';
import { MissionFormInputData } from './use-mission-form';

interface TextAnswersInputProps {
  values: string[];
  setFormValue: UseFormSetValue<MissionFormInputData>;
}

export const TextAnswersInput = ({ values, setFormValue }: TextAnswersInputProps) => {
  const { t } = useTranslate('missionForm.textAnswers');

  const placeholder = values.length > 0 ? t('placeholder') : t('placeholderEmpty');

  const onRemove = (valueToRemove: string) => {
    setFormValue(
      'answers',
      values.filter((value) => value !== valueToRemove.toLowerCase()),
    );
  };
  const onAdd = (valueToAdd: string) => {
    const formattedValueToAdd = valueToAdd.toLowerCase();
    if (!values.includes(formattedValueToAdd)) {
      setFormValue('answers', [...values, formattedValueToAdd]);
    }
  };

  return (
    <div className="flex flex-col">
      <Label size="sm" className="block mb-2">
        {t('label').toUpperCase()}
      </Label>
      <TagInput placeholder={placeholder} values={values} onAdd={onAdd} onRemove={onRemove} />
      <Text className="text-black-64" size="sm">
        {t('hint')}
      </Text>
    </div>
  );
};
