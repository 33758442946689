/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type MyOwnedWorkspacesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyOwnedWorkspacesQuery = { __typename: 'Query', myOwnedWorkspaces: Array<{ __typename: 'Workspace', displayName: string, id: string, type: Types.WorkspaceType, members: Array<{ __typename: 'WorkspaceMember', displayName: string }> }> };


export const MyOwnedWorkspacesDocument = `
    query MyOwnedWorkspaces {
  myOwnedWorkspaces {
    displayName
    id
    type
    members {
      displayName
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    MyOwnedWorkspaces: build.query<MyOwnedWorkspacesQuery, MyOwnedWorkspacesQueryVariables | void>({
      query: (variables) => ({ document: MyOwnedWorkspacesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useMyOwnedWorkspacesQuery, useLazyMyOwnedWorkspacesQuery } = injectedRtkApi;

