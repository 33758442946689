/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { HostedCheckoutPageFragmentFragmentDoc } from './hosted-checkout-page.fragment.generated';
import { rootApi } from 'data/api';
module.hot?.accept();
export type StartAddonCheckoutMutationVariables = Types.Exact<{
  addonId: Types.Scalars['String']['input'];
  experienceId: Types.Scalars['ID']['input'];
}>;


export type StartAddonCheckoutMutation = { __typename: 'Mutation', startAddonCheckout: { __typename: 'HostedCheckoutPage', id: string, type: string, url: string, state: string, embed: boolean, created_at: number, expires_at: number, object: string, updated_at: number, resource_version: number } };


export const StartAddonCheckoutDocument = `
    mutation StartAddonCheckout($addonId: String!, $experienceId: ID!) {
  startAddonCheckout(addonId: $addonId, experienceId: $experienceId) {
    ...HostedCheckoutPageFragment
  }
}
    ${HostedCheckoutPageFragmentFragmentDoc}`;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    StartAddonCheckout: build.mutation<StartAddonCheckoutMutation, StartAddonCheckoutMutationVariables>({
      query: (variables) => ({ document: StartAddonCheckoutDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useStartAddonCheckoutMutation } = injectedRtkApi;

