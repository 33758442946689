/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type StartCohortMutationVariables = Types.Exact<{
  params: Types.StartCohortParams;
}>;


export type StartCohortMutation = { __typename: 'Mutation', startCohortAndScheduleEnd: { __typename: 'Cohort', id: string, status: Types.CohortState } };


export const StartCohortDocument = `
    mutation StartCohort($params: StartCohortParams!) {
  startCohortAndScheduleEnd(params: $params) {
    id
    status
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    StartCohort: build.mutation<StartCohortMutation, StartCohortMutationVariables>({
      query: (variables) => ({ document: StartCohortDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useStartCohortMutation } = injectedRtkApi;

