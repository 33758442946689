import { useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { Button, Text, Label, Icon } from '@goosechase/ui';

import { useTranslate } from '../../util/i18n';
import { GpsLocationFormUI, GpsLocationFormData } from '../gps-location-form';
import { MissionFormInputData } from './use-mission-form';

const getLocationLabel = ({
  locationName,
  latitude,
  longitude,
}: Omit<GpsLocationTagProps, 'onEdit'>): string => {
  if (locationName && locationName.length > 0) {
    return locationName;
  }

  return `${latitude},${longitude}`;
};

interface GpsLocationTagProps {
  ariaLabel?: string;
  locationName?: string;
  latitude: string;
  longitude: string;
  onEdit: () => void;
}

const GpsLocationTag = ({
  ariaLabel,
  locationName,
  latitude,
  longitude,
  onEdit,
}: GpsLocationTagProps) => {
  return (
    <div className="flex inline-block rounded bg-offWhite items-center w-fit">
      <Text className="font-semibold uppercase py-1 pl-2.5 pr-1" size="xs">
        {getLocationLabel({ locationName, latitude, longitude })}
      </Text>
      <button
        aria-label={ariaLabel}
        className="h-full px-1 rounded-r hover:bg-black-12 focus:bg-black-12 outline-black"
        type="button"
        onClick={onEdit}>
        <Icon className="shrink-0" name="Edit" size={14} />
      </button>
    </div>
  );
};

interface GpsLocationInputProps {
  control: Control<MissionFormInputData>;
}

export const GpsLocationInput = ({ control }: GpsLocationInputProps) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslate('missionForm.gpsLocationInput');

  const {
    field: { value: locationName, onChange: onChangeLocationName },
    fieldState: { error: locationNameError },
  } = useController({ name: 'locationName', control });
  const {
    field: { value: latitude, onChange: onChangeLatitude },
    fieldState: { error: latitudeError },
  } = useController({ name: 'latitude', control });
  const {
    field: { value: longitude, onChange: onChangeLongitude },
    fieldState: { error: longitudeError },
  } = useController({ name: 'longitude', control });
  const {
    field: { value: radius, onChange: onChangeRadius },
    fieldState: { error: radiusError },
  } = useController({ name: 'radius', control });

  const errorExists = Boolean(locationNameError || latitudeError || longitudeError || radiusError);

  const gpsLocationExists = latitude.length > 0 && longitude.length > 0;

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmitLocation = (data: GpsLocationFormData) => {
    onChangeLocationName(data.locationName);
    onChangeLatitude(data.latitude);
    onChangeLongitude(data.longitude);
    onChangeRadius(data.radius);

    handleCloseModal();
  };

  return (
    <div className="flex flex-col">
      <Label size="sm" className="block mb-2">
        {t('label').toUpperCase()}
      </Label>
      {gpsLocationExists ? (
        <GpsLocationTag
          ariaLabel={t('editGpsLocation') ?? undefined}
          locationName={locationName ?? undefined}
          latitude={latitude}
          longitude={longitude}
          onEdit={handleShowModal}
        />
      ) : (
        <Button
          className="w-fit"
          type="button"
          outlined
          size="sm"
          label={t('buttonLabel')}
          onClick={handleShowModal}
        />
      )}
      {errorExists && (
        <div className="mt-2">
          <Text size="xs" className="text-vibrantRed">
            {t('error')}
          </Text>
        </div>
      )}
      <GpsLocationFormUI
        defaultValues={{
          locationName: locationName ?? undefined,
          latitude,
          longitude,
          radius,
        }}
        show={showModal}
        onClose={handleCloseModal}
        onSubmit={handleSubmitLocation}
      />
    </div>
  );
};
