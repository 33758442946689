import { Label, mergeClasses } from '@goosechase/ui';

interface TabProps {
  label: string;
  selectedTab: number;
  index: number;
  onSelect: (tab: number) => void;
}

const Tab = ({ label, selectedTab, index, onSelect }: TabProps) => {
  const handleOnClick = () => {
    onSelect(index);
  };

  return (
    <button
      className={mergeClasses(
        'text-black-64 hover:text-black border-transparent hover:border-vibrantBlue border-b-2 outline-black outline-offset-4',
        {
          'border-vibrantBlue text-black': selectedTab === index,
        },
      )}
      onClick={handleOnClick}>
      <Label className="cursor-pointer">{label}</Label>
    </button>
  );
};

interface TabInfo {
  name: string;
  label: string;
}

interface TabsRowProps {
  tabs: TabInfo[];
  selectedTab: number;
  onSelectTab: (tab: number) => void;
}

export const TabsRow = ({ selectedTab, onSelectTab, tabs }: TabsRowProps) => {
  return (
    <div className="flex gap-8">
      {tabs.map(({ name, label }, index) => (
        <Tab
          key={name}
          index={index}
          label={label}
          selectedTab={selectedTab}
          onSelect={onSelectTab}
        />
      ))}
    </div>
  );
};
