/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type UpcomingBillingQueryVariables = Types.Exact<{
  workspaceId: Types.Scalars['ID']['input'];
}>;


export type UpcomingBillingQuery = { __typename: 'Query', upcomingBilling: { __typename: 'Billing', nextBillingAt: string, priceUsdCents: number, card: { __typename: 'Card', type: string, last4: string } } | null };


export const UpcomingBillingDocument = `
    query UpcomingBilling($workspaceId: ID!) {
  upcomingBilling(workspaceId: $workspaceId) {
    nextBillingAt
    priceUsdCents
    card {
      type
      last4
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    UpcomingBilling: build.query<UpcomingBillingQuery, UpcomingBillingQueryVariables>({
      query: (variables) => ({ document: UpcomingBillingDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpcomingBillingQuery, useLazyUpcomingBillingQuery } = injectedRtkApi;

