import { useLeaderboardQuery } from '../../data/leaderboard';
import { LeaderboardItem } from './leaderboard-item';
import { ReactComponent as HatchedEgg } from 'media/hatched-egg.svg';
import { useTranslate } from '../../util/i18n';
import { LoadingSpinner } from '../../components/loading-spinner';
import { EmptyState } from '../../components/empty-state';
import { useActiveCohortId } from 'data/experiences';
import { ErrorState } from 'components/error-state';

export const Leaderboard = () => {
  const activeCohortId = useActiveCohortId();
  const { data, isLoading, isError } = useLeaderboardQuery({ cohortId: activeCohortId || '' });
  const leaderboard = data?.leaderboard ?? [];
  const { t } = useTranslate('pages.participants.teams');

  if (isError) {
    return (
      <div className="flex justify-center py-16 px-8">
        <ErrorState />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center flex-col py-16 px-8 h-full">
        <LoadingSpinner />
      </div>
    );
  }

  if (!leaderboard.length) {
    return (
      <div className="flex justify-center items-center flex-col py-16 px-8">
        <EmptyState
          renderGraphic={() => <HatchedEgg width="323" className="mb-6" />}
          text={t('emptyState.title')}
          subtext={t('emptyState.body')}
        />
      </div>
    );
  }
  return (
    <div className="rounded-lg bg-white divide-y-[1px] divide-black-12">
      {leaderboard.map(({ team, rank }) => (
        <LeaderboardItem
          key={team.id}
          id={team.id}
          name={team.displayName}
          teamMode={team.mode}
          photoUrl={team.photoUrl}
          points={team.points}
          rank={rank}
        />
      ))}
    </div>
  );
};
