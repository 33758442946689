var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Edit = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M10.9395 4.06067H3.93945C3.40902 4.06067 2.90031 4.27138 2.52524 4.64646C2.15017 5.02153 1.93945 5.53024 1.93945 6.06067V20.0607C1.93945 20.5911 2.15017 21.0998 2.52524 21.4749C2.90031 21.85 3.40902 22.0607 3.93945 22.0607H17.9395C18.4699 22.0607 18.9786 21.85 19.3537 21.4749C19.7287 21.0998 19.9395 20.5911 19.9395 20.0607V13.0607", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M18.4395 2.56065C18.8373 2.16283 19.3768 1.93933 19.9395 1.93933C20.5021 1.93933 21.0416 2.16283 21.4395 2.56065C21.8373 2.95848 22.0608 3.49804 22.0608 4.06065C22.0608 4.62326 21.8373 5.16283 21.4395 5.56065L11.9395 15.0607L7.93945 16.0607L8.93945 12.0607L18.4395 2.56065Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
