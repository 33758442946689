/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type SendBroadcastMutationVariables = Types.Exact<{
  params: Types.SendBroadcastParams;
}>;


export type SendBroadcastMutation = { __typename: 'Mutation', sendBroadcast: { __typename: 'Broadcast', id: string } };


export const SendBroadcastDocument = `
    mutation SendBroadcast($params: SendBroadcastParams!) {
  sendBroadcast(params: $params) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    SendBroadcast: build.mutation<SendBroadcastMutation, SendBroadcastMutationVariables>({
      query: (variables) => ({ document: SendBroadcastDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSendBroadcastMutation } = injectedRtkApi;

