import { TFunction } from 'i18next';
import { Dropdown, Text } from '@goosechase/ui';

import { useTranslate } from '../../util/i18n';
import { SubmissionGroup, SUBMISSION_GROUPS } from './constants';

const convertSubmissionGroupToDropdownOption = (
  t: TFunction,
  submissionGroup: SubmissionGroup,
) => ({
  id: submissionGroup,
  label: t(submissionGroup),
});

interface DropdownOptionType {
  label: string;
}

const DropdownOption = ({ label }: DropdownOptionType) => (
  <Text className="truncate py-3 px-5" size="sm">
    {label}
  </Text>
);

interface SubmissionGroupDropdownProps {
  selectedGroup: SubmissionGroup;
  disabled: boolean;
  onChange: (value: SubmissionGroup) => void;
}

export const SubmissionGroupDropdown = ({
  selectedGroup,
  disabled,
  onChange,
}: SubmissionGroupDropdownProps) => {
  const { t } = useTranslate('pages.submissions.submissionGroupDropdown');

  const options = SUBMISSION_GROUPS.map((sg) => convertSubmissionGroupToDropdownOption(t, sg));

  const selectedOption = convertSubmissionGroupToDropdownOption(t, selectedGroup);

  return (
    <Dropdown
      containerClassName="w-full tablet-wide:w-[275px] bg-white rounded-dropdown"
      buttonClassName="w-full"
      label={selectedOption.label}
      options={options}
      selectedOptionId={selectedOption.id}
      disabled={disabled}
      customRenderOption={DropdownOption}
      onSelect={(val: string) => onChange(val as SubmissionGroup)}
    />
  );
};
