import { ArrayElement } from '../../global';
import { type MissionSubmissionsQuery } from '../../data/submissions';
import { type Submission } from '../../components/submission-grid';
import { InformationTagsMission } from 'components/information-tags/information-tags.util';

interface CommonMissionSubmissionFields {
  missionLocationName?: string;
  missionName: Submission['missionName'];
  missionType: Submission['missionType'];
  missionPoints: Submission['missionPoints'];
  mission: InformationTagsMission;
}

export const convertGqlMissionSubmissionToUIMissionSubmission =
  ({ missionLocationName, mission, ...commonFields }: CommonMissionSubmissionFields) =>
  (
    submission: ArrayElement<NonNullable<MissionSubmissionsQuery['mission']>['submissions']>,
  ): Submission => {
    const fields = {
      ...commonFields,
      submissionId: submission.id,
      submissionDate: new Date(submission.createdAt),
      teamId: submission.team.id,
      teamName: submission.team.displayName,
      teamImageUrl: submission.team.photoUrl ?? undefined,
      teamMode: submission.team.mode,
      bonusPoints: submission.bonusPoints,
      isLiked: submission.liked,
      numLikes: submission.numLikes,
      missionDescription: mission.description,
      submissionBonuses: submission.bonuses.map((bonus) => ({
        ...bonus,
        bonusId: bonus.id,
        createdAt: new Date(bonus.createdAt),
      })),
      informationTagsMission: mission,
    };

    if (submission.__typename === 'CameraSubmission') {
      const submissionImageSrc = submission.assets.find((asset) => asset.role === 'PHOTO')?.url;
      const submissionVideoSrc = submission.assets.find((asset) => asset.role === 'VIDEO')?.url;

      return {
        ...fields,
        submissionImageSrc,
        submissionVideoSrc,
        submissionDownloadSrc:
          submission.assets.find((asset) => asset.role === 'VIDEO')?.url ?? submissionImageSrc,
        submissionCaption: submission.caption ?? undefined,
      };
    } else if (submission.__typename === 'TextSubmission') {
      return {
        ...fields,
        submissionContent: submission.answer,
        submissionAnswer: submission.answer,
      };
    } else {
      return {
        ...fields,
        submissionContent: missionLocationName ?? `${submission.latitude}, ${submission.longitude}`,
        submissionLatitude: submission.latitude,
        submissionLongitude: submission.longitude,
        submissionCaption: submission.caption ?? undefined,
      };
    }
  };
