import { ArrayElement } from '../../global';
import { type TeamSubmissionsQuery } from '../../data/submissions';
import { type Submission } from '../../components/submission-grid';

interface CommonTeamSubmissionFields {
  teamId: Submission['teamId'];
  teamName: Submission['teamName'];
  teamImageUrl: Submission['teamImageUrl'];
  teamMode: Submission['teamMode'];
}

export const convertGqlTeamSubmissionToUITeamSubmission =
  (commonFields: CommonTeamSubmissionFields) =>
  (
    submission: ArrayElement<NonNullable<TeamSubmissionsQuery['team']>['submissions']>,
  ): Submission => {
    const fields = {
      ...commonFields,
      submissionId: submission.id,
      submissionDate: new Date(submission.createdAt),
      missionName: submission.mission.displayName,
      missionType: submission.mission.__typename,
      missionPoints: submission.mission.points,
      bonusPoints: submission.bonusPoints,
      isLiked: submission.liked,
      numLikes: submission.numLikes,
      missionDescription: submission.mission.description,
      submissionBonuses: submission.bonuses.map((bonus) => ({
        ...bonus,
        bonusId: bonus.id,
        createdAt: new Date(bonus.createdAt),
      })),
      informationTagsMission: submission.mission,
    };

    if (submission.__typename === 'CameraSubmission') {
      const submissionImageSrc = submission.assets.find((asset) => asset.role === 'PHOTO')?.url;
      const submissionVideoSrc = submission.assets.find((asset) => asset.role === 'VIDEO')?.url;

      return {
        ...fields,
        submissionImageSrc,
        submissionVideoSrc,
        submissionDownloadSrc:
          submission.assets.find((asset) => asset.role === 'VIDEO')?.url ?? submissionImageSrc,
        submissionCaption: submission.caption ?? undefined,
      };
    } else if (submission.__typename === 'TextSubmission') {
      return {
        ...fields,
        submissionAnswer: submission.answer,
        submissionContent: submission.answer,
      };
    } else {
      return {
        ...fields,
        submissionContent:
          submission.mission.locationName ?? `${submission.latitude}, ${submission.longitude}`,
        submissionLatitude: submission.latitude,
        submissionLongitude: submission.longitude,
        submissionCaption: submission.caption ?? undefined,
      };
    }
  };
