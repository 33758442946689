var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useFloating, autoUpdate, offset as floatingOffset, flip, shift, useHover, useFocus, useDismiss, useRole, useInteractions, FloatingPortal, FloatingArrow, arrow, } from '@floating-ui/react';
import { mergeClasses } from '../util/merge-classes';
import { Text } from '../typography';
export var Tooltip = function (_a) {
    var _b = _a.placement, placement = _b === void 0 ? 'top' : _b, body = _a.body, className = _a.className, _c = _a.offset, offset = _c === void 0 ? 5 : _c, _d = _a.variant, variant = _d === void 0 ? 'dark' : _d, customRootId = _a.customRootId, props = __rest(_a, ["placement", "body", "className", "offset", "variant", "customRootId"]);
    var _e = React.useState(false), isOpen = _e[0], setIsOpen = _e[1];
    var arrowRef = React.useRef(null);
    var _f = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: placement,
        whileElementsMounted: autoUpdate,
        middleware: [
            floatingOffset(offset),
            flip({
                fallbackAxisSideDirection: 'start',
            }),
            shift(),
            arrow({
                element: arrowRef,
            }),
        ],
    }), refs = _f.refs, floatingStyles = _f.floatingStyles, context = _f.context;
    var hover = useHover(context, { move: false, delay: { close: 150, open: 250 } });
    var focus = useFocus(context);
    var dismiss = useDismiss(context);
    var role = useRole(context, { role: 'tooltip' });
    var _g = useInteractions([hover, focus, dismiss, role]), getReferenceProps = _g.getReferenceProps, getFloatingProps = _g.getFloatingProps;
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ ref: refs.setReference }, getReferenceProps(), { children: props.children })), _jsx(FloatingPortal, __assign({ id: customRootId }, { children: isOpen && (_jsxs("div", __assign({ className: mergeClasses('rounded-lg p-4 text-center z-50', className, {
                        'bg-black text-white': variant === 'dark',
                        'bg-white text-black border border-black-24': variant === 'light',
                    }), ref: refs.setFloating, style: floatingStyles }, getFloatingProps(), props, { children: [_jsx(Text, __assign({ size: "xs" }, { children: body })), _jsx(FloatingArrow, { ref: arrowRef, context: context, tipRadius: 2, width: 24, height: 10, className: mergeClasses({
                                'fill-black': variant === 'dark',
                                'fill-white [&>path:first-of-type]:stroke-black-24 [&>path:last-of-type]:stroke-white': variant === 'light',
                            }), strokeWidth: 1 })] }))) }))] }));
};
