import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { analytics } from 'util/analytics';

export const usePageView = () => {
  const location = useLocation();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    analytics.page(location.pathname);
  }, [location]);
};
