import { Text } from '@goosechase/ui';

import { Card } from '../../components/card.component';
import { SubmissionFocusedView } from '../../components/submission-focused-view';
import type { PublicSubmissionQuery } from '../../data/submissions';
import { ReactComponent as GooseInFootPrint } from 'media/goose-in-foot-print.svg';
import { useScreenSize } from '../../hooks/use-screen-size';
import { Trans } from 'react-i18next';
import { useTranslate } from '../../util/i18n';
import { convertPublicSubmissionToComponentProps } from './util';
import { ExternalRoutes } from 'constants/routes';

const CustomFooter = () => {
  const { screen } = useScreenSize();
  const { t } = useTranslate('pages.publicSubmission');

  return (
    <div className="flex flex-row h-full w-full">
      <GooseInFootPrint className="aspect-square shrink-0" />
      <Text className="px-2 self-center text-center" size={screen === 'tablet-wide' ? 'xs' : 'md'}>
        <Trans
          t={t}
          i18nKey="customFooter"
          components={[
            <a
              key={ExternalRoutes.MARKETING_SITE}
              target="_blank"
              className="font-bold"
              href={ExternalRoutes.MARKETING_SITE}
              rel="noreferrer"
            />,
          ]}
        />
      </Text>
    </div>
  );
};

interface PublicSubmissionProps {
  submission: NonNullable<PublicSubmissionQuery['publicSubmission']>;
}

export const PublicSubmission = ({ submission }: PublicSubmissionProps) => (
  <Card className="flex flex-col overflow-y-auto shadow-lg w-[100vw] h-full tablet-narrow:rounded-xl tablet-narrow:w-[552px] tablet-narrow:h-[876px] tablet-wide:w-[832px] tablet-wide:h-[552px] desktop:w-[1080px] desktop:h-[708px] relative">
    <SubmissionFocusedView
      submission={convertPublicSubmissionToComponentProps(submission)}
      sidePanelClassName="tablet-wide:pb-[100px] desktop:pb-32"
      footerClassName="h-[112px] tablet-wide:h-[84px] desktop:h-[112px] shrink-0 p-4"
      renderCustomFooter={CustomFooter}
    />
  </Card>
);
