import { Control, useController, useWatch } from 'react-hook-form';
import { Text } from '@goosechase/ui';

import { InteractiveMap } from '../interactive-map.component';
import { useScreenSize } from '../../hooks/use-screen-size';
import { GpsLocationFormData } from './use-gps-location-form';

interface Coordinates {
  latitude: string;
  longitude: string;
}

const convertCoordinatesToLatLng = (
  coordinates: Coordinates,
): google.maps.LatLngLiteral | undefined => {
  const latLng = {
    lat: parseFloat(coordinates.latitude),
    lng: parseFloat(coordinates.longitude),
  };

  if (isNaN(latLng.lat) || isNaN(latLng.lng)) {
    return undefined;
  }

  return latLng;
};

interface LocationMapProps {
  description: string;
  control: Control<GpsLocationFormData>;
  clearLocationName: () => void;
}

export const LocationMap = ({ description, control, clearLocationName }: LocationMapProps) => {
  const { isTabletWide } = useScreenSize();

  const {
    field: { value: latitude, onChange: onChangeLatitude },
  } = useController({ name: 'latitude', control });
  const {
    field: { value: longitude, onChange: onChangeLongitude },
  } = useController({ name: 'longitude', control });
  const radius = useWatch({
    control,
    name: 'radius',
  });
  const zoom = useWatch({ control, name: 'zoom' });

  const handleCoordinateChange = ({ lat, lng }: google.maps.LatLngLiteral) => {
    onChangeLatitude(lat.toFixed(6));
    onChangeLongitude(lng.toFixed(6));
    clearLocationName();
  };

  return (
    <div className="flex flex-col gap-2">
      <Text size={isTabletWide ? 'sm' : 'xs'}>{description}</Text>
      <InteractiveMap
        className="w-full h-[280px] tablet-wide:h-[320px]"
        coordinates={convertCoordinatesToLatLng({ latitude, longitude })}
        radius={radius}
        zoom={zoom}
        onCoordinateChange={handleCoordinateChange}
      />
    </div>
  );
};
