/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type UpdateScheduledBroadcastMutationVariables = Types.Exact<{
  params: Types.UpdateScheduledBroadcastParams;
}>;


export type UpdateScheduledBroadcastMutation = { __typename: 'Mutation', updateScheduledBroadcast: { __typename: 'Broadcast', id: string } };


export const UpdateScheduledBroadcastDocument = `
    mutation UpdateScheduledBroadcast($params: UpdateScheduledBroadcastParams!) {
  updateScheduledBroadcast(params: $params) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    UpdateScheduledBroadcast: build.mutation<UpdateScheduledBroadcastMutation, UpdateScheduledBroadcastMutationVariables>({
      query: (variables) => ({ document: UpdateScheduledBroadcastDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateScheduledBroadcastMutation } = injectedRtkApi;

