/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type ExperienceInfoQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ExperienceInfoQuery = { __typename: 'Query', experience: { __typename: 'Experience', id: string, displayName: string, teamCreationMode: Types.TeamCreationMode, status: Types.CohortState, joinCode: string, missionOrder: Types.MissionOrder, timezone: string, cohorts: Array<{ __typename: 'Cohort', id: string }>, workspace: { __typename: 'Workspace', id: string, type: Types.WorkspaceType, myWorkspaceMember: { __typename: 'WorkspaceMember', roleId: string } | null, subscription: { __typename: 'Subscription', plan: { __typename: 'WorkspacePlan', maxConcurrentExperiences: number | null } } } } | null };


export const ExperienceInfoDocument = `
    query ExperienceInfo($id: ID!) {
  experience(id: $id) {
    id
    displayName
    teamCreationMode
    status
    joinCode
    missionOrder
    timezone
    cohorts {
      id
    }
    workspace {
      id
      myWorkspaceMember {
        roleId
      }
      type
      subscription {
        plan {
          maxConcurrentExperiences
        }
      }
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ExperienceInfo: build.query<ExperienceInfoQuery, ExperienceInfoQueryVariables>({
      query: (variables) => ({ document: ExperienceInfoDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useExperienceInfoQuery, useLazyExperienceInfoQuery } = injectedRtkApi;

