import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { AccountFormLayout } from 'components/account-form-layout.component';

import { ResetPasswordPanel } from './reset-password-panel.component';
import { NotificationBanner } from '@goosechase/ui';
import { AppLink } from '../../components/app-link.component';

export const ResetPasswordDonePage = () => {
  const { t } = useTranslate('pages.resetPassword');

  return (
    <Page title={t('meta.title')} description={t('meta.description') as string}>
      <AccountFormLayout>
        <AccountFormLayout.Body>
          <AccountFormLayout.Title>{t('headingDone')}</AccountFormLayout.Title>
          <NotificationBanner
            className="mt-8 mb-12"
            type="success"
            showIcon
            title={t('doneBanner.title')}
            body={t('doneBanner.body')}
          />
          <div className="tablet-narrow:text-paragraph-lg">
            <AppLink page="LOGIN">{t('backToLogIn')}</AppLink>
          </div>
        </AccountFormLayout.Body>
        <ResetPasswordPanel />
      </AccountFormLayout>
    </Page>
  );
};
