var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from '../icon';
import { Text } from '../typography';
import { mergeClasses } from '../util/merge-classes';
export var DefaultRenderOption = function (option) { return (_jsx("div", __assign({ className: "p-4" }, { children: _jsxs("div", __assign({ className: "flex flex-row gap-3 items-center" }, { children: [option.icon && (_jsx(Icon, { className: "shrink-0", size: 24, name: option.icon, color: option.disabled ? 'black-48' : 'black' })), _jsxs("div", __assign({ className: mergeClasses('flex flex-col gap-1', { 'text-black-48': option.disabled }) }, { children: [option.title && (_jsx(Text, __assign({ className: "font-bold", size: "md" }, { children: option.title }))), option.description && (_jsx(Text, __assign({ className: "font-light text-black-64", size: "sm" }, { children: option.description })))] }))] })) }))); };
