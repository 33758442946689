import { EmptyState } from 'components/empty-state';
import { LoadingSpinner } from 'components/loading-spinner';
import { useTranslate } from 'util/i18n';
import { ReactComponent as OstrichGoose } from 'media/ostrich-goose.svg';
import { useLoadBroadcastsForCohortQuery } from 'data/broadcasts';
import { Broadcast } from 'data/models';
import { useState } from 'react';
import {
  calculateTriggerAtForBroadcasts,
  filterBroadcastBySearchValue,
  filterBroadcastByState,
  orderBroadcastsByScheduledSendTime,
} from './util';
import { BroadcastListItem } from './broadcast-list-item.component';
import BroadcastSearch from 'components/broadcast-search.component';
import { useLoadExperienceStartAndEndQuery } from 'data/experiences';
import { TabsRow } from 'components/tabs-row';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';

const BROADCAST_TABS = ['all', 'scheduled', 'sent'];

interface BroadcastListProps {
  cohortId: string;
}

export const BroadcastList = ({ cohortId }: BroadcastListProps) => {
  const { t } = useTranslate('pages.broadcasts');
  const experienceTimezone = useExperienceInfo().data?.experience?.timezone ?? 'UTC';

  const { data, isLoading } = useLoadBroadcastsForCohortQuery(
    {
      cohortId,
    },
    { skip: !cohortId },
  );

  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = BROADCAST_TABS.map((tab) => ({ name: tab, label: t(`list.tabs.${tab}`) }));

  const { isLoading: isLoadingStartAndEndData, data: startAndEndData } =
    useLoadExperienceStartAndEndQuery({ id: cohortId }, { skip: !cohortId });

  const cohortStartDate = startAndEndData?.cohort?.startDate ?? null;
  const cohortEndDate = startAndEndData?.cohort?.endDate ?? null;

  const unsortedBroadcasts: Broadcast[] = data?.cohort?.broadcasts
    ? calculateTriggerAtForBroadcasts({
        broadcasts: data.cohort.broadcasts,
        cohortStartDate,
        cohortEndDate,
        experienceTimezone,
        t,
      })
    : [];

  const broadcasts: Broadcast[] = orderBroadcastsByScheduledSendTime(unsortedBroadcasts);

  const [searchValue, setSearchValue] = useState('');

  const filteredBroadcasts = broadcasts
    ?.filter(filterBroadcastByState(BROADCAST_TABS[selectedTab]))
    .filter(filterBroadcastBySearchValue(searchValue));

  if (isLoading || isLoadingStartAndEndData) {
    return (
      <div className="flex justify-center py-16 px-8">
        <LoadingSpinner />
      </div>
    );
  }

  if (!(broadcasts.length || isLoading)) {
    return (
      <>
        <BroadcastSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          disabled={true}
        />
        <div className="flex justify-center items-center flex-col pt-10 pb-6">
          <EmptyState
            renderGraphic={() => <OstrichGoose width="194" className="" />}
            text={t('emptyState.title')}
            subtext={t('emptyState.body')}
          />
        </div>
      </>
    );
  }
  return (
    <>
      <div className="py-4 px-5 border-black-24 border-b">
        <TabsRow tabs={tabs} selectedTab={selectedTab} onSelectTab={setSelectedTab} />
      </div>
      <BroadcastSearch searchValue={searchValue} setSearchValue={setSearchValue} disabled={false} />
      <div className="bg-white divide-y-[1px] divide-black-24 rounded-xl pb-4">
        {filteredBroadcasts.map((broadcast) => (
          <BroadcastListItem
            key={broadcast.id}
            broadcast={broadcast}
            message={broadcast.message}
            recipientType={broadcast.recipientType}
            broadcastTriggerId={broadcast.id}
            teamName={
              broadcast.recipientType === 'ALL'
                ? undefined
                : broadcast.recipients[0].team.displayName
            }
            teamPhoto={
              broadcast.recipientType === 'ALL'
                ? undefined
                : broadcast.recipients[0].team.photoUrl ?? undefined
            }
            teamMode={
              broadcast.recipientType === 'ALL' ? undefined : broadcast.recipients[0].team.mode
            }
            sent={broadcast.state === 'SENT'}
            isValid={broadcast.isValid ?? true}
            experienceTimezone={experienceTimezone}
          />
        ))}
      </div>
    </>
  );
};
