var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { mergeClasses } from '../util/merge-classes';
import { Text } from '../typography';
import { RadioButton } from './radio-button';
// RadioSelector is intended for quick and consistent use of a list of RadioButton options with labels
export var RadioSelector = function (_a) {
    var name = _a.name, options = _a.options, selectedValue = _a.selectedValue, className = _a.className, optionClassName = _a.optionClassName, onSelect = _a.onSelect, _b = _a.disabled, disabled = _b === void 0 ? false : _b, errorMessage = _a.errorMessage;
    return (_jsxs("div", __assign({ className: className }, { children: [options.map(function (_a) {
                var value = _a.value, label = _a.label;
                return (_jsxs("label", __assign({ className: mergeClasses('flex items-center cursor-pointer', { 'cursor-default': disabled }, optionClassName) }, { children: [_jsx(RadioButton, { value: value, name: name, checked: value === selectedValue, onChange: onSelect, disabled: disabled }), _jsx(Text, __assign({ className: mergeClasses('ml-1.5', {
                                'text-black-48 cursor-default': disabled,
                            }) }, { children: label }))] }), value));
            }), _jsx(Text, __assign({ size: "xs", className: "text-vibrantRed" }, { children: errorMessage !== null && errorMessage !== void 0 ? errorMessage : '' }))] })));
};
