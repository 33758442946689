import { Dropdown } from '@goosechase/ui';
import { TFunction } from 'i18next';
import { MissionOrder } from '../../data/models';
import { useTranslate } from '../../util/i18n';

interface MissionOrderDropdownProps {
  value?: MissionOrder;
  onChange: (value: MissionOrder) => void;
}

const makeOptions = (t: TFunction) => [
  {
    id: MissionOrder.Points,
    title: t(`options.points.title`),
    description: t(`options.points.description`),
  },
  {
    id: MissionOrder.Name,
    title: t(`options.name.title`),
    description: t(`options.name.description`),
  },
  {
    id: MissionOrder.Random,
    title: t(`options.random.title`),
    description: t(`options.random.description`),
  },
  {
    id: MissionOrder.Custom,
    title: t(`options.custom.title`),
    description: t(`options.custom.description`),
  },
];

export const MissionOrderDropdown = ({ value, onChange }: MissionOrderDropdownProps) => {
  const { t } = useTranslate('pages.missions.missionOrder.dropdown');

  const options = makeOptions(t);

  return (
    <Dropdown
      buttonClassName="w-full"
      optionsClassName="max-h-none"
      label={value ? t(`options.${value.toLowerCase()}.title`) : t('label')}
      options={options}
      selectedOptionId={value}
      onSelect={(v: string) => onChange(v as MissionOrder)}
    />
  );
};
