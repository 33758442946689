import classNames from 'classnames';
export var BUTTON_TYPES = ['DEFAULT', 'DESTRUCTIVE'];
export var getButtonIconProps = function (isFilledButton, buttonType) {
    if (buttonType === void 0) { buttonType = 'DEFAULT'; }
    if (isFilledButton) {
        if (buttonType === 'DESTRUCTIVE') {
            return {
                iconColor: 'black',
                iconDisabledColor: 'white',
            };
        }
        else {
            return {
                iconColor: 'highlighterYellow',
                iconDisabledColor: 'white',
            };
        }
    }
    // Outlined buttons and Text buttons
    if (buttonType === 'DESTRUCTIVE') {
        return {
            iconColor: 'vibrantRed',
            iconDisabledColor: 'black-24',
            iconHoverClassNames: classNames('group-hover:fill-black', 'group-hover:stroke-black'),
        };
    }
    else {
        return {
            iconColor: 'vibrantBlue',
            iconDisabledColor: 'black-24',
            iconHoverClassNames: classNames('group-hover:fill-black', 'group-hover:stroke-black'),
        };
    }
};
// BaseButtonClassNames are to be used by all buttons, regardless of style (eg. filled, outlined)
export var getBaseButtonClassNames = function (disabled) {
    var baseClassNames = [
        'group',
        'flex',
        'items-center',
        'select-none',
        'outline-black',
        'outline-offset-4',
    ];
    var interactiveClassNames = disabled
        ? 'cursor-auto'
        : ['cursor-pointer', 'focus:outline', 'focus:outline-2', 'focus:outline-offset-2'];
    return classNames(baseClassNames, interactiveClassNames);
};
export var getButtonLabelClassNames = function (isFilledButton, disabled, buttonType) {
    if (buttonType === void 0) { buttonType = 'DEFAULT'; }
    if (isFilledButton) {
        if (disabled) {
            return 'text-white';
        }
        else if (buttonType === 'DESTRUCTIVE') {
            return classNames('text-white', 'hover:text-highlighterYellow');
        }
        else {
            return classNames('text-highlighterYellow');
        }
    }
    if (disabled) {
        return 'text-black-24';
    }
    else if (buttonType === 'DESTRUCTIVE') {
        return classNames('text-vibrantRed', 'hover:text-black');
    }
    else {
        return classNames('text-vibrantBlue', 'hover:text-black');
    }
};
// ButtonTypeClassNames are separate from BaseButtonClassNames as they depend on the style of the button
export var getButtonTypeClassNames = function (isFilledButton, disabled, buttonType) {
    if (buttonType === void 0) { buttonType = 'DEFAULT'; }
    if (isFilledButton) {
        if (disabled) {
            return classNames('bg-black-24', 'border-black-24');
        }
        else if (buttonType === 'DESTRUCTIVE') {
            return classNames('bg-vibrantRed', 'border-vibrantRed', 'hover:bg-black', 'hover:border-black');
        }
        else {
            return classNames('bg-vibrantBlue', 'border-vibrantBlue', 'hover:bg-black', 'hover:border-black');
        }
    }
    if (disabled) {
        return classNames('bg-white', 'border-black-24');
    }
    else if (buttonType === 'DESTRUCTIVE') {
        return classNames('bg-white', 'border-vibrantRed', 'hover:bg-white', 'hover:border-black');
    }
    else {
        return classNames('bg-white', 'border-vibrantBlue', 'hover:bg-white', 'hover:border-black');
    }
};
