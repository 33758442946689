/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

module.hot?.accept();
export type HostedCheckoutPageFragmentFragment = { __typename: 'HostedCheckoutPage', id: string, type: string, url: string, state: string, embed: boolean, created_at: number, expires_at: number, object: string, updated_at: number, resource_version: number };

export const HostedCheckoutPageFragmentFragmentDoc = `
    fragment HostedCheckoutPageFragment on HostedCheckoutPage {
  __typename
  id
  type
  url
  state
  embed
  created_at
  expires_at
  object
  updated_at
  resource_version
}
    `;
