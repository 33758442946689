var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Dialog, DialogPanel } from '@headlessui/react';
import { getDataTestIDProp } from '../util/test-id';
import { mergeClasses } from '../util/merge-classes';
import { ModalContext, ModalHeader, ModalBody, ModalFooter } from './modal-context';
var Modal = function (_a) {
    var className = _a.className, overlayClassName = _a.overlayClassName, show = _a.show, children = _a.children, onClose = _a.onClose, testID = _a.testID;
    return (_jsx(Dialog, __assign({ open: show, onClose: onClose }, getDataTestIDProp(testID, 'wrapper'), { children: _jsx("div", __assign({ className: mergeClasses('fixed z-[999] top-0 left-0 w-full h-full flex items-center justify-center bg-black/50 overflow-y-auto', overlayClassName) }, { children: _jsx(DialogPanel, { children: _jsx(ModalContext.Provider, __assign({ value: { onClose: onClose, testID: testID } }, { children: _jsx("div", __assign({ className: mergeClasses('bg-white rounded-xl border border-black-12 overflow-hidden', className) }, getDataTestIDProp(testID, 'content'), { children: children })) })) }) })) })));
};
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
export { Modal };
