import { Button } from '@goosechase/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { LabelledField } from 'components/labelled-field.component';
import { PhotoUploadField, PhotoUploadUIProps } from 'components/photo-upload';
import { useUpdateWorkspaceMutation } from 'data/workspaces';
import { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { displayToast } from 'services/toast';
import { Nullable } from 'types/util';
import { transformEmptyInputToNull } from 'util/form-util';
import { useTranslate } from 'util/i18n';
import { z } from 'zod';

import { PhotoUploadUI } from './photo-upload-ui.component';

export interface WorkspaceData {
  photoId?: Nullable<string>;
  displayName: string;
}

const useWorkspaceForm = (defaultData: WorkspaceData) => {
  const { t } = useTranslate('pages.workspace.tabs.settings');

  return useForm<WorkspaceData>({
    mode: 'onBlur',
    defaultValues: {
      displayName: defaultData.displayName,
      photoId: defaultData.photoId,
    },
    resolver: zodResolver(
      z.object({
        displayName: z.string().min(1, t('displayName.errors.required') ?? 'Name is required'),
        photoId: z.string().optional().nullable().transform(transformEmptyInputToNull),
      }),
    ),
  });
};

type UpdateWorkspaceFormProps = {
  workspaceId: string;
  photoUrl?: Nullable<string>;
  displayName: string;
  canUpdateSettings: boolean;
};

export const UpdateWorkspaceForm = (props: UpdateWorkspaceFormProps) => {
  const { control, formState, handleSubmit, setValue } = useWorkspaceForm({
    displayName: props.displayName,
    photoId: undefined,
  });
  const { t } = useTranslate('pages.workspace.tabs.settings');
  const [uploading, setUploading] = useState(false);
  const [updateWorkspace, { isLoading }] = useUpdateWorkspaceMutation();

  const onSubmit: SubmitHandler<WorkspaceData> = async (values) => {
    const data = await updateWorkspace({
      params: {
        workspaceId: props.workspaceId,
        photoId: values.photoId,
        displayName: values.displayName,
      },
    }).unwrap();
    displayToast({
      body: t('toasts.success.body'),
      id: data.updateWorkspace.id,
      title: t('toasts.success.title'),
    });
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="photoId"
        render={({ fieldState: { error } }) => (
          <PhotoUploadField
            label=""
            setValue={(photoId) => setValue('photoId', photoId)}
            setError={() => {}}
            errorMessage={error?.message}
            defaultSrc={props?.photoUrl ?? ''}
            setUploading={setUploading}
            uploading={uploading}
            photoUploadUI={(data: PhotoUploadUIProps) => (
              <PhotoUploadUI {...data} disabled={!props.canUpdateSettings} />
            )}
          />
        )}
      />

      <Controller
        control={control}
        name="displayName"
        render={({ field, fieldState }) => (
          <LabelledField
            label={t('displayName.label')}
            type="text"
            onChange={field.onChange}
            onBlur={field.onBlur}
            errorMessage={fieldState.error?.message}
            disabled={isLoading || !props.canUpdateSettings}
            defaultValue={field.value}
          />
        )}
      />
      <Button
        className="w-fit"
        label={t('save')}
        disabled={!formState.isValid || uploading || isLoading || !props.canUpdateSettings}
      />
    </form>
  );
};
