import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Button } from '@goosechase/ui';

import { useTranslate } from '../../util/i18n';
import { DEFAULT_FEED_VISIBILITY } from './constants';
import { useMissionForm, MissionFormOutputData, UseMissionFormArgs } from './use-mission-form';
import { answersAreEligibleForApproximation } from '../../util/mission-util';
import { TabsRow } from '../tabs-row';
import { MissionContent } from './mission-content.component';
import { MissionAvailability } from './mission-availability.component';
import { MissionSettings } from './mission-settings.component';
import { useUpdateEffect } from '../../hooks/use-update-effect';
import { Nullable } from 'types/util';
import { DraftModeBanner } from './draft-mode-banner';
import { MissionStatus } from 'data/models';

const MISSION_FORM_TABS = ['content', 'availability', 'settings'];

export interface MissionFormUIProps {
  defaultValues: UseMissionFormArgs['defaultValues'];
  isLoading: boolean;
  onSubmit: (data: MissionFormOutputData) => void;
  onCancel: () => void;
  missionImage?: string;
  cohortStartDate?: Nullable<string>;
  cohortEndDate?: Nullable<string>;
  experienceTimezone: string;
  missionStatus: Nullable<MissionStatus>;
}

// TODO: Mobile sizing once all features are added
export const MissionFormUI = ({
  defaultValues,
  isLoading,
  onSubmit,
  onCancel,
  missionImage,
  cohortEndDate,
  cohortStartDate,
  experienceTimezone,
  missionStatus,
}: MissionFormUIProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { t } = useTranslate('missionForm');

  const { control, handleSubmit, setValue, trigger } = useMissionForm({
    defaultValues,
    cohortEndDate,
    cohortStartDate,
    experienceTimezone,
    missionStatus,
  });

  const missionType = useWatch({
    control,
    name: 'missionType',
  });

  const answers = useWatch({
    control,
    name: 'answers',
  });

  const draft = useWatch({
    control,
    name: 'draft',
  });

  // Changes some default form values whenever we toggle between different mission types
  useUpdateEffect(() => {
    // useUpdateEffect instead of useEffect to maintain feedVisibility when editing an existing mission
    setValue('feedVisibility', DEFAULT_FEED_VISIBILITY[missionType]);
    // Only want to run this when missionType changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionType]);

  // we want to skip the first render with useUpdateEffect so we don't overwrite the API value for allowApproximateAnswers
  useUpdateEffect(() => {
    if (!answersAreEligibleForApproximation(answers)) {
      // Whenever the answers list becomes ineligible for approximation we want to set the flag to false
      // to avoid sending ineligible answers with a flag set to true to the server.
      setValue('allowApproximateAnswers', false);
    } else {
      setValue('allowApproximateAnswers', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  const tabs = MISSION_FORM_TABS.map((tab) => ({ name: tab, label: t(`tabs.${tab}`) }));

  const handleSelectTab = (tab: number) => {
    setSelectedTab(tab);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-center py-5  border-black-24 border-b">
        <TabsRow tabs={tabs} selectedTab={selectedTab} onSelectTab={handleSelectTab} />
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col grow justify-between min-h-0">
        <div className="flex flex-col grow w-full overflow-auto px-11 py-7">
          {draft ? (
            <div className="mb-6">
              <DraftModeBanner
                onClick={
                  MISSION_FORM_TABS[selectedTab] !== 'availability'
                    ? () => handleSelectTab(1)
                    : undefined
                }
              />
            </div>
          ) : null}

          {MISSION_FORM_TABS[selectedTab] === 'content' && (
            <MissionContent
              control={control}
              isLoading={isLoading}
              missionType={missionType}
              setFormValue={setValue}
              missionImage={missionImage}
            />
          )}
          {MISSION_FORM_TABS[selectedTab] === 'availability' && (
            <MissionAvailability
              control={control}
              cohortEndDate={cohortEndDate}
              cohortStartDate={cohortStartDate}
              trigger={trigger}
            />
          )}
          {MISSION_FORM_TABS[selectedTab] === 'settings' && (
            <MissionSettings missionType={missionType} control={control} />
          )}
        </div>

        <div className="flex flex-row gap-4 py-6 px-11 border-t-black-24 border-t justify-self-end shrink-0">
          <Button type="button" outlined size="sm" label={t('cancel')} onClick={onCancel} />
          {/* TODO: save button spinner */}
          <Button size="sm" label={t('saveChanges')} />
        </div>
      </form>
    </div>
  );
};
