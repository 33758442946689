import { Checkbox, Headline, Text, Label } from '@goosechase/ui';
import { NumberInput } from '../number-input';
import { Nullable } from 'types/util';
import { useTranslate } from 'util/i18n';

interface MaxSizeInputProps {
  label: string;
  name: string;
  value: Nullable<number>;
  onChange: (value: Nullable<number>) => void;
  onBlur: (value: Nullable<number>) => void;
  errorMessage?: string;
}

export const MaxSizeInput = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  errorMessage,
}: MaxSizeInputProps) => {
  const { t } = useTranslate('maxSizeInput');

  const handleCheckboxChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.checked) {
      onChange(null);
    } else {
      onChange(5);
    }
  };

  const handleInputChange = (updatedValue: number | null) => {
    onChange(updatedValue);
  };

  return (
    <div className="flex flex-col gap-2">
      <Headline size="xs" type="secondary" className="block mb-3">
        {label}
      </Headline>
      <div className="flex flex-col tablet-narrow:flex-row gap-7">
        <div>
          <NumberInput
            name={name}
            label={label}
            onChange={handleInputChange}
            onBlur={onBlur}
            value={value}
            disabled={value === null}
            min={1}
            placeholder={value === null ? t('unlimited') ?? undefined : undefined}
            error={Boolean(errorMessage)}
            className="w-full"
          />
          {errorMessage ? (
            <Text size="xs" className="text-vibrantRed mt-2">
              {errorMessage}
            </Text>
          ) : null}
        </div>
        <div className="flex flex-row gap-2 items-center tablet-narrow:mb-4">
          <Checkbox id="unlimited" onChange={handleCheckboxChange} checked={value === null} />
          <Label className="text-black-64 font-normal text-sm" size="sm" htmlFor="unlimited">
            {t('unlimited')}
          </Label>
        </div>
      </div>
    </div>
  );
};
