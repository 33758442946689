import { AccountDropdown } from '../header-nav-bar/account-dropdown.component';
import { HeaderNavBar, NAV_BAR_HEIGHT } from '../header-nav-bar';
import { ExperienceSectionTabs } from '../experience-section-tabs';

import { ExperienceTitle } from './experience-title.component';
import { ExperienceStatusBadgeLink } from '../experience-status-badge';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';

interface SecondaryHeaderRowProps {
  experienceId: string;
}

const SecondaryHeaderRow = ({ experienceId }: SecondaryHeaderRowProps) => (
  <div className="flex grow items-center desktop:hidden justify-between">
    <ExperienceSectionTabs experienceId={experienceId} />
    {/* TODO: Introduce menu dropdown */}
    {/* https://app.asana.com/0/0/1205382045007343/f */}
    {/* <Icon className="cursor-pointer" name="Menu" size={24} color="white" /> */}
  </div>
);

// TODO: Add experience title, state, and type
export const ExperienceHeaderNavBar = () => {
  const { experienceId, data } = useExperienceInfo();
  const displayName = data?.experience?.displayName;
  const experienceStatus = data?.experience?.status;

  return (
    <HeaderNavBar
      variant="experience"
      secondaryRow={<SecondaryHeaderRow experienceId={experienceId} />}>
      <>
        <div className="flex flex-row basis-4/5 tablet-narrow:flex-col tablet-narrow:basis-3/5 desktop:basis-1/3 h-full items-center tablet-narrow:items-start justify-between tablet-narrow:justify-center min-w-0 text-white gap-1 tablet-narrow:gap-0.5">
          <ExperienceTitle title={displayName ?? ''} />
          {experienceStatus && (
            <ExperienceStatusBadgeLink
              experienceId={experienceId}
              className="tablet-narrow:hidden"
              status={experienceStatus}
            />
          )}
        </div>
        <div className="hidden desktop:flex basis-1/3 h-full justify-center">
          <ExperienceSectionTabs experienceId={experienceId} />
        </div>
        <div className="flex basis-1/5 tablet-narrow:basis-2/5 desktop:basis-1/3 h-full justify-end items-center gap-5">
          {experienceStatus && (
            <ExperienceStatusBadgeLink
              experienceId={experienceId}
              className="hidden tablet-narrow:flex"
              status={experienceStatus}
            />
          )}
          <AccountDropdown className={NAV_BAR_HEIGHT} variant="experience" />
        </div>
      </>
    </HeaderNavBar>
  );
};
