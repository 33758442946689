import * as Sentry from '@sentry/react';
import { Modal } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { Trans } from 'react-i18next';
import { Addons } from 'components/addons';
import {
  useAvailableAddonsQuery,
  useStartAddonCheckoutMutation,
  useCompleteAddonCheckoutMutation,
} from 'data/addons';
import {
  toggleUpgradeExperienceModal,
  useLoadExperienceAddonDetailsQuery,
  useUpgradeExperienceModalOpen,
} from 'data/experiences';
import { useExperienceInfo } from 'hooks/use-experience-info.hook';
import { useMemo } from 'react';
import { getChargebeeInstance } from 'services/chargebee';
import { displayToast, useGenericErrorToast } from 'services/toast';
import { useAppDispatch } from 'data/hooks';
import { SubscriptionBanner } from './subscription-banner.component';
import { RequestQuoteForm } from 'components/request-quote';

export const ExperienceAddonModal = () => {
  const show = useUpgradeExperienceModalOpen();
  const dispatch = useAppDispatch();

  const { t } = useTranslate('pages.upgradeExperience');
  const { data: addons } = useAvailableAddonsQuery();
  const { experienceId, data: experienceInfo } = useExperienceInfo();
  const { data: pageDetails } = useLoadExperienceAddonDetailsQuery(
    { id: experienceId },
    { skip: !experienceId },
  );
  const activeExperience = useMemo(() => pageDetails?.experience, [pageDetails]);

  const purchasedAddonIds = useMemo(
    () => activeExperience?.addons.map((addon) => addon.id) ?? [],
    [activeExperience],
  );

  const [startAddonCheckout] = useStartAddonCheckoutMutation();
  const [completeAddonCheckout] = useCompleteAddonCheckoutMutation();
  const chargebee = getChargebeeInstance();
  const displayErrorToast = useGenericErrorToast();

  const handleAddonSelect = (addonId: string) => () => {
    if (!activeExperience) {
      return;
    }
    chargebee.openCheckout({
      hostedPage: () => {
        return startAddonCheckout({ addonId: addonId, experienceId: activeExperience.id })
          .unwrap()
          .then((response) => response.startAddonCheckout);
      },
      success: async (checkoutId: string) => {
        try {
          await completeAddonCheckout({
            checkoutId,
          }).unwrap();
          dispatch(toggleUpgradeExperienceModal());
        } catch (e) {
          // If this error occurs, it means the user successfully purchased an addon
          //   but something went wrong and we failed to apply it to their experience
          Sentry.captureException(e, { tags: { checkoutId } });
          displayErrorToast();
        }
      },
      error: () => {
        displayToast({
          id: 'checkout-error-toast',
          body: (
            <Trans
              t={t}
              i18nKey="editErrorToast.body"
              values={{ experienceName: experienceInfo?.experience?.displayName }}
            />
          ),
          title: t('editErrorToast.title'),
          type: 'error',
        });
        // TODO: log to sentry
      },
    });
  };

  const handleClose = () => {
    dispatch(toggleUpgradeExperienceModal());
  };

  return (
    <Modal
      className="flex flex-col overflow-y-auto tablet-wide:overflow-y-hidden border-0 w-[calc(100vw-40px)] h-[calc(100vh-112px)] rounded-xl tablet-narrow:w-[calc(100vw-40px)] tablet-wide:w-[calc(100vw-112px)] desktop:w-[1160px] relative"
      show={show}
      onClose={handleClose}>
      <Modal.Header title={t('title') ?? ''} />
      <Modal.Body className="bg-darkGreen overflow-auto p-0">
        <div className="flex flex-col gap-10">
          <div className="py-6 px-6 pb-0">
            <SubscriptionBanner />
          </div>
          <div className="grid grid-cols-1 tablet-narrow:grid-cols-2 desktop:grid-cols-4 gap-2 w-full tablet-narrow:max-w-[800px] desktop:max-w-full mx-auto px-6">
            <Addons
              addons={addons?.availableAddons ?? []}
              onClick={handleAddonSelect}
              purchasedAddonIds={purchasedAddonIds}
            />
          </div>
          <div className="bg-white py-10 px-6">
            <RequestQuoteForm />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
