import { Icon, Text } from '@goosechase/ui';
import { Link } from './link.component';

interface Breadcrumb {
  label: string;
  href: string;
}

export interface BreadcrumbsProps {
  activeBreadcrumbLabel: string;
  previousBreadcrumbs: Breadcrumb[];
}

export const Breadcrumbs = ({ activeBreadcrumbLabel, previousBreadcrumbs }: BreadcrumbsProps) => (
  <div className="flex flex-row gap-3 items-start tablet-narrow:items-center">
    {previousBreadcrumbs.map(({ label, href }) => (
      <div key={label} className="flex flex-row gap-3 items-center">
        <Link useRouter href={href}>
          <Text size="sm">{label}</Text>
        </Link>
        <Icon className="-rotate-90" size={16} name="CaretDown" />
      </div>
    ))}
    <Text className="font-bold" size="sm">
      {activeBreadcrumbLabel}
    </Text>
  </div>
);
