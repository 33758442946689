import { SetStateAction } from 'react';
import { useTranslate } from 'util/i18n';
import { mergeClasses, TextField } from '@goosechase/ui';

interface BroadcastSearchProps {
  searchValue: string;
  setSearchValue: (val: SetStateAction<string>) => void;
  disabled: boolean;
}

const BroadcastSearch = ({ searchValue, setSearchValue, disabled }: BroadcastSearchProps) => {
  const { t } = useTranslate('pages.broadcasts.list');

  const handleSearchOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!disabled) {
      setSearchValue(e.target.value);
    }
  };

  return (
    <div className="p-4">
      <TextField
        className={mergeClasses({
          'bg-black-4': disabled,
        })}
        placeholder={t('searchPlaceholder') ?? undefined}
        value={searchValue}
        onChange={handleSearchOnChange}
        disabled={disabled}
        rounded
        leftIcon="Search"
      />
    </div>
  );
};

export default BroadcastSearch;
