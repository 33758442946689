import { useEffect, useState } from 'react';
import { Button, Modal } from '@goosechase/ui';
import { TeamFormUI } from '../../components/team-form/team-form-ui';

import { TeamMode } from '../../data/models';
import { useTranslate } from 'util/i18n';
import { type TeamFormOutputData, useTeamForm } from '../../components/team-form/use-team-form';
import { useActiveCohortId } from 'data/experiences';
import { useCreateTeamMutation } from 'data/teams';
import { displayToast } from 'services/toast';
import { Nullable } from 'types/util';

interface CreateTeamModalProps {
  defaultTeamMode: TeamMode;
  defaultTeamMaxSize: Nullable<number>;
  shouldDisableIndividualMode?: boolean;
  shouldDisableCreateNewTeam: boolean;
}

export const CreateTeamModal = ({
  defaultTeamMode,
  defaultTeamMaxSize,
  shouldDisableIndividualMode,
  shouldDisableCreateNewTeam,
}: CreateTeamModalProps) => {
  const { t } = useTranslate('pages.participants');
  const { formState, control, handleSubmit, setValue, watch, reset } = useTeamForm({
    defaultValues: {
      displayName: '',
      passcode: '',
      mode: defaultTeamMode,
      maxSize: defaultTeamMaxSize,
    },
    numParticipants: 0,
  });
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [uploadingPhoto, setUploadingPhoto] = useState(false);

  const handleShowModal = () => {
    setShowCreateTeamModal(true);
  };

  const handleCloseModal = () => {
    setShowCreateTeamModal(false);
    reset({
      displayName: '',
      passcode: '',
      mode: defaultTeamMode,
      maxSize: defaultTeamMaxSize,
    });
  };

  const cohortId = useActiveCohortId();

  const [createTeam, { isLoading }] = useCreateTeamMutation();

  useEffect(() => {
    setValue('mode', defaultTeamMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTeamMode]);

  const handleCreateTeam = async ({ passcode, ...data }: TeamFormOutputData) => {
    try {
      if (!cohortId) {
        throw new Error('No cohort set, cannot create team');
      }
      await createTeam({
        params: {
          cohortId,
          ...data,
          // Prevents sending an empty passcode
          passcode: passcode?.length > 0 ? passcode : undefined,
          maxSize: data.maxSize,
        },
      }).unwrap();
      handleCloseModal();
    } catch (e) {
      displayToast({
        id: data.id,
        title: t('teamForm.createErrorToast.title'),
        body: t('teamForm.createErrorToast.body'),
        type: 'error',
      });
    }
  };

  const teamMode = watch('mode');

  useEffect(() => {
    if (teamMode === TeamMode.Solo) {
      setValue('maxSize', 1);
    } else {
      setValue('maxSize', defaultTeamMaxSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTeamMaxSize, teamMode]);

  return (
    <>
      <Button
        label={t('teams.add')}
        leftIcon="Plus"
        onClick={handleShowModal}
        disabled={shouldDisableCreateNewTeam}
      />
      <Modal
        show={showCreateTeamModal}
        onClose={handleCloseModal}
        className="w-[calc(100vw-16px)] max-h-[calc(100vh-16px)] tablet-narrow:w-[498px] desktop:max-w-[709px] desktop:w-full overflow-auto">
        <Modal.Header title={t('teamForm.modal.title.create') ?? undefined} />
        <form onSubmit={handleSubmit(handleCreateTeam)}>
          <Modal.Body className="overflow-y-auto">
            <TeamFormUI
              isLoading={isLoading}
              control={control}
              setValue={setValue}
              uploadingPhoto={uploadingPhoto}
              setUploadingPhoto={setUploadingPhoto}
              teamCreationMode={teamMode}
              context="create"
              shouldDisableIndividualMode={shouldDisableIndividualMode}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              label={t('teamForm.modal.confirm')}
              disabled={!formState.isValid || uploadingPhoto}
            />
            <Button label={t('teamForm.modal.cancel')} onClick={handleCloseModal} outlined={true} />
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
