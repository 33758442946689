/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type CreateAssetMutationVariables = Types.Exact<{
  params: Types.CreateAssetParams;
}>;


export type CreateAssetMutation = { __typename: 'Mutation', createAsset: { __typename: 'CreateAssetPayload', presignedUrl: string, assetId: string } };


export const CreateAssetDocument = `
    mutation CreateAsset($params: CreateAssetParams!) {
  createAsset(params: $params) {
    presignedUrl
    assetId
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    CreateAsset: build.mutation<CreateAssetMutation, CreateAssetMutationVariables>({
      query: (variables) => ({ document: CreateAssetDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateAssetMutation } = injectedRtkApi;

