/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type AuthenticateImpersonationSessionMutationVariables = Types.Exact<{
  sessionId: Types.Scalars['ID']['input'];
}>;


export type AuthenticateImpersonationSessionMutation = { __typename: 'Mutation', claimImpersonationSession: { __typename: 'ImpersonationAuthResponse', accessToken: string } | null };


export const AuthenticateImpersonationSessionDocument = `
    mutation AuthenticateImpersonationSession($sessionId: ID!) {
  claimImpersonationSession(sessionId: $sessionId) {
    accessToken
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    AuthenticateImpersonationSession: build.mutation<AuthenticateImpersonationSessionMutation, AuthenticateImpersonationSessionMutationVariables>({
      query: (variables) => ({ document: AuthenticateImpersonationSessionDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAuthenticateImpersonationSessionMutation } = injectedRtkApi;

