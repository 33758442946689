import { TextButton, Text } from '@goosechase/ui';
import { Link } from 'components/link.component';
import { AppRoutes } from 'constants/routes';
import { toggleUpgradeExperienceModal } from 'data/experiences';
import { useAppDispatch } from 'data/hooks';
import { Trans } from 'react-i18next';
import { useTranslate } from 'util/i18n';

type InlineBannerProps = {
  i18nKey: string;
  i18nValues?: Record<string, number | null>;
  showUpgradePlanAction?: boolean;
  showUpgradeExperienceAction?: boolean;
};

export const InlineBanner = ({
  i18nKey,
  i18nValues,
  showUpgradePlanAction,
  showUpgradeExperienceAction,
}: InlineBannerProps) => {
  const { t } = useTranslate('inlineBanner');
  const dispatch = useAppDispatch();

  const handleUpgradeExperienceClick = () => {
    dispatch(toggleUpgradeExperienceModal());
  };

  return (
    <div className="bg-yellowOrange-10 rounded-lg border border-yellowOrange p-4 flex gap-3 flex-col tablet-narrow:flex-row tablet-narrow:items-center justify-between">
      <Text>
        <Trans t={t} i18nKey={i18nKey} values={i18nValues} />
      </Text>
      <div className="flex items-center gap-2">
        {showUpgradeExperienceAction ? (
          <div className="w-min">
            <TextButton
              label={t('upgradeThisExperience')}
              onClick={handleUpgradeExperienceClick}
              className="bg-white border-2 border-yellowOrange text-black"
              leftIcon="YellowOrangeZap"
              iconClassName="w-4 h-4"
            />
          </div>
        ) : null}
        {showUpgradeExperienceAction && showUpgradePlanAction ? (
          <>
            <Text>{t('or')}</Text>
            <Link
              href={AppRoutes.PLANS}
              target="_blank"
              className="font-soleil text-vibrantBlue font-semibold">
              {t('viewPlans')}
            </Link>
          </>
        ) : null}
        {showUpgradePlanAction && !showUpgradeExperienceAction ? (
          <div className="w-min">
            <Link href={AppRoutes.PLANS} target="_blank">
              <TextButton
                label={t('upgradeYourPlan')}
                className="bg-white border-2 border-yellowOrange text-black"
                leftIcon="YellowOrangeZap"
                iconClassName="w-4 h-4"
              />
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};
