import { displayToast } from 'services/toast';
import { useTranslate } from 'util/i18n';
import { getConfig } from '../constants/config';
import { AppRoutes } from '../constants/routes';

const PUBLIC_SUBMISSION_BASE_URL = getConfig().PUBLIC_SUBMISSION_BASE_URL;

export const useCopySubmissionLink = () => {
  const { t } = useTranslate('copySubmissionLink');

  return (submissionId: string) =>
    navigator.clipboard
      .writeText(`${PUBLIC_SUBMISSION_BASE_URL}${AppRoutes.PUBLIC.SUBMISSION}/${submissionId}`)
      .then(() => {
        displayToast({
          id: submissionId,
          type: 'success',
          title: t('linkCopiedToast.title'),
          body: t('linkCopiedToast.body'),
        });
      });
};
