import { SubmissionFeedQuery } from 'data/submissions';
import { type Submission } from '../../components/submission-grid';

type FeedSubmission = Submission & { missionId: string; teamId: string };

export const convertGqlFeedSubmissionToUIFeedSubmission = (
  submissions: SubmissionFeedQuery['submissionFeed']['edges'],
): FeedSubmission[] =>
  submissions.map(({ node: submission }) => {
    const fields = {
      submissionId: submission.id,
      submissionDate: new Date(submission.createdAt),
      teamId: submission.team.id,
      teamMode: submission.team.mode,
      teamName: submission.team.displayName,
      teamImageUrl: submission.team.photoUrl ?? undefined,
      missionId: submission.mission.id,
      missionName: submission.mission.displayName,
      missionDescription: submission.mission.description,
      missionPoints: submission.mission.points,
      missionType: submission.mission.__typename,
      isLiked: submission.liked,
      numLikes: submission.numLikes,
      submissionBonuses: submission.bonuses.map((bonus) => ({
        value: bonus.value,
        note: bonus.note,
        bonusId: bonus.id,
        createdAt: new Date(bonus.createdAt),
      })),
      informationTagsMission: submission.mission,
      bonusPoints: submission.bonusPoints,
    };

    if (submission.__typename === 'CameraSubmission') {
      const submissionImageSrc = submission.assets.find((asset) => asset.role === 'PHOTO')?.url;
      const submissionVideoSrc = submission.assets.find((asset) => asset.role === 'VIDEO')?.url;

      return {
        ...fields,
        submissionCaption: submission.caption ?? undefined,
        submissionImageSrc,
        submissionVideoSrc,
        submissionDownloadSrc:
          submission.assets.find((asset) => asset.role === 'VIDEO')?.url ?? submissionImageSrc,
      };
    } else if (submission.__typename === 'TextSubmission') {
      return {
        ...fields,
        submissionAnswer: submission.answer,
      };
    }

    return {
      ...fields,
      submissionCaption: submission.caption ?? undefined,
      submissionLatitude: submission.latitude,
      submissionLongitude: submission.longitude,
    };
  });
