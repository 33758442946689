import { saveAs } from 'file-saver';
import { MissionTypeName } from 'data/models';

interface MakeDownloadHandlerArgs {
  missionType?: MissionTypeName;
  mediaSrc?: string;
  filename?: string;
}

export const useDownloadSubmission = ({
  missionType,
  mediaSrc,
  filename,
}: MakeDownloadHandlerArgs) => {
  if (!(missionType === 'CameraMission' && mediaSrc)) {
    // Returns no function if the submission isn't a CameraMission and doesn't have a valid mediaSrc
    return undefined;
  }

  return () => {
    saveAs(mediaSrc, filename);
  };
};
