import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';

import { WelcomeBanner } from './welcome-banner';
import { AcceptedWorkspaceInvitationModal } from 'components/accepted-invitation-modal';
import { useMultipleWorkspacesFeatureFlag } from '../../hooks/use-feature-flag.hook';
import { OldExperiencesPage } from './old-experiences.page';
import { Experiences } from './experiences.component';
import { ExperienceListFilter } from '../../components/experience-list-filter';

export const ExperiencesPage = () => {
  const { t } = useTranslate('pages.experiences');

  return (
    <Page title={t('meta.title')}>
      <div className="flex flex-col w-full">
        <WelcomeBanner />
        <AcceptedWorkspaceInvitationModal />
        <div className="flex gap-6 justify-center py-10 px-4 tablet-narrow:px-5 w-full max-w-[1264px] mx-auto">
          <div className="hidden tablet-wide:block min-w-[200px] max-w-[252px] mt-2.5">
            <ExperienceListFilter />
          </div>
          <div className="w-full max-w-[712px] min-w-0">
            <Experiences />
          </div>
        </div>
      </div>
    </Page>
  );
};

// TODO: Delete OldExperiencesPage and Variant after feature is live
export const ExperiencesPageVariant = () => {
  const multipleWorkspacesFlag = useMultipleWorkspacesFeatureFlag();

  if (multipleWorkspacesFlag) {
    return <ExperiencesPage />;
  }

  return <OldExperiencesPage />;
};
