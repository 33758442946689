/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type RequestQuoteMutationVariables = Types.Exact<{
  params: Types.RequestQuoteParams;
}>;


export type RequestQuoteMutation = { __typename: 'Mutation', requestQuote: boolean | null };


export const RequestQuoteDocument = `
    mutation RequestQuote($params: RequestQuoteParams!) {
  requestQuote(params: $params)
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    RequestQuote: build.mutation<RequestQuoteMutation, RequestQuoteMutationVariables>({
      query: (variables) => ({ document: RequestQuoteDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useRequestQuoteMutation } = injectedRtkApi;

