import { Controller, Control } from 'react-hook-form';

import type { MissionFormInputData } from './use-mission-form';
import { FeedVisibilityDropdown } from './feed-visibility-dropdown.component';
import { MediaTypeDropdown } from './media-type-dropdown.component';
import { SubmissionSourceDropdown } from './submission-source-dropdown.component';
import { MissionType } from '../../data/models';
interface MissionSettingsProps {
  missionType: MissionType;
  control: Control<MissionFormInputData>;
}

// TODO: Update dropdown components and icons
// https://app.asana.com/0/1102597710945296/1205366208937792/f
export const MissionSettings = ({ control, missionType }: MissionSettingsProps) => (
  <div className="flex flex-col grow gap-6">
    <Controller
      control={control}
      name="feedVisibility"
      render={({ field: { value, onChange } }) => (
        <FeedVisibilityDropdown value={value} onChange={onChange} />
      )}
    />
    {missionType === 'CAMERA' && (
      <>
        <Controller
          control={control}
          name="mediaType"
          render={({ field: { value, onChange } }) => (
            <MediaTypeDropdown value={value} onChange={onChange} />
          )}
        />
        <Controller
          control={control}
          name="allowCameraRollUploads"
          render={({ field: { value, onChange } }) => (
            <SubmissionSourceDropdown value={value} onChange={onChange} />
          )}
        />
      </>
    )}
  </div>
);
