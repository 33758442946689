import { GraphQLClient, ClientError } from 'graphql-request';
import { Mutex } from 'async-mutex';
import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';

import { getConfig } from 'constants/config';
import { AppState } from './store';
import { TOKEN_REFRESH_MUTATION, type RefreshResponse, getResponseData } from './token-refresh';
// Importing directly from auth.slice to avoid cyclic dependency with rootApi
import { setAuthState, clearAccessToken, logout } from './auth/auth.slice';
import { ApiError } from './errors';

const isUnauthorizedError = (error: unknown) => (error as ClientError)?.response?.status === 401;

const refreshMutex = new Mutex();

const client = new GraphQLClient(getConfig().API_BASE_URL, {
  // Passes httpOnly cookies with each request
  credentials: 'include',
});

const baseQuery = graphqlRequestBaseQuery<ApiError>({
  // NOTE: We are currently stuck on version 4.3.0 of graphql-request due to known incompatibilities with graphql-request-base-query
  // Issue logged here: https://github.com/reduxjs/redux-toolkit/issues/2931
  client,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as AppState).auth.accessToken;

    // If we have an accessToken set in state, add it to the auth header
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    // Pass the client's timezone (used for experiences)
    headers.set('X-Timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);

    return headers;
  },
  // By default, graphqlRequestBaseQuery does not deserialize standard GQL errors correctly
  // src: https://stackoverflow.com/questions/71828943/how-to-handle-error-format-in-redux-toolkit-rtk-query-graphql-application
  customErrors: (apiError): ApiError => {
    const response = apiError.response;
    if (response.status === 401 || response.status === 403) {
      throw apiError;
    }
    const error = response?.errors?.[0];
    // TODO: log all errors to sentry
    // For simplicitly, we only extract the first error
    return {
      message: error?.message ?? 'Something went wrong',
      code: error?.extensions?.code ?? 'UNEXPECTED_ERROR',
      ...(error?.extensions ?? {}),
    };
  },
});

// baseQuery wrapped to handle token refresh and retry logic
const baseQueryWithReauth: typeof baseQuery = async (args, api, extraOptions) => {
  // Wait until mutex is available - Locked means a refresh request is in progress
  await refreshMutex.waitForUnlock();
  try {
    return await baseQuery(args, api, extraOptions);
  } catch (error) {
    if (isUnauthorizedError(error)) {
      if (!refreshMutex.isLocked()) {
        // Lock mutex while we make a refresh request
        const release = await refreshMutex.acquire();
        // accesssToken is invalid -> Clear it
        api.dispatch(clearAccessToken());
        try {
          // Try to fetch a fresh accessToken
          const refreshResult = (await baseQuery(
            TOKEN_REFRESH_MUTATION,
            api,
            extraOptions,
          )) as RefreshResponse;

          const { accessToken, userId, intercomHmac } = getResponseData(refreshResult);
          if (accessToken) {
            api.dispatch(setAuthState({ accessToken, intercomHmac, userId }));
            try {
              // Retry the original request
              return await baseQuery(args, api, extraOptions);
            } catch (retryError) {
              if (isUnauthorizedError(retryError)) {
                // After refreshing the accessToken, we continue to run into a 401
                // Something went wrong -> Logout
                // TODO: Sentry - unexpected error after successful refresh
                api.dispatch(logout({ forced: true }));
              }
              throw retryError;
            }
          } else {
            // Refresh mutation returned a 200 response, but no accessToken was present (eg. UnknownError) -> Logout
            // TODO: Sentry - unexpected error from refresh
            api.dispatch(logout({ forced: true }));
            return refreshResult;
          }
        } catch (refreshError) {
          // Failed to refresh the accessToken -> Logout.
          api.dispatch(logout({ forced: true }));
          throw refreshError;
        } finally {
          // Release the lock after the request passes or fails
          release();
        }
      } else {
        await refreshMutex.waitForUnlock();
        return await baseQuery(args, api, extraOptions);
      }
    } else {
      // Not a 401, rethrow
      throw error;
    }
  }
};

// This root API can be extended by each data slice and is automatically while providing a singular entrypoint into the store
// As such, it should contain all configuration and logic that needs to be common to all API calls
// src: https://redux-toolkit.js.org/rtk-query/usage/code-splitting
export const rootApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: 'api',
  endpoints: () => ({}),
  tagTypes: [
    'MyExperiences',
    'ExperienceAddon',
    'ExperienceMissions',
    'ExperienceInfo',
    'ExperienceTeams',
    'SubmissionsOverview',
    'MissionSubmissions',
    'TeamSubmissions',
    'ExperienceFormDetails',
    'PublicSubmission',
    'SubmissionFeed',
    'Leaderboard',
    'Team',
    'ExperienceTriggers',
    'SubmissionArchives',
    'MissionTriggers',
    'Broadcasts',
    'MyUser',
    'ActiveWorkspaceWithSubscription',
    'ActiveWorkspace',
    'WorkspaceMembers',
    'PendingWorkspaceInvitations',
    'MyOwnedWorkspaces',
    'ExperienceFormBranding',
    'ExperienceManagers',
    'PendingExperienceInvitations',
    'Statistics',
    'MyPreviousMissions',
    'MySavedMissions',
    'SubmissionInfoStatistics',
    'WorkspaceExperiences',
    'MyPersonalExperiences',
    'MyWorkspaces',
    'MySharedExperiences',
    'MyWorkspaceExperiences',
    'Workspace',
    'IsEducatorProfileRequired',
  ],
});
