import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { getExperienceSubPageTitleOptions } from '../utils';
import { Card } from 'components/card.component';
import { Leaderboard } from './leaderboard.component';
import { PageHeadline } from '../../components/page-headline.component';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { TeamBonus } from './team-bonus.component';

export const LeaderboardPage = () => {
  const { t } = useTranslate('pages.leaderboard');
  const { data } = useExperienceInfo();
  const displayName = data?.experience?.displayName;

  return (
    <Page title={t('meta.title', getExperienceSubPageTitleOptions(displayName))}>
      <div className="flex flex-col gap-6">
        <PageHeadline>{t('title')}</PageHeadline>
        <div className="flex flex-col tablet-wide:flex-row gap-4">
          <Card className="w-full h-fit">
            <Leaderboard />
          </Card>
          <Card className="p-5 h-fit w-full tablet-wide:max-w-[340px]">
            <TeamBonus />
          </Card>
        </div>
      </div>
    </Page>
  );
};
