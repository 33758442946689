/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type ResetPasswordMutationVariables = Types.Exact<{
  passwordResetId: Types.Scalars['String']['input'];
  newPassword: Types.Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename: 'Mutation', resetPassword: void | null };


export const ResetPasswordDocument = `
    mutation ResetPassword($passwordResetId: String!, $newPassword: String!) {
  resetPassword(passwordResetId: $passwordResetId, newPassword: $newPassword)
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ResetPassword: build.mutation<ResetPasswordMutation, ResetPasswordMutationVariables>({
      query: (variables) => ({ document: ResetPasswordDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useResetPasswordMutation } = injectedRtkApi;

