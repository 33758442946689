import { mergeClasses } from '@goosechase/ui';

import { ReactComponent as GooseResetPassword } from 'media/goose-reset-password.svg';
import { PANEL_CLASSNAME } from 'components/account-form-layout.component';

export const ResetPasswordPanel = () => (
  <div className={mergeClasses(PANEL_CLASSNAME, 'p-24 bg-offWhite')}>
    <div className="flex w-full">
      <GooseResetPassword className="flex grow max-w-[302px] fill-black" />
    </div>
  </div>
);
