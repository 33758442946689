/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type CreateEducatorWorkspaceMutationVariables = Types.Exact<{
  displayName: Types.Scalars['String']['input'];
}>;


export type CreateEducatorWorkspaceMutation = { __typename: 'Mutation', createWorkspace: { __typename: 'Workspace', id: string, displayName: string, type: Types.WorkspaceType } };


export const CreateEducatorWorkspaceDocument = `
    mutation CreateEducatorWorkspace($displayName: String!) {
  createWorkspace(displayName: $displayName, type: EDUCATOR) {
    id
    displayName
    type
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    CreateEducatorWorkspace: build.mutation<CreateEducatorWorkspaceMutation, CreateEducatorWorkspaceMutationVariables>({
      query: (variables) => ({ document: CreateEducatorWorkspaceDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateEducatorWorkspaceMutation } = injectedRtkApi;

