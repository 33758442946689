/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type WorkspaceMembersQueryVariables = Types.Exact<{
  workspaceId: Types.Scalars['ID']['input'];
}>;


export type WorkspaceMembersQuery = { __typename: 'Query', workspaceMembers: Array<{ __typename: 'WorkspaceMember', userId: string, email: string, roleId: string, user: { __typename: 'User', profile: { __typename: 'UserProfile', photoUrl: string | null } | null } }> };


export const WorkspaceMembersDocument = `
    query WorkspaceMembers($workspaceId: ID!) {
  workspaceMembers(workspaceId: $workspaceId) {
    __typename
    userId
    email
    roleId
    user {
      profile {
        photoUrl
      }
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    WorkspaceMembers: build.query<WorkspaceMembersQuery, WorkspaceMembersQueryVariables>({
      query: (variables) => ({ document: WorkspaceMembersDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useWorkspaceMembersQuery, useLazyWorkspaceMembersQuery } = injectedRtkApi;

