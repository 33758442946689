import { Outlet } from 'react-router-dom';
import { MainHeaderNavBar } from './main-nav-bar';
import { UpgradeBanner } from './upgrade-banner';

export interface BaseWrapperProps {
  children?: React.ReactNode;
}

export const LayoutWrapper = ({ children }: BaseWrapperProps) => {
  return <div className="flex flex-col h-full w-full">{children}</div>;
};

export const HeaderWrapper = ({ children }: BaseWrapperProps) => {
  return <div className="relative tablet-narrow:sticky w-full z-10">{children}</div>;
};

export const ContentWrapper = ({ children }: BaseWrapperProps) => {
  return <div className="flex overflow-auto bg-offWhite h-screen">{children}</div>;
};

export const MainLayout = ({ children }: BaseWrapperProps) => {
  return (
    <LayoutWrapper>
      <UpgradeBanner />
      <HeaderWrapper>
        <MainHeaderNavBar />
      </HeaderWrapper>
      <ContentWrapper>{children ? children : <Outlet />}</ContentWrapper>
    </LayoutWrapper>
  );
};
