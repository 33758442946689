/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type DeleteSubmissionBonusMutationVariables = Types.Exact<{
  submissionBonusId: Types.Scalars['ID']['input'];
}>;


export type DeleteSubmissionBonusMutation = { __typename: 'Mutation', deleteSubmissionBonus: { __typename: 'SubmissionBonus', id: string } };


export const DeleteSubmissionBonusDocument = `
    mutation DeleteSubmissionBonus($submissionBonusId: ID!) {
  deleteSubmissionBonus(id: $submissionBonusId) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    DeleteSubmissionBonus: build.mutation<DeleteSubmissionBonusMutation, DeleteSubmissionBonusMutationVariables>({
      query: (variables) => ({ document: DeleteSubmissionBonusDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteSubmissionBonusMutation } = injectedRtkApi;

