/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type LoadExperienceStartAndEndQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type LoadExperienceStartAndEndQuery = { __typename: 'Query', cohort: { __typename: 'Cohort', id: string, status: Types.CohortState, startDate: string | null, endDate: string | null, triggers: Array<{ __typename: 'ExperienceTrigger', id: string, cohortId: string, triggerAt: string, taskType: Types.ExperienceTaskType, timing: Types.ExperienceTriggerTiming, relativeDuration: number | null, relativeUnit: Types.TriggerTimeUnit | null, relativeAnchor: Types.TriggerTimeAnchorV1 | null, createdAt: string, updatedAt: string }> } | null };


export const LoadExperienceStartAndEndDocument = `
    query LoadExperienceStartAndEnd($id: ID!) {
  cohort(id: $id) {
    id
    status
    startDate
    endDate
    triggers {
      id
      cohortId
      triggerAt
      taskType
      timing
      relativeDuration
      relativeUnit
      relativeAnchor
      createdAt
      updatedAt
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    LoadExperienceStartAndEnd: build.query<LoadExperienceStartAndEndQuery, LoadExperienceStartAndEndQueryVariables>({
      query: (variables) => ({ document: LoadExperienceStartAndEndDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLoadExperienceStartAndEndQuery, useLazyLoadExperienceStartAndEndQuery } = injectedRtkApi;

