import { Button, Text } from '@goosechase/ui';
import { toggleUpgradeExperienceModal } from 'data/experiences';
import { useAppDispatch } from 'data/hooks';
import { useTranslate } from 'util/i18n';

export const UpgradeExperienceBanner = () => {
  const { t } = useTranslate('upgradeExperienceBanner');
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(toggleUpgradeExperienceModal());
  };

  return (
    <div className="w-full p-2 bg-vibrantBlue text-white flex items-center justify-center gap-3">
      <Text className="font-bold">{t('body')}</Text>
      <Button
        label={t('cta')}
        leftIcon="YellowOrangeZap"
        outlined
        size="sm"
        onClick={handleClick}
      />
    </div>
  );
};
