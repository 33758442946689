import { Button, mergeClasses, Text } from '@goosechase/ui';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { AppRoutes } from '../../constants/routes';

import { useIsLoggedIn } from '../../data/auth/auth.hooks';
import { useMultipleWorkspacesFeatureFlag } from '../../hooks/use-feature-flag.hook';
import { useTranslate } from '../../util/i18n';
import { ExperienceListFilterDropdown } from '../experience-list-filter';
import { AccountDropdown, HeaderNavBar, NAV_BAR_HEIGHT } from '../header-nav-bar';
import { Link } from '../link.component';

const LoggedOutButtons = () => {
  const { t } = useTranslate('headerNavBar');
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate(AppRoutes.SIGNUP);
  };

  return (
    <div className="flex flex-row gap-6 items-center h-full">
      <Link useRouter href={AppRoutes.LOGIN}>
        <Text className="font-bold" size="md">
          {t('logIn')}
        </Text>
      </Link>
      <Button size="sm" outlined label={t('getStarted')} onClick={handleGetStarted} />
    </div>
  );
};

const DropdownSection = () => {
  const isLoggedIn = useIsLoggedIn();
  const multipleWorkspacesFlag = useMultipleWorkspacesFeatureFlag();
  const { pathname } = useLocation();

  if (!isLoggedIn) {
    return <LoggedOutButtons />;
  }

  const shouldRenderExperienceListFilterDropdown =
    multipleWorkspacesFlag && pathname === AppRoutes.MY_EXPERIENCES;

  return (
    <div className="flex items-center gap-5">
      {shouldRenderExperienceListFilterDropdown ? <ExperienceListFilterDropdown /> : null}
      <AccountDropdown className={NAV_BAR_HEIGHT} />
    </div>
  );
};

export const MainHeaderNavBar = () => {
  return (
    <HeaderNavBar variant="default">
      <div className={mergeClasses('flex grow justify-end items-center', NAV_BAR_HEIGHT)}>
        <div className="flex items-center gap-5">
          <DropdownSection />
        </div>
      </div>
    </HeaderNavBar>
  );
};
