/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type CompleteAddonCheckoutMutationVariables = Types.Exact<{
  checkoutId: Types.Scalars['String']['input'];
}>;


export type CompleteAddonCheckoutMutation = { __typename: 'Mutation', completeAddonCheckout: { __typename: 'Experience', id: string } };


export const CompleteAddonCheckoutDocument = `
    mutation CompleteAddonCheckout($checkoutId: String!) {
  completeAddonCheckout(checkoutId: $checkoutId) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    CompleteAddonCheckout: build.mutation<CompleteAddonCheckoutMutation, CompleteAddonCheckoutMutationVariables>({
      query: (variables) => ({ document: CompleteAddonCheckoutDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCompleteAddonCheckoutMutation } = injectedRtkApi;

