import { Modal } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';

interface HowItWorksVideoModalProps {
  show: boolean;
  onClose: () => void;
}

export const HowItWorksVideoModal = ({ show, onClose }: HowItWorksVideoModalProps) => {
  const { t } = useTranslate('pages.experiences.welcomeBanner.howItWorks');

  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[498px] desktop:w-[766px]"
      show={show}
      onClose={onClose}>
      <Modal.Header title={t('header') ?? undefined} />
      <Modal.Body>
        <div className="relative overflow-hidden h-0 pb-[56.25%]">
          <iframe
            className="absolute w-full h-full"
            src="https://www.youtube.com/embed/LI6tQfvGMVo"
            title={t('header') ?? undefined}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
