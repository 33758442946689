import { Button, Modal } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { Trans } from 'react-i18next';

interface DeleteConfirmationModalProps {
  show: boolean;
  header?: string;
  body: string;
  buttonText?: string;
  onClose: () => void;
  onSubmit: () => void;
}

export const DeleteConfirmationModal = ({
  show,
  header,
  body,
  buttonText,
  onClose,
  onSubmit,
}: DeleteConfirmationModalProps) => {
  const { t } = useTranslate('deleteConfirmationModal');

  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[498px] desktop:w-[766px]"
      show={show}
      onClose={onClose}>
      {header ? <Modal.Header title={header} /> : null}
      <Modal.Body>
        <Trans>{body}</Trans>
      </Modal.Body>
      <Modal.Footer>
        <Button label={buttonText ?? t('confirm')} onClick={onSubmit} buttonType={'DESTRUCTIVE'} />
        <Button label={t('cancel')} onClick={onClose} outlined />
      </Modal.Footer>
    </Modal>
  );
};
