/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type CheckAccountDeletionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CheckAccountDeletionQuery = { __typename: 'Query', checkAccountDeletion: { __typename: 'DeleteCheck', deletionAllowed: boolean } };


export const CheckAccountDeletionDocument = `
    query CheckAccountDeletion {
  checkAccountDeletion {
    deletionAllowed
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    CheckAccountDeletion: build.query<CheckAccountDeletionQuery, CheckAccountDeletionQueryVariables | void>({
      query: (variables) => ({ document: CheckAccountDeletionDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCheckAccountDeletionQuery, useLazyCheckAccountDeletionQuery } = injectedRtkApi;

