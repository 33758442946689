import { useDispatch } from 'react-redux';
import { SidePanel } from '../side-panel';
import {
  closeAndResetBroadcastPanel,
  useBroadcastPanelOpen,
  useSelectedBroadcast,
} from '../../data/broadcasts';
import { EditBroadcastForm } from './edit-broadcast-form.component';
import { CreateBroadcastForm } from './create-broadcast-form.component';

export const BroadcastSidePanel = () => {
  const open = useBroadcastPanelOpen();
  const selectedBroadcast = useSelectedBroadcast();
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeAndResetBroadcastPanel());
  };

  return (
    <SidePanel visible={open} onClickBackdrop={handleClose}>
      {open && (
        <>
          {selectedBroadcast !== null ? (
            <EditBroadcastForm
              initialBroadcast={selectedBroadcast}
              onCancel={handleClose}
              onSubmitSuccess={handleClose}
            />
          ) : (
            <CreateBroadcastForm onCancel={handleClose} onSubmitSuccess={handleClose} />
          )}
        </>
      )}
    </SidePanel>
  );
};
