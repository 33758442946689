import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import { dataStore } from 'data/store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { initIntl } from './util/i18n';
import { initSentry } from './util/sentry';
import { getConfig } from './constants/config';
import { ToasterSingleton } from './components/toaster-singleton';

const config = getConfig();

initIntl();

initSentry({ dsn: config.SENTRY_DSN, environment: config.APP_ENV });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <React.StrictMode>
        <Provider store={dataStore}>
          <App />
          <ToasterSingleton />
        </Provider>
      </React.StrictMode>
    </BrowserRouter>
  </HelmetProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
