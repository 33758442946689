import React from 'react';
import { Label, Text, TextAreaField } from '@goosechase/ui';
import { CharacterCount } from './character-count';

export type FormTextFieldProps = {
  label: string;
  size?: 'lg' | 'sm';
  errorMessage?: string;
  maxCharacters?: number;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const LabelledTextArea = ({
  label,
  size = 'sm',
  errorMessage,
  maxCharacters,
  onChange,
  ...htmlProps
}: FormTextFieldProps) => {
  const [count, setCount] = React.useState(
    htmlProps.defaultValue ? htmlProps.defaultValue.toString().length : 0,
  );

  const handleCharacterCountUpdate =
    (onChangeHandler?: React.ChangeEventHandler<HTMLTextAreaElement>) =>
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setCount(e.target.value.length);
      onChangeHandler?.(e);
    };

  return (
    <div className="flex flex-col content-start">
      <Label size={size} className="block mb-2" htmlFor={htmlProps.name}>
        {label.toUpperCase()}
      </Label>
      <TextAreaField
        aria-label={label}
        onChange={maxCharacters ? handleCharacterCountUpdate(onChange) : onChange}
        responseState={errorMessage ? 'error' : undefined}
        {...htmlProps}
      />
      <div className="flex justify-between mt-2">
        <Text size="xs" className="text-vibrantRed">
          {errorMessage ?? ''}
        </Text>
        {maxCharacters ? (
          <CharacterCount
            maxCharacters={maxCharacters}
            count={count}
            isError={Boolean(errorMessage)}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
