/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type LoadBroadcastsForCohortQueryVariables = Types.Exact<{
  cohortId: Types.Scalars['ID']['input'];
}>;


export type LoadBroadcastsForCohortQuery = { __typename: 'Query', cohort: { __typename: 'Cohort', broadcasts: Array<{ __typename: 'Broadcast', id: string, cohortId: string, type: Types.BroadcastType, recipientType: Types.BroadcastRecipientType, message: string, state: Types.BroadcastState, createdAt: string, updatedAt: string, recipients: Array<{ __typename: 'BroadcastRecipient', team: { __typename: 'Team', id: string, displayName: string, photoUrl: string | null, mode: Types.TeamMode } }>, trigger: { __typename: 'BroadcastTrigger', id: string, timing: Types.BroadcastTriggerTiming, relativeDuration: number | null, relativeUnit: Types.TriggerTimeUnit | null, relativeAnchor: Types.TriggerTimeAnchor | null, specificDay: number | null, specificTime: string | null, createdAt: string, updatedAt: string } | null }> } | null };


export const LoadBroadcastsForCohortDocument = `
    query LoadBroadcastsForCohort($cohortId: ID!) {
  cohort(id: $cohortId) {
    broadcasts {
      id
      cohortId
      type
      recipientType
      message
      state
      recipients {
        team {
          id
          displayName
          photoUrl
          mode
        }
      }
      trigger {
        id
        timing
        relativeDuration
        relativeUnit
        relativeAnchor
        specificDay
        specificTime
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    LoadBroadcastsForCohort: build.query<LoadBroadcastsForCohortQuery, LoadBroadcastsForCohortQueryVariables>({
      query: (variables) => ({ document: LoadBroadcastsForCohortDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLoadBroadcastsForCohortQuery, useLazyLoadBroadcastsForCohortQuery } = injectedRtkApi;

