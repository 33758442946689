var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Text } from '../../typography';
import { mergeClasses } from '../../util/merge-classes';
// ButtonLabel used to use a the Label component from Typography, which used sizes `lg` and `sm`.
// It was later updated to use Text, which has different fontSizes, so this function handles the conversion
var convertLabelSizeToTextSize = function (size) { return (size === 'lg' ? 'md' : size); };
export var ButtonLabel = function (_a) {
    var text = _a.text, size = _a.size, disabled = _a.disabled, testID = _a.testID;
    return (_jsx(Text, __assign({ size: convertLabelSizeToTextSize(size), className: mergeClasses('font-semibold font-soleil whitespace-nowrap', disabled ? 'cursor-auto' : 'cursor-pointer'), testID: testID }, { children: text })));
};
