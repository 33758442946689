import { Modal, Text } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import { Trans } from 'react-i18next';
import { Nullable } from 'types/util';
import { ShareExperience } from './share-experience.component';

interface ShareExperienceModalProps {
  show: boolean;
  onClose: () => void;
  experienceDisplayName: string;
  experienceId: string;
  experienceShareLink: Nullable<string>;
}

export const ShareExperienceModal = ({
  show,
  onClose,
  experienceDisplayName,
  experienceId,
  experienceShareLink,
}: ShareExperienceModalProps) => {
  const { t } = useTranslate('shareExperience');

  return (
    <Modal
      className="w-[calc(100vw-24px)] tablet-narrow:w-[592px] overflow-visible"
      show={show}
      onClose={onClose}>
      <Modal.Header title={t('header') ?? undefined} />
      <Modal.Body className="py-6">
        <div className="flex flex-col gap-5">
          <Text size="sm">
            <Trans i18nKey="body">{t('body', { experienceDisplayName })}</Trans>
          </Text>
          <ShareExperience experienceId={experienceId} experienceShareLink={experienceShareLink} />
        </div>
      </Modal.Body>
    </Modal>
  );
};
