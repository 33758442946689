import { jsx as _jsx } from "react/jsx-runtime";
import { SinglePlaceholder } from './single-placeholder';
import { TeamDuoPlaceholder } from './duo-placeholder';
import { TeamTrioPlaceholder } from './trio-placeholder';
import { WorkspacePlaceholder } from './workspace-placeholder';
import { ExperiencePlaceholder } from './experience';
export var PlaceholderAvatar = function (_a) {
    var _b = _a.placeholder, placeholder = _b === void 0 ? 'single' : _b;
    switch (placeholder) {
        case 'workspace':
            return _jsx(WorkspacePlaceholder, {});
        case 'experience':
            return _jsx(ExperiencePlaceholder, {});
        case 'team-trio':
            return _jsx(TeamTrioPlaceholder, {});
        case 'team-duo':
            return _jsx(TeamDuoPlaceholder, {});
        case 'single':
        default:
            return _jsx(SinglePlaceholder, {});
    }
};
