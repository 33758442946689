/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type SubmissionInfoStatisticsQueryVariables = Types.Exact<{
  cohortId: Types.Scalars['ID']['input'];
}>;


export type SubmissionInfoStatisticsQuery = { __typename: 'Query', submissionInfo: Array<{ __typename: 'SubmissionInfo', id: string, type: string, createdAt: string, missionName: string, teamName: string, submissionDataString: string, likes: number, points: number, bonusPoints: number }> };


export const SubmissionInfoStatisticsDocument = `
    query SubmissionInfoStatistics($cohortId: ID!) {
  submissionInfo(cohortId: $cohortId) {
    id
    type
    createdAt
    missionName
    teamName
    submissionDataString
    likes
    points
    bonusPoints
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    SubmissionInfoStatistics: build.query<SubmissionInfoStatisticsQuery, SubmissionInfoStatisticsQueryVariables>({
      query: (variables) => ({ document: SubmissionInfoStatisticsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSubmissionInfoStatisticsQuery, useLazySubmissionInfoStatisticsQuery } = injectedRtkApi;

