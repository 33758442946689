import { useEffect, useState, useRef } from 'react';
import { CircleF, GoogleMap, MarkerF } from '@react-google-maps/api';

import { useGoogleMapsApi } from '../hooks/use-google-maps-api';

const DEFAULT_CENTER: google.maps.LatLngLiteral = {
  lat: 45,
  lng: -100,
};

const DEFAULT_ZOOM = 3;

interface MapOptions {
  streetViewControl: boolean;
}
const DEFAULT_MAP_OPTIONS: MapOptions = { streetViewControl: false };

interface CircleOptions {
  fillColor: string;
  fillOpacity: number;
  strokeColor: string;
  strokeOpacity: number;
  strokeWeight: number;
  clickable?: boolean;
}
const DEFAULT_CIRCLE_OPTIONS: CircleOptions = {
  fillColor: 'red',
  fillOpacity: 0.2,
  strokeColor: 'red',
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
};

interface InteractiveMapProps {
  className?: string;
  coordinates?: google.maps.LatLngLiteral;
  radius: number;
  zoom?: number;
  mapOptions?: MapOptions;
  circleOptions?: CircleOptions;
  onCoordinateChange?: (coordinates: google.maps.LatLngLiteral) => void;
}

export const InteractiveMap = ({
  className,
  coordinates,
  radius,
  zoom = DEFAULT_ZOOM,
  mapOptions = DEFAULT_MAP_OPTIONS,
  circleOptions = DEFAULT_CIRCLE_OPTIONS,
  onCoordinateChange,
}: InteractiveMapProps) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const [center, setCenter] = useState<google.maps.LatLngLiteral | google.maps.LatLng>(
    coordinates ?? DEFAULT_CENTER,
  );

  const { isLoaded } = useGoogleMapsApi();

  useEffect(() => {
    // Change map center when coordinates change AND they are not in the current bounds
    if (isLoaded && coordinates && zoom) {
      // refresh bounds manually before checking against new coords
      let bounds: google.maps.LatLngBounds | undefined;
      if (mapRef.current) {
        bounds = mapRef.current.getBounds();
      }
      const newCenter = new google.maps.LatLng(coordinates.lat, coordinates.lng);

      if (!bounds || !bounds.contains(newCenter)) {
        setCenter(newCenter);
      }
    }
  }, [isLoaded, coordinates, zoom]);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const latLng = event.latLng;

    if (latLng !== null) {
      onCoordinateChange?.({ lat: latLng.lat(), lng: latLng.lng() });
    }
  };

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <GoogleMap
      mapContainerClassName={className}
      center={center}
      zoom={zoom}
      options={mapOptions}
      onClick={handleMapClick}
      onLoad={onLoad}>
      {coordinates && (
        <>
          <MarkerF position={coordinates} />
          <CircleF
            center={coordinates}
            radius={radius}
            options={circleOptions}
            onClick={handleMapClick}
          />
        </>
      )}
    </GoogleMap>
  );
};
