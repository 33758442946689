/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type UpdateCohortEndMutationVariables = Types.Exact<{
  params: Types.UpdateCohortEndParams;
}>;


export type UpdateCohortEndMutation = { __typename: 'Mutation', updateCohortEnd: { __typename: 'Cohort', id: string, status: Types.CohortState } };


export const UpdateCohortEndDocument = `
    mutation UpdateCohortEnd($params: UpdateCohortEndParams!) {
  updateCohortEnd(params: $params) {
    id
    status
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    UpdateCohortEnd: build.mutation<UpdateCohortEndMutation, UpdateCohortEndMutationVariables>({
      query: (variables) => ({ document: UpdateCohortEndDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateCohortEndMutation } = injectedRtkApi;

