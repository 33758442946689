/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type SharedExperienceQueryVariables = Types.Exact<{
  experienceShareId: Types.Scalars['ID']['input'];
}>;


export type SharedExperienceQuery = { __typename: 'Query', sharedExperience: { __typename: 'PublicExperience', id: string, displayName: string, description: string, photoUrl: string | null, missionOrder: Types.MissionOrder, missions: Array<{ __typename: 'PublicCameraMission', allowCameraRollUploads: boolean, mediaType: Types.MediaType, displayName: string, description: string, points: number, image: string | null, missionType: Types.MissionType } | { __typename: 'PublicGpsMission', locationName: string | null, latitude: string, longitude: string, radius: number, displayName: string, description: string, points: number, image: string | null, missionType: Types.MissionType } | { __typename: 'PublicTextMission', answers: Array<string>, allowApproximateAnswers: boolean, displayName: string, description: string, points: number, image: string | null, missionType: Types.MissionType }> } };


export const SharedExperienceDocument = `
    query SharedExperience($experienceShareId: ID!) {
  sharedExperience(experienceShareId: $experienceShareId) {
    id
    displayName
    description
    photoUrl
    missionOrder
    missions {
      displayName
      description
      points
      image
      missionType
      ... on PublicCameraMission {
        allowCameraRollUploads
        mediaType
      }
      ... on PublicTextMission {
        answers
        allowApproximateAnswers
      }
      ... on PublicGpsMission {
        locationName
        latitude
        longitude
        radius
      }
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    SharedExperience: build.query<SharedExperienceQuery, SharedExperienceQueryVariables>({
      query: (variables) => ({ document: SharedExperienceDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSharedExperienceQuery, useLazySharedExperienceQuery } = injectedRtkApi;

