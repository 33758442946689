import { IconButton, TextField, mergeClasses } from '@goosechase/ui';
import { Nullable } from 'types/util';
import { useTranslate } from '../../util/i18n';

interface NumberInputProps {
  name: string;
  label: string;
  value: Nullable<number | string>;
  onChange: (value: number | null) => void;
  onBlur: (value: number | null) => void;
  disabled?: boolean;
  min?: number;
  placeholder?: string;
  error?: boolean;
  hideSpinButtons?: boolean;
  className?: string;
  containerClassName?: string;
  autoFocusInput?: boolean;
}

export const NumberInput = ({
  name,
  label,
  disabled,
  value,
  onChange,
  onBlur,
  min,
  placeholder,
  error,
  hideSpinButtons,
  className,
  containerClassName,
  autoFocusInput,
}: NumberInputProps) => {
  const { t } = useTranslate('numberInput');
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.target.value ? Number(event.target.value) : null);
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLButtonElement> = (event) => {
    onBlur(event.target.value ? Number(event.target.value) : null);
  };

  const handleIncrement = () => onChange(value !== null ? Number(value) + 1 : null);

  const handleDecrement = () =>
    onChange(value !== null ? Math.max(Number(value) - 1, min ?? 0) : null);

  return (
    <div className={mergeClasses('flex flex-row gap-2 relative', containerClassName)}>
      <TextField
        className={className}
        inputClasses={className}
        type="number"
        name={name}
        aria-label={label}
        value={value === null ? '' : value}
        disabled={disabled}
        min={min}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        responseState={error ? 'error' : undefined}
        autoFocusInput={autoFocusInput}
      />
      {!hideSpinButtons ? (
        <div className="flex flex-col absolute right-0 h-full justify-center">
          <IconButton
            icon="CaretDown"
            type="button"
            className="rotate-180 h-4"
            iconColor={disabled ? 'black-64' : 'black'}
            onClick={handleIncrement}
            disabled={disabled}
            aria-label={t('ariaDecreaseLabel') ?? ''}
          />
          <IconButton
            icon="CaretDown"
            type="button"
            className="h-4"
            iconColor={disabled ? 'black-64' : 'black'}
            onClick={handleDecrement}
            disabled={disabled}
            aria-label={t('ariaIncreaseLabel') ?? ''}
          />
        </div>
      ) : null}
    </div>
  );
};
