/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

module.hot?.accept();
export type SubmissionTeamFragmentFragment = { __typename: 'Team', id: string, displayName: string, photoUrl: string | null, mode: Types.TeamMode };

export const SubmissionTeamFragmentFragmentDoc = `
    fragment SubmissionTeamFragment on Team {
  id
  displayName
  photoUrl
  mode
}
    `;
