import { ExperienceFragmentFragment } from '../../data/experiences';
import { CohortState, type Experience } from '../../data/models';
import { ExperienceFields } from './experience-list.component';

type SortableExperience = Pick<Experience, 'status' | 'endDate' | 'displayName' | 'createdAt'>;

const sortExperiences = (a: SortableExperience, b: SortableExperience) => {
  if (a.status === CohortState.Active && b.status !== CohortState.Active) {
    return -1; // Live experiences come first
  } else if (a.status !== CohortState.Active && b.status === CohortState.Active) {
    return 1; // Live experiences come first
  } else if (
    a.status === CohortState.Active &&
    b.status === CohortState.Active &&
    a.endDate &&
    b.endDate
  ) {
    // Both are live, sort by endDate
    const endDateComparison = new Date(a.endDate).getTime() - new Date(b.endDate).getTime();
    if (!endDateComparison) {
      return a.displayName.localeCompare(b.displayName);
    } else {
      return endDateComparison;
    }
  } else {
    // If they are not live, sort by creation date or alphabetically by displayName
    return (
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime() ||
      a.displayName.localeCompare(b.displayName)
    );
  }
};

export const transformExperiencesDataToExperiencesList = (
  experiences: ExperienceFragmentFragment[],
): ExperienceFields[] =>
  experiences
    .slice()
    .sort(sortExperiences)
    .map(
      ({
        id,
        displayName,
        description,
        shareLink,
        assets,
        status,
        addons,
        myExperienceManager,
        workspace,
      }) => ({
        id,
        displayName,
        description,
        photoUrl: assets.find((asset) => asset.role === 'PHOTO')?.url,
        status,
        addons,
        roleId: myExperienceManager?.role.id,
        workspacePlanId: workspace.subscription.plan.id,
        workspacePlanUpgradable: workspace.subscription.plan.upgradable,
        workspaceId: workspace.id,
        shareLink,
      }),
    );
