import { Button, Headline, NotificationBanner, Text } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import { Card } from 'components/card.component';
import { useCallback, useMemo, useState } from 'react';
import { EndConfirmationModal } from './end-confirmation-modal';
import { EditEndConfirmationModal } from './edit-end-confirmation-modal';
import { displayToast } from 'services/toast';
import { useEndCohortMutation, useUpdateCohortEndMutation } from 'data/experiences';
import { Trans } from 'react-i18next';
import { buildBaseParams, StartEndFormInputData, useStartEndForm } from './use-start-end-form';
import { format, formatDuration, intervalToDuration } from 'date-fns';
import { abbreviateTimespan, MissionTrigger, systemTimezoneAbbreviation } from 'util/time-util';
import { Nullable } from 'types/util';
import {
  BroadcastTrigger,
  CohortState,
  ExperienceTrigger,
  ExperienceTriggerTiming,
  TriggerTimeUnit,
} from '../../data/models';
import StartEndFormUI from './start-end-form-ui';

interface LiveFormProps {
  cohortId: string;
  startDateTime: Date;
  startTrigger?: ExperienceTrigger;
  endTrigger: ExperienceTrigger;
  missionTriggers?: MissionTrigger[];
  broadcastTriggers?: BroadcastTrigger[];
  experienceTimezone: string;
}

const LiveForm = ({
  cohortId,
  startDateTime,
  startTrigger,
  endTrigger,
  missionTriggers,
  broadcastTriggers,
  experienceTimezone,
}: LiveFormProps) => {
  const { t } = useTranslate('pages.startEnd');
  const [showEndNowModal, setShowEndNowModal] = useState(false);
  const [editable, setEditable] = useState(false);
  const [showEditEndModal, setShowEditEndModal] = useState(false);

  // TODO: make sure these are in the right time zone
  const [endDateTime, setEndDateTime] = useState<Nullable<Date>>(new Date(endTrigger.triggerAt));

  const [endCohort] = useEndCohortMutation();
  const [updateCohortEnd] = useUpdateCohortEndMutation();

  const defaultValues = {
    startTiming: startTrigger?.timing ?? 'NOW',
    startDate: startDateTime,
    startTime: startDateTime,
    endTiming: endTrigger.timing ?? ExperienceTriggerTiming.Relative,
    relativeDuration: endTrigger.relativeDuration ?? undefined,
    relativeUnit: endTrigger.relativeUnit ?? TriggerTimeUnit.Hours,
    endDate: endTrigger.triggerAt ? new Date(endTrigger.triggerAt) : undefined,
    endTime: endTrigger.triggerAt ? new Date(endTrigger.triggerAt) : undefined,
  };

  const { control, trigger, reset, handleSubmit, formState } = useStartEndForm({
    defaultValues,
    editingLive: editable,
    startDate: startDateTime,
  });

  const toggleEditable = (resetForm: boolean) => {
    if (editable && resetForm) {
      setEndDateTime(new Date(endTrigger.triggerAt));
      reset(defaultValues);
    }
    setEditable(!editable);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    trigger();
  };

  const handleEndExperience = async () => {
    try {
      await endCohort({ id: cohortId });
      displayToast({
        id: 'end-experience-toast',
        title: t('endToast.success.title'),
        body: t('endToast.success.body'),
        type: 'success',
      });
      setShowEndNowModal(false);
    } catch {
      displayToast({
        id: 'end-experience-toast',
        title: t('endToast.error.title'),
        body: t('endToast.error.body'),
        type: 'error',
      });
    }
  };

  const handleSave = async (data: StartEndFormInputData) => {
    try {
      const updateParams = buildBaseParams(cohortId, data);
      await updateCohortEnd({ params: updateParams });
      displayToast({
        id: 'update-experience-end-toast',
        title: t('updateEndToast.success.title'),
        body: t('updateEndToast.success.body'),
        type: 'success',
      });
      setShowEditEndModal(false);
      toggleEditable(false);
    } catch {
      displayToast({
        id: 'update-experience-end-toast',
        title: t('updateEndToast.error.title'),
        body: t('updateEndToast.error.body'),
        type: 'error',
      });
    }
  };

  const totalTime = useMemo(() => {
    if (!endDateTime) {
      return '';
    }
    const duration = intervalToDuration({
      start: editable ? startDateTime : new Date(),
      end: endDateTime,
    });
    return abbreviateTimespan(
      formatDuration(duration, {
        format: ['years', 'months', 'days', 'hours', 'minutes'],
      }),
    );
  }, [startDateTime, endDateTime, editable]);

  const handleChangeEnd = useCallback(
    (val: Nullable<Date>) => {
      setEndDateTime(val);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      trigger();
    },
    [setEndDateTime, trigger],
  );

  return (
    <>
      <Card className="mt-4 p-5 w-full">
        <>
          <Headline type="secondary" size="sm" className="mb-6">
            {t('draftHeadline')}
          </Headline>
          {editable && (
            <NotificationBanner
              body={t('form.editingLiveWarning')}
              title=""
              type="warning"
              className="mb-6"
            />
          )}
          <StartEndFormUI
            control={control}
            trigger={trigger}
            onChangeStart={() => {}}
            onChangeEnd={handleChangeEnd}
            startDisabled={true}
            endDisabled={!editable}
            cohortState={CohortState.Active}
            startDateTime={startDateTime}
            missionTriggers={missionTriggers}
            broadcastTriggers={broadcastTriggers}
            experienceTimezone={experienceTimezone}
          />
          <div className="border-t border-black-12 pt-6 mt-4">
            {startDateTime && endDateTime && (
              <Text className="mb-6">
                <Trans>
                  {editable
                    ? t('scheduleInfo', {
                        startDateTime: format(startDateTime, "EEEE, LLLL d yyyy 'at' h:mm aa"),
                        endDateTime: format(endDateTime, "EEEE, LLLL d yyyy 'at' h:mm aa"),
                        timeZone: systemTimezoneAbbreviation,
                        totalTime: totalTime,
                      })
                    : t('liveInfo', {
                        startDateTime: format(startDateTime, "EEEE, LLLL d yyyy 'at' h:mm aa"),
                        endDateTime: format(endDateTime, "EEEE, LLLL d yyyy 'at' h:mm aa"),
                        timeZone: systemTimezoneAbbreviation,
                        timeLeft: totalTime,
                      })}
                </Trans>
              </Text>
            )}
            {editable ? (
              <div className="flex flex-row gap-x-6">
                <Button
                  label={t('form.saveChanges')}
                  onClick={() => setShowEditEndModal(true)}
                  disabled={!formState.isValid}
                />
                <Button label={t('form.cancel')} onClick={() => toggleEditable(true)} outlined />
              </div>
            ) : (
              <Button leftIcon="Edit" label="Edit" outlined onClick={() => toggleEditable(false)} />
            )}
          </div>
        </>
      </Card>
      <Card className="mt-4 p-5 w-full">
        <>
          <Headline type="secondary" size="xs">
            {t('endHeadline')}
          </Headline>
          <Text className="my-6">{t('endInfo1')}</Text>
          <Text className="my-6">{t('endInfo2')}</Text>
          <Button
            label={t('endExperienceNow')}
            buttonType="DESTRUCTIVE"
            outlined
            onClick={() => setShowEndNowModal(true)}
          />
        </>
      </Card>
      <EndConfirmationModal
        show={showEndNowModal}
        onSubmit={handleEndExperience}
        onClose={() => setShowEndNowModal(false)}
      />
      <EditEndConfirmationModal
        show={showEditEndModal}
        onSubmit={handleSubmit(handleSave)}
        onClose={() => setShowEditEndModal(false)}
      />
    </>
  );
};

export default LiveForm;
