import { useNavigate } from 'react-router-dom';
import { NotificationBanner } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { AccountFormLayout } from 'components/account-form-layout.component';

import { ForgotPasswordForm } from './forgot-password-form';
import { AppRoutes } from '../../constants/routes';
import { ForgotPasswordPanel } from './forgot-password-panel.component';
import { useIsRedirectedFromExpiredLink } from '../../hooks/use-forgot-password-redirect.hook';

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslate('pages.forgotPassword');
  const showExpiredRequestBanner = useIsRedirectedFromExpiredLink();

  const onSubmitSuccess = () => {
    navigate(AppRoutes.FORGOT_PASSWORD_DONE);
  };

  return (
    <Page title={t('meta.title')} description={t('meta.description') as string}>
      <AccountFormLayout>
        <AccountFormLayout.Body>
          <AccountFormLayout.Title>{t('heading')}</AccountFormLayout.Title>
          {showExpiredRequestBanner && (
            <NotificationBanner
              className="my-8"
              type={'error'}
              showIcon
              title={t('expiredRequestBanner.title')}
              body={t('expiredRequestBanner.body')}
            />
          )}
          <AccountFormLayout.Description>{t('subheading')}</AccountFormLayout.Description>
          <ForgotPasswordForm onSubmitSuccess={onSubmitSuccess} />
        </AccountFormLayout.Body>
        <ForgotPasswordPanel />
      </AccountFormLayout>
    </Page>
  );
};
