import { Headline, Text } from '@goosechase/ui';

import { ReactComponent as GooseWithFlag } from 'media/goose-with-flag.svg';

export interface EmptyStateProps {
  text: string;
  subtext: React.ReactNode;
  renderGraphic?: () => React.ReactNode;
}

export const EmptyState = ({ text, subtext, renderGraphic }: EmptyStateProps) => (
  <div className="flex flex-col items-center text-black-64">
    {renderGraphic ? (
      renderGraphic()
    ) : (
      <GooseWithFlag className="max-w-[165px] min-w-[100px] opacity-60" />
    )}
    <Headline className="my-6 text-center" type="secondary" size="sm">
      {text}
    </Headline>
    <Text className="text-center max-w-xl max-w-[340px]" size="sm">
      {subtext}
    </Text>
  </div>
);
