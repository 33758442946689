import { Button, Headline, Label, Text } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import { Card } from 'components/card.component';
import { useState, useMemo } from 'react';
import { ResetConfirmationModal } from './reset-confirmation-modal';
import { useResetExperienceMutation } from 'data/experiences';
import { displayToast } from 'services/toast';
import { format as formatDate, formatDuration, intervalToDuration } from 'date-fns';
import { abbreviateTimespan, systemTimezoneAbbreviation } from 'util/time-util';

interface EndedFormProps {
  experienceId: string;
  startDateTime: Date;
  endDateTime: Date;
}

const EndedForm = ({ experienceId, startDateTime, endDateTime }: EndedFormProps) => {
  const { t } = useTranslate('pages.startEnd');
  const [showModal, setShowModal] = useState(false);
  const [resetExperience] = useResetExperienceMutation();
  // TODO get start/end times based on experience state changes]

  const handleSubmit = async () => {
    try {
      await resetExperience({ id: experienceId });
      displayToast({
        id: 'reset-experience-toast',
        title: t('resetToast.success.title'),
        body: t('resetToast.success.body'),
        type: 'success',
      });
    } catch {
      displayToast({
        id: 'reset-experience-toast',
        title: t('resetToast.error.title'),
        body: t('resetToast.error.body'),
        type: 'error',
      });
    }
    setShowModal(false);
  };

  const totalTime = useMemo(() => {
    const duration = intervalToDuration({ start: startDateTime, end: endDateTime });
    const formatted = formatDuration(duration, {
      format: ['years', 'months', 'days', 'hours', 'minutes', 'seconds'],
    });
    return formatted !== '' ? abbreviateTimespan(formatted) : '0s';
  }, [startDateTime, endDateTime]);

  return (
    <>
      <Card className="mt-4 p-5 w-full">
        <>
          <Headline type="secondary" size="sm" className="mb-6">
            {t('endedHeadline')}
          </Headline>
          <div className="flex flex-col tablet-narrow:flex-row gap-x-6 gap-y-3">
            <div className="tablet-narrow:w-1/2">
              <Label size="sm" className="block mb-2">
                {t('form.start.label').toUpperCase()}
              </Label>
              <Text className="text-black-48">
                {`${formatDate(
                  startDateTime,
                  "EEEE, LLLL d yyyy 'at' h:mm aa",
                )} (${systemTimezoneAbbreviation})`}
              </Text>
            </div>
            <div className="tablet-narrow:w-1/2">
              <Label size="sm" className="block mb-2">
                {t('form.end.label').toUpperCase()}
              </Label>
              <Text className="text-black-48">
                {`${formatDate(
                  endDateTime,
                  "EEEE, LLLL d yyyy 'at' h:mm aa",
                )} (${systemTimezoneAbbreviation})`}
              </Text>
            </div>
          </div>
          <div className="border-t border-black-12 pt-6 mt-4">
            <Text className="mb-1">
              {t('endedDuration', {
                totalTime: totalTime,
              })}
            </Text>
          </div>
        </>
      </Card>
      <Card className="mt-4 p-5 w-full">
        <>
          <Headline type="secondary" size="sm">
            {t('resetHeadline')}
          </Headline>
          <Text className="my-6">{t('resetInfo')}</Text>
          <Button
            label={t('resetStartAndEndTimes')}
            leftIcon="Refresh"
            onClick={() => setShowModal(true)}
            outlined
          />
        </>
      </Card>
      <ResetConfirmationModal
        show={showModal}
        onSubmit={handleSubmit}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default EndedForm;
