var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Text = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M14.4 1.68573H6.1714C5.62581 1.68573 5.10257 1.90246 4.71678 2.28825C4.33099 2.67404 4.11426 3.19728 4.11426 3.74287V22.2572C4.11426 22.8027 4.33099 23.326 4.71678 23.7118C5.10257 24.0976 5.62581 24.3143 6.1714 24.3143H18.5143C19.0598 24.3143 19.5831 24.0976 19.9689 23.7118C20.3547 23.326 20.5714 22.8027 20.5714 22.2572V7.85716L14.4 1.68573Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M14.3999 1.68573V7.85716H20.5713", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M16.4571 11.9714H8.22852", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M16.4571 16.0858H8.22852", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M16.4571 20.2H8.22852", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M10.2857 7.85718H9.25709H8.22852", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
