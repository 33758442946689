var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var CopyLink = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M10.1885 12.9062C10.5776 13.4264 11.074 13.8568 11.6441 14.1683C12.2141 14.4797 12.8445 14.665 13.4925 14.7114C14.1404 14.7578 14.7908 14.6643 15.3994 14.4372C16.008 14.2102 16.5607 13.8549 17.02 13.3955L19.7381 10.6774C20.5633 9.823 21.0199 8.67867 21.0096 7.49087C20.9993 6.30307 20.5228 5.16685 19.6829 4.32692C18.843 3.48699 17.7067 3.01055 16.5189 3.00023C15.3311 2.98991 14.1868 3.44653 13.3324 4.27173L11.774 5.82105", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M13.8115 11.0938C13.4224 10.5736 12.926 10.1432 12.3559 9.83175C11.7858 9.52029 11.1555 9.33508 10.5075 9.28867C9.85958 9.24227 9.20923 9.33576 8.60059 9.5628C7.99196 9.78984 7.43927 10.1451 6.98001 10.6045L4.26191 13.3226C3.4367 14.177 2.98008 15.3214 2.99041 16.5092C3.00073 17.697 3.47716 18.8332 4.31709 19.6731C5.15702 20.5131 6.29325 20.9895 7.48104 20.9998C8.66884 21.0101 9.81317 20.5535 10.6676 19.7283L12.2169 18.179", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
