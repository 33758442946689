var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { mergeClasses } from '../util/merge-classes';
import { Icon } from '../icon';
import { Text } from '../typography';
import { TextInput } from '../text-input';
import { TextContainer } from '../util/field-styling';
var Tag = function (_a) {
    var value = _a.value, onRemove = _a.onRemove;
    var handleRemove = function () {
        onRemove(value);
    };
    return (_jsxs("div", __assign({ className: "flex basis-0 inline-block rounded bg-offWhite" }, { children: [_jsx("div", __assign({ className: "flex basis-0 inline-block py-1 pl-2 rounded-l bg-offWhite" }, { children: _jsx(Text, __assign({ className: "whitespace-nowrap", size: "xs" }, { children: value.toUpperCase() })) })), _jsx("button", __assign({ type: "button", className: "flex items-center px-2 rounded-r outline-black bg-offWhite hover:brightness-90", onClick: handleRemove, "aria-label": 'Remove' }, { children: _jsx(Icon, { name: "CloseBold", size: 9 }) }))] })));
};
export var TagInput = function (_a) {
    var className = _a.className, placeholder = _a.placeholder, values = _a.values, onAdd = _a.onAdd, onRemove = _a.onRemove;
    var _b = useState(''), value = _b[0], setValue = _b[1];
    var handleKeyDown = function (e) {
        var targetValue = e.target.value;
        if (e.key === 'Enter') {
            if (targetValue !== '') {
                onAdd(targetValue);
                setValue('');
            }
            // Since we're using the Enter key to add a tag,
            // we need to prevent the default behaviour (ie. form submission)
            e.preventDefault();
        }
    };
    var handleBlur = function (e) {
        if (e.target.value !== '') {
            onAdd(e.target.value);
            setValue('');
        }
    };
    var handleChange = function (e) {
        setValue(e.target.value);
    };
    return (_jsxs(TextContainer, __assign({ className: mergeClasses('flex-col p-4', className) }, { children: [values.length > 0 && (_jsx("div", __assign({ className: "flex flex-wrap gap-2 mb-2.5" }, { children: values.map(function (v) { return (_jsx(Tag, { value: v, onRemove: onRemove }, v)); }) }))), _jsx(TextInput, { placeholder: placeholder, value: value, onChange: handleChange, onKeyDown: handleKeyDown, onBlur: handleBlur })] })));
};
