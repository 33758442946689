/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { BaseMissionFragmentFragmentDoc } from './base-mission.fragment.generated';
import { CameraMissionFieldsFragmentFragmentDoc } from './camera-mission-fields.fragment.generated';
import { rootApi } from 'data/api';
module.hot?.accept();
export type UpdateCameraMissionMutationVariables = Types.Exact<{
  params: Types.UpdateCameraMissionInput;
}>;


export type UpdateCameraMissionMutation = { __typename: 'Mutation', updateCameraMission: { __typename: 'CameraMission', position: number | null, draft: boolean, id: string, displayName: string, description: string, points: number, image: string | null, link: string | null, feedVisibility: Types.FeedVisibility, creatorId: string, createdAt: string, updatedAt: string, missionType: Types.MissionType, allowCameraRollUploads: boolean, mediaType: Types.MediaType, releaseTrigger: { __typename: 'MissionTrigger', createdAt: string, id: string, missionId: string, relativeAnchor: Types.TriggerTimeAnchorV1 | null, relativeDuration: number | null, relativeUnit: Types.TriggerTimeUnit | null, specificDay: number | null, specificTime: string | null, taskType: Types.MissionTaskType, timing: Types.MissionTriggerTiming, updatedAt: string } | null, expireTrigger: { __typename: 'MissionTrigger', createdAt: string, id: string, missionId: string, relativeAnchor: Types.TriggerTimeAnchorV1 | null, relativeDuration: number | null, relativeUnit: Types.TriggerTimeUnit | null, specificDay: number | null, specificTime: string | null, taskType: Types.MissionTaskType, timing: Types.MissionTriggerTiming, updatedAt: string } | null } };


export const UpdateCameraMissionDocument = `
    mutation UpdateCameraMission($params: UpdateCameraMissionInput!) {
  updateCameraMission(params: $params) {
    ...BaseMissionFragment
    ...CameraMissionFieldsFragment
    position
    draft
  }
}
    ${BaseMissionFragmentFragmentDoc}
${CameraMissionFieldsFragmentFragmentDoc}`;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    UpdateCameraMission: build.mutation<UpdateCameraMissionMutation, UpdateCameraMissionMutationVariables>({
      query: (variables) => ({ document: UpdateCameraMissionDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateCameraMissionMutation } = injectedRtkApi;

