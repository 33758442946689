import { WorkspaceScope } from '../data/models';
import { useMyWorkspacesQuery } from '../data/workspaces';

export const usePersonalWorkspace = () => {
  const { data: myWorkspacesData, isLoading: myWorkspacesIsLoading } = useMyWorkspacesQuery();

  if (myWorkspacesIsLoading) {
    return null;
  }

  return myWorkspacesData?.myWorkspaces.find((w) => w.scope === WorkspaceScope.Personal);
};
