import { useLayoutEffect, useState } from 'react';

import { convertWidthToScreenSize, type ScreenSizeAPI, type ScreenSize } from './screen-size.utils';

export const useScreenSize = (): ScreenSizeAPI => {
  const [screen, setScreen] = useState<ScreenSize>('mobile');
  const [isMobile, setIsMobile] = useState<boolean>(true);
  const [isTabletNarrow, setIsTabletNarrow] = useState<boolean>(true);
  const [isTabletWide, setIsTabletWide] = useState<boolean>(true);
  const [isDesktop, setIsDesktop] = useState<boolean>(false);

  const handleResize = () => {
    const updatedScreenSize = convertWidthToScreenSize(window.innerWidth);
    setScreen(updatedScreenSize.screen);
    setIsMobile(updatedScreenSize.isMobile);
    setIsTabletNarrow(updatedScreenSize.isTabletNarrow);
    setIsTabletWide(updatedScreenSize.isTabletWide);
    setIsDesktop(updatedScreenSize.isDesktop);
  };

  useLayoutEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    screen,
    isMobile,
    isTabletNarrow,
    isTabletWide,
    isDesktop,
  };
};
