var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { mergeClasses } from '../util/merge-classes';
import { getTestIDProp } from '../util/test-id';
import { SecondaryHeadline } from '../typography';
import { Icon } from '../icon';
export var ModalContext = React.createContext(null);
var useModalContext = function () {
    var context = React.useContext(ModalContext);
    if (!context) {
        throw new Error('Child components of Modal cannot be rendered outside the Modal component!');
    }
    return context;
};
export var ModalHeader = function (_a) {
    var title = _a.title;
    var _b = useModalContext(), onClose = _b.onClose, testID = _b.testID;
    return (_jsxs("div", __assign({ className: "flex flex-row items-center p-4 border-b border-black-12" }, { children: [title && (_jsx(SecondaryHeadline, __assign({ className: "truncate", size: "sm" }, { children: title }))), _jsx("button", __assign({ className: "ml-auto", onClick: onClose, "aria-label": "Close" }, { children: _jsx(Icon, __assign({ className: "hover:stroke-black", size: 15, color: "black-64", name: "Close" }, getTestIDProp(testID, 'close'))) }))] })));
};
export var ModalBody = function (_a) {
    var children = _a.children, className = _a.className;
    useModalContext();
    return _jsx("div", __assign({ className: mergeClasses('p-4', className) }, { children: children }));
};
export var ModalFooter = function (_a) {
    var children = _a.children, className = _a.className;
    useModalContext();
    return (_jsx("div", __assign({ className: mergeClasses('p-4 border-t border-black-12 flex flex-row-reverse gap-2', className) }, { children: children })));
};
