import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useIsPasswordResetIdValidQuery } from '../../data/auth';

type ValidState = 'loading' | 'valid' | 'expired' | 'invalid';

type ResetPasswordRouteParams = {
  passwordResetId: string;
};

export const usePasswordResetIdParam = () => {
  const { passwordResetId } = useParams<ResetPasswordRouteParams>();
  return passwordResetId;
};

export const useValidatePasswordResetId = (): ValidState => {
  const [validState, setValidState] = useState<ValidState>('loading');
  const passwordResetId = usePasswordResetIdParam();

  const { data, isError } = useIsPasswordResetIdValidQuery(
    { passwordResetId: passwordResetId as string },
    { skip: !passwordResetId },
  );

  useEffect(() => {
    if (!passwordResetId) {
      setValidState('invalid');
    }
  }, [passwordResetId, setValidState]);

  useEffect(() => {
    if (isError) {
      setValidState('invalid');
    }
  }, [isError, setValidState]);

  useEffect(() => {
    if (data?.isPasswordResetIdValid === true) {
      setValidState('valid');
    } else if (data?.isPasswordResetIdValid === false) {
      setValidState('expired');
    }
  }, [data?.isPasswordResetIdValid, setValidState]);

  return validState;
};
