import { Button, Text, Headline } from '@goosechase/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslate } from 'util/i18n';
import { z } from 'zod';
import { ReactComponent as GooseTyping } from 'media/goose-typing.svg';
import { LabelledField } from 'components/labelled-field.component';
import { LabelledTextArea } from 'components/labelled-text-area';
import { LabelledNumberField } from 'components/labelled-number-field.component';
import { useRequestQuoteMutation } from 'data/workspaces';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'constants/routes';

export interface RequestQuoteData {
  name: string;
  email: string;
  company: string;
  participantCount: string;
  message: string;
}

const useRequestQuoteForm = () => {
  return useForm<RequestQuoteData>({
    mode: 'onTouched',
    defaultValues: {
      name: '',
      email: '',
      company: '',
      participantCount: '',
      message: '',
    },

    resolver: zodResolver(
      z.object({
        name: z.string(),
        email: z.string().email(),
        company: z.string(),
        participantCount: z.number().int(),
        message: z.string(),
      }),
    ),
  });
};

const SuccessMessage = () => {
  const { t } = useTranslate('quoteForm.success');
  return (
    <div>
      <Text className="mb-3 text-center">{t('thanks')}</Text>
      <Headline type="primary" size="sm" className="mb-10 text-center">
        {t('nextStep')}
      </Headline>
      <GooseTyping className="max-w-[380px] mx-auto mb-10" />
      <Link to={AppRoutes.MY_EXPERIENCES}>
        <Button label={t('getStarted')} className="mx-auto" />
      </Link>
    </div>
  );
};

export const RequestQuoteForm = () => {
  const { t } = useTranslate('quoteForm');
  const { control, handleSubmit, reset, formState } = useRequestQuoteForm();
  const [requestQuote, { isLoading }] = useRequestQuoteMutation();

  const onSubmit: SubmitHandler<RequestQuoteData> = async (params) => {
    const [firstName, ...lastNames] = params.name.split(' ');
    await requestQuote({
      params: {
        firstName: firstName,
        lastName: lastNames.join(' '),
        email: params.email,
        company: params.company,
        participantCount: String(params.participantCount),
        message: params.message,
        sentFromPage: window.location.href,
      },
    }).unwrap();
    reset();
  };

  return (
    <div id="quote">
      {formState.isSubmitSuccessful ? (
        <SuccessMessage />
      ) : (
        <div className="w-full">
          <Headline type="secondary" size="sm" className="mb-2 text-center">
            {t('title')}
          </Headline>
          <Text className="mb-6 text-center">{t('subtitle')}</Text>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
            <div className="flex flex-col tablet-narrow:flex-row gap-8">
              <Controller
                control={control}
                name="name"
                render={({ field, fieldState }) => (
                  <LabelledField
                    label={t('name.label')}
                    placeholder={t('name.placeholder') ?? ''}
                    type="text"
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    autoComplete="name"
                    errorMessage={fieldState.error?.message}
                    disabled={formState.isLoading}
                    defaultValue={field.value}
                    className="flex-1"
                  />
                )}
              />
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState }) => (
                  <LabelledField
                    label={t('email.label')}
                    placeholder={t('email.placeholder') ?? ''}
                    type="text"
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    autoComplete="email"
                    errorMessage={fieldState.error?.message}
                    disabled={formState.isLoading}
                    defaultValue={field.value}
                    className="flex-1"
                  />
                )}
              />
            </div>
            <div className="flex flex-col tablet-narrow:flex-row gap-8">
              <Controller
                control={control}
                name="company"
                render={({ field, fieldState }) => (
                  <LabelledField
                    label={t('organizationName.label')}
                    placeholder={t('organizationName.placeholder') ?? ''}
                    type="text"
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    autoComplete="organization"
                    errorMessage={fieldState.error?.message}
                    disabled={formState.isLoading}
                    defaultValue={field.value}
                    className="flex-1"
                  />
                )}
              />
              <Controller
                control={control}
                name="participantCount"
                render={({ field, fieldState }) => (
                  <LabelledNumberField
                    label={t('numberOfParticipants.label')}
                    placeholder={t('numberOfParticipants.placeholder') ?? ''}
                    type="text"
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    errorMessage={fieldState.error?.message}
                    disabled={formState.isLoading}
                    defaultValue={field.value}
                    className="flex-1"
                    value={field.value}
                    name={field.name}
                    hideSpinButtons
                  />
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="message"
                render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
                  <LabelledTextArea
                    label={t('message.label')}
                    placeholder={t('message.placeholder') ?? undefined}
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    disabled={formState.isLoading}
                    value={value}
                    name={name}
                    rows={4}
                  />
                )}
              />
            </div>
            <div className="flex justify-center">
              <Button label={t('submit')} disabled={isLoading} />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
