import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Nullable } from '../types/util';
import { transformEmptyInputToNull } from '../util/form-util';
import { useTranslate } from '../util/i18n';

interface DeleteSubmissionFormInput {
  reason: string;
}

export interface DeleteSubmissionFormOutput {
  reason: Nullable<string>;
}

export const REASON_MAX_CHARS = 500;

export const useDeleteSubmissionForm = () => {
  const { t } = useTranslate('deleteSubmissionModal');

  const schema = z.object({
    reason: z
      .string()
      .max(REASON_MAX_CHARS, {
        message: t('reason.errors.max', { maxChars: REASON_MAX_CHARS }) ?? undefined,
      })
      .transform(transformEmptyInputToNull),
  });

  return useForm<DeleteSubmissionFormInput, unknown, DeleteSubmissionFormOutput>({
    mode: 'onTouched',
    defaultValues: {
      reason: '',
    },
    resolver: zodResolver(schema),
  });
};
