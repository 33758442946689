import { useDeleteSubmissionBonusMutation } from 'data/submissions';
import { displayToast } from 'services/toast';
import { useTranslate } from 'util/i18n';

export const useDeleteSubmissionBonus = () => {
  const { t } = useTranslate('submissionBonus');
  const [deleteSubmissionBonus] = useDeleteSubmissionBonusMutation();

  return async (submissionBonusId: string) => {
    try {
      await deleteSubmissionBonus({
        submissionBonusId,
      }).unwrap();
      displayToast({
        id: submissionBonusId,
        title: t(`toasts.deleteSuccess.title`),
        type: 'success',
        body: t(`toasts.deleteSuccess.body`),
      });
    } catch {
      displayToast({
        id: submissionBonusId,
        title: t(`toasts.deleteError.title`),
        type: 'error',
        body: t(`toasts.deleteError.body`),
      });
    }
  };
};
