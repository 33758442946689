import { useState } from 'react';

interface DeleteModalState {
  show: boolean;
  submissionId: string;
  missionName: string;
}

const INITIAL_STATE: DeleteModalState = {
  show: false,
  submissionId: '',
  missionName: '',
};

export const useDeleteSubmissionModal = () => {
  const [modalState, setModalState] = useState<DeleteModalState>(INITIAL_STATE);

  const onClose = () => {
    setModalState(INITIAL_STATE);
  };

  const onShow = (submissionId: string, missionName: string) => {
    setModalState({ submissionId, missionName, show: true });
  };

  return {
    ...modalState,
    onClose,
    onShow,
  };
};
