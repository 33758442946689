import { Helmet } from 'react-helmet-async';

interface PageProps {
  title: string;
  description?: string;
  children: React.ReactNode;
}

export const Page = ({ title, description, children }: PageProps) => (
  <>
    <Helmet>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
    {children}
  </>
);
