import { useState } from 'react';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, NotificationBanner } from '@goosechase/ui';

import { useTranslate } from '../../../util/i18n';
import { LabelledField } from '../../../components/labelled-field.component';
import { AppLink } from '../../../components/app-link.component';
import { useForgotPasswordMutation } from '../../../data/auth';
import { InvalidInputErrorIssue, isInvalidInputError } from '../../../data/errors';

interface FormData {
  email: string;
}

const useForgotPasswordForm = () => {
  const { t } = useTranslate('pages.forgotPassword.forgotPasswordForm');

  return useForm<FormData>({
    mode: 'onTouched',
    defaultValues: { email: '' },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .min(1, { message: t('email.errors.required') as string })
          .email({ message: t('email.errors.invalid') as string }),
      }),
    ),
  });
};

interface ServerErrors {
  general?: string;
  email?: string;
}

export interface ForgotPasswordFormProps {
  onSubmitSuccess: () => void;
}

export const ForgotPasswordForm = ({ onSubmitSuccess }: ForgotPasswordFormProps) => {
  const { t } = useTranslate('pages.forgotPassword.forgotPasswordForm');
  const [serverErrors, setServerErrors] = useState<ServerErrors | undefined>();
  const { control, handleSubmit } = useForgotPasswordForm();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const handleInvalidInputError = (issues: InvalidInputErrorIssue[]) => {
    const errors: ServerErrors = {};
    issues.forEach(({ path }) => {
      if (path === 'email') {
        errors.email = t('email.errors.invalid') as string;
      }
    });
    setServerErrors(Object.keys(errors).length > 0 ? errors : undefined);
  };

  const handleGenericError = () => {
    setServerErrors({ general: t('generalError') as string });
  };

  const clearServerErrors = () => {
    setServerErrors(undefined);
  };

  const onSubmit = async ({ email }: FormData) => {
    try {
      await forgotPassword({ email }).unwrap();
      onSubmitSuccess();
    } catch (err) {
      if (isInvalidInputError(err)) {
        handleInvalidInputError(err.issues);
      } else {
        handleGenericError();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6 desktop:mb-8">
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur }, fieldState }) => (
            <LabelledField
              label={t('email.label')}
              type="text"
              onChange={(input) => {
                if (serverErrors) {
                  clearServerErrors();
                }
                onChange(input);
              }}
              onBlur={onBlur}
              errorMessage={fieldState.error?.message || serverErrors?.email}
              disabled={isLoading}
            />
          )}
        />
      </div>
      {serverErrors?.general && (
        <NotificationBanner
          className="mb-6 desktop:mb-8"
          type="error"
          title={t('generalErrorTitle') as string}
          body={serverErrors.general}
          showIcon
        />
      )}
      <div className="flex flex-col tablet-narrow:flex-row gap-6 tablet-narrow:gap-10 items-center">
        <Button className="w-full tablet-narrow:w-fit" label={t('submit')} disabled={isLoading} />
        <div className="tablet-narrow:text-paragraph-lg">
          <AppLink page="LOGIN">{t('backToLogIn')}</AppLink>
        </div>
      </div>
    </form>
  );
};
