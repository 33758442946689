import { useTranslate } from 'util/i18n';
import { useMyExperiencesQuery, type MyExperiencesQuery } from 'data/experiences';
import { LoadingSpinner } from 'components/loading-spinner';
import { EmptyState } from 'components/empty-state/empty-state.component';
import { Card } from 'components/card.component';
import { ExperienceCard } from 'components/experience-card';
import { ErrorState } from 'components/error-state';
import { CohortState } from '../../data/models';
import { ArrayElement } from 'global';
import { useActiveWorkspaceWithSubscriptionQuery } from 'data/workspaces';
import { InlineBanner } from 'components/inline-banner';
import { Nullable } from 'types/util';

interface ExperienceFields {
  id: string;
  displayName: string;
  shareLink: Nullable<string>;
  description: string;
  photoUrl?: string;
  status: CohortState;
  addons: { id: string; maxTeamsPerExperience: Nullable<number> }[];
  roleId?: string;
  workspacePlanId: string;
  workspaceId: string;
}

const sortExperiences = (
  a: ArrayElement<MyExperiencesQuery['myManagedExperiences']>,
  b: ArrayElement<MyExperiencesQuery['myManagedExperiences']>,
) => {
  if (a.status === CohortState.Active && b.status !== CohortState.Active) {
    return -1; // Live experiences come first
  } else if (a.status !== CohortState.Active && b.status === CohortState.Active) {
    return 1; // Live experiences come first
  } else if (
    a.status === CohortState.Active &&
    b.status === CohortState.Active &&
    a.endDate &&
    b.endDate
  ) {
    // Both are live, sort by endDate
    const endDateComparison = new Date(a.endDate).getTime() - new Date(b.endDate).getTime();
    if (!endDateComparison) {
      return a.displayName.localeCompare(b.displayName);
    } else {
      return endDateComparison;
    }
  } else {
    // If they are not live, sort by creation date or alphabetically by displayName
    return (
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime() ||
      a.displayName.localeCompare(b.displayName)
    );
  }
};

const transformMyExperiencesDataToExperiencesList = (
  data?: MyExperiencesQuery,
): ExperienceFields[] => {
  const rawExperiences = data?.myManagedExperiences ?? [];

  return rawExperiences
    .slice()
    .sort(sortExperiences)
    .map(
      ({
        id,
        displayName,
        description,
        shareLink,
        assets,
        status,
        addons,
        myExperienceManager,
        workspace,
      }) => ({
        id,
        displayName,
        description,
        photoUrl: assets.find((asset) => asset.role === 'PHOTO')?.url,
        status,
        addons,
        roleId: myExperienceManager?.role.id,
        workspacePlanId: workspace.subscription.plan.id,
        workspaceId: workspace.id,
        shareLink,
      }),
    );
};

export const MyExperiencesList = () => {
  const { t } = useTranslate('pages.experiences.myExperiencesList');
  const { data, isLoading, isError } = useMyExperiencesQuery();
  const { data: workspaceData } = useActiveWorkspaceWithSubscriptionQuery();

  const experiences = transformMyExperiencesDataToExperiencesList(data);

  if (isLoading) {
    return (
      <Card>
        <div className="flex justify-center py-16 px-8">
          <LoadingSpinner />
        </div>
      </Card>
    );
  }
  if (isError) {
    return (
      <Card>
        <div className="flex justify-center py-16 px-8">
          <ErrorState />
        </div>
      </Card>
    );
  }

  if (!experiences || experiences.length === 0) {
    return (
      <Card className="p-12">
        <EmptyState text={t('emptyState.text')} subtext={t('emptyState.subtext')} />
      </Card>
    );
  }

  const activeWorkspace = workspaceData?.myActiveWorkspace;
  const isOwner = activeWorkspace?.myWorkspaceMember?.roleId === 'owner';
  const canUpgradePlan = isOwner && activeWorkspace?.subscription?.plan.upgradable;
  const liveExperiences = experiences.filter(
    (experience) =>
      experience.status === CohortState.Active && experience.workspaceId === activeWorkspace?.id,
  );

  return (
    <div className="flex flex-col gap-6 mb-28">
      {experiences
        .filter((experience) => experience.status === CohortState.Active)
        .map(({ id, displayName, description, photoUrl, status, addons, roleId, shareLink }) => {
          return (
            <ExperienceCard
              className="w-full"
              key={id}
              experienceId={id}
              title={displayName}
              description={description}
              srcImage={photoUrl}
              status={status}
              addons={addons}
              canUpgradeWorkspace={Boolean(
                workspaceData?.myActiveWorkspace?.subscription.plan.upgradable,
              )}
              roleId={roleId}
              shareLink={shareLink}
            />
          );
        })}
      {activeWorkspace &&
      activeWorkspace.subscription.plan.maxConcurrentExperiences &&
      liveExperiences.length >= activeWorkspace.subscription.plan.maxConcurrentExperiences &&
      canUpgradePlan ? (
        <InlineBanner i18nKey="freeLiveExperienceLimit" showUpgradePlanAction />
      ) : null}
      {experiences
        .filter((experience) => experience.status !== CohortState.Active)
        .map(({ id, displayName, description, photoUrl, status, addons, roleId, shareLink }) => {
          return (
            <ExperienceCard
              className="w-full"
              key={id}
              experienceId={id}
              title={displayName}
              description={description}
              srcImage={photoUrl}
              status={status}
              addons={addons}
              roleId={roleId}
              canUpgradeWorkspace={Boolean(
                workspaceData?.myActiveWorkspace?.subscription.plan.upgradable,
              )}
              shareLink={shareLink}
            />
          );
        })}
    </div>
  );
};
