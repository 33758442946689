import { mergeClasses, Headline, Text, RadioButton } from '@goosechase/ui';

type RadioBlockOption = {
  value: string;
  optionLabel: string;
  description: string;
  image?: React.ReactNode;
  disabled?: boolean;
};

export type RadioBlockSelectorProps = {
  name: string;
  label?: string;
  options: RadioBlockOption[];
  selectedValue?: string;
  className?: string;
  onSelect: React.ChangeEventHandler<HTMLInputElement>;
};

// RadioBlockSelector is intended for quick and consistent use of a list of RadioButton options with big boxy labels
export const RadioBlockSelector = ({
  name,
  label,
  options,
  selectedValue,
  className,
  onSelect,
}: RadioBlockSelectorProps) => (
  <div>
    {label ? (
      <Headline size="xs" type="secondary" className="block mb-3">
        {label}
      </Headline>
    ) : null}
    <div
      className={mergeClasses(
        'flex flex-col tablet-narrow:flex-row tablet-narrow:flex-wrap gap-6',
        className,
      )}>
      {options.map(({ value, optionLabel, description, image, disabled }) => (
        <label key={value} className="flex flex-1 cursor-pointer">
          <div
            className={mergeClasses(
              'flex flex-1 flex-row gap-x-3 px-4 py-3 border rounded-lg outline outline-2',
              {
                'outline-vibrantBlue bg-vibrantBlue-10 text-vibrantBlue': selectedValue == value,
                'outline-black-32 text-black-64': selectedValue != value,
                'outline-black-24 text-black-48': disabled,
              },
            )}>
            <div className="flex flex-col gap-3">
              {image ?? null}
              <div className="flex gap-3">
                <div className="mt-1 flex align-top">
                  <RadioButton
                    value={value}
                    name={name}
                    checked={value === selectedValue}
                    onChange={onSelect}
                    disabled={disabled}
                  />
                </div>
                <div>
                  <Text className="mb-1 font-bold">{optionLabel}</Text>
                  <Text size="xs">
                    <span dangerouslySetInnerHTML={{ __html: description }} />
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </label>
      ))}
    </div>
  </div>
);
