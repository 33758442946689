import { z } from 'zod';
import * as dateFns from 'date-fns';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useTranslate } from '../../util/i18n';
import {
  BroadcastTriggerTiming,
  TRIGGER_TIME_UNITS,
  TriggerTimeUnit,
  TriggerTimeAnchor,
  BroadcastSendOption,
  DURING_EXPERIENCE_TIME_ANCHORS,
} from '../../data/models';
import { BASE_BROADCAST_DEFAULTS, MESSAGE_MAX_CHARS } from './constants';
import { Nullable } from 'types/util';
import { isBroadcastTriggerValid } from 'util/time-util';

export interface BroadcastFormInputData {
  id?: string;
  sendTo: string;
  teamName: string;
  message: string;
  sendTiming: BroadcastSendOption;
  beforeExperienceDuration?: Nullable<number>;
  beforeExperienceUnit?: Nullable<TriggerTimeUnit>;
  duringExperienceTiming?: Nullable<
    BroadcastTriggerTiming.Relative | BroadcastTriggerTiming.Specific
  >;
  relativeDuration?: Nullable<number>;
  relativeUnit?: Nullable<TriggerTimeUnit>;
  relativeAnchor?: Nullable<TriggerTimeAnchor>;
  specificDay?: Nullable<number>;
  specificTime?: Nullable<string>;
  afterExperienceDuration?: Nullable<number>;
  afterExperienceUnit?: Nullable<TriggerTimeUnit>;
}

export type BroadcastFormOutputData = BroadcastFormInputData;

export interface UseBroadcastFormArgs {
  defaultValues: Partial<BroadcastFormInputData>;
  cohortStartDate?: Nullable<string>;
  cohortEndDate?: Nullable<string>;
  experienceTimezone: string;
}

// eslint-disable-next-line complexity
export const useBroadcastForm = ({
  defaultValues,
  cohortEndDate,
  cohortStartDate,
  experienceTimezone,
}: UseBroadcastFormArgs) => {
  const { t } = useTranslate('broadcastForm');

  const sendToSchema = z.literal('ALL').or(z.string().uuid());
  const messageSchema = z
    .string()
    .min(1, { message: t('message.errors.required') ?? undefined })
    .max(MESSAGE_MAX_CHARS, {
      message: t('message.errors.max', { maxChars: MESSAGE_MAX_CHARS }) ?? undefined,
    });

  const nowSchema = z.object({
    id: z.string().uuid().optional(),
    sendTo: sendToSchema,
    teamName: z.string(),
    message: messageSchema,
    sendTiming: z.literal(BroadcastSendOption.Now),
  });

  const beforeExperienceSchema = z.object({
    id: z.string().uuid().optional(),
    sendTo: sendToSchema,
    teamName: z.string(),
    message: messageSchema,
    sendTiming: z.literal(BroadcastSendOption.BeforeExperience),
    beforeExperienceDuration: z
      .number({
        required_error: t('broadcastTrigger.relativeDuration.errors.required') ?? undefined,
        invalid_type_error: t('broadcastTrigger.relativeDuration.errors.invalid') ?? undefined,
      })
      .int(t('broadcastTrigger.relativeDuration.errors.invalid') ?? undefined)
      .positive(t('broadcastTrigger.relativeDuration.errors.invalid') ?? undefined),
    beforeExperienceUnit: z.enum(TRIGGER_TIME_UNITS),
  });

  const atStartSchema = z.object({
    id: z.string().uuid().optional(),
    sendTo: sendToSchema,
    teamName: z.string(),
    message: messageSchema,
    sendTiming: z.literal(BroadcastSendOption.AtStartOfExperience),
  });

  const relativeSchema = z.object({
    id: z.string().uuid().optional(),
    sendTo: sendToSchema,
    teamName: z.string(),
    message: messageSchema,
    sendTiming: z.literal(BroadcastSendOption.DuringExperience),
    duringExperienceTiming: z.literal(BroadcastTriggerTiming.Relative),
    relativeDuration: z
      .number({
        required_error: t('broadcastTrigger.relativeDuration.errors.required') ?? undefined,
        invalid_type_error: t('broadcastTrigger.relativeDuration.errors.invalid') ?? undefined,
      })
      .int(t('broadcastTrigger.relativeDuration.errors.invalid') ?? undefined)
      .positive(t('broadcastTrigger.relativeDuration.errors.invalid') ?? undefined),
    relativeUnit: z.enum(TRIGGER_TIME_UNITS),
    relativeAnchor: z.enum(DURING_EXPERIENCE_TIME_ANCHORS),
  });

  const specificSchema = z.object({
    id: z.string().uuid().optional(),
    sendTo: sendToSchema,
    teamName: z.string(),
    message: messageSchema,
    sendTiming: z.literal(BroadcastSendOption.DuringExperience),
    duringExperienceTiming: z.literal(BroadcastTriggerTiming.Specific),
    specificDay: z
      .number({
        required_error: t('broadcastTrigger.specificDay.errors.required') ?? undefined,
        invalid_type_error: t('broadcastTrigger.specificDay.errors.required') ?? undefined,
      })
      .int()
      .min(1, t('broadcastTrigger.specificDay.errors.minDay') ?? undefined),
    specificTime: z
      .date({ required_error: t('broadcastTrigger.specificTime.errors.required') ?? undefined })
      .transform((value) => {
        return dateFns.format(value, 'H:mm');
      })
      .or(z.string()),
  });

  const atEndSchema = z.object({
    id: z.string().uuid().optional(),
    sendTo: sendToSchema,
    teamName: z.string(),
    message: messageSchema,
    sendTiming: z.literal(BroadcastSendOption.AtEndOfExperience),
  });

  const afterExperienceSchema = z.object({
    id: z.string().uuid().optional(),
    sendTo: sendToSchema,
    teamName: z.string(),
    message: messageSchema,
    sendTiming: z.literal(BroadcastSendOption.AfterExperience),
    afterExperienceDuration: z
      .number({
        required_error: t('broadcastTrigger.relativeDuration.errors.required') ?? undefined,
        invalid_type_error: t('broadcastTrigger.relativeDuration.errors.invalid') ?? undefined,
      })
      .int(t('broadcastTrigger.relativeDuration.errors.invalid') ?? undefined)
      .positive(t('broadcastTrigger.relativeDuration.errors.invalid') ?? undefined),
    afterExperienceUnit: z.enum(TRIGGER_TIME_UNITS),
  });

  const broadcastFormSchema = z
    .union([
      nowSchema,
      beforeExperienceSchema,
      atStartSchema,
      relativeSchema,
      specificSchema,
      atEndSchema,
      afterExperienceSchema,
    ])
    // eslint-disable-next-line complexity
    .superRefine((val, ctx) => {
      if (val.sendTiming === BroadcastSendOption.BeforeExperience) {
        if (
          !isBroadcastTriggerValid({
            trigger: {
              timing: BroadcastTriggerTiming.Relative,
              relativeAnchor: TriggerTimeAnchor.BeforeStart,
              relativeDuration: val.beforeExperienceDuration,
              relativeUnit: val.beforeExperienceUnit,
              specificDay: null,
              specificTime: null,
            },
            startDate: cohortStartDate,
            endDate: cohortEndDate,
            experienceTimezone,
          })
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t(`broadcastTrigger.errors.thirtyDays`) ?? '',
            path: ['beforeExperienceUnit'],
          });
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t(`broadcastTrigger.errors.thirtyDays`) ?? '',
            path: ['beforeExperienceDuration'],
          });
        }
      } else if (val.sendTiming === BroadcastSendOption.DuringExperience) {
        if (val.duringExperienceTiming === BroadcastTriggerTiming.Relative) {
          if (
            !isBroadcastTriggerValid({
              trigger: {
                timing: BroadcastTriggerTiming.Relative,
                relativeAnchor: val.relativeAnchor,
                relativeDuration: val.relativeDuration,
                relativeUnit: val.relativeUnit,
                specificDay: null,
                specificTime: null,
              },
              startDate: cohortStartDate,
              endDate: cohortEndDate,
              experienceTimezone,
            })
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t(`broadcastTrigger.errors.outside`) ?? '',
              path: ['relativeUnit'],
            });
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t(`broadcastTrigger.errors.outside`) ?? '',
              path: ['relativeAnchor'],
            });
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t(`broadcastTrigger.errors.outside`) ?? '',
              path: ['relativeDuration'],
            });
          }
        } else if (val.duringExperienceTiming === BroadcastTriggerTiming.Specific) {
          if (
            !isBroadcastTriggerValid({
              trigger: {
                timing: BroadcastTriggerTiming.Specific,
                specificDay: val.specificDay,
                specificTime: val.specificTime,
                relativeAnchor: null,
                relativeDuration: null,
                relativeUnit: null,
              },
              startDate: cohortStartDate,
              endDate: cohortEndDate,
              experienceTimezone,
            })
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t(`broadcastTrigger.errors.outside`) ?? '',
              path: ['specificDay'],
            });
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t(`broadcastTrigger.errors.outside`) ?? '',
              path: ['specificTime'],
            });
          }
        }
      } else if (val.sendTiming === BroadcastSendOption.AfterExperience) {
        if (
          !isBroadcastTriggerValid({
            trigger: {
              timing: BroadcastTriggerTiming.Relative,
              relativeAnchor: TriggerTimeAnchor.AfterEnd,
              relativeDuration: val.afterExperienceDuration,
              relativeUnit: val.afterExperienceUnit,
              specificDay: null,
              specificTime: null,
            },
            startDate: cohortStartDate,
            endDate: cohortEndDate,
            experienceTimezone,
          })
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t(`broadcastTrigger.errors.thirtyDays`) ?? '',
            path: ['afterExperienceUnit'],
          });
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t(`broadcastTrigger.errors.thirtyDays`) ?? '',
            path: ['afterExperienceDuration'],
          });
        }
      }
      return true;
    });

  return useForm<BroadcastFormInputData, unknown, BroadcastFormOutputData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      id: defaultValues.id ?? BASE_BROADCAST_DEFAULTS.ID,
      message: defaultValues.message ?? BASE_BROADCAST_DEFAULTS.MESSAGE,
      sendTo: defaultValues.sendTo ?? BASE_BROADCAST_DEFAULTS.SEND_TO,
      teamName: defaultValues.teamName ?? BASE_BROADCAST_DEFAULTS.TEAM_NAME,
      sendTiming: defaultValues.sendTiming ?? BASE_BROADCAST_DEFAULTS.SEND_TIMING,
      beforeExperienceDuration: defaultValues.beforeExperienceDuration,
      beforeExperienceUnit: defaultValues.beforeExperienceUnit ?? BASE_BROADCAST_DEFAULTS.TIME_UNIT,
      duringExperienceTiming: defaultValues.duringExperienceTiming,
      relativeDuration: defaultValues.relativeDuration,
      relativeAnchor: defaultValues.relativeAnchor ?? BASE_BROADCAST_DEFAULTS.ANCHOR,
      relativeUnit: defaultValues.relativeUnit ?? BASE_BROADCAST_DEFAULTS.TIME_UNIT,
      specificDay: defaultValues.specificDay,
      specificTime: defaultValues.specificTime,
      afterExperienceDuration: defaultValues.afterExperienceDuration,
      afterExperienceUnit: defaultValues.afterExperienceUnit ?? BASE_BROADCAST_DEFAULTS.TIME_UNIT,
    },
    resolver: zodResolver(broadcastFormSchema),
  });
};
