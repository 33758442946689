import { mergeClasses } from '@goosechase/ui';

import { MissionType, MissionTypeName } from '../../data/models';
import { ReactComponent as CameraMissionDrawing } from 'media/camera-mission-drawing.svg';
import { ReactComponent as TextMissionDrawing } from 'media/text-mission-drawing.svg';
import { ReactComponent as GpsMissionDrawing } from 'media/gps-mission-drawing.svg';

const typeToIconMap: Record<
  MissionTypeName | MissionType,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  CameraMission: CameraMissionDrawing,
  TextMission: TextMissionDrawing,
  GpsMission: GpsMissionDrawing,
  CAMERA: CameraMissionDrawing,
  GPS: GpsMissionDrawing,
  TEXT: TextMissionDrawing,
};

export interface MissionIconProps {
  className?: string;
  type: MissionTypeName | MissionType;
}

export const MissionIcon = ({ className, type }: MissionIconProps) => {
  const Icon = typeToIconMap[type];

  return <Icon className={mergeClasses('w-[70px] h-[70px] shrink-0', className)} />;
};
