import { IconButton, Text } from '@goosechase/ui';
import { format } from 'date-fns';
import { Nullable } from 'types/util';
import { useTranslate } from 'util/i18n';
import { Card } from '../card.component';
import { BonusPointsPill } from '../points-pill';

export interface BonusPointsCardProps {
  bonusId: string;
  value: number;
  createdAt: Date;
  onDelete?: (bonusId: string) => void;
  note: Nullable<string>;
}

export const BonusPointsCard = ({
  bonusId,
  note,
  value,
  createdAt,
  onDelete,
}: BonusPointsCardProps) => {
  const { t } = useTranslate('bonusCard');

  return (
    <Card className="border border-black-24 p-4">
      <div className="flex flex-col gap-3">
        <div className="flex items-center justify-between">
          <BonusPointsPill points={value} />
          {onDelete ? (
            <IconButton
              icon="Delete"
              iconColor="black-32"
              onClick={() => onDelete(bonusId)}
              aria-label={t('ariaDeleteBonusLabel') ?? 'Delete bonus'}
            />
          ) : null}
        </div>
        {note ? <Text>{note}</Text> : null}
        <Text>{format(createdAt, t('dateFormat'))}</Text>
      </div>
    </Card>
  );
};
