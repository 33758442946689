/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type CohortSubmissionArchivesQueryVariables = Types.Exact<{
  cohortId: Types.Scalars['ID']['input'];
  state: Types.InputMaybe<Types.SubmissionArchiveState>;
}>;


export type CohortSubmissionArchivesQuery = { __typename: 'Query', cohortSubmissionArchives: Array<{ __typename: 'SubmissionArchive', id: string, state: Types.SubmissionArchiveState, grouping: Types.SubmissionArchiveGrouping, createdAt: string, url: string | null, name: string | null }> };


export const CohortSubmissionArchivesDocument = `
    query CohortSubmissionArchives($cohortId: ID!, $state: SubmissionArchiveState) {
  cohortSubmissionArchives(cohortId: $cohortId, state: $state) {
    id
    state
    grouping
    createdAt
    url
    name
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    CohortSubmissionArchives: build.query<CohortSubmissionArchivesQuery, CohortSubmissionArchivesQueryVariables>({
      query: (variables) => ({ document: CohortSubmissionArchivesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCohortSubmissionArchivesQuery, useLazyCohortSubmissionArchivesQuery } = injectedRtkApi;

