import useInfiniteScroll from './use-infinite-scroll';
import { useEffect, useState } from 'react';
import uniqBy from 'lodash.uniqby';
import { SubmissionFeedQuery, useSubmissionFeedQuery } from 'data/submissions';
import { useExperienceOutletContext } from 'components/experience-layout';
import { SortOrder, SubmissionFeedOrderBy } from 'data/models';
import { Nullable } from 'types/util';

export const useSubmissionFeed = () => {
  const { experienceId } = useExperienceOutletContext();
  const [submissions, setSubmissions] = useState<SubmissionFeedQuery['submissionFeed']['edges']>(
    [],
  );
  const [after, setAfter] = useState<string | null>(null);
  const { data, isUninitialized, isLoading, isError, isFetching } = useSubmissionFeedQuery({
    after,
    experienceId,
    first: 10,
    orderBy: SubmissionFeedOrderBy.CreatedAt,
    orderDirection: SortOrder.Desc,
  });

  useEffect(() => {
    if (data?.submissionFeed.edges.length) {
      setSubmissions(uniqBy([...submissions, ...data.submissionFeed.edges], 'cursor'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const fetchNextPage = () => {
    if (data?.submissionFeed.pageInfo.endCursor) {
      setAfter(data.submissionFeed.pageInfo.endCursor);
    }
  };

  const { thresholdElementRef } = useInfiniteScroll({
    fetchNextPage,
  });

  const toggleSubmissionLike = (id: string) => {
    setSubmissions(
      submissions.map((submission) => {
        if (submission.node.id === id) {
          return {
            ...submission,
            node: {
              ...submission.node,
              liked: !submission.node.liked,
              numLikes: submission.node.liked
                ? submission.node.numLikes - 1
                : submission.node.numLikes + 1,
            },
          };
        } else {
          return submission;
        }
      }),
    );
  };

  const deleteSubmission = (id: string) => {
    setSubmissions(submissions.filter((submission) => submission.node.id !== id));
  };

  const handleAddSubmissionBonus = (
    submissionId: string,
    bonusId: string,
    value: number,
    note?: Nullable<string>,
  ) => {
    setSubmissions(
      submissions.map((submission) => {
        if (submission.node.id === submissionId) {
          return {
            ...submission,
            node: {
              ...submission.node,
              bonuses: [
                ...submission.node.bonuses,
                {
                  __typename: 'SubmissionBonus' as const,
                  value,
                  note: note ?? null,
                  id: bonusId,
                  createdAt: new Date().toISOString(),
                },
              ],
              bonusPoints: submission.node.bonusPoints + value,
            },
          };
        }
        return submission;
      }),
    );
  };

  return {
    isLoading,
    isFetching,
    data: submissions,
    totalCount: data?.submissionFeed.totalCount,
    thresholdElementRef,
    isUninitialized,
    isError,
    toggleSubmissionLike,
    deleteSubmission,
    handleAddSubmissionBonus,
  };
};
