var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Palette = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M14.0039 6.5C14.0039 6.77614 13.78 7 13.5039 7C13.2278 7 13.0039 6.77614 13.0039 6.5C13.0039 6.22386 13.2278 6 13.5039 6C13.78 6 14.0039 6.22386 14.0039 6.5Z", stroke: "inherit", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M18.0039 10.5C18.0039 10.7761 17.78 11 17.5039 11C17.2278 11 17.0039 10.7761 17.0039 10.5C17.0039 10.2239 17.2278 10 17.5039 10C17.78 10 18.0039 10.2239 18.0039 10.5Z", stroke: "inherit", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M8.50391 8C8.78005 8 9.00391 7.77614 9.00391 7.5C9.00391 7.22386 8.78005 7 8.50391 7C8.22776 7 8.00391 7.22386 8.00391 7.5C8.00391 7.77614 8.22776 8 8.50391 8Z" }), _jsx("path", { d: "M9.00391 7.5C9.00391 7.77614 8.78005 8 8.50391 8C8.22776 8 8.00391 7.77614 8.00391 7.5C8.00391 7.22386 8.22776 7 8.50391 7C8.78005 7 9.00391 7.22386 9.00391 7.5Z", stroke: "inherit", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M8.00391 13.5C8.00391 13.7761 7.78005 14 7.50391 14C7.22776 14 7.00391 13.7761 7.00391 13.5C7.00391 13.2239 7.22776 13 7.50391 13C7.78005 13 8.00391 13.2239 8.00391 13.5Z", stroke: "inherit", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M12.0039 2C6.50391 2 2.00391 6.5 2.00391 12C2.00391 17.5 6.50391 22 12.0039 22C12.9299 22 13.6519 21.254 13.6519 20.312C13.6519 19.875 13.4719 19.477 13.2149 19.187C12.9249 18.898 12.7769 18.535 12.7769 18.062C12.7731 17.8419 12.8137 17.6233 12.8962 17.4192C12.9786 17.2151 13.1014 17.0298 13.257 16.8741C13.4127 16.7185 13.598 16.5957 13.8021 16.5132C14.0062 16.4308 14.2248 16.3902 14.4449 16.394H16.4409C19.4919 16.394 21.9959 13.891 21.9959 10.84C21.9689 6.012 17.4649 2 12.0039 2Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
