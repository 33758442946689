// import { useActiveCohortId } from '../../data/experiences';
// import { useTranslate } from '../../util/i18n';
// import { SubmissionInfoTable } from './submission-info-table.component';
import { LeaderboardTable } from './leaderboard-table.component';
import { MainStatistics } from './main-statistics.component';

export const Statistics = () => {
  // const activeCohortId = useActiveCohortId();
  // const { t } = useTranslate('pages.stats');

  return (
    <div
      className="grid grid-cols-4
        grid-rows-16 tablet-narrow:grid-rows-10 desktop:grid-rows-6
        gap-y-2 gap-x-2
        font-soleil">
      <MainStatistics />
      {/* <SubmissionInfoTable
        cohortId={activeCohortId}
        fileName={t('submissionDetails.fileName')}
        label={t('submissionDetails.label')}
      /> */}
      <LeaderboardTable />
    </div>
  );
};
