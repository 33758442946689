import { Icon, IconName, StyledAnchor, Text, mergeClasses } from '@goosechase/ui';

interface Link {
  label: string;
  href: string;
}

export interface InformationBannerProps {
  className?: string;
  icon?: IconName;
  body: string;
  boldBody?: string;
  link?: Link;
}

export const InformationBanner = ({
  className,
  icon,
  body,
  boldBody,
  link,
}: InformationBannerProps) => {
  return (
    <div
      className={mergeClasses(
        'flex flex-col gap-2.5 py-4 px-6 bg-darkGreen rounded-lg',
        className,
      )}>
      {icon && <Icon name={icon} color="white" size={24} />}
      {boldBody && (
        <Text className="text-white font-bold mb-3" size="md">
          {boldBody}
        </Text>
      )}
      <Text className="text-white" size="md">
        {body}
      </Text>
      {link && (
        <StyledAnchor
          className="bg-darkGreen text-white p-2 hover:text-white"
          underlineClassName="bg-highlighterYellow group-hover:bg-white"
          href={link.href}>
          {link.label}
        </StyledAnchor>
      )}
    </div>
  );
};
