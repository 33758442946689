import { workspacesSlice } from './workspace.slice';
import {
  createEducatorWorkspaceApi,
  activeWorkspaceWithSubscriptionApi,
  activeWorkspaceApi,
  availablePlansApi,
  startPlanCheckoutApi,
  requestQuoteApi,
  getBillingDetailsRedirectApi,
  experienceWorkspaceApi,
  updateWorkspaceApi,
  workspaceMembersApi,
  updateWorkspaceMemberMutationApi,
  deleteWorkspaceMemberMutationApi,
  pendingWorkspaceInvitationsApi,
  createWorkspaceInvitationApi,
  deleteWorkspaceInvitationApi,
  updateWorkspaceInvitationApi,
  workspaceInvitationApi,
  acceptWorkspaceInvitationApi,
  completePlanCheckoutApi,
  myOwnedWorkspacesApi,
  upcomingBillingApi,
  myWorkspacesApi,
  workspaceApi,
} from './workspaces.api';

export const { useCreateEducatorWorkspaceMutation } = createEducatorWorkspaceApi;
export const { useActiveWorkspaceWithSubscriptionQuery } = activeWorkspaceWithSubscriptionApi;
export const { useActiveWorkspaceQuery } = activeWorkspaceApi;
export const { useAvailablePlansQuery } = availablePlansApi;
export const { useStartPlanCheckoutMutation } = startPlanCheckoutApi;
export const { useRequestQuoteMutation } = requestQuoteApi;
export const { useGetBillingDetailsRedirectMutation } = getBillingDetailsRedirectApi;
export const { useExperienceWorkspaceQuery } = experienceWorkspaceApi;
export const { useUpdateWorkspaceMutation } = updateWorkspaceApi;
export const { useWorkspaceMembersQuery } = workspaceMembersApi;
export const { useUpdateWorkspaceMemberMutation } = updateWorkspaceMemberMutationApi;
export const { useDeleteWorkspaceMemberMutation } = deleteWorkspaceMemberMutationApi;
export const { usePendingWorkspaceInvitationsQuery } = pendingWorkspaceInvitationsApi;
export const { useCreateWorkspaceInvitationMutation } = createWorkspaceInvitationApi;
export const { useDeleteWorkspaceInvitationMutation } = deleteWorkspaceInvitationApi;
export const { useUpdateWorkspaceInvitationMutation } = updateWorkspaceInvitationApi;
export const { useWorkspaceInvitationQuery } = workspaceInvitationApi;
export const { useAcceptWorkspaceInvitationMutation } = acceptWorkspaceInvitationApi;
export const { useCompletePlanCheckoutMutation } = completePlanCheckoutApi;
export const { useMyOwnedWorkspacesQuery, useLazyMyOwnedWorkspacesQuery } = myOwnedWorkspacesApi;
export const { useUpcomingBillingQuery } = upcomingBillingApi;
export const { useMyWorkspacesQuery } = myWorkspacesApi;
export const { useWorkspaceQuery } = workspaceApi;

export const { toggleAcceptedWorkspaceInvitationModal, setSelectedWorkspace } =
  workspacesSlice.actions;
export const workspacesReducer = workspacesSlice.reducer;
export * from './workspace.hooks';
