import { Text } from '@goosechase/ui';
import { useTranslate } from '../../util/i18n';
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form';

import { LabelledField } from '../labelled-field.component';
import { TeamModeInput } from './team-mode-input.component';
import { TeamFormInputData } from './use-team-form';
import { PhotoUploadField, type PhotoUploadUIProps } from 'components/photo-upload';
import { TeamPhotoUploadUI } from './team-photo-upload-ui.component';
import { MaxSizeInput } from 'components/max-size-input';
import { TeamMode } from '../../data/models';

export interface TeamFormUIProps {
  control: Control<TeamFormInputData>;
  isLoading: boolean;
  uploadingPhoto: boolean;
  setUploadingPhoto: (value: boolean) => void;
  setValue: UseFormSetValue<TeamFormInputData>;
  teamCreationMode: TeamMode;
  context: 'create' | 'edit';
  photoUrl?: string;
  shouldDisableIndividualMode?: boolean;
}

export const TeamFormUI = ({
  control,
  isLoading,
  uploadingPhoto,
  setUploadingPhoto,
  setValue,
  context,
  photoUrl,
  shouldDisableIndividualMode,
  teamCreationMode,
}: TeamFormUIProps) => {
  const { t } = useTranslate('pages.participants.teamForm');
  const mode = useWatch({ name: 'mode', control });

  return (
    <div className="flex flex-col h-full">
      <div className="grid grid-cols-6 grow w-full overflow-auto p-1 tablet-narrow:px-4 desktop:px-12 py-1 gap-x-16">
        <div className="flex grow justify-center col-span-6 desktop:col-span-2">
          <Controller
            control={control}
            name="assetId"
            render={({ fieldState: { error } }) => (
              <div className="flex flex-col ">
                <PhotoUploadField
                  label={t('photoId.label')}
                  setValue={(assetId: string) => {
                    setValue('assetId', assetId, { shouldDirty: true });
                  }}
                  setError={() => {}}
                  errorMessage={error?.message}
                  defaultSrc={photoUrl}
                  setUploading={setUploadingPhoto}
                  uploading={uploadingPhoto}
                  photoUploadUI={(data: PhotoUploadUIProps) => (
                    <TeamPhotoUploadUI {...data} control={control} />
                  )}
                />
              </div>
            )}
          />
        </div>
        <div className="flex flex-col grow min-w-0 gap-4 col-span-6 desktop:col-span-4">
          <Controller
            control={control}
            name="displayName"
            render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
              <LabelledField
                label={t(`displayName.label.${mode?.toLowerCase() ?? 'team'}`)}
                type="text"
                errorMessage={error?.message}
                disabled={isLoading}
                value={value}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={t(`displayName.placeholder.${mode?.toLowerCase() ?? 'team'}`) ?? ''}
              />
            )}
          />
          <Controller
            control={control}
            name="passcode"
            render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
              <LabelledField
                label={t('passcode.label')}
                type="text"
                errorMessage={error?.message}
                disabled={isLoading}
                value={value}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={t('passcode.placeholder') ?? ''}
                hint={t('passcode.hint') ?? undefined}
              />
            )}
          />
          <Controller
            control={control}
            name="mode"
            render={({ field: { onChange, name, value }, fieldState }) => (
              <>
                <TeamModeInput
                  name={name}
                  onChange={onChange}
                  value={value}
                  shouldDisableIndividualMode={shouldDisableIndividualMode}
                />
                {fieldState.error?.message ? (
                  <Text size="xs" className="text-vibrantRed mt-2">
                    {fieldState.error?.message}
                  </Text>
                ) : null}
              </>
            )}
          />
          {teamCreationMode === TeamMode.Team ? (
            <div className="mt-4">
              <Controller
                control={control}
                name="maxSize"
                render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
                  <MaxSizeInput
                    label={t(`maxSize.label.${context}`)}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    value={value}
                    errorMessage={error?.message}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
