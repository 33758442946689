import { useState } from 'react';
import { useLoginMutation, type LoginMutation } from 'data/auth';
import { useTranslate } from 'util/i18n';

import { LoginFormUI } from './login-form-ui';
import { isNotAllowedError } from 'data/errors';

interface LoginFormData {
  email: string;
  password: string;
}

export interface LoginFormProps {
  isLoadingOnboarding: boolean;
  onLoginSuccess: (response: LoginMutation['signIn']) => void;
}

export const LoginForm = ({ isLoadingOnboarding, onLoginSuccess }: LoginFormProps) => {
  const [serverError, setServerError] = useState<string | undefined>();
  const [login, { isLoading }] = useLoginMutation();
  const { t } = useTranslate('pages.login.loginForm');

  const handleLogin = async (fields: LoginFormData) => {
    try {
      setServerError(undefined);
      const response = await login(fields).unwrap();
      onLoginSuccess(response.signIn);
    } catch (e) {
      if (isNotAllowedError(e)) {
        setServerError(t('notAllowedError') as string);
      } else {
        setServerError(t('generalError') as string);
      }
    }
  };

  return (
    <LoginFormUI
      onSubmit={handleLogin}
      loading={isLoading || isLoadingOnboarding}
      serverError={serverError}
    />
  );
};
