import { Controller } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Button, Modal, Text } from '@goosechase/ui';

import { useScreenSize } from '../../hooks/use-screen-size';
import { useTranslate } from '../../util/i18n';
import {
  DeleteSubmissionFormOutput,
  REASON_MAX_CHARS,
  useDeleteSubmissionForm,
} from '../../hooks/use-delete-submission-form.hook';
import { LabelledTextArea } from '../labelled-text-area';
import { useDeleteSubmissionMutation } from '../../data/submissions';
import { displayToast } from '../../services/toast';

interface DeleteSubmissionModalProps {
  show: boolean;
  submissionId: string;
  missionName: string;
  onClose: (isDeleted: boolean) => void;
}

export const DeleteSubmissionModal = ({
  show,
  submissionId,
  missionName,
  onClose,
}: DeleteSubmissionModalProps) => {
  const { isTabletWide } = useScreenSize();
  const { t } = useTranslate('deleteSubmissionModal');

  const { control, handleSubmit, reset } = useDeleteSubmissionForm();
  const [deleteSubmission, { isLoading }] = useDeleteSubmissionMutation();

  const handleClose = (isDeleted = false) => {
    reset();
    onClose(isDeleted);
  };

  const handleDeleteSubmission = async (data: DeleteSubmissionFormOutput) => {
    try {
      await deleteSubmission({ params: { id: submissionId, reason: data.reason } }).unwrap();
      handleClose(true);

      displayToast({
        id: submissionId,
        title: t('toast.success.title'),
        body: t('toast.success.body'),
        type: 'success',
      });
    } catch (e) {
      displayToast({
        id: submissionId,
        title: t('toast.error.title'),
        body: t('toast.error.body'),
        type: 'error',
      });
    }
  };

  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[500px]"
      show={show}
      onClose={handleClose}>
      <Modal.Header title={t('header') ?? undefined} />
      <form onSubmit={handleSubmit(handleDeleteSubmission)}>
        <Modal.Body className="flex flex-col gap-4">
          <Text size={isTabletWide ? 'sm' : 'xs'}>
            <Trans>{t('body', { missionName })}</Trans>
          </Text>
          <Controller
            control={control}
            name="reason"
            render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
              <LabelledTextArea
                label={t('reason.label')}
                placeholder={t('reason.placeholder') ?? undefined}
                maxCharacters={REASON_MAX_CHARS}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={error?.message}
                disabled={isLoading}
                value={value}
                name={name}
              />
            )}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button label={t('delete')} disabled={isLoading} buttonType={'DESTRUCTIVE'} />
          <Button label={t('cancel')} type="button" onClick={() => handleClose(false)} outlined />
        </Modal.Footer>
      </form>
    </Modal>
  );
};
