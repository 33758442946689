import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { getExperienceSubPageTitleOptions } from '../utils';
import { PageHeadline } from '../../components/page-headline.component';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { BrandingForm } from './branding-form.component';

export const BrandingPage = () => {
  const { t } = useTranslate('pages.branding');
  const { data } = useExperienceInfo();
  const displayName = data?.experience?.displayName;

  return (
    <Page title={t('meta.title', getExperienceSubPageTitleOptions(displayName))}>
      <div className="flex flex-col gap-6">
        <PageHeadline>{t('title')}</PageHeadline>
        <BrandingForm />
      </div>
    </Page>
  );
};
