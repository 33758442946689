/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type LoadMyUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LoadMyUserQuery = { __typename: 'Query', myUser: { __typename: 'User', id: string, accountId: string, profile: { __typename: 'UserProfile', id: string, photoUrl: string | null, email: string } | null } };


export const LoadMyUserDocument = `
    query LoadMyUser {
  myUser {
    id
    accountId
    profile {
      id
      photoUrl
      email
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    LoadMyUser: build.query<LoadMyUserQuery, LoadMyUserQueryVariables | void>({
      query: (variables) => ({ document: LoadMyUserDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLoadMyUserQuery, useLazyLoadMyUserQuery } = injectedRtkApi;

