/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type AcceptExperienceInvitationMutationVariables = Types.Exact<{
  invitationId: Types.Scalars['ID']['input'];
}>;


export type AcceptExperienceInvitationMutation = { __typename: 'Mutation', acceptExperienceInvitation: { __typename: 'ExperienceInvitation', id: string, email: string, roleId: string, status: string, lastSentAt: string } };


export const AcceptExperienceInvitationDocument = `
    mutation AcceptExperienceInvitation($invitationId: ID!) {
  acceptExperienceInvitation(invitationId: $invitationId) {
    id
    email
    roleId
    status
    lastSentAt
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    AcceptExperienceInvitation: build.mutation<AcceptExperienceInvitationMutation, AcceptExperienceInvitationMutationVariables>({
      query: (variables) => ({ document: AcceptExperienceInvitationDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAcceptExperienceInvitationMutation } = injectedRtkApi;

