import { useExperienceListFilter } from '../data/experiences';
import { WorkspaceScope } from '../data/models';
import { useMyWorkspacesQuery } from '../data/workspaces';

export const useActiveWorkspace = () => {
  const { data: myWorkspacesData, isLoading: myWorkspacesIsLoading } = useMyWorkspacesQuery();

  const filter = useExperienceListFilter();

  if (myWorkspacesIsLoading) {
    return null;
  }

  const currentWorkspace = myWorkspacesData?.myWorkspaces.find((w) =>
    filter.type === 'organization'
      ? w.id === filter.id
      : // when the filter value is "shared", there's no current workspace
        filter.value === 'personal' && w.scope === WorkspaceScope.Personal,
  );

  return currentWorkspace;
};
