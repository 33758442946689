import { submissionsSlice } from './submissions.slice';
import {
  submissionsOverviewApi,
  missionSubmissionsApi,
  teamSubmissionsApi,
  deleteSubmissionApi,
  toggleLikeSubmissionApi,
  publicSubmissionApi,
  type SubmissionsOverviewQuery,
  type MissionSubmissionsQuery,
  type TeamSubmissionsQuery,
  addSubmissionBonusApi,
  deleteSubmissionBonusApi,
  submissionFeedApi,
  type SubmissionFeedQuery,
  type PublicSubmissionQuery,
  createSubmissionArchiveApi,
  submissionArchivesApi,
  submissionArchiveApi,
} from './submissions.api';

export const { useSubmissionsOverviewQuery } = submissionsOverviewApi;
export const { useMissionSubmissionsQuery } = missionSubmissionsApi;
export const { useTeamSubmissionsQuery } = teamSubmissionsApi;
export const { useDeleteSubmissionMutation } = deleteSubmissionApi;
export const { useToggleLikeSubmissionMutation } = toggleLikeSubmissionApi;
export const { usePublicSubmissionQuery } = publicSubmissionApi;
export const { useAddSubmissionBonusMutation } = addSubmissionBonusApi;
export const { useDeleteSubmissionBonusMutation } = deleteSubmissionBonusApi;
export const { useSubmissionFeedQuery } = submissionFeedApi;
export const { useCreateSubmissionArchiveMutation } = createSubmissionArchiveApi;
export const { useCohortSubmissionArchivesQuery } = submissionArchivesApi;
export const { useSubmissionArchiveQuery } = submissionArchiveApi;

export {
  SubmissionsOverviewQuery,
  MissionSubmissionsQuery,
  TeamSubmissionsQuery,
  SubmissionFeedQuery,
  PublicSubmissionQuery,
};

export const { setActiveMissionId, setActiveTeamId } = submissionsSlice.actions;

export const submissionsReducer = submissionsSlice.reducer;
