/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type AddMissionFromMissionLibraryMutationVariables = Types.Exact<{
  experienceId: Types.Scalars['ID']['input'];
  missionId: Types.Scalars['ID']['input'];
}>;


export type AddMissionFromMissionLibraryMutation = { __typename: 'Mutation', addMissionFromMissionLibrary: { __typename: 'CameraMission', id: string } | { __typename: 'GpsMission', id: string } | { __typename: 'TextMission', id: string } };


export const AddMissionFromMissionLibraryDocument = `
    mutation AddMissionFromMissionLibrary($experienceId: ID!, $missionId: ID!) {
  addMissionFromMissionLibrary(experienceId: $experienceId, missionId: $missionId) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    AddMissionFromMissionLibrary: build.mutation<AddMissionFromMissionLibraryMutation, AddMissionFromMissionLibraryMutationVariables>({
      query: (variables) => ({ document: AddMissionFromMissionLibraryDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddMissionFromMissionLibraryMutation } = injectedRtkApi;

