import { Button, Modal } from '@goosechase/ui';
import { closeAndResetTeamBonusHistoryModal, useTeamBonusModalOpen } from 'data/leaderboard';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'util/i18n';
import { TeamBonusHistory } from './team-bonus-history.component';

export type Team = {
  bonuses: {
    id: string;
    value: number;
    note: string | null;
    createdAt: string;
  }[];
};

export const TeamBonusHistoryModal = () => {
  const { t } = useTranslate('teamBonusHistory');
  const show = useTeamBonusModalOpen();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeAndResetTeamBonusHistoryModal());
  };

  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[580px] tablet-wide:w-[798px]"
      show={show}
      onClose={handleClose}>
      <Modal.Header title={t('title') ?? undefined} />
      <Modal.Body>
        <TeamBonusHistory />
      </Modal.Body>
      <Modal.Footer>
        <Button label={t('close')} onClick={handleClose} outlined />
      </Modal.Footer>
    </Modal>
  );
};
