import { Headline, IconButton, mergeClasses } from '@goosechase/ui';
import { format as formatDate, parseISO as parseDate } from 'date-fns';
import { Card } from 'components/card.component';
import { useActiveCohortId } from 'data/experiences';
import { useLeaderboardQuery } from 'data/leaderboard';
import { useTranslate } from 'util/i18n';
import { ErrorState } from 'components/error-state';
import { LoadingSpinner } from 'components/loading-spinner';
import { SortOrder } from 'data/models';
import { useEffect, useState } from 'react';
import type { LeaderboardQuery } from 'data/leaderboard';
import { DownloadLeaderboardTableButton } from './leaderboard-table-download-button.component';

type TableHeaderProps = {
  label: string;
  active: boolean;
  onClick: (direction: SortOrder) => void;
};

const TableHeader = (props: TableHeaderProps) => {
  const [direction, setDirection] = useState(SortOrder.Desc);

  const handleClick = () => {
    const newDirection = direction === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
    setDirection(newDirection);
    props.onClick(newDirection);
  };

  return (
    <th className="p-2" onClick={handleClick}>
      <div
        className={mergeClasses(
          'flex items-center cursor-pointer',
          props.active ? 'text-vibrantBlue' : 'text-black-64',
        )}>
        <IconButton
          icon="CaretDown"
          className="h-6 w-6 inline"
          iconColor={props.active ? 'vibrantBlue' : 'black'}
          iconClassName={mergeClasses({
            'rotate-180': direction === SortOrder.Asc,
          })}
          aria-label={props.label}
          iconSize={12}
        />
        {props.label}
      </div>
    </th>
  );
};

const makeSortLeaderboard =
  (sort: { field: string; direction: SortOrder }) =>
  // eslint-disable-next-line complexity
  (a: LeaderboardQuery['leaderboard'][number], b: LeaderboardQuery['leaderboard'][number]) => {
    if (sort.field === 'points') {
      if (sort.direction === SortOrder.Asc) {
        return a.team.points > b.team.points ? 1 : -1;
      }
      return b.team.points > a.team.points ? 1 : -1;
    }

    if (sort.field === 'team') {
      if (sort.direction === SortOrder.Asc) {
        return a.team.displayName.localeCompare(b.team.displayName);
      }
      return b.team.displayName.localeCompare(a.team.displayName);
    }

    if (sort.field === 'submissions') {
      if (sort.direction === SortOrder.Asc) {
        return a.team.numSubmissions > b.team.numSubmissions ? 1 : -1;
      }
      return b.team.numSubmissions > a.team.numSubmissions ? 1 : -1;
    }

    if (sort.field === 'lastSubmission') {
      if (sort.direction === SortOrder.Asc) {
        if (!a.team.lastSubmission) {
          return 1;
        }
        if (!b.team.lastSubmission) {
          return -1;
        }
        return +new Date(a.team.lastSubmission) > +new Date(b.team.lastSubmission) ? 1 : -1;
      }
      if (!a.team.lastSubmission) {
        return -1;
      }
      if (!b.team.lastSubmission) {
        return 1;
      }
      return +new Date(b.team.lastSubmission) > +new Date(a.team.lastSubmission) ? 1 : -1;
    }

    return 0;
  };

export const LeaderboardTable = () => {
  const { t } = useTranslate('pages.stats.leaderboard');
  const activeCohort = useActiveCohortId();
  const { data, isError, isLoading } = useLeaderboardQuery(
    { cohortId: activeCohort ?? '' },
    { skip: !activeCohort },
  );
  const [sort, setSort] = useState({ field: 'points', direction: SortOrder.Desc });
  const [leaderboard, setLeaderboard] = useState<LeaderboardQuery['leaderboard']>([]);

  const toggleSort = (field: string) => (direction: SortOrder) => {
    setSort({
      field,
      direction,
    });
  };

  useEffect(() => {
    if (data) {
      setLeaderboard([...data.leaderboard].sort(makeSortLeaderboard(sort)));
    }
  }, [sort, data]);

  if (isLoading) {
    return (
      <Card className="flex items-center justify-center p-10 col-span-4 space-y-2">
        <LoadingSpinner />
      </Card>
    );
  }

  if (isError || !data) {
    return (
      <Card className="text-black-64 p-10 col-span-4 space-y-2">
        <ErrorState />
      </Card>
    );
  }

  return (
    <Card className="text-black-64 p-6 col-span-4 space-y-2">
      <div className="flex justify-between">
        <Headline type="secondary" size="sm" className="font-bold">
          {t('label')}
        </Headline>
        <DownloadLeaderboardTableButton
          ariaLabel={t('ariaLabel')}
          data={data.leaderboard}
          headers={[
            t('table.team'),
            t('table.points'),
            t('table.submissions'),
            t('table.lastSubmission'),
          ]}
        />
      </div>
      <table className="table-auto w-full">
        <thead>
          <tr className="w-full border-b border-black-12 text-left">
            <TableHeader
              active={sort.field === 'team'}
              label={t('table.team')}
              onClick={toggleSort('team')}
            />
            <TableHeader
              active={sort.field === 'points'}
              label={t('table.points')}
              onClick={toggleSort('points')}
            />
            <TableHeader
              active={sort.field === 'submissions'}
              label={t('table.submissions')}
              onClick={toggleSort('submissions')}
            />
            <TableHeader
              active={sort.field === 'lastSubmission'}
              label={t('table.lastSubmission')}
              onClick={toggleSort('lastSubmission')}
            />
          </tr>
        </thead>
        <tbody>
          {leaderboard.map((entry) => (
            <tr key={entry.team.id} className="border-b border-black-12 text-left">
              <td className="p-2">{entry.team.displayName}</td>
              <td className="p-2">{entry.team.points}</td>
              <td className="p-2">{entry.team.numSubmissions}</td>
              <td className="p-2">
                {entry.team.lastSubmission
                  ? formatDate(parseDate(entry.team.lastSubmission), 'LLL d yyyy, h:mm aa')
                  : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};
