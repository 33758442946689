var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { mergeClasses } from '../util/merge-classes';
import { Text } from '../typography';
import { Icon } from '../icon';
var TOAST_CLASSES = {
    success: 'border-vibrantGreen',
    error: 'border-vibrantRed',
    warning: 'border-yellowOrange',
};
var TOAST_ICON = {
    success: 'Success',
    error: 'Fail',
    warning: 'Warning',
};
var TOAST_ICON_COLOR = {
    success: 'black',
    error: 'white',
    warning: 'black',
};
export var Toast = function (_a) {
    var _b = _a.type, type = _b === void 0 ? 'success' : _b, title = _a.title, body = _a.body, onClose = _a.onClose;
    return (_jsxs("div", __assign({ className: mergeClasses('shadow flex flex-row gap-2 items-start bg-white p-4 rounded flex flex-row rounded border-t-4 max-w-[320px]', TOAST_CLASSES[type]) }, { children: [_jsx(Icon, { className: "shrink-0", size: 20, name: TOAST_ICON[type], color: TOAST_ICON_COLOR[type] }), _jsxs("div", __assign({ className: "flex flex-col gap-2" }, { children: [_jsx(Text, __assign({ className: "font-bold", size: "sm" }, { children: title })), _jsx(Text, __assign({ size: "xs", className: "break-words max-w-[244px]" }, { children: body }))] })), _jsx("button", __assign({ onClick: onClose, "aria-label": "Dismiss" }, { children: _jsx(Icon, { className: "shrink-0", size: 8, name: "CloseBold" }) }))] })));
};
