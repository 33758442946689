import { Icon, Text, Tooltip, type IconName } from '@goosechase/ui';

export interface TagValue {
  label: string;
  icon?: IconName;
}

export const Tag = ({ label, icon }: TagValue) => (
  <div className="flex inline-block py-1 px-2.5 rounded rounded-l bg-offWhite gap-2 items-center">
    {icon && <Icon className="shrink-0" name={icon} size={14} />}
    <Text className="font-semibold uppercase" size="xs">
      {label.toUpperCase()}
    </Text>
  </div>
);

export interface TagValueWithTooltip extends TagValue {
  tooltip: React.ReactNode;
}

interface InformationTagsProps {
  title?: string;
  primaryValues: TagValue[];
  secondaryValues: TagValueWithTooltip[];
}

export const InformationTags = ({
  title,
  primaryValues,
  secondaryValues,
}: InformationTagsProps) => (
  <div className="flex flex-col mt-1 gap-2">
    {title && (
      <Text size="sm" className="font-semibold">
        {title}
      </Text>
    )}
    <div className="flex flex-wrap gap-2">
      {primaryValues.map(({ label, icon }) => (
        <Tag key={label} label={label} icon={icon} />
      ))}
    </div>
    <div className="flex flex-wrap gap-4">
      {secondaryValues.map(({ label, icon, tooltip }) => (
        <Tooltip key={label} id={label} body={tooltip} className="w-[220px]">
          <Tag label={label} icon={icon} />
        </Tooltip>
      ))}
    </div>
  </div>
);
