/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type LeaveExperienceAsManagerMutationVariables = Types.Exact<{
  experienceId: Types.Scalars['ID']['input'];
}>;


export type LeaveExperienceAsManagerMutation = { __typename: 'Mutation', leaveExperienceAsManager: { __typename: 'ExperienceManager', createdAt: string, updatedAt: string } };


export const LeaveExperienceAsManagerDocument = `
    mutation LeaveExperienceAsManager($experienceId: ID!) {
  leaveExperienceAsManager(experienceId: $experienceId) {
    createdAt
    updatedAt
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    LeaveExperienceAsManager: build.mutation<LeaveExperienceAsManagerMutation, LeaveExperienceAsManagerMutationVariables>({
      query: (variables) => ({ document: LeaveExperienceAsManagerDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLeaveExperienceAsManagerMutation } = injectedRtkApi;

