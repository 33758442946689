import {
  Broadcast as BroadcastSchema,
  BroadcastRecipientType,
  BroadcastTrigger as BroadcastTriggerSchema,
  BroadcastTriggerTiming,
  BroadcastTaskType,
  BroadcastType,
  BroadcastState,
  CameraMission,
  CohortState,
  ScheduleCohortParams,
  EducatorPosition,
  ExperienceAssetRole,
  ExperienceTaskType,
  ExperienceTrigger,
  ExperienceTriggerTiming,
  FeedVisibility,
  GpsMission,
  HostedCheckoutPage,
  MediaType,
  Mission,
  SchoolLevel,
  TeamMode,
  TextMission,
  WorkspacePlan,
  WorkspaceType,
  Team,
  TeamCreationMode,
  CameraSubmission,
  TextSubmission,
  GpsSubmission,
  SubmissionBonus,
  SubmissionAsset,
  SubmissionAssetRole,
  MissionOrder,
  TriggerTimeUnit,
  SubmissionArchiveGroupingInput,
  SubmissionArchiveState,
  SubmissionArchive,
  MissionTriggerTiming,
  TriggerTimeAnchor,
  TriggerTimeAnchorV1,
  MissionTaskType,
  MissionTrigger,
  MissionStatus,
  StartCohortParams,
  UpdateCohortEndParams,
  SubmissionInfoOrderBy,
  SortOrder,
  SubmissionFeedOrderBy,
  ShowUpgradeBanner,
  PublicCameraMission,
  PublicGpsMission,
  PublicTextMission,
  Experience,
  WorkspaceScope,
} from 'data/__generated__/graphql';
import { Nullable } from 'types/util';

export type {
  CameraMission,
  GpsMission,
  HostedCheckoutPage as ChargebeeHostedCheckoutPage,
  Mission,
  TextMission,
  WorkspacePlan,
  Team,
  CameraSubmission,
  TextSubmission,
  GpsSubmission,
  SubmissionBonus,
  SubmissionAsset,
  SubmissionArchive,
  ScheduleCohortParams,
  ExperienceTrigger,
  MissionTrigger,
  StartCohortParams,
  UpdateCohortEndParams,
  PublicCameraMission,
  PublicGpsMission,
  PublicTextMission,
  Experience,
};

export {
  BroadcastType,
  BroadcastState,
  BroadcastRecipientType,
  BroadcastTaskType,
  BroadcastTriggerTiming,
  EducatorPosition,
  ExperienceAssetRole,
  ExperienceTaskType,
  ExperienceTriggerTiming,
  FeedVisibility,
  MediaType,
  SchoolLevel,
  TeamMode,
  WorkspaceType,
  TeamCreationMode,
  SubmissionAssetRole,
  MissionOrder,
  TriggerTimeUnit,
  SubmissionArchiveGroupingInput,
  SubmissionArchiveState,
  MissionTriggerTiming,
  TriggerTimeAnchor,
  TriggerTimeAnchorV1,
  MissionTaskType,
  MissionStatus,
  SubmissionInfoOrderBy,
  SortOrder,
  SubmissionFeedOrderBy,
  ShowUpgradeBanner,
  WorkspaceScope,
};

export const MISSION_TYPES = ['CAMERA', 'TEXT', 'GPS'] as const;
export type MissionType = (typeof MISSION_TYPES)[number];
export const MISSION_STATUSES = [
  MissionStatus.Available,
  MissionStatus.Hidden,
  MissionStatus.Expired,
] as const;
export const FEED_VISIBILITIES = [
  FeedVisibility.AlwaysPublic,
  FeedVisibility.AlwaysPrivate,
  FeedVisibility.PublicAtExperienceEnd,
] as const;
export const MISSION_ORDER = [
  MissionOrder.Custom,
  MissionOrder.Name,
  MissionOrder.Points,
  MissionOrder.Random,
];

export const MEDIA_TYPES = [MediaType.PhotoAndVideo, MediaType.Photo, MediaType.Video] as const;
export const SUBMISSION_SOURCES = ['LIVE_AND_LIBRARY', 'LIVE_ONLY'] as const;
export type SubmissionSource = (typeof SUBMISSION_SOURCES)[number];

export type MissionUnion = CameraMission | TextMission | GpsMission;
export type MissionTypeName = MissionUnion['__typename'];
export type SubmissionUnion = CameraSubmission | TextSubmission | GpsSubmission;
export type SubmissionTypeName = SubmissionUnion['__typename'];

export { CohortState };

export type ExperienceActionType = 'delete' | 'duplicate';

export const EXPERIENCE_ASSET_ROLES = [
  ExperienceAssetRole.Photo,
  ExperienceAssetRole.SplashScreenPhoto,
] as const;

export const ASSET_MIME_TYPES = [
  'image/jpeg',
  'image/webp',
  'image/png',
  'image/gif',
  'video/webm',
  'video/ogg',
  'video/mp4',
  'video/mpeg',
  'video/x-msvideo',
  'video/quicktime',
] as const;
export type AssetMimeType = (typeof ASSET_MIME_TYPES)[number];

export const TEAM_MODES = [TeamMode.Team, TeamMode.Solo] as const;

export const TEAM_CREATION_MODES = [
  TeamCreationMode.All,
  TeamCreationMode.None,
  TeamCreationMode.Solo,
  TeamCreationMode.Team,
] as const;

export const TRIGGER_TIME_UNITS = [
  TriggerTimeUnit.Minutes,
  TriggerTimeUnit.Hours,
  TriggerTimeUnit.Days,
  TriggerTimeUnit.Weeks,
  TriggerTimeUnit.Months,
] as const;

export const MISSION_TRIGGER_TIMINGS = [
  MissionTriggerTiming.Relative,
  MissionTriggerTiming.Specific,
] as const;

export const MISSION_TASK_TYPES = [
  MissionTaskType.ExpireMission,
  MissionTaskType.HideMission,
  MissionTaskType.ReleaseMission,
] as const;

export const MISSION_TRIGGER_TIME_ANCHOR = [
  TriggerTimeAnchorV1.AfterStart,
  TriggerTimeAnchorV1.BeforeEnd,
] as const;

export const TRIGGER_TIME_ANCHOR = [
  TriggerTimeAnchor.BeforeStart,
  TriggerTimeAnchor.AtStart,
  TriggerTimeAnchor.AfterStart,
  TriggerTimeAnchor.BeforeEnd,
  TriggerTimeAnchor.AtEnd,
  TriggerTimeAnchor.AfterEnd,
] as const;

export const DURING_EXPERIENCE_TIME_ANCHORS = [
  TriggerTimeAnchor.AfterStart,
  TriggerTimeAnchor.BeforeEnd,
] as const;

export const MISSION_TRIGGER_TIME_UNITS = [
  TriggerTimeUnit.Minutes,
  TriggerTimeUnit.Hours,
  TriggerTimeUnit.Days,
] as const;

export const BROADCAST_TRIGGER_TIMINGS = [
  BroadcastTriggerTiming.Relative,
  BroadcastTriggerTiming.Specific,
] as const;

export const BROADCAST_TRIGGER_TIME_UNITS = [
  TriggerTimeUnit.Minutes,
  TriggerTimeUnit.Hours,
  TriggerTimeUnit.Days,
] as const;

export type BroadcastTriggerTimingOptions = (typeof BROADCAST_TRIGGER_TIMINGS)[number];

export const BROADCAST_TYPES = [BroadcastType.Immediate, BroadcastType.Scheduled] as const;

export const BROADCAST_STATES = [
  BroadcastState.Scheduled,
  BroadcastState.Sent,
  BroadcastState.Invalid,
] as const;

export interface BroadcastRecipient {
  team: {
    id: string;
    displayName: string;
    photoUrl: Nullable<string>;
    mode: TeamMode;
  };
}

export interface Broadcast {
  id: BroadcastSchema['id'];
  cohortId: BroadcastSchema['cohortId'];
  message: BroadcastSchema['message'];
  type: BroadcastSchema['type'];
  recipientType: BroadcastSchema['recipientType'];
  state: BroadcastSchema['state'];
  recipients: BroadcastRecipient[];
  trigger: Nullable<BroadcastTrigger>;
  updatedAt: BroadcastSchema['updatedAt'];
  triggerAt: Nullable<Date>;
  isValid: Nullable<boolean>;
  errorMessage: Nullable<string>;
}

export interface BroadcastTrigger {
  timing: BroadcastTriggerSchema['timing'];
  relativeDuration: BroadcastTriggerSchema['relativeDuration'];
  relativeUnit: BroadcastTriggerSchema['relativeUnit'];
  relativeAnchor: BroadcastTriggerSchema['relativeAnchor'];
  specificDay: BroadcastTriggerSchema['specificDay'];
  specificTime: BroadcastTriggerSchema['specificTime'];
  createdAt: BroadcastTriggerSchema['createdAt'];
  updatedAt: BroadcastTriggerSchema['updatedAt'];
}

export enum BroadcastSendOption {
  Now = 'NOW',
  BeforeExperience = 'BEFORE_EXPERIENCE',
  AtStartOfExperience = 'AT_START_OF_EXPERIENCE',
  DuringExperience = 'DURING_EXPERIENCE',
  AtEndOfExperience = 'AT_END_OF_EXPERIENCE',
  AfterExperience = 'AFTER_EXPERIENCE',
}

export const BROADCAST_SEND_OPTIONS = [
  BroadcastSendOption.Now,
  BroadcastSendOption.BeforeExperience,
  BroadcastSendOption.AtStartOfExperience,
  BroadcastSendOption.DuringExperience,
  BroadcastSendOption.AtEndOfExperience,
  BroadcastSendOption.AfterExperience,
] as const;
