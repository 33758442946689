var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var DeleteBold = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M3 6H5H21", stroke: "inherit", strokeWidth: "2.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z", stroke: "inherit", strokeWidth: "2.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M10 11V17", stroke: "inherit", strokeWidth: "2.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M14 11V17", stroke: "inherit", strokeWidth: "2.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
