import { Dropdown, IconName } from '@goosechase/ui';
import type { TFunction } from 'i18next';

import { FeedVisibility, FEED_VISIBILITIES } from '../../data/models';
import { useTranslate } from '../../util/i18n';
import { DropdownOptionType, renderSelectedLeftIcon } from './dropdown-option.component';
import { LabelledSetting } from '../labelled-setting.component';

const SUPPORTED_FEED_VISIBILITIES = FEED_VISIBILITIES.filter(
  (feedVisibility) => feedVisibility !== 'PUBLIC_AT_EXPERIENCE_END',
);

const FEED_VISIBILITY_TO_TRANSLATION_KEY_MAP: Record<FeedVisibility, string> = {
  ALWAYS_PUBLIC: 'alwaysPublic',
  ALWAYS_PRIVATE: 'alwaysPrivate',
  PUBLIC_AT_EXPERIENCE_END: 'publicAtExperienceEnd',
};

const FEED_VISIBILITY_TO_ICON_MAP: Record<FeedVisibility, IconName> = {
  ALWAYS_PUBLIC: 'ShownEye',
  ALWAYS_PRIVATE: 'HiddenEye',
  PUBLIC_AT_EXPERIENCE_END: 'ShownEye',
};

const convertFeedVisibilityToDropdownOption = (
  t: TFunction,
  feedVisibility: FeedVisibility,
): DropdownOptionType => ({
  id: feedVisibility,
  icon: FEED_VISIBILITY_TO_ICON_MAP[feedVisibility],
  title: t(`${FEED_VISIBILITY_TO_TRANSLATION_KEY_MAP[feedVisibility]}.title`),
  description: t(`${FEED_VISIBILITY_TO_TRANSLATION_KEY_MAP[feedVisibility]}.description`),
});

interface FeedVisibilityDropdownProps {
  value: FeedVisibility;
  onChange: (value: FeedVisibility) => void;
}

export const FeedVisibilityDropdown = ({ value, onChange }: FeedVisibilityDropdownProps) => {
  const { t } = useTranslate('missionForm.feedVisibility');

  const options = SUPPORTED_FEED_VISIBILITIES.map((feedVisibility) =>
    convertFeedVisibilityToDropdownOption(t, feedVisibility),
  );

  const selectedDropdownOption = convertFeedVisibilityToDropdownOption(t, value);

  return (
    <LabelledSetting label={t('title')} description={t('description')}>
      <Dropdown
        buttonClassName="w-full"
        label={selectedDropdownOption.title}
        options={options}
        selectedOptionId={value}
        renderLeft={renderSelectedLeftIcon(selectedDropdownOption)}
        onSelect={(val: string) => onChange(val as FeedVisibility)}
      />
    </LabelledSetting>
  );
};
