import { useState } from 'react';
import { Label, mergeClasses, Text, TextField } from '@goosechase/ui';
import { CharacterCount } from './character-count';

export type FormTextFieldProps = {
  className?: string;
  label: string;
  hint?: string;
  errorMessage?: string;
  maxCharacters?: number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  inputRef?: React.ForwardedRef<HTMLInputElement>;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const LabelledField = ({
  className,
  label,
  hint,
  errorMessage,
  maxCharacters,
  onChange,
  inputRef,
  ...htmlProps
}: FormTextFieldProps) => {
  const [count, setCount] = useState(
    htmlProps.defaultValue ? htmlProps.defaultValue.toString().length : 0,
  );

  const handleCharacterCountUpdate =
    (onChangeHandler?: React.ChangeEventHandler<HTMLInputElement>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCount(e.target.value.length);
      onChangeHandler?.(e);
    };

  return (
    <div className={mergeClasses('flex flex-col content-start', className)}>
      <Label size="sm" className="block mb-2" htmlFor={htmlProps.name}>
        {label.toUpperCase()}
      </Label>
      <TextField
        aria-label={label}
        onChange={maxCharacters ? handleCharacterCountUpdate(onChange) : onChange}
        responseState={errorMessage ? 'error' : undefined}
        inputRef={inputRef}
        {...htmlProps}
      />
      {(errorMessage || maxCharacters) && (
        <div className="flex w-full justify-between mt-2">
          <Text size="xs" className="text-vibrantRed">
            {errorMessage ?? ''}
          </Text>
          {maxCharacters ? (
            <CharacterCount
              maxCharacters={maxCharacters}
              count={count}
              isError={Boolean(errorMessage)}
            />
          ) : (
            ''
          )}
        </div>
      )}
      {hint ? (
        <div className="flex w-full justify-between mt-3">
          <Text className="text-black-64" size="xs">
            {hint}
          </Text>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
