import { mergeClasses, Icon } from '@goosechase/ui';
import { useSortable } from '@dnd-kit/sortable';
import { useTranslate } from 'util/i18n';

interface DragHandleProps {
  id: string;
  isDragging: boolean;
  dragEnabled: boolean;
}

export const DragHandle = ({ id, isDragging, dragEnabled }: DragHandleProps) => {
  const { t } = useTranslate('pages.missions.missionList');
  const { attributes, listeners } = useSortable({
    id,
  });
  return (
    <div
      className={mergeClasses(
        'flex desktop:opacity-0 desktop:focus-visible:opacity-100 desktop:group-hover:opacity-100 items-center absolute top-0 left-0 h-full hover:cursor-grab focus-visible:cursor-grab active:cursor-grabbing select-none',
        { 'desktop:flex desktop:opacity-100 outline-none': isDragging },
        { invisible: !dragEnabled },
      )}
      {...listeners}
      {...attributes}
      aria-label={t('ariaDragHandleLabel') ?? 'Drag'}>
      <Icon name="Grab" color="black-64" />
    </div>
  );
};
