import { Nullable } from 'types/util';
import { calculateTrigger, getTriggerDate, isBroadcastTriggerValid } from 'util/time-util';
import {
  Broadcast,
  BroadcastState,
  BroadcastTriggerTiming,
  BroadcastType,
  TriggerTimeAnchor,
} from '../../data/models';
import type { TFunction } from 'i18next';

export const filterBroadcastByState =
  (selectedState: string) =>
  ({ state }: Pick<Broadcast, 'state'>) => {
    if (selectedState === 'all') {
      return true;
    } else if (selectedState === state.toLowerCase()) {
      return true;
    }
  };

export const filterBroadcastBySearchValue =
  (searchValue: string) =>
  ({ message }: Pick<Broadcast, 'message'>) => {
    const caseInsensitiveSearchValue = searchValue.toLowerCase();

    return message.toLowerCase().includes(caseInsensitiveSearchValue);
  };

interface CalculateTriggerAtForBroadcastsParams {
  broadcasts: Omit<Broadcast, 'triggerAt' | 'isValid' | 'errorMessage'>[];
  cohortStartDate: Nullable<string>;
  cohortEndDate: Nullable<string>;
  experienceTimezone: string;
  t: TFunction;
}

export const calculateTriggerAtForBroadcasts = ({
  broadcasts,
  cohortStartDate,
  cohortEndDate,
  experienceTimezone,
  t,
}: CalculateTriggerAtForBroadcastsParams): Broadcast[] => {
  return broadcasts.map((b) => {
    if (b.trigger && cohortStartDate && cohortEndDate) {
      const triggerAt = calculateTrigger({
        triggerDate: getTriggerDate(
          cohortStartDate,
          cohortEndDate,
          b.trigger.relativeAnchor ?? undefined,
        ),
        relativeAnchor: b.trigger.relativeAnchor,
        relativeDuration: b.trigger.relativeDuration,
        relativeUnit: b.trigger.relativeUnit,
        timing: b.trigger.timing,
        specificDay: b.trigger.specificDay,
        specificTime: b.trigger.specificTime,
        experienceTimezone,
      });

      const isValid = b.state === BroadcastState.Scheduled || b.state === BroadcastState.Sent;
      let message = '';
      if (!isValid && b.trigger) {
        if (b.trigger.timing === BroadcastTriggerTiming.Specific) {
          message = t('list.warnings.outside');
        } else {
          if (
            b.trigger.relativeAnchor === TriggerTimeAnchor.BeforeEnd ||
            b.trigger.relativeAnchor === TriggerTimeAnchor.AfterStart
          ) {
            if (
              isBroadcastTriggerValid({
                trigger: b.trigger,
                startDate: cohortStartDate,
                endDate: cohortEndDate,
                experienceTimezone,
              })
            ) {
              message = t('list.warnings.past');
            } else {
              message = t('list.warnings.outside');
            }
          } else {
            message = t('list.warnings.past');
          }
        }
      }
      return {
        ...b,
        triggerAt: triggerAt ?? null,
        isValid: isValid,
        errorMessage: message,
      };
    } else {
      // no broadcast trigger but type is scheduled
      if (!b.trigger && b.type === BroadcastType.Scheduled) {
        return {
          ...b,
          triggerAt: null,
          isValid: false,
          errorMessage: t('list.warnings.missingTrigger'),
        };
      }

      return {
        ...b,
        triggerAt: null,
        isValid: true,
        errorMessage: null,
      };
    }
  });
};

export const orderBroadcastsByScheduledSendTime = (broadcasts: Broadcast[]): Broadcast[] => {
  return broadcasts.sort((a, b) => {
    if (a.state !== b.state) {
      // all sent items are less than unsent scheduled ones
      return a.state === 'SENT' ? 1 : -1;
    } else {
      if (a.state === 'SENT' || !a.trigger || !b.trigger) {
        // sent items are sorted by their last state change
        if (a.updatedAt === b.updatedAt) {
          return 0;
        } else {
          return a.updatedAt < b.updatedAt ? 1 : -1;
        }
      } else {
        // scheduled items are sorted by their anticipated send date
        if (a.triggerAt === b.triggerAt) {
          return 0;
        } else if (!a.triggerAt || !b.triggerAt) {
          return 0;
        } else {
          // TODO: improve sorting based on anticipated send date even if trigger dates aren't known (no start/end time)
          // should still be able to determine eg. 5 minutes before is earlier than 3 minutes before
          return a.triggerAt < b.triggerAt ? 1 : -1;
        }
      }
    }
  });
};
