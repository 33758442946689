import { Button, Text, TextAreaField } from '@goosechase/ui';
import { NumberInput } from 'components/number-input';
import { BonusPointsPill } from 'components/points-pill';
import { TeamBonusFormInputData, useTeamBonusForm } from 'components/team-bonus-form';
import { format } from 'date-fns';
import { ArrayElement } from 'global';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslate } from 'util/i18n';
import { TeamBonusActions } from './team-bonus-actions';
import { Team } from './team-bonus-history-modal.component';

interface TeamBonusItemProps {
  bonus: ArrayElement<Team['bonuses']>;
  onEdit: (params: Extract<TeamBonusFormInputData, { bonusId: string }>) => void;
  onDelete: (id: string) => void;
}

export const TeamBonusItem = ({ bonus, onEdit, onDelete }: TeamBonusItemProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { t } = useTranslate('teamBonusHistory');
  const { control, handleSubmit, formState, reset } = useTeamBonusForm({
    bonusId: bonus.id,
    note: bonus.note ?? '',
    value: String(bonus.value),
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
      setIsEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isSubmitSuccessful]);

  const errorMessage = Object.values(formState.errors)[0]?.message;

  return (
    <>
      {errorMessage ? (
        <Text size="xs" className="text-vibrantRed ml-2">
          {errorMessage ?? ''}
        </Text>
      ) : null}
      <tr className="border-b border-black-12">
        <td className="w-max text-left p-3">
          {isEditing ? (
            <Controller
              control={control}
              name="value"
              render={({ field: { onChange, onBlur, name, value }, fieldState }) => (
                <NumberInput
                  label=""
                  placeholder={t('form.value.placeholder') ?? undefined}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={Boolean(fieldState.error?.message)}
                  hideSpinButtons
                  value={value}
                  className="w-full"
                />
              )}
            />
          ) : (
            <BonusPointsPill points={bonus.value} />
          )}
        </td>
        <td className="w-full text-left p-3">
          {isEditing ? (
            <Controller
              control={control}
              name="note"
              render={({ field: { onChange, onBlur, name, value }, fieldState }) => (
                <TextAreaField
                  name={name}
                  placeholder={t('form.note.placeholder') ?? undefined}
                  onChange={onChange}
                  onBlur={onBlur}
                  responseState={fieldState.error?.message ? 'error' : undefined}
                  value={value}
                  rows={1}
                />
              )}
            />
          ) : (
            <Text>{bonus.note}</Text>
          )}
        </td>
        <td className="text-left whitespace-nowrap p-3">
          {format(new Date(bonus.createdAt), t('dateFormat'))}
        </td>
        <td className="p-3 relative">
          {isEditing ? (
            <div className="flex gap-1">
              <Button
                label={t('form.cancel')}
                size="sm"
                outlined
                onClick={() => setIsEditing(false)}
              />
              <Button label={t('form.submit')} size="sm" onClick={handleSubmit(onEdit)} />
            </div>
          ) : (
            <TeamBonusActions
              handleEdit={() => setIsEditing(true)}
              handleDelete={() => onDelete(bonus.id)}
            />
          )}
        </td>
      </tr>
    </>
  );
};
