var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Account = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M20.0002 20.9999V17.9999C20.0002 16.939 19.5787 15.9216 18.8286 15.1714C18.0784 14.4213 17.061 13.9998 16.0001 13.9998H8.00004C6.93916 13.9998 5.92174 14.4213 5.17158 15.1714C4.42143 15.9216 4 16.939 4 17.9999V20.9999", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M12.002 11.0002C14.2112 11.0002 16.002 9.20929 16.002 7.00013C16.002 4.79097 14.2112 3.00009 12.002 3.00009C9.79283 3.00009 8.00195 4.79097 8.00195 7.00013C8.00195 9.20929 9.79283 11.0002 12.002 11.0002Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
