import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';

import {
  api as _submissionsOverviewApi,
  SubmissionsOverviewQuery,
} from './graphql/__generated__/submissions-overview.query.generated';
import {
  api as _missionSubmissionsApi,
  MissionSubmissionsQuery,
} from './graphql/__generated__/mission-submissions.query.generated';
import {
  api as _teamSubmissionsApi,
  TeamSubmissionsQuery,
} from './graphql/__generated__/team-submissions.query.generated';
import { api as _deleteSubmissionApi } from './graphql/__generated__/delete-submission.mutation.generated';
import { api as _toggleLikeSubmissionApi } from './graphql/__generated__/toggle-like-submission.mutation.generated';
import {
  api as _publicSubmissionApi,
  PublicSubmissionQuery,
} from './graphql/__generated__/public-submission.query.generated';
import { AppState } from '../store';
import { api as _addSubmissionBonusApi } from './graphql/__generated__/add-submission-bonus.mutation.generated';
import { api as _deleteSubmissionBonusApi } from './graphql/__generated__/delete-submission-bonus.mutation.generated';
import { Nullable } from 'types/util';
import {
  api as _submissionFeedApi,
  SubmissionFeedQuery,
} from './graphql/__generated__/submissions-feed.query.generated';
import { api as _createSubmissionArchiveApi } from './graphql/__generated__/create-submission-archive.mutation.generated';
import { api as _submissionArchivesApi } from './graphql/__generated__/submission-archives.query.generated';
import {
  api as _submissionArchiveApi,
  SubmissionArchiveQuery,
} from './graphql/__generated__/submission-archive.query.generated';

export {
  type SubmissionsOverviewQuery,
  type MissionSubmissionsQuery,
  type TeamSubmissionsQuery,
  type SubmissionFeedQuery,
  type PublicSubmissionQuery,
  type SubmissionArchiveQuery,
};

export const submissionsOverviewApi = _submissionsOverviewApi.enhanceEndpoints({
  endpoints: {
    SubmissionsOverview: {
      providesTags: ['SubmissionsOverview'],
    },
  },
});

export const missionSubmissionsApi = _missionSubmissionsApi.enhanceEndpoints({
  endpoints: {
    MissionSubmissions: {
      providesTags: ['MissionSubmissions'],
    },
  },
});

export const teamSubmissionsApi = _teamSubmissionsApi.enhanceEndpoints({
  endpoints: {
    TeamSubmissions: {
      providesTags: ['TeamSubmissions'],
    },
  },
});

export const deleteSubmissionApi = _deleteSubmissionApi.enhanceEndpoints({
  endpoints: {
    DeleteSubmission: {
      invalidatesTags: [
        'SubmissionsOverview',
        'MissionSubmissions',
        'TeamSubmissions',
        'SubmissionFeed',
        'Statistics',
        'Leaderboard',
      ],
    },
  },
});

interface ToggleLikeSubmissionFields {
  id: string;
  liked: boolean;
  numLikes: number;
}

const updateToggleLikeSubmissions =
  (submissionIdToUpdate: string) =>
  <T extends ToggleLikeSubmissionFields>(submissions: Array<T>) =>
    submissions.map((submission) => {
      if (submission.id === submissionIdToUpdate) {
        return {
          ...submission,
          liked: !submission.liked,
          numLikes: submission.liked ? submission.numLikes - 1 : submission.numLikes + 1,
        };
      } else {
        return submission;
      }
    });

export const toggleLikeSubmissionApi = _toggleLikeSubmissionApi.enhanceEndpoints({
  endpoints: {
    ToggleLikeSubmission: {
      invalidatesTags: ['MissionSubmissions', 'TeamSubmissions', 'SubmissionFeed'],
      async onQueryStarted({ id: submissionId }, { dispatch, getState, queryFulfilled }) {
        const activeCohortId = (getState() as AppState).experiences.activeCohortId;
        const activeMissionId = (getState() as AppState).submissions.activeMissionId;
        const activeTeamId = (getState() as AppState).submissions.activeTeamId;

        const patchCollection: PatchCollection[] = [];

        if (activeCohortId && activeMissionId) {
          const patchResult = dispatch(
            missionSubmissionsApi.util.updateQueryData(
              'MissionSubmissions',
              { missionId: activeMissionId, cohortId: activeCohortId },
              (draft) => {
                if (draft?.mission) {
                  return {
                    ...draft,
                    mission: {
                      ...draft.mission,
                      submissions: [
                        ...updateToggleLikeSubmissions(submissionId)(draft.mission.submissions),
                      ],
                    },
                  };
                }
                return draft;
              },
            ),
          );
          patchCollection.push(patchResult);
        }

        if (activeTeamId) {
          const patchResult = dispatch(
            teamSubmissionsApi.util.updateQueryData(
              'TeamSubmissions',
              { teamId: activeTeamId },
              (draft) => {
                if (draft?.team) {
                  return {
                    ...draft,
                    team: {
                      ...draft.team,
                      submissions: [
                        ...updateToggleLikeSubmissions(submissionId)(draft.team.submissions),
                      ],
                    },
                  };
                }
                return draft;
              },
            ),
          );
          patchCollection.push(patchResult);
        }

        try {
          await queryFulfilled;
        } catch {
          patchCollection.forEach((patch) => patch.undo());
        }
      },
    },
  },
});

export const publicSubmissionApi = _publicSubmissionApi.enhanceEndpoints({
  endpoints: {
    PublicSubmission: {
      providesTags: ['PublicSubmission'],
    },
  },
});

interface SubmissionBonusFields {
  id: string;
  bonuses: {
    [key: string]: unknown;
  }[];
}

const updateSubmissionWithNewBonus =
  (submissionId: string, value: number, note?: Nullable<string>) =>
  <T extends SubmissionBonusFields>(submissions: Array<T>) => {
    return submissions.map((submission) => {
      if (submission.id === submissionId) {
        return {
          ...submission,
          bonuses: [
            ...submission.bonuses,
            {
              __typename: 'SubmissionBonus' as const,
              value,
              note: note ?? null,
              createdAt: new Date().toISOString(),
            },
          ],
        };
      }
      return submission;
    });
  };

export const addSubmissionBonusApi = _addSubmissionBonusApi.enhanceEndpoints({
  endpoints: {
    AddSubmissionBonus: {
      invalidatesTags: [
        'SubmissionsOverview',
        'MissionSubmissions',
        'TeamSubmissions',
        'SubmissionFeed',
        'Leaderboard',
      ],
      async onQueryStarted(
        { params: { submissionId, value, note } },
        { dispatch, getState, queryFulfilled },
      ) {
        const activeCohortId = (getState() as AppState).experiences.activeCohortId;
        const activeMissionId = (getState() as AppState).submissions.activeMissionId;
        const activeTeamId = (getState() as AppState).submissions.activeTeamId;

        const patchCollection: PatchCollection[] = [];

        if (activeCohortId && activeMissionId) {
          const patchResult = dispatch(
            missionSubmissionsApi.util.updateQueryData(
              'MissionSubmissions',
              { missionId: activeMissionId, cohortId: activeCohortId },
              (draft) => {
                if (draft?.mission) {
                  return {
                    ...draft,
                    mission: {
                      ...draft.mission,
                      submissions: updateSubmissionWithNewBonus(
                        submissionId,
                        value,
                        note,
                      )(draft.mission.submissions),
                    },
                  };
                }
                return draft;
              },
            ),
          );
          patchCollection.push(patchResult);
        }

        if (activeTeamId) {
          const patchResult = dispatch(
            teamSubmissionsApi.util.updateQueryData(
              'TeamSubmissions',
              { teamId: activeTeamId },
              (draft) => {
                if (draft?.team) {
                  return {
                    ...draft,
                    team: {
                      ...draft.team,
                      submissions: updateSubmissionWithNewBonus(
                        submissionId,
                        value,
                        note,
                      )(draft.team.submissions),
                    },
                  };
                }
                return draft;
              },
            ),
          );
          patchCollection.push(patchResult);
        }

        try {
          await queryFulfilled;
        } catch {
          patchCollection.forEach((patch) => patch.undo());
        }
      },
    },
  },
});

export const deleteSubmissionBonusApi = _deleteSubmissionBonusApi.enhanceEndpoints({
  endpoints: {
    DeleteSubmissionBonus: {
      invalidatesTags: [
        'SubmissionsOverview',
        'MissionSubmissions',
        'TeamSubmissions',
        'SubmissionFeed',
        'Leaderboard',
      ],
    },
  },
});

export const submissionFeedApi = _submissionFeedApi.enhanceEndpoints({
  endpoints: {
    SubmissionFeed: {
      providesTags: ['SubmissionFeed'],
    },
  },
});

export const submissionArchivesApi = _submissionArchivesApi.enhanceEndpoints({
  endpoints: {
    CohortSubmissionArchives: {
      providesTags: ['SubmissionArchives'],
    },
  },
});

export const submissionArchiveApi = _submissionArchiveApi;

export const createSubmissionArchiveApi = _createSubmissionArchiveApi.enhanceEndpoints({
  endpoints: {
    CreateSubmissionArchive: {
      invalidatesTags: ['SubmissionArchives'],
    },
  },
});
