import { Text } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { PageHeadline } from '../../components/page-headline.component';
import { SubmissionsOverviewQuery } from '../../data/submissions';
import { SubmissionGroup } from './constants';
import { useSubmissionsOverview } from './hooks';

interface OverviewCounts {
  numMissions: number;
  numTeams: number;
  numSubmissions: number;
}
const getOverviewCounts = (data?: SubmissionsOverviewQuery): OverviewCounts => ({
  numMissions: data?.experience?.numMissions ?? 0,
  numTeams: data?.cohort?.preview?.numberOfTeams ?? 0,
  numSubmissions: data?.cohort?.numSubmissions ?? 0,
});

interface SubmissionsOverviewHeadlineProps {
  submissionGroup: SubmissionGroup;
}

export const SubmissionsOverviewHeadline = ({
  submissionGroup,
}: SubmissionsOverviewHeadlineProps) => {
  const { t } = useTranslate('pages.submissions');
  const { data, isLoading, isUninitialized } = useSubmissionsOverview();

  const { numMissions, numSubmissions, numTeams } = getOverviewCounts(data);

  return (
    <div className="flex grow flex-col basis-1/3 gap-1">
      <PageHeadline>{t('title')}</PageHeadline>
      {!(isLoading || isUninitialized) && (
        <div className="flex flex-row gap-2 text-black">
          <Text size="md">
            {t(`overviewCount.${submissionGroup === 'mission' ? 'totalMissions' : 'totalTeams'}`, {
              count: submissionGroup === 'mission' ? numMissions : numTeams,
            })}
          </Text>
          <Text>
            {t('overviewCount.totalSubmissions', {
              count: numSubmissions,
            })}
          </Text>
        </div>
      )}
    </div>
  );
};
