import { useState } from 'react';
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form';
import { Label, TextButton, mergeClasses } from '@goosechase/ui';

import { useTranslate } from '../../util/i18n';
import { LabelledField } from '../labelled-field.component';
import { LabelledTextArea } from '../labelled-text-area';
import { DESCRIPTION_MAX_CHARS, DISPLAY_NAME_MAX_CHARS } from './constants';
import type { MissionFormInputData } from './use-mission-form';
import { PointsInput } from './points-input.component';
import { MissionType } from '../../data/models';
import { TextAnswersInput } from './text-answers-input.component';
import { LinkInput } from './link-input.component';
import { ApproximateAnswersInput } from './approximate-answers-input.component';
import { answersAreEligibleForApproximation } from '../../util/mission-util';
import { MissionTypeDropdown } from './mission-type-dropdown.component';
import { GpsLocationInput } from './gps-location-input';
import { MissionImageUploadUI } from './mission-image-upload-ui.component';
import { PhotoUploadField, PhotoUploadUIProps } from '../../components/photo-upload';

interface MissionContentProps {
  control: Control<MissionFormInputData>;
  isLoading: boolean;
  missionType: MissionType;
  setFormValue: UseFormSetValue<MissionFormInputData>;
  missionImage?: string;
}

export const MissionContent = ({
  control,
  isLoading,
  missionType,
  setFormValue,
  missionImage,
}: MissionContentProps) => {
  const { t } = useTranslate('missionForm');

  const link = useWatch({
    control,
    name: 'link',
  });
  const imageAssetId = useWatch({
    control,
    name: 'imageAssetId',
  });

  const [uploadingMissionImage, setUploadingMissionImage] = useState(false);
  const [showLink, setShowLink] = useState(link !== '');

  return (
    <div className="flex flex-col grow min-w-0 gap-4">
      <Controller
        control={control}
        name="displayName"
        render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
          <LabelledField
            label={t('displayName.label')}
            placeholder={t('displayName.placeholder') ?? undefined}
            type="text"
            maxCharacters={DISPLAY_NAME_MAX_CHARS}
            errorMessage={error?.message}
            disabled={isLoading}
            value={value}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />

      <div className="flex flex-row gap-4">
        <Controller
          control={control}
          name="missionType"
          render={({ field: { value, onChange, name } }) => (
            <div className="flex flex-1 flex-col content-start">
              <Label size="sm" className="block mb-2" htmlFor={name}>
                {t('missionType.label').toUpperCase()}
              </Label>
              <MissionTypeDropdown selectedMissionType={value as MissionType} onSelect={onChange} />
            </div>
          )}
        />
        <Controller
          control={control}
          name="points"
          render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
            <div className="flex-1">
              <PointsInput
                value={value}
                errorMessage={error?.message}
                onBlur={onBlur}
                onChange={onChange}
                disabled={isLoading}
              />
            </div>
          )}
        />
      </div>
      <Controller
        control={control}
        name="description"
        render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
          <LabelledTextArea
            label={t('description.label')}
            placeholder={t('description.placeholder') ?? undefined}
            maxCharacters={DESCRIPTION_MAX_CHARS}
            onChange={onChange}
            onBlur={onBlur}
            errorMessage={error?.message}
            disabled={isLoading}
            value={value}
            name={name}
          />
        )}
      />

      {missionType === 'TEXT' && (
        <>
          <Controller
            control={control}
            name="answers"
            render={({ field: { value: answersValue } }) => (
              <>
                <TextAnswersInput values={answersValue} setFormValue={setFormValue} />
                {/* Only render approximation input if the answers array is eligible */}
                {answersAreEligibleForApproximation(answersValue) && (
                  <Controller
                    control={control}
                    name="allowApproximateAnswers"
                    render={({ field: { value, onChange } }) => (
                      <ApproximateAnswersInput value={value} onChange={onChange} />
                    )}
                  />
                )}
              </>
            )}
          />
        </>
      )}

      {missionType === 'GPS' && <GpsLocationInput control={control} />}

      {/* Divider */}
      <div className="w-full h-px bg-black-24" />

      <div
        className={mergeClasses('w-full flex gap-6 items-start', {
          'flex-col':
            (missionImage && imageAssetId !== '') ||
            imageAssetId ||
            uploadingMissionImage ||
            showLink,
        })}>
        <Controller
          control={control}
          name="imageAssetId"
          render={({ field: { value }, fieldState: { error } }) => (
            <>
              <PhotoUploadField
                label={t('photoId.label')}
                setValue={(assetId: string) => {
                  setFormValue('imageAssetId', assetId, { shouldDirty: true });
                }}
                setError={() => {}}
                errorMessage={error?.message}
                defaultSrc={missionImage}
                setUploading={setUploadingMissionImage}
                uploading={uploadingMissionImage}
                photoUploadUI={(props: PhotoUploadUIProps) =>
                  (missionImage && value !== '') || value || uploadingMissionImage ? (
                    <MissionImageUploadUI {...props} control={control} />
                  ) : (
                    <TextButton
                      type="button"
                      className="px-0"
                      label={t('addImage')}
                      onClick={props.onClick}
                    />
                  )
                }
              />
            </>
          )}
        />

        {showLink ? (
          <Controller
            control={control}
            name="link"
            render={({ field: { value, onChange } }) => (
              <LinkInput
                value={value}
                onChange={onChange}
                setShowLink={setShowLink}
                setFormValue={setFormValue}
              />
            )}
          />
        ) : (
          <TextButton
            type="button"
            className="px-0"
            label={t('addLink')}
            onClick={() => setShowLink(true)}
          />
        )}
      </div>
    </div>
  );
};
