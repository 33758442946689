import { Checkbox, Label, Text } from '@goosechase/ui';

import { useTranslate } from '../../util/i18n';

interface ApproximateAnswersInputProps {
  value: boolean;
  onChange: () => void;
}

export const ApproximateAnswersInput = ({ value, onChange }: ApproximateAnswersInputProps) => {
  const { t } = useTranslate('missionForm.approximateAnswers');

  return (
    <div className="flex flex-col">
      <Label size="sm" className="block mb-2">
        {t('label').toUpperCase()}
      </Label>
      <div className="flex flex-row gap-2 items-center">
        <Checkbox checked={value} onChange={onChange} id="approxanswers-checkbox" />
        <label htmlFor="approxanswers-checkbox">
          <Text className="text-black-64" size="sm">
            {t('hint')}
          </Text>
        </label>
      </div>
    </div>
  );
};
