/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type DeleteTeamMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteTeamMutation = { __typename: 'Mutation', deleteTeam: { __typename: 'Team', id: string, displayName: string } };


export const DeleteTeamDocument = `
    mutation DeleteTeam($id: ID!) {
  deleteTeam(id: $id) {
    id
    displayName
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    DeleteTeam: build.mutation<DeleteTeamMutation, DeleteTeamMutationVariables>({
      query: (variables) => ({ document: DeleteTeamDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteTeamMutation } = injectedRtkApi;

