import { Button, Headline, Text, TextField } from '@goosechase/ui';
import { EmptyState } from 'components/empty-state';
import { ErrorState } from 'components/error-state';
import { LoadingSpinner } from 'components/loading-spinner';
import { AppRoutes } from 'constants/routes';
import {
  useCreateWorkspaceInvitationMutation,
  useDeleteWorkspaceInvitationMutation,
  useDeleteWorkspaceMemberMutation,
  usePendingWorkspaceInvitationsQuery,
  useUpdateWorkspaceInvitationMutation,
  useUpdateWorkspaceMemberMutation,
  useWorkspaceMembersQuery,
} from 'data/workspaces';
import sortBy from 'lodash.sortby';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router';
import { displayToast } from 'services/toast';
import { Nullable } from 'types/util';

import { useTranslate } from 'util/i18n';
import { DestructiveConfirmationModal } from './destructive-confirmation-modal.component';
import { Member } from './member.component';
import { WorkspaceInviteModal } from './workspace-invite-modal.component';

type MembersProps = {
  workspaceId: string;
  currentWorkspaceMember: Nullable<{ userId: string; roleId: string }>;
  displayName: string;
  allowInvites: boolean;
};

const WORKSPACE_MEMBER_ROLE_ORDERING = ['owner', 'admin', 'member'];

export const Members = (props: MembersProps) => {
  const { t } = useTranslate('pages.workspace.tabs.members');
  const { data, isLoading, isError } = useWorkspaceMembersQuery({ workspaceId: props.workspaceId });
  const { data: workspaceInvitationsData, isLoading: workspaceInvitationsLoading } =
    usePendingWorkspaceInvitationsQuery({ workspaceId: props.workspaceId });
  const [searchValue, setSearchValue] = useState('');
  const [updateWorkspaceMember] = useUpdateWorkspaceMemberMutation();
  const [deleteWorkspaceMember] = useDeleteWorkspaceMemberMutation();
  const [createWorkspaceInvitation] = useCreateWorkspaceInvitationMutation();
  const [updateWorkspaceInvitation] = useUpdateWorkspaceInvitationMutation();
  const [deleteWorkspaceInvitation] = useDeleteWorkspaceInvitationMutation();
  const [showDestructiveModal, setShowDestructiveModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [selectedWorkspaceMember, setSelectedWorkspaceMember] = useState<null | {
    action: string;
    workspaceMemberUserId: string;
    email: string;
  }>(null);
  const navigate = useNavigate();

  const handleSearchOnChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchValue(event.target.value);
  };

  const handleUpdateWorkspaceMember = async (params: {
    workspaceMemberUserId: string;
    roleId: string;
    email: string;
  }) => {
    try {
      await updateWorkspaceMember({
        params: {
          workspaceId: props.workspaceId,
          workspaceMemberUserId: params.workspaceMemberUserId,
          roleId: params.roleId,
        },
      }).unwrap();
      displayToast({
        body: <Trans t={t} i18nKey="toasts.updateSuccess.body" values={{ email: params.email }} />,
        id: params.workspaceMemberUserId,
        title: t('toasts.updateSuccess.title'),
        type: 'success',
      });
    } catch (error) {
      displayToast({
        body: t('toasts.updateError.body'),
        id: params.workspaceMemberUserId,
        title: t('toasts.updateError.title'),
        type: 'error',
      });
    }
  };

  const handleDeleteWorkspaceMember = async (
    params?: Nullable<{
      workspaceMemberUserId: string;
      email: string;
      action: string;
    }>,
  ) => {
    try {
      if (params) {
        await deleteWorkspaceMember({
          params: {
            workspaceId: props.workspaceId,
            workspaceMemberUserId: params.workspaceMemberUserId,
          },
        }).unwrap();
        if (params.action === 'remove') {
          displayToast({
            body: (
              <Trans t={t} i18nKey="toasts.deleteSuccess.body" values={{ email: params.email }} />
            ),
            id: params.workspaceMemberUserId,
            title: t('toasts.deleteSuccess.title'),
            type: 'success',
          });
        } else {
          navigate(AppRoutes.MY_EXPERIENCES);
        }
      }
      setShowDestructiveModal(false);
      setSelectedWorkspaceMember(null);
    } catch (error) {
      displayToast({
        body: t('toasts.deleteError.body'),
        title: t('toasts.deleteError.title'),
        type: 'error',
        id: 'workspaceMemberError',
      });
    }
  };

  const handleCreateWorkspaceInvitation = async (params: { roleId: string; email: string }) => {
    try {
      await createWorkspaceInvitation({
        params: {
          workspaceId: props.workspaceId,
          email: params.email,
          roleId: params.roleId,
        },
      }).unwrap();
      displayToast({
        body: (
          <Trans
            t={t}
            i18nKey="toasts.createInvitationSuccess.body"
            values={{ email: params.email }}
          />
        ),
        id: params.email,
        title: t('toasts.createInvitationSuccess.title'),
        type: 'success',
      });
    } catch (error) {
      displayToast({
        body: t('toasts.createInvitationError.body'),
        id: params.email,
        title: t('toasts.createInvitationError.title'),
        type: 'error',
      });
    }
  };

  const handleUpdateWorkspaceInvitation = async (params: {
    workspaceInvitationId: string;
    roleId: string;
    email: string;
  }) => {
    try {
      await updateWorkspaceInvitation({
        params: {
          invitationId: params.workspaceInvitationId,
          roleId: params.roleId,
        },
      }).unwrap();
      displayToast({
        body: (
          <Trans
            t={t}
            i18nKey="toasts.updateInvitationSuccess.body"
            values={{ email: params.email }}
          />
        ),
        id: params.workspaceInvitationId,
        title: t('toasts.updateInvitationSuccess.title'),
        type: 'success',
      });
    } catch (error) {
      displayToast({
        body: t('toasts.updateInvitationError.body'),
        id: params.workspaceInvitationId,
        title: t('toasts.updateInvitationError.title'),
        type: 'error',
      });
    }
  };

  const handleDeleteWorkspaceInvitation = async (params: {
    workspaceInvitationId: string;
    email: string;
  }) => {
    try {
      await deleteWorkspaceInvitation({
        invitationId: params.workspaceInvitationId,
      }).unwrap();
      displayToast({
        body: (
          <Trans
            t={t}
            i18nKey="toasts.deleteInvitationSuccess.body"
            values={{ email: params.email }}
          />
        ),
        id: params.workspaceInvitationId,
        title: t('toasts.deleteInvitationSuccess.title'),
        type: 'success',
      });
    } catch (error) {
      displayToast({
        body: t('toasts.deleteInvitationError.body'),
        id: params.workspaceInvitationId,
        title: t('toasts.deleteInvitationError.title'),
        type: 'error',
      });
    }
  };

  const filteredMembers = [
    ...(data?.workspaceMembers ?? []),
    ...(workspaceInvitationsData?.pendingWorkspaceInvitations ?? []),
  ]
    .filter((workspaceMember) =>
      workspaceMember.email.toLowerCase().includes(searchValue.toLowerCase()),
    )
    .map((workspaceMember) => ({
      email: workspaceMember.email,
      roleId: workspaceMember.roleId,
      ...(workspaceMember.__typename === 'WorkspaceMember'
        ? {
            photoUrl: workspaceMember.user.profile?.photoUrl,
            userId: workspaceMember.userId,
            workspaceInvitationId: '',
          }
        : {
            workspaceInvitationId: workspaceMember.id,
            photoUrl: '',
            userId: '',
          }),
    }))
    .sort((a, b) => a.email.localeCompare(b.email));

  const roleSortedMembers = sortBy(filteredMembers, (s) =>
    WORKSPACE_MEMBER_ROLE_ORDERING.indexOf(s.roleId),
  );

  if (isLoading || workspaceInvitationsLoading) {
    return (
      <div className="flex justify-center py-16 px-8">
        <LoadingSpinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex justify-center py-16 px-8">
        <ErrorState />
      </div>
    );
  }

  const currentUserIsOwnerOrAdmin =
    props.currentWorkspaceMember?.roleId === 'owner' ||
    props.currentWorkspaceMember?.roleId === 'admin';
  const canUpdateMembers = currentUserIsOwnerOrAdmin && props.allowInvites;

  return (
    <div className="flex flex-col gap-6 pb-4">
      <div className="flex items-center flex-wrap gap-4">
        <div>
          <Headline type="secondary" size="sm" className="mb-1">
            {t('title')}
          </Headline>
          <Text>{t('subtitle', { count: data?.workspaceMembers.length })}</Text>
        </div>
        <div className="flex tablet-wide:ml-auto items-center gap-4 flex-wrap">
          <TextField
            placeholder={t('searchPlaceholder') ?? undefined}
            value={searchValue}
            onChange={handleSearchOnChange}
            rounded
            leftIcon="Search"
          />
          {canUpdateMembers ? (
            <Button label={t('invite')} onClick={() => setShowInviteModal(true)} />
          ) : null}
        </div>
      </div>
      <div>
        {roleSortedMembers?.length ? (
          roleSortedMembers.map((workspaceMember) => (
            <Member
              key={workspaceMember.email}
              email={workspaceMember.email}
              photoUrl={workspaceMember.photoUrl}
              currentUser={props.currentWorkspaceMember?.userId === workspaceMember.userId}
              canUpdateMembers={canUpdateMembers}
              roleId={workspaceMember.roleId}
              workspaceMemberUserId={workspaceMember.userId}
              onUpdateWorkspaceMember={handleUpdateWorkspaceMember}
              onDeleteWorkspaceMember={(params) => {
                setSelectedWorkspaceMember(params);
                setShowDestructiveModal(true);
              }}
              onCancelWorkspaceInvitation={handleDeleteWorkspaceInvitation}
              onUpdateWorkspaceInvitation={handleUpdateWorkspaceInvitation}
              workspaceInvitationId={workspaceMember.workspaceInvitationId}
            />
          ))
        ) : (
          <div className="flex justify-center py-16 px-8">
            <EmptyState text={t('emptyState.text')} subtext={t('emptyState.subtext')} />
          </div>
        )}
      </div>
      <DestructiveConfirmationModal
        bodyText={t(`destructiveModal.${selectedWorkspaceMember?.action}.body`, {
          displayName: props.displayName,
          email: selectedWorkspaceMember?.email,
        })}
        cancelText={t(`destructiveModal.${selectedWorkspaceMember?.action}.cancel`)}
        confirmText={t(`destructiveModal.${selectedWorkspaceMember?.action}.confirm`)}
        headerText={t(`destructiveModal.${selectedWorkspaceMember?.action}.header`)}
        onClose={() => setShowDestructiveModal(false)}
        onSubmit={() => handleDeleteWorkspaceMember(selectedWorkspaceMember)}
        show={showDestructiveModal}
      />
      <WorkspaceInviteModal
        onClose={() => setShowInviteModal(false)}
        onSubmit={handleCreateWorkspaceInvitation}
        show={showInviteModal}
      />
    </div>
  );
};
