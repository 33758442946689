import {
  myUseCasesApi,
  allUseCasesApi,
  setUseCasesApi,
  submitEducatorProfileApi,
} from './onboarding.api';
import { onboardingSlice } from './onboarding.slice';

export const { useLazyMyUseCasesQuery } = myUseCasesApi;
export const { useAllUseCasesQuery } = allUseCasesApi;
export const { useSetUseCasesMutation } = setUseCasesApi;
export const { useSubmitEducatorProfileMutation } = submitEducatorProfileApi;

export const { setSelectedUseCases } = onboardingSlice.actions;
export const onboardingReducer = onboardingSlice.reducer;
