import { Button, Text, Label } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import { type PhotoUploadUIProps } from '../photo-upload/photo-upload.component';
import { LoadingSpinner } from 'components/loading-spinner';
import { MissionFormInputData } from './use-mission-form';
import { Control } from 'react-hook-form';

type MissionImageUploadUIProps = PhotoUploadUIProps &
  React.InputHTMLAttributes<HTMLButtonElement> & {
    control: Control<MissionFormInputData>;
  };

export const MissionImageUploadUI = ({
  photoUrl,
  uploading,
  onClick,
  errorMessage,
  onDelete,
}: MissionImageUploadUIProps) => {
  const { t } = useTranslate('missionForm');

  return (
    <div>
      <Label size="sm" className="block mb-2">
        {t('imageInput.label').toUpperCase()}
      </Label>
      <div className="flex flex items-center gap-x-4 gap-y-3">
        <div className="rounded w-[100px]">
          {uploading ? (
            <div className="flex items-center justify-center bg-offWhite w-[100px] h-[100px] rounded">
              <LoadingSpinner />
            </div>
          ) : (
            <img
              className="max-h-[150px] max-w-full rounded"
              alt={t('missionImageAlt') ?? 'Mission image'}
              src={photoUrl}
            />
          )}
        </div>
        <div className="flex flex-col gap-2">
          {!photoUrl && (
            <Button
              label={t('imageInput.buttons.add')}
              leftIcon="Plus"
              size="sm"
              outlined
              onClick={onClick}
              disabled={uploading}
            />
          )}
          {photoUrl && (
            <>
              <Button
                label={t('imageInput.buttons.replace')}
                leftIcon="Plus"
                size="sm"
                outlined
                onClick={onClick}
                disabled={uploading}
              />
              <Button
                label={t('imageInput.buttons.delete')}
                leftIcon="Delete"
                size="sm"
                outlined
                onClick={onDelete}
                disabled={uploading}
              />
            </>
          )}
          <Text className="whitespace-nowrap text-black-64" size="xs">
            {t('imageInput.description')}
          </Text>
        </div>
      </div>
      <div className="flex justify-between mt-2">
        <Text size="xs" className="text-vibrantRed">
          {errorMessage ?? ''}
        </Text>
      </div>
    </div>
  );
};
