import { Icon } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { useAppDispatch } from 'data/hooks';
import {
  type ExperienceListFilter as ExperienceListFilterType,
  setExperienceListFilter,
  useExperienceListFilter,
} from 'data/experiences';
import { NavBarDropdown } from 'components/header-nav-bar/nav-bar-dropdown.component';
import { PersonalFilters } from './personal-filters.component';
import { OrganizationFilters } from './organization-filters.component';

const useExperienceFilterControls = () => {
  const dispatch = useAppDispatch();
  const filter = useExperienceListFilter();

  const setFilter = (val: ExperienceListFilterType) => {
    dispatch(setExperienceListFilter(val));
  };

  return { filter, setFilter };
};

// Desktop
export const ExperienceListFilter = () => {
  const { filter, setFilter } = useExperienceFilterControls();

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col w-full divide-y-[1px] divide-black-32">
        <PersonalFilters isDropdown={false} selectedFilter={filter} onSetFilter={setFilter} />
        <OrganizationFilters isDropdown={false} selectedFilter={filter} onSetFilter={setFilter} />
      </div>
    </div>
  );
};

// Smaller screens
export const ExperienceListFilterDropdown = () => {
  const { t } = useTranslate('experienceListFilter');
  const { filter, setFilter } = useExperienceFilterControls();

  return (
    <NavBarDropdown
      variant={'default'}
      className="tablet-wide:hidden"
      dropdownClassName="right-0 top-7"
      ariaLabel={t('ariaDropdownLabel') ?? ''}
      dropdownButton={<Icon name="Menu" />}>
      <div className="pt-4 px-5 w-52 divide-y-[1px] divide-black-32">
        <PersonalFilters isDropdown selectedFilter={filter} onSetFilter={setFilter} />
        <OrganizationFilters isDropdown selectedFilter={filter} onSetFilter={setFilter} />
      </div>
    </NavBarDropdown>
  );
};
