import { Button, Headline, IconButton, Text, TextField } from '@goosechase/ui';
import { Card } from 'components/card.component';
import { useExperienceOutletContext } from 'components/experience-layout';
import { PhotoUploadField, type PhotoUploadUIProps } from 'components/photo-upload';
import { useExperienceFormBrandingQuery, useUpdateExperienceMutation } from 'data/experiences';
import { ExperienceAssetRole } from 'data/models';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { displayToast } from 'services/toast';
import { useTranslate } from 'util/i18n';
import { PhotoUploadUI } from './photo-upload-ui.component';
import { SplashScreenPreviewModal } from './splash-screen-preview-modal.component';
import SplashScreenPreview from './splash-screen-preview.component';
import { type BrandingFormData, useBrandingForm } from './use-branding-form';

export const BrandingForm = () => {
  const { experienceId } = useExperienceOutletContext();
  const { data } = useExperienceFormBrandingQuery({ id: experienceId });
  const [updateExperience] = useUpdateExperienceMutation();
  const { t } = useTranslate('pages.branding.form');
  const { control, handleSubmit, setValue, formState } = useBrandingForm({
    termsOfServiceUrl: data?.experience?.termsOfServiceUrl ?? null,
    splashScreenPhotoId: null,
  });
  const [uploading, setUploading] = useState(false);
  const [photoUrl, setPhotoUrl] = useState<string | undefined>();
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  useEffect(() => {
    if (data?.experience?.termsOfServiceUrl) {
      setValue('termsOfServiceUrl', data.experience.termsOfServiceUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.experience]);

  const onSubmit = async (values: BrandingFormData) => {
    try {
      await updateExperience({
        params: {
          id: experienceId,
          termsOfServiceUrl: values.termsOfServiceUrl,
          splashScreenPhotoId: values.splashScreenPhotoId,
        },
      });
      displayToast({
        title: t('successToast.title'),
        body: t('successToast.body'),
        type: 'success',
        id: experienceId,
      });
    } catch (error) {
      displayToast({
        title: t('errorToast.title'),
        body: t('errorToast.body'),
        type: 'error',
        id: experienceId,
      });
    }
  };

  const splashScreenPhotoAsset = data?.experience?.assets.find(
    (asset) => asset.role === ExperienceAssetRole.SplashScreenPhoto,
  );

  return (
    <div className="flex gap-6 items-start">
      <Card className="p-6 flex-1">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-3">
              <div className="flex justify-between">
                <Headline type="secondary" size="xs">
                  {t('splashScreen.title')}
                </Headline>
                <IconButton
                  icon="Smartphone"
                  onClick={() => setShowPreviewModal(true)}
                  iconColor="black"
                  className="bg-black-12 flex desktop:hidden"
                  type="button"
                />
              </div>
              <Text>{t('splashScreen.description')}</Text>
              <Controller
                control={control}
                name="splashScreenPhotoId"
                render={({ fieldState: { error } }) => (
                  <PhotoUploadField
                    label=""
                    setValue={(photoId) => setValue('splashScreenPhotoId', photoId)}
                    setError={() => {}}
                    errorMessage={error?.message}
                    defaultSrc={splashScreenPhotoAsset?.url ?? ''}
                    setUploading={setUploading}
                    uploading={uploading}
                    photoUploadUI={(props: PhotoUploadUIProps) => {
                      setPhotoUrl(props.photoUrl);
                      return <PhotoUploadUI {...props} />;
                    }}
                  />
                )}
              />
            </div>
            <div className="flex flex-col gap-3">
              <Headline type="secondary" size="xs">
                {t('termsOfServiceUrl.title')}
              </Headline>
              <Text>{t('termsOfServiceUrl.description')}</Text>
              <Controller
                name="termsOfServiceUrl"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <TextField
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      placeholder={t('termsOfServiceUrl.placeholder') ?? ''}
                      name={field.name}
                      value={field.value ?? ''}
                      responseState={fieldState.error ? 'error' : undefined}
                    />
                    {fieldState.error ? (
                      <Text size="xs" className="text-vibrantRed">
                        {fieldState.error.message}
                      </Text>
                    ) : null}
                  </>
                )}
              />
            </div>
            <div>
              <Button
                label={t('submit')}
                disabled={uploading || formState.isSubmitting || !formState.isValid}
              />
            </div>
          </div>
        </form>
      </Card>
      <div className="hidden desktop:block">
        <SplashScreenPreview id="desktop" photoUrl={photoUrl} />
      </div>
      <SplashScreenPreviewModal
        show={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
        photoUrl={photoUrl}
      />
    </div>
  );
};
