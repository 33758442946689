import { useExperienceOutletContext } from '../../components/experience-layout';
import { useActiveCohortId } from '../../data/experiences';
import { useSubmissionsOverviewQuery } from '../../data/submissions';

// useSubmissionsOverview is exported to prevent prop-drilling
//  in all of the "Submission Overview" components
export const useSubmissionsOverview = () => {
  const { experienceId } = useExperienceOutletContext();
  const activeCohortId = useActiveCohortId();

  return useSubmissionsOverviewQuery(
    {
      experienceId,
      cohortId: activeCohortId ?? '',
    },
    { skip: experienceId === '' || activeCohortId === null },
  );
};
