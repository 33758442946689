import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface LeaderboardState {
  selectedTeamId: string | null;
  teamBonusHistoryModalOpen: boolean;
}

const initialState: LeaderboardState = {
  selectedTeamId: null,
  teamBonusHistoryModalOpen: false,
};

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    selectTeamId: (state, action: PayloadAction<string>) => {
      state.selectedTeamId = action.payload;
      state.teamBonusHistoryModalOpen = true;
    },
    closeAndResetTeamBonusHistoryModal: (state) => {
      state.selectedTeamId = null;
      state.teamBonusHistoryModalOpen = false;
    },
  },
});
