import { Button } from '@goosechase/ui';
import { useTranslate } from '../../util/i18n';
import {
  useBroadcastForm,
  BroadcastFormOutputData,
  UseBroadcastFormArgs,
} from './use-broadcast-form';
import { Nullable } from 'types/util';
import { BroadcastMessageField } from './broadcast-message-field.component';
import { BroadcastSendToDropdown } from './broadcast-send-to-dropdown.component';
import { useExperienceOutletContext } from 'components/experience-layout';
import { SaveConfirmationModal } from './save-confirmation-modal.component';
import { useMemo, useState } from 'react';
import { BroadcastSendTimingField } from './broadcast-send-timing/broadcast-send-timing-field.component';
import { useWatch } from 'react-hook-form';
import {
  BroadcastSendOption,
  BroadcastTriggerTiming,
  TriggerTimeAnchor,
  TriggerTimeUnit,
} from 'data/models';
import { calculateTrigger } from 'util/time-util';

interface CalculateBroadcastTriggerParams {
  timing: BroadcastTriggerTiming;
  triggerDate: Date;
  relativeAnchor: Nullable<TriggerTimeAnchor>;
  relativeDuration: Nullable<number>;
  relativeUnit: Nullable<TriggerTimeUnit>;
  specificDay: Nullable<number>;
  specificTime: Nullable<string>;
  experienceTimezone: string;
}

export interface BroadcastFormUIProps {
  defaultValues: UseBroadcastFormArgs['defaultValues'];
  isLoading: boolean;
  onSubmit: (data: BroadcastFormOutputData) => void;
  onCancel: () => void;
  cohortStartDate?: Nullable<string>;
  cohortEndDate?: Nullable<string>;
  experienceTimezone: string;
}

export const BroadcastFormUI = ({
  defaultValues,
  onSubmit,
  onCancel,
  cohortEndDate,
  cohortStartDate,
  experienceTimezone,
}: BroadcastFormUIProps) => {
  const { t } = useTranslate('broadcastForm');
  const { experienceId } = useExperienceOutletContext();

  const [showModal, setShowModal] = useState(false);

  const { control, handleSubmit, watch, formState, trigger } = useBroadcastForm({
    defaultValues,
    cohortEndDate,
    cohortStartDate,
    experienceTimezone,
  });

  const formWatch = useWatch({ control });
  const sendTiming = useWatch({ control, name: `sendTiming` });

  // eslint-disable-next-line complexity
  const anticipatedSendDateTime = useMemo(() => {
    if (sendTiming === BroadcastSendOption.Now) {
      return new Date();
    }

    if (!cohortStartDate || !cohortEndDate) {
      return null;
    }

    const triggerInfo: CalculateBroadcastTriggerParams = {
      timing: BroadcastTriggerTiming.Relative,
      triggerDate: new Date(cohortStartDate),
      relativeAnchor: null,
      relativeDuration: null,
      relativeUnit: null,
      specificDay: null,
      specificTime: null,
      experienceTimezone,
    };

    if (sendTiming === BroadcastSendOption.BeforeExperience) {
      triggerInfo.relativeAnchor = TriggerTimeAnchor.BeforeStart;
      triggerInfo.relativeUnit = formWatch.beforeExperienceUnit ?? null;
      triggerInfo.relativeDuration = formWatch.beforeExperienceDuration ?? null;
    } else if (sendTiming === BroadcastSendOption.AtStartOfExperience) {
      triggerInfo.relativeAnchor = TriggerTimeAnchor.AtStart;
    } else if (sendTiming === BroadcastSendOption.DuringExperience) {
      if (formWatch.duringExperienceTiming === BroadcastTriggerTiming.Relative) {
        triggerInfo.relativeAnchor = formWatch.relativeAnchor ?? TriggerTimeAnchor.AfterStart;
        triggerInfo.relativeUnit = formWatch.relativeUnit ?? null;
        triggerInfo.relativeDuration = formWatch.relativeDuration ?? null;
        if (triggerInfo.relativeAnchor === TriggerTimeAnchor.BeforeEnd) {
          triggerInfo.triggerDate = new Date(cohortEndDate);
        }
      } else {
        triggerInfo.timing = BroadcastTriggerTiming.Specific;
        triggerInfo.specificDay = formWatch.specificDay ?? null;
        triggerInfo.specificTime = formWatch.specificTime ?? null;
      }
    } else if (sendTiming === BroadcastSendOption.AtEndOfExperience) {
      triggerInfo.triggerDate = new Date(cohortEndDate);
      triggerInfo.relativeAnchor = TriggerTimeAnchor.AtEnd;
    } else if (sendTiming === BroadcastSendOption.AfterExperience) {
      triggerInfo.triggerDate = new Date(cohortEndDate);
      triggerInfo.relativeAnchor = TriggerTimeAnchor.AfterEnd;
      triggerInfo.relativeUnit = formWatch.afterExperienceUnit ?? null;
      triggerInfo.relativeDuration = formWatch.afterExperienceDuration ?? null;
    }

    try {
      return calculateTrigger(triggerInfo);
    } catch {
      return null;
    }
  }, [sendTiming, cohortStartDate, cohortEndDate, experienceTimezone, formWatch]);

  const isInFuture = useMemo(() => {
    if (sendTiming === BroadcastSendOption.Now) {
      return false;
    }

    if (!cohortStartDate || !cohortEndDate || !anticipatedSendDateTime) {
      return true;
    }

    const now = new Date();

    return anticipatedSendDateTime > now;
  }, [anticipatedSendDateTime, sendTiming, cohortStartDate, cohortEndDate]);

  const disableSubmit = useMemo(() => {
    return !formState.isValid || !formState.isDirty;
  }, [formState.isValid, formState.isDirty]);

  const handleConfirm = () => {
    setShowModal(true);
  };

  return (
    <form className="flex flex-col grow justify-between min-h-0">
      <div className="flex flex-col grow w-full overflow-auto px-11 py-7 gap-y-4">
        <BroadcastSendToDropdown control={control} experienceId={experienceId} />
        <BroadcastMessageField control={control} />
        <BroadcastSendTimingField
          control={control}
          trigger={trigger}
          isInFuture={isInFuture}
          anticipatedSendDateTime={anticipatedSendDateTime}
        />
      </div>

      <div className="flex flex-row gap-4 py-6 px-11 border-t-black-24 border-t justify-self-end shrink-0">
        <Button outlined size="sm" label={t('cancel')} onClick={onCancel} />
        <Button
          type="button"
          size="sm"
          label={isInFuture ? t('scheduleButton') : t('sendButton')}
          onClick={isInFuture ? handleSubmit(onSubmit) : handleConfirm}
          disabled={disableSubmit}
        />
      </div>
      <SaveConfirmationModal
        show={showModal}
        header={t('saveConfirmationModal.header') ?? undefined}
        body={t('saveConfirmationModal.body', {
          recipient:
            watch('sendTo') === 'ALL' ? t('allParticipants').toLowerCase() : watch('teamName'),
        })}
        onSubmit={handleSubmit(onSubmit)}
        onClose={() => setShowModal(false)}
      />
    </form>
  );
};
