import { Headline, Text } from '@goosechase/ui';
import { Nullable } from 'types/util';
import { useTranslate } from 'util/i18n';
import { UpdateWorkspaceForm } from './update-workspace-form.component';

type SettingsProps = {
  workspaceId: string;
  displayName: string;
  photoUrl: Nullable<string>;
  currentWorkspaceMember: Nullable<{ userId: string; roleId: string }>;
};

export const Settings = (props: SettingsProps) => {
  const { t } = useTranslate('pages.workspace.tabs.settings');
  const currentUserIsOwnerOrAdmin =
    props.currentWorkspaceMember?.roleId === 'owner' ||
    props.currentWorkspaceMember?.roleId === 'admin';
  return (
    <div>
      <Headline type="secondary" size="sm" className="mb-1">
        {t('title')}
      </Headline>
      <Text className="mb-6">{t('subtitle')}</Text>
      <UpdateWorkspaceForm
        workspaceId={props.workspaceId}
        displayName={props.displayName}
        photoUrl={props.photoUrl}
        canUpdateSettings={currentUserIsOwnerOrAdmin}
      />
    </div>
  );
};
