// TODO: Update the contents of this file to use codegen
import { gql } from 'graphql-request';

interface RefreshPayload {
  __typename: string;
  userId: string;
  token: string;
  intercomHmac: string | null;
}

export interface RefreshResponse {
  data: {
    refresh: RefreshPayload;
  };
}

export const TOKEN_REFRESH_MUTATION = {
  document: gql`
    mutation refresh {
      refresh {
        __typename
        userId
        token
        intercomHmac
      }
    }
  `,
};

export const getResponseData = (response: RefreshResponse) => {
  return {
    userId: response.data?.refresh?.userId,
    intercomHmac: response.data?.refresh?.intercomHmac,
    accessToken: response.data?.refresh?.token,
  };
};
