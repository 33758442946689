var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Email = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M2.66683 3.12817H13.3335C14.0668 3.12817 14.6668 3.72817 14.6668 4.46151V12.4615C14.6668 13.1948 14.0668 13.7948 13.3335 13.7948H2.66683C1.9335 13.7948 1.3335 13.1948 1.3335 12.4615V4.46151C1.3335 3.72817 1.9335 3.12817 2.66683 3.12817Z", stroke: "#0C0A0C", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M14.6668 4.46143L8.00016 9.12809L1.3335 4.46143", stroke: "#0C0A0C", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
