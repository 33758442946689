import { mergeClasses } from '@goosechase/ui';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactNode } from 'react';

interface DraggableItemProps {
  id: string;
  children: ({ isDragging }: { isDragging: boolean }) => ReactNode;
}

export const DraggableItem = ({ id, children }: DraggableItemProps) => {
  const { setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Translate.toString(
      transform && isDragging ? { ...transform, x: 16, y: transform.y - 8 } : transform,
    ),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={mergeClasses('group relative', {
        ' z-10 shadow-md rounded-xl overflow-hidden focus-within:ring-2 focus-within:ring-vibrantBlue active:ring-0':
          isDragging,
      })}
      tabIndex={-1}>
      {children ? children({ isDragging }) : null}
    </div>
  );
};
