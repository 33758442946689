import { Button, ButtonType, Modal } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { Trans } from 'react-i18next';
import { ExperienceActionType } from 'data/models';

interface ConfirmationModalProps {
  action: ExperienceActionType;
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  experienceName: string;
}

const BUTTON_TYPE_MAP: Record<ExperienceActionType, ButtonType> = {
  delete: 'DESTRUCTIVE',
  duplicate: 'DEFAULT',
};

export const ConfirmationModal = ({
  action,
  show,
  onClose,
  onSubmit,
  experienceName,
}: ConfirmationModalProps) => {
  const { t } = useTranslate(`experienceCard.confirmationModal.${action}`);

  return (
    <Modal className="w-[calc(100vw-24px)] tablet-narrow:w-[592px]" show={show} onClose={onClose}>
      <Modal.Header title={t('header') ?? undefined} />
      <Modal.Body>
        <Trans i18nKey="body">{t('body', { experienceName })}</Trans>
      </Modal.Body>
      <Modal.Footer>
        <Button label={t('confirm')} onClick={onSubmit} buttonType={BUTTON_TYPE_MAP[action]} />
        <Button label={t('cancel')} onClick={onClose} outlined={true} />
      </Modal.Footer>
    </Modal>
  );
};
