import { Button, Headline, Text } from '@goosechase/ui';
import { displayToast } from '../../services/toast';
import { Card } from '../../components/card.component';
import { InformationBanner } from '../../components/information-banner';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { useTranslate } from '../../util/i18n';

export const ShareJoinCode = () => {
  const { data } = useExperienceInfo();
  const { t } = useTranslate('pages.participants.joinCode');

  const handleClick = () => {
    if (data?.experience?.joinCode) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigator.clipboard.writeText(data.experience.joinCode).then(() => {
        displayToast({
          id: 'join-code-copied',
          type: 'success',
          title: t('copiedToast.title'),
          body: t('copiedToast.body'),
        });
      });
    }
  };

  return (
    <div className="flex flex-col tablet-wide:flex-row gap-6">
      <div className="basis-1/2 block">
        <Card className="flex flex-col gap-3 p-6">
          <Headline type="secondary" size="sm">
            {t('title')}
          </Headline>
          <Text size="sm">{t('description')}</Text>
          <div className="flex bg-black-4 border border-black-24 rounded-lg relative">
            <Text className="flex-1 p-4" size="md">
              {data?.experience?.joinCode}
            </Text>
            <Button
              className="rounded-s-none rounded-e-lg h-[56px] absolute -top-[1px] -right-[1px]"
              label={t('copyButton')}
              onClick={handleClick}
            />
          </div>
        </Card>
      </div>
      <InformationBanner className="basis-1/2 self-baseline" body={t('guide')} icon="Phone" />
    </div>
  );
};
