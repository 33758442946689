import { Label, TextField, Button, Text } from '@goosechase/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useInviteExperienceManagerMutation } from 'data/experiences';
import { useForm, Controller } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { displayToast } from 'services/toast';
import { useTranslate } from 'util/i18n';
import { z } from 'zod';

interface ExperienceInvitationData {
  email: string;
}

interface InviteCollaboratorFormProps {
  experienceId: string;
}

const defaultValues = {
  email: '',
};

export const InviteCollaboratorForm = ({ experienceId }: InviteCollaboratorFormProps) => {
  const { t } = useTranslate('experienceCard.inviteCollaboratorsModal');
  const [inviteExperienceManager] = useInviteExperienceManagerMutation();

  const { handleSubmit, control, formState, reset, setError } = useForm<ExperienceInvitationData>({
    mode: 'onBlur',
    defaultValues,
    resolver: zodResolver(
      z.object({
        email: z
          .string({ required_error: t('form.email.errors.invalid') ?? 'Required' })
          .email({ message: t('form.email.errors.invalid') ?? 'Invalid email' }),
      }),
    ),
  });

  const onSubmit = async (values: ExperienceInvitationData) => {
    try {
      await inviteExperienceManager({ params: { email: values.email, experienceId } }).unwrap();
      displayToast({
        body: <Trans t={t} i18nKey="toasts.sentSuccess.body" values={{ email: values.email }} />,
        id: experienceId,
        title: t('toasts.sentSuccess.title'),
        type: 'success',
      });
      reset(defaultValues);
    } catch (error) {
      if (
        error &&
        typeof error === 'object' &&
        'code' in error &&
        error.code === 'ALREADY_A_COLLABORATOR'
      ) {
        setError('email', {
          message: t('form.email.errors.alreadyCollaborator') ?? 'Email is already a collaborator',
        });
      } else {
        displayToast({
          body: t('toasts.sentError.body'),
          id: experienceId,
          title: t('toasts.sentError.title'),
          type: 'error',
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Label className="block uppercase mb-2" size="lg">
        {t('form.email.label')}
      </Label>
      <div className="flex gap-5 flex-col tablet-narrow:flex-row">
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <TextField
              placeholder={t('form.email.placeholder') ?? ''}
              type="text"
              onChange={field.onChange}
              onBlur={field.onBlur}
              autoComplete="email"
              disabled={formState.isSubmitting}
              className="flex-1"
              responseState={fieldState.error ? 'error' : undefined}
              value={field.value}
            />
          )}
        />
        <Button label={t('form.submit')} disabled={formState.isSubmitting || !formState.isValid} />
      </div>
      {formState.errors.email ? (
        <Text className="mt-1 text-vibrantRed" size="sm">
          {formState.errors.email.message}
        </Text>
      ) : null}
    </form>
  );
};
