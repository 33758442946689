import { useMyOwnedWorkspacesQuery } from 'data/workspaces';
import { LoadingSpinner } from 'components/loading-spinner';
import { ErrorState } from 'components/error-state';
import { DeleteAccountWarning } from './delete-account-warning.component';
import { DeleteAccountConfirmation } from './delete-account-confirmation.component';
import { useCheckAccountDeletionQuery } from 'data/auth';

interface DeleteAccountModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const DeleteAccountModal = ({ show, onClose, onSubmit }: DeleteAccountModalProps) => {
  const { data, isLoading, isError } = useMyOwnedWorkspacesQuery();
  const { data: checkData, isLoading: checkIsLoading } = useCheckAccountDeletionQuery();

  if (isLoading || checkIsLoading) {
    return (
      <div className="flex justify-center p-8">
        <LoadingSpinner />
      </div>
    );
  }

  if (isError || !data?.myOwnedWorkspaces) {
    return (
      <div className="flex justify-center p-8">
        <ErrorState />
      </div>
    );
  }

  // deletion will not be allowed when an owned workspace have more than 1 member
  const workspace = data.myOwnedWorkspaces.find(
    (ownedWorkspace) => ownedWorkspace.members.length > 1,
  );

  return checkData?.checkAccountDeletion.deletionAllowed ? (
    <DeleteAccountConfirmation show={show} onClose={onClose} onSubmit={onSubmit} />
  ) : (
    <DeleteAccountWarning
      show={show}
      onClose={onClose}
      workspaceName={workspace?.displayName ?? ''}
    />
  );
};
