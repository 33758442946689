import { useFlags } from 'launchdarkly-react-client-sdk';

export const useMultipleWorkspacesFeatureFlag = (): boolean => {
  const { releaseMultipleWorkspaces } = useFlags();
  return Boolean(releaseMultipleWorkspaces);
};

export const useGatedMissionsFeatureFlag = (): boolean => {
  const { gatedMissions } = useFlags();
  return Boolean(gatedMissions);
};
