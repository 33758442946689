import { FormEventHandler } from 'react';
import { Controller } from 'react-hook-form';
import { Button, Modal } from '@goosechase/ui';

import { LabelledField } from 'components/labelled-field.component';
import { useTranslate } from '../../util/i18n';
import { LocationInput } from './location-input.component';
import { LocationMap } from './location-map.component';
import { RadiusDropdown } from './radius-dropdown.component';
import {
  useGpsLocationForm,
  GpsLocationFormData,
  UseGpsLocationFormProps,
} from './use-gps-location-form';

interface GpsLocationFormProps {
  defaultValues: UseGpsLocationFormProps['defaultValues'];
  show: boolean;
  onClose: () => void;
  onSubmit: (data: GpsLocationFormData) => void;
}

export const GpsLocationFormUI = ({
  defaultValues,
  show,
  onClose,
  onSubmit,
}: GpsLocationFormProps) => {
  const { t } = useTranslate('gpsLocationForm');

  const { control, handleSubmit, setValue } = useGpsLocationForm({ defaultValues });

  const handleFormSubmission: FormEventHandler<HTMLFormElement> = (event) => {
    // Stop propagation as this is form is nested within the mission form
    event.stopPropagation();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleSubmit(onSubmit)(event);
  };

  const clearLocationName = () => {
    setValue('locationName', '');
  };

  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[500px] desktop:w-[800px] overflow-visible text-black"
      show={show}
      onClose={onClose}>
      <Modal.Header title={t('header') ?? undefined} />
      <form onSubmit={handleFormSubmission}>
        <Modal.Body>
          <div className="flex flex-col gap-3">
            <LocationMap
              control={control}
              description={t('mapDescription')}
              clearLocationName={clearLocationName}
            />

            <LocationInput control={control} setValue={setValue} />

            <div className="grid grid-cols-6 gap-y-3 gap-x-8">
              <Controller
                control={control}
                name="latitude"
                render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
                  <LabelledField
                    className="grow col-span-6 tablet-narrow:col-span-3 desktop:col-span-2"
                    label={t('latitude.label')}
                    placeholder={t('latitude.placeholder') ?? undefined}
                    type="text"
                    errorMessage={error?.message}
                    value={value}
                    name={name}
                    onChange={(event: React.ChangeEvent) => {
                      onChange(event);
                      clearLocationName();
                    }}
                    onBlur={onBlur}
                  />
                )}
              />

              <Controller
                control={control}
                name="longitude"
                render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
                  <LabelledField
                    className="grow col-span-6 tablet-narrow:col-span-3 desktop:col-span-2"
                    label={t('longitude.label')}
                    placeholder={t('longitude.placeholder') ?? undefined}
                    type="text"
                    errorMessage={error?.message}
                    value={value}
                    name={name}
                    onChange={(event: React.ChangeEvent) => {
                      onChange(event);
                      clearLocationName();
                    }}
                    onBlur={onBlur}
                  />
                )}
              />

              <Controller
                control={control}
                name="radius"
                render={({ field: { value, onChange } }) => (
                  <RadiusDropdown
                    className="col-span-6 desktop:col-span-2"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button label={t('save')} size="sm" />
          <Button label={t('cancel')} size="sm" onClick={onClose} outlined={true} />
        </Modal.Footer>
      </form>
    </Modal>
  );
};
