/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type AvailablePlansQueryVariables = Types.Exact<{
  workspaceType: Types.InputMaybe<Types.WorkspaceType>;
}>;


export type AvailablePlansQuery = { __typename: 'Query', plans: Array<{ __typename: 'WorkspacePlan', id: string, chargebeeSku: string | null, displayName: string, priceUsdCents: number }> };


export const AvailablePlansDocument = `
    query AvailablePlans($workspaceType: WorkspaceType) {
  plans(filter: {types: [$workspaceType]}) {
    id
    chargebeeSku
    displayName
    priceUsdCents
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    AvailablePlans: build.query<AvailablePlansQuery, AvailablePlansQueryVariables | void>({
      query: (variables) => ({ document: AvailablePlansDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAvailablePlansQuery, useLazyAvailablePlansQuery } = injectedRtkApi;

