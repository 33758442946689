import { useExperienceOutletContext } from 'components/experience-layout';
import { AppRoutes, EXPERIENCE_PATH } from 'constants/routes';
import {
  LoadExperienceAddonDetailsQuery,
  useLoadExperienceAddonDetailsQuery,
} from 'data/experiences';
import { ShowUpgradeBanner, WorkspaceType } from 'data/models';
import {
  useActiveWorkspaceWithSubscriptionQuery,
  useExperienceWorkspaceQuery,
} from 'data/workspaces';
import { useLocation } from 'react-router';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useMultipleWorkspacesFeatureFlag } from '../../hooks/use-feature-flag.hook';
import { UpgradeExperienceBanner } from './upgrade-experience-banner';
import { UpgradePlanBanner } from './upgrade-plan-banner';

const experienceHasAddon = (experience?: LoadExperienceAddonDetailsQuery['experience']) => {
  if (!experience) {
    // If there is no experience, we cannot properly check => return true
    return true;
  }

  return experience.addons.length > 0;
};

export const UpgradeBanner = () => {
  const experienceOutlet = useExperienceOutletContext();
  const { data: experienceWorkspaceData, isLoading: experienceWorkspaceLoading } =
    useExperienceWorkspaceQuery(
      { id: experienceOutlet?.experienceId },
      { skip: !experienceOutlet?.experienceId },
    );
  const { data: workspaceData, isLoading: activeWorkspaceIsLoading } =
    useActiveWorkspaceWithSubscriptionQuery();
  const { data: experienceData } = useLoadExperienceAddonDetailsQuery(
    { id: experienceOutlet?.experienceId },
    { skip: !experienceOutlet?.experienceId },
  );
  const activeWorkspace = useActiveWorkspace();
  const location = useLocation();
  const multipleWorkspacesFlag = useMultipleWorkspacesFeatureFlag();

  if (experienceWorkspaceLoading || activeWorkspaceIsLoading) {
    return null;
  }

  let workspace;
  if (experienceWorkspaceData?.experience?.workspace) {
    // if there's a experience workspace, this should be the active one
    workspace = experienceWorkspaceData.experience.workspace;
  } else if (multipleWorkspacesFlag) {
    // if the multiple workspaces flag is active, use the activeWorkspace hook
    workspace = activeWorkspace;
  } else {
    // if not, use the original active workspace
    workspace = workspaceData?.myActiveWorkspace;
  }

  const experience = experienceData?.experience;

  if (!workspace) {
    return null;
  }

  const isMyExperiencesPage = location.pathname === AppRoutes.MY_EXPERIENCES;
  const isExperienceDetailsPage = location.pathname.startsWith(`${EXPERIENCE_PATH}/`);
  const alwaysShowUpgradeBanner =
    workspace.subscription.plan.showUpgradeBanner === ShowUpgradeBanner.Always;

  if (alwaysShowUpgradeBanner) {
    if (isMyExperiencesPage) {
      return <UpgradePlanBanner />;
    } else if (isExperienceDetailsPage) {
      if (workspace.type === WorkspaceType.Educator && !experienceHasAddon(experience)) {
        return <UpgradePlanBanner />;
      } else if (workspace.type === WorkspaceType.Regular && !experienceHasAddon(experience)) {
        return <UpgradeExperienceBanner />;
      }
    }
  }

  return null;
};
