import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'constants/routes';
import { LoadingSpinner } from 'components/loading-spinner';

import { setSelectedUseCases, useAllUseCasesQuery, useSetUseCasesMutation } from 'data/onboarding';
import { UseCaseForm } from './use-case-form';
import { useAppDispatch } from 'data/hooks';

export const UseCaseSelectionPage = () => {
  const { data, isError, isLoading } = useAllUseCasesQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [saveUseCases] = useSetUseCasesMutation();

  const allUseCases = data?.useCases;

  const handleSubmitUseCases = async (selectedUseCases: string[]) => {
    // Note: For now we are saving a local copy of the use cases selected as well
    // as submitting it to the API here.
    // If / when onboarding becomes more complex (eg we conditionally skip steps,
    // make future steps based on past steps, allow going back etc)
    // we will likely want to perform these operations in a centralized thunk. For now it is simple enough
    // to just do both here.
    dispatch(setSelectedUseCases(selectedUseCases));
    await saveUseCases({ useCases: selectedUseCases });
    navigate(AppRoutes.HOME);
  };

  if (isError) {
    // TODO: Sentry - Failed to fetch UseCases
    navigate(AppRoutes.HOME);
  }
  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center h-80 w-60 tablet-narrow:w-80 desktop:w-[700px]">
          <LoadingSpinner />
        </div>
      ) : (
        <UseCaseForm
          serverUseCases={(allUseCases ?? []).map(({ id }) => id)}
          onSubmit={handleSubmitUseCases}
        />
      )}
    </div>
  );
};
