import { IconButton } from '@goosechase/ui';
import { useExperienceOutletContext } from 'components/experience-layout';
import { useActiveCohortId, useExperienceInfoQuery } from 'data/experiences';
import type { LeaderboardQuery } from 'data/leaderboard';
import { displayToast } from 'services/toast';
import { useTranslate } from 'util/i18n';

interface DownloadLeaderboardTableButtonProps {
  headers: string[];
  ariaLabel: string;
  className?: string;
  data: LeaderboardQuery['leaderboard'];
}

export const DownloadLeaderboardTableButton = ({
  headers,
  className,
  ariaLabel,
  data,
}: DownloadLeaderboardTableButtonProps) => {
  const { t } = useTranslate('pages.stats.leaderboard');
  const { experienceId } = useExperienceOutletContext();
  const cohortId = useActiveCohortId();
  const { data: experienceInfoData } = useExperienceInfoQuery({ id: experienceId });

  const handleClick = async () => {
    if (!cohortId || !data) {
      return;
    }

    try {
      const flattenedData = data.map((d) => [
        // text fields that may contain commas must be wrapped in double quotes to not break the csv file
        `"${d.team.displayName}"`,
        d.team.points,
        d.team.numSubmissions,
        d.team.lastSubmission ?? '',
      ]);

      const csvContent = `data:text/csv;charset=utf-8,${headers.join()}\n${flattenedData?.join(
        '\n',
      )}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute(
        'download',
        `${experienceInfoData?.experience?.displayName}'s ${t('fileName')}.csv`,
      );
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file
      link.remove();
    } catch {
      displayToast({
        body: t('errorToast.body'),
        id: cohortId,
        title: t('errorToast.title'),
        type: 'error',
      });
    }
  };

  return (
    <IconButton
      icon="Save"
      iconColor="black"
      disabled={!cohortId}
      onClick={handleClick}
      className={className}
      aria-label={ariaLabel}
    />
  );
};
