import { Helmet } from 'react-helmet-async';

import { useTranslate } from 'util/i18n';

export const FallbackHelmet = () => {
  const { t } = useTranslate('fallbackHelmet');

  return (
    <Helmet>
      <title>{t('title')}</title>
      <meta name="description" content="" />
    </Helmet>
  );
};
