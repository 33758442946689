var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var Like = function () { return (_jsx("g", __assign({ fill: "none" }, { children: _jsx("path", { d: "M8.49175 13.4349C11.534 11.1931 10.8291 3.03318 10.8291 3.03318C11.6599 2.92163 12.4725 3.0694 13.3576 3.8147C14.0816 4.42425 14.2695 5.37602 14.2695 6.75491C14.2695 8.1338 14.2695 10.1572 14.2695 10.1572L19.0725 9.65247C19.2969 9.64937 19.5192 9.7059 19.7239 9.81816C19.9286 9.93042 20.111 10.0957 20.2582 10.3026C20.4055 10.5095 20.5141 10.753 20.5767 11.0163C20.6393 11.2796 19.7086 14.3932 19.6749 14.6642L19.0401 19.3923C18.9841 19.8433 18.7966 20.2544 18.5121 20.5498C18.2277 20.8452 17.8654 21.005 17.4921 20.9999H8.49175M8.49175 13.4349C8.49175 17.4971 8.49175 20.9999 8.49175 20.9999M8.49175 13.4349C7.58494 13.4349 4.54801 13.4349 4.54801 13.4349C4.13746 13.4349 3.74371 13.6342 3.4534 13.9889C3.16309 14.3435 3 14.8246 3 15.3262V19.1086C3 19.6102 3.16309 20.0913 3.4534 20.4459C3.74371 20.8006 4.13746 20.9999 4.54801 20.9999H8.49175", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) }))); };
