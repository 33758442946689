import { z } from 'zod';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, NotificationBanner } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { LabelledField } from 'components/labelled-field.component';
import { AppLink } from 'components/app-link.component';

export interface LoginFormUIProps {
  loading: boolean;
  serverError?: string;
  onSubmit: (data: LoginData) => void;
}

export interface LoginData {
  email: string;
  password: string;
}

const useLoginForm = () => {
  const { t } = useTranslate('pages.login.loginForm');

  return useForm({
    mode: 'onTouched',
    defaultValues: { email: '', password: '' },
    resolver: zodResolver(
      z.object({
        email: z.string().min(1, { message: t('email.errors.required') as string }),
        password: z.string().min(1, { message: t('password.errors.required') as string }),
      }),
    ),
  });
};

export const LoginFormUI = ({ loading, serverError, onSubmit }: LoginFormUIProps) => {
  const { t } = useTranslate('pages.login.loginForm');
  const { control, handleSubmit, formState } = useLoginForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6 desktop:mb-8">
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <LabelledField
              label={t('email.label')}
              type="text"
              onChange={field.onChange}
              onBlur={field.onBlur}
              autoComplete="email"
              errorMessage={fieldState.error?.message}
              disabled={loading}
            />
          )}
        />
      </div>
      <div className="mb-6 desktop:mb-8">
        <Controller
          control={control}
          name="password"
          render={({ field, fieldState }) => (
            <LabelledField
              label={t('password.label')}
              type="password"
              onChange={field.onChange}
              onBlur={field.onBlur}
              autoComplete="current-password"
              errorMessage={fieldState.error?.message}
              disabled={loading}
            />
          )}
        />
        <AppLink page="FORGOT_PASSWORD" className="text-sm mt3">
          {t('forgotPassword')}
        </AppLink>
      </div>
      {serverError && (
        <NotificationBanner
          className="mb-6 desktop:mb-8"
          type="error"
          title={t('generalErrorTitle') as string}
          body={serverError}
          showIcon
        />
      )}
      <Button label={t('submit')} disabled={!formState.isValid || loading} />
    </form>
  );
};
