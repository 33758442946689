import { Dropdown, Icon, IconName } from '@goosechase/ui';
import type { TFunction } from 'i18next';

import { BroadcastSendOption, BROADCAST_SEND_OPTIONS } from '../../../data/models';
import { useTranslate } from '../../../util/i18n';
import { DropdownOptionType } from '../util';

const BROADCAST_SEND_OPTION_TO_TRANSLATION_KEY_MAP: Record<BroadcastSendOption, string> = {
  NOW: 'now',
  BEFORE_EXPERIENCE: 'beforeExperience',
  AT_START_OF_EXPERIENCE: 'atStartOfExperience',
  DURING_EXPERIENCE: 'duringExperience',
  AT_END_OF_EXPERIENCE: 'atEndOfExperience',
  AFTER_EXPERIENCE: 'afterExperience',
};

const BROADCAST_SEND_OPTION_TO_ICON_MAP: Record<BroadcastSendOption, IconName> = {
  NOW: 'Now',
  BEFORE_EXPERIENCE: 'BeforeExperience',
  AT_START_OF_EXPERIENCE: 'AtStartOfExperience',
  DURING_EXPERIENCE: 'DuringExperience',
  AT_END_OF_EXPERIENCE: 'AtEndOfExperience',
  AFTER_EXPERIENCE: 'AfterExperience',
};

export const renderSelectedLeftIcon =
  ({ icon, iconColor = 'black' }: DropdownOptionType) =>
  // eslint-disable-next-line react/display-name
  () =>
    icon ? <Icon size={24} name={icon} color={iconColor} /> : null;

const convertBroadcastSendOptionToDropdownOption = (
  t: TFunction,
  broadcastSendOption: BroadcastSendOption,
): DropdownOptionType => ({
  id: broadcastSendOption,
  icon: BROADCAST_SEND_OPTION_TO_ICON_MAP[broadcastSendOption],
  title: t(`${BROADCAST_SEND_OPTION_TO_TRANSLATION_KEY_MAP[broadcastSendOption]}.title`),
  description: t(
    `${BROADCAST_SEND_OPTION_TO_TRANSLATION_KEY_MAP[broadcastSendOption]}.description`,
  ),
});

export interface BroadcastSendTimingDropdownProps {
  value: BroadcastSendOption;
  onChange: (value: BroadcastSendOption) => void;
}

export const BroadcastSendTimingDropdown = ({
  value,
  onChange,
}: BroadcastSendTimingDropdownProps) => {
  const { t } = useTranslate('broadcastForm.sendTiming');

  const options = BROADCAST_SEND_OPTIONS.map((broadcastSendOption) =>
    convertBroadcastSendOptionToDropdownOption(t, broadcastSendOption),
  );

  const selectedDropdownOption = convertBroadcastSendOptionToDropdownOption(t, value);

  return (
    <Dropdown
      buttonClassName="w-full"
      label={selectedDropdownOption.title}
      options={options}
      selectedOptionId={value}
      renderLeft={renderSelectedLeftIcon(selectedDropdownOption)}
      onSelect={(val: string) => onChange(val as BroadcastSendOption)}
    />
  );
};
