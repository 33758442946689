var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { getDataTestIDProp, getTestIDProp } from '../../util/test-id';
import { ButtonIcon, ButtonLabel } from '../components';
import { getBaseButtonClassNames, getButtonIconProps, getButtonLabelClassNames, getButtonTypeClassNames, } from '../button-utils';
import { mergeClasses } from '../../util/merge-classes';
var HalfButton = function (_a) {
    var className = _a.className, position = _a.position, disabled = _a.disabled, children = _a.children, onClick = _a.onClick, testID = _a.testID, htmlProps = __rest(_a, ["className", "position", "disabled", "children", "onClick", "testID"]);
    var positionClassNames = position === 'left'
        ? ['rounded-l-button-sm', 'border-r-0', 'pl-4 pr-2.5']
        : ['rounded-r-button-sm', 'border-l-0', 'pr-4 pl-2.5'];
    return (_jsx("button", __assign({ className: classNames(className, 'relative focus:z-10', positionClassNames), disabled: disabled, onClick: onClick }, getDataTestIDProp(testID), htmlProps, { children: children })));
};
export var SplitButtonWithDropdown = function (_a) {
    var options = _a.options, label = _a.label, _b = _a.icon, icon = _b === void 0 ? 'DropdownArrow' : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, onClick = _a.onClick, renderOption = _a.renderOption, onOptionClick = _a.onOptionClick, testID = _a.testID, dropdownAriaLabel = _a.dropdownAriaLabel;
    var baseClassNames = ['border-2', getBaseButtonClassNames(disabled)];
    var _d = getButtonIconProps(true), iconColor = _d.iconColor, iconDisabledColor = _d.iconDisabledColor, iconHoverClassNames = _d.iconHoverClassNames;
    var labelClassNames = getButtonLabelClassNames(true, disabled);
    var buttonTypeClassNames = getButtonTypeClassNames(true, disabled);
    var sizeClassNames = ['h-[40px]'];
    var buttonClassNames = classNames(labelClassNames, buttonTypeClassNames, baseClassNames, sizeClassNames);
    var handleOptionClick = function (id) {
        return function () {
            onOptionClick(id);
        };
    };
    return (_jsxs("div", __assign({ className: "flex" }, { children: [_jsx(HalfButton, __assign({ className: buttonClassNames, position: "left", disabled: disabled, onClick: onClick }, getTestIDProp(testID, 'left-button'), { children: _jsx(ButtonLabel, __assign({ text: label, size: "lg", disabled: disabled }, getTestIDProp(testID, 'label'))) })), _jsx("div", __assign({ className: classNames('flex', 'items-center', disabled ? 'bg-black-24' : 'bg-vibrantBlue') }, { children: _jsx("div", { className: classNames('w-px', 'h-[26px]', 'bg-white') }) })), _jsxs(Menu, __assign({ as: "div", className: "relative" }, { children: [_jsx(MenuButton, __assign({ disabled: disabled, role: "application", "aria-label": dropdownAriaLabel, as: "div" }, { children: _jsx(HalfButton, __assign({ className: buttonClassNames, position: "right", disabled: disabled, "aria-label": dropdownAriaLabel }, getTestIDProp(testID, 'right-button'), { children: _jsx(ButtonIcon, __assign({ name: icon, disabled: disabled, iconColor: iconColor, iconDisabledColor: iconDisabledColor, iconHoverClassNames: iconHoverClassNames }, getTestIDProp(testID, 'icon'))) })) })), _jsx(MenuItems, __assign({ className: "absolute top-full right-0 mt-0.5 max-h-64 flex flex-col cursor-pointer bg-white border border-black-24 rounded-lg overflow-auto outline-0 z-10 drop-shadow-md" }, getDataTestIDProp(testID, 'list'), { children: options.map(function (option) { return (_jsx(MenuItem, __assign({}, getDataTestIDProp(testID, 'item'), { children: _jsx("button", __assign({ type: "button", className: mergeClasses('outline-0 text-left hover:bg-black-4 ui-active:bg-black-4', { 'border-t border-black-24': option.bordered }), onClick: handleOptionClick(option.id) }, { children: renderOption(option) })) }), option.id)); }) }))] }))] })));
};
