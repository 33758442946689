import { AppRoutes } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from 'util/i18n';
import { EducatorInfoForm } from './educator-info-form/educator-info-form';
import { OnboardingFormLayout } from './onboarding-form-layout';

export const EducatorInfoPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslate('pages.onboarding.educatorInfo');

  return (
    <OnboardingFormLayout title={t('title')} subtext={t('subtitle')}>
      <EducatorInfoForm onSubmitSuccess={() => navigate(AppRoutes.HOME)} />
    </OnboardingFormLayout>
  );
};
