import { Avatar, Button, Label, Text } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';

import { LoadingSpinner } from 'components/loading-spinner';
import { PhotoUploadUIProps } from 'components/photo-upload';

interface PhotoUploadImageProps {
  src?: string;
  loading: boolean;
}

const PhotoUploadImage = ({ src, loading = false }: PhotoUploadImageProps) =>
  loading ? (
    <div className="flex items-center justify-center w-[116px] h-[116px]">
      <LoadingSpinner />
    </div>
  ) : (
    <Avatar size="xl" placeholder="single" src={src} alt="" />
  );

export const PhotoUploadUI = ({
  label,
  photoUrl,
  uploading,
  onClick,
  onDelete,
  errorMessage,
}: PhotoUploadUIProps) => {
  const { t } = useTranslate('pages.accountInfo.editAccount.updateUserProfile');

  return (
    <div>
      <Label size="sm" className="block mb-2">
        {label.toUpperCase()}
      </Label>
      <div className="flex gap-5 items-center">
        <PhotoUploadImage src={photoUrl ?? ''} loading={uploading} />
        <div className="flex flex-col gap-3">
          <div className="flex gap-3">
            <Button
              label={photoUrl ? t('photoId.replace') : t('photoId.upload')}
              leftIcon="Plus"
              size="sm"
              outlined
              onClick={onClick}
              disabled={uploading}
              className="w-fit"
            />
            {photoUrl ? (
              <Button
                label={t('photoId.delete')}
                leftIcon="Delete"
                size="sm"
                outlined
                onClick={onDelete}
                disabled={uploading}
                className="w-fit"
              />
            ) : null}
          </div>
          <Text>{t('photoId.description')}</Text>
        </div>
      </div>
      <div className="flex justify-between mt-2">
        <Text size="xs" className="text-vibrantRed">
          {errorMessage ?? ''}
        </Text>
      </div>
    </div>
  );
};
