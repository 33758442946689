import { Button, Modal, Text } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';

interface ResetConfirmationModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const ResetConfirmationModal = ({
  show,
  onClose,
  onSubmit,
}: ResetConfirmationModalProps) => {
  const { t } = useTranslate('pages.startEnd.resetConfirmation');

  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[498px] desktop:w-[766px]"
      show={show}
      onClose={onClose}>
      <Modal.Header title={t('header') ?? undefined} />
      <Modal.Body>
        <Text className="mb-6">{t('body1')}</Text>
        <Text>{t('body2')}</Text>
      </Modal.Body>
      <Modal.Footer className="flex flex-col tablet-narrow:flex-row">
        <Button label={t('confirm')} onClick={onSubmit} leftIcon="Refresh" />
        <Button label={t('cancel')} onClick={onClose} outlined />
      </Modal.Footer>
    </Modal>
  );
};
