import { NotificationBanner } from '@goosechase/ui';
import { Trans } from 'react-i18next';
import { useTranslate } from 'util/i18n';

type DraftModeBannerProps = {
  onClick?: () => void;
};

export const DraftModeBanner = (props: DraftModeBannerProps) => {
  const { t } = useTranslate('missionForm.draftMode.banner');

  return (
    <NotificationBanner
      body={t('body')}
      title={
        <Trans
          t={t}
          i18nKey="title"
          components={[
            <span
              key="go-to-availability"
              className={props.onClick ? 'underline cursor-pointer' : ''}
              onClick={props.onClick}
            />,
          ]}
        />
      }
      showIcon
      type="warning"
    />
  );
};
