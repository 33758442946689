import { useState } from 'react';
import { TextField, mergeClasses } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { LoadingSpinner } from 'components/loading-spinner';
import { EmptyState } from 'components/empty-state/empty-state.component';
import { Card } from 'components/card.component';
import { ErrorState } from 'components/error-state';

import {
  useExperienceMissionsQuery,
  useSelectedMission,
  useUpdateMissionCustomOrderPositionMutation,
} from '../../data/missions';
import { EditableMissionCard } from './editable-mission-card.component';
import { filterMissionBySearchValue } from './util';
import { useActiveCohortId, useLoadExperienceStartAndEndQuery } from 'data/experiences';
import { MissionOrder } from 'data/models';
import { DragContext, DraggableItem } from '../../components/drag-list';
import { DownloadMissions } from './download-missions.component';
import { Trans } from 'react-i18next';
import { MissionDropdown } from '../../components/mission-card/mission-dropdown.component';
import { displayToast } from 'services/toast';

interface MissionListProps {
  experienceId: string;
  missionOrder?: MissionOrder;
  onMissionLibraryClick: () => void;
}

export const MissionList = ({
  experienceId,
  missionOrder,
  onMissionLibraryClick,
}: MissionListProps) => {
  const { t } = useTranslate('pages.missions');
  const cohortId = useActiveCohortId();
  const { data, isLoading, isError, isUninitialized } = useExperienceMissionsQuery(
    {
      id: experienceId,
      cohortId: cohortId ?? '',
    },
    { skip: cohortId === null },
  );
  const { isLoading: isLoadingStartAndEndData, data: startAndEndData } =
    useLoadExperienceStartAndEndQuery({ id: cohortId ?? '' }, { skip: !cohortId });
  const [searchValue, setSearchValue] = useState('');
  const selectedMission = useSelectedMission();
  const [updateMissionCustomOrderPosition] = useUpdateMissionCustomOrderPositionMutation();

  if (isLoading || isLoadingStartAndEndData || isUninitialized) {
    return (
      <Card className="w-full flex justify-center items-center p-8">
        <LoadingSpinner />
      </Card>
    );
  }

  if (isError) {
    return (
      <Card className="w-full flex justify-center items-center p-8">
        <ErrorState />
      </Card>
    );
  }

  const missions = data?.experienceMissions;

  const handleSearchOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value);
  };

  const handleItemPositionUpdate = async (
    updateMissionCustomOrderPositionId: string,
    newPosition: number,
  ) => {
    try {
      await updateMissionCustomOrderPosition({ newPosition, updateMissionCustomOrderPositionId });
      displayToast({
        id: updateMissionCustomOrderPositionId,
        title: t('missionOrder.toast.success.title'),
        body: t('missionOrder.toast.success.body'),
        type: 'success',
      });
    } catch {
      displayToast({
        id: updateMissionCustomOrderPositionId,
        title: t('missionOrder.toast.error.title'),
        body: t('missionOrder.toast.error.body'),
        type: 'error',
      });
    }
  };

  const filteredMissions = missions?.filter(filterMissionBySearchValue(searchValue));

  const dragEnabled = missionOrder === MissionOrder.Custom;

  return (
    <Card className="w-full">
      <div className="flex gap-4 items-center p-4">
        <TextField
          className="flex-1"
          placeholder={t('missionList.searchPlaceholder') ?? undefined}
          value={searchValue}
          onChange={handleSearchOnChange}
          rounded
          leftIcon="Search"
        />
        <DownloadMissions />
      </div>
      {missions.length ? (
        <DragContext items={filteredMissions} onItemPositionUpdate={handleItemPositionUpdate}>
          {filteredMissions.map((mission, index) => (
            <DraggableItem id={mission.id} key={mission.id}>
              {({ isDragging }) => (
                <>
                  <EditableMissionCard
                    className={mergeClasses({
                      'border-t border-black-12': index !== 0 && !isDragging,
                      'rounded-b-xl': index === missions.length - 1,
                    })}
                    isSelected={mission.id === selectedMission?.id}
                    dragEnabled={dragEnabled}
                    isDragging={isDragging}
                    cohortStartDate={startAndEndData?.cohort?.startDate}
                    cohortEndDate={startAndEndData?.cohort?.endDate}
                    {...mission}
                  />
                  <MissionDropdown
                    missionId={mission.id}
                    displayName={mission.displayName}
                    numSubmissions={mission.numSubmissions}
                  />
                </>
              )}
            </DraggableItem>
          ))}
        </DragContext>
      ) : (
        <div className="p-12">
          <EmptyState
            text={t('missionList.emptyState.text')}
            subtext={
              <Trans
                t={t}
                i18nKey="missionList.emptyState.subtext"
                components={[
                  <span
                    key="go-to-availability"
                    className="font-bold text-vibrantBlue cursor-pointer"
                    onClick={onMissionLibraryClick}
                  />,
                ]}
              />
            }
          />
        </div>
      )}
    </Card>
  );
};
