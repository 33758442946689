import { Avatar, Button, IconButton, mergeClasses } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import {
  setExperienceListFilter,
  useExperienceListFilter,
  useMyWorkspaceExperiencesQuery,
} from 'data/experiences';
import { LoadingSpinner } from 'components/loading-spinner';
import { EmptyState } from 'components/empty-state/empty-state.component';
import { Card } from 'components/card.component';
import { ErrorState } from 'components/error-state';
import { ReactComponent as GeeseWithMagnifyingGlass } from 'media/goose-with-magnifying-glass.svg';

import { PageHeadline } from '../../components/page-headline.component';
import { useNavigate } from 'react-router';
import { AppRoutes, WORKSPACE_PATH } from '../../constants/routes';
import { transformExperiencesDataToExperiencesList } from './util';
import { ExperienceList } from './experience-list.component';
import { useMyWorkspacesQuery } from '../../data/workspaces';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch } from '../../data/hooks';

interface MyOrganizationExperiencesListProps {
  workspaceId: string;
}

const MyOrganizationExperiencesList = ({ workspaceId }: MyOrganizationExperiencesListProps) => {
  const { data, isLoading, isError, isFetching } = useMyWorkspaceExperiencesQuery({ workspaceId });
  const { t } = useTranslate('pages.experiences.myOrganizationExperiences');

  const experiences = transformExperiencesDataToExperiencesList(data?.myWorkspaceExperiences ?? []);

  if (isLoading || isFetching) {
    return (
      <Card>
        <div className="flex justify-center py-16 px-8">
          <LoadingSpinner />
        </div>
      </Card>
    );
  }

  if (isError) {
    return (
      <Card>
        <div className="flex justify-center py-16 px-8">
          <ErrorState />
        </div>
      </Card>
    );
  }

  if (!experiences || experiences.length === 0) {
    return (
      <Card className="p-12">
        <EmptyState
          text={t('emptyState.text')}
          subtext={t('emptyState.subtext')}
          renderGraphic={() => <GeeseWithMagnifyingGlass />}
        />
      </Card>
    );
  }

  return <ExperienceList experiences={experiences} />;
};

interface OrganizationInfo {
  id: string;
  displayName: string;
  photoUrl: string | null;
}

const useOrganizationInfo = (workspaceId: string): OrganizationInfo => {
  const dispatch = useAppDispatch();
  const { data, isSuccess } = useMyWorkspacesQuery();
  const filter = useExperienceListFilter();

  const organizationInfo = data?.myWorkspaces.find((workspace) => workspace.id === workspaceId);

  useEffect(() => {
    if (isSuccess && organizationInfo === undefined) {
      // If we've loaded myWorkspaces data and the workspace does not exist in the list
      //   we should set the filter back to its default state.
      // Likely the persisted data is corrupted or the user was removed from the org
      dispatch(setExperienceListFilter({ type: 'personal', value: 'personal' }));
    }
  }, [isSuccess, organizationInfo, dispatch]);

  // If the id matches an org in the list, return that organization
  if (organizationInfo) {
    return organizationInfo;
  }

  // If the filter type is 'organization', we can use the data in the filter
  if (filter.type === 'organization') {
    return {
      id: filter.id,
      displayName: filter.displayName,
      photoUrl: filter.photoUrl,
    };
  }

  // Fallback - Return nullish data
  // We shouldn't fall into this case, but it's here for typing
  return {
    id: '',
    displayName: '',
    photoUrl: null,
  };
};

interface MyOrganizationExperiencesProps {
  workspaceId: string;
}

export const MyOrganizationExperiences = ({ workspaceId }: MyOrganizationExperiencesProps) => {
  const { t } = useTranslate('pages.experiences.myOrganizationExperiences');
  const navigate = useNavigate();
  const organizationInfo = useOrganizationInfo(workspaceId);

  const navigateToCreateNew = () => {
    navigate(AppRoutes.CREATE_EXPERIENCE);
  };

  return (
    <>
      <div className="flex flex-row items-center justify-between w-full mb-4 tablet-narrow:mb-6 flex-col tablet-narrow:flex-row gap-4">
        <div className="flex flex-row items-center w-full min-w-0">
          <Avatar
            placeholder="workspace"
            alt={organizationInfo.displayName}
            size="xs2"
            src={organizationInfo.photoUrl ?? undefined}
            className={mergeClasses('shrink-0 mr-4', {
              'bg-black-12 border border-black-24': !organizationInfo.photoUrl,
            })}
            squared
          />

          <PageHeadline className="truncate leading-[44px]">
            {organizationInfo.displayName.substring(0, 50) ?? ''}
          </PageHeadline>

          <Link to={`${WORKSPACE_PATH}/${organizationInfo.id}`}>
            <IconButton icon="Settings" iconColor="black" />
          </Link>
        </div>
        <Button size="sm" label={t('createExperience')} onClick={navigateToCreateNew} />
      </div>
      <MyOrganizationExperiencesList workspaceId={workspaceId} />
    </>
  );
};
