import { ZodError } from 'zod';

// NOTE: Keep this in sync with apps/api/src/errors.ts
const ErrorCodes = {
  UNAUTHORIZED: 'UNAUTHORIZED',
  NOT_FOUND: 'NOT_FOUND',
  FORBIDDEN: 'FORBIDDEN',
  NOT_ALLOWED: 'NOT_ALLOWED',
  INVALID_INPUT: 'INVALID_INPUT',
  INTERNAL: 'INTERNAL',
  UNPROCESSABLE: 'UNPROCESSABLE',
  REQUEST_EXPIRED: 'REQUEST_EXPIRED',
  INSUFFICIENT_PERMISSIONS: 'INSUFFICIENT_PERMISSIONS',
};

export type ApiErrorCode = keyof typeof ErrorCodes;
export interface ApiError {
  message: string;
  code: ApiErrorCode;
}

export type InvalidInputCode =
  | 'INVALID_INPUT' // Default generic code
  | 'EMAIL_IN_USE';

export interface InvalidInputErrorIssue {
  path: string;
  code: InvalidInputCode;
}

export interface InvalidInputError extends ApiError {
  code: 'INVALID_INPUT';
  issues: InvalidInputErrorIssue[];
}

export interface NotAllowedError extends ApiError {
  code: 'NOT_ALLOWED';
  reason?: string;
}

export interface InternalError extends ApiError {
  code: 'INTERNAL';
}

export interface LogicError extends ApiError {
  code: 'UNPROCESSABLE';
}

export interface RequestExpiredError extends ApiError {
  code: 'REQUEST_EXPIRED';
}

// eslint-disable-next-line
export const isApiError = (e: any): e is ApiError => 'code' in e;
// eslint-disable-next-line
export const isInvalidInputError = (e: any): e is InvalidInputError => e.code === 'INVALID_INPUT';
// eslint-disable-next-line
export const isNotAllowedError = (e: any): e is NotAllowedError => e.code === 'NOT_ALLOWED';
// eslint-disable-next-line
export const isZodError = (e: any): e is ZodError => 'issues' in e;
// eslint-disable-next-line
export const isRequestExpiredError = (e: any): e is RequestExpiredError =>
  e.code === 'REQUEST_EXPIRED';
// eslint-disable-next-line
export const isNotFoundError = (e: any) => e.code === ErrorCodes.NOT_FOUND;
// eslint-disable-next-line
export const isInsufficientPermissionsError = (e: any) =>
  e.code === ErrorCodes.INSUFFICIENT_PERMISSIONS;
