import { Avatar } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import { AppRoutes, EXPERIENCE_PATH } from '../../constants/routes';
import { TeamMode } from '../../data/models';
import { useScreenSize } from '../../hooks/use-screen-size';

import { SubmissionOverviewCard } from './submission-overview-card.component';
import { SubmissionOverviewImage } from './submission-overview-image.component';

interface TeamImageProps {
  imageUrl?: string;
  teamMode: TeamMode;
  alt: string;
}

const TeamImage = ({ imageUrl, teamMode, alt }: TeamImageProps) => {
  const { isDesktop } = useScreenSize();

  if (imageUrl) {
    return <SubmissionOverviewImage src={imageUrl} alt={alt} />;
  }

  return (
    <Avatar
      size={isDesktop ? 'xl5' : 'xl3'}
      placeholder={teamMode === TeamMode.Solo ? 'single' : 'team-duo'}
      alt={alt}
    />
  );
};

export interface TeamSubmissionOverviewCardProps extends Pick<TeamImageProps, 'teamMode'> {
  experienceId: string;
  teamId: string;
  teamName: string;
  totalTeamPoints: number;
  numTeamSubmissions: number;
  teamBonusPoints: number;
  teamImageUrl?: string;
}

export const TeamSubmissionOverviewCard = ({
  experienceId,
  teamId,
  teamName,
  totalTeamPoints,
  numTeamSubmissions,
  teamBonusPoints,
  teamImageUrl,
  teamMode,
}: TeamSubmissionOverviewCardProps) => {
  const { t } = useTranslate('pages.submissions');
  return (
    <SubmissionOverviewCard
      displayName={teamName}
      bonusPoints={teamBonusPoints}
      points={totalTeamPoints}
      numSubmissions={numTeamSubmissions}
      variant="Team"
      href={`${EXPERIENCE_PATH}/${experienceId}/${AppRoutes.SUB_PATHS.TEAM_SUBMISSIONS}/${teamId}`}>
      <TeamImage imageUrl={teamImageUrl} teamMode={teamMode} alt={t('teamPhotoAlt')} />
    </SubmissionOverviewCard>
  );
};
