import { useEffect, useRef } from 'react';

export const useUpdateEffect: typeof useEffect = (effectFn, dependencyList) => {
  const isFirstMount = useRef(true);

  useEffect(() => {
    if (!isFirstMount.current) {
      effectFn();
    } else {
      isFirstMount.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList);

  useEffect(() => {
    // Cleanup function to ensure a pure component
    // Issues were discovered via React.StrictMode's double setup+cleanup functionality
    return () => {
      isFirstMount.current = true;
    };
    // Separate empty useEffect with an empty dependency list to only run when unmounting
  }, []);
};
