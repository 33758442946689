import { mergeClasses, Pill } from '@goosechase/ui';

import { useTranslate } from '../../util/i18n';

export interface PointsPillProps {
  className?: string;
  points: number;
}

export const PointsPill = ({ className, points }: PointsPillProps) => {
  const { t } = useTranslate('pointsPill');

  return (
    <Pill
      className={mergeClasses(
        'w-fit uppercase text-black shrink-0 bg-offWhite border-0 whitespace-nowrap',
        className,
      )}
      label={t('points', { count: points })}
    />
  );
};

export const BonusPointsPill = ({ className, points }: PointsPillProps) => {
  const { t } = useTranslate('pointsPill');

  if (points === 0) {
    return (
      <Pill
        variant="neutral"
        className={mergeClasses('w-fit uppercase shrink-0 whitespace-nowrap', className)}
        label={t('points', { count: points })}
      />
    );
  }

  const isNegative = points < 0;
  const translationKey = isNegative ? 'pointsNegative' : 'pointsPositive';

  return (
    <Pill
      variant={isNegative ? 'negative' : 'positive'}
      className={mergeClasses(
        'w-fit uppercase text-black shrink-0 whitespace-nowrap',
        { 'text-black': !isNegative, 'text-white': isNegative },
        className,
      )}
      label={t(translationKey, { count: Math.abs(points) })}
    />
  );
};

import { MissionType, MissionTypeName } from '../../data/models';

const typeToColorMap: Record<MissionTypeName | MissionType, string> = {
  CameraMission: 'bg-yellowOrange border-yellowOrange',
  TextMission: 'bg-cyan border-cyan',
  GpsMission: 'bg-lilac border-lilac',
  CAMERA: 'bg-yellowOrange border-yellowOrange',
  TEXT: 'bg-cyan border-cyan',
  GPS: 'bg-lilac border-lilac',
};

export interface MissionPointsPillProps {
  className?: string;
  points: number;
  type: MissionTypeName | MissionType;
}

export const MissionPointsPill = ({ className, points, type }: MissionPointsPillProps) => {
  const color = typeToColorMap[type];

  return <PointsPill className={mergeClasses(color, className)} points={points} />;
};
