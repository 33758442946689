// Util functions that return testID prop objects if a testID is passed
// Returning undefined results in no attribute added to the DOM element, maintaining a cleaner DOM
export var getTestIDProp = function (parentTestID, elementTestID) {
    if (parentTestID) {
        if (elementTestID) {
            return { testID: "".concat(parentTestID, "-").concat(elementTestID) };
        }
        return { testID: parentTestID };
    }
    return undefined;
};
// data-testid is the attribute that is required on html elements
export var getDataTestIDProp = function (parentTestID, elementTestID) {
    if (parentTestID) {
        if (elementTestID) {
            return { 'data-testid': "".concat(parentTestID, "-").concat(elementTestID) };
        }
        return { 'data-testid': parentTestID };
    }
    return undefined;
};
