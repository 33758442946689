import { Dropdown, Text } from '@goosechase/ui';
import { Controller, Control, useFormState, UseFormTrigger } from 'react-hook-form';

import type { BroadcastFormInputData } from '../use-broadcast-form';
import { NumberInput } from '../../number-input';
import { useTranslate } from 'util/i18n';
import {
  BROADCAST_TRIGGER_TIME_UNITS,
  DURING_EXPERIENCE_TIME_ANCHORS,
  TriggerTimeAnchor,
} from '../../../data/models';
import { TIME_UNIT_TO_TRANSLATION_KEY_MAP, type BroadcastTriggerTimeUnit } from '../util';
import { TIME_ANCHOR_TO_TRANSLATION_KEY_MAP } from 'util/time-util';
import { SubfieldArrow } from 'components/subfield-arrow';
import { Trans } from 'react-i18next';

interface BroadcastTriggerRelativeFieldsProps {
  control: Control<BroadcastFormInputData>;
  isInFuture: boolean;
  trigger: UseFormTrigger<BroadcastFormInputData>;
}

export const BroadcastTriggerRelativeFields = ({
  control,
  isInFuture,
  trigger,
}: BroadcastTriggerRelativeFieldsProps) => {
  const { t } = useTranslate('broadcastForm.broadcastTrigger');
  const { errors } = useFormState({ control });

  const relativeUnitOptions = BROADCAST_TRIGGER_TIME_UNITS.map((timeUnit) => {
    return {
      id: timeUnit,
      title: t(`relativeUnit.${TIME_UNIT_TO_TRANSLATION_KEY_MAP[timeUnit]}`),
    };
  });

  const relativeAnchorOptions = DURING_EXPERIENCE_TIME_ANCHORS.map((timeAnchor) => {
    return {
      id: timeAnchor,
      title: t(`relativeAnchor.${TIME_ANCHOR_TO_TRANSLATION_KEY_MAP[timeAnchor]}`),
    };
  });

  return (
    <div className="flex flex-col gap-2 w-full mb-3">
      <div className="flex gap-2 items-center w-full">
        <SubfieldArrow />
        <Controller
          control={control}
          name={`relativeDuration`}
          render={({
            field: { name, value, onChange: onChangeDuration, onBlur: onBlurDuration },
            fieldState: { error },
          }) => (
            <NumberInput
              label=""
              placeholder={t('relativeDuration.placeholder') ?? undefined}
              name={name}
              onBlur={() => {
                onBlurDuration();
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                trigger();
              }}
              onChange={(val) => {
                onChangeDuration(val);
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                trigger();
              }}
              value={value ?? null}
              min={1}
              hideSpinButtons
              containerClassName="flex-1"
              error={Boolean(error) || Boolean(errors.relativeDuration?.message)}
            />
          )}
        />
        <Controller
          name={`relativeUnit`}
          control={control}
          render={({ field: { onChange: onChangeUnit, value } }) => {
            const selectedDropdownOption = {
              id: value,
              title: t(
                `relativeUnit.${
                  TIME_UNIT_TO_TRANSLATION_KEY_MAP[value as BroadcastTriggerTimeUnit]
                }`,
              ),
            };

            return (
              <Dropdown
                buttonClassName="w-full"
                label={selectedDropdownOption.title}
                options={relativeUnitOptions}
                selectedOptionId={value ?? undefined}
                onSelect={(val: string) => {
                  onChangeUnit(val);
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  trigger();
                }}
                containerClassName="flex-1"
              />
            );
          }}
        />
      </div>
      <div className="pl-12 w-full">
        <Controller
          name={`relativeAnchor`}
          control={control}
          render={({ field: { onChange: onChangeAnchor, value } }) => {
            const selectedDropdownOption = {
              id: value,
              title: t(
                `relativeAnchor.${TIME_ANCHOR_TO_TRANSLATION_KEY_MAP[value as TriggerTimeAnchor]}`,
              ),
            };

            return (
              <Dropdown
                containerClassName="w-full"
                buttonClassName="w-full"
                label={selectedDropdownOption.title}
                options={relativeAnchorOptions}
                selectedOptionId={value ?? undefined}
                onSelect={(val: string) => {
                  onChangeAnchor(val);
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  trigger();
                }}
              />
            );
          }}
        />
      </div>

      {errors.relativeAnchor?.message ||
      errors.relativeDuration?.message ||
      errors.relativeUnit?.message ? (
        <div className="bg-vibrantRed-10 border border-vibrantRed rounded-lg p-4 ml-12">
          <Text size="md">
            <span
              dangerouslySetInnerHTML={{
                __html:
                  errors.relativeAnchor?.message ||
                  errors.relativeDuration?.message ||
                  errors.relativeUnit?.message ||
                  '',
              }}
            />
          </Text>
        </div>
      ) : isInFuture ? null : (
        <div className="bg-yellowOrange-10 border border-yellowOrange rounded-lg p-4 ml-12">
          <Trans>{t('warnings.past')}</Trans>
        </div>
      )}
    </div>
  );
};
