import type { TooltipProps } from 'recharts';
import { useTranslate } from 'util/i18n';

export const SubmissionPopularityTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  const { t } = useTranslate('pages.stats.submissionPopularity');

  if (active && payload && payload.length) {
    return (
      <div className="text-left bg-white border p-2">
        <p>{`${t('mission')}: ${payload[0].payload.missionName}`}</p>
        <p>{`${t('submittedBy')}: ${payload[0].payload.teamName}`}</p>
        <p>{`${t('likes')}: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};
