import { AppRoutes } from 'constants/routes';
import { useNavigate } from 'react-router';
import { Nullable } from 'types/util';
import { DepartmentAnnualPlan } from './department-annual-plan';
import { EnterprisePlan } from './enterprise-plan';
import { NotForProfitPlan } from './not-for-profit-plan';
import { RecreationalPlan } from './recreational-plan';

type OrganizationPlansProps = {
  plans: {
    priceUsdCents: number;
    id: string;
    chargebeeSku: Nullable<string>;
  }[];
  currentPlan?: {
    id: string;
    chargebeeSku: Nullable<string>;
  };
  onClick: (planKey: string) => () => void;
};

export const OrganizationPlans = (props: OrganizationPlansProps) => {
  const navigate = useNavigate();

  const goToQuoteForm = () => {
    const quoteForm = document.querySelector('#quote');
    quoteForm?.scrollIntoView({ behavior: 'smooth' });
  };

  const goToNewExperienceForm = () => navigate(AppRoutes.CREATE_EXPERIENCE);

  const departmentAnnualPlan = props.plans?.find((plan) => plan.id === 'department-wide-ecommerce');

  return (
    <>
      <RecreationalPlan
        priceUsdCents={0}
        selected={['recreational-5', 'recreational-org', 'recreational-solo'].includes(
          props.currentPlan?.id ?? '',
        )}
        onClick={goToNewExperienceForm}
      />
      <DepartmentAnnualPlan
        priceUsdCents={departmentAnnualPlan?.priceUsdCents ?? 0}
        selected={['department-wide-ecommerce', 'department-wide-direct'].includes(
          props.currentPlan?.id ?? '',
        )}
        onClick={props.onClick('department-wide-ecommerce')}
      />
      <EnterprisePlan selected={props.currentPlan?.id === 'enterprise'} onClick={goToQuoteForm} />
      <NotForProfitPlan selected={props.currentPlan?.id === 'npo'} onClick={goToQuoteForm} />
    </>
  );
};
