import { useDispatch } from 'react-redux';

import { useTranslate } from 'util/i18n';
import { MissionCard } from '../../components/mission-card';
import { selectMission } from '../../data/missions';
import type { MissionUnion } from '../../data/models';
import { DistributiveOmit } from '../../global';
import { getAdditionalMissionInfo } from '../../components/information-tags/information-tags.util';
import { Nullable } from 'types/util';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';

type EditableMissionCardProps = {
  className?: string;
  isSelected: boolean;
  dragEnabled: boolean;
  isDragging: boolean;
  cohortStartDate?: Nullable<string>;
  cohortEndDate?: Nullable<string>;
} & DistributiveOmit<MissionUnion, 'submissions' | 'myTeamSubmission' | 'experience'>;

export const EditableMissionCard = ({
  className,
  isSelected,
  dragEnabled,
  isDragging,
  cohortEndDate,
  cohortStartDate,
  ...props
}: EditableMissionCardProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslate('missionInformationTags');
  const { infoTags, secondaryInfoTags, infoTitle } = getAdditionalMissionInfo(t, props);
  const experienceInfo = useExperienceInfo();
  const experienceTimezone = experienceInfo.data?.experience?.timezone ?? 'UTC';
  const experienceStatus = experienceInfo.data?.experience?.status;

  const handleEdit = () => {
    dispatch(selectMission(props));
  };

  return (
    <>
      <MissionCard
        className={className}
        isSelected={isSelected}
        id={props.id}
        type={props.__typename}
        title={props.displayName}
        description={props.description}
        points={props.points}
        numSubmissions={props.numSubmissions}
        missionStatus={props.status}
        infoTitle={infoTitle}
        infoTags={infoTags}
        secondaryInfoTags={secondaryInfoTags}
        link={props.link ?? undefined}
        image={props.image ?? undefined}
        releaseTrigger={props.releaseTrigger ?? undefined}
        onEdit={handleEdit}
        dragEnabled={dragEnabled}
        isDragging={isDragging}
        cohortStartDate={cohortStartDate}
        cohortEndDate={cohortEndDate}
        expireTrigger={props.expireTrigger ?? undefined}
        expireAt={props.expireAt ?? undefined}
        releaseAt={props.releaseAt ?? undefined}
        draft={props.draft}
        experienceTimezone={experienceTimezone}
        experienceStatus={experienceStatus}
      />
    </>
  );
};
