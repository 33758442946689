import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { useTranslate } from 'util/i18n';
import { Button, Checkbox, NotificationBanner, Text } from '@goosechase/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { LabelledField } from 'components/labelled-field.component';

export interface SubmissionData {
  email: string;
  password: string;
  marketingEmailsAccepted: boolean;
}

export interface ServerErrors {
  general?: string;
  email?: string;
  password?: string;
}

export interface SignUpFormUIProps {
  loading: boolean;
  serverErrors?: ServerErrors;
  canSubmit: boolean;
  onSubmit: (data: SubmissionData) => void;
  clearServerErrors: () => void;
}

export const SignUpFormUI = ({
  loading,
  serverErrors,
  canSubmit,
  onSubmit,
  clearServerErrors,
}: SignUpFormUIProps) => {
  const { t } = useTranslate('pages.signUp.signUpForm');
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: '',
      marketingEmailsAccepted: false,
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .min(1, { message: t('email.errors.required') as string })
          .email({ message: t('email.errors.invalid') as string }),
        password: z
          .string()
          .min(1, { message: t('password.errors.required') as string })
          .min(8, { message: t('password.errors.minLength') as string }),
        marketingEmailsAccepted: z.boolean(),
      }),
    ),
  });

  const clearServerErrorIfPresent = (error?: string) => {
    if (error) {
      clearServerErrors();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6 desktop:mb-8">
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur }, fieldState: { error } }) => (
            <LabelledField
              label={t('email.label')}
              type="text"
              onChange={(input) => {
                clearServerErrorIfPresent(serverErrors?.email);
                onChange(input);
              }}
              onBlur={onBlur}
              errorMessage={error?.message || serverErrors?.email}
              autoComplete="email"
              disabled={loading}
            />
          )}
        />
      </div>
      <div className="mb-6 desktop:mb-8">
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, onBlur }, fieldState: { error } }) => (
            <LabelledField
              label={t('password.label')}
              type="password"
              onChange={(input) => {
                clearServerErrorIfPresent(serverErrors?.password);
                onChange(input);
              }}
              onBlur={onBlur}
              errorMessage={error?.message || serverErrors?.password}
              autoComplete="new-password"
              disabled={loading}
            />
          )}
        />
        <Text size="sm" className="mt-2.5 text-black-64">
          {t('passwordDisclaimer')}
        </Text>
      </div>
      <div className="mb-8 desktop:mb-12">
        <Controller
          control={control}
          name="marketingEmailsAccepted"
          render={({ field: { onChange } }) => (
            <label className="flex items-start">
              <Checkbox onChange={onChange} disabled={loading} />
              <Text className="ml-2">{t('marketingAgreement')}</Text>
            </label>
          )}
        />
      </div>
      {serverErrors?.general && (
        <NotificationBanner
          className="mb-6 desktop:mb-8"
          type="error"
          title={t('generalErrorTitle') as string}
          body={serverErrors.general}
          showIcon
        />
      )}
      <Button
        className="w-full tablet-narrow:w-fit"
        label={t('submit')}
        disabled={!isValid || !canSubmit || loading}
      />
    </form>
  );
};
