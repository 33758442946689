var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Participants = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M16.7457 19.25V17.4722C16.7457 16.5292 16.3711 15.6249 15.7043 14.9581C15.0375 14.2913 14.1331 13.9167 13.1901 13.9167H6.07899C5.136 13.9167 4.23163 14.2913 3.56484 14.9581C2.89804 15.6249 2.52344 16.5292 2.52344 17.4722V19.25", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M9.63368 10.3611C11.5974 10.3611 13.1892 8.76923 13.1892 6.80555C13.1892 4.84188 11.5974 3.25 9.63368 3.25C7.67 3.25 6.07812 4.84188 6.07812 6.80555C6.07812 8.76923 7.67 10.3611 9.63368 10.3611Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M22.0788 19.25V17.4722C22.0782 16.6844 21.816 15.9191 21.3333 15.2965C20.8507 14.6739 20.1749 14.2292 19.4121 14.0322", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M15.8574 3.3656C16.6222 3.56142 17.3001 4.00622 17.7842 4.62988C18.2683 5.25353 18.5311 6.02056 18.5311 6.81004C18.5311 7.59953 18.2683 8.36656 17.7842 8.99021C17.3001 9.61386 16.6222 10.0587 15.8574 10.2545", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
