import { Label, mergeClasses, Text } from '@goosechase/ui';

export interface SelectFieldProps {
  options: readonly string[];
  value?: string;
  name: string;
  onSelect: (value: string) => void;
  onBlur?: (e: React.ChangeEvent) => void;
  label?: string | null;
  getOptionLabel: (option: string) => string;
  className?: string;
  errorMessage?: string;
  allowEmptyOption?: boolean;
}

const BASE_STYLES =
  'flex p-4 border overflow-hidden border-black-24 focus-within:border-black rounded-lg outline-0 focus-visible:outline-none text-ellipsis w-full placeholder:text-black/[.76] focus:placeholder:text-black-64 caret-black bg-inherit';

export const SelectField = ({
  name,
  options,
  value,
  onSelect,
  label,
  getOptionLabel,
  className,
  allowEmptyOption,
  errorMessage,
  ...htmlSelectProps
}: SelectFieldProps) => {
  return (
    <>
      {label ? (
        <Label size="sm" className="block mb-2" htmlFor={name}>
          {label.toUpperCase()}
        </Label>
      ) : null}
      <select
        id={name}
        name={name}
        value={value}
        onChange={(e) => onSelect(e.target.value)}
        className={mergeClasses(BASE_STYLES, className)}
        {...htmlSelectProps}>
        {allowEmptyOption ? <option value=""></option> : null}
        {options.map((opt) => (
          <option key={opt} value={opt}>
            {getOptionLabel(opt)}
          </option>
        ))}
      </select>
      {errorMessage && (
        <Text size="xs" className="mt-2 text-vibrantRed">
          {errorMessage}
        </Text>
      )}
    </>
  );
};
