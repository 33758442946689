import { api as createEducatorWorkspaceApi } from './graphql/__generated__/create-educator-workspace.mutation.generated';
import { api as _activeWorkspaceWithSubscriptionApi } from './graphql/__generated__/active-workspace-with-subscription.query.generated';
import { api as _activeWorkspaceApi } from './graphql/__generated__/active-workspace.query.generated';
import { api as availablePlansApi } from './graphql/__generated__/available-plans.query.generated';
import { api as startPlanCheckoutApi } from './graphql/__generated__/start-plan-checkout.mutation.generated';
import { api as requestQuoteApi } from './graphql/__generated__/request-quote.mutation.generated';
import { api as getBillingDetailsRedirectApi } from './graphql/__generated__/get-billing-details-redirect.mutation.generated';
import { api as experienceWorkspaceApi } from './graphql/__generated__/experience-workspace.query.generated';
import { api as _updateWorkspaceApi } from './graphql/__generated__/update-workspace.mutation.generated';
import { api as _workspaceMembersApi } from './graphql/__generated__/workspace-members.query.generated';
import { api as _updateWorkspaceMemberMutationApi } from './graphql/__generated__/update-workspace-member.mutation.generated';
import { api as _deleteWorkspaceMemberMutationApi } from './graphql/__generated__/delete-workspace-member.mutation.generated';
import { api as _pendingWorkspaceInvitationsApi } from './graphql/__generated__/pending-workspace-invitations.query.generated';
import { api as _createWorkspaceInvitationApi } from './graphql/__generated__/create-workspace-invitation.mutation.generated';
import { api as _updateWorkspaceInvitationApi } from './graphql/__generated__/update-workspace-invitation.mutation.generated';
import { api as _deleteWorkspaceInvitationApi } from './graphql/__generated__/delete-workspace-invitation.mutation.generated';
import { api as _workspaceInvitationApi } from './graphql/__generated__/workspace-invitation.query.generated';
import { api as _acceptWorkspaceInvitationApi } from './graphql/__generated__/accept-workspace-invitation.mutation.generated';
import { api as _completePlanCheckoutApi } from './graphql/__generated__/complete-plan-checkout.mutation.generated';
import { api as _myOwnedWorkspacesApi } from './graphql/__generated__/my-owned-workspaces.query.generated';
import { api as upcomingBillingApi } from './graphql/__generated__/upcoming-billing.query.generated';
import { api as _myWorkspacesApi } from './graphql/__generated__/my-workspaces.query.generated';
import { api as _workspaceApi } from './graphql/__generated__/workspace.query.generated';

export {
  createEducatorWorkspaceApi,
  availablePlansApi,
  startPlanCheckoutApi,
  requestQuoteApi,
  getBillingDetailsRedirectApi,
  experienceWorkspaceApi,
  upcomingBillingApi,
};

export const activeWorkspaceWithSubscriptionApi =
  _activeWorkspaceWithSubscriptionApi.enhanceEndpoints({
    endpoints: {
      ActiveWorkspaceWithSubscription: { providesTags: ['ActiveWorkspaceWithSubscription'] },
    },
  });

export const workspaceApi = _workspaceApi.enhanceEndpoints({
  endpoints: {
    Workspace: { providesTags: ['Workspace'] },
  },
});

export const activeWorkspaceApi = _activeWorkspaceApi.enhanceEndpoints({
  endpoints: { ActiveWorkspace: { providesTags: ['ActiveWorkspace'] } },
});

export const updateWorkspaceApi = _updateWorkspaceApi.enhanceEndpoints({
  endpoints: {
    UpdateWorkspace: {
      invalidatesTags: [
        'ActiveWorkspace',
        'ActiveWorkspaceWithSubscription',
        'MyWorkspaces',
        'Workspace',
      ],
    },
  },
});

export const workspaceMembersApi = _workspaceMembersApi.enhanceEndpoints({
  endpoints: { WorkspaceMembers: { providesTags: ['WorkspaceMembers'] } },
});

export const updateWorkspaceMemberMutationApi = _updateWorkspaceMemberMutationApi.enhanceEndpoints({
  endpoints: { UpdateWorkspaceMember: { invalidatesTags: ['WorkspaceMembers'] } },
});

export const deleteWorkspaceMemberMutationApi = _deleteWorkspaceMemberMutationApi.enhanceEndpoints({
  endpoints: {
    DeleteWorkspaceMember: {
      invalidatesTags: [
        'WorkspaceMembers',
        'ActiveWorkspace',
        'ActiveWorkspaceWithSubscription',
        'Workspace',
      ],
    },
  },
});

export const pendingWorkspaceInvitationsApi = _pendingWorkspaceInvitationsApi.enhanceEndpoints({
  endpoints: { PendingWorkspaceInvitations: { providesTags: ['PendingWorkspaceInvitations'] } },
});

export const createWorkspaceInvitationApi = _createWorkspaceInvitationApi.enhanceEndpoints({
  endpoints: { CreateWorkspaceInvitation: { invalidatesTags: ['PendingWorkspaceInvitations'] } },
});

export const updateWorkspaceInvitationApi = _updateWorkspaceInvitationApi.enhanceEndpoints({
  endpoints: { UpdateWorkspaceInvitation: { invalidatesTags: ['PendingWorkspaceInvitations'] } },
});

export const deleteWorkspaceInvitationApi = _deleteWorkspaceInvitationApi.enhanceEndpoints({
  endpoints: { DeleteWorkspaceInvitation: { invalidatesTags: ['PendingWorkspaceInvitations'] } },
});

export const workspaceInvitationApi = _workspaceInvitationApi;

export const acceptWorkspaceInvitationApi = _acceptWorkspaceInvitationApi.enhanceEndpoints({
  endpoints: {
    AcceptWorkspaceInvitation: {
      invalidatesTags: [
        'ActiveWorkspace',
        'ActiveWorkspaceWithSubscription',
        'MyWorkspaces',
        'Workspace',
      ],
    },
  },
});

export const completePlanCheckoutApi = _completePlanCheckoutApi.enhanceEndpoints({
  endpoints: {
    CompletePlanCheckout: {
      invalidatesTags: [
        'ActiveWorkspace',
        'ActiveWorkspaceWithSubscription',
        'Workspace',
        'MyWorkspaces',
      ],
    },
  },
});

export const myOwnedWorkspacesApi = _myOwnedWorkspacesApi.enhanceEndpoints({
  endpoints: {
    MyOwnedWorkspaces: {
      providesTags: ['MyOwnedWorkspaces'],
    },
  },
});

export const myWorkspacesApi = _myWorkspacesApi.enhanceEndpoints({
  endpoints: {
    MyWorkspaces: {
      providesTags: ['MyWorkspaces'],
    },
  },
});
