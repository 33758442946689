import { Label, Text, Toggle, mergeClasses } from '@goosechase/ui';
import { HelpCircle } from 'components/help-circle.component';
import { Control, Controller, useWatch } from 'react-hook-form';
import { useTranslate } from 'util/i18n';

import { MissionFormInputData } from './use-mission-form';

type DraftModeProps = {
  control: Control<MissionFormInputData>;
};

export const DraftMode = ({ control }: DraftModeProps) => {
  const { t } = useTranslate('missionForm.draftMode');
  const draft = useWatch({ control, name: 'draft' });

  return (
    <div
      className={mergeClasses(
        'border border-black-24 rounded-md p-4 flex gap-2 relative justify-between items-center',
        {
          'border-2 border-vibrantBlue bg-vibrantBlue-10': draft,
        },
      )}>
      <div>
        <div className="flex gap-1 mb-1">
          <Label size="lg" className="uppercase">
            {t('label')}
          </Label>
          <HelpCircle id="draft-mode" tooltipText={t('tooltip')} />
        </div>
        <Text size="sm">{t('hint')}</Text>
      </div>
      <Controller
        name="draft"
        control={control}
        render={({ field: { name, onChange, value } }) => (
          <Toggle name={name} onChange={onChange} checked={value} label={t('label') ?? undefined} />
        )}
      />
    </div>
  );
};
