import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { DistributiveOmit } from '../../global';
import { MissionUnion, MissionType } from '../models';

type EditableMission = DistributiveOmit<
  MissionUnion,
  'submissions' | 'myTeamSubmission' | 'releaseAt' | 'expireAt' | 'experience'
>;

export interface MissionsState {
  selectedMission: EditableMission | null;
  initialMissionType: MissionType;
  missionPanelOpen: boolean;
}

const DEFAULT_MISSION_TYPE = 'CAMERA';

const initialState: MissionsState = {
  selectedMission: null,
  initialMissionType: DEFAULT_MISSION_TYPE,
  missionPanelOpen: false,
};

export const missionsSlice = createSlice({
  name: 'missions',
  initialState,
  reducers: {
    selectMission: (state, action: PayloadAction<EditableMission>) => {
      state.selectedMission = action.payload;
      state.missionPanelOpen = true;
    },
    closeAndResetMissionPanel: (state) => {
      state.selectedMission = null;
      state.missionPanelOpen = false;
    },
    openCreateMissionPanel: (state, action: PayloadAction<MissionType>) => {
      state.initialMissionType = action.payload;
      state.missionPanelOpen = true;
    },
  },
});
