import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Icon, Text, mergeClasses } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import { useSaveMission } from 'hooks/use-save-mission.hook';
import { useDeleteMissionMutation } from 'data/missions/graphql/__generated__/delete-mission.mutation.generated';
import { DeleteConfirmationModal } from './delete-confirmation-modal';
import { useState } from 'react';
import { displayToast } from 'services/toast';
import { Trans } from 'react-i18next';

interface DropdownOptionProps {
  label: string;
  onClick: () => void;
  className?: string;
}

const DropdownOption = ({ label, onClick, className }: DropdownOptionProps) => {
  return (
    <MenuItem>
      {({ close }) => {
        const handleOnClick: React.HTMLAttributes<HTMLButtonElement>['onClick'] = (e) => {
          e.preventDefault();
          onClick();
          close();
        };

        return (
          <button
            className={mergeClasses(
              'w-full outline-0 pt-2 pb-2 text-left hover:bg-black-4 ui-active:bg-black-4 flex justify-start items-center',
              className,
            )}
            onClick={handleOnClick}>
            <Text className="px-3 py-1 pl-2 whitespace-nowrap font-semibold">{label}</Text>
          </button>
        );
      }}
    </MenuItem>
  );
};

export interface MissionDropdownProps {
  missionId: string;
  displayName: string;
  numSubmissions: number;
}

export const MissionDropdown = ({
  missionId,
  displayName,
  numSubmissions,
}: MissionDropdownProps) => {
  const { t } = useTranslate('missionCard');
  const saveMission = useSaveMission();
  const [deleteMission] = useDeleteMissionMutation();
  const [showModal, setShowModal] = useState(false);

  const handleSave = async () => {
    await saveMission({ missionId: missionId, displayName: displayName });
  };

  const handleDelete = async () => {
    try {
      await deleteMission({ id: missionId }).unwrap();
      displayToast({
        id: missionId,
        type: 'success',
        title: t('deleteSuccessToast.title'),
        body: (
          <Trans
            t={t}
            i18nKey="deleteSuccessToast.body"
            values={{
              missionName: displayName,
            }}
          />
        ),
      });
    } catch {
      // TODO: Sentry error
      displayToast({
        id: missionId,
        type: 'error',
        title: t('deleteErrorToast.title'),
        body: t('deleteErrorToast.body'),
      });
    }
  };

  return (
    <>
      <Menu
        as="div"
        className="absolute top-3.5 right-5"
        onClick={(event) => {
          event.stopPropagation();
        }}>
        <MenuButton
          aria-label={t('ariaIconLabel') ?? ''}
          className="group flex items-center justify-center rounded-full h-6 w-6 shrink-0 hover:bg-black-12">
          <Icon
            name="More"
            color="black"
            className="group-hover:fill-black group-hover:stroke-black"
            size={24}
          />
        </MenuButton>
        <MenuItems className="absolute top-6 right-0 cursor-pointer bg-white border border-black-24 rounded-lg outline-0 overflow-hidden z-10 ">
          <DropdownOption label={t('save')} onClick={handleSave} />
          <DropdownOption
            label={t('delete')}
            onClick={() => setShowModal(true)}
            className="text-vibrantRed"
          />
        </MenuItems>
      </Menu>
      <DeleteConfirmationModal
        show={showModal}
        onSubmit={handleDelete}
        onClose={() => setShowModal(false)}
        numSubmissions={numSubmissions}
      />
    </>
  );
};
