/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type AllUseCasesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AllUseCasesQuery = { __typename: 'Query', useCases: Array<{ __typename: 'UseCase', id: string }> };


export const AllUseCasesDocument = `
    query AllUseCases {
  useCases {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    AllUseCases: build.query<AllUseCasesQuery, AllUseCasesQueryVariables | void>({
      query: (variables) => ({ document: AllUseCasesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAllUseCasesQuery, useLazyAllUseCasesQuery } = injectedRtkApi;

