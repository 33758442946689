import { SubmissionCard, SubmissionCardProps } from '../submission-card';
import { SubmissionsEmptyState } from '../submissions-empty-states.component';
import { DeleteSubmissionModal } from '../delete-submission-modal/delete-submission-modal.component';
import { useDeleteSubmissionModal } from '../../hooks/use-delete-submission-modal.hook';
import { SubmissionGridContainer } from './submission-grid-container.component';
import { FocusedSubmission } from '../submission-focused-view';

export type Submission = Omit<
  SubmissionCardProps & FocusedSubmission,
  'experienceId' | 'onClick' | 'onDelete' | 'onBonus'
>;

export interface SubmissionGridProps {
  experienceId: SubmissionCardProps['experienceId'];
  submissions: Submission[];
  onSubmissionClick: SubmissionCardProps['onClick'];
  onSubmissionBonus: SubmissionCardProps['onBonus'];
}

export const SubmissionGrid = ({
  experienceId,
  submissions,
  onSubmissionClick,
  onSubmissionBonus,
}: SubmissionGridProps) => {
  const { show, submissionId, missionName, onClose, onShow } = useDeleteSubmissionModal();

  if (submissions.length === 0) {
    return <SubmissionsEmptyState />;
  }

  return (
    <>
      <SubmissionGridContainer>
        {submissions.map((submission) => (
          <SubmissionCard
            key={submission.submissionId}
            experienceId={experienceId}
            onClick={onSubmissionClick}
            onDelete={() => onShow(submission.submissionId, submission.missionName)}
            onBonus={onSubmissionBonus}
            {...submission}
          />
        ))}
      </SubmissionGridContainer>
      <DeleteSubmissionModal
        show={show}
        submissionId={submissionId}
        missionName={missionName}
        onClose={onClose}
      />
    </>
  );
};
