import {
  api as _myExperiencesApi,
  type MyExperiencesQuery,
} from './graphql/__generated__/my-experiences.query.generated';
import {
  api as _experienceFormDetailsApi,
  type ExperienceFormDetailsQuery,
} from './graphql/__generated__/experience-form-details.query.generated';
import { api as _updateExperienceApi } from './graphql/__generated__/update-experience.mutation.generated';
import { api as _createExperienceApi } from './graphql/__generated__/create-experience.mutation.generated';
import { api as _deleteExperienceApi } from './graphql/__generated__/delete-experience.mutation.generated';
import { api as _duplicateExperienceApi } from './graphql/__generated__/duplicate-experience.mutation.generated';
import {
  api as _loadExperienceAddonDetailsApi,
  type LoadExperienceAddonDetailsQuery,
} from './graphql/__generated__/load-experience-addon-details.query.generated';
import {
  api as _experienceInfoApi,
  type ExperienceInfoQuery,
} from './graphql/__generated__/experience-info.query.generated';
import { api as _startCohortApi } from './graphql/__generated__/start-cohort.mutation.generated';
import { api as _endCohortApi } from './graphql/__generated__/end-cohort.mutation.generated';
import {
  api as _experienceStartAndEndApi,
  type LoadExperienceStartAndEndQuery,
} from './graphql/__generated__/load-experience-start-and-end.query.generated';
import { api as _scheduleExperienceApi } from './graphql/__generated__/schedule-experience.mutation.generated';
import { api as _resetExperienceApi } from './graphql/__generated__/reset-experience.mutation.generated';
import { api as _updateCohortEndApi } from './graphql/__generated__/update-cohort-end.mutation.generated';
import { api as _experienceFormBrandingApi } from './graphql/__generated__/experience-form-branding.query.generated';
import { api as _workspaceExperiencesApi } from './graphql/__generated__/workspace-experiences.query.generated';
import {
  api as _experienceManagersApi,
  type ExperienceManagersQuery,
} from './graphql/__generated__/experience-managers.query.generated';
import { api as _inviteExperienceManagerApi } from './graphql/__generated__/invite-experience-manager.mutation.generated';
import { api as _pendingExperienceInvitationsApi } from './graphql/__generated__/pending-experience-invitations.query.generated';
import { api as _deleteExperienceInvitationApi } from './graphql/__generated__/delete-experience-invitation.mutation.generated';
import { api as _deleteExperienceManagerApi } from './graphql/__generated__/delete-experience-manager.mutation.generated';
import { api as _resendExperienceInvitationApi } from './graphql/__generated__/resend-experience-invitation.mutation.generated';
export { api as experienceInvitationApi } from './graphql/__generated__/experience-invitation.query.generated';
import { api as _acceptExperienceInvitationApi } from './graphql/__generated__/accept-experience-invitation.mutation.generated';
import { api as _leaveExperienceAsManagerApi } from './graphql/__generated__/leave-experience-as-manager.mutation.generated';
import { api as _statisticsApi } from './graphql/__generated__/statistics.query.generated';
import { api as _shareExperienceApi } from './graphql/__generated__/share-experience.mutation.generated';
import { api as _unshareExperienceApi } from './graphql/__generated__/unshare-experience.mutation.generated';
export { api as sharedExperienceApi } from './graphql/__generated__/shared-experience.query.generated';
import { api as _copySharedExperienceApi } from './graphql/__generated__/copy-shared-experience.mutation.generated';
import { api as _submissionInfoStatsPaginatedApi } from './graphql/__generated__/submission-info-stats-paginated.query.generated';
import { api as _submissionInfoStatsApi } from './graphql/__generated__/submission-info-stats.query.generated';
import { api as _myPersonalExperiencesApi } from './graphql/__generated__/my-personal-experiences.query.generated';
import { api as _mySharedExperiencesApi } from './graphql/__generated__/my-shared-experiences.query.generated';
import { api as _myWorkspaceExperiencesApi } from './graphql/__generated__/my-workspace-experiences.query.generated';
import { type ExperienceFragmentFragment } from './graphql/__generated__/experience.fragment.generated';

export {
  MyExperiencesQuery,
  ExperienceFormDetailsQuery,
  ExperienceInfoQuery,
  LoadExperienceStartAndEndQuery,
  ExperienceManagersQuery,
  LoadExperienceAddonDetailsQuery,
  ExperienceFragmentFragment,
};

export const myExperiencesApi = _myExperiencesApi.enhanceEndpoints({
  endpoints: {
    MyExperiences: {
      providesTags: ['MyExperiences'],
    },
  },
});

export const myPersonalExperiencesApi = _myPersonalExperiencesApi.enhanceEndpoints({
  endpoints: {
    MyPersonalExperiences: {
      providesTags: ['MyPersonalExperiences'],
    },
  },
});

export const mySharedExperiencesApi = _mySharedExperiencesApi.enhanceEndpoints({
  endpoints: {
    MySharedExperiences: {
      providesTags: ['MySharedExperiences'],
    },
  },
});

export const myWorkspaceExperiencesApi = _myWorkspaceExperiencesApi.enhanceEndpoints({
  endpoints: {
    MyWorkspaceExperiences: {
      providesTags: ['MyWorkspaceExperiences'],
    },
  },
});

export const experienceFormDetailsApi = _experienceFormDetailsApi.enhanceEndpoints({
  endpoints: {
    ExperienceFormDetails: {
      providesTags: ['ExperienceFormDetails'],
    },
  },
});

export const updateExperienceApi = _updateExperienceApi.enhanceEndpoints({
  endpoints: {
    UpdateExperience: {
      invalidatesTags: [
        'MyExperiences',
        'ExperienceFormDetails',
        'ExperienceInfo',
        'ExperienceMissions',
        'ExperienceFormBranding',
        'MyPersonalExperiences',
        'MySharedExperiences',
        'MyWorkspaceExperiences',
      ],
    },
  },
});

export const createExperienceApi = _createExperienceApi.enhanceEndpoints({
  endpoints: {
    CreateExperience: {
      invalidatesTags: ['MyExperiences', 'MyPersonalExperiences', 'MyWorkspaceExperiences'],
    },
  },
});

export const deleteExperienceApi = _deleteExperienceApi.enhanceEndpoints({
  endpoints: {
    DeleteExperience: {
      async onQueryStarted({ id: deletedId }, { dispatch, queryFulfilled }) {
        dispatch(
          myExperiencesApi.util.updateQueryData('MyExperiences', undefined, (draft) => {
            if (draft && draft.myManagedExperiences) {
              return {
                ...draft,
                myManagedExperiences: draft.myManagedExperiences.filter(
                  ({ id }) => id !== deletedId,
                ),
              };
            }
            return draft;
          }),
        );
        dispatch(
          myPersonalExperiencesApi.util.updateQueryData(
            'MyPersonalExperiences',
            undefined,
            (draft) => {
              if (draft && draft.myPersonalExperiences) {
                return {
                  ...draft,
                  myPersonalExperiences: draft.myPersonalExperiences.filter(
                    ({ id }) => id !== deletedId,
                  ),
                };
              }
              return draft;
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          dispatch(
            myExperiencesApi.util.invalidateTags(['MyExperiences', 'MyPersonalExperiences']),
          );
        }
      },
      invalidatesTags: ['MyWorkspaceExperiences'],
    },
  },
});

export const duplicateExperienceApi = _duplicateExperienceApi.enhanceEndpoints({
  endpoints: {
    DuplicateExperience: {
      invalidatesTags: ['MyExperiences', 'MyPersonalExperiences'],
    },
  },
});

export const loadExperienceAddonDetailsApi = _loadExperienceAddonDetailsApi.enhanceEndpoints({
  endpoints: {
    LoadExperienceAddonDetails: {
      providesTags: ['ExperienceAddon'],
    },
  },
});

export const experienceInfoApi = _experienceInfoApi.enhanceEndpoints({
  endpoints: {
    ExperienceInfo: {
      providesTags: ['ExperienceInfo'],
    },
  },
});

export const startCohortApi = _startCohortApi.enhanceEndpoints({
  endpoints: {
    StartCohort: {
      invalidatesTags: [
        'MyExperiences',
        'ExperienceInfo',
        'ExperienceTriggers',
        'Broadcasts',
        'ExperienceMissions',
        'MissionTriggers',
        'WorkspaceExperiences',
        'MyPersonalExperiences',
        'MySharedExperiences',
        'MyWorkspaceExperiences',
      ],
    },
  },
});

export const endCohortApi = _endCohortApi.enhanceEndpoints({
  endpoints: {
    EndCohort: {
      invalidatesTags: [
        'MyExperiences',
        'ExperienceInfo',
        'ExperienceTriggers',
        'Broadcasts',
        'ExperienceMissions',
        'MissionTriggers',
        'WorkspaceExperiences',
        'MyPersonalExperiences',
        'MySharedExperiences',
        'MyWorkspaceExperiences',
      ],
    },
  },
});

export const workspaceExperiencesApi = _workspaceExperiencesApi.enhanceEndpoints({
  endpoints: {
    WorkspaceExperiences: {
      providesTags: ['WorkspaceExperiences'],
    },
  },
});

export const experienceStartAndEndApi = _experienceStartAndEndApi.enhanceEndpoints({
  endpoints: {
    LoadExperienceStartAndEnd: {
      providesTags: ['ExperienceTriggers'],
    },
  },
});

export const scheduleExperienceApi = _scheduleExperienceApi.enhanceEndpoints({
  endpoints: {
    ScheduleExperience: {
      invalidatesTags: [
        'MyExperiences',
        'ExperienceInfo',
        'ExperienceTriggers',
        'Broadcasts',
        'ExperienceMissions',
        'MissionTriggers',
        'WorkspaceExperiences',
        'MyPersonalExperiences',
        'MySharedExperiences',
        'MyWorkspaceExperiences',
      ],
    },
  },
});

export const resetExperienceApi = _resetExperienceApi.enhanceEndpoints({
  endpoints: {
    ResetExperience: {
      invalidatesTags: [
        'MyExperiences',
        'ExperienceInfo',
        'ExperienceTriggers',
        'ExperienceMissions',
        'Broadcasts',
        'MissionTriggers',
        'WorkspaceExperiences',
        'MyPersonalExperiences',
        'MySharedExperiences',
        'MyWorkspaceExperiences',
      ],
    },
  },
});

export const updateCohortEndApi = _updateCohortEndApi.enhanceEndpoints({
  endpoints: {
    UpdateCohortEnd: {
      invalidatesTags: [
        'MyExperiences',
        'ExperienceInfo',
        'ExperienceTriggers',
        'Broadcasts',
        'ExperienceMissions',
        'MissionTriggers',
        'WorkspaceExperiences',
        'MyPersonalExperiences',
        'MySharedExperiences',
        'MyWorkspaceExperiences',
      ],
    },
  },
});

export const experienceFormBrandingApi = _experienceFormBrandingApi.enhanceEndpoints({
  endpoints: {
    ExperienceFormBranding: {
      providesTags: ['ExperienceFormBranding'],
    },
  },
});

export const experienceManagersApi = _experienceManagersApi.enhanceEndpoints({
  endpoints: {
    ExperienceManagers: {
      providesTags: ['ExperienceManagers'],
    },
  },
});

export const pendingExperienceInvitationsApi = _pendingExperienceInvitationsApi.enhanceEndpoints({
  endpoints: {
    PendingExperienceInvitations: {
      providesTags: ['PendingExperienceInvitations'],
    },
  },
});

export const inviteExperienceManagerApi = _inviteExperienceManagerApi.enhanceEndpoints({
  endpoints: {
    InviteExperienceManager: {
      invalidatesTags: ['PendingExperienceInvitations'],
    },
  },
});

export const deleteExperienceInvitationApi = _deleteExperienceInvitationApi.enhanceEndpoints({
  endpoints: {
    DeleteExperienceInvitation: {
      invalidatesTags: ['PendingExperienceInvitations'],
    },
  },
});

export const resendExperienceInvitationApi = _resendExperienceInvitationApi.enhanceEndpoints({
  endpoints: {
    ResendExperienceInvitation: {
      invalidatesTags: ['PendingExperienceInvitations'],
    },
  },
});

export const deleteExperienceManagerApi = _deleteExperienceManagerApi.enhanceEndpoints({
  endpoints: {
    DeleteExperienceManager: {
      invalidatesTags: ['ExperienceManagers'],
    },
  },
});

export const leaveExperienceAsManagerApi = _leaveExperienceAsManagerApi.enhanceEndpoints({
  endpoints: {
    LeaveExperienceAsManager: {
      invalidatesTags: ['MySharedExperiences'],
    },
  },
});

export const statisticsApi = _statisticsApi.enhanceEndpoints({
  endpoints: {
    LoadStatistics: {
      providesTags: ['Statistics'],
    },
  },
});

export const shareExperienceApi = _shareExperienceApi.enhanceEndpoints({
  endpoints: {
    ShareExperience: {
      invalidatesTags: [
        'MyExperiences',
        'MyPersonalExperiences',
        'MySharedExperiences',
        'MyWorkspaceExperiences',
      ],
    },
  },
});

export const unshareExperienceApi = _unshareExperienceApi.enhanceEndpoints({
  endpoints: {
    UnshareExperience: {
      invalidatesTags: [
        'MyExperiences',
        'MyPersonalExperiences',
        'MySharedExperiences',
        'MyWorkspaceExperiences',
      ],
    },
  },
});

export const copySharedExperienceApi = _copySharedExperienceApi.enhanceEndpoints({
  endpoints: {
    CopySharedExperience: {
      invalidatesTags: ['MyExperiences', 'MyPersonalExperiences'],
    },
  },
});

export const submissionInfoStatsPaginatedApi = _submissionInfoStatsPaginatedApi.enhanceEndpoints({
  endpoints: {
    SubmissionInfoStatisticsPaginated: {
      providesTags: ['SubmissionInfoStatistics'],
    },
  },
});

export const submissionInfoStatsApi = _submissionInfoStatsApi.enhanceEndpoints({
  endpoints: {
    SubmissionInfoStatistics: {
      providesTags: [],
    },
  },
});

export const acceptExperienceInvitationApi = _acceptExperienceInvitationApi.enhanceEndpoints({
  endpoints: {
    AcceptExperienceInvitation: {
      invalidatesTags: ['MySharedExperiences'],
    },
  },
});
