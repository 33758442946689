import { mergeClasses } from '@goosechase/ui';
import { AppRoutes, EXPERIENCE_PATH } from '../../constants/routes';

import { MissionTypeName } from '../../data/models';
import { MissionIcon } from '../mission-icon';
import { SubmissionOverviewCard } from './submission-overview-card.component';
import { SubmissionOverviewImage } from './submission-overview-image.component';

const MISSION_TYPE_TO_CLASSNAME_MAP: Record<MissionTypeName, string> = {
  CameraMission: 'bg-yellowOrange',
  TextMission: 'bg-cyan/[.5]',
  GpsMission: 'bg-lilac/[.3]',
};

interface MissionSubmissionImageProps {
  imageUrl?: string;
  imageCaption?: string;
  missionName: string;
  missionType: MissionTypeName;
}

const MissionSubmissionImage = ({
  imageUrl,
  missionType,
  missionName,
  imageCaption,
}: MissionSubmissionImageProps) => {
  if (imageUrl) {
    return (
      <SubmissionOverviewImage src={imageUrl} alt={imageCaption || `${missionName}'s submission`} />
    );
  }

  return (
    <div
      className={mergeClasses(
        'flex w-full h-full justify-center items-center',
        MISSION_TYPE_TO_CLASSNAME_MAP[missionType],
      )}>
      <MissionIcon className="w-[72px] h-[72px]" type={missionType} />
    </div>
  );
};

export interface MissionSubmissionOverviewCardProps
  extends Pick<MissionSubmissionImageProps, 'missionType'> {
  experienceId: string;
  missionId: string;
  missionName: string;
  missionPoints: number;
  numSubmissions: number;
  submissionImageUrl?: string;
  submissionCaption?: string;
}

export const MissionSubmissionOverviewCard = ({
  experienceId,
  missionId,
  missionName,
  missionPoints,
  numSubmissions,
  submissionImageUrl,
  missionType,
  submissionCaption,
}: MissionSubmissionOverviewCardProps) => (
  <SubmissionOverviewCard
    displayName={missionName}
    bonusPoints={0}
    points={missionPoints}
    numSubmissions={numSubmissions}
    variant={missionType}
    href={`${EXPERIENCE_PATH}/${experienceId}/${AppRoutes.SUB_PATHS.MISSION_SUBMISSIONS}/${missionId}`}>
    <MissionSubmissionImage
      imageUrl={submissionImageUrl}
      missionType={missionType}
      missionName={missionName}
      imageCaption={submissionCaption}
    />
  </SubmissionOverviewCard>
);
