import { useState } from 'react';
import { Headline, IconButton } from '@goosechase/ui';
import { ExternalRoutes } from 'constants/routes';

import { ReactComponent as TemplatesIcon } from 'media/resources-templates.svg';
import { ReactComponent as ShopIcon } from 'media/resources-shop.svg';
import { ReactComponent as LightbulbIcon } from 'media/resources-blog.svg';
import { useTranslate } from 'util/i18n';
import { Link } from 'components/link.component';

import { WelcomeCard } from './welcome-card.component';
import { HowItWorksVideoModal } from './how-it-works-video-modal.component';

const CardsContainer = () => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslate('pages.experiences.welcomeBanner.resources');

  const handleShowHowToVideo = () => {
    setShowModal(true);
  };

  const handleHideHowToVideo = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="w-full mt-3 tablet-narrow:mt-7 tablet-narrow:mb-7 desktop:mb-[14px] flex flex-col tablet-narrow:flex-row-reverse gap-2 tablet-narrow:gap-7 items-stretch">
        <Link className="grow basis-1/3" href={ExternalRoutes.SHOP} target="_blank">
          <WelcomeCard icon={ShopIcon} label={t('shop')} />
        </Link>
        <Link className="grow basis-1/3" href={ExternalRoutes.TEMPLATE_LIBRARY} target="_blank">
          <WelcomeCard icon={TemplatesIcon} label={t('templateLibrary')} />
        </Link>
        <button className="grow basis-1/3" onClick={handleShowHowToVideo}>
          <WelcomeCard icon={LightbulbIcon} label={t('learn')} />
        </button>
      </div>
      <HowItWorksVideoModal show={showModal} onClose={handleHideHowToVideo} />
    </>
  );
};

export interface WelcomeBannerUIProps {
  isOpen: boolean;
  onClose: () => void;
}

export const WelcomeBannerUI = ({ isOpen, onClose }: WelcomeBannerUIProps) => {
  const { t } = useTranslate('pages.experiences.welcomeBanner');

  return (
    <div className="flex flex-col items-center w-full bg-yellowOrange px-4 tablet-narrow:px-5 pt-4 tablet-narrow:pt-[50px] pb-[18px] tablet-narrow:pb-[42px]">
      <div className="w-full max-w-[1094px]">
        <div className="flex justify-between">
          <Headline type="primary" size="sm">
            {t('welcome')}
          </Headline>
          {isOpen && (
            <IconButton
              aria-label={t('ariaCloseLabel') ?? ''}
              className="self-start h-fit w-fit"
              icon="Close"
              iconSize={16}
              iconColor="black"
              onClick={onClose}
            />
          )}
        </div>
        {isOpen && <CardsContainer />}
      </div>
    </div>
  );
};
