import { Headline, Icon } from '@goosechase/ui';
import { Card } from 'components/card.component';

interface WelcomeCardProps {
  label: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const WelcomeCard = ({ label, icon: ResourceIcon }: WelcomeCardProps) => (
  <Card className="group h-full px-2 py-[6px] tablet-narrow:p-5 flex justify-between items-center desktop:gap-20">
    <>
      <div className="flex items-center">
        <ResourceIcon className="h-11 w-11 desktop:h-16 desktop:w-16 mr-2 shrink-0" />
        <Headline className="text-start" type="secondary" size="2xs">
          {label}
        </Headline>
      </div>
      <Icon
        name="Arrow"
        size={16}
        className="mr-1.5 tablet-narrow:hidden desktop:block shrink-0 group-hover:stroke-vibrantBlue ui-open:stroke-vibrantBlue"
      />
    </>
  </Card>
);
