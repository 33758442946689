import { useState } from 'react';
import { Button, Modal } from '@goosechase/ui';
import { TeamFormUI } from '../../components/team-form/team-form-ui';

import { useTranslate } from '../../util/i18n';
import {
  type TeamFormOutputData,
  useTeamForm,
  UseTeamFormArgs,
} from '../../components/team-form/use-team-form';
import { displayToast } from '../../services/toast';
import { useUpdateTeamMutation, useTeamModalOpen } from 'data/teams';
import { getDirtyValues } from '../../util/form-util';

interface EditTeamModalProps {
  defaultValues: UseTeamFormArgs['defaultValues'];
  numParticipants: number;
  photoUrl?: string;
  onSubmitSuccess?: () => void;
  onClose: () => void;
  shouldDisableIndividualMode?: boolean;
}

export const EditTeamModal = ({
  defaultValues,
  numParticipants,
  photoUrl,
  onSubmitSuccess,
  onClose,
  shouldDisableIndividualMode,
}: EditTeamModalProps) => {
  const { t } = useTranslate('pages.participants');

  const show = useTeamModalOpen();

  const { formState, control, handleSubmit, setValue, watch } = useTeamForm({
    defaultValues,
    numParticipants,
  });

  const [uploadingPhoto, setUploadingPhoto] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const [updateTeam, { isLoading }] = useUpdateTeamMutation();

  const handleEditTeam = async (data: TeamFormOutputData) => {
    try {
      await updateTeam({
        params: {
          ...getDirtyValues(formState.dirtyFields, data),
          id: data.id,
        },
      }).unwrap();
      onSubmitSuccess?.();
      displayToast({
        id: data.id,
        title: t('teamForm.updateSuccessToast.title'),
        body: t('teamForm.updateSuccessToast.body'),
        type: 'success',
      });
    } catch (e) {
      displayToast({
        id: data.id,
        title: t('teamForm.updateErrorToast.title'),
        body: t('teamForm.updateErrorToast.body'),
        type: 'error',
      });
    }
  };

  return (
    <Modal
      show={show}
      onClose={handleClose}
      className="w-[calc(100vw-16px)] tablet-narrow:w-[498px] desktop:max-w-[709px] desktop:w-full">
      <Modal.Header title={t('teamForm.modal.title.edit') ?? undefined} />
      <form
        onSubmit={handleSubmit(handleEditTeam)}
        className="flex flex-col grow justify-between min-h-0">
        <Modal.Body>
          <TeamFormUI
            isLoading={isLoading}
            control={control}
            setValue={setValue}
            uploadingPhoto={uploadingPhoto}
            setUploadingPhoto={setUploadingPhoto}
            teamCreationMode={watch('mode')}
            photoUrl={photoUrl}
            context="edit"
            shouldDisableIndividualMode={shouldDisableIndividualMode}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            label={t('teamForm.modal.confirm')}
            disabled={!formState.isValid || uploadingPhoto}
          />
          <Button label={t('teamForm.modal.cancel')} onClick={handleClose} outlined />
        </Modal.Footer>
      </form>
    </Modal>
  );
};
