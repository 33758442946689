import { ChargebeeInstance } from 'global';

let cbInstance: ChargebeeInstance;

export const getChargebeeInstance = (): ChargebeeInstance => {
  if (!cbInstance) {
    cbInstance = window.Chargebee.init({
      site: window.goosechaseConfig?.CHARGEBEE_SITE ?? '',
      publishableKey: window.goosechaseConfig?.CHARGEBEE_PUBLISHABLE_KEY ?? '',
    });
  }

  return cbInstance;
};
