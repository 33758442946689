import { InlineBanner } from '../../components/inline-banner';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';

type LiveExperienceLimitBannerProps = {
  liveExperiencesCount: number;
};
export const LiveExperienceLimitBanner = ({
  liveExperiencesCount,
}: LiveExperienceLimitBannerProps) => {
  const workspace = useActiveWorkspace();

  const showBanner =
    workspace &&
    workspace.subscription.plan.maxConcurrentExperiences &&
    workspace.subscription.plan.upgradable &&
    liveExperiencesCount >= workspace.subscription.plan.maxConcurrentExperiences;

  if (!showBanner) {
    return null;
  }

  return <InlineBanner i18nKey="freeLiveExperienceLimit" showUpgradePlanAction />;
};
