/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type LeaderboardQueryVariables = Types.Exact<{
  cohortId: Types.Scalars['ID']['input'];
}>;


export type LeaderboardQuery = { __typename: 'Query', leaderboard: Array<{ __typename: 'TeamRanking', rank: number, team: { __typename: 'Team', id: string, displayName: string, mode: Types.TeamMode, photoUrl: string | null, points: number, numSubmissions: number, lastSubmission: string | null } }> };


export const LeaderboardDocument = `
    query Leaderboard($cohortId: ID!) {
  leaderboard(cohortId: $cohortId) {
    team {
      id
      displayName
      mode
      photoUrl
      points
      mode
      numSubmissions
      lastSubmission
    }
    rank
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    Leaderboard: build.query<LeaderboardQuery, LeaderboardQueryVariables>({
      query: (variables) => ({ document: LeaderboardDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLeaderboardQuery, useLazyLeaderboardQuery } = injectedRtkApi;

