import { Dropdown, Text } from '@goosechase/ui';
import { Controller, Control, useFormState } from 'react-hook-form';

import type { MissionFormInputData } from './use-mission-form';
import { NumberInput } from '../number-input';
import { useTranslate } from 'util/i18n';
import {
  MISSION_TRIGGER_TIME_UNITS,
  MISSION_TRIGGER_TIME_ANCHOR,
  TriggerTimeAnchorV1,
} from '../../data/models';
import {
  TIME_ANCHOR_TO_TRANSLATION_KEY_MAP,
  TIME_UNIT_TO_TRANSLATION_KEY_MAP,
} from '../../util/time-util';
import { MissionTriggerInfo } from '../mission-trigger-time';

interface MissionTriggerRelativeFieldsProps {
  control: Control<MissionFormInputData>;
  triggerType: 'releaseTrigger' | 'expireTrigger';
}

export const MissionTriggerRelativeFields = ({
  control,
  triggerType,
}: MissionTriggerRelativeFieldsProps) => {
  const { t } = useTranslate('missionForm.missionTrigger');
  const { errors } = useFormState({ control });

  const relativeUnitOptions = MISSION_TRIGGER_TIME_UNITS.map((timeUnit) => {
    return {
      id: timeUnit,
      title: t(`relativeUnit.${TIME_UNIT_TO_TRANSLATION_KEY_MAP[timeUnit].toLowerCase()}`),
    };
  });

  const relativeAnchorOptions = MISSION_TRIGGER_TIME_ANCHOR.map((timeAnchor) => {
    return {
      id: timeAnchor,
      title: t(`relativeAnchor.${TIME_ANCHOR_TO_TRANSLATION_KEY_MAP[timeAnchor]}`),
    };
  });

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex gap-2 items-center w-full">
        <svg
          width="40"
          height="36"
          viewBox="0 0 40 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 5C7 4.44772 6.55228 4 6 4C5.44772 4 5 4.44772 5 5H7ZM34.7071 24.7071C35.0976 24.3166 35.0976 23.6834 34.7071 23.2929L28.3431 16.9289C27.9526 16.5384 27.3195 16.5384 26.9289 16.9289C26.5384 17.3195 26.5384 17.9526 26.9289 18.3431L32.5858 24L26.9289 29.6569C26.5384 30.0474 26.5384 30.6805 26.9289 31.0711C27.3195 31.4616 27.9526 31.4616 28.3431 31.0711L34.7071 24.7071ZM34 23H14V25H34V23ZM7 16V5H5V16H7ZM14 23C10.134 23 7 19.866 7 16H5C5 20.9706 9.02944 25 14 25V23Z"
            fill="#B1B1B1"
          />
        </svg>

        <Controller
          control={control}
          name={`${triggerType}.relativeDuration`}
          render={({ field: { name, value, onChange, onBlur }, fieldState: { error } }) => (
            <NumberInput
              label=""
              placeholder={t('relativeDuration.placeholder') ?? undefined}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              value={value ?? null}
              hideSpinButtons
              containerClassName="flex-1"
              error={Boolean(error) || Boolean(errors.releaseTrigger?.message)}
            />
          )}
        />
        <Controller
          name={`${triggerType}.relativeUnit`}
          control={control}
          render={({ field: { onChange, value } }) => {
            const selectedDropdownOption = value
              ? {
                  id: value,
                  title: t(`relativeUnit.${TIME_UNIT_TO_TRANSLATION_KEY_MAP[value].toLowerCase()}`),
                }
              : undefined;

            return (
              <Dropdown
                buttonClassName="w-full"
                label={selectedDropdownOption?.title ?? ''}
                options={relativeUnitOptions}
                selectedOptionId={value ?? undefined}
                onSelect={(val: string) => onChange(val)}
                containerClassName="flex-1"
              />
            );
          }}
        />
      </div>
      {errors[triggerType] ? (
        <div className="pl-12">
          <Text size="xs" className="text-vibrantRed">
            <span
              dangerouslySetInnerHTML={{
                __html:
                  errors[triggerType]?.relativeDuration?.message ||
                  errors[triggerType]?.message ||
                  '',
              }}
            />
          </Text>
        </div>
      ) : null}
      <div className="pl-12 w-full">
        <Controller
          name={`${triggerType}.relativeAnchor`}
          control={control}
          render={({ field: { onChange, value } }) => {
            const selectedDropdownOption = {
              id: value,
              title: t(
                `relativeAnchor.${
                  TIME_ANCHOR_TO_TRANSLATION_KEY_MAP[value as TriggerTimeAnchorV1]
                }`,
              ),
            };

            return (
              <Dropdown
                containerClassName="w-full"
                buttonClassName="w-full"
                label={selectedDropdownOption.title}
                options={relativeAnchorOptions}
                selectedOptionId={value ?? undefined}
                onSelect={(val: string) => onChange(val)}
              />
            );
          }}
        />
      </div>
      <MissionTriggerInfo control={control} triggerType={triggerType} />
    </div>
  );
};
