var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { mergeClasses } from '../util/merge-classes';
import { Label } from '../typography';
var getVariantClassNames = function (variant) {
    switch (variant) {
        case 'positive':
            return 'text-black bg-vibrantGreen';
        case 'negative':
            return 'text-white bg-vibrantRed';
        case 'neutral':
            return 'text-vibrantBlue border-vibrantBlue border';
    }
};
export var Pill = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? 'neutral' : _b, label = _a.label, className = _a.className;
    return (_jsx("div", __assign({ className: mergeClasses('flex h-6 px-3 items-center rounded-pill', getVariantClassNames(variant), className) }, { children: _jsx(Label, __assign({ size: "sm" }, { children: label })) })));
};
