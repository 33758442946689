import { Avatar, Headline, Button, Modal, Text } from '@goosechase/ui';
import { useAppDispatch } from 'data/hooks';
import {
  toggleAcceptedWorkspaceInvitationModal,
  useAcceptedWorkspaceInvitationModalOpen,
  useSelectedWorkspace,
} from 'data/workspaces';

import { useTranslate } from 'util/i18n';

export const AcceptedWorkspaceInvitationModal = () => {
  const { t } = useTranslate('acceptedWorkspaceInvitationModal');
  const show = useAcceptedWorkspaceInvitationModalOpen();
  const workspace = useSelectedWorkspace();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(toggleAcceptedWorkspaceInvitationModal());
  };

  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[592px]"
      show={show}
      onClose={handleClose}>
      <Modal.Body>
        <div className="flex flex-col gap-6 items-center justify-center text-center">
          <Avatar
            alt={workspace?.displayName ?? 'Workspace avatar'}
            placeholder="workspace"
            src={workspace?.photoUrl ?? undefined}
            size="xl2"
          />
          <Headline type="primary" size="sm">
            {t('headline', { displayName: workspace?.displayName })}
          </Headline>
          <Text>{t('body')}</Text>
          <Button label={t('gotIt')} onClick={handleClose} />
        </div>
      </Modal.Body>
    </Modal>
  );
};
