import { z } from 'zod';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, NotificationBanner } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { LabelledField } from 'components/labelled-field.component';

export interface ServerErrors {
  general?: string;
}

export interface ResetPasswordFormUIProps {
  serverErrors: ServerErrors | undefined;
  loading: boolean;
  onSubmit: (data: ResetPasswordData) => void;
  clearServerErrors: () => void;
}

export interface ResetPasswordData {
  newPassword: string;
  confirmNewPassword: string;
}

const useResetPasswordForm = () => {
  const { t } = useTranslate('pages.resetPassword.resetPasswordForm');

  return useForm({
    mode: 'onTouched',
    defaultValues: { newPassword: '', confirmNewPassword: '' },
    resolver: zodResolver(
      z
        .object({
          newPassword: z
            .string()
            .min(1, { message: t('newPassword.errors.required') as string })
            .min(8, { message: t('newPassword.errors.minLength') as string }),
          confirmNewPassword: z.string(),
        })
        .refine(({ newPassword, confirmNewPassword }) => newPassword === confirmNewPassword, {
          message: t('confirmNewPassword.errors.matchingPasswords') as string,
          path: ['confirmNewPassword'],
        }),
    ),
  });
};

export const ResetPasswordFormUI = ({
  loading,
  serverErrors,
  onSubmit,
  clearServerErrors,
}: ResetPasswordFormUIProps) => {
  const { t } = useTranslate('pages.resetPassword.resetPasswordForm');
  const { control, handleSubmit } = useResetPasswordForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6 desktop:mb-8">
        <Controller
          control={control}
          name="newPassword"
          render={({ field: { onBlur, onChange }, fieldState }) => (
            <LabelledField
              label={t('newPassword.label')}
              type="password"
              onChange={(input) => {
                if (serverErrors) {
                  clearServerErrors();
                }
                onChange(input);
              }}
              onBlur={onBlur}
              autoComplete="new-password"
              errorMessage={fieldState.error?.message}
              disabled={loading}
            />
          )}
        />
      </div>
      <div className="mb-6 desktop:mb-8">
        <Controller
          control={control}
          name="confirmNewPassword"
          render={({ field: { onBlur, onChange }, fieldState }) => (
            <LabelledField
              label={t('confirmNewPassword.label')}
              type="password"
              onChange={(input) => {
                if (serverErrors) {
                  clearServerErrors();
                }
                onChange(input);
              }}
              onBlur={onBlur}
              autoComplete="new-password"
              errorMessage={fieldState.error?.message}
              disabled={loading}
            />
          )}
        />
      </div>
      {serverErrors?.general && (
        <NotificationBanner
          className="mb-6 desktop:mb-8"
          type="error"
          title={t('generalErrorTitle') as string}
          body={serverErrors.general}
          showIcon
        />
      )}
      <Button label={t('submit')} disabled={loading} />
    </form>
  );
};
