export var getDimensions = function (size) {
    switch (size) {
        case 'xl5':
            return 'h-[206px] w-[206px]';
        case 'xl4':
            return 'h-[200px] w-[200px]';
        case 'xl3':
            return 'h-[168px] w-[168px]';
        case 'xl2':
            return 'h-[132px] w-[132px]';
        case 'xl':
            return 'h-[116px] w-[116px]';
        case 'lg':
            return 'h-[96px] w-[96px]';
        case 'sm':
            return 'h-[80px] w-[80px]';
        case 'xs':
            return 'h-[64px] w-[64px]';
        case 'xs2':
            return 'h-[48px] w-[48px]';
        case 'xs3':
            return 'h-[38px] w-[38px]';
        case 'xs4':
            return 'h-[28px] w-[28px]';
        case 'xs5':
            return 'h-[24px] w-[24px]';
        case 'base':
        default:
            return 'h-[88px] w-[88px]';
    }
};
export var getFontSize = function (size) {
    switch (size) {
        case 'xl5':
        case 'xl4':
        case 'xl3':
        case 'xl2':
            return 'text-avatar-initials-xl';
        case 'xl':
            return 'text-avatar-initials-lg';
        case 'xs2':
        case 'xs3':
            return 'text-avatar-initials-sm';
        case 'xs4':
        case 'xs5':
            return 'text-avatar-initials-xs';
        case 'lg':
        case 'base':
        case 'sm':
        case 'xs':
        default:
            return 'text-avatar-initials-base';
    }
};
export var getBorder = function (size) {
    switch (size) {
        case 'xs3':
        case 'xs4':
        case 'xs5':
            return 'border-2 border-offWhite';
        default:
            return 'border-4 border-offWhite';
    }
};
export var getBorderRadius = function (squared, size) {
    if (!squared) {
        return 'rounded-full';
    }
    // Squared border radii based on size / 6
    switch (size) {
        case 'xl5':
            return 'rounded-[34px]';
        case 'xl4':
            return 'rounded-[33px]';
        case 'xl3':
            return 'rounded-[28px]';
        case 'xl2':
            return 'rounded-[22px]';
        case 'xl':
            return 'rounded-[19px]';
        case 'lg':
            return 'rounded-2xl';
        case 'sm':
            return 'rounded-[13px]';
        case 'xs':
            return 'rounded-[10px]';
        case 'xs2':
            return 'rounded-lg';
        case 'xs3':
            return 'rounded-md';
        case 'xs4':
        case 'xs5':
            return 'rounded';
        case 'base':
        default:
            return 'rounded-2xl';
    }
};
