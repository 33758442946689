import { useEffect } from 'react';

import { useAppDispatch } from 'data/hooks';
import { logout, useLogoutMutation } from 'data/auth';
import { ExternalRoutes } from 'constants/routes';

export const LogoutPage = () => {
  const dispatch = useAppDispatch();
  const [logoutMutation] = useLogoutMutation();

  useEffect(() => {
    logoutMutation().catch(() => {
      // TODO: Sentry - Failed signOut mutation
    });

    // TODO: Clear all local state / storage, not just the access token
    dispatch(logout({ forced: false }));
    window.location.replace(ExternalRoutes.MARKETING_SITE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
