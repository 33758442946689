/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type DeleteExperienceInvitationMutationVariables = Types.Exact<{
  invitationId: Types.Scalars['ID']['input'];
}>;


export type DeleteExperienceInvitationMutation = { __typename: 'Mutation', deleteExperienceInvitation: { __typename: 'ExperienceInvitation', id: string } };


export const DeleteExperienceInvitationDocument = `
    mutation DeleteExperienceInvitation($invitationId: ID!) {
  deleteExperienceInvitation(invitationId: $invitationId) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    DeleteExperienceInvitation: build.mutation<DeleteExperienceInvitationMutation, DeleteExperienceInvitationMutationVariables>({
      query: (variables) => ({ document: DeleteExperienceInvitationDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteExperienceInvitationMutation } = injectedRtkApi;

