import { Dropdown } from '@goosechase/ui';

import { MissionType, MISSION_TYPES } from '../../data/models';
import {
  MissionTypeDropdownOption,
  MissionTypeIcon,
  MissionTypeOptionProps,
} from '../mission-type-dropdown-option';
import { useTranslate } from '../../util/i18n';

interface MissionTypeOption extends MissionTypeOptionProps {
  id: string;
}

const MISSION_TYPE_OPTIONS: MissionTypeOption[] = MISSION_TYPES.map((type) => ({
  id: type,
  missionType: type,
}));

// eslint-disable-next-line react/display-name
const makeRenderLeft = (selectedMissionType: MissionType) => () =>
  <MissionTypeIcon missionType={selectedMissionType} />;

interface MissionTypeDropdownProps {
  selectedMissionType: MissionType;
  onSelect: (missionType: MissionType) => void;
}

export const MissionTypeDropdown = ({
  selectedMissionType,
  onSelect,
}: MissionTypeDropdownProps) => {
  const { t } = useTranslate(`missionTypeDropdownOption.missionType`);

  return (
    <Dropdown
      buttonClassName="w-full py-2 px-4"
      label={t(`${selectedMissionType.toLowerCase()}.label`)}
      options={MISSION_TYPE_OPTIONS}
      selectedOptionId={selectedMissionType}
      renderLeft={makeRenderLeft(selectedMissionType)}
      customRenderOption={MissionTypeDropdownOption}
      onSelect={(val: string) => onSelect(val as MissionType)}
    />
  );
};
