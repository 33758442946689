import { Headline, Text } from '@goosechase/ui';

import { ReactComponent as ConfusedGoose } from 'media/confused-goose.svg';
import { useTranslate } from 'util/i18n';

export interface ErrorStateProps {
  text?: string;
  subtext?: string;
}

export const ErrorState = ({ text, subtext }: ErrorStateProps) => {
  const { t } = useTranslate('errorState');

  return (
    <div className="flex flex-col items-center text-black-64">
      <ConfusedGoose className="mb-6 max-w-[148px] min-w-[100px]" />
      <Headline className="mb-2 text-center" type="secondary" size="sm">
        {text ?? t('defaultText')}
      </Headline>
      <Text className="text-center" size="sm">
        {subtext ?? t('defaultSubtext')}
      </Text>
    </div>
  );
};
