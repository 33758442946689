import { EXPERIENCE_SECTION_NAV_LINKS } from '../../constants/experience-sections';
import { useActiveExperienceSection } from '../../hooks/use-active-experience-section';
import { useScreenSize } from '../../hooks/use-screen-size';
import { ExperienceNavLink } from '../experience-section-tabs';
import { ExperienceSettings } from './experience-settings.component';

export const SIDEBAR_WIDTH = 'desktop:w-[180px]';

interface ExperienceSideNavBarProps {
  experienceId: string;
}

export const SideNavBar = ({ experienceId }: ExperienceSideNavBarProps) => {
  const activeSection = useActiveExperienceSection();
  const { isDesktop } = useScreenSize();

  if (!isDesktop) {
    // Do not display the sidebar for screen sizes smaller than desktop
    return null;
  }

  return (
    <div className="pt-8 bg-white font-soleil border-r border-r-black-12">
      <div className="relative h-full w-[181px]">
        <div className="flex flex-col flex-wrap justify-between h-full">
          <div className={`flex flex-col border-b border-b-black-12`}>
            {activeSection &&
              EXPERIENCE_SECTION_NAV_LINKS[activeSection].map((navLink) => {
                return (
                  <ExperienceNavLink
                    key={navLink.name}
                    variant="sidebar"
                    experienceId={experienceId}
                    {...navLink}
                  />
                );
              })}
          </div>
          <ExperienceSettings />
        </div>
      </div>
    </div>
  );
};
