import { Avatar, Button, Text, Dropdown, mergeClasses } from '@goosechase/ui';
import { EmptyState } from 'components/empty-state';
import { useExperienceOutletContext } from 'components/experience-layout';
import { getAdditionalMissionInfo } from 'components/information-tags/information-tags.util';
import { LoadingSpinner } from 'components/loading-spinner';
import { MissionSummaryCard } from 'components/mission-summary-card';
import { useMyPreviousMissionsQuery } from 'data/missions';
import { FeedVisibility } from 'data/models';
import { ReactComponent as GeeseWithMagnifyingGlass } from 'media/goose-with-magnifying-glass.svg';
import { useState } from 'react';
import { Nullable } from 'types/util';
import { useTranslate } from 'util/i18n';

type PreviousMissionsListProps = {
  onAddClick: (missionId: string, displayName: string) => void;
};

export const PreviousMissionsList = (props: PreviousMissionsListProps) => {
  const { t } = useTranslate('missionLibrary');
  const { t: t2 } = useTranslate('missionInformationTags');
  const { experienceId } = useExperienceOutletContext();
  const { data, isError, isLoading } = useMyPreviousMissionsQuery({
    excludeExperienceId: experienceId,
  });
  const [selectedExperienceId, setSelectedExperienceId] = useState('');

  if (isLoading) {
    return (
      <div className="p-10">
        <LoadingSpinner />
      </div>
    );
  }

  if (isError || !data?.myPreviousMissions.length) {
    return (
      <EmptyState
        subtext={t('previous.empty.subtext')}
        text={t('previous.empty.text')}
        renderGraphic={() => <GeeseWithMagnifyingGlass />}
      />
    );
  }

  const experiences = data.myPreviousMissions.reduce((acc, mission) => {
    if (mission.experience && !acc[mission.experience.id]) {
      acc[mission.experience.id] = mission.experience;
    }
    return acc;
  }, {} as Record<string, { id: string; displayName: string; photoUrl: Nullable<string> }>);

  const experienceOptions = Object.values(experiences).map((experience) => ({ id: experience.id }));

  const customRenderOption = (option: { id: string }, padded = true) => (
    <div className={mergeClasses('flex items-center gap-3', { 'p-4': padded })}>
      <Avatar
        alt={experiences[option.id].displayName}
        squared
        src={experiences[option.id].photoUrl ?? undefined}
        placeholder="experience"
        size="xs4"
      />
      <Text size="md">{experiences[option.id].displayName}</Text>
    </div>
  );

  const missions = data.myPreviousMissions.filter(
    (mission) => !selectedExperienceId || mission.experience?.id === selectedExperienceId,
  );

  return (
    <div className="flex flex-col gap-3 w-full">
      <Dropdown
        label={
          selectedExperienceId
            ? customRenderOption({ id: selectedExperienceId }, false)
            : t('previous.filter')
        }
        onSelect={setSelectedExperienceId}
        options={experienceOptions}
        selectedOptionId={selectedExperienceId}
        buttonClassName="w-full"
        customRenderOption={customRenderOption}
        clearable
      />
      <div className="flex flex-col gap-3 overflow-y-auto pb-16">
        {missions.map((mission) => {
          const additionalMissionInfo = getAdditionalMissionInfo(t2, {
            ...mission,
            feedVisibility: FeedVisibility.PublicAtExperienceEnd,
          });

          return (
            <MissionSummaryCard
              description={mission.description}
              infoTags={additionalMissionInfo.infoTags}
              points={mission.points}
              secondaryInfoTags={additionalMissionInfo.secondaryInfoTags}
              title={mission.displayName}
              type={mission.__typename}
              infoTitle={additionalMissionInfo.infoTitle}
              key={mission.id}
              renderBottomRight={() => (
                <Button
                  outlined
                  leftIcon="Plus"
                  label={t('add')}
                  size="sm"
                  onClick={() => props.onAddClick(mission.id, mission.displayName)}
                />
              )}
            />
          );
        })}
      </div>
    </div>
  );
};
