import { useParams } from 'react-router';

import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { usePublicSubmissionQuery } from '../../data/submissions';
import { LoadingSpinner } from '../../components/loading-spinner';
import { ErrorState } from '../../components/error-state';
import { Card } from '../../components/card.component';
import { NotFound } from '../../components/not-found';
import { PublicSubmission } from './public-submission.component';

export const PublicSubmissionPage = () => {
  const { t } = useTranslate('pages.publicSubmission');
  const { submissionId } = useParams();
  const { data, isLoading, isError } = usePublicSubmissionQuery(
    { submissionId: submissionId ?? '' },
    { skip: submissionId === undefined },
  );

  if (isLoading) {
    return (
      <div className="flex grow justify-center items-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex grow justify-center items-center p-4">
        <Card className="py-16 px-8">
          <ErrorState />
        </Card>
      </div>
    );
  }

  if (data == null || data.publicSubmission === null) {
    return (
      <div className="flex grow justify-center items-center">
        <NotFound />
      </div>
    );
  }

  const missionName = data?.publicSubmission?.mission.displayName;

  return (
    <Page
      title={t('meta.title', { context: missionName ? 'missionName' : undefined, missionName })}>
      <div className="flex grow justify-center tablet-narrow:pt-9">
        <PublicSubmission submission={data.publicSubmission} />
      </div>
    </Page>
  );
};
