import * as dateFns from 'date-fns';
import { Icon, Text, Tooltip } from '@goosechase/ui';
import { Trans } from 'react-i18next';

import {
  formatSpecificTime,
  isMissionTriggerValid,
  TIME_ANCHOR_TO_TRANSLATION_KEY_MAP,
  TIME_UNIT_TO_TRANSLATION_KEY_MAP,
} from '../../util/time-util';
import {
  MissionStatus,
  MissionTaskType,
  MissionTriggerTiming,
  type MissionTrigger,
} from 'data/models';
import { useTranslate } from 'util/i18n';
import { Nullable } from 'types/util';
import { Link } from 'components/link.component';
import { AppRoutes } from 'constants/routes';
import { MissionTriggerTime } from '../mission-trigger-time';

const TASK_TYPE_TO_TRANSLATION_KEY_MAP: Record<MissionTaskType, string> = {
  [MissionTaskType.ExpireMission]: 'expire',
  [MissionTaskType.HideMission]: 'hide',
  [MissionTaskType.ReleaseMission]: 'release',
};

const TRIGGER_TIMING_TO_TRANSLATION_KEY_MAP: Record<MissionTriggerTiming, string> = {
  [MissionTriggerTiming.Relative]: 'relative',
  [MissionTriggerTiming.Specific]: 'specific',
};

type MissionTriggerInfoProps = MissionTrigger & {
  status: MissionStatus;
  releaseAt?: Nullable<string>;
  expireAt?: Nullable<string>;
  cohortStartDate?: Nullable<string>;
  cohortEndDate?: Nullable<string>;
  experienceTimezone: string;
};

const MissionTriggerIcon = ({
  trigger,
  cohortEndDate,
  cohortStartDate,
  isPastTrigger,
  timing,
  experienceTimezone,
  status,
}: {
  trigger: MissionTrigger;
  cohortStartDate?: Nullable<string>;
  cohortEndDate?: Nullable<string>;
  isPastTrigger: boolean;
  timing: MissionTriggerTiming;
  experienceTimezone: string;
  status: MissionStatus;
}) => {
  const { t } = useTranslate('missionCard');

  const isValidCheck = isMissionTriggerValid({
    startDate: cohortStartDate ? new Date(cohortStartDate) : null,
    endDate: cohortEndDate ? new Date(cohortEndDate) : null,
    experienceTimezone,
  })({
    timing,
    relativeAnchor: trigger.relativeAnchor,
    relativeDuration: trigger.relativeDuration,
    relativeUnit: trigger.relativeUnit,
    specificDay: trigger.specificDay,
    specificTime: trigger.specificTime,
    type: trigger.taskType,
    status,
    triggerAt: null,
  });

  if (!isValidCheck.valid) {
    return (
      <Tooltip
        body={
          <span>
            {t(
              `trigger.warningTooltip.${TASK_TYPE_TO_TRANSLATION_KEY_MAP[trigger.taskType]}.${
                isValidCheck.messageKey
              }`,
            )}
            {isValidCheck.messageKey === 'outsideStartEnd' ? (
              <Link className="underline" href={AppRoutes.SUB_PATHS.START_END}>
                Start & End times
              </Link>
            ) : null}
          </span>
        }
        className="w-[186px] ">
        <div className="flex gap-2">
          <Icon size={16} name="Warning" color="white" />
        </div>
      </Tooltip>
    );
  }

  return isPastTrigger ? (
    <Icon size={16} name="Success" />
  ) : (
    <Icon size={16} name={timing === MissionTriggerTiming.Relative ? 'Hourglass' : 'Clock'} />
  );
};

export const MissionTriggerInfo = (props: MissionTriggerInfoProps) => {
  const { t } = useTranslate('missionCard');

  const isPastTrigger =
    Boolean(
      props.status !== MissionStatus.Hidden &&
        props.taskType === MissionTaskType.ReleaseMission &&
        props.releaseAt &&
        dateFns.isBefore(new Date(props.releaseAt), new Date()),
    ) ||
    Boolean(
      props.status === MissionStatus.Expired &&
        props.taskType === MissionTaskType.ExpireMission &&
        props.expireAt &&
        dateFns.isBefore(new Date(props.expireAt), new Date()),
    );

  const key = `trigger.${TASK_TYPE_TO_TRANSLATION_KEY_MAP[props.taskType]}.${
    TRIGGER_TIMING_TO_TRANSLATION_KEY_MAP[props.timing]
  }.${isPastTrigger ? 'complete' : 'pending'}`;

  // MISSION TODO: likely we will want to show the triggerAt time on mission page to help with understanding
  // your anticipated trigger times. But this needs more work since props.releaseAt can be
  // one of: mission's actual state's change time, trigger's anticipated trigger time, or the last time the mission was in the desired state
  // const triggerAt =
  //   props.taskType === MissionTaskType.ReleaseMission && props.releaseAt
  //     ? dateFns.format(new Date(props.releaseAt), "EE, LLL d yyyy 'at' h:mm aa")
  //     : props.expireAt
  //     ? dateFns.format(new Date(props.expireAt), "EE, LLL d yyyy 'at' h:mm aa")
  //     : null;

  return (
    <div className="flex gap-2 items-center">
      <MissionTriggerIcon
        status={props.status}
        isPastTrigger={isPastTrigger}
        timing={props.timing}
        trigger={props}
        cohortEndDate={props.cohortEndDate}
        cohortStartDate={props.cohortStartDate}
        experienceTimezone={props.experienceTimezone}
      />
      <Text>
        <>
          <Trans
            t={t}
            i18nKey={key}
            values={{
              duration:
                props.relativeUnit &&
                props.relativeDuration &&
                t(`trigger.${TIME_UNIT_TO_TRANSLATION_KEY_MAP[props.relativeUnit].toLowerCase()}`, {
                  count: props.relativeDuration,
                }),
              anchor:
                props.relativeAnchor &&
                t(`trigger.${TIME_ANCHOR_TO_TRANSLATION_KEY_MAP[props.relativeAnchor]}`),
              day: props.specificDay,
              time: props.specificTime && formatSpecificTime(props.specificTime),
            }}
          />
          <span className="text-black-64 ml-2">
            <MissionTriggerTime
              timing={props.timing}
              relativeAnchor={props.relativeAnchor}
              relativeDuration={props.relativeDuration}
              relativeUnit={props.relativeUnit}
              specificDay={props.specificDay}
              specificTime={props.specificTime}
            />
          </span>
        </>
      </Text>
    </div>
  );
};
