import { Button, Text, Headline, Label, Icon, mergeClasses } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';

type NotForProfitPlanProps = {
  selected: boolean;
  onClick: () => void;
};

export const NotForProfitPlan = (props: NotForProfitPlanProps) => {
  const { t } = useTranslate('plans');

  return (
    <div
      className={mergeClasses(
        'bg-white rounded-lg px-5 py-9 pt-5 border-t-[16px] border-lilac w-full max-w-[390px] justify-self-center',
        {
          'bg-black-12': props.selected,
        },
      )}>
      <Headline type="secondary" size="sm" className="mb-6">
        {t(`plan.npoUnlimited.title`)}
      </Headline>
      <div className="mb-8 h-[66px] flex items-end">
        <Headline type="secondary" size="lg">
          {t('letsTalk')}
        </Headline>
      </div>
      <div className="flex min-h-[52px] mb-6">
        {props.selected ? (
          <div className="flex gap-1 items-center">
            <Icon name="GooseFeet" />
            <Label size="lg" className="uppercase">
              {t('currentPlan')}
            </Label>
          </div>
        ) : (
          <Button label={t('contactUs')} outlined onClick={props.onClick} />
        )}
      </div>
      <div className="flex flex-col gap-4 pb-4">
        {(t(`plan.npoUnlimited.subtitle`, { returnObjects: true }) as string[]).map((subtitle) => (
          <Text key={subtitle} className="font-bold">
            {subtitle}
          </Text>
        ))}
      </div>
      <div className="flex flex-col gap-4">
        <div className="h-px bg-black-24 w-full" />
        {(t(`plan.npoUnlimited.features`, { returnObjects: true }) as string[]).map((features) => (
          <Text key={features}>{features}</Text>
        ))}
      </div>
    </div>
  );
};
