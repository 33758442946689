var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch } from '@headlessui/react';
import { mergeClasses } from '../util/merge-classes';
export var Toggle = function (props) {
    return (_jsxs(Switch, __assign({ name: props.name, checked: props.checked, onChange: props.onChange, className: mergeClasses('inline-flex h-10 w-20 items-center rounded-full bg-black-64', {
            'bg-vibrantBlue': props.checked,
        }) }, { children: [props.label ? _jsx("span", __assign({ className: "sr-only" }, { children: props.label })) : null, _jsx("div", { className: mergeClasses('inline-block h-9 w-9 transform rounded-full bg-white transition translate-x-0.5', {
                    'translate-x-[2.625rem]': props.checked,
                }) })] })));
};
