import { zodResolver } from '@hookform/resolvers/zod';
import { TeamCreationMode, TeamMode, TEAM_CREATION_MODES, TEAM_MODES } from 'data/models';
import { useForm } from 'react-hook-form';
import { Nullable } from 'types/util';
import { z } from 'zod';

export interface ParticipantOptionsData {
  allowParticipantsToCreateTeams: boolean;
  teamCreationMode: TeamCreationMode;
  studioDefaultTeamMode: TeamMode;
  studioDefaultTeamMaxSize: Nullable<number>;
}

export const useParticipantOptionsForm = (defaultValues?: ParticipantOptionsData) => {
  const schema = z.object({
    allowParticipantsToCreateTeams: z.boolean(),
    studioDefaultTeamMaxSize: z.number().int().min(1).nullable(),
    studioDefaultTeamMode: z.enum(TEAM_MODES),
    teamCreationMode: z.enum(TEAM_CREATION_MODES),
  });

  return useForm<ParticipantOptionsData>({
    mode: 'onTouched',
    defaultValues,
    resolver: zodResolver(schema),
  });
};
