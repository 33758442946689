var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { getDataTestIDProp } from '../util/test-id';
export var Text = React.forwardRef(function (_a, ref) {
    var children = _a.children, className = _a.className, _b = _a.size, size = _b === void 0 ? 'md' : _b, testID = _a.testID;
    var css = classNames({
        'font-soleil': true,
        'text-paragraph-xl': size === 'xl',
        'text-paragraph-lg': size === 'lg',
        'text-paragraph-md': size === 'md',
        'text-paragraph-sm': size === 'sm',
        'text-paragraph-xs': size === 'xs',
    }, className);
    return (_jsx("p", __assign({ ref: ref, className: css }, getDataTestIDProp(testID), { children: children })));
});
Text.displayName = 'Text';
