import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { PropsWithChildren } from 'react';

interface DragContextProps extends PropsWithChildren {
  items: { id: UniqueIdentifier }[];
  onItemPositionUpdate: (
    updateMissionCustomOrderPositionId: string,
    newPosition: number,
  ) => Promise<void>;
}
export const DragContext = ({ children, items, onItemPositionUpdate }: DragContextProps) => {
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (typeof active.id === 'string' && typeof over?.id === 'string' && active.id !== over.id) {
      const newPosition = items.findIndex((item) => item.id === over.id);
      // mission positions are not 0-based
      await onItemPositionUpdate(active.id, newPosition + 1);
    }
  };

  return (
    <div className="relative">
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {children}
        </SortableContext>
      </DndContext>
    </div>
  );
};
