var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { mergeClasses } from '../util/merge-classes';
var DISABLED_ANCHOR_CLASSES = 'text-black-24 cursor-not-allowed focus:none outline-transparent';
var ENABLED_ANCHOR_CLASSES = 'text-vibrantBlue hover:text-black focus:outline-2 outline-black';
var DISABLED_UNDERLINE_CLASSES = 'bg-black-24 cursor-not-allowed';
var ENABLED_UNDERLINE_CLASSES = 'bg-vibrantBlue group-hover:bg-black';
export var StyledLink = function (_a) {
    var children = _a.children, variant = _a.variant, className = _a.className, underlineClassName = _a.underlineClassName;
    var containerClasses = mergeClasses(variant === 'disabled' ? DISABLED_ANCHOR_CLASSES : ENABLED_ANCHOR_CLASSES, 'font-semibold', 'group', 'inline-block', 'p-1', className);
    var underlineClasses = mergeClasses('flex', 'h-[4px]', 'mt-[4px]', 'rounded', variant === 'disabled' ? DISABLED_UNDERLINE_CLASSES : ENABLED_UNDERLINE_CLASSES, underlineClassName);
    return (_jsxs("span", __assign({ className: containerClasses }, { children: [children, _jsx("span", { className: underlineClasses })] })));
};
export var StyledAnchor = function (_a) {
    var href = _a.href, children = _a.children, disabled = _a.disabled, className = _a.className, onClick = _a.onClick, underlineClassName = _a.underlineClassName, htmlProps = __rest(_a, ["href", "children", "disabled", "className", "onClick", "underlineClassName"]);
    var handleClick = useCallback(function (e) {
        if (disabled) {
            return e.preventDefault();
        }
        else {
            return onClick === null || onClick === void 0 ? void 0 : onClick(e);
        }
    }, [disabled, onClick]);
    return (_jsx("a", __assign({ href: href, onClick: handleClick }, htmlProps, { children: _jsx(StyledLink, __assign({ variant: disabled ? 'disabled' : 'enabled', className: className, underlineClassName: underlineClassName }, { children: children })) })));
};
