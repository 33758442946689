/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type SetUseCasesMutationVariables = Types.Exact<{
  useCases: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type SetUseCasesMutation = { __typename: 'Mutation', setMyUseCases: Array<{ __typename: 'UseCase', id: string }> };


export const SetUseCasesDocument = `
    mutation SetUseCases($useCases: [String!]!) {
  setMyUseCases(useCases: $useCases) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    SetUseCases: build.mutation<SetUseCasesMutation, SetUseCasesMutationVariables>({
      query: (variables) => ({ document: SetUseCasesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSetUseCasesMutation } = injectedRtkApi;

