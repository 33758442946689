import { mergeClasses, Button, Label, Text } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';

import { LoadingSpinner } from 'components/loading-spinner';
import { ReactComponent as DefaultImage } from 'media/goosechase-icon-light.svg';
import { PhotoUploadUIProps } from 'components/photo-upload';

interface PhotoUploadImageProps {
  src?: string;
  alt?: string;
  loading: boolean;
}

const PhotoUploadImage = ({
  src,
  loading = false,
  alt = 'Experience image',
}: PhotoUploadImageProps) => (
  <div
    className={mergeClasses(
      'relative shrink-0 w-[128px] h-[128px] rounded-xl overflow-hidden flex items-center justify-center',
      {
        'bg-offWhite': !src,
      },
    )}>
    {loading ? (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingSpinner />
      </div>
    ) : src ? (
      <img src={src} className="rounded-xl" alt={alt} />
    ) : (
      <div className="w-[42px] h-[72px]">
        <DefaultImage />
      </div>
    )}
  </div>
);

export const PhotoUploadUI = ({
  label,
  photoUrl,
  uploading,
  onClick,
  errorMessage,
  alt,
}: PhotoUploadUIProps) => {
  const { t } = useTranslate('pages.createExperience.createExperienceForm');
  return (
    <div>
      <Label size="sm" className="block mb-2">
        {label.toUpperCase()}
      </Label>
      <div className="flex gap-5">
        <PhotoUploadImage src={photoUrl ?? ''} loading={uploading} alt={alt} />
        <div className="flex flex-col gap-2">
          <Button
            type="button"
            label={t('photoId.button')}
            leftIcon="Plus"
            size="sm"
            outlined
            onClick={onClick}
            disabled={uploading}
            className="w-fit"
          />
          <Text>{t('photoId.description')}</Text>
        </div>
      </div>
      <div className="flex justify-between mt-2">
        <Text size="xs" className="text-vibrantRed">
          {errorMessage ?? ''}
        </Text>
      </div>
    </div>
  );
};
