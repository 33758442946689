import { TFunction } from 'i18next';

import { Dropdown, Label, mergeClasses, Text } from '@goosechase/ui';

import { useTranslate } from '../../util/i18n';
import { HelpCircle } from '../help-circle.component';

const RADIUS_OPTIONS = [50, 100, 250, 500, 1000, 2000, 5000];

interface RadiusDropdownOptionType {
  id: string;
  value: number;
  label: string;
}

const convertRadiusToDropdownOption = (t: TFunction, radius: number): RadiusDropdownOptionType => ({
  id: String(radius),
  value: radius,
  label: t(`options.${radius}`),
});

const RadiusDropdownOption = (option: RadiusDropdownOptionType) => (
  <div className="px-4 py-3">
    <Text size="sm">{option.label}</Text>
  </div>
);

interface RadiusDropdownProps {
  className?: string;
  value: number;
  onChange: (value: number) => void;
}

export const RadiusDropdown = ({ className, value, onChange }: RadiusDropdownProps) => {
  const { t } = useTranslate('gpsLocationForm.radiusDropdown');

  const options = RADIUS_OPTIONS.map((radius) => convertRadiusToDropdownOption(t, radius));

  const selectedDropdownOption = convertRadiusToDropdownOption(t, value);

  return (
    <div className={mergeClasses('flex flex-col gap-2', className)}>
      <div className="flex flex-row gap-2">
        <Label className="uppercase" size="sm">
          {t('label')}
        </Label>
        <HelpCircle id="location-tooltip" tooltipText={t('tooltip')} />
      </div>
      <Dropdown
        buttonClassName="w-full"
        label={selectedDropdownOption.label}
        options={options}
        selectedOptionId={selectedDropdownOption.id}
        customRenderOption={RadiusDropdownOption}
        onSelect={(val: string) => onChange(Number(val))}
      />
    </div>
  );
};
