/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type ExperienceFormDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ExperienceFormDetailsQuery = { __typename: 'Query', experience: { __typename: 'Experience', id: string, displayName: string, description: string, location: string | null, latitude: string | null, longitude: string | null, password: string | null, teamCreationMode: Types.TeamCreationMode, studioDefaultTeamMaxSize: number | null, studioDefaultTeamMode: Types.TeamMode, defaultVisible: boolean, assets: Array<{ __typename: 'ExperienceAsset', id: string, url: string, role: Types.ExperienceAssetRole }> } | null };


export const ExperienceFormDetailsDocument = `
    query ExperienceFormDetails($id: ID!) {
  experience(id: $id) {
    id
    displayName
    description
    location
    latitude
    longitude
    password
    teamCreationMode
    studioDefaultTeamMaxSize
    studioDefaultTeamMode
    defaultVisible
    assets {
      id
      url
      role
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ExperienceFormDetails: build.query<ExperienceFormDetailsQuery, ExperienceFormDetailsQueryVariables>({
      query: (variables) => ({ document: ExperienceFormDetailsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useExperienceFormDetailsQuery, useLazyExperienceFormDetailsQuery } = injectedRtkApi;

