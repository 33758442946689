type CreditCardProps = {
  type: string;
};

export const CreditCard = (props: CreditCardProps) => {
  if (props.type === 'visa') {
    return (
      <svg
        width="35"
        height="24"
        viewBox="0 0 35 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="34" height="23" rx="3.5" fill="white" stroke="#D9D9D9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6253 16.2582H8.50494L6.91495 10.1924C6.83949 9.91334 6.67925 9.66667 6.44354 9.5504C5.85531 9.25823 5.20712 9.0257 4.5 8.90843V8.6749H7.91567C8.38708 8.6749 8.74064 9.0257 8.79957 9.43313L9.62454 13.8086L11.7438 8.6749H13.8052L10.6253 16.2582ZM14.9838 16.2582H12.9813L14.6302 8.6749H16.6327L14.9838 16.2582ZM19.2234 10.7757C19.2823 10.3673 19.6359 10.1337 20.0483 10.1337C20.6965 10.0751 21.4026 10.1924 21.9919 10.4835L22.3454 8.85081C21.7562 8.61727 21.108 8.5 20.5198 8.5C18.5762 8.5 17.162 9.55041 17.162 11.0082C17.162 12.1173 18.1637 12.6996 18.8708 13.0504C19.6359 13.4002 19.9305 13.6338 19.8716 13.9835C19.8716 14.5082 19.2823 14.7418 18.6941 14.7418C17.9869 14.7418 17.2798 14.5669 16.6327 14.2747L16.2791 15.9085C16.9862 16.1996 17.7512 16.3169 18.4584 16.3169C20.6376 16.3745 21.9919 15.3251 21.9919 13.75C21.9919 11.7665 19.2234 11.6502 19.2234 10.7757ZM29 16.2582L27.41 8.6749H25.7022C25.3486 8.6749 24.9951 8.90843 24.8772 9.25823L21.9329 16.2582H23.9943L24.4058 15.1502H26.9386L27.1743 16.2582H29ZM25.9968 10.7171L26.585 13.5751H24.9361L25.9968 10.7171Z"
          fill="#172B85"
        />
      </svg>
    );
  }

  if (props.type === 'mastercard') {
    return (
      <svg
        width="35"
        height="24"
        viewBox="0 0 35 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="34" height="23" rx="3.5" fill="white" stroke="#D9D9D9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.75 17.1569C16.5584 18.1852 15.0128 18.806 13.3238 18.806C9.55511 18.806 6.5 15.7154 6.5 11.903C6.5 8.09057 9.55511 5 13.3238 5C15.0128 5 16.5584 5.62075 17.75 6.64903C18.9416 5.62075 20.4872 5 22.1762 5C25.9449 5 29 8.09057 29 11.903C29 15.7154 25.9449 18.806 22.1762 18.806C20.4872 18.806 18.9416 18.1852 17.75 17.1569Z"
          fill="#ED0006"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.75 17.1569C19.2172 15.8908 20.1475 14.0068 20.1475 11.903C20.1475 9.79917 19.2172 7.91517 17.75 6.64903C18.9416 5.62075 20.4872 5 22.1762 5C25.9449 5 29 8.09057 29 11.903C29 15.7154 25.9449 18.806 22.1762 18.806C20.4872 18.806 18.9416 18.1852 17.75 17.1569Z"
          fill="#F9A000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.75 6.64906C19.2172 7.9152 20.1475 9.79919 20.1475 11.903C20.1475 14.0068 19.2172 15.8908 17.75 17.1569C16.2828 15.8908 15.3525 14.0068 15.3525 11.903C15.3525 9.79919 16.2828 7.9152 17.75 6.64906Z"
          fill="#FF5E00"
        />
      </svg>
    );
  }
  return (
    <svg width="35" height="24" viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31 0.5H4C2.067 0.5 0.5 2.067 0.5 4V20C0.5 21.933 2.067 23.5 4 23.5H31C32.933 23.5 34.5 21.933 34.5 20V4C34.5 2.067 32.933 0.5 31 0.5Z"
        fill="white"
        stroke="#D9D9D9"
      />
      <path
        d="M24.4639 6.93787C24.7375 6.93787 24.9613 7.16171 24.9613 7.4353V8.43018H17.4998H10.0382V7.4353C10.0382 7.16171 10.2621 6.93787 10.5356 6.93787H24.4639ZM24.9613 11.4148V17.384C24.9613 17.6576 24.7375 17.8815 24.4639 17.8815H10.5356C10.2621 17.8815 10.0382 17.6576 10.0382 17.384V11.4148H24.9613ZM10.5356 5.44556C9.43818 5.44556 8.5459 6.33783 8.5459 7.4353V17.384C8.5459 18.4815 9.43818 19.3738 10.5356 19.3738H24.4639C25.5613 19.3738 26.4536 18.4815 26.4536 17.384V7.4353C26.4536 6.33783 25.5613 5.44556 24.4639 5.44556H10.5356ZM12.2767 14.8969C11.8632 14.8969 11.5305 15.2295 11.5305 15.643C11.5305 16.0565 11.8632 16.3892 12.2767 16.3892H13.769C14.1825 16.3892 14.5151 16.0565 14.5151 15.643C14.5151 15.2295 14.1825 14.8969 13.769 14.8969H12.2767ZM16.2562 14.8969C15.8427 14.8969 15.51 15.2295 15.51 15.643C15.51 16.0565 15.8427 16.3892 16.2562 16.3892H19.7382C20.1517 16.3892 20.4844 16.0565 20.4844 15.643C20.4844 15.2295 20.1517 14.8969 19.7382 14.8969H16.2562Z"
        fill="#A6A3A4"
      />
    </svg>
  );
};
