import { IconName } from '@goosechase/ui';
import { AppRoutes } from './routes';

export const EXPERIENCE_SECTIONS = ['create', 'publish', 'review'] as const;
export type ExperienceSection = (typeof EXPERIENCE_SECTIONS)[number];

interface ExperienceSectionNavLink {
  name: string;
  path: string;
  activeIcon: IconName;
  inactiveIcon: IconName;
}

export const EXPERIENCE_SECTION_NAV_LINKS: Record<ExperienceSection, ExperienceSectionNavLink[]> = {
  create: [
    {
      name: 'details',
      path: AppRoutes.SUB_PATHS.EDIT,
      activeIcon: 'ClipboardFilled',
      inactiveIcon: 'Clipboard',
    },
    {
      name: 'missions',
      path: AppRoutes.SUB_PATHS.MISSIONS,
      activeIcon: 'FlagFilled',
      inactiveIcon: 'Flag',
    },
    {
      name: 'broadcasts',
      path: AppRoutes.SUB_PATHS.BROADCASTS,
      // TODO: Update icons when implementing this page
      activeIcon: 'AnnouncementFilled',
      inactiveIcon: 'Announcement',
    },
    {
      name: 'branding',
      path: AppRoutes.SUB_PATHS.BRANDING,
      activeIcon: 'PaletteFilled',
      inactiveIcon: 'Palette',
    },
  ],
  publish: [
    {
      name: 'participants',
      path: AppRoutes.SUB_PATHS.PARTICIPANTS,
      activeIcon: 'ParticipantsFilled',
      inactiveIcon: 'Participants',
    },
    {
      name: 'startEnd',
      path: AppRoutes.SUB_PATHS.START_END,
      // TODO: Update icons when implementing this page
      activeIcon: 'CheckeredFlag',
      inactiveIcon: 'CheckeredFlag',
    },
  ],
  review: [
    {
      name: 'feed',
      path: AppRoutes.SUB_PATHS.FEED,
      activeIcon: 'FeedFilled',
      inactiveIcon: 'Feed',
    },
    {
      name: 'leaderboard',
      path: AppRoutes.SUB_PATHS.LEADERBOARD,
      activeIcon: 'PodiumFilled',
      inactiveIcon: 'Podium',
    },
    {
      name: 'submissions',
      path: AppRoutes.SUB_PATHS.SUBMISSIONS,
      activeIcon: 'SubmissionsFilled',
      inactiveIcon: 'Submissions',
    },
    {
      name: 'stats',
      path: AppRoutes.SUB_PATHS.STATS,
      activeIcon: 'Dashboard',
      inactiveIcon: 'Dashboard',
    },
  ],
};
