import { createListenerMiddleware, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { Nullable } from 'types/util';
import { analytics } from 'util/analytics';

const AUTH_TOKEN_STORAGE_KEY = 'gc_auth_token';
const INTERCOM_HMAC_STORAGE_KEY = 'gc_intercom_user_hmac';
const USER_ID_STORAGE_KEY = 'gc_user_id';

export interface AuthState {
  accessToken: Nullable<string>;
  isLoggedIn: boolean;
  forcedLogout: boolean;
  impersonationActive: boolean;
  intercomHmac: Nullable<string>;
  userId: Nullable<string>;
}

const initialState: AuthState = {
  accessToken: null,
  isLoggedIn: false,
  forcedLogout: false,
  impersonationActive: false,
  intercomHmac: null,
  userId: null,
};

const identifyUser = (userId: string, intercomHmac: string) => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  analytics.identify(
    userId,
    {},
    {
      integrations: {
        Intercom: {
          user_hash: intercomHmac,
        },
      },
    },
  );
};

const parseAccessToken = (accessToken: string | null) => {
  try {
    if (!accessToken) {
      return {};
    }
    const parsed = JSON.parse(window.atob(accessToken.split('.')[1]).toString());
    return parsed;
  } catch {
    return {};
  }
};

export const rehydrateAuthState = (): AuthState => {
  const accessToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  const intercomHmac = localStorage.getItem(INTERCOM_HMAC_STORAGE_KEY) ?? null;
  const userId = localStorage.getItem(USER_ID_STORAGE_KEY) ?? null;
  const { impersonator } = parseAccessToken(accessToken);

  if (intercomHmac && userId) {
    identifyUser(userId, intercomHmac);
  }

  return {
    ...initialState,
    accessToken,
    intercomHmac,
    userId,
    isLoggedIn: Boolean(accessToken),
    impersonationActive: Boolean(impersonator),
  };
};

export interface AuthActionPayload {
  accessToken: string;
  userId: string;
  intercomHmac: string | null;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthActionPayload>) => {
      state.accessToken = action.payload.accessToken;
      state.intercomHmac = action.payload.intercomHmac;
      state.userId = action.payload.userId;
      state.isLoggedIn = true;
      state.forcedLogout = false;
    },
    setImpersonationActive: (state, action: PayloadAction<boolean>) => {
      state.impersonationActive = action.payload;
    },
    clearAccessToken: (state) => {
      state.accessToken = null;
    },
    // TODO: Use forced logout flag to display a banner/toast
    // to the user that their credentials are out of date.
    logout: (state, action: PayloadAction<{ forced: boolean }>) => {
      state.accessToken = initialState.accessToken;
      state.isLoggedIn = initialState.isLoggedIn;
      state.userId = initialState.userId;
      state.forcedLogout = action.payload.forced;
      state.impersonationActive = initialState.impersonationActive;
      state.intercomHmac = initialState.intercomHmac;
    },
  },
});

export const authMiddleware = createListenerMiddleware();
// Automatically persist auth token to local storage when set in state
authMiddleware.startListening({
  actionCreator: authSlice.actions.setAuthState,
  effect: (action) => {
    localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, action.payload.accessToken);
    localStorage.setItem(USER_ID_STORAGE_KEY, action.payload.userId);
    if (action.payload.intercomHmac) {
      localStorage.setItem(INTERCOM_HMAC_STORAGE_KEY, action.payload.intercomHmac);
    }
    // Identify user to segment
    if (action.payload.userId && action.payload.intercomHmac) {
      identifyUser(action.payload.userId, action.payload.intercomHmac);
    }
  },
});

authMiddleware.startListening({
  actionCreator: authSlice.actions.clearAccessToken,
  effect: () => {
    localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
  },
});

authMiddleware.startListening({
  actionCreator: authSlice.actions.logout,
  effect: () => {
    localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
    localStorage.removeItem(INTERCOM_HMAC_STORAGE_KEY);
    localStorage.removeItem(USER_ID_STORAGE_KEY);
  },
});

export const { setAuthState, clearAccessToken, logout } = authSlice.actions;
