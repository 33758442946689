import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { useTranslate } from 'util/i18n';
import { Button } from '@goosechase/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { LabelledField } from 'components/labelled-field.component';
import { useEffect } from 'react';

export interface ChangePasswordSubmissionData {
  currentPassword: string;
  newPassword: string;
}

export interface ChangePasswordFormProps {
  onSubmit: (data: ChangePasswordSubmissionData) => void;
  loading?: boolean;
}

const DEFAULT_FIELD_VALUES = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

// TODO: Error handling from server
export const ChangePasswordForm = ({ onSubmit, loading }: ChangePasswordFormProps) => {
  const { t } = useTranslate('pages.changePassword.changePasswordForm');
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitSuccessful },
    reset,
  } = useForm({
    mode: 'onTouched',
    defaultValues: DEFAULT_FIELD_VALUES,
    resolver: zodResolver(
      z
        .object({
          currentPassword: z
            .string()
            .min(1, { message: t('currentPassword.errors.required') as string }),
          newPassword: z
            .string()
            .min(1, { message: t('newPassword.errors.required') as string })
            .min(8, { message: t('newPassword.errors.minLength') as string }),
          confirmNewPassword: z
            .string()
            .min(1, { message: t('newPassword.errors.required') as string })
            .min(8, { message: t('newPassword.errors.minLength') as string }),
        })
        .refine(({ newPassword, confirmNewPassword }) => newPassword === confirmNewPassword, {
          message: t('newPassword.errors.matchingPasswords') as string,
          path: ['confirmNewPassword'],
        }),
    ),
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(DEFAULT_FIELD_VALUES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6">
        <Controller
          control={control}
          name="currentPassword"
          render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => (
            <LabelledField
              label={t('currentPassword.label')}
              type="password"
              inputRef={ref}
              {...fieldProps}
              errorMessage={error?.message}
              autoComplete="current-password"
            />
          )}
        />
      </div>
      <div className="mb-6">
        <Controller
          control={control}
          name="newPassword"
          render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => (
            <LabelledField
              label={t('newPassword.label')}
              type="password"
              inputRef={ref}
              {...fieldProps}
              errorMessage={error?.message}
              autoComplete="new-password"
            />
          )}
        />
      </div>
      <div className="mb-6">
        <Controller
          control={control}
          name="confirmNewPassword"
          render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => (
            <LabelledField
              label={t('confirmNewPassword.label')}
              type="password"
              inputRef={ref}
              {...fieldProps}
              errorMessage={error?.message}
              autoComplete="new-password"
            />
          )}
        />
      </div>
      <Button label={t('submit')} disabled={!isValid || loading} />
    </form>
  );
};
