import { mergeClasses, Text } from '@goosechase/ui';

export interface CharacterCountProps {
  count: number;
  maxCharacters: number;
  isError: boolean;
}

export const CharacterCount = ({ count, maxCharacters, isError }: CharacterCountProps) => {
  return (
    <Text
      className={mergeClasses('text-black-64 self-end', {
        'text-vibrantRed': count > maxCharacters || isError,
      })}
      size="xs">
      {`${count.toString()} / ${maxCharacters.toString()}`}
    </Text>
  );
};
