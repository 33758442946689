import { MainLayout } from '../main-layout.component';
import { NotFound } from './not-found.component';

export const NotFoundPage = () => (
  <MainLayout>
    <div className="flex flex-col grow items-center p-16">
      <NotFound />
    </div>
  </MainLayout>
);
