import classNames from 'classnames';

import { GoosechaseLogo } from './goosechase-logo.component';
import { SIDEBAR_WIDTH } from '../side-nav-bar';

export const EXPERIENCE_NAV_BAR_HEIGHT = 'h-28 desktop:h-14';
export const NAV_BAR_HEIGHT = 'h-14';

export type NavBarVariant = 'default' | 'experience';

export interface HeaderNavBarProps {
  children: React.ReactNode;
  secondaryRow?: React.ReactNode;
  variant: NavBarVariant;
}

export const HeaderNavBar = ({ children, variant, secondaryRow }: HeaderNavBarProps) => {
  return (
    <div
      className={classNames('flex w-full', {
        [`bg-black ${EXPERIENCE_NAV_BAR_HEIGHT}`]: variant === 'experience',
        [`bg-white ${NAV_BAR_HEIGHT}`]: variant === 'default',
      })}>
      <div className="flex flex-col w-full">
        <div className="flex">
          <div
            className={classNames(
              'flex h-full justify-center items-center shrink-0',
              SIDEBAR_WIDTH,
              'aspect-square desktop:aspect-auto',
              { 'bg-orange': variant === 'experience' },
            )}>
            <GoosechaseLogo variant={variant} />
          </div>
          <div className="flex grow px-6 h-full">{children}</div>
        </div>
        {secondaryRow && <div className="flex grow px-6 h-full">{secondaryRow}</div>}
      </div>
    </div>
  );
};
