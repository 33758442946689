var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var GPS = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M19.1998 8.79998C19.1998 14.4 11.9998 22.4 11.9998 22.4C11.9998 22.4 4.7998 14.4 4.7998 8.79998C4.7998 6.89042 5.55837 5.05907 6.90864 3.70881C8.2589 2.35854 10.0902 1.59998 11.9998 1.59998C13.9094 1.59998 15.7407 2.35854 17.091 3.70881C18.4412 5.05907 19.1998 6.89042 19.1998 8.79998Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M11.9996 11.2C13.3251 11.2 14.3996 10.1254 14.3996 8.79996C14.3996 7.47448 13.3251 6.39996 11.9996 6.39996C10.6741 6.39996 9.59961 7.47448 9.59961 8.79996C9.59961 10.1254 10.6741 11.2 11.9996 11.2Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
