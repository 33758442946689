/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type DeleteWorkspaceInvitationMutationVariables = Types.Exact<{
  invitationId: Types.Scalars['ID']['input'];
}>;


export type DeleteWorkspaceInvitationMutation = { __typename: 'Mutation', deleteWorkspaceInvitation: { __typename: 'WorkspaceInvitation', id: string, email: string, roleId: string, status: string } };


export const DeleteWorkspaceInvitationDocument = `
    mutation DeleteWorkspaceInvitation($invitationId: ID!) {
  deleteWorkspaceInvitation(invitationId: $invitationId) {
    id
    email
    roleId
    status
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    DeleteWorkspaceInvitation: build.mutation<DeleteWorkspaceInvitationMutation, DeleteWorkspaceInvitationMutationVariables>({
      query: (variables) => ({ document: DeleteWorkspaceInvitationDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteWorkspaceInvitationMutation } = injectedRtkApi;

