/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

module.hot?.accept();
export type BaseMissionFragment_CameraMission_Fragment = { __typename: 'CameraMission', id: string, displayName: string, description: string, points: number, position: number | null, image: string | null, link: string | null, feedVisibility: Types.FeedVisibility, creatorId: string, createdAt: string, updatedAt: string, missionType: Types.MissionType, releaseTrigger: { __typename: 'MissionTrigger', createdAt: string, id: string, missionId: string, relativeAnchor: Types.TriggerTimeAnchorV1 | null, relativeDuration: number | null, relativeUnit: Types.TriggerTimeUnit | null, specificDay: number | null, specificTime: string | null, taskType: Types.MissionTaskType, timing: Types.MissionTriggerTiming, updatedAt: string } | null, expireTrigger: { __typename: 'MissionTrigger', createdAt: string, id: string, missionId: string, relativeAnchor: Types.TriggerTimeAnchorV1 | null, relativeDuration: number | null, relativeUnit: Types.TriggerTimeUnit | null, specificDay: number | null, specificTime: string | null, taskType: Types.MissionTaskType, timing: Types.MissionTriggerTiming, updatedAt: string } | null };

export type BaseMissionFragment_GpsMission_Fragment = { __typename: 'GpsMission', id: string, displayName: string, description: string, points: number, position: number | null, image: string | null, link: string | null, feedVisibility: Types.FeedVisibility, creatorId: string, createdAt: string, updatedAt: string, missionType: Types.MissionType, releaseTrigger: { __typename: 'MissionTrigger', createdAt: string, id: string, missionId: string, relativeAnchor: Types.TriggerTimeAnchorV1 | null, relativeDuration: number | null, relativeUnit: Types.TriggerTimeUnit | null, specificDay: number | null, specificTime: string | null, taskType: Types.MissionTaskType, timing: Types.MissionTriggerTiming, updatedAt: string } | null, expireTrigger: { __typename: 'MissionTrigger', createdAt: string, id: string, missionId: string, relativeAnchor: Types.TriggerTimeAnchorV1 | null, relativeDuration: number | null, relativeUnit: Types.TriggerTimeUnit | null, specificDay: number | null, specificTime: string | null, taskType: Types.MissionTaskType, timing: Types.MissionTriggerTiming, updatedAt: string } | null };

export type BaseMissionFragment_TextMission_Fragment = { __typename: 'TextMission', id: string, displayName: string, description: string, points: number, position: number | null, image: string | null, link: string | null, feedVisibility: Types.FeedVisibility, creatorId: string, createdAt: string, updatedAt: string, missionType: Types.MissionType, releaseTrigger: { __typename: 'MissionTrigger', createdAt: string, id: string, missionId: string, relativeAnchor: Types.TriggerTimeAnchorV1 | null, relativeDuration: number | null, relativeUnit: Types.TriggerTimeUnit | null, specificDay: number | null, specificTime: string | null, taskType: Types.MissionTaskType, timing: Types.MissionTriggerTiming, updatedAt: string } | null, expireTrigger: { __typename: 'MissionTrigger', createdAt: string, id: string, missionId: string, relativeAnchor: Types.TriggerTimeAnchorV1 | null, relativeDuration: number | null, relativeUnit: Types.TriggerTimeUnit | null, specificDay: number | null, specificTime: string | null, taskType: Types.MissionTaskType, timing: Types.MissionTriggerTiming, updatedAt: string } | null };

export type BaseMissionFragmentFragment = BaseMissionFragment_CameraMission_Fragment | BaseMissionFragment_GpsMission_Fragment | BaseMissionFragment_TextMission_Fragment;

export const BaseMissionFragmentFragmentDoc = `
    fragment BaseMissionFragment on Mission {
  __typename
  id
  displayName
  description
  points
  position
  image
  link
  feedVisibility
  creatorId
  createdAt
  updatedAt
  missionType
  releaseTrigger {
    createdAt
    id
    missionId
    relativeAnchor
    relativeDuration
    relativeUnit
    specificDay
    specificTime
    taskType
    timing
    updatedAt
  }
  expireTrigger {
    createdAt
    id
    missionId
    relativeAnchor
    relativeDuration
    relativeUnit
    specificDay
    specificTime
    taskType
    timing
    updatedAt
  }
}
    `;
