import { BroadcastTrigger, CohortState } from 'data/models';
import type { UseFormTrigger, Control } from 'react-hook-form';
import StartDateTime from './start-date-time.component';
import EndDateTime from './end-date-time.component';
import { StartEndFormInputData } from './use-start-end-form';
import { Nullable } from 'types/util';
import { MissionTrigger } from 'util/time-util';

interface StartEndFormUIProps {
  control: Control<StartEndFormInputData>;
  trigger: UseFormTrigger<StartEndFormInputData>;
  onChangeStart: (date: Date | null) => void;
  onChangeEnd: (date: Date | null) => void;
  startDisabled?: boolean;
  endDisabled?: boolean;
  cohortState: CohortState;
  startDateTime: Nullable<Date>;
  missionTriggers?: MissionTrigger[];
  broadcastTriggers?: BroadcastTrigger[];
  experienceTimezone: string;
}

const StartEndFormUI = ({
  control,
  trigger,
  onChangeStart,
  onChangeEnd,
  startDisabled = false,
  endDisabled = false,
  cohortState,
  startDateTime,
  missionTriggers,
  broadcastTriggers,
  experienceTimezone,
}: StartEndFormUIProps) => {
  return (
    <div className="flex flex-col tablet-wide:flex-row gap-x-6 gap-y-2">
      <StartDateTime
        control={control}
        trigger={trigger}
        className="tablet-wide:w-1/2 mb-2"
        onChangeDateTime={onChangeStart}
        disabled={startDisabled}
        cohortState={cohortState}
      />
      <EndDateTime
        control={control}
        trigger={trigger}
        className="tablet-wide:w-1/2"
        onChangeDateTime={onChangeEnd}
        disabled={endDisabled}
        startDateTime={startDateTime}
        missionTriggers={missionTriggers}
        broadcastTriggers={broadcastTriggers}
        experienceTimezone={experienceTimezone}
      />
    </div>
  );
};

export default StartEndFormUI;
