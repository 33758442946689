/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type DeleteMissionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteMissionMutation = { __typename: 'Mutation', deleteMission: { __typename: 'CameraMission', id: string } | { __typename: 'GpsMission', id: string } | { __typename: 'TextMission', id: string } };


export const DeleteMissionDocument = `
    mutation DeleteMission($id: ID!) {
  deleteMission(id: $id) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    DeleteMission: build.mutation<DeleteMissionMutation, DeleteMissionMutationVariables>({
      query: (variables) => ({ document: DeleteMissionDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteMissionMutation } = injectedRtkApi;

