import { useActiveCohortId, useLoadExperienceStartAndEndQuery } from 'data/experiences';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { displayToast } from 'services/toast';
import { useTranslate } from 'util/i18n';
import { MissionFormUI } from '../../components/mission-form/mission-form-ui';
import type {
  CameraMissionFormFields,
  GpsMissionFormFields,
  MissionFormOutputData,
  TextMissionFormFields,
} from '../../components/mission-form/use-mission-form';
import {
  useUpdateCameraMissionMutation,
  useUpdateGpsMissionMutation,
  useUpdateTextMissionMutation,
} from '../../data/missions';
import { MissionUnion, SubmissionSource } from '../../data/models';
import { DistributiveOmit } from '../../global';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { MISSION_TYPENAME_TO_MISSION_TYPE_MAP } from '../../util/mission-util';
import { UpdateMissionTypeConfirmationModal } from './update-mission-type-confirmation-modal';

const convertBooleanToSubmissionSource = (allowCameraRollUploads: boolean): SubmissionSource => {
  if (allowCameraRollUploads) {
    return 'LIVE_AND_LIBRARY';
  }

  return 'LIVE_ONLY';
};

interface EditMissionFormProps {
  initialMission: DistributiveOmit<
    MissionUnion,
    'submissions' | 'myTeamSubmission' | 'releaseAt' | 'expireAt' | 'experience'
  >;
  onSubmitSuccess?: () => void;
  onCancel?: () => void;
}

export const EditMissionForm = ({
  initialMission,
  onCancel,
  onSubmitSuccess,
}: EditMissionFormProps) => {
  const cohortId = useActiveCohortId();
  const [updateCameraMission, { isLoading: isLoadingCameraMission }] =
    useUpdateCameraMissionMutation();
  const [updateTextMission, { isLoading: isLoadingTextMission }] = useUpdateTextMissionMutation();
  const [updateGpsMission, { isLoading: isLoadingGpsMission }] = useUpdateGpsMissionMutation();
  const { isLoading: isLoadingStartAndEndData, data: startAndEndData } =
    useLoadExperienceStartAndEndQuery({ id: cohortId ?? '' }, { skip: !cohortId });
  const experienceTimezone = useExperienceInfo().data?.experience?.timezone ?? 'UTC';
  const { t } = useTranslate('missionForm');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updateMissionData, setUpdateMissionData] = useState<MissionFormOutputData | null>(null);

  const isLoading =
    isLoadingCameraMission ||
    isLoadingTextMission ||
    isLoadingGpsMission ||
    isLoadingStartAndEndData;

  const updateMission = async ({ missionType, ...data }: MissionFormOutputData) => {
    try {
      if (missionType === 'CAMERA') {
        await updateCameraMission({
          params: {
            ...(data as CameraMissionFormFields),
          },
        });
      } else if (missionType === 'TEXT') {
        await updateTextMission({
          params: { ...(data as TextMissionFormFields) },
        });
      } else if (missionType === 'GPS') {
        await updateGpsMission({
          params: { ...(data as GpsMissionFormFields) },
        });
      } else {
        // Unsupported mission type
        throw Error('Editing unsupported mission type');
      }

      displayToast({
        id: data.id,
        type: 'success',
        title: t('saveSuccessToast.title'),
        body: (
          <Trans t={t} i18nKey="saveSuccessToast.body" values={{ missionName: data.displayName }} />
        ),
      });

      onSubmitSuccess?.();
    } catch (e) {
      displayToast({
        id: data.id,
        body: (
          <Trans t={t} i18nKey="saveErrorToast.body" values={{ missionName: data.displayName }} />
        ),
        title: t('saveErrorToast.title'),
        type: 'error',
      });
    }
  };

  const handleSubmit = (data: MissionFormOutputData) => {
    if (initialMission.numSubmissions > 0 && data.missionType !== initialMission.missionType) {
      setShowConfirmationModal(true);
      setUpdateMissionData(data);
    } else {
      return updateMission(data);
    }
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <>
      <MissionFormUI
        defaultValues={{
          ...initialMission,
          points: String(initialMission.points),
          link: initialMission.link ?? undefined,
          missionType: MISSION_TYPENAME_TO_MISSION_TYPE_MAP[initialMission.__typename],
          allowCameraRollUploads:
            'allowCameraRollUploads' in initialMission
              ? convertBooleanToSubmissionSource(initialMission.allowCameraRollUploads)
              : undefined,
        }}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        missionImage={initialMission.image ?? undefined}
        cohortEndDate={startAndEndData?.cohort?.endDate}
        cohortStartDate={startAndEndData?.cohort?.startDate}
        experienceTimezone={experienceTimezone}
        missionStatus={initialMission.status ?? null}
      />
      <UpdateMissionTypeConfirmationModal
        numSubmissions={initialMission.numSubmissions}
        onClose={() => setShowConfirmationModal(false)}
        onSubmit={() => updateMissionData && updateMission(updateMissionData)}
        show={showConfirmationModal}
      />
    </>
  );
};
