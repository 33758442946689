import { Menu } from '@headlessui/react';
import { useState } from 'react';
import { ThemeColor, Icon, IconName, Text, mergeClasses } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import {
  toggleUpgradeExperienceModal,
  useDeleteExperienceMutation,
  useDuplicateExperienceMutation,
  useLeaveExperienceAsManagerMutation,
} from 'data/experiences';
import { ConfirmationModal } from './confirmation-modal';
import { ExperienceActionType } from 'data/models';
import { displayToast } from 'services/toast';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router';
import { AppRoutes, EXPERIENCE_PATH } from 'constants/routes';
import { InviteCollaboratorsModal } from './invite-collaborators-modal.component';
import { useAppDispatch } from 'data/hooks';
import { ShareExperienceModal } from './share-experience-modal.component';
import { Nullable } from 'types/util';

interface DropdownOptionProps {
  label: string;
  icon: IconName;
  onClick: () => void;
  className?: string;
  iconColor?: ThemeColor;
}

const DropdownOption = ({ label, icon, onClick, className, iconColor }: DropdownOptionProps) => {
  return (
    <Menu.Item>
      {({ close }) => {
        const handleOnClick: React.HTMLAttributes<HTMLButtonElement>['onClick'] = (e) => {
          e.preventDefault();
          onClick();
          close();
        };

        return (
          <button
            className={mergeClasses(
              'w-full outline-0 pt-2 pb-2 text-left hover:bg-black-4 ui-active:bg-black-4 flex justify-start items-center',
              className,
            )}
            onClick={handleOnClick}>
            <Icon className="ml-3" name={icon} color={iconColor} size={20} />
            <Text className="px-3 py-1 pl-2 whitespace-nowrap font-semibold">{label}</Text>
          </button>
        );
      }}
    </Menu.Item>
  );
};

export interface ExperienceCardDropdownProps {
  experienceId: string;
  experienceDisplayName: string;
  experienceShareLink: Nullable<string>;
  name: string;
  upgradable: boolean;
  roleId?: string;
}

export const ExperienceCardDropdown = ({
  experienceId,
  experienceDisplayName,
  experienceShareLink,
  name,
  upgradable,
  roleId,
}: ExperienceCardDropdownProps) => {
  const { t } = useTranslate('experienceCard.dropdownOptions');
  const [deleteExperience] = useDeleteExperienceMutation();
  const [duplicateExperience] = useDuplicateExperienceMutation();
  const [leaveExperienceAsManager] = useLeaveExperienceAsManagerMutation();
  const [showModal, setShowModal] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState<ExperienceActionType>();
  const [confirmationCallback, setConfirmationCallback] = useState<() => void>();
  const [showInviteCollaboratorsModal, setShowInviteCollaboratorsModal] = useState(false);
  const [showShareExperienceModal, setShowShareExperienceModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const confirmAction = (action: ExperienceActionType, callback: () => void) => {
    setConfirmationAction(action);
    setConfirmationCallback(() => () => {
      callback();
      setConfirmationAction(undefined);
      setConfirmationCallback(undefined);
    });
    setShowModal(true);
  };

  const handleDuplicate = async () => {
    try {
      const response = await duplicateExperience({ id: experienceId }).unwrap();
      displayToast({
        id: experienceId,
        title: t('duplicateToast.title'),
        type: 'success',
        body: (
          <Trans
            t={t}
            i18nKey="duplicateToast.body"
            values={{ experienceName: response.duplicateExperience.displayName }}
          />
        ),
      });
    } catch {
      // TODO: Sentry error
    }
  };

  const handleDelete = async () => {
    try {
      await deleteExperience({ id: experienceId }).unwrap();
      displayToast({
        id: experienceId,
        title: t('deleteSuccessToast.title'),
        type: 'success',
        body: (
          <Trans
            t={t}
            i18nKey="deleteSuccessToast.body"
            values={{
              experienceName: experienceDisplayName,
            }}
          />
        ),
      });
    } catch {
      // TODO: Sentry error
    }
  };

  const handleLeave = async () => {
    try {
      await leaveExperienceAsManager({
        experienceId,
      }).unwrap();
      displayToast({
        id: experienceId,
        title: t('leaveSuccessToast.title'),
        type: 'success',
        body: (
          <Trans
            t={t}
            i18nKey="leaveSuccessToast.body"
            values={{
              experienceName: experienceDisplayName,
            }}
          />
        ),
      });
    } catch {
      displayToast({
        id: experienceId,
        title: t('leaveErrorToast.title'),
        type: 'error',
        body: t('leaveErrorToast.body'),
      });
    }
  };

  const canInviteNewCollaborators = roleId === 'creator' || roleId === 'manager';

  return (
    <Menu as="div" className="relative" onClick={(event) => event.stopPropagation()}>
      <Menu.Button
        aria-label={t('ariaIconLabel') ?? ''}
        className="group flex items-center justify-center rounded-full h-6 w-6 shrink-0 hover:bg-black-12">
        <Icon
          name="More"
          color="black"
          className="group-hover:fill-black group-hover:stroke-black"
          size={24}
        />
      </Menu.Button>
      <Menu.Items className="absolute top-6 right-0 cursor-pointer bg-white border border-black-24 rounded-lg outline-0 overflow-hidden z-10">
        {canInviteNewCollaborators ? (
          <DropdownOption
            label={t('invite')}
            icon="UsersPlus"
            onClick={() => setShowInviteCollaboratorsModal(true)}
          />
        ) : null}
        <DropdownOption
          label={t('share')}
          icon="Share"
          onClick={() => setShowShareExperienceModal(true)}
        />
        <DropdownOption
          label={t('duplicate')}
          icon="Copy"
          onClick={() => confirmAction('duplicate', handleDuplicate)}
        />
        {roleId === 'creator' ? (
          <DropdownOption
            label={t('delete')}
            icon="DeleteBold"
            onClick={() => confirmAction('delete', handleDelete)}
          />
        ) : (
          <DropdownOption label={t('leave')} icon="Logout" onClick={handleLeave} />
        )}
        {upgradable ? (
          <>
            <div className="h-px w-full bg-black-24" />
            <DropdownOption
              label={t('upgrade')}
              icon="YellowOrangeZap"
              onClick={() => {
                navigate(`${EXPERIENCE_PATH}/${experienceId}/${AppRoutes.SUB_PATHS.EDIT}`);
                dispatch(toggleUpgradeExperienceModal());
              }}
              className="bg-yellowOrange-10 hover:bg-yellowOrange-10 ui-active:bg-yellowOrange-10"
            />
          </>
        ) : null}
      </Menu.Items>
      {confirmationAction && confirmationCallback && (
        <ConfirmationModal
          action={confirmationAction}
          show={showModal}
          onSubmit={confirmationCallback}
          onClose={() => setShowModal(false)}
          experienceName={name}
        />
      )}
      {canInviteNewCollaborators ? (
        <InviteCollaboratorsModal
          experienceId={experienceId}
          experienceDisplayName={experienceDisplayName}
          onClose={() => setShowInviteCollaboratorsModal(false)}
          show={showInviteCollaboratorsModal}
        />
      ) : null}
      <ShareExperienceModal
        experienceId={experienceId}
        experienceDisplayName={experienceDisplayName}
        experienceShareLink={experienceShareLink}
        onClose={() => setShowShareExperienceModal(false)}
        show={showShareExperienceModal}
      />
    </Menu>
  );
};
