import { Avatar } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { AppRoutes } from 'constants/routes';
import { NavBarDropdown } from './nav-bar-dropdown.component';
import { type NavBarVariant } from './header-nav-bar.component';
import { useLoadMyUserQuery } from '../../data/auth';
import { useActiveWorkspaceWithSubscriptionQuery } from 'data/workspaces';
import { useMultipleWorkspacesFeatureFlag } from '../../hooks/use-feature-flag.hook';

interface RenderButtonProps {
  srcImage?: string;
  alt: string;
}

const DropdownButton = ({ srcImage, alt }: RenderButtonProps) => (
  <Avatar size="xs3" src={srcImage} alt={alt} />
);

interface AccountDropdownProps {
  className?: string;
  variant?: NavBarVariant;
}

export const AccountDropdown = ({ variant = 'default', className }: AccountDropdownProps) => {
  const { t } = useTranslate('headerNavBar.accountDropdown');
  const { data } = useLoadMyUserQuery();
  const { data: workspaceData } = useActiveWorkspaceWithSubscriptionQuery();
  const multipleWorkspacesFlag = useMultipleWorkspacesFeatureFlag();

  const currentWorkspace =
    workspaceData?.myActiveWorkspace &&
    !workspaceData.myActiveWorkspace.subscription.plan.upgradable
      ? workspaceData.myActiveWorkspace
      : null;

  return (
    <NavBarDropdown
      className={className}
      variant={variant}
      dropdownClassName="right-0"
      ariaLabel={t('ariaDropdownLabel') ?? ''}
      dropdownButton={
        <DropdownButton
          srcImage={data?.myUser.profile?.photoUrl ?? undefined}
          alt={t('userProfileAlt')}
        />
      }>
      {multipleWorkspacesFlag ? (
        <>
          <NavBarDropdown.DropdownLinkItem
            useRouter
            label={t('experiences')}
            href={AppRoutes.MY_EXPERIENCES}
          />
          <NavBarDropdown.DropdownLinkItem
            useRouter
            label={t('account')}
            href={AppRoutes.ACCOUNT_INFO}
          />
        </>
      ) : (
        <>
          <NavBarDropdown.DropdownLinkItem
            useRouter
            label={t('account')}
            href={AppRoutes.ACCOUNT_INFO}
          />
          <NavBarDropdown.DropdownLinkItem
            useRouter
            label={t('changePassword')}
            href={AppRoutes.CHANGE_PASSWORD}
          />
          {currentWorkspace ? (
            <>
              <NavBarDropdown.Divider />
              <NavBarDropdown.DropdownLinkItem
                useRouter
                label={currentWorkspace.displayName}
                photoUrl={currentWorkspace.photoUrl}
                withImage
                href={AppRoutes.WORKSPACE}
              />
            </>
          ) : null}
        </>
      )}

      <NavBarDropdown.Divider />
      <NavBarDropdown.DropdownLinkItem useRouter label={t('logout')} href={AppRoutes.LOGOUT} />
    </NavBarDropdown>
  );
};
