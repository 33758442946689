import { useTranslate } from 'util/i18n';
import { useMySharedExperiencesQuery } from 'data/experiences';
import { LoadingSpinner } from 'components/loading-spinner';
import { EmptyState } from 'components/empty-state/empty-state.component';
import { Card } from 'components/card.component';
import { ErrorState } from 'components/error-state';
import { PageHeadline } from '../../components/page-headline.component';
import { transformExperiencesDataToExperiencesList } from './util';
import { ReactComponent as GooseCouple } from 'media/goose-couple.svg';
import { ExperienceList } from './experience-list.component';

const MySharedExperiencesList = () => {
  const { data, isLoading, isError } = useMySharedExperiencesQuery();
  const { t } = useTranslate('pages.experiences.mySharedExperiences');

  const experiences = transformExperiencesDataToExperiencesList(data?.mySharedExperiences ?? []);

  if (isLoading) {
    return (
      <Card>
        <div className="flex justify-center py-16 px-8">
          <LoadingSpinner />
        </div>
      </Card>
    );
  }

  if (isError) {
    return (
      <Card>
        <div className="flex justify-center py-16 px-8">
          <ErrorState />
        </div>
      </Card>
    );
  }

  if (!experiences || experiences.length === 0) {
    return (
      <Card className="p-12">
        <EmptyState
          text={t('emptyState.text')}
          subtext={t('emptyState.subtext')}
          renderGraphic={() => <GooseCouple />}
        />
      </Card>
    );
  }

  return <ExperienceList experiences={experiences} />;
};

export const MySharedExperiences = () => {
  const { t } = useTranslate('pages.experiences.mySharedExperiences');

  return (
    <>
      <div className="w-full mb-4 tablet-narrow:mb-6">
        <PageHeadline>{t('title')}</PageHeadline>
      </div>
      <MySharedExperiencesList />
    </>
  );
};
