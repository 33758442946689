import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useIsLoggedIn } from 'data/auth/auth.hooks';
import { AppRoutes } from 'constants/routes';

interface Props {
  children?: JSX.Element;
  redirect?: string;
}

export const AuthGate = ({ children, redirect = AppRoutes.LOGIN }: Props) => {
  const isLoggedIn = useIsLoggedIn();
  const location = useLocation();
  // TODO: fetch user profile here

  if (!isLoggedIn) {
    return <Navigate to={redirect} state={{ from: location }} replace />;
  }

  return children ? children : <Outlet />;
};
