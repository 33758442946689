import { Dropdown } from '@goosechase/ui';
import { TFunction } from 'i18next';
import { SubmissionArchiveGroupingInput } from '../../data/models';
import { useTranslate } from '../../util/i18n';

interface GroupSubmissionsDropdownProps {
  value: SubmissionArchiveGroupingInput;
  onChange: (value: SubmissionArchiveGroupingInput) => void;
}

const makeOptions = (t: TFunction) => [
  {
    id: SubmissionArchiveGroupingInput.ByTeam,
    title: t(`options.by_team.title`),
    description: t(`options.by_team.description`),
  },
  {
    id: SubmissionArchiveGroupingInput.ByMission,
    title: t(`options.by_mission.title`),
    description: t(`options.by_mission.description`),
  },
  {
    id: SubmissionArchiveGroupingInput.None,
    title: t(`options.none.title`),
    description: t(`options.none.description`),
  },
];

export const GroupSubmissionsDropdown = ({ value, onChange }: GroupSubmissionsDropdownProps) => {
  const { t } = useTranslate('downloadSubmissions.modal');

  const options = makeOptions(t);

  return (
    <Dropdown
      buttonClassName="w-full overflow-visible"
      optionsClassName="max-h-none z-10"
      containerClassName="relative overflow-visible	"
      label={t(`options.${value.toLowerCase()}.title`)}
      options={options}
      selectedOptionId={value}
      onSelect={(v: string) => onChange(v as SubmissionArchiveGroupingInput)}
    />
  );
};
