import { Headline, Text } from '@goosechase/ui';

interface OnboardingFormLayoutProps {
  title: string;
  subtext: string;
  children: React.ReactNode;
}

export const OnboardingFormLayout = ({ title, subtext, children }: OnboardingFormLayoutProps) => (
  <div>
    <Headline className="mb-2.5" type="primary" size="sm">
      {title}
    </Headline>
    <Text size="sm" className="mb-6">
      {subtext}
    </Text>
    {children}
  </div>
);
