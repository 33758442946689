import { Label, mergeClasses, Text } from '@goosechase/ui';
import { Nullable } from 'types/util';
import { NumberInput } from './number-input';

export type LabelledNumberFieldProps = {
  name: string;
  className?: string;
  label: string;
  hint?: string;
  errorMessage?: string;
  onChange: (value: number | null) => void;
  onBlur: (value: Nullable<number>) => void;
  value: Nullable<number | string>;
  hideSpinButtons?: boolean;
  min?: number;
  autoFocusInput?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const LabelledNumberField = ({
  className,
  label,
  hint,
  errorMessage,
  onChange,
  hideSpinButtons,
  min,
  value,
  autoFocusInput,
  ...htmlProps
}: LabelledNumberFieldProps) => {
  return (
    <div className={mergeClasses('flex flex-col content-start', className)}>
      <Label size="sm" className="block mb-2" htmlFor={htmlProps.name}>
        {label.toUpperCase()}
      </Label>
      <NumberInput
        label={label}
        onChange={onChange}
        error={Boolean(errorMessage)}
        min={min}
        hideSpinButtons={hideSpinButtons}
        value={value}
        className="w-full"
        autoFocusInput={autoFocusInput}
        {...htmlProps}
      />
      {errorMessage && (
        <div className="flex w-full justify-between mt-2">
          <Text size="xs" className="text-vibrantRed">
            {errorMessage ?? ''}
          </Text>
        </div>
      )}
      {hint ? (
        <div className="flex w-full justify-between mt-3">
          <Text className="text-black-64" size="xs">
            {hint}
          </Text>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
