import { useExperienceOutletContext } from '../../components/experience-layout';
import { SubmissionContent } from '../../components/submission-content';
import { MissionTypeName, TeamMode } from 'data/models';
import { LikeButton } from 'components/like-button';
import { BonusPointsCard } from 'components/bonus-points-card';
import { Nullable } from 'types/util';
import { SubmissionCardDropdown } from 'components/submission-card/submission-card-dropdown.component';
import { FeedSubmissionCardHeader } from './feed-submission-card-header.component';
import { useDownloadSubmission } from 'hooks/use-download-submission.hook';
import { useCopySubmissionLink } from 'hooks/use-copy-submission-link.hook';

export type FeedSubmissionCardProps = {
  submissionId: string;
  teamId: string;
  teamImageUrl?: string;
  teamMode: TeamMode;
  teamName: string;
  submissionDate: Date;
  missionName: string;
  missionDescription: string;
  missionId: string;
  missionPoints: number;
  missionType: MissionTypeName;
  submissionCaption?: string;
  submissionImageSrc?: string;
  submissionVideoSrc?: string;
  submissionLatitude?: string;
  submissionLongitude?: string;
  submissionAnswer?: string;
  isLiked: boolean;
  numLikes: number;
  onClickLike: () => Promise<void>;
  onDeleteSubmissionBonus: (submissionBonusId: string) => Promise<void>;
  submissionBonuses: {
    bonusId: string;
    value: number;
    createdAt: Date;
    onDelete?: (bonusId: string) => void;
    note: Nullable<string>;
  }[];
  onDelete: () => void;
  onSubmissionClick: (submissionId: string) => void;
  onClickBonus: (submissionId: string) => void;
};

export const FeedSubmissionCard = ({
  submissionId,
  submissionDate,
  teamId,
  teamMode,
  teamName,
  teamImageUrl,
  missionId,
  missionName,
  missionDescription,
  missionPoints,
  missionType,
  submissionCaption,
  submissionAnswer,
  submissionImageSrc,
  submissionLatitude,
  submissionLongitude,
  submissionVideoSrc,
  isLiked,
  numLikes,
  onClickLike,
  submissionBonuses,
  onDeleteSubmissionBonus,
  onDelete,
  onSubmissionClick,
  onClickBonus,
}: FeedSubmissionCardProps) => {
  const { experienceId } = useExperienceOutletContext();
  const downloadSubmission = useDownloadSubmission({
    filename: submissionId,
    missionType: missionType,
    mediaSrc: submissionVideoSrc ?? submissionImageSrc,
  });
  const copySubmissionLink = useCopySubmissionLink();

  const handleCopy = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    copySubmissionLink(submissionId);
  };

  const handleClick = () => {
    onSubmissionClick(submissionId);
  };

  const handleBonus = () => {
    onClickBonus(submissionId);
  };

  // Videos aren't clickable because they play inline when clicked
  // Maps aren't because they are already interactive elements and can't be nested in another button
  const isContentClickable =
    (missionType === 'CameraMission' && submissionVideoSrc) || missionType === 'GpsMission';

  return (
    <div className="flex flex-col gap-3">
      <FeedSubmissionCardHeader
        submissionDate={submissionDate}
        teamId={teamId}
        teamMode={teamMode}
        teamName={teamName}
        teamImageUrl={teamImageUrl}
        experienceId={experienceId}
        missionId={missionId}
        missionName={missionName}
        missionDescription={missionDescription}
        missionPoints={missionPoints}
        missionType={missionType}
        submissionCaption={submissionCaption}
      />
      <div className="flex flex-col gap-3 tablet-wide:ml-24">
        {isContentClickable ? (
          <SubmissionContent
            className="rounded-lg"
            missionType={missionType}
            submissionCaption={submissionCaption}
            submissionAnswer={submissionAnswer}
            submissionImageSrc={submissionImageSrc}
            submissionLatitude={submissionLatitude}
            submissionLongitude={submissionLongitude}
            submissionVideoSrc={submissionVideoSrc}
          />
        ) : (
          <button onClick={handleClick} className="text-left">
            <SubmissionContent
              className="rounded-lg"
              missionType={missionType}
              submissionCaption={submissionCaption}
              submissionAnswer={submissionAnswer}
              submissionImageSrc={submissionImageSrc}
              submissionLatitude={submissionLatitude}
              submissionLongitude={submissionLongitude}
              submissionVideoSrc={submissionVideoSrc}
            />
          </button>
        )}
        {submissionBonuses.map((submissionBonus) => (
          <BonusPointsCard
            key={submissionBonus.bonusId}
            {...submissionBonus}
            onDelete={onDeleteSubmissionBonus}
          />
        ))}

        <div className="flex flex-row-reverse justify-between">
          <SubmissionCardDropdown
            onCopy={handleCopy}
            onDownload={downloadSubmission}
            onDelete={onDelete}
            onBonus={handleBonus}
          />
          <LikeButton isLiked={isLiked} numLikes={numLikes} onClick={onClickLike} />
        </div>
      </div>
    </div>
  );
};
