import { Icon, Label, mergeClasses, type ThemeColor } from '@goosechase/ui';

import { CohortState } from 'data/models';
import { useTranslate } from 'util/i18n';
import { AppRoutes, EXPERIENCE_PATH } from '../../constants/routes';
import { Link } from '../link.component';

const CONTAINER_COLOR: Record<CohortState, string> = {
  ENDED: 'bg-black-4 border-black-32',
  ACTIVE: 'bg-notificationGreen border-vibrantGreen',
  SCHEDULED: 'bg-yellowOrange-10 border-yellowOrange',
  DRAFT: 'bg-vibrantBlue-10 border-vibrantBlue',
};

const ICON_COLOR: Record<CohortState, ThemeColor> = {
  ENDED: 'black-64',
  ACTIVE: 'vibrantGreen',
  SCHEDULED: 'yellowOrange',
  DRAFT: 'vibrantBlue',
};

export interface ExperienceStatusBadgeProps {
  className?: string;
  status: CohortState;
  isLink?: boolean;
}

export const ExperienceStatusBadge = ({
  className,
  status,
  isLink = false,
}: ExperienceStatusBadgeProps) => {
  const { t } = useTranslate('experienceStatus');

  return (
    <div
      className={mergeClasses(
        'flex flex-row items-center gap-2.5 py-1 px-3 text-black rounded-pill border w-fit',
        CONTAINER_COLOR[status],
        className,
      )}>
      <Icon className="hidden desktop:block" name="Circle" size={12} color={ICON_COLOR[status]} />
      <Label size="sm" className={mergeClasses('uppercase', { 'cursor-pointer': isLink })}>
        {t(status)}
      </Label>
    </div>
  );
};

export interface ExperienceStatusBadgeLinkProps extends ExperienceStatusBadgeProps {
  experienceId: string;
}

export const ExperienceStatusBadgeLink = ({
  experienceId,
  ...props
}: ExperienceStatusBadgeLinkProps) => {
  return (
    <Link
      className="rounded-pill outline-black outline-offset-2"
      useRouter
      href={`${EXPERIENCE_PATH}/${experienceId}/${AppRoutes.SUB_PATHS.START_END}`}
      aria-label={props.status}>
      <ExperienceStatusBadge isLink {...props} />
    </Link>
  );
};
