import { AppRoutes } from 'constants/routes';
import { useNavigate } from 'react-router';
import { Nullable } from 'types/util';
import { DistrictAndSchoolWidePlan } from './district-and-school-wide-plan';
import { EducatorBasicPlan } from './educator-basic-plan';
import { EducatorPlusPlan } from './educator-plus-plan';
import { School500Plan } from './school-500-plan';

type EducatorPlansProps = {
  plans: {
    priceUsdCents: number;
    id: string;
    chargebeeSku: Nullable<string>;
  }[];
  currentPlan?: {
    id: string;
    chargebeeSku: Nullable<string>;
  };
  onClick: (planKey: string) => () => void;
};

export const EducatorPlans = (props: EducatorPlansProps) => {
  const navigate = useNavigate();

  const goToQuoteForm = () => {
    window.open(' https://www.goosechase.com/pricing/education#eduPricingReqQuote');
  };

  const goToNewExperienceForm = () => navigate(AppRoutes.CREATE_EXPERIENCE);

  const educatorPlusPlan = props.plans?.find((plan) => plan.id === 'edu-plus-40');
  const school500 = props.plans?.find((plan) => plan.id === 'school-500');

  return (
    <>
      <EducatorBasicPlan
        priceUsdCents={0}
        selected={['edu-basic', 'edu-basic-org'].includes(props.currentPlan?.id ?? '')}
        onClick={goToNewExperienceForm}
      />
      <EducatorPlusPlan
        priceUsdCents={educatorPlusPlan?.priceUsdCents ?? 0}
        selected={['edu-plus-40', 'edu-plus-50'].includes(props.currentPlan?.id ?? '')}
        onClick={props.onClick('edu-plus-40')}
      />
      <School500Plan
        priceUsdCents={school500?.priceUsdCents ?? 0}
        selected={['school-500'].includes(props.currentPlan?.id ?? '')}
        onClick={props.onClick('school-500')}
      />
      <DistrictAndSchoolWidePlan
        selected={[
          'classroom-unlimited',
          'edu-unlimited',
          'k12-district-wide',
          'k12-school-wide',
        ].includes(props.currentPlan?.id ?? '')}
        onClick={goToQuoteForm}
      />
    </>
  );
};
