import { broadcastsSlice } from './broadcasts.slice';
import {
  sendBroadcastApi,
  loadBroadcastTriggersApi,
  scheduleBroadcastApi,
  deleteBroadcastApi,
  updateScheduledBroadcastApi,
  type BroadcastsQuery,
} from './broadcasts.api';

export const { useScheduleBroadcastMutation } = scheduleBroadcastApi;
export const { useSendBroadcastMutation } = sendBroadcastApi;
export const { useLoadBroadcastsForCohortQuery } = loadBroadcastTriggersApi;
export const { useDeleteBroadcastMutation } = deleteBroadcastApi;
export const { useUpdateScheduledBroadcastMutation } = updateScheduledBroadcastApi;

export const { selectBroadcast, closeAndResetBroadcastPanel, openCreateBroadcastPanel } =
  broadcastsSlice.actions;

export const broadcastsReducer = broadcastsSlice.reducer;

export type { BroadcastsQuery };

export * from './broadcasts.hooks';
