import { Modal, Text } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import { Trans } from 'react-i18next';
import { CollaboratorsList } from './collaborators-list.component';
import { InviteCollaboratorForm } from './invite-collaborator-form.component';

interface InviteCollaboratorsModalProps {
  show: boolean;
  onClose: () => void;
  experienceDisplayName: string;
  experienceId: string;
}

export const InviteCollaboratorsModal = ({
  show,
  onClose,
  experienceDisplayName,
  experienceId,
}: InviteCollaboratorsModalProps) => {
  const { t } = useTranslate('experienceCard.inviteCollaboratorsModal');

  return (
    <Modal
      className="w-[calc(100vw-24px)] tablet-narrow:w-[592px] overflow-visible"
      show={show}
      onClose={onClose}>
      <Modal.Header title={t('header') ?? undefined} />
      <Modal.Body className="py-6">
        <div className="flex flex-col gap-5">
          <Text size="sm">
            <Trans i18nKey="body">{t('body', { experienceDisplayName })}</Trans>
          </Text>
          <InviteCollaboratorForm experienceId={experienceId} />
          <CollaboratorsList experienceId={experienceId} />
        </div>
      </Modal.Body>
    </Modal>
  );
};
