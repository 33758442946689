import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from 'types/util';

export interface OnboardingState {
  useCases: string[];
  inviteCode: Nullable<string>;
}

const initialState: OnboardingState = {
  useCases: [],
  inviteCode: null,
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setSelectedUseCases: (state, action: PayloadAction<string[]>) => {
      state.useCases = action.payload;
    },
  },
});
