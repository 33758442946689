/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type SignUpMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  password: Types.Scalars['String']['input'];
  marketingEmailsAccepted: Types.Scalars['Boolean']['input'];
}>;


export type SignUpMutation = { __typename: 'Mutation', register: { __typename: 'AuthPayload', userId: string, token: string, intercomHmac: string | null } };


export const SignUpDocument = `
    mutation SignUp($email: String!, $password: String!, $marketingEmailsAccepted: Boolean!) {
  register(
    params: {email: $email, password: $password, marketingEmailsAccepted: $marketingEmailsAccepted}
  ) {
    userId
    token
    intercomHmac
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    SignUp: build.mutation<SignUpMutation, SignUpMutationVariables>({
      query: (variables) => ({ document: SignUpDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSignUpMutation } = injectedRtkApi;

