import { z } from 'zod';
import { createListenerMiddleware, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '../../types/util';
import { authSlice } from 'data/auth/auth.slice';

const EXPERIENCE_LIST_FILTER_STORAGE_KEY = 'gc_exp_list_filter';

type SelectedExperience = {
  id: string;
  displayName: string;
  photoUrl: Nullable<string>;
};

const PERSONAL_EXPERIENCE_FILTER = ['personal', 'shared'] as const;
export type PersonalExperienceFilter = (typeof PERSONAL_EXPERIENCE_FILTER)[number];

const experienceListFilterSchema = z.discriminatedUnion('type', [
  z.object({ type: z.literal('personal'), value: z.enum(PERSONAL_EXPERIENCE_FILTER) }),
  z.object({
    type: z.literal('organization'),
    id: z.string().uuid(),
    photoUrl: z.string().nullable(),
    displayName: z.string(),
  }),
]);

export type ExperienceListFilter = z.infer<typeof experienceListFilterSchema>;

export interface ExperiencesState {
  activeExperienceId: Nullable<string>;
  activeCohortId: Nullable<string>;
  upgradeExperienceModalOpen: boolean;
  acceptedExperienceInvitationModalOpen: boolean;
  selectedExperience: Nullable<SelectedExperience>;
  copyExperienceModalOpen: boolean;
  experienceListFilter: ExperienceListFilter;
}

const initialState: ExperiencesState = {
  // activeExperienceId based on ExperienceLayout's route params.
  // This value is stored in state so it can be accessed via getState in our endpoints
  activeExperienceId: null,
  activeCohortId: null,
  upgradeExperienceModalOpen: false,
  acceptedExperienceInvitationModalOpen: false,
  selectedExperience: null,
  copyExperienceModalOpen: false,
  experienceListFilter: { type: 'personal', value: 'personal' },
};

const resetExperienceListFilter = (): ExperienceListFilter => {
  // Reset the data in localStorage to clean it up
  localStorage.setItem(
    EXPERIENCE_LIST_FILTER_STORAGE_KEY,
    JSON.stringify(initialState.experienceListFilter),
  );
  return initialState.experienceListFilter;
};

const parseExperienceListFilterFromLocalStorage = (): ExperienceListFilter => {
  const localStorageValue = localStorage.getItem(EXPERIENCE_LIST_FILTER_STORAGE_KEY);

  // Return initial state value if local storage value is null
  if (localStorageValue === null) {
    return resetExperienceListFilter();
  }

  try {
    const parsedValue = JSON.parse(localStorageValue);
    return experienceListFilterSchema.parse(parsedValue);
  } catch (err) {
    // If any error occurred while parsing, return initial state value
    return resetExperienceListFilter();
  }
};

export const rehydrateExperiencesState = (): ExperiencesState => ({
  ...initialState,
  experienceListFilter: parseExperienceListFilterFromLocalStorage(),
});

export const experiencesSlice = createSlice({
  name: 'experiences',
  initialState,
  reducers: {
    setActiveExperienceId: (state, action: PayloadAction<Nullable<string>>) => {
      state.activeExperienceId = action.payload;
    },
    setActiveCohortId: (state, action: PayloadAction<Nullable<string>>) => {
      state.activeCohortId = action.payload;
    },
    toggleUpgradeExperienceModal: (state) => {
      state.upgradeExperienceModalOpen = !state.upgradeExperienceModalOpen;
    },
    toggleAcceptedExperienceInvitationModal: (state) => {
      state.acceptedExperienceInvitationModalOpen = !state.acceptedExperienceInvitationModalOpen;
    },
    setSelectedExperience: (state, payload: PayloadAction<Nullable<SelectedExperience>>) => {
      state.selectedExperience = payload.payload;
    },
    toggleCopyExperienceModal: (state) => {
      state.copyExperienceModalOpen = !state.copyExperienceModalOpen;
    },
    setExperienceListFilter: (state, action: PayloadAction<ExperienceListFilter>) => {
      state.experienceListFilter = action.payload;
    },
  },
});

export const experiencesMiddleware = createListenerMiddleware();
// Automatically persist experience list filter to local storage when set in state
experiencesMiddleware.startListening({
  actionCreator: experiencesSlice.actions.setExperienceListFilter,
  effect: (action) => {
    // We can only set values as string in localStorage so JSON.stringify is used
    localStorage.setItem(EXPERIENCE_LIST_FILTER_STORAGE_KEY, JSON.stringify(action.payload));
  },
});

// Logout listener
experiencesMiddleware.startListening({
  actionCreator: authSlice.actions.logout,
  effect: () => {
    // Clear local storage
    localStorage.removeItem(EXPERIENCE_LIST_FILTER_STORAGE_KEY);
  },
});
