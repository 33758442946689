import { Label, Text } from '@goosechase/ui';

interface LabelledSettingProps {
  label: string;
  description: string;
  children: React.ReactNode;
}

export const LabelledSetting = ({ label, description, children }: LabelledSettingProps) => (
  <div className="flex flex-col justify-between gap-3">
    <Label size="lg">{label.toUpperCase()}</Label>
    <Text size="md">{description}</Text>
    {children}
  </div>
);
