import { ShowUpgradeBanner } from '../../data/models';
import { InlineBanner } from '../inline-banner';

type UpgradeExperienceBannerProps = {
  maxParticipantsPerExperience?: number | null;
  maxTeamsPerExperience?: number | null;
  numberOfParticipants?: number;
  showUpgradeBanner?: ShowUpgradeBanner;
  showUpgradeExperienceAction?: boolean;
  shouldDisableIndividualMode?: boolean;
};

interface I18nData {
  key: string;
  values: Record<string, number | null>;
}

interface GetInlineBannerI18nData {
  maxParticipantsPerExperienceFromPlan?: number | null;
  maxParticipantsPerExperienceFromExperience?: number | null;
  maxTeamsPerExperienceFromExperience?: number | null;
  shouldDisableIndividualMode?: boolean;
}

const getInlineBannerI18nData = ({
  maxParticipantsPerExperienceFromPlan,
  maxParticipantsPerExperienceFromExperience,
  maxTeamsPerExperienceFromExperience,
  shouldDisableIndividualMode,
}: GetInlineBannerI18nData): I18nData => {
  let key = 'maxParticipantsPerExperience';
  if (typeof maxParticipantsPerExperienceFromPlan === 'number') {
    key = 'maxParticipantsPerExperienceReached';
  } else if (shouldDisableIndividualMode) {
    key = 'maxTeamsPerExperience';
  }

  const values = {
    maxTeamsPerExperience: maxTeamsPerExperienceFromExperience ?? null,
    maxParticipantsPerExperience: maxParticipantsPerExperienceFromExperience ?? null,
  };

  return {
    key,
    values,
  };
};

export const UpgradeExperienceBanner = (props: UpgradeExperienceBannerProps) => {
  const hasParticipantLimit = typeof props.maxParticipantsPerExperience === 'number';
  const hasTeamLimit = typeof props.maxTeamsPerExperience === 'number';
  const hasParticipants = typeof props.numberOfParticipants === 'number';

  const alwaysShowBanner =
    props?.showUpgradeBanner === ShowUpgradeBanner.Always && (hasParticipantLimit || hasTeamLimit);

  const showBannerAtParticipantLimit =
    props?.showUpgradeBanner === ShowUpgradeBanner.AtParticipantLimit &&
    hasParticipantLimit &&
    hasParticipants &&
    // both variables have been checked above, they aren't null
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    props.numberOfParticipants! >= props.maxParticipantsPerExperience!;

  const i18nData = getInlineBannerI18nData({
    maxParticipantsPerExperienceFromPlan: props.maxParticipantsPerExperience,
    maxParticipantsPerExperienceFromExperience: props.maxParticipantsPerExperience ?? null,
    maxTeamsPerExperienceFromExperience: props.maxTeamsPerExperience ?? null,
    shouldDisableIndividualMode: props.shouldDisableIndividualMode,
  });

  if (!alwaysShowBanner && !showBannerAtParticipantLimit) {
    return null;
  }

  return (
    <div className="mb-6">
      <InlineBanner
        i18nKey={i18nData.key}
        i18nValues={i18nData.values}
        showUpgradeExperienceAction={props.showUpgradeExperienceAction}
        showUpgradePlanAction
      />
    </div>
  );
};
