import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { format, utcToZonedTime } from 'date-fns-tz';

import { useActiveCohortId, useLoadExperienceStartAndEndQuery } from '../../data/experiences';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { useTranslate } from '../../util/i18n';
import { calculateMissionTrigger } from '../../util/time-util';
import { MissionTriggerTiming, TriggerTimeAnchorV1, TriggerTimeUnit } from '../../data/models';
import { Nullable } from '../../types/util';

type MissionTriggerTimeProps = {
  timing: MissionTriggerTiming;
  relativeAnchor?: Nullable<TriggerTimeAnchorV1>;
  relativeDuration?: Nullable<number>;
  relativeUnit?: Nullable<TriggerTimeUnit>;
  specificDay?: Nullable<number>;
  specificTime?: Nullable<string>;
};

type UseMissionTriggerTimeProps = {
  timing: MissionTriggerTiming;
  relativeAnchor?: Nullable<TriggerTimeAnchorV1>;
  relativeDuration?: Nullable<number>;
  relativeUnit?: Nullable<TriggerTimeUnit>;
  specificDay?: Nullable<number>;
  specificTime?: Nullable<string>;
};

export const useMissionTriggerTime = ({
  timing,
  relativeAnchor,
  relativeDuration,
  relativeUnit,
  specificDay,
  specificTime,
}: UseMissionTriggerTimeProps) => {
  const experienceTimezone = useExperienceInfo().data?.experience?.timezone ?? 'UTC';
  const cohortId = useActiveCohortId();
  const { isLoading, data } = useLoadExperienceStartAndEndQuery(
    { id: cohortId ?? '' },
    { skip: !cohortId },
  );

  const referenceDate = useMemo(() => {
    if (data?.cohort?.startDate && data.cohort.endDate) {
      return relativeAnchor === TriggerTimeAnchorV1.BeforeEnd
        ? new Date(data.cohort.endDate)
        : new Date(data.cohort.startDate);
    }
  }, [data?.cohort?.endDate, data?.cohort?.startDate, relativeAnchor]);

  if (isLoading || !referenceDate) {
    return null;
  }

  const date = calculateMissionTrigger({
    experienceTimezone,
    relativeAnchor,
    relativeDuration,
    relativeUnit,
    specificDay,
    specificTime,
    timing,
    referenceDate,
  });

  return date;
};

export const MissionTriggerTime = ({
  timing,
  relativeAnchor,
  relativeDuration,
  relativeUnit,
  specificDay,
  specificTime,
}: MissionTriggerTimeProps) => {
  const { t } = useTranslate('missionTriggerTime');
  const experienceTimezone = useExperienceInfo().data?.experience?.timezone ?? 'UTC';
  const date = useMissionTriggerTime({
    timing,
    relativeAnchor,
    relativeDuration,
    relativeUnit,
    specificDay,
    specificTime,
  });

  if (!date) {
    return null;
  }

  return (
    <Trans
      t={t}
      i18nKey="on"
      values={{
        date: format(utcToZonedTime(date, experienceTimezone), 'EEE, MMM d yyyy', {
          timeZone: experienceTimezone,
        }),
        time: format(utcToZonedTime(date, experienceTimezone), 'h:mm a zzz', {
          timeZone: experienceTimezone,
        }),
      }}
    />
  );
};
