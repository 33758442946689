import { Button, Modal } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { Trans } from 'react-i18next';

interface UpdateMissionTypeConfirmationModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  numSubmissions: number;
}

export const UpdateMissionTypeConfirmationModal = ({
  show,
  onClose,
  onSubmit,
  numSubmissions,
}: UpdateMissionTypeConfirmationModalProps) => {
  const { t } = useTranslate('missionForm.updateMissionTypeConfirmationModal');

  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[498px] desktop:w-[766px]"
      show={show}
      onClose={onClose}>
      <Modal.Header title={t('header') ?? undefined} />
      <Modal.Body>
        <Trans i18nKey="body">{t('body', { numSubmissions })}</Trans>
      </Modal.Body>
      <Modal.Footer>
        <Button label={t('confirm')} onClick={onSubmit} buttonType={'DESTRUCTIVE'} />
        <Button label={t('cancel')} onClick={onClose} outlined={true} />
      </Modal.Footer>
    </Modal>
  );
};
