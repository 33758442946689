var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeClasses } from '../util/merge-classes';
import { getDataTestIDProp } from '../util/test-id';
export var Label = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 'lg' : _b, children = _a.children, className = _a.className, testID = _a.testID, restProps = __rest(_a, ["size", "children", "className", "testID"]);
    var css = classNames({
        'font-semibold': true,
        'font-soleil': true,
        'text-label-lg': size === 'lg',
        'text-label-sm': size === 'sm',
    });
    return (_jsx("label", __assign({ className: mergeClasses(css, className) }, getDataTestIDProp(testID), restProps, { children: children })));
};
