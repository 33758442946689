import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { setActiveCohortId } from '../../data/experiences';
import { CreateMissionButton } from './create-mission-button.component';
import { MissionSidePanel } from './mission-side-panel';
import { MissionType } from '../../data/models';
import { openCreateMissionPanel, useInitialMissionType } from '../../data/missions';
import { useDispatch } from 'react-redux';
import { getExperienceSubPageTitleOptions } from '../utils';
import { useEffect, useState } from 'react';
import { PageHeadline } from '../../components/page-headline.component';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { PointsOverview } from './points-overview.component';
import { MissionOrderCard } from './mission-order.component';
import { MissionList } from './mission-list.component';
import { MissionLibraryModal } from './mission-library-modal.component';

export const MissionsPage = () => {
  const dispatch = useDispatch();

  const { t } = useTranslate('pages.missions');
  const { experienceId, data } = useExperienceInfo();
  const initialMissionType = useInitialMissionType();
  const [showMissionLibrary, setShowMissionLibrary] = useState(false);
  const displayName = data?.experience?.displayName;
  const missionOrder = data?.experience?.missionOrder;

  useEffect(() => {
    if (data?.experience?.cohorts?.[0]?.id) {
      dispatch(setActiveCohortId(data?.experience?.cohorts?.[0]?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.experience?.cohorts]);

  const onClickCreate = (missionType: MissionType) => {
    dispatch(openCreateMissionPanel(missionType));
  };

  return (
    <Page title={t('meta.title', getExperienceSubPageTitleOptions(displayName))}>
      <div className="flex justify-start w-full mb-4 tablet-narrow:mb-5 flex-col tablet-narrow:flex-row gap-4">
        <PageHeadline>{t('title')}</PageHeadline>
        <CreateMissionButton
          onSelectMissionType={onClickCreate}
          defaultMissionType={initialMissionType}
          onMissionLibraryClick={() => setShowMissionLibrary(true)}
        />
      </div>
      <div className="flex flex-col tablet-wide:flex-row gap-6 pb-6 items-start">
        <MissionList
          experienceId={experienceId}
          missionOrder={missionOrder}
          onMissionLibraryClick={() => setShowMissionLibrary(true)}
        />
        <div className="flex flex-col gap-6 h-fit w-full tablet-wide:max-w-[344px]">
          <MissionOrderCard missionOrder={data?.experience?.missionOrder} />
          <PointsOverview />
        </div>
      </div>
      <MissionSidePanel />
      <MissionLibraryModal onClose={() => setShowMissionLibrary(false)} show={showMissionLibrary} />
    </Page>
  );
};
