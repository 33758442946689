import { useNavigate, useParams } from 'react-router';
import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { LoadingSpinner } from '../../components/loading-spinner';
import { ErrorState } from '../../components/error-state';
import { Card } from '../../components/card.component';
import { NotFound } from '../../components/not-found';
import {
  useSharedExperienceQuery,
  setSelectedExperience,
  useCopySharedExperienceMutation,
  toggleCopyExperienceModal,
} from 'data/experiences';
import { SideBar } from './side-bar.component';
import { PublicExperience } from './public-experience';
import { useAppDispatch } from 'data/hooks';
import { EXPERIENCE_PATH } from 'constants/routes';
import { LoadingModal } from './loading-modal.component';

export const SharedExperiencePage = () => {
  const { t } = useTranslate('pages.sharedExperience');
  const { experienceShareId } = useParams();
  const { data, isLoading, isError, error } = useSharedExperienceQuery(
    { experienceShareId: experienceShareId ?? '' },
    { skip: !experienceShareId },
  );
  const [copySharedExperience, { isLoading: isLoadingCopy }] = useCopySharedExperienceMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div className="flex grow justify-center items-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (data == null || data.sharedExperience === null || error?.code === 'NOT_FOUND') {
    return (
      <div className="flex grow justify-center items-center">
        <NotFound />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex grow justify-center items-center p-4">
        <Card className="py-16 px-8">
          <ErrorState />
        </Card>
      </div>
    );
  }

  const experience = data?.sharedExperience;

  const handleClick = async () => {
    if (experienceShareId) {
      const response = await copySharedExperience({ experienceShareId }).unwrap();
      dispatch(
        setSelectedExperience({
          displayName: experience.displayName,
          id: response.copySharedExperience.id,
          photoUrl: experience.photoUrl,
        }),
      );
      dispatch(toggleCopyExperienceModal());
      navigate(`${EXPERIENCE_PATH}/${response.copySharedExperience.id}`);
    }
  };

  return (
    <Page
      title={t('meta.title', {
        context: experience.displayName ? 'name' : undefined,
        displayName: experience.displayName,
      })}>
      <div className="flex flex-col items-center w-full p-4 tablet-wide:p-10">
        <div className="flex flex-col-reverse tablet-wide:flex-row gap-6 desktop:max-w-[1080px] w-full">
          <div className="max-w-[348px] my-6 self-center tablet-wide:self-start w-full">
            <SideBar onCopyExperience={handleClick} />
          </div>
          <div className="grow tablet-wide:pb-10">
            <PublicExperience
              displayName={experience.displayName}
              photoUrl={experience.photoUrl}
              description={experience.description}
              missions={experience.missions}
            />
          </div>
        </div>
      </div>
      <LoadingModal show={isLoadingCopy} photoUrl={experience.photoUrl} />
    </Page>
  );
};
