var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Feed = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M4.02659 22C4.39088 22 15.1792 22 15.1792 22C15.6344 22 16.0896 21.8 16.431 21.5C16.7724 21.2 17 20.8 17 20.4V7.6C17 7.2 16.7724 6.8 16.431 6.5C16.0896 6.2 15.6344 6 15.1792 6H4.02659C3.57138 6 3.11617 6.2 2.77477 6.5C2.43336 6.8 2.20576 7.2 2.20576 7.6C2.20576 7.6 2.20576 16.1597 2.20576 20.4C2.20576 20.9077 2.40534 21.1876 2.76088 21.5C3.11642 21.8124 3.56627 22 4.02659 22Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.50195 17.1248L17.0001 17.1248", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M21 17.3657V3.6C21 3.2 20.8 2.8 20.5 2.5C20.2 2.2 19.8 2 19.4 2H7.64997", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
