import classNames from 'classnames';
import { Icon, IconName, Text, Label, mergeClasses } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { MissionType } from '../../data/models';

type CreateMissionButtonTypes = MissionType | 'LIBRARY';

const MISSION_TYPE_TO_TRANSLATION_KEY_MAP: Record<CreateMissionButtonTypes, string> = {
  CAMERA: 'camera',
  TEXT: 'text',
  GPS: 'gps',
  LIBRARY: 'library',
};

const MISSION_TYPE_TO_ICON_NAME_MAP: Record<CreateMissionButtonTypes, IconName> = {
  CAMERA: 'Camera',
  TEXT: 'Text',
  GPS: 'GPS',
  LIBRARY: 'Plus',
};

const MISSION_TYPE_TO_BG_COLOR_MAP: Record<CreateMissionButtonTypes, string> = {
  CAMERA: 'bg-yellowOrange',
  TEXT: 'bg-cyan',
  GPS: 'bg-lilac',
  LIBRARY: 'bg-offWhite',
};

interface MissionTypeIconProps {
  missionType: MissionType;
}

export const MissionTypeIcon = ({ missionType }: MissionTypeIconProps) => (
  <div
    className={classNames(
      'flex shrink-0 items-center justify-center w-[38px] h-[38px] rounded',
      MISSION_TYPE_TO_BG_COLOR_MAP[missionType],
    )}>
    <Icon size={24} name={MISSION_TYPE_TO_ICON_NAME_MAP[missionType]} />
  </div>
);

export interface MissionTypeOptionProps {
  className?: string;
  missionType: MissionType;
}

export const MissionTypeDropdownOption = ({ className, missionType }: MissionTypeOptionProps) => {
  const { t } = useTranslate(
    `missionTypeDropdownOption.missionType.${MISSION_TYPE_TO_TRANSLATION_KEY_MAP[missionType]}`,
  );

  return (
    <div className={mergeClasses('flex p-2 gap-2 items-center', className)}>
      <MissionTypeIcon missionType={missionType} />
      <div className="flex flex-col">
        <Label className="cursor-pointer" size="lg">
          {t('label')}
        </Label>
        <Text className="text-black-64" size="xs">
          {t('description')}
        </Text>
      </div>
    </div>
  );
};
