import { Menu } from '@headlessui/react';
import { Avatar, Text, mergeClasses } from '@goosechase/ui';

import { Link } from 'components/link.component';
import { type NavBarVariant } from './header-nav-bar.component';
import { Nullable } from 'types/util';

const Divider = () => <div className="h-px bg-black-24 my-1"></div>;

interface DropdownLinkItemProps {
  useRouter?: boolean;
  label: string;
  href: string;
  photoUrl?: Nullable<string>;
  withImage?: boolean;
}

const DropdownLinkItem = ({
  label,
  href,
  useRouter,
  photoUrl,
  withImage,
}: DropdownLinkItemProps) => (
  <Menu.Item>
    <Link
      useRouter={useRouter}
      href={href}
      className="flex items-center gap-3 ui-active:bg-black-12 py-2 px-4 whitespace-nowrap">
      {withImage ? (
        <Avatar alt={label} placeholder="workspace" src={photoUrl ?? undefined} size="xs4" />
      ) : null}
      <Text className="text-black font-bold overflow-hidden text-ellipsis max-w-[200px]">
        {label}
      </Text>
    </Link>
  </Menu.Item>
);

interface ButtonLabelProps {
  label: string;
}

const ButtonLabel = ({ label }: ButtonLabelProps) => (
  <Text className="font-semibold font-soleil text-black group-hover:text-vibrantBlue ui-open:text-vibrantBlue">
    {label}
  </Text>
);

interface NavBarDropdownProps {
  className?: string;
  dropdownButton: React.ReactNode;
  dropdownClassName?: string;
  children: React.ReactNode;
  variant?: NavBarVariant;
  ariaLabel?: string;
}

const NavBarDropdown = ({
  className,
  dropdownButton,
  dropdownClassName,
  children,
  ariaLabel = 'dropdown',
  variant = 'default',
}: NavBarDropdownProps) => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button
        aria-label={ariaLabel}
        className={mergeClasses(
          'group flex items-center cursor-pointer',
          { ' ui-open:bg-black-12 ': variant === 'default' },
          { ' ui-open:bg-black ': variant === 'experience' },
          className,
        )}>
        {dropdownButton}
      </Menu.Button>
      <Menu.Items
        className={mergeClasses(
          'absolute top-12 shadow-md flex flex-col outline-0 bg-white border border-black-12 rounded-lg min-w-[200px] z-50 overflow-hidden',
          dropdownClassName,
        )}>
        {children}
      </Menu.Items>
    </Menu>
  );
};

NavBarDropdown.Divider = Divider;
NavBarDropdown.ButtonLabel = ButtonLabel;
NavBarDropdown.DropdownLinkItem = DropdownLinkItem;

export { type NavBarDropdownProps, NavBarDropdown };
