/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type IsPasswordResetIdValidQueryVariables = Types.Exact<{
  passwordResetId: Types.Scalars['String']['input'];
}>;


export type IsPasswordResetIdValidQuery = { __typename: 'Query', isPasswordResetIdValid: boolean | null };


export const IsPasswordResetIdValidDocument = `
    query IsPasswordResetIdValid($passwordResetId: String!) {
  isPasswordResetIdValid(passwordResetId: $passwordResetId)
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    IsPasswordResetIdValid: build.query<IsPasswordResetIdValidQuery, IsPasswordResetIdValidQueryVariables>({
      query: (variables) => ({ document: IsPasswordResetIdValidDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useIsPasswordResetIdValidQuery, useLazyIsPasswordResetIdValidQuery } = injectedRtkApi;

