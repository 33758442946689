var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Announcement = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M3 10.4959L21 5.49588V17.4959L3 13.4959V10.4959Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M11.5997 16.2958C11.4947 16.6766 11.3156 17.033 11.0728 17.3447C10.83 17.6563 10.5282 17.9171 10.1847 18.1121C9.84108 18.3071 9.46245 18.4325 9.07041 18.4812C8.67836 18.5299 8.28056 18.5009 7.89973 18.3958C7.51889 18.2907 7.16248 18.1117 6.85084 17.8689C6.5392 17.6261 6.27844 17.3243 6.08343 16.9807C5.88843 16.6371 5.76301 16.2585 5.71433 15.8665C5.66565 15.4744 5.69467 15.0766 5.79973 14.6958", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
