import { Headline } from '@goosechase/ui';
import { useActiveCohortId, useLoadExperienceStartAndEndQuery } from 'data/experiences';
import { BroadcastFormUI } from './broadcast-form-ui.component';
import { useScheduleBroadcastMutation, useSendBroadcastMutation } from '../../data/broadcasts';
import { BroadcastFormOutputData } from './use-broadcast-form';
import { LoadingSpinner } from 'components/loading-spinner';
import {
  BroadcastRecipientType,
  BroadcastSendOption,
  BroadcastTaskType,
  BroadcastTriggerTiming,
  TriggerTimeAnchor,
  TriggerTimeUnit,
} from 'data/models';
import { displayToast } from 'services/toast';
import { useTranslate } from 'util/i18n';
import { Trans } from 'react-i18next';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';

interface CreateBroadcastFormProps {
  onSubmitSuccess?: () => void;
  onCancel?: () => void;
}

export const CreateBroadcastForm = ({ onCancel, onSubmitSuccess }: CreateBroadcastFormProps) => {
  const { t } = useTranslate('broadcastForm');
  const cohortId = useActiveCohortId();
  const [sendBroadcast, { isLoading: isLoadingSendBroadcast }] = useSendBroadcastMutation();
  const [scheduleBroadcast, { isLoading: isLoadingScheduleBroadcast }] =
    useScheduleBroadcastMutation();
  const { isLoading: isLoadingStartAndEndData, data: startAndEndData } =
    useLoadExperienceStartAndEndQuery({ id: cohortId ?? '' }, { skip: !cohortId });
  const experienceTimezone = useExperienceInfo().data?.experience?.timezone ?? 'UTC';

  const isLoading =
    isLoadingSendBroadcast || isLoadingScheduleBroadcast || isLoadingStartAndEndData || !cohortId;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  // eslint-disable-next-line complexity
  const handleSubmit = async (data: BroadcastFormOutputData) => {
    const { sendTo, teamName, message, sendTiming } = data;

    if (sendTiming === BroadcastSendOption.Now) {
      try {
        const response = await sendBroadcast({
          params: {
            cohortId,
            message,
            recipientType:
              sendTo === 'ALL' ? BroadcastRecipientType.All : BroadcastRecipientType.Team,
            teamId: sendTo === 'ALL' ? undefined : sendTo,
          },
        }).unwrap();
        onSubmitSuccess?.();
        displayToast({
          id: response.sendBroadcast.id,
          type: 'success',
          title: t('sendSuccessToast.title'),
          body: (
            <Trans
              t={t}
              i18nKey="sendSuccessToast.body"
              values={{ recipient: data.sendTo === 'ALL' ? t('allParticipants') : teamName }}
            />
          ),
        });
      } catch (e) {
        displayToast({
          id: '',
          type: 'error',
          title: t('sendErrorToast.title'),
          body: t('sendErrorToast.body'),
        });
      }
    } else {
      try {
        let timingParams;
        if (sendTiming === BroadcastSendOption.BeforeExperience) {
          timingParams = {
            timing: BroadcastTriggerTiming.Relative,
            relativeDuration: data.beforeExperienceDuration,
            relativeAnchor: TriggerTimeAnchor.BeforeStart,
            relativeUnit: data.beforeExperienceUnit,
          };
        } else if (sendTiming === BroadcastSendOption.AtStartOfExperience) {
          timingParams = {
            timing: BroadcastTriggerTiming.Relative,
            relativeAnchor: TriggerTimeAnchor.AtStart,
            relativeDuration: 1, // this will be ignored for trigger calculations
            relativeUnit: TriggerTimeUnit.Minutes, // this will be ignored for trigger calculations
          };
        } else if (sendTiming === BroadcastSendOption.DuringExperience) {
          if (data.duringExperienceTiming === BroadcastTriggerTiming.Relative) {
            timingParams = {
              timing: BroadcastTriggerTiming.Relative,
              relativeAnchor: data.relativeAnchor,
              relativeDuration: data.relativeDuration,
              relativeUnit: data.relativeUnit,
            };
          } else {
            timingParams = {
              timing: BroadcastTriggerTiming.Specific,
              specificDay: data.specificDay,
              specificTime: data.specificTime,
            };
          }
        } else if (sendTiming === BroadcastSendOption.AtEndOfExperience) {
          timingParams = {
            timing: BroadcastTriggerTiming.Relative,
            relativeAnchor: TriggerTimeAnchor.AtEnd,
            relativeDuration: 1, // this will be ignored for trigger calculations
            relativeUnit: TriggerTimeUnit.Minutes, // this will be ignored for trigger calculations
          };
        } else if (sendTiming === BroadcastSendOption.AfterExperience) {
          timingParams = {
            timing: BroadcastTriggerTiming.Relative,
            relativeDuration: data.afterExperienceDuration,
            relativeAnchor: TriggerTimeAnchor.AfterEnd,
            relativeUnit: data.afterExperienceUnit,
          };
        } else {
          throw new Error('Invalid broadcast options');
        }
        const response = await scheduleBroadcast({
          params: {
            cohortId,
            message,
            taskType: BroadcastTaskType.SendBroadcast,
            recipientType:
              sendTo === 'ALL' ? BroadcastRecipientType.All : BroadcastRecipientType.Team,
            teamId: sendTo === 'ALL' ? undefined : sendTo,
            ...timingParams,
          },
        }).unwrap();
        onSubmitSuccess?.();
        displayToast({
          id: response.scheduleBroadcast.id,
          type: 'success',
          title: t('scheduleSuccessToast.title'),
          body: (
            <Trans
              t={t}
              i18nKey="scheduleSuccessToast.body"
              values={{ recipient: data.sendTo === 'ALL' ? t('allParticipants') : teamName }}
            />
          ),
        });
      } catch (e) {
        displayToast({
          id: '',
          type: 'error',
          title: t('scheduleErrorToast.title'),
          body: t('scheduleErrorToast.body'),
        });
      }
    }
    // } else {
    //   try {
    //     const timingParams =
    //       data.timing === BroadcastTriggerTiming.Relative
    //         ? {
    //             timing: BroadcastTriggerTiming.Relative,
    //             relativeDuration: data.relativeDuration,
    //             relativeAnchor: data.relativeAnchor,
    //             relativeUnit: data.relativeUnit,
    //           }
    //         : {
    //             timing: BroadcastTriggerTiming.Specific,
    //             specificDay: data.specificDay,
    //             specificTime: data.specificTime,
    //           };
    //     const response = await scheduleBroadcast({
    //       params: {
    //         cohortId,
    //         message,
    //         taskType: BroadcastTaskType.SendBroadcast,
    //         recipientType:
    //           sendTo === 'ALL' ? BroadcastRecipientType.All : BroadcastRecipientType.Team,
    //         teamId: sendTo === 'ALL' ? undefined : sendTo,
    //         ...timingParams,
    //       },
    //     }).unwrap();
    //     onSubmitSuccess?.();
    //     displayToast({
    //       id: response.scheduleBroadcast.id,
    //       type: 'success',
    //       title: t('scheduleSuccessToast.title'),
    //       body: (
    //         <Trans
    //           t={t}
    //           i18nKey="scheduleSuccessToast.body"
    //           values={{ recipient: data.sendTo === 'ALL' ? t('allParticipants') : teamName }}
    //         />
    //       ),
    //     });
    //   } catch (e) {
    //     displayToast({
    //       id: '',
    //       type: 'error',
    //       title: t('scheduleErrorToast.title'),
    //       body: t('scheduleErrorToast.body'),
    //     });
    //   }
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-start px-11 py-5 border-black-24 border-b">
        <Headline type="secondary" size="sm">
          {t('createTitle')}
        </Headline>
      </div>
      <BroadcastFormUI
        defaultValues={{ sendTiming: BroadcastSendOption.Now }}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        cohortStartDate={startAndEndData?.cohort?.startDate}
        cohortEndDate={startAndEndData?.cohort?.endDate}
        experienceTimezone={experienceTimezone}
      />
    </div>
  );
};
