/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

module.hot?.accept();
export type GpsMissionFieldsFragmentFragment = { __typename: 'GpsMission', locationName: string | null, latitude: string, longitude: string, radius: number };

export const GpsMissionFieldsFragmentFragmentDoc = `
    fragment GpsMissionFieldsFragment on GpsMission {
  locationName
  latitude
  longitude
  radius
}
    `;
