import { useState } from 'react';
import * as Sentry from '@sentry/react';

import { ExperienceFormUI } from '../../../components/experience-form';

import {
  setExperienceListFilter,
  useCreateExperienceMutation,
  useExperienceListFilter,
} from 'data/experiences';
import { useTranslate } from 'util/i18n';
import { Nullable } from 'types/util';
import { useActiveWorkspaceQuery, useMyWorkspacesQuery } from 'data/workspaces';
import { useMultipleWorkspacesFeatureFlag } from '../../../hooks/use-feature-flag.hook';
import { WorkspaceScope } from '../../../data/models';
import { isInsufficientPermissionsError } from '../../../data/errors';
import { useAppDispatch } from '../../../data/hooks';

const useActiveWorkspaceId = (): string | undefined => {
  const mutlipleWorkspacesEnabled = useMultipleWorkspacesFeatureFlag();
  const filter = useExperienceListFilter();
  const { data: workspaceData } = useActiveWorkspaceQuery();
  const { data: myWorkspacesData } = useMyWorkspacesQuery();

  if (mutlipleWorkspacesEnabled) {
    if (filter.type === 'organization') {
      return filter.id;
    }

    // Fallback to personal workspace id otherwise
    return myWorkspacesData?.myWorkspaces?.find((w) => w.scope === WorkspaceScope.Personal)?.id;
  }

  return workspaceData?.myActiveWorkspace?.id;
};

const useResetExperienceListFilter = () => {
  const dispatch = useAppDispatch();

  const setFilter = () => {
    dispatch(setExperienceListFilter({ type: 'personal', value: 'personal' }));
  };

  return setFilter;
};

interface CreateExperienceFormData {
  photoId: Nullable<string>;
  displayName: string;
  description: string;
  location: Nullable<string>;
  latitude: Nullable<string>;
  longitude: Nullable<string>;
  password: Nullable<string>;
  defaultVisible: 'visible' | 'hidden';
}

export interface CreateExperienceFormProps {
  onCreateExperienceSuccess: (id: string) => void;
}

export const CreateExperienceForm = ({ onCreateExperienceSuccess }: CreateExperienceFormProps) => {
  const [serverError, setServerError] = useState<string | undefined>();
  const [create] = useCreateExperienceMutation();
  const { t } = useTranslate('pages.createExperience.createExperienceForm');
  const workspaceId = useActiveWorkspaceId();
  const resetExperienceListFilter = useResetExperienceListFilter();

  const handleGenericError = () => {
    setServerError(t('generalError') as string);
  };

  const handleCreateExperience = async ({ defaultVisible, ...data }: CreateExperienceFormData) => {
    // Clear server errors
    setServerError(undefined);

    try {
      if (!workspaceId) {
        Sentry.captureMessage('User tried to create an experience without a workspaceId');
        throw new Error('No workspace set, cannot create experience');
      }

      const response = await create({
        params: {
          workspaceId,
          defaultVisible: defaultVisible === 'visible',
          ...data,
        },
      }).unwrap();

      onCreateExperienceSuccess(response.createExperience.id);
    } catch (e) {
      if (isInsufficientPermissionsError(e)) {
        // Resets filter back to "personal" if the user tried
        //   to create an experience in a workspace they are not a part of
        resetExperienceListFilter();
        Sentry.captureMessage(
          'User ran into permissions error while trying to create an experience',
        );
      }
      handleGenericError();
    }
  };
  return <ExperienceFormUI onSubmit={handleCreateExperience} serverError={serverError} />;
};
