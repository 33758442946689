import { useSaveMissionMutation } from 'data/missions';
import { Trans } from 'react-i18next';
import { displayToast } from 'services/toast';
import { useTranslate } from 'util/i18n';

export const useSaveMission = () => {
  const { t } = useTranslate('missionLibrary.toasts');
  const [saveMission] = useSaveMissionMutation();

  return async ({ displayName, missionId }: { missionId: string; displayName: string }) => {
    await saveMission({ missionId }).unwrap();
    displayToast({
      id: missionId,
      title: t('saveSuccess.title'),
      type: 'success',
      body: (
        <Trans
          t={t}
          i18nKey="saveSuccess.body"
          values={{
            displayName,
          }}
        />
      ),
    });
  };
};
