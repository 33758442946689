import { Text } from '@goosechase/ui';
import { Card } from 'components/card.component';
import { ErrorState } from 'components/error-state';
import { LoadingSpinner } from 'components/loading-spinner';
import { TeamBonusFormInputData } from 'components/team-bonus-form';
import { useSelectedTeamId } from 'data/leaderboard';
import { useLoadTeamQuery, useUpdateTeamBonusMutation } from 'data/teams';
import { useDeleteTeamBonus } from 'hooks/use-delete-team-bonus.hook';
import { displayToast } from 'services/toast';
import { useTranslate } from 'util/i18n';
import { TeamBonusItem } from './team-bonus-item.component';

export type Team = {
  bonuses: {
    id: string;
    value: number;
    note: string | null;
    createdAt: string;
  }[];
};

export const TeamBonusHistory = () => {
  const { t } = useTranslate('teamBonusHistory');
  const [updateTeamBonus] = useUpdateTeamBonusMutation();
  const deleteTeamBonus = useDeleteTeamBonus();
  const teamId = useSelectedTeamId();
  const { data, isError, isLoading } = useLoadTeamQuery(
    { teamId: teamId ?? '' },
    { skip: !teamId },
  );

  if (isError) {
    return (
      <Card>
        <div className="flex justify-center py-16 px-8">
          <ErrorState />
        </div>
      </Card>
    );
  }

  if (isLoading) {
    return (
      <div className="p-5">
        <LoadingSpinner />
      </div>
    );
  }

  const handleUpdateTeamBonus = async (
    params: Extract<TeamBonusFormInputData, { bonusId: string }>,
  ) => {
    try {
      await updateTeamBonus({
        params: { ...params, value: Number(params.value) },
      }).unwrap();
      displayToast({
        id: params.bonusId,
        title: t(`toasts.updateSuccess.title`),
        type: 'success',
        body: t(`toasts.updateSuccess.body`),
      });
    } catch (error) {
      displayToast({
        id: params.bonusId,
        title: t(`toasts.updateError.title`),
        type: 'error',
        body: t(`toasts.updateError.body`),
      });
      throw error;
    }
  };

  return (
    <div className="overflow-x-auto">
      {data?.team?.bonuses.length ? (
        <table className="table-auto">
          <thead>
            <tr className="border-b border-black-12">
              <th className="w-max min-w-[100px] text-left p-2">{t('points')}</th>
              <th className="w-full text-left p-2">{t('reason')}</th>
              <th className="text-left p-2">{t('timestamp')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.team.bonuses.map((bonus) => (
              <TeamBonusItem
                key={bonus.id}
                bonus={bonus}
                onEdit={handleUpdateTeamBonus}
                onDelete={deleteTeamBonus}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <Text>{t('empty')}</Text>
      )}
    </div>
  );
};
