import { Icon, Pill, Text, Avatar } from '@goosechase/ui';
import { TeamMode } from 'data/models';
import { Nullable } from 'types/util';
import { useTranslate } from '../../util/i18n';
import { selectTeam, useDeleteTeamMutation } from 'data/teams';
import { displayToast } from 'services/toast';
import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { MoreButton } from './more-button';

interface TeamListItemProps {
  id: string;
  name: string;
  teamMaxSize: number | null;
  numParticipants: number | null;
  passcode: string | null;
  isPreCreated: boolean;
  teamMode: TeamMode;
  photoUrl: Nullable<string>;
}

export const TeamListItem = ({
  id,
  name,
  teamMaxSize,
  numParticipants,
  passcode,
  teamMode,
  photoUrl,
}: TeamListItemProps) => {
  const { t } = useTranslate('pages.participants.teams.list');
  const dispatch = useDispatch();
  const [deleteTeam] = useDeleteTeamMutation();

  const teamAtCapacity = teamMaxSize && numParticipants ? numParticipants >= teamMaxSize : false;

  const handleEdit = () => {
    dispatch(
      selectTeam({
        displayName: name,
        maxSize: teamMaxSize,
        mode: teamMode,
        id,
        passcode,
        photoUrl,
        numParticipants: numParticipants ?? 0,
      }),
    );
  };

  const handleDelete = async () => {
    try {
      const response = await deleteTeam({ id: id }).unwrap();
      displayToast({
        id: id,
        title: t('deleteSuccessToast.title'),
        type: 'success',
        body: (
          <Trans
            t={t}
            i18nKey="deleteSuccessToast.body"
            values={{
              teamName: response.deleteTeam.displayName,
            }}
          />
        ),
      });
    } catch {
      displayToast({
        id: id,
        title: t('deleteErrorToast.title'),
        type: 'error',
        body: (
          <Trans
            t={t}
            i18nKey="deleteErrorToast.body"
            values={{
              teamName: name,
            }}
          />
        ),
      });
    }
  };

  return (
    <div className="flex flex-row px-4 py-2 min-h-[60px] items-center relative hover:bg-black-4">
      <div className="self-start tablet-narrow:self-center mr-3">
        <Avatar
          size="xs2"
          placeholder={teamMode === 'TEAM' ? 'team-duo' : 'single'}
          src={photoUrl ?? undefined}
          alt={t('teamPhoto.alt', { teamName: name })}></Avatar>
      </div>
      <div className="flex justify-between flex-1 flex-col tablet-narrow:flex-row gap-y-2">
        <div className="flex flex-col justify-center gap-y-2 tablet-narrow:gap-0">
          <Text size="md" className="font-bold">
            {name}
          </Text>
          {teamMode === 'TEAM' && (
            <span className="flex flex-col gap-y-1 tablet-narrow:flex-row">
              <Text size="xs">
                {teamMaxSize
                  ? t('currentCapacity', {
                      current: numParticipants,
                      max: teamMaxSize,
                    })
                  : t('capacity', { count: numParticipants ?? 0 })}
              </Text>
              {teamAtCapacity && (
                <Pill
                  label={t('badges.participantLimitReached')}
                  variant="positive"
                  className="tablet-narrow:ml-3 bg-notificationGreen"
                />
              )}
            </span>
          )}
        </div>
        <div className="flex items-center">
          {passcode && <Icon name="Locked" className="mr-2" />}
          <Text size="xs">
            {passcode ? t('passcode', { passcode: passcode }) : t('noPasscode')}
          </Text>
        </div>
      </div>
      <MoreButton name={name} handleEdit={handleEdit} handleDelete={handleDelete} />
    </div>
  );
};
