import { Headline, Text } from '@goosechase/ui';
import { MissionTypeName } from '../../data/models';
import { useTranslate } from '../../util/i18n';

import { Card } from '../card.component';
import { Link } from '../link.component';
import {
  BonusPointsPill,
  PointsPill,
  MissionPointsPill,
} from '../points-pill/points-pill.component';

type Variant = MissionTypeName | 'Team';

const getSubmissionsKey = (variant: Variant): string => {
  if (variant === 'GpsMission') {
    return 'gpsSubmissions';
  } else if (variant === 'TextMission') {
    return 'textSubmissions';
  } else {
    return 'submissions';
  }
};

export interface SubmissionOverviewCardProps {
  children: React.ReactNode;
  displayName: string;
  bonusPoints: number;
  points: number;
  numSubmissions: number;
  variant: Variant;
  href: string;
}

export const SubmissionOverviewCard = ({
  children,
  displayName,
  bonusPoints,
  points,
  numSubmissions,
  variant,
  href,
}: SubmissionOverviewCardProps) => {
  const { t } = useTranslate('submissionOverviewCard');

  return (
    <Link className="outline-black w-full" useRouter href={href}>
      <Card className="flex p-5 w-full">
        <div className="flex flex-col w-full gap-2 text-left">
          <div className="flex justify-center items-center relative aspect-square rounded overflow-hidden">
            {children}
            {bonusPoints !== 0 && (
              <BonusPointsPill className="absolute right-3 bottom-3" points={bonusPoints} />
            )}
          </div>
          <Headline className="truncate" type="secondary" size="xs">
            {displayName}
          </Headline>
          <div className="flex justify-between items-center">
            {variant === 'Team' ? (
              <PointsPill points={points} />
            ) : (
              <MissionPointsPill points={points} type={variant} />
            )}
            <div className="font-bold text-vibrantBlue">
              <Text size="sm">{t(getSubmissionsKey(variant), { count: numSubmissions })}</Text>
            </div>
          </div>
        </div>
      </Card>
    </Link>
  );
};
