import { useActiveWorkspaceQuery, useGetBillingDetailsRedirectMutation } from 'data/workspaces';
import { PropsWithChildren } from 'react';

type ManageSubscriptionRedirectProps = PropsWithChildren;

export const ManageSubscriptionRedirect = (props: ManageSubscriptionRedirectProps) => {
  const { data } = useActiveWorkspaceQuery();
  const [getBillingDetailsRedirect] = useGetBillingDetailsRedirectMutation();

  if (
    !data?.myActiveWorkspace?.id ||
    data.myActiveWorkspace?.myWorkspaceMember?.roleId !== 'owner'
  ) {
    return null;
  }

  const handleClick = async () => {
    if (data.myActiveWorkspace?.id) {
      const response = await getBillingDetailsRedirect({
        workspaceId: data.myActiveWorkspace.id,
      }).unwrap();
      window.open(response.getBillingDetailsRedirect, '_blank');
    }
  };

  return <div onClick={handleClick}>{props.children}</div>;
};
