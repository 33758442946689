var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Copy = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M15.0002 4H9.48019C9.16019 4 8.84019 4.16 8.60019 4.4C8.36019 4.64 8.2002 4.96 8.2002 5.28V15.52C8.2002 15.84 8.36019 16.16 8.60019 16.4C8.84019 16.64 9.16019 16.8 9.48019 16.8H17.3202C17.6402 16.8 17.9602 16.64 18.2002 16.4C18.4402 16.16 18.6002 15.84 18.6002 15.52V7.59999L15.0002 4Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M5 8.48004V18.72C5 19.04 5.16 19.36 5.4 19.6C5.64 19.84 5.96 20 6.28 20H14.12", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M14.5996 4V7.99999H18.5996", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
