import { Account } from './account';
import { AddBonus } from './add-bonus';
import { AfterExperience } from './after-experience';
import { Announcement } from './announcement';
import { AnnouncementFilled } from './announcement-filled';
import { Arrow } from './arrow';
import { AtEndOfExperience } from './at-end-of-experience';
import { AtStartOfExperience } from './at-start-of-experience';
import { AwardedPoints } from './awarded-points';
import { BackArrow } from './back-arrow';
import { BackCaret } from './back-caret';
import { BeforeExperience } from './before-experience';
import { Calendar } from './calendar';
import { Camera } from './camera';
import { CaretDown } from './caret-down';
import { CheckeredFlag } from './checkered-flag';
import { Checkmark } from './checkmark';
import { Circle } from './circle';
import { Clapperboard } from './clapperboard';
import { ClapperboardFilled } from './clapperboard-filled';
import { Clipboard } from './clipboard';
import { ClipboardFilled } from './clipboard-filled';
import { Clock } from './clock';
import { Close } from './close';
import { CloseBold } from './close-bold';
import { Copy } from './copy';
import { CopyLink } from './copy-link';
import { Dashboard } from './dashboard';
import { DeductedPoints } from './deducted-points';
import { Delete } from './delete';
import { DeleteBold } from './delete-bold';
import { DropdownArrow } from './dropdown-arrow';
import { Duplicate } from './duplicate';
import { DuringExperience } from './during-experience';
import { Edit } from './edit';
import { EditBold } from './edit-bold';
import { Expand } from './expand';
import { ExternalLink } from './external-link';
import { Fail } from './fail';
import { Feed } from './feed';
import { Feedback } from './feedback';
import { FeedFilled } from './feed-filled';
import { Flag } from './flag';
import { FlagFilled } from './flag-filled';
import { ForwardCaret } from './forward-caret';
import { GooseFeet } from './goose-feet';
import { GPS } from './gps';
import { Grab } from './grab';
import { Help } from './help';
import { HiddenEye } from './hidden-eye';
import { Home } from './home';
import { Hourglass } from './hourglass';
import { Like } from './like';
import { LikeFilled } from './like-filled';
import { List } from './list';
import { Locked } from './locked';
import { Logout } from './logout';
import { Menu } from './menu';
import { MessageCircle } from './message-circle';
import { More } from './more';
import { Notifications } from './notifications';
import { Palette } from './palette';
import { PaletteFilled } from './palette-filled';
import { Participants } from './participants';
import { ParticipantsFilled } from './participants-filled';
import { Phone } from './phone';
import { PhotosVideos } from './photos-videos';
import { Play } from './play';
import { Plus } from './plus';
import { Podium } from './podium';
import { PodiumFilled } from './podium-filled';
import { PrivacyLock } from './privacy-lock';
import { Refresh } from './refresh';
import { Save } from './save';
import { Search } from './search';
import { Settings } from './settings';
import { Share } from './share';
import { ShownEye } from './shown-eye';
import { Smartphone } from './smartphone';
import { SmartphoneCapture } from './smartphone-capture';
import { SmartphoneLiveLib } from './smartphone-live-lib';
import { Stopwatch } from './stopwatch';
import { StopwatchFilled } from './stopwatch-filled';
import { Submissions } from './submissions';
import { SubmissionsFilled } from './submissions-filled';
import { Success } from './success';
import { Terms } from './terms';
import { Text } from './text';
import { Uploading } from './uploading';
import { UsersPlus } from './users-plus';
import { VideoCam } from './video-cam';
import { Warning } from './warning';
import { WarningYellow } from './warning-yellow';
import { YellowOrangeZap } from './yellow-orange-zap';
import { Zap } from './zap';
import { Email } from './email';
import { Now } from './now';
export var IconsMap = {
    Account: { component: Account, viewBox: '0 0 24 24' },
    AddBonus: { component: AddBonus, viewBox: '0 0 24 24' },
    AfterExperience: { component: AfterExperience, viewBox: '0 0 20 20' },
    Announcement: { component: Announcement, viewBox: '0 0 24 24' },
    AnnouncementFilled: { component: AnnouncementFilled, viewBox: '0 0 24 24' },
    Arrow: { component: Arrow, viewBox: '0 0 24 24' },
    AtEndOfExperience: { component: AtEndOfExperience, viewBox: '0 0 20 20' },
    AtStartOfExperience: { component: AtStartOfExperience, viewBox: '0 0 20 20' },
    AwardedPoints: { component: AwardedPoints, viewBox: '0 0 24 24' },
    BackArrow: { component: BackArrow, viewBox: '0 0 24 24' },
    BackCaret: { component: BackCaret, viewBox: '0 0 24 24' },
    BeforeExperience: { component: BeforeExperience, viewBox: '0 0 20 20' },
    Calendar: { component: Calendar, viewBox: '0 0 24 24' },
    Camera: { component: Camera, viewBox: '0 0 24 24' },
    CaretDown: { component: CaretDown, viewBox: '0 0 24 24' },
    CheckeredFlag: { component: CheckeredFlag, viewBox: '0 0 24 24' },
    Checkmark: { component: Checkmark, viewBox: '0 0 24 24' },
    Circle: { component: Circle, viewBox: '0 0 20 20' },
    Clapperboard: { component: Clapperboard, viewBox: '0 0 24 24' },
    ClapperboardFilled: { component: ClapperboardFilled, viewBox: '0 0 24 24' },
    Clipboard: { component: Clipboard, viewBox: '0 0 24 24' },
    ClipboardFilled: { component: ClipboardFilled, viewBox: '0 0 24 24' },
    Clock: { component: Clock, viewBox: '0 0 24 24' },
    Close: { component: Close, viewBox: '0 0 24 24' },
    CloseBold: { component: CloseBold, viewBox: '0 0 10 10' },
    Copy: { component: Copy, viewBox: '0 0 24 24' },
    CopyLink: { component: CopyLink, viewBox: '0 0 24 24' },
    Dashboard: { component: Dashboard, viewBox: '0 0 24 24' },
    DeductedPoints: { component: DeductedPoints, viewBox: '0 0 24 24' },
    Delete: { component: Delete, viewBox: '0 0 24 24' },
    DeleteBold: { component: DeleteBold, viewBox: '0 0 24 24' },
    DropdownArrow: { component: DropdownArrow, viewBox: '0 0 24 24' },
    Duplicate: { component: Duplicate, viewBox: '0 0 24 24' },
    DuringExperience: { component: DuringExperience, viewBox: '0 0 20 20' },
    Edit: { component: Edit, viewBox: '0 0 24 24' },
    EditBold: { component: EditBold, viewBox: '0 0 24 24' },
    Email: { component: Email, viewBox: '0 0 16 16' },
    Expand: { component: Expand, viewBox: '0 0 17 16' },
    ExternalLink: { component: ExternalLink, viewBox: '0 0 24 24' },
    Fail: { component: Fail, viewBox: '0 0 20 20' },
    Feed: { component: Feed, viewBox: '0 0 24 24' },
    Feedback: { component: Feedback, viewBox: '0 0 24 24' },
    FeedFilled: { component: FeedFilled, viewBox: '0 0 24 24' },
    Flag: { component: Flag, viewBox: '0 0 24 24' },
    FlagFilled: { component: FlagFilled, viewBox: '0 0 24 24' },
    ForwardCaret: { component: ForwardCaret, viewBox: '0 0 24 24' },
    GooseFeet: { component: GooseFeet, viewBox: '0 0 24 24' },
    GPS: { component: GPS, viewBox: '0 0 24 24' },
    Grab: { component: Grab, viewBox: '0 0 24 24' },
    Help: { component: Help, viewBox: '0 0 24 24' },
    HiddenEye: { component: HiddenEye, viewBox: '0 0 24 24' },
    Home: { component: Home, viewBox: '0 0 24 24' },
    Hourglass: { component: Hourglass, viewBox: '0 0 24 24' },
    Like: { component: Like, viewBox: '0 0 24 24' },
    LikeFilled: { component: LikeFilled, viewBox: '0 0 24 24' },
    List: { component: List, viewBox: '0 0 24 24' },
    Locked: { component: Locked, viewBox: '0 0 20 20' },
    Logout: { component: Logout, viewBox: '0 0 24 24' },
    Menu: { component: Menu, viewBox: '0 0 24 24' },
    MessageCircle: { component: MessageCircle, viewBox: '0 0 24 24' },
    More: { component: More, viewBox: '0 0 24 24' },
    Notifications: { component: Notifications, viewBox: '0 0 24 24' },
    Now: { component: Now, viewBox: '0 0 20 20' },
    Palette: { component: Palette, viewBox: '0 0 24 24' },
    PaletteFilled: { component: PaletteFilled, viewBox: '0 0 24 24' },
    Participants: { component: Participants, viewBox: '0 0 24 24' },
    ParticipantsFilled: { component: ParticipantsFilled, viewBox: '0 0 24 24' },
    Phone: { component: Phone, viewBox: '0 0 24 24' },
    PhotosVideos: { component: PhotosVideos, viewBox: '0 0 24 24' },
    Play: { component: Play, viewBox: '0 0 16 16' },
    Plus: { component: Plus, viewBox: '0 0 16 16' },
    Podium: { component: Podium, viewBox: '0 0 24 24' },
    PodiumFilled: { component: PodiumFilled, viewBox: '0 0 24 24' },
    PrivacyLock: { component: PrivacyLock, viewBox: '0 0 24 24' },
    Refresh: { component: Refresh, viewBox: '0 0 24 24' },
    Save: { component: Save, viewBox: '0 0 24 24' },
    Search: { component: Search, viewBox: '0 0 24 24' },
    Settings: { component: Settings, viewBox: '0 0 24 24' },
    Share: { component: Share, viewBox: '0 0 24 24' },
    ShownEye: { component: ShownEye, viewBox: '0 0 24 24' },
    Smartphone: { component: Smartphone, viewBox: '0 0 24 24' },
    SmartphoneCapture: { component: SmartphoneCapture, viewBox: '0 0 24 24' },
    SmartphoneLiveLib: { component: SmartphoneLiveLib, viewBox: '0 0 24 24' },
    Stopwatch: { component: Stopwatch, viewBox: '0 0 24 24' },
    StopwatchFilled: { component: StopwatchFilled, viewBox: '0 0 24 24' },
    Submissions: { component: Submissions, viewBox: '0 0 24 24' },
    SubmissionsFilled: { component: SubmissionsFilled, viewBox: '0 0 24 24' },
    Success: { component: Success, viewBox: '0 0 20 20' },
    Terms: { component: Terms, viewBox: '0 0 24 24' },
    Text: { component: Text, viewBox: '0 0 24 26' },
    Uploading: { component: Uploading, viewBox: '0 0 20 20' },
    UsersPlus: { component: UsersPlus, viewBox: '0 0 24 24' },
    VideoCam: { component: VideoCam, viewBox: '0 0 24 24' },
    Warning: { component: Warning, viewBox: '0 0 20 20' },
    WarningYellow: { component: WarningYellow, viewBox: '0 0 20 20' },
    YellowOrangeZap: { component: YellowOrangeZap, viewBox: '0 0 16 16' },
    Zap: { component: Zap, viewBox: '0 0 16 16' },
};
