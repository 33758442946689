import { Button } from '@goosechase/ui';
import { Controller } from 'react-hook-form';
import { useTranslate } from 'util/i18n';
import { LabelledTextArea } from '../labelled-text-area';
import {
  useSubmissionBonusForm,
  NOTE_MAX_CHARS,
  type SubmissionBonusFormOutputData,
  SUBMISSION_BONUS_FORM_DEFAULT_VALUES,
} from './use-submission-bonus-form';
import { LabelledNumberField } from 'components/labelled-number-field.component';
import { useEffect } from 'react';

export interface SubmissionBonusFormProps {
  onSubmit: (data: SubmissionBonusFormOutputData) => void;
  autoFocusBonus?: boolean;
}

export const SubmissionBonusForm = ({ onSubmit, autoFocusBonus }: SubmissionBonusFormProps) => {
  const { formState, control, handleSubmit, reset } = useSubmissionBonusForm();
  const { t } = useTranslate('submissionBonusForm');

  useEffect(() => {
    if (formState.isSubmitSuccessful || !autoFocusBonus) {
      reset(SUBMISSION_BONUS_FORM_DEFAULT_VALUES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isSubmitSuccessful, autoFocusBonus]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <Controller
        control={control}
        name="value"
        render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
          <LabelledNumberField
            label={t('value.label')}
            placeholder={t('value.placeholder') ?? undefined}
            onChange={onChange}
            onBlur={onBlur}
            errorMessage={error?.message}
            disabled={formState.isSubmitting}
            value={value}
            name={name}
            hideSpinButtons
            autoFocusInput={autoFocusBonus}
          />
        )}
      />
      <Controller
        control={control}
        name="note"
        render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
          <LabelledTextArea
            label={t('note.label')}
            maxCharacters={NOTE_MAX_CHARS}
            onChange={onChange}
            onBlur={onBlur}
            errorMessage={error?.message}
            disabled={formState.isSubmitting}
            value={value}
            name={name}
            placeholder={t('note.placeholder') ?? undefined}
            rows={3}
          />
        )}
      />
      <div>
        <Button label={t('submit')} disabled={!formState.isValid} />
      </div>
    </form>
  );
};
