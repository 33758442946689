import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Broadcast } from 'data/models';

export interface BroadcastsState {
  selectedBroadcast: Broadcast | null;
  broadcastPanelOpen: boolean;
}

const initialState: BroadcastsState = {
  selectedBroadcast: null,
  broadcastPanelOpen: false,
};

export const broadcastsSlice = createSlice({
  name: 'broadcasts',
  initialState,
  reducers: {
    selectBroadcast: (state, action: PayloadAction<Broadcast>) => {
      state.selectedBroadcast = action.payload;
      state.broadcastPanelOpen = true;
    },
    closeAndResetBroadcastPanel: (state) => {
      state.selectedBroadcast = null;
      state.broadcastPanelOpen = false;
    },
    openCreateBroadcastPanel: (state) => {
      state.broadcastPanelOpen = true;
    },
  },
});
