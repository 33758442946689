/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { SubmissionTeamFragmentFragmentDoc } from './submission-team.fragment.generated';
import { SubmissionFragmentFragmentDoc } from './submission.fragment.generated';
import { SubmissionMissionFragmentFragmentDoc } from './submission-mission.fragment.generated';
import { rootApi } from 'data/api';
module.hot?.accept();
export type TeamSubmissionsQueryVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
}>;


export type TeamSubmissionsQuery = { __typename: 'Query', team: { __typename: 'Team', points: number, numSubmissions: number, id: string, displayName: string, photoUrl: string | null, mode: Types.TeamMode, submissions: Array<{ __typename: 'CameraSubmission', caption: string | null, id: string, createdAt: string, bonusPoints: number, numLikes: number, liked: boolean, mission: { __typename: 'CameraMission', mediaType: Types.MediaType, allowCameraRollUploads: boolean, id: string, displayName: string, points: number, description: string, image: string | null, feedVisibility: Types.FeedVisibility }, assets: Array<{ __typename: 'SubmissionAsset', id: string, url: string, role: Types.SubmissionAssetRole }>, bonuses: Array<{ __typename: 'SubmissionBonus', id: string, value: number, note: string | null, createdAt: string }> } | { __typename: 'GpsSubmission', caption: string | null, latitude: string, longitude: string, id: string, createdAt: string, bonusPoints: number, numLikes: number, liked: boolean, mission: { __typename: 'GpsMission', locationName: string | null, latitude: string, longitude: string, id: string, displayName: string, points: number, description: string, image: string | null, feedVisibility: Types.FeedVisibility }, bonuses: Array<{ __typename: 'SubmissionBonus', id: string, value: number, note: string | null, createdAt: string }> } | { __typename: 'TextSubmission', answer: string, id: string, createdAt: string, bonusPoints: number, numLikes: number, liked: boolean, mission: { __typename: 'TextMission', answers: Array<string>, allowApproximateAnswers: boolean, id: string, displayName: string, points: number, description: string, image: string | null, feedVisibility: Types.FeedVisibility }, bonuses: Array<{ __typename: 'SubmissionBonus', id: string, value: number, note: string | null, createdAt: string }> }> } | null };


export const TeamSubmissionsDocument = `
    query TeamSubmissions($teamId: ID!) {
  team(id: $teamId) {
    points
    ...SubmissionTeamFragment
    numSubmissions
    submissions {
      ...SubmissionFragment
      mission {
        ...SubmissionMissionFragment
      }
    }
  }
}
    ${SubmissionTeamFragmentFragmentDoc}
${SubmissionFragmentFragmentDoc}
${SubmissionMissionFragmentFragmentDoc}`;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    TeamSubmissions: build.query<TeamSubmissionsQuery, TeamSubmissionsQueryVariables>({
      query: (variables) => ({ document: TeamSubmissionsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useTeamSubmissionsQuery, useLazyTeamSubmissionsQuery } = injectedRtkApi;

