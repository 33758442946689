import { Avatar, Button, Text } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import { type PhotoUploadUIProps } from '../photo-upload/photo-upload.component';

import { LoadingSpinner } from 'components/loading-spinner';
import classNames from 'classnames';
import { TeamMode } from 'data/models';
import { TeamFormInputData } from './use-team-form';
import { Control, useWatch } from 'react-hook-form';

interface PhotoUploadImageProps {
  src?: string;
  loading: boolean;
  control: Control<TeamFormInputData>;
  alt: string;
}

export const TeamPhotoImage = ({ src, control, alt, loading = false }: PhotoUploadImageProps) => {
  const mode = useWatch({ name: 'mode', control });
  return (
    <div
      className={classNames('relative shrink-0 w-[96px] h-[96px] rounded-xl overflow-hidden', {
        'bg-offWhite': src === undefined,
      })}>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Avatar
          size="lg"
          placeholder={mode === TeamMode.Team ? 'team-duo' : 'single'}
          src={src}
          alt={alt}
        />
      )}
    </div>
  );
};

type TeamPhotoUploadUIProps = PhotoUploadUIProps &
  React.InputHTMLAttributes<HTMLButtonElement> & {
    control: Control<TeamFormInputData>;
  };

export const TeamPhotoUploadUI = ({
  photoUrl,
  uploading,
  onClick,
  errorMessage,
  control,
  onDelete,
}: TeamPhotoUploadUIProps) => {
  const { t } = useTranslate('pages.participants.teamForm');
  return (
    <>
      <div className="flex flex-col items-center gap-x-4 gap-y-3">
        <TeamPhotoImage
          control={control}
          src={photoUrl ?? ''}
          loading={uploading}
          alt={t('photoId.label')}
        />

        {!photoUrl && (
          <Button
            label={t('photoId.buttons.add')}
            leftIcon="Plus"
            size="sm"
            outlined
            onClick={onClick}
            disabled={uploading}
          />
        )}
        {photoUrl && (
          <>
            <Button
              label={t('photoId.buttons.replace')}
              leftIcon="Plus"
              size="sm"
              outlined
              onClick={onClick}
              disabled={uploading}
            />
            <Button
              label={t('photoId.buttons.delete')}
              leftIcon="Delete"
              size="sm"
              outlined
              onClick={onDelete}
              disabled={uploading}
            />
          </>
        )}
        <Text className="whitespace-nowrap text-black-64" size="xs">
          {t('photoId.description')}
        </Text>
      </div>
      <div className="flex justify-between mt-2">
        <Text size="xs" className="text-vibrantRed">
          {errorMessage ?? ''}
        </Text>
      </div>
    </>
  );
};
