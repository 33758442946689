var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var AtStartOfExperience = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M3.75016 6H2.50016C1.57969 6 0.833496 6.39797 0.833496 6.88889V13.1111C0.833496 13.602 1.57969 14 2.50016 14H3.75016", stroke: "#757474", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M16.25 6H17.5C18.4205 6 19.1667 6.39797 19.1667 6.88889V13.1111C19.1667 13.602 18.4205 14 17.5 14H16.25", stroke: "#757474", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M10.3335 4H13.3335V16H10.3335", stroke: "#757474", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M7 4V15.6667", stroke: "#0C0A0C", strokeWidth: "2.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
