import { Headline, IconButton, Modal, Text, mergeClasses } from '@goosechase/ui';
import { useState } from 'react';
import { useTranslate } from 'util/i18n';
import { TabsRow } from 'components/tabs-row';
import { PreviousMissionsList } from './previous-missions-list';
import { displayToast } from 'services/toast';
import { Trans } from 'react-i18next';
import { useAddMissionFromMissionLibraryMutation } from 'data/missions';
import { useExperienceOutletContext } from 'components/experience-layout';
import { SavedMissionsList } from './saved-missions-list';

interface MissionLibraryModalProps {
  show: boolean;
  onClose: () => void;
}

export const MissionLibraryModal = ({ show, onClose }: MissionLibraryModalProps) => {
  const { t } = useTranslate('missionLibrary');
  const { experienceId } = useExperienceOutletContext();
  const [selectedTab, setSelectedTab] = useState(0);
  const [addMissionFromMissionLibrary] = useAddMissionFromMissionLibraryMutation();
  const tabs = ['previous', 'saved'].map((tab) => ({ name: tab, label: t(`${tab}.label`) }));

  const handleSelectTab = (tab: number) => {
    setSelectedTab(tab);
  };

  const handleMissionAdd = async (missionId: string, displayName: string) => {
    await addMissionFromMissionLibrary({ experienceId, missionId }).unwrap();
    displayToast({
      body: <Trans t={t} i18nKey="toasts.addSuccess.body" values={{ displayName }} />,
      id: missionId,
      title: t('toasts.addSuccess.title'),
      type: 'success',
    });
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      className="flex flex-col overflow-y-auto tablet-wide:overflow-y-hidden h-full rounded-none border-0 w-[100vw] h-[calc(100vh-112px)] tablet-narrow:rounded-xl tablet-narrow:w-[552px] tablet-narrow:h-[876px] tablet-wide:w-[832px] tablet-wide:h-[552px] desktop:w-[1080px] desktop:h-[708px] relative"
      overlayClassName="bg-transparent items-end tablet-narrow:bg-black/50 tablet-narrow:items-center">
      <div className="py-4 px-6 tablet-narrow:p-6 desktop:p-9 h-full overflow-hidden">
        <div className="flex justify-between items-center mb-6">
          <Headline type="primary" size="sm">
            {t('header')}
          </Headline>
          <IconButton
            onClick={onClose}
            icon="Close"
            iconColor="black"
            iconSize={18}
            aria-label={t('close') ?? 'Close'}
          />
        </div>
        <div className="flex flex-col tablet-wide:flex-row gap-5 tablet-wide:gap-10 max-h-full">
          <div className="hidden tablet-wide:flex flex-col items-start gap-1 w-[240px]">
            {tabs.map((tab, index) => (
              <button key={tab.name} onClick={() => setSelectedTab(index)}>
                <Text
                  size="lg"
                  className={mergeClasses({ 'font-bold text-vibrantBlue': selectedTab === index })}>
                  {t(`${tab.name}.label`)}
                </Text>
              </button>
            ))}
          </div>
          <div className="tablet-wide:hidden">
            <TabsRow onSelectTab={handleSelectTab} selectedTab={selectedTab} tabs={tabs} />
          </div>
          <div className="flex justify-center w-full overflow-y-auto">
            {selectedTab === 0 ? <PreviousMissionsList onAddClick={handleMissionAdd} /> : null}
            {selectedTab === 1 ? <SavedMissionsList onAddClick={handleMissionAdd} /> : null}
          </div>
        </div>
      </div>
    </Modal>
  );
};
