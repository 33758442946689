var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var VideoCam = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M22.1177 8.00012L16.1177 12.0001L22.1177 16.0001V8.00012Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M14.1177 6H4.11768C3.01311 6 2.11768 6.89543 2.11768 8V16C2.11768 17.1046 3.01311 18 4.11768 18H14.1177C15.2222 18 16.1177 17.1046 16.1177 16V8C16.1177 6.89543 15.2222 6 14.1177 6Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
