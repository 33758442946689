import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Team } from '../models';

type EditableTeam = Pick<
  Team,
  'id' | 'displayName' | 'maxSize' | 'mode' | 'passcode' | 'photoUrl'
> & { numParticipants: number };

export interface TeamsState {
  selectedTeam: EditableTeam | null;
  teamModalOpen: boolean;
}

const initialState: TeamsState = {
  selectedTeam: null,
  teamModalOpen: false,
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    selectTeam: (state, action: PayloadAction<EditableTeam>) => {
      state.selectedTeam = action.payload;
      state.teamModalOpen = true;
    },
    closeAndResetTeamModal: (state) => {
      state.selectedTeam = null;
      state.teamModalOpen = false;
    },
  },
});
