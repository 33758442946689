import {
  ExperienceSection,
  EXPERIENCE_SECTION_NAV_LINKS,
} from '../../constants/experience-sections';

const PATH_TO_EXPERIENCE_SECTION_MAP: Record<string, ExperienceSection> = Object.entries(
  EXPERIENCE_SECTION_NAV_LINKS,
).reduce((acc, keyValuePair) => {
  const [section, navLinks] = keyValuePair;
  // For each section (create, review, publish)
  // creates an object of `[navLink.path]: section` entries, and merge them all together

  const sectionMap = navLinks.reduce(
    (sectionAcc, navLink) => ({ ...sectionAcc, [navLink.path]: section }),
    {},
  );

  return { ...acc, ...sectionMap };
}, {});

export const getExperienceSectionFromPathname = (pathname: string): ExperienceSection | null => {
  // Checking every segment of the pathname as there is no guarantee
  // the segment we want is in a specific location (eg. at the end)
  const matches = pathname
    .split('/')
    .map((segment) => PATH_TO_EXPERIENCE_SECTION_MAP[segment] ?? null)
    .filter((el) => el !== null);

  return matches[0] ?? null;
};
