import { StyledLink } from '@goosechase/ui';
import { AppRoutes } from 'constants/routes';
import { Link } from 'components/link.component';

export interface AppLinkProps {
  page: keyof Omit<typeof AppRoutes, 'SUB_PATHS' | 'PUBLIC'>;
  className?: string;
  children?: React.ReactNode;
  queryParams?: URLSearchParams;
}

/*
 * A link for navigating within the application that looks like our design system link.
 * If you don't need the styling, consider using a <Link /> directly from link.component.tsx instead
 */
export const AppLink = ({ children, page, className, queryParams }: AppLinkProps) => (
  <Link useRouter href={`${AppRoutes[page]}${queryParams ? `?${queryParams.toString()}` : ''}`}>
    <StyledLink className={className}>{children}</StyledLink>
  </Link>
);
