import { ArrayElement } from '../../global';
import {
  TeamSubmissionOverviewCard,
  TeamSubmissionOverviewCardProps,
} from '../../components/submission-overview-card';
import { SubmissionsOverviewQuery } from '../../data/submissions';
import { SubmissionGroup } from './constants';
import { useSubmissionsOverview } from './hooks';
import { useExperienceOutletContext } from '../../components/experience-layout';
import { SubmissionGridContainer } from '../../components/submission-grid';
import { SubmissionsEmptyState } from '../../components/submissions-empty-states.component';

type Teams = Exclude<SubmissionsOverviewQuery['cohort'], null>['teams'];
type Team = ArrayElement<Teams>;

const filterTeamsWithSubmissions = (team: Team) => team.numSubmissions > 0;

const convertTeamToComponentProps = (
  team: Team,
): Omit<TeamSubmissionOverviewCardProps, 'experienceId'> => ({
  teamId: team.id,
  teamName: team.displayName,
  totalTeamPoints: team.points,
  numTeamSubmissions: team.numSubmissions,
  teamBonusPoints: team.bonusPoints,
  teamImageUrl: team.photoUrl ?? undefined,
  teamMode: team.mode,
});

type TeamSubmissionGroup = Exclude<SubmissionGroup, 'mission'>;

const makeSortFn = (submissionGroup: TeamSubmissionGroup) => (a: Team, b: Team) => {
  if (submissionGroup === 'teamPoints') {
    return b.points - a.points;
  } else {
    return a.displayName.localeCompare(b.displayName);
  }
};

interface TeamSubmissionsOverviewListProps {
  submissionGroup: TeamSubmissionGroup;
}

export const TeamSubmissionsOverviewList = ({
  submissionGroup,
}: TeamSubmissionsOverviewListProps) => {
  const { experienceId } = useExperienceOutletContext();
  const { data } = useSubmissionsOverview();

  const teamsWithSubmissions =
    data?.cohort?.teams
      .filter(filterTeamsWithSubmissions)
      .sort(makeSortFn(submissionGroup))
      .map(convertTeamToComponentProps) ?? [];

  if (teamsWithSubmissions.length === 0) {
    return <SubmissionsEmptyState />;
  }

  return (
    <SubmissionGridContainer>
      {teamsWithSubmissions.map((team) => (
        <TeamSubmissionOverviewCard key={team.teamId} experienceId={experienceId} {...team} />
      ))}
    </SubmissionGridContainer>
  );
};
