import { useNavigate } from 'react-router';
import { Button } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { AppRoutes } from 'constants/routes';
import { Page } from 'components/page.component';

import { MyExperiencesList } from './my-experiences-list.component';
import { WelcomeBanner } from './welcome-banner';
import { PageHeadline } from '../../components/page-headline.component';
import { AcceptedWorkspaceInvitationModal } from 'components/accepted-invitation-modal';

export const OldExperiencesPage = () => {
  const { t } = useTranslate('pages.experiences');
  const navigate = useNavigate();

  const navigateToCreateNew = () => {
    navigate(AppRoutes.CREATE_EXPERIENCE);
  };

  return (
    <Page title={t('meta.title')}>
      <div className="flex flex-col w-full">
        <WelcomeBanner />
        <AcceptedWorkspaceInvitationModal />
        <div className="flex flex-col items-center py-7 px-4 tablet-narrow:px-5 w-full">
          <div className="w-full max-w-[724px]">
            <div className="flex justify-between w-full mb-4 tablet-narrow:mb-6 flex-col tablet-narrow:flex-row gap-4">
              <PageHeadline>{t('title')}</PageHeadline>
              <Button
                leftIcon="Plus"
                size="sm"
                label={t('newExperience')}
                onClick={navigateToCreateNew}
              />
            </div>
            <MyExperiencesList />
          </div>
        </div>
      </div>
    </Page>
  );
};
