import { Button } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';

interface CreateBroadcastButtonProps {
  onClick: () => void;
}

export const CreateBroadcastButton = (props: CreateBroadcastButtonProps) => {
  const { t } = useTranslate('pages.broadcasts');

  const handleOnClick = () => {
    props.onClick();
  };

  return <Button label={t('sendABroadcast')} onClick={handleOnClick} />;
};
