var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { mergeClasses } from '../util/merge-classes';
import { Text } from '../typography';
import { Icon } from '../icon';
var BANNER_CONTAINER_CLASSES = {
    success: 'bg-notificationGreen border-vibrantGreen',
    error: 'bg-vibrantRed-10 border-vibrantRed',
    warning: 'bg-yellowOrange-10 border-yellowOrange',
};
var BANNER_ICON = {
    success: 'Success',
    error: 'Fail',
    warning: 'WarningYellow',
};
var BANNER_ICON_COLOR = {
    success: 'black',
    error: 'white',
    warning: 'black',
};
export var NotificationBanner = function (_a) {
    var className = _a.className, _b = _a.type, type = _b === void 0 ? 'success' : _b, title = _a.title, body = _a.body, _c = _a.showIcon, showIcon = _c === void 0 ? false : _c, onClick = _a.onClick;
    return (_jsxs("div", __assign({ className: mergeClasses('flex flex-row gap-3 rounded flex flex-row border', {
            'p-4': !showIcon,
            'px-4 py-3': showIcon,
            'cursor-pointer': onClick !== undefined,
        }, BANNER_CONTAINER_CLASSES[type], className), onClick: onClick }, { children: [showIcon && (_jsx(Icon, { className: "shrink-0", size: 20, name: BANNER_ICON[type], color: BANNER_ICON_COLOR[type] })), _jsxs(Text, __assign({ className: mergeClasses({ 'flex flex-col gap-2': showIcon }), size: "md" }, { children: [_jsxs("span", __assign({ className: "font-bold" }, { children: [title, " "] })), body] }))] })));
};
