import { Headline, mergeClasses } from '@goosechase/ui';
import { InteractiveMap } from '../interactive-map.component';
import { MissionTypeName } from '../../data/models';

export interface SubmissionContentProps {
  className?: string;
  missionType: MissionTypeName;
  submissionCaption?: string;
  submissionImageSrc?: string;
  submissionVideoSrc?: string;
  submissionLatitude?: string;
  submissionLongitude?: string;
  submissionAnswer?: string;
}

const MISSION_TYPE_CLASSNAME: Record<MissionTypeName, string> = {
  CameraMission: '',
  TextMission: 'bg-cyan-30 text-black',
  GpsMission: 'bg-lilac-50 text-lilac-dark',
};

export const SubmissionContent = ({
  className,
  missionType,
  submissionAnswer,
  submissionCaption,
  submissionImageSrc,
  submissionLatitude,
  submissionLongitude,
  submissionVideoSrc,
}: SubmissionContentProps) => {
  const commonClasses = mergeClasses(className, 'w-full h-full aspect-square');

  if (missionType === 'CameraMission') {
    return submissionVideoSrc ? (
      <video className={commonClasses} src={submissionVideoSrc} controls />
    ) : (
      <img alt={submissionCaption} className={commonClasses} src={submissionImageSrc} />
    );
  }

  if (missionType === 'TextMission') {
    return (
      <div
        className={mergeClasses(
          commonClasses,
          'flex items-center justify-center p-[44px] aspect-auto',
          MISSION_TYPE_CLASSNAME[missionType],
        )}>
        <Headline className="w-full" type="secondary" size="md">
          {`"${submissionAnswer}"`}
        </Headline>
      </div>
    );
  }

  return (
    <InteractiveMap
      className={commonClasses}
      coordinates={{
        lat: Number(submissionLatitude),
        lng: Number(submissionLongitude),
      }}
      radius={0}
      zoom={16}
    />
  );
};
