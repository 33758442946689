import { Card } from 'components/card.component';
import { Headline } from '@goosechase/ui';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import { DownloadSubmissionPopularityButton } from './submission-popularity-download-button.component';
import { SubmissionPopularityTooltip } from './submission-popularity-tooltip.component';

export interface PopularityDatum {
  id: string;
  missionName: string;
  teamName: string;
  likeCount: number;
}

interface SubmissionPopularityBarChartCardProps {
  data: PopularityDatum[];
  headers: string[];
  fileName: string;
  label: string;
  ariaLabel: string;
  xAxisLabel: string;
  yAxisLabel: string;
}

const MAX_NAME_LENGTH = 20;

export const SubmissionPopularityBarChartCard = ({
  data,
  headers,
  fileName,
  label,
  ariaLabel,
  xAxisLabel,
  yAxisLabel,
}: SubmissionPopularityBarChartCardProps) => {
  return (
    <Card
      className="col-span-4 tablet-narrow:col-span-4 tablet-wide:col-span-2 
                row-span-2 tablet-narrow:row-span-4 tablet-wide:row-span-4 desktop:row-span-4
                flex flex-col items-center align-center justify-center
                h-128 tablet-narrow:h-128 desktop:h-128 p-6
                text-lg text-black-64 font-bold text-center
                group">
      <div className="flex flex-row justify-between w-full">
        <Headline type="secondary" size="sm" className="font-bold pb-2">
          {label}
        </Headline>
        <DownloadSubmissionPopularityButton
          data={data}
          headers={headers}
          fileName={fileName}
          className="desktop:invisible desktop:group-hover:visible"
          ariaLabel={ariaLabel}
        />
      </div>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: 120,
          }}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="name"
            label={{ value: xAxisLabel, position: 'bottom', offset: 100 }}
            angle={-45}
            fontSize={12}
            textAnchor="end"
            tickFormatter={(value) => {
              return value.length > MAX_NAME_LENGTH
                ? `${value.slice(0, MAX_NAME_LENGTH)}...`
                : value;
            }}
          />
          <YAxis
            allowDecimals={false}
            label={{ value: yAxisLabel, angle: -90, position: 'left' }}
          />
          <Tooltip content={<SubmissionPopularityTooltip />} />
          <Bar dataKey="likeCount" fill="#4F6CF2" />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};
