import { Headline, Label, mergeClasses, Text } from '@goosechase/ui';

import {
  CohortState,
  MissionStatus,
  type MissionTrigger,
  type MissionTypeName,
} from '../../data/models';
import { Link } from '../link.component';
import {
  InformationTags,
  type TagValue,
  type TagValueWithTooltip,
} from '../information-tags/information-tags.component';
import { MissionStatusInfo } from './mission-status-info.component';
import { MissionIcon } from '../mission-icon';
import { MissionPointsPill } from '../points-pill';
import { DragHandle } from '../../components/drag-list';
import { Nullable } from 'types/util';

export interface MissionCardProps {
  className?: string;
  isSelected: boolean;
  id: string;
  type: MissionTypeName;
  title: string;
  description: string;
  points: number;
  numSubmissions: number;
  missionStatus: Nullable<MissionStatus>;
  infoTitle?: string;
  infoTags: TagValue[];
  secondaryInfoTags: TagValueWithTooltip[];
  link?: string;
  image?: string;
  dragEnabled: boolean;
  isDragging: boolean;
  onEdit: (id: string) => void;
  releaseTrigger?: MissionTrigger;
  expireTrigger?: MissionTrigger;
  releaseAt?: Nullable<string>;
  expireAt?: Nullable<string>;
  cohortStartDate?: Nullable<string>;
  cohortEndDate?: Nullable<string>;
  draft: boolean;
  experienceTimezone: string;
  experienceStatus?: CohortState;
}

// eslint-disable-next-line complexity
export const MissionCard = ({
  className,
  isSelected,
  id,
  type,
  title,
  description,
  points,
  missionStatus,
  infoTitle,
  infoTags,
  secondaryInfoTags,
  link,
  image,
  dragEnabled,
  isDragging,
  releaseTrigger,
  expireTrigger,
  expireAt,
  releaseAt,
  onEdit,
  cohortEndDate,
  cohortStartDate,
  draft,
  experienceTimezone,
  experienceStatus,
}: MissionCardProps) => {
  // live or ended experience + mission without status means invalid mission trigger config
  const isReleaseTriggerInvalid =
    (experienceStatus === CohortState.Active || experienceStatus === CohortState.Ended) &&
    releaseTrigger &&
    !missionStatus;
  const bgOpacityClassName = mergeClasses({
    'bg-opacity-50':
      missionStatus === 'EXPIRED' || missionStatus === 'HIDDEN' || draft || isReleaseTriggerInvalid,
  });
  const opacityClassName = mergeClasses({
    'opacity-50':
      missionStatus === 'EXPIRED' || missionStatus === 'HIDDEN' || draft || isReleaseTriggerInvalid,
  });
  const textColorClassName = mergeClasses({
    'text-black-64':
      missionStatus === 'EXPIRED' || missionStatus === 'HIDDEN' || draft || isReleaseTriggerInvalid,
  });

  const shouldRenderInformationTags =
    infoTitle || infoTags.length > 0 || secondaryInfoTags.length > 0;

  return (
    <div
      className={mergeClasses(
        'relative flex flex-row px-5 py-3.5 gap-4 text-black border-l-4 hover:bg-black-4',
        className,
        { 'border-l-vibrantBlue bg-vibrantBlue-10': isSelected },
        { 'border-l-transparent': !isSelected },
        { 'bg-black-4': isDragging },
      )}>
      <DragHandle id={id} isDragging={isDragging} dragEnabled={dragEnabled} />
      <button
        className="w-full h-full text-left outline-none rounded-xl focus-visible:ring-2 focus-visible:ring-vibrantBlue active:ring-0"
        onClick={() => {
          onEdit(id);
        }}>
        <div className="flex flex-row gap-4 w-full h-full">
          <MissionIcon className={opacityClassName} type={type} />
          <div className="flex flex-col grow min-w-0">
            <div>
              <div className={mergeClasses('flex flex-row justify-between', textColorClassName)}>
                <Headline type="secondary" size="xs">
                  {title}
                </Headline>
              </div>
              <div className="flex gap-4 justify-between">
                <Text
                  size="sm"
                  className={mergeClasses('mt-1 whitespace-pre-line', textColorClassName)}>
                  {description}
                </Text>
                <div className="flex  mt-2">
                  <MissionPointsPill className={bgOpacityClassName} type={type} points={points} />
                </div>
              </div>
              {shouldRenderInformationTags && (
                <InformationTags
                  title={infoTitle}
                  primaryValues={infoTags}
                  secondaryValues={secondaryInfoTags}
                />
              )}
              {image && (
                <img className="mt-2 rounded max-w-full max-h-[100px]" alt={title} src={image} />
              )}
              {link && (
                <div className="mt-2">
                  <Link
                    className="w-fit text-vibrantBlue hover:text-[#0013c1]"
                    href={link}
                    target="_blank">
                    <Label className="w-fit cursor-pointer hover:underline" size="lg">
                      {link}
                    </Label>
                  </Link>
                </div>
              )}
            </div>
            <MissionStatusInfo
              draft={draft}
              status={missionStatus ?? MissionStatus.Hidden}
              releaseTrigger={releaseTrigger}
              expireTrigger={expireTrigger}
              expireAt={expireAt}
              releaseAt={releaseAt}
              cohortEndDate={cohortEndDate}
              cohortStartDate={cohortStartDate}
              experienceTimezone={experienceTimezone}
            />
          </div>
        </div>
      </button>
    </div>
  );
};
