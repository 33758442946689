export const transformEmptyInputToNull = (input: string | undefined | null) =>
  input === '' ? null : input;

// From: https://github.com/orgs/react-hook-form/discussions/1991#discussioncomment-351784
export const getDirtyValues = <
  DirtyFields extends Record<string, unknown>,
  Values extends Record<keyof DirtyFields, unknown>,
>(
  dirtyFields: DirtyFields,
  values: Values,
): Partial<typeof values> => {
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RHF sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) {
      return prev;
    }

    return {
      ...prev,
      [key]:
        typeof dirtyFields[key] === 'object'
          ? getDirtyValues(dirtyFields[key] as DirtyFields, values[key] as Values)
          : values[key],
    };
  }, {});

  return dirtyValues;
};
