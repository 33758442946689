import { mergeClasses } from '@goosechase/ui';

export interface CardProps {
  children: React.ReactNode;
  className?: string;
}
export const Card = ({ children, className, ...htmlProps }: CardProps) => {
  return (
    <div className={mergeClasses('bg-white rounded-xl', className)} {...htmlProps}>
      {children}
    </div>
  );
};
