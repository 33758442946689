var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Clapperboard = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M14.7365 3.19208L13.8765 7.10208", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M9.01674 5.00208L8.15674 8.90207", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M3.73633 11.8921H21.7363V20.5721C21.7363 21.1025 21.5256 21.6112 21.1505 21.9863C20.7755 22.3613 20.2668 22.5721 19.7363 22.5721H5.73633C5.20589 22.5721 4.69719 22.3613 4.32211 21.9863C3.94704 21.6112 3.73633 21.1025 3.73633 20.5721V11.8921Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M3.46656 10.6921L20.5166 5.25207C20.5288 5.24874 20.5402 5.24287 20.5499 5.23484C20.5597 5.22681 20.5677 5.21681 20.5734 5.20548C20.5791 5.19416 20.5823 5.18177 20.5828 5.16911C20.5834 5.15646 20.5812 5.14384 20.5766 5.13207L20.0266 3.33207C19.9501 3.07816 19.824 2.84199 19.6555 2.63728C19.487 2.43257 19.2794 2.26341 19.0449 2.13965C18.8104 2.01588 18.5537 1.93999 18.2896 1.91637C18.0255 1.89275 17.7593 1.92188 17.5066 2.00207L4.14656 6.27207C3.89625 6.35263 3.66429 6.48175 3.46394 6.65205C3.2636 6.82236 3.0988 7.03049 2.97898 7.26456C2.85916 7.49862 2.78668 7.75402 2.76567 8.01612C2.74466 8.27823 2.77555 8.54191 2.85656 8.79207L3.46656 10.6921L3.82656 11.8921", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
