import { Card } from '../../components/card.component';
import { ErrorState } from '../../components/error-state';
import { LoadingSpinner } from '../../components/loading-spinner';
import { SubmissionGroup } from './constants';
import { useSubmissionsOverview } from './hooks';
import { MissionSubmissionsOverviewList } from './mission-submissions-overview-list.component';
import { TeamSubmissionsOverviewList } from './team-submissions-overview-list.component';

interface MissionSubmissionsOverviewProps {
  submissionGroup: SubmissionGroup;
}

export const SubmissionsOverviewList = ({ submissionGroup }: MissionSubmissionsOverviewProps) => {
  const { isLoading, isError, isUninitialized } = useSubmissionsOverview();

  if (isLoading || isUninitialized) {
    return (
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (isError) {
    return (
      <Card>
        <div className="flex justify-center py-16 px-8">
          <ErrorState />
        </div>
      </Card>
    );
  }

  if (submissionGroup === 'mission') {
    return <MissionSubmissionsOverviewList />;
  } else {
    return <TeamSubmissionsOverviewList submissionGroup={submissionGroup} />;
  }
};
