import type { IconName, ThemeColor } from '@goosechase/ui';

import { TriggerTimeUnit } from 'data/models';

export interface DropdownOptionType {
  id: string;
  icon?: IconName;
  iconColor?: ThemeColor;
  title: string;
  description: string;
}

export type BroadcastTriggerTimeUnit =
  | TriggerTimeUnit.Minutes
  | TriggerTimeUnit.Hours
  | TriggerTimeUnit.Days;

export const TIME_UNIT_TO_TRANSLATION_KEY_MAP: Record<BroadcastTriggerTimeUnit, string> = {
  [TriggerTimeUnit.Minutes]: 'minutes',
  [TriggerTimeUnit.Hours]: 'hours',
  [TriggerTimeUnit.Days]: 'days',
};
