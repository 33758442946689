var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { mergeClasses } from './merge-classes';
export var getResponseStateBorderClassname = function (responseState) {
    if (responseState === 'success') {
        return 'border-vibrantGreen focus-within:border-vibrantGreen';
    }
    if (responseState === 'error') {
        return 'border-vibrantRed focus-within:border-vibrantRed';
    }
    return '';
};
export var getTextFieldClasses = function (disabled, className) {
    return mergeClasses('outline-0 focus-visible:outline-none text-ellipsis w-full placeholder:text-black-48 focus:placeholder:text-black-64 caret-black bg-inherit', disabled && 'text-black-64 placeholder:text-black-64', className);
};
export var getContainerClassname = function (disabled, rounded, className) {
    return mergeClasses('flex border overflow-hidden border-black-24 focus-within:border-black', disabled && 'bg-black-4', rounded ? 'rounded-round-text-input' : 'rounded-lg', className);
};
// Used for consistent styling of text input/area containers
export var TextContainer = function (_a) {
    var children = _a.children, className = _a.className, disabled = _a.disabled, rounded = _a.rounded;
    return _jsx("div", __assign({ className: getContainerClassname(disabled, rounded, className) }, { children: children }));
};
