var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { getDataTestIDProp, getTestIDProp } from '../../util/test-id';
import { ButtonIcon, ButtonLabel } from '../components';
import { getBaseButtonClassNames, getButtonIconProps, getButtonLabelClassNames, getButtonTypeClassNames, } from '../button-utils';
var BaseButton = function (_a) {
    var label = _a.label, _b = _a.size, size = _b === void 0 ? 'lg' : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, className = _a.className, leftIcon = _a.leftIcon, rightIcon = _a.rightIcon, iconColor = _a.iconColor, iconDisabledColor = _a.iconDisabledColor, iconHoverClassNames = _a.iconHoverClassNames, testID = _a.testID, buttonType = _a.buttonType, htmlProps = __rest(_a, ["label", "size", "disabled", "className", "leftIcon", "rightIcon", "iconColor", "iconDisabledColor", "iconHoverClassNames", "testID", "buttonType"]);
    var baseClassNames = ['justify-center border-2', getBaseButtonClassNames(disabled)];
    var sizeClassNames = size === 'lg'
        ? ['h-[52px]', 'px-[28px]', 'rounded-button-lg']
        : ['h-[40px]', 'px-[20px]', 'rounded-button-sm'];
    return (_jsxs("button", __assign({ className: classNames(baseClassNames, sizeClassNames, className), disabled: disabled }, getDataTestIDProp(testID), htmlProps, { children: [leftIcon && (_jsx(ButtonIcon, __assign({ name: leftIcon, className: size === 'lg' ? 'mr-3.5' : 'mr-2.5', size: size === 'lg' ? 24 : 16, disabled: disabled, iconColor: iconColor, iconDisabledColor: iconDisabledColor, iconHoverClassNames: iconHoverClassNames }, getTestIDProp(testID, 'left-icon')))), _jsx(ButtonLabel, __assign({ text: label, size: size, disabled: disabled }, getTestIDProp(testID, 'label'))), rightIcon && (_jsx(ButtonIcon, __assign({ name: rightIcon, className: size === 'lg' ? 'ml-3.5' : 'ml-2.5', size: size === 'lg' ? 24 : 16, disabled: disabled, iconColor: iconColor, iconDisabledColor: iconDisabledColor, iconHoverClassNames: iconHoverClassNames }, getTestIDProp(testID, 'right-icon'))))] })));
};
var FilledButton = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    var buttonTypeClassNames = getButtonTypeClassNames(true, props.disabled, props.buttonType);
    var _b = getButtonIconProps(true), iconColor = _b.iconColor, iconDisabledColor = _b.iconDisabledColor, iconHoverClassNames = _b.iconHoverClassNames;
    var labelClassNames = getButtonLabelClassNames(true, props.disabled, props.buttonType);
    return (_jsx(BaseButton, __assign({ className: classNames(labelClassNames, buttonTypeClassNames, className), iconColor: iconColor, iconDisabledColor: iconDisabledColor, iconHoverClassNames: iconHoverClassNames }, props)));
};
var OutlinedButton = function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    var buttonTypeClassNames = getButtonTypeClassNames(false, props.disabled, props.buttonType);
    var _b = getButtonIconProps(false, props.buttonType), iconColor = _b.iconColor, iconDisabledColor = _b.iconDisabledColor, iconHoverClassNames = _b.iconHoverClassNames;
    var labelClassNames = getButtonLabelClassNames(false, props.disabled, props.buttonType);
    return (_jsx(BaseButton, __assign({ className: classNames(labelClassNames, buttonTypeClassNames, className), iconColor: iconColor, iconDisabledColor: iconDisabledColor, iconHoverClassNames: iconHoverClassNames }, props)));
};
export var Button = function (_a) {
    var _b = _a.outlined, outlined = _b === void 0 ? false : _b, rest = __rest(_a, ["outlined"]);
    if (outlined) {
        return _jsx(OutlinedButton, __assign({}, rest));
    }
    return _jsx(FilledButton, __assign({}, rest));
};
