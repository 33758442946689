import { Icon, Text, mergeClasses } from '@goosechase/ui';
import { useTranslate } from '../../util/i18n';

interface LikeUIProps {
  isLiked: boolean;
  numLikes: number;
}

const LikeUI = ({ isLiked, numLikes }: LikeUIProps) => {
  const { t } = useTranslate('likeButton');

  return (
    <div className="flex flex-row gap-1 w-fit items-center">
      <Icon
        name={isLiked ? 'LikeFilled' : 'Like'}
        size={24}
        color={isLiked ? 'vibrantBlue' : 'black-64'}
      />
      <Text
        className={mergeClasses('font-bold', {
          'text-vibrantBlue': isLiked,
          'text-black-64': !isLiked,
        })}
        size="sm">
        {t('numLikes', { count: numLikes })}
      </Text>
    </div>
  );
};

export interface LikeButtonProps extends LikeUIProps {
  onClick?: () => void;
}

export const LikeButton = ({ isLiked, numLikes, onClick }: LikeButtonProps) => {
  if (onClick) {
    return (
      <button className="outline-black w-fit" onClick={onClick}>
        <LikeUI isLiked={isLiked} numLikes={numLikes} />
      </button>
    );
  }

  return <LikeUI isLiked={isLiked} numLikes={numLikes} />;
};
