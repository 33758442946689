import { ArrayElement } from '../../global';
import {
  MissionSubmissionOverviewCard,
  MissionSubmissionOverviewCardProps,
} from '../../components/submission-overview-card';
import { SubmissionsOverviewQuery } from '../../data/submissions';
import { useSubmissionsOverview } from './hooks';
import { useExperienceOutletContext } from '../../components/experience-layout';
import { SubmissionGridContainer } from '../../components/submission-grid';
import { SubmissionsEmptyState } from '../../components/submissions-empty-states.component';

const filterMissionsWithSubmissions = (
  mission: ArrayElement<SubmissionsOverviewQuery['experienceMissions']>,
) => mission.numSubmissions > 0;

const extractCameraSubmissionAssetUrl = (
  mission: ArrayElement<SubmissionsOverviewQuery['experienceMissions']>,
): string | undefined => {
  if (mission.__typename === 'CameraMission') {
    if (mission.submissions[0]?.__typename === 'CameraSubmission') {
      return mission.submissions[0]?.assets?.find((asset) => asset.role === 'PHOTO')?.url;
    }
  }
  return undefined;
};

const extractCameraSubmissionCaption = (
  mission: ArrayElement<SubmissionsOverviewQuery['experienceMissions']>,
): string | undefined => {
  if (mission.__typename === 'CameraMission') {
    if (mission.submissions[0]?.__typename === 'CameraSubmission') {
      return mission.submissions[0]?.caption ?? undefined;
    }
  }
  return undefined;
};

const convertMissionToComponentProps = (
  mission: ArrayElement<SubmissionsOverviewQuery['experienceMissions']>,
): Omit<MissionSubmissionOverviewCardProps, 'experienceId'> => ({
  missionId: mission.id,
  missionType: mission.__typename,
  missionName: mission.displayName,
  missionPoints: mission.points,
  numSubmissions: mission.numSubmissions,
  submissionImageUrl: extractCameraSubmissionAssetUrl(mission),
  submissionCaption: extractCameraSubmissionCaption(mission),
});

export const MissionSubmissionsOverviewList = () => {
  const { experienceId } = useExperienceOutletContext();
  const { data } = useSubmissionsOverview();

  const missionsWithSubmissions =
    data?.experienceMissions
      .filter(filterMissionsWithSubmissions)
      .map(convertMissionToComponentProps) ?? [];

  if (missionsWithSubmissions.length === 0) {
    return <SubmissionsEmptyState />;
  }

  return (
    <SubmissionGridContainer>
      {missionsWithSubmissions.map((mission) => (
        <MissionSubmissionOverviewCard
          key={mission.missionId}
          experienceId={experienceId}
          {...mission}
        />
      ))}
    </SubmissionGridContainer>
  );
};
