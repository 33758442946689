import * as Sentry from '@sentry/react';
import { Headline, Text } from '@goosechase/ui';

import { OrganizationPlans } from '../../components/plans';
import { Page } from '../../components/page.component';
import { AVAILABLE_EDU_PLANS, AVAILABLE_STANDARD_PLANS } from '../../constants/plans';
import {
  useAvailablePlansQuery,
  useCompletePlanCheckoutMutation,
  useStartPlanCheckoutMutation,
} from '../../data/workspaces';
import { useTranslate } from '../../util/i18n';
import { RequestQuoteForm } from '../../components/request-quote';
import { getChargebeeInstance } from 'services/chargebee';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'constants/routes';
import { EducatorPlans } from 'components/plans/educator-plans';
import { useMultipleWorkspacesFeatureFlag } from '../../hooks/use-feature-flag.hook';
import { OldPlansPage } from './old-plans.page';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { usePersonalWorkspace } from '../../hooks/use-personal-workspace';
import { useGenericErrorToast } from '../../services/toast';

export const PlansPage = () => {
  const { t } = useTranslate('pages.plans');
  const activeWorkspace = useActiveWorkspace();
  const personalWorkspace = usePersonalWorkspace();
  const [startPlanCheckout] = useStartPlanCheckoutMutation();
  const [completePlanCheckout] = useCompletePlanCheckoutMutation();
  const navigate = useNavigate();
  const displayErrorToast = useGenericErrorToast();

  const workspace = activeWorkspace ?? personalWorkspace;

  const { data: plansData } = useAvailablePlansQuery(
    { workspaceType: workspace?.type ?? null },
    {
      skip: !workspace,
    },
  );

  const plansAllowList =
    workspace?.type === 'EDUCATOR' ? AVAILABLE_EDU_PLANS : AVAILABLE_STANDARD_PLANS;

  const availablePlans = plansData?.plans?.filter((p) => plansAllowList.includes(p.id)) ?? [];

  const handlePlanSelection = (planId: string) => () => {
    if (workspace) {
      const cb = getChargebeeInstance();
      cb.openCheckout({
        hostedPage: () =>
          startPlanCheckout({ planId, workspaceId: workspace.id })
            .unwrap()
            .then((response) => response.startPlanCheckout),
        success: async (checkoutId) => {
          try {
            await completePlanCheckout({ checkoutId }).unwrap();
            navigate(AppRoutes.MY_EXPERIENCES);
          } catch (e) {
            // If this error occurs, it means the user successfully purchased a plan
            //   but something went wrong and we failed to apply it to their workspace
            Sentry.captureException(e, { tags: { checkoutId } });
            displayErrorToast();
          }
        },
        error: () => {
          displayErrorToast();
        },
      });
    } else {
      Sentry.captureMessage('No workspace was selected while trying to purchase a plan');
      displayErrorToast();
    }
  };

  const currentPlan = workspace?.subscription?.plan;

  return (
    <Page title={t('meta.title')}>
      <div className="w-full">
        <div className="bg-yellowOrange-10 w-full px-6 py-12 h-max">
          <div className="max-w-[1225px] mx-auto">
            <Headline type="primary" size="sm" className="mb-2 text-center">
              {t('headline')}
            </Headline>
            <Text className="mb-6 text-center">
              {t(`subtitle.${workspace?.type === 'EDUCATOR' ? 'educator' : 'regular'}`)}
            </Text>
            <div className="tablet-wide:max-w-[796px] desktop:max-w-max mx-auto">
              <div className="grid grid-cols-1 tablet-wide:grid-cols-2 desktop:grid-cols-4 gap-4">
                {workspace?.type === 'EDUCATOR' ? (
                  <EducatorPlans
                    currentPlan={currentPlan}
                    plans={availablePlans}
                    onClick={handlePlanSelection}
                  />
                ) : (
                  <OrganizationPlans
                    currentPlan={currentPlan}
                    plans={availablePlans}
                    onClick={handlePlanSelection}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {workspace?.type !== 'EDUCATOR' ? (
          <div className="bg-white w-full px-6 py-12 h-max">
            <div className="tablet-wide:max-w-[796px] desktop:max-w-[1225px] mx-auto w-full">
              <RequestQuoteForm />
            </div>
          </div>
        ) : null}
      </div>
    </Page>
  );
};

// TODO: Delete OldPlansPage and Variant after feature is live
export const PlansPageVariant = () => {
  const multipleWorkspacesFlag = useMultipleWorkspacesFeatureFlag();

  if (multipleWorkspacesFlag) {
    return <PlansPage />;
  }

  return <OldPlansPage />;
};
