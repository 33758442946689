import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Text } from '@goosechase/ui';

import { Card } from '../../components/card.component';
import { ErrorState } from '../../components/error-state';
import { LoadingSpinner } from '../../components/loading-spinner';
import { PageHeadline } from '../../components/page-headline.component';
import { useActiveCohortId } from '../../data/experiences';
import { setActiveMissionId, useMissionSubmissionsQuery } from '../../data/submissions';
import { MissionPointsPill } from '../../components/points-pill';
import { useTranslate } from '../../util/i18n';
import { SubmissionBreadcrumbs } from '../../components/submission-breadcrumbs';
import { SubmissionGrid } from '../../components/submission-grid';
import { convertGqlMissionSubmissionToUIMissionSubmission } from './util';
import { SubmissionFocusedViewModal } from '../../components/submission-focused-view-modal';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { useSubmissionFocusedViewModal } from 'hooks/use-submission-focused-view-modal-hook';

export const MissionSubmissionsPage = () => {
  const { missionId } = useParams();
  const { experienceId } = useExperienceInfo();
  const dispatch = useDispatch();
  const cohortId = useActiveCohortId();
  const { t } = useTranslate('pages.missionSubmissions');
  const { data, isLoading, isUninitialized, isError } = useMissionSubmissionsQuery(
    { missionId: missionId ?? '', cohortId: cohortId ?? '' },
    { skip: missionId === undefined || cohortId == null },
  );
  const {
    submissionId,
    bonusFocus,
    setBonusFocus,
    handleSubmissionClick,
    handleBonusClick,
    setFocusedSubmissionId,
  } = useSubmissionFocusedViewModal();

  useEffect(() => {
    dispatch(setActiveMissionId(missionId ?? null));

    return () => {
      // Clears the activeMissionId when navigating away from this page
      dispatch(setActiveMissionId(null));
    };
    // Only want to run when missionId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionId]);

  const mission = data?.mission;

  if (isLoading || isUninitialized) {
    return (
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (isError || mission == null) {
    return (
      <Card>
        <div className="flex justify-center py-16 px-8">
          <ErrorState />
        </div>
      </Card>
    );
  }

  const submissions = mission.submissions.map(
    convertGqlMissionSubmissionToUIMissionSubmission({
      missionLocationName:
        mission.__typename === 'GpsMission' ? mission.locationName ?? undefined : undefined,
      missionName: mission.displayName,
      missionType: mission.__typename,
      missionPoints: mission.points,
      mission,
    }),
  );

  const currentFocusedSubmission = submissions.find(
    (submission) => submission.submissionId === submissionId,
  );

  return (
    <>
      <div className="mb-6">
        <div className="flex flex-col gap-6">
          <SubmissionBreadcrumbs
            experienceId={experienceId}
            activeBreadcrumbLabel={mission.displayName}
          />
          <div className="flex justify-between">
            <div className="flex flex-col gap-2">
              <PageHeadline>{mission.displayName}</PageHeadline>
              <div className="flex flex-row gap-3">
                <MissionPointsPill points={mission.points} type={mission.__typename} />
                <Text size="md">{t('numSubmissions', { count: mission.numSubmissions })}</Text>
              </div>
            </div>
            {/* TODO: Add Download button - https://app.asana.com/0/0/1205549485090264/f */}
          </div>
        </div>
      </div>
      <SubmissionGrid
        experienceId={experienceId}
        submissions={submissions}
        onSubmissionClick={handleSubmissionClick}
        onSubmissionBonus={handleBonusClick}
      />
      <SubmissionFocusedViewModal
        experienceId={experienceId}
        setCurrentFocusedSubmissionId={setFocusedSubmissionId}
        currentFocusedSubmission={currentFocusedSubmission}
        submissions={submissions}
        autoFocusBonus={bonusFocus}
        setAutoFocusBonus={setBonusFocus}
      />
    </>
  );
};
