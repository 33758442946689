import { AppRoutes } from 'constants/routes';
import { useNavigate } from 'react-router';
import { StarterAddon } from './starter';
import { ProfessionalAddon } from './professional';
import { EnterpriseAddon } from './enterprise';
import { RecreationalAddon } from './recreational';

type AddonsProps = {
  addons: {
    priceUsdCents: number;
    id: string;
  }[];
  purchasedAddonIds: string[];
  onClick: (addonId: string) => () => void;
};

export const Addons = (props: AddonsProps) => {
  const navigate = useNavigate();

  const goToQuoteForm = () => {
    const quoteForm = document.querySelector('#quote');
    quoteForm?.scrollIntoView({ behavior: 'smooth' });
  };

  const goToNewExperienceForm = () => navigate(AppRoutes.CREATE_EXPERIENCE);

  const starterAddon = props.addons?.find((plan) => plan.id === 'starter');
  const professionalAddon = props.addons?.find((plan) => plan.id === 'professional');

  return (
    <>
      <RecreationalAddon priceUsdCents={0} selected={false} onClick={goToNewExperienceForm} />
      <StarterAddon
        priceUsdCents={starterAddon?.priceUsdCents ?? 0}
        selected={props.purchasedAddonIds.includes('starter')}
        onClick={props.onClick('starter')}
      />
      <ProfessionalAddon
        priceUsdCents={professionalAddon?.priceUsdCents ?? 0}
        selected={
          props.purchasedAddonIds.includes('professional') ||
          props.purchasedAddonIds.includes('starter-to-professional')
        }
        onClick={
          props.purchasedAddonIds.includes('starter')
            ? props.onClick('starter-to-professional')
            : props.onClick('professional')
        }
      />
      <EnterpriseAddon selected={false} onClick={goToQuoteForm} />
    </>
  );
};
