import { mergeClasses, Text } from '@goosechase/ui';
import { Link } from 'components/link.component';
import { SubmissionArchive } from 'data/models';
import { formatDistance } from 'date-fns';
import { useTranslate } from 'util/i18n';

type SubmissionArchivesListProps = {
  currentSubmissionArchiveId?: string;
  submissionArchives: Pick<SubmissionArchive, 'name' | 'url' | 'id' | 'grouping' | 'createdAt'>[];
};

export const SubmissionArchivesList = ({
  currentSubmissionArchiveId,
  submissionArchives,
}: SubmissionArchivesListProps) => {
  const { t } = useTranslate('downloadSubmissions.modal');

  return (
    <table className="table-auto">
      <thead className="border-y border-y-black-12">
        <tr>
          <th className="text-left uppercase p-3 pr-4">
            <Text>{t('table.name')}</Text>
          </th>
          <th className="text-left uppercase p-3 pr-4">
            <Text>{t('table.grouping')}</Text>
          </th>
          <th className="text-left uppercase p-3">
            <Text>{t('table.created')}</Text>
          </th>
        </tr>
      </thead>
      <tbody>
        {submissionArchives.map((submissionArchive) => {
          return (
            <tr
              key={submissionArchive.id}
              className={mergeClasses('p-3', {
                'bg-yellowOrange-10': submissionArchive.id === currentSubmissionArchiveId,
              })}>
              <td className="p-3">
                <Link
                  href={submissionArchive.url ?? ''}
                  target="_blank"
                  className="text-vibrantBlue">
                  {submissionArchive.name}
                </Link>
              </td>
              <td className="p-3">
                <Text>{t(`table.${submissionArchive.grouping}`)}</Text>
              </td>
              <td className="p-3">
                <Text>
                  {formatDistance(new Date(submissionArchive.createdAt), new Date(), {
                    addSuffix: true,
                  })}
                </Text>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
