import { Headline, Text } from '@goosechase/ui';
import { useExperienceMissionsQuery } from '../../data/missions';
import { useTranslate } from 'util/i18n';

import { Card } from '../../components/card.component';
import { useActiveCohortId } from '../../data/experiences';
import { useExperienceOutletContext } from '../../components/experience-layout';

export const PointsOverview = () => {
  const { t } = useTranslate('pages.missions.pointsOverview');
  const { experienceId } = useExperienceOutletContext();
  const cohortId = useActiveCohortId();
  const { data } = useExperienceMissionsQuery(
    {
      id: experienceId,
      cohortId: cohortId ?? '',
    },
    { skip: cohortId === null },
  );

  const missions =
    data?.experienceMissions.reduce((acc, current) => {
      acc[current.points] = (acc[current.points] || 0) + 1;
      return acc;
    }, {} as Record<number, number>) ?? {};

  const totalPoints = data?.experienceMissions.reduce((acc, current) => acc + current.points, 0);

  return (
    <Card className="p-7 w-full flex flex-col gap-7">
      <Headline type="secondary" size="xs">
        {t('title')}
      </Headline>
      <table className="table-fixed">
        <thead>
          <tr className="border-b border-black-12">
            <th className="text-left w-full pb-3">
              <Text size="xs">{t('points')}</Text>
            </th>
            <th className="text-right whitespace-nowrap pb-3">
              <Text size="xs">{t('numberOfMissions')}</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(missions).map(([points, count]) => (
            <tr key={points} className="border-b border-black-12">
              <td className="py-3">
                <Text size="sm">{t('points', { count: Number(points) })}</Text>
              </td>
              <td className="text-right py-3">
                <Text size="sm">{count}</Text>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th className="text-left py-3">
              <Text size="xs">{t('total')}</Text>
            </th>
          </tr>
          <tr>
            <td>
              <Text size="sm">{t('points', { count: totalPoints ?? 0 })}</Text>
            </td>
            <td className="text-right">
              <Text size="sm">{data?.experienceMissions.length ?? 0}</Text>
            </td>
          </tr>
        </tfoot>
      </table>
    </Card>
  );
};
