import { Icon, Label, Tooltip } from '@goosechase/ui';
import { Nullable } from 'types/util';
import { useTranslate } from 'util/i18n';

import { type MissionTrigger, MissionStatus } from '../../data/models';
import { MissionTriggerInfo } from './mission-trigger-info.component';

interface MissionStatusInfoProps {
  status: MissionStatus;
  releaseAt?: Nullable<string>;
  expireAt?: Nullable<string>;
  releaseTrigger?: MissionTrigger;
  expireTrigger?: MissionTrigger;
  cohortStartDate?: Nullable<string>;
  cohortEndDate?: Nullable<string>;
  draft: boolean;
  experienceTimezone: string;
}

export const MissionStatusInfo = ({
  status,
  releaseTrigger,
  expireTrigger,
  expireAt,
  releaseAt,
  cohortEndDate,
  cohortStartDate,
  draft,
  experienceTimezone,
}: MissionStatusInfoProps) => {
  const { t } = useTranslate('missionInformationTags');

  if (draft) {
    return (
      <div>
        <div className="w-full h-px my-4 bg-black-12 max-w-[500px]" />
        <div className="flex flex-row gap-2 items-center mt-4 py-0.5">
          <Icon size={12} name="Circle" color="yellowOrange-50" />
          <Tooltip body={t('draftTooltip')} className="w-[156px]">
            <Label size="lg" className="uppercase">
              {t('draft')}
            </Label>
          </Tooltip>
        </div>
      </div>
    );
  }

  if (!releaseTrigger && !expireTrigger) {
    return null;
  }

  return (
    <div className="space-y-2">
      <div className="w-full h-px my-4 bg-black-12 max-w-[500px]" />
      {releaseTrigger ? (
        <MissionTriggerInfo
          {...releaseTrigger}
          status={status}
          expireAt={expireAt}
          releaseAt={releaseAt}
          cohortEndDate={cohortEndDate}
          cohortStartDate={cohortStartDate}
          experienceTimezone={experienceTimezone}
        />
      ) : null}
      {expireTrigger ? (
        <MissionTriggerInfo
          {...expireTrigger}
          status={status}
          expireAt={expireAt}
          releaseAt={releaseAt}
          cohortEndDate={cohortEndDate}
          cohortStartDate={cohortStartDate}
          experienceTimezone={experienceTimezone}
        />
      ) : null}
    </div>
  );
};
