import { Headline } from '@goosechase/ui';
import { useActiveCohortId, useLoadExperienceStartAndEndQuery } from 'data/experiences';
import { BroadcastFormUI } from './broadcast-form-ui.component';
import { useUpdateScheduledBroadcastMutation } from '../../data/broadcasts';
import { BroadcastFormOutputData } from './use-broadcast-form';
import { LoadingSpinner } from 'components/loading-spinner';
import {
  Broadcast,
  BroadcastTrigger,
  BroadcastSendOption,
  BroadcastRecipientType,
  BroadcastTaskType,
  BroadcastTriggerTiming,
  BroadcastType,
  TriggerTimeAnchor,
  TriggerTimeUnit,
} from 'data/models';
import { useTranslate } from 'util/i18n';
import { displayToast } from 'services/toast';
import { Trans } from 'react-i18next';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';

interface EditBroadcastFormProps {
  initialBroadcast: Broadcast;
  onSubmitSuccess?: () => void;
  onCancel?: () => void;
}

const sendTimingFromTriggerData = (trigger: BroadcastTrigger) => {
  if (trigger.timing === BroadcastTriggerTiming.Relative) {
    switch (trigger.relativeAnchor) {
      case TriggerTimeAnchor.BeforeStart:
        return BroadcastSendOption.BeforeExperience;
      case TriggerTimeAnchor.AtStart:
        return BroadcastSendOption.AtStartOfExperience;
      case TriggerTimeAnchor.AfterStart:
        return BroadcastSendOption.DuringExperience;
      case TriggerTimeAnchor.BeforeEnd:
        return BroadcastSendOption.DuringExperience;
      case TriggerTimeAnchor.AtEnd:
        return BroadcastSendOption.AtEndOfExperience;
      case TriggerTimeAnchor.AfterEnd:
        return BroadcastSendOption.AfterExperience;
    }
  }

  if (trigger.timing === BroadcastTriggerTiming.Specific) {
    return BroadcastSendOption.DuringExperience;
  }
};

export const EditBroadcastForm = ({
  initialBroadcast,
  onCancel,
  onSubmitSuccess,
}: EditBroadcastFormProps) => {
  const { t } = useTranslate('broadcastForm');
  const cohortId = useActiveCohortId();
  const [updateScheduledBroadcast, { isLoading: isLoadingUpdateScheduledBroadcast }] =
    useUpdateScheduledBroadcastMutation();
  const { isLoading: isLoadingStartAndEndData, data: startAndEndData } =
    useLoadExperienceStartAndEndQuery({ id: cohortId ?? '' }, { skip: !cohortId });
  const experienceTimezone = useExperienceInfo().data?.experience?.timezone ?? 'UTC';

  const isLoading = isLoadingUpdateScheduledBroadcast || isLoadingStartAndEndData || !cohortId;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  // eslint-disable-next-line complexity
  const handleSubmit = async (data: BroadcastFormOutputData) => {
    const { sendTo, teamName, message, sendTiming } = data;

    try {
      let timingParams;
      if (sendTiming === BroadcastSendOption.Now) {
        timingParams = { timing: BroadcastTriggerTiming.Now };
      } else if (sendTiming === BroadcastSendOption.BeforeExperience) {
        timingParams = {
          timing: BroadcastTriggerTiming.Relative,
          relativeDuration: data.beforeExperienceDuration,
          relativeAnchor: TriggerTimeAnchor.BeforeStart,
          relativeUnit: data.beforeExperienceUnit,
        };
      } else if (sendTiming === BroadcastSendOption.AtStartOfExperience) {
        timingParams = {
          timing: BroadcastTriggerTiming.Relative,
          relativeAnchor: TriggerTimeAnchor.AtStart,
          relativeDuration: 1, // this will be ignored for trigger calculations
          relativeUnit: TriggerTimeUnit.Minutes, // this will be ignored for trigger calculations
        };
      } else if (sendTiming === BroadcastSendOption.DuringExperience) {
        if (data.duringExperienceTiming === BroadcastTriggerTiming.Relative) {
          timingParams = {
            timing: BroadcastTriggerTiming.Relative,
            relativeAnchor: data.relativeAnchor,
            relativeDuration: data.relativeDuration,
            relativeUnit: data.relativeUnit,
          };
        } else {
          timingParams = {
            timing: BroadcastTriggerTiming.Specific,
            specificDay: data.specificDay,
            specificTime: data.specificTime,
          };
        }
      } else if (sendTiming === BroadcastSendOption.AtEndOfExperience) {
        timingParams = {
          timing: BroadcastTriggerTiming.Relative,
          relativeAnchor: TriggerTimeAnchor.AtEnd,
          relativeDuration: 1, // this will be ignored for trigger calculations
          relativeUnit: TriggerTimeUnit.Minutes, // this will be ignored for trigger calculations
        };
      } else if (sendTiming === BroadcastSendOption.AfterExperience) {
        timingParams = {
          timing: BroadcastTriggerTiming.Relative,
          relativeDuration: data.afterExperienceDuration,
          relativeAnchor: TriggerTimeAnchor.AfterEnd,
          relativeUnit: data.afterExperienceUnit,
        };
      } else {
        return;
      }

      const response = await updateScheduledBroadcast({
        params: {
          broadcastId: initialBroadcast.id,
          message,
          taskType: BroadcastTaskType.SendBroadcast,
          type:
            sendTiming === BroadcastSendOption.Now
              ? BroadcastType.Immediate
              : BroadcastType.Scheduled,
          recipientType:
            sendTo === 'ALL' ? BroadcastRecipientType.All : BroadcastRecipientType.Team,
          teamId: sendTo === 'ALL' ? undefined : sendTo,
          ...timingParams,
        },
      }).unwrap();
      onSubmitSuccess?.();
      displayToast({
        id: response.updateScheduledBroadcast.id,
        type: 'success',
        title:
          sendTiming === BroadcastSendOption.Now
            ? t('sendSuccessToast.title')
            : t('scheduleSuccessToast.title'),
        body: (
          <Trans
            t={t}
            i18nKey={
              sendTiming === BroadcastSendOption.Now
                ? 'sendSuccessToast.body'
                : 'scheduleSuccessToast.body'
            }
            values={{ recipient: data.sendTo === 'ALL' ? t('allParticipants') : teamName }}
          />
        ),
      });
    } catch (e) {
      displayToast({
        id: initialBroadcast.id,
        type: 'error',
        title:
          sendTiming === BroadcastSendOption.Now
            ? t('sendErrorToast.title')
            : t('scheduleErrorToast.title'),
        body:
          sendTiming === BroadcastSendOption.Now
            ? t('sendErrorToast.body')
            : t('scheduleErrorToast.body'),
      });
    }
  };

  const handleCancel = () => {
    onCancel?.();
  };

  const initialSendOption = initialBroadcast.trigger
    ? sendTimingFromTriggerData(initialBroadcast.trigger)
    : BroadcastSendOption.Now;

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-start px-11 py-5 border-black-24 border-b">
        <Headline type="secondary" size="sm">
          {t('editTitle')}
        </Headline>
      </div>

      <BroadcastFormUI
        defaultValues={{
          id: initialBroadcast.id,
          sendTo:
            initialBroadcast.recipientType === 'ALL'
              ? 'ALL'
              : initialBroadcast.recipients[0].team.id,
          teamName:
            initialBroadcast.recipientType === 'ALL'
              ? ''
              : initialBroadcast.recipients[0].team.displayName,
          message: initialBroadcast.message,
          sendTiming: initialSendOption,
          beforeExperienceDuration:
            initialSendOption === BroadcastSendOption.BeforeExperience
              ? initialBroadcast.trigger?.relativeDuration
              : null,
          beforeExperienceUnit:
            initialSendOption === BroadcastSendOption.BeforeExperience
              ? initialBroadcast.trigger?.relativeUnit
              : null,
          duringExperienceTiming:
            initialSendOption === BroadcastSendOption.DuringExperience &&
            initialBroadcast.trigger?.timing !== 'NOW'
              ? initialBroadcast.trigger?.timing
              : null,
          relativeDuration: initialBroadcast.trigger?.relativeDuration,
          relativeUnit: initialBroadcast.trigger?.relativeUnit,
          relativeAnchor: initialBroadcast.trigger?.relativeAnchor,
          specificDay: initialBroadcast.trigger?.specificDay,
          specificTime: initialBroadcast.trigger?.specificTime,
          afterExperienceDuration:
            initialSendOption === BroadcastSendOption.AfterExperience
              ? initialBroadcast.trigger?.relativeDuration
              : null,
          afterExperienceUnit:
            initialSendOption === BroadcastSendOption.AfterExperience
              ? initialBroadcast.trigger?.relativeUnit
              : null,
        }}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        cohortStartDate={startAndEndData?.cohort?.startDate}
        cohortEndDate={startAndEndData?.cohort?.endDate}
        experienceTimezone={experienceTimezone}
      />
    </div>
  );
};
