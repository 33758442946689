import classNames from 'classnames';
import { Headline, mergeClasses, Text } from '@goosechase/ui';

import { ReactComponent as DefaultExperienceImage } from 'media/goose-foot-prints.svg';
import { CohortState } from 'data/models';
import { EXPERIENCE_PATH, AppRoutes } from 'constants/routes';
import { Card } from 'components/card.component';
import { Link } from 'components/link.component';
import { ExperienceStatusBadge } from 'components/experience-status-badge';
import { ExperienceCardDropdown } from './experience-card-dropdown.component';
import { useTranslate } from '../../util/i18n';
import { UpgradedPill } from './upgraded-pill.component';
import { Nullable } from 'types/util';

interface ExperienceCardImageProps {
  src?: string;
  status: CohortState;
  experienceName: string;
}

const ExperienceCardImage = ({ src, status, experienceName }: ExperienceCardImageProps) => {
  const { t } = useTranslate('experienceCard');

  return (
    <div
      className={classNames(
        'relative shrink-0 w-[80px] h-[80px] desktop:w-[115px] desktop:h-[115px] rounded-xl',
        { 'bg-offWhite': src === undefined },
      )}>
      {src ? (
        <img
          src={src}
          className="rounded-xl w-full h-full"
          alt={t('picAlt', { experienceName }) ?? undefined}
        />
      ) : (
        <DefaultExperienceImage />
      )}
      <ExperienceStatusBadge className="absolute -right-1 -bottom-1" status={status} />
    </div>
  );
};

export interface ExperienceCardProps {
  className?: string;
  experienceId: string;
  title: string;
  shareLink: Nullable<string>;
  description: string;
  srcImage?: string;
  status: CohortState;
  addons: { id: string; maxTeamsPerExperience: Nullable<number> }[];
  canUpgradeWorkspace: boolean;
  roleId?: string;
}

export const ExperienceCard = ({
  className,
  experienceId,
  title,
  description,
  shareLink,
  srcImage,
  status,
  addons,
  roleId,
  canUpgradeWorkspace,
}: ExperienceCardProps) => {
  const [highestTierAddon] = [...addons].sort((a, b) =>
    a.maxTeamsPerExperience === null
      ? 1
      : -1 ||
        (typeof b.maxTeamsPerExperience === 'number' &&
          typeof b.maxTeamsPerExperience === 'number' &&
          a.maxTeamsPerExperience > b.maxTeamsPerExperience),
  );
  return (
    <Link
      className={mergeClasses('group', className)}
      useRouter
      href={`${EXPERIENCE_PATH}/${experienceId}/${AppRoutes.SUB_PATHS.EDIT}`}>
      <Card className="p-[18px]">
        <div className="flex gap-[22px]">
          <ExperienceCardImage src={srcImage} status={status} experienceName={title} />
          <div className="flex w-full">
            <div className="flex flex-col gap-2 flex-1">
              {highestTierAddon ? <UpgradedPill addonId={highestTierAddon.id} /> : null}
              <Headline className="break-all" type="secondary" size="xs">
                {title}
              </Headline>
              <Text>{description}</Text>
            </div>
            <ExperienceCardDropdown
              experienceId={experienceId}
              experienceDisplayName={title}
              experienceShareLink={shareLink}
              name={title}
              upgradable={roleId === 'creator' && canUpgradeWorkspace && !addons.length}
              roleId={roleId}
            />
          </div>
        </div>
      </Card>
    </Link>
  );
};
