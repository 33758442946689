import { IconButton } from '@goosechase/ui';
import { useExperienceOutletContext } from 'components/experience-layout';
import { useExperienceInfoQuery } from 'data/experiences';
import { useMemo } from 'react';
import type { PopularityDatum } from './submission-popularity-bar-chart-card.component';

interface DownloadSubmissionPopularityButtonProps {
  headers: string[];
  data: PopularityDatum[];
  fileName: string;
  ariaLabel: string;
  className?: string;
}

export const DownloadSubmissionPopularityButton = ({
  headers,
  data,
  fileName,
  className,
  ariaLabel,
}: DownloadSubmissionPopularityButtonProps) => {
  const { experienceId } = useExperienceOutletContext();

  const { data: experienceInfoData } = useExperienceInfoQuery({ id: experienceId });

  const csvContent = useMemo(() => {
    const flattenedData = data.map((datum) => [
      // text fields that may contain commas must be wrapped in double quotes to not break the csv file
      datum.id,
      `"${datum.missionName}"`,
      `"${datum.teamName}"`,
      datum.likeCount,
    ]);

    return `data:text/csv;charset=utf-8,${headers.join()}\n${flattenedData?.join('\n')}`;
  }, [headers, data]);

  const handleClick = () => {
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute(
      'download',
      `${experienceInfoData?.experience?.displayName}'s ${fileName}.csv`,
    );
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file
    link.remove();
  };

  return (
    <IconButton
      icon="Save"
      iconColor="black"
      disabled={!data.length}
      onClick={handleClick}
      className={className}
      aria-label={ariaLabel}
    />
  );
};
