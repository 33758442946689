import { useState } from 'react';
import { ExperienceData, ExperienceFormUI } from '../../../components/experience-form';

import {
  useExperienceFormDetailsQuery,
  useUpdateExperienceMutation,
  type ExperienceFormDetailsQuery,
} from 'data/experiences';
import { useTranslate } from 'util/i18n';

import { LoadingSpinner } from 'components/loading-spinner';

const transformExperienceFormDetailsDataToExperienceData = (
  data?: ExperienceFormDetailsQuery,
): ExperienceData | undefined => {
  const photo = data?.experience?.assets.find((asset) => asset.role === 'PHOTO');
  return data?.experience
    ? {
        ...data.experience,
        defaultVisible: data.experience.defaultVisible ? 'visible' : 'hidden',
        photoId: photo?.id ?? null,
        photoUrl: photo?.url ?? null,
      }
    : undefined;
};

export interface UpdateExperienceFormProps {
  experienceId: string;
  onUpdateExperienceSuccess: (id: string) => Promise<void>;
}

export const UpdateExperienceForm = ({
  experienceId,
  onUpdateExperienceSuccess,
}: UpdateExperienceFormProps) => {
  const [serverError, setServerError] = useState<string | undefined>();
  const { data, isLoading, isError } = useExperienceFormDetailsQuery({ id: experienceId });
  const { t } = useTranslate('pages.createExperience.createExperienceForm');
  const [update] = useUpdateExperienceMutation();

  const experienceData = transformExperienceFormDetailsDataToExperienceData(data);

  const handleGenericError = () => {
    setServerError(t('generalError') as string);
  };

  const handleUpdateExperience = async (updateData: ExperienceData) => {
    // Clear server errors
    setServerError(undefined);

    try {
      const response = await update({
        params: {
          ...updateData,
          defaultVisible: updateData.defaultVisible === 'visible',
          id: experienceId,
        },
      }).unwrap();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onUpdateExperienceSuccess(response.updateExperience.id);
    } catch {
      // TODO: Sentry - network error
      handleGenericError();
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center py-16 px-8">
        <LoadingSpinner />
      </div>
    );
  }

  // TODO: make sure absence of experience data is properly handled while loading
  if (isError || !experienceData) {
    return <div>ERROR</div>;
  }

  return (
    <ExperienceFormUI
      onSubmit={handleUpdateExperience}
      serverError={serverError}
      experienceData={{ ...experienceData }}
    />
  );
};
