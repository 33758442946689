import { useAppSelector } from 'data/hooks';

export const useActiveCohortId = () => useAppSelector((state) => state.experiences.activeCohortId);

export const useActiveExperienceId = () =>
  useAppSelector((state) => state.experiences.activeExperienceId);

export const useUpgradeExperienceModalOpen = () =>
  useAppSelector((state) => state.experiences.upgradeExperienceModalOpen);

export const useAcceptedExperienceInvitationModalOpen = () =>
  useAppSelector((state) => state.experiences.acceptedExperienceInvitationModalOpen);

export const useSelectedExperience = () =>
  useAppSelector((state) => state.experiences.selectedExperience);

export const useCopyExperienceModalOpen = () =>
  useAppSelector((state) => state.experiences.copyExperienceModalOpen);

export const useExperienceListFilter = () =>
  useAppSelector((state) => state.experiences.experienceListFilter);
