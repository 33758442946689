import { useActiveCohortId, useLoadExperienceStartAndEndQuery } from 'data/experiences';
import { Trans } from 'react-i18next';
import { displayToast } from 'services/toast';
import { useTranslate } from 'util/i18n';
import { useExperienceOutletContext } from '../../components/experience-layout';
import { MissionFormUI } from '../../components/mission-form/mission-form-ui';
import type {
  MissionFormOutputData,
  CameraMissionFormFields,
  TextMissionFormFields,
  GpsMissionFormFields,
} from '../../components/mission-form/use-mission-form';
import {
  useCreateCameraMissionMutation,
  useCreateGpsMissionMutation,
  useCreateTextMissionMutation,
} from '../../data/missions';
import { MissionType } from '../../data/models';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';

interface CreateMissionFormProps {
  initialType: MissionType;
  onSubmitSuccess?: () => void;
  onCancel?: () => void;
}

export const CreateMissionForm = ({
  initialType,
  onCancel,
  onSubmitSuccess,
}: CreateMissionFormProps) => {
  const { experienceId } = useExperienceOutletContext();
  const cohortId = useActiveCohortId();
  const [createCameraMission, { isLoading: isLoadingCameraMission }] =
    useCreateCameraMissionMutation();
  const [createTextMission, { isLoading: isLoadingTextMission }] = useCreateTextMissionMutation();
  const [createGpsMission, { isLoading: isLoadingGpsMission }] = useCreateGpsMissionMutation();
  const { isLoading: isLoadingStartAndEndData, data: startAndEndData } =
    useLoadExperienceStartAndEndQuery({ id: cohortId ?? '' }, { skip: !cohortId });
  const experienceTimezone = useExperienceInfo().data?.experience?.timezone ?? 'UTC';

  const { t } = useTranslate('missionForm');

  const isLoading =
    isLoadingCameraMission ||
    isLoadingTextMission ||
    isLoadingGpsMission ||
    isLoadingStartAndEndData;

  const handleCreateMission = async ({ id, missionType, ...data }: MissionFormOutputData) => {
    try {
      if (missionType === 'CAMERA') {
        await createCameraMission({
          params: {
            ...(data as CameraMissionFormFields),
            experienceId,
          },
        }).unwrap();
      } else if (missionType === 'TEXT') {
        await createTextMission({
          params: {
            ...(data as TextMissionFormFields),
            experienceId,
          },
        }).unwrap();
      } else {
        // Only remaining type is GPS
        await createGpsMission({
          params: {
            ...(data as GpsMissionFormFields),
            experienceId,
          },
        }).unwrap();
      }

      displayToast({
        id,
        type: 'success',
        title: t('saveSuccessToast.title'),
        body: (
          <Trans t={t} i18nKey="saveSuccessToast.body" values={{ missionName: data.displayName }} />
        ),
      });

      onSubmitSuccess?.();
    } catch (e) {
      displayToast({
        id: 'create-mission-error-toast',
        body: (
          <Trans t={t} i18nKey="saveErrorToast.body" values={{ missionName: data.displayName }} />
        ),
        title: t('saveErrorToast.title'),
        type: 'error',
      });
      // TODO: Sentry - network error
    }
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <MissionFormUI
      defaultValues={{ missionType: initialType }}
      isLoading={isLoading}
      onSubmit={handleCreateMission}
      onCancel={handleCancel}
      cohortStartDate={startAndEndData?.cohort?.startDate}
      cohortEndDate={startAndEndData?.cohort?.endDate}
      experienceTimezone={experienceTimezone}
      missionStatus={null}
    />
  );
};
