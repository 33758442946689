import { Icon, Tooltip, mergeClasses } from '@goosechase/ui';

export interface HelpCircleProps {
  id: string;
  className?: string;
  tooltipText: string;
  variant?: 'dark' | 'light';
  customRootId?: string;
}
export const HelpCircle = ({
  className,
  tooltipText,
  id,
  variant = 'dark',
  customRootId,
  ...htmlProps
}: HelpCircleProps) => {
  return (
    <Tooltip
      id={id}
      customRootId={customRootId}
      body={tooltipText}
      variant={variant}
      className={mergeClasses('w-[220px]', className)}
      offset={10}>
      <div
        className="flex justify-center items-center h-4 w-4 rounded-full bg-highlighterYellow text-vibrantBlue"
        {...htmlProps}>
        <Icon name="Help" size={10} color="black" />
      </div>
    </Tooltip>
  );
};
