/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type AddTeamBonusMutationVariables = Types.Exact<{
  params: Types.AddTeamBonusInput;
}>;


export type AddTeamBonusMutation = { __typename: 'Mutation', addTeamBonus: { __typename: 'TeamBonus', id: string } };


export const AddTeamBonusDocument = `
    mutation AddTeamBonus($params: AddTeamBonusInput!) {
  addTeamBonus(params: $params) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    AddTeamBonus: build.mutation<AddTeamBonusMutation, AddTeamBonusMutationVariables>({
      query: (variables) => ({ document: AddTeamBonusDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddTeamBonusMutation } = injectedRtkApi;

