var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var ExternalLink = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M17.3333 12.8888V18.2222C17.3333 18.6937 17.146 19.1459 16.8126 19.4793C16.4792 19.8127 16.0271 20 15.5556 20H5.77778C5.30628 20 4.8541 19.8127 4.5207 19.4793C4.1873 19.1459 4 18.6937 4 18.2222V8.4444C4 7.97291 4.1873 7.52072 4.5207 7.18732C4.8541 6.85393 5.30628 6.66663 5.77778 6.66663H11.1111", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M14.667 4H20.0003V9.33333", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M10.2227 13.7778L20.0004 4", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
