/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { HostedCheckoutPageFragmentFragmentDoc } from '../../../addons/graphql/__generated__/hosted-checkout-page.fragment.generated';
import { rootApi } from 'data/api';
module.hot?.accept();
export type StartPlanCheckoutMutationVariables = Types.Exact<{
  planId: Types.Scalars['String']['input'];
  workspaceId: Types.Scalars['ID']['input'];
}>;


export type StartPlanCheckoutMutation = { __typename: 'Mutation', startPlanCheckout: { __typename: 'HostedCheckoutPage', id: string, type: string, url: string, state: string, embed: boolean, created_at: number, expires_at: number, object: string, updated_at: number, resource_version: number } };


export const StartPlanCheckoutDocument = `
    mutation StartPlanCheckout($planId: String!, $workspaceId: ID!) {
  startPlanCheckout(planId: $planId, workspaceId: $workspaceId) {
    ...HostedCheckoutPageFragment
  }
}
    ${HostedCheckoutPageFragmentFragmentDoc}`;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    StartPlanCheckout: build.mutation<StartPlanCheckoutMutation, StartPlanCheckoutMutationVariables>({
      query: (variables) => ({ document: StartPlanCheckoutDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useStartPlanCheckoutMutation } = injectedRtkApi;

