/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { ExperienceFragmentFragmentDoc } from './experience.fragment.generated';
import { rootApi } from 'data/api';
module.hot?.accept();
export type MyPersonalExperiencesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyPersonalExperiencesQuery = { __typename: 'Query', myPersonalExperiences: Array<{ __typename: 'Experience', id: string, displayName: string, description: string, shareLink: string | null, status: Types.CohortState, startDate: string | null, endDate: string | null, createdAt: string, assets: Array<{ __typename: 'ExperienceAsset', id: string, url: string, role: Types.ExperienceAssetRole }>, addons: Array<{ __typename: 'Addon', id: string, chargebeeSku: string | null, maxTeamsPerExperience: number | null }>, myExperienceManager: { __typename: 'ExperienceManager', role: { __typename: 'ExperienceManagerRole', id: string } } | null, workspace: { __typename: 'Workspace', id: string, subscription: { __typename: 'Subscription', plan: { __typename: 'WorkspacePlan', id: string, upgradable: boolean } } } }> };


export const MyPersonalExperiencesDocument = `
    query MyPersonalExperiences {
  myPersonalExperiences {
    ...ExperienceFragment
  }
}
    ${ExperienceFragmentFragmentDoc}`;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    MyPersonalExperiences: build.query<MyPersonalExperiencesQuery, MyPersonalExperiencesQueryVariables | void>({
      query: (variables) => ({ document: MyPersonalExperiencesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useMyPersonalExperiencesQuery, useLazyMyPersonalExperiencesQuery } = injectedRtkApi;

