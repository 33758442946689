/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type ScheduleBroadcastMutationVariables = Types.Exact<{
  params: Types.ScheduleBroadcastParams;
}>;


export type ScheduleBroadcastMutation = { __typename: 'Mutation', scheduleBroadcast: { __typename: 'Broadcast', id: string } };


export const ScheduleBroadcastDocument = `
    mutation ScheduleBroadcast($params: ScheduleBroadcastParams!) {
  scheduleBroadcast(params: $params) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ScheduleBroadcast: build.mutation<ScheduleBroadcastMutation, ScheduleBroadcastMutationVariables>({
      query: (variables) => ({ document: ScheduleBroadcastDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useScheduleBroadcastMutation } = injectedRtkApi;

