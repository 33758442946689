import { Button, Text, Headline, Label, Icon, mergeClasses } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';

type RecreationalAddonProps = {
  priceUsdCents: number;
  selected: boolean;
  onClick: () => void;
};

export const RecreationalAddon = (props: RecreationalAddonProps) => {
  const { t } = useTranslate('upgradeExperience');

  return (
    <div
      className={mergeClasses(
        'bg-white rounded-lg px-5 py-9 w-full max-w-[390px] justify-self-center',
        {
          'bg-black-12 border border-black-24': props.selected,
        },
      )}>
      <Headline type="secondary" size="sm" className="mb-6">
        {t(`recreational.title`)}
      </Headline>
      <div className="flex items-end mb-8">
        <Headline type="secondary" size="md">
          $
        </Headline>
        <Headline type="secondary" size="xl">
          {(props.priceUsdCents / 100).toFixed(0)}
        </Headline>
        <Text className="ml-3 w-16">{t('usdPerExperience')}</Text>
      </div>
      <div className="flex min-h-[52px] mb-6">
        {props.selected ? (
          <div className="flex gap-1 items-center">
            <Icon name="GooseFeet" />
            <Label size="lg" className="uppercase">
              {t('purchased')}
            </Label>
          </div>
        ) : (
          <Button label={t('try')} onClick={props.onClick} />
        )}
      </div>
      <div className="flex flex-col gap-4 mb-2 h-[90px]">
        {(t(`recreational.subtitle`, { returnObjects: true }) as string[]).map((subtitle) => (
          <Text key={subtitle} className="font-bold">
            {subtitle}
          </Text>
        ))}
      </div>
      <div className="flex flex-col gap-4">
        <div className="h-px bg-black-24 w-full" />
        {(t(`recreational.features`, { returnObjects: true }) as string[]).map((features) => (
          <Text key={features}>{features}</Text>
        ))}
      </div>
    </div>
  );
};
