import { Label, Text } from '@goosechase/ui';
import { MissionTriggerTiming, TriggerTimeAnchorV1, TriggerTimeUnit } from 'data/models';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import { Nullable } from 'types/util';
import {
  formatSpecificTime,
  TIME_ANCHOR_TO_TRANSLATION_KEY_MAP,
  TIME_UNIT_TO_TRANSLATION_KEY_MAP,
} from '../../util/time-util';

type MissionTriggerSummaryProps = {
  t: TFunction;
  timing: MissionTriggerTiming;
  relativeDuration?: Nullable<number>;
  relativeUnit?: Nullable<TriggerTimeUnit>;
  relativeAnchor?: Nullable<TriggerTimeAnchorV1>;
  specificDay?: Nullable<number>;
  specificTime?: Nullable<string>;
  triggerType: 'releaseTrigger' | 'expireTrigger';
};

export const MissionTriggerSummary = ({
  t,
  timing,
  relativeDuration,
  relativeUnit,
  relativeAnchor,
  specificDay,
  specificTime,
  triggerType,
}: MissionTriggerSummaryProps) => {
  if (
    timing === MissionTriggerTiming.Relative &&
    typeof relativeDuration === 'number' &&
    relativeUnit &&
    relativeAnchor
  ) {
    return (
      <div className="flex gap-2 flex-col">
        <Label size="lg" className="uppercase">
          {t(`timing.${triggerType}Title`)}
        </Label>
        <Text>
          <Trans
            t={t}
            i18nKey={`${triggerType}RelativeSummary`}
            values={{
              anchor: t(`relativeAnchor.${TIME_ANCHOR_TO_TRANSLATION_KEY_MAP[relativeAnchor]}`),
              time: t(TIME_UNIT_TO_TRANSLATION_KEY_MAP[relativeUnit].toLowerCase(), {
                count: relativeDuration,
              }),
            }}
          />
        </Text>
      </div>
    );
  }

  if (timing === MissionTriggerTiming.Specific && typeof specificDay === 'number' && specificTime) {
    return (
      <div className="flex gap-2 flex-col">
        <Label size="lg" className="uppercase">
          {t(`timing.${triggerType}Title`)}
        </Label>
        <Text>
          <Trans
            t={t}
            i18nKey={`${triggerType}SpecificSummary`}
            values={{
              day: specificDay,
              time: formatSpecificTime(specificTime),
            }}
          />
        </Text>
      </div>
    );
  }

  return null;
};
