import { TFunction } from 'i18next';
import { Dropdown, IconName } from '@goosechase/ui';

import { useTranslate } from '../../util/i18n';
import { MediaType, MEDIA_TYPES } from '../../data/models';
import { DropdownOptionType, renderSelectedLeftIcon } from './dropdown-option.component';
import { LabelledSetting } from '../labelled-setting.component';

const MEDIA_TYPE_TO_TRANSLATION_KEY_MAP: Record<MediaType, string> = {
  PHOTO: 'photo',
  VIDEO: 'video',
  PHOTO_AND_VIDEO: 'photoAndVideo',
};

const MEDIA_TYPE_TO_ICON_MAP: Record<MediaType, IconName> = {
  PHOTO: 'Camera',
  VIDEO: 'VideoCam',
  PHOTO_AND_VIDEO: 'PhotosVideos',
};

const convertMediaTypeToDropdownOption = (
  t: TFunction,
  mediaType: MediaType,
): DropdownOptionType => ({
  id: mediaType,
  icon: MEDIA_TYPE_TO_ICON_MAP[mediaType],
  title: t(`${MEDIA_TYPE_TO_TRANSLATION_KEY_MAP[mediaType]}.title`),
  description: t(`${MEDIA_TYPE_TO_TRANSLATION_KEY_MAP[mediaType]}.description`),
});

interface MediaTypeDropdownProps {
  value: MediaType;
  onChange: (value: MediaType) => void;
}

export const MediaTypeDropdown = ({ value, onChange }: MediaTypeDropdownProps) => {
  const { t } = useTranslate('missionForm.mediaType');

  const options = MEDIA_TYPES.map((mediaType) => convertMediaTypeToDropdownOption(t, mediaType));

  const selectedDropdownOption = convertMediaTypeToDropdownOption(t, value);

  return (
    <LabelledSetting label={t('title')} description={t('description')}>
      <Dropdown
        buttonClassName="w-full"
        label={selectedDropdownOption.title}
        options={options}
        selectedOptionId={value}
        renderLeft={renderSelectedLeftIcon(selectedDropdownOption)}
        onSelect={(val: string) => onChange(val as MediaType)}
      />
    </LabelledSetting>
  );
};
