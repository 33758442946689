import { Icon, mergeClasses, Text } from '@goosechase/ui';
import { useTranslate } from '../../util/i18n';
import { AppRoutes } from '../../constants/routes';
import { Link } from '../link.component';
import { Menu } from '@headlessui/react';
import { useScreenSize } from '../../hooks/use-screen-size';
import { ExperienceNavLink } from './experience-nav-link.component';
import { useActiveExperienceSection } from '../../hooks/use-active-experience-section';
import {
  ExperienceSection,
  EXPERIENCE_SECTIONS,
  EXPERIENCE_SECTION_NAV_LINKS,
} from '../../constants/experience-sections';

interface SectionTabProps {
  label: string;
  isActive: boolean;
}

const SectionTab = ({ label, isActive }: SectionTabProps) => {
  return (
    <div
      className={mergeClasses(
        'flex font-bold text-white uppercase px-3 h-full justify-center items-center border-b-4 border-transparent cursor-pointer hover:bg-black-64',
        {
          'border-b-orange bg-black-64': isActive,
        },
      )}>
      <div className="flex items-center gap-3">
        <Text size="md">{label}</Text>
        <Icon
          className="hidden tablet-narrow:flex desktop:hidden"
          name="CaretDown"
          color="white"
          size={24}
        />
      </div>
    </div>
  );
};

const SECTION_TO_PATH_MAP: Record<ExperienceSection, string> = {
  create: AppRoutes.SUB_PATHS.EDIT,
  publish: AppRoutes.SUB_PATHS.PARTICIPANTS,
  review: AppRoutes.SUB_PATHS.FEED,
};

interface SectionTabLinkProps extends SectionTabProps {
  section: ExperienceSection;
}

const SectionTabLink = ({ section, label, isActive }: SectionTabLinkProps) => {
  return (
    <div className="h-full">
      <Link
        className="outline-white h-full"
        useRouter
        // # Prevents any navigation when the tab is already active
        href={isActive ? '#' : SECTION_TO_PATH_MAP[section]}>
        <SectionTab label={label} isActive={isActive} />
      </Link>
    </div>
  );
};

interface SectionTabDropdownProps extends SectionTabProps {
  section: ExperienceSection;
  experienceId: string;
}

const SectionTabDropdown = ({
  section,
  experienceId,
  label,
  isActive,
}: SectionTabDropdownProps) => {
  return (
    <Menu as="div" className="relative h-full">
      <Menu.Button className="outline-white h-full ui-open:bg-black-64">
        <SectionTab label={label} isActive={isActive} />
      </Menu.Button>
      <Menu.Items
        className={mergeClasses(
          'outline-0 absolute top-full flex flex-col bg-black rounded-b-lg z-10 overflow-hidden',
        )}>
        {EXPERIENCE_SECTION_NAV_LINKS[section].map((navLink) => (
          <Menu.Item key={navLink.name}>
            <ExperienceNavLink variant="header" experienceId={experienceId} {...navLink} />
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

interface ExperienceSectionTabsProps {
  experienceId: string;
}

export const ExperienceSectionTabs = ({ experienceId }: ExperienceSectionTabsProps) => {
  const { t } = useTranslate('experienceSectionTabs.sections');
  const activeSection = useActiveExperienceSection();
  const { isDesktop } = useScreenSize();
  const ExperienceSectionTab = isDesktop ? SectionTabLink : SectionTabDropdown;

  return (
    <div className="flex flex-nowrap gap-3 items-center h-full">
      {EXPERIENCE_SECTIONS.map((section) => (
        <ExperienceSectionTab
          key={section}
          section={section}
          experienceId={experienceId}
          label={t(`${section}.label`)}
          isActive={section === activeSection}
        />
      ))}
    </div>
  );
};
