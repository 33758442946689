/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type DeleteExperienceMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteExperienceMutation = { __typename: 'Mutation', deleteExperience: { __typename: 'Experience', id: string, displayName: string } };


export const DeleteExperienceDocument = `
    mutation DeleteExperience($id: ID!) {
  deleteExperience(id: $id) {
    id
    displayName
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    DeleteExperience: build.mutation<DeleteExperienceMutation, DeleteExperienceMutationVariables>({
      query: (variables) => ({ document: DeleteExperienceDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteExperienceMutation } = injectedRtkApi;

