import { Avatar, Label, Text, mergeClasses } from '@goosechase/ui';
import { MenuItem } from '@headlessui/react';
import classNames from 'classnames';
import { forwardRef } from 'react';

import { useTranslate } from 'util/i18n';
import { ExperienceListFilter } from '../../data/experiences';
import { WorkspaceScope } from '../../data/models';
import { useMyWorkspacesQuery } from '../../data/workspaces';

interface OrganizationImageProps {
  photoUrl?: string;
  displayName: string;
  isActive: boolean;
}

const OrganizationImage = ({ photoUrl, displayName, isActive }: OrganizationImageProps) => {
  return (
    <div
      className={mergeClasses('border-2 border-transparent', {
        'border-vibrantBlue rounded-md': isActive,
      })}>
      <Avatar
        placeholder="workspace"
        alt={displayName}
        size="xs3"
        src={photoUrl}
        className={mergeClasses({ 'bg-black-12 border border-black-24': !photoUrl })}
        squared
      />
    </div>
  );
};

interface OrganizationFilterOptionProps {
  className?: string;
  id: string;
  displayName: string;
  photoUrl?: string;
  isActive: boolean;
  onClick: () => void;
}

/*
  Note: The use of forwardRef and prop spreading is present to satisfy HeadlessUI props and functionality
*/
const OrganizationFilterOption = forwardRef<HTMLButtonElement, OrganizationFilterOptionProps>(
  function OrganizationOption(
    { className, id, displayName, photoUrl, isActive, onClick, ...props },
    ref,
  ) {
    return (
      <button
        ref={ref}
        className={mergeClasses('text-left w-fit max-w-full', className)}
        onClick={onClick}
        {...props}>
        <div className="flex flex-row gap-3 items-center max-w-full">
          <OrganizationImage photoUrl={photoUrl} displayName={displayName} isActive={isActive} />
          <Text
            className={classNames('cursor-pointer w-fit font-semibold truncate', {
              'text-vibrantBlue': isActive,
            })}>
            {displayName.substring(0, 50)}
          </Text>
        </div>
      </button>
    );
  },
);

const isFilterActive = (filterValue: string, selectedFilter: ExperienceListFilter): boolean => {
  if (selectedFilter.type === 'organization') {
    return selectedFilter.id === filterValue;
  }
  return false;
};

interface OrganizationsFiltersProps {
  isDropdown: boolean;
  selectedFilter: ExperienceListFilter;
  onSetFilter: (filter: ExperienceListFilter) => void;
}

export const OrganizationFilters = ({
  isDropdown,
  selectedFilter,
  onSetFilter,
}: OrganizationsFiltersProps) => {
  const { t } = useTranslate('experienceListFilter');
  const { data } = useMyWorkspacesQuery();

  const workspaces = data?.myWorkspaces;

  if (workspaces === undefined) {
    return null;
  }

  const organizations = workspaces.filter((w) => w.scope === WorkspaceScope.Organizational);

  if (organizations.length === 0) {
    return null;
  }

  const handleClick = (id: string) => {
    const workspace = workspaces.find((w) => w.id === id);

    if (workspace) {
      onSetFilter({
        type: 'organization',
        id,
        displayName: workspace.displayName,
        photoUrl: workspace.photoUrl,
      });
    }
  };

  return (
    <div className={mergeClasses('flex flex-col pt-5', { 'w-full py-4': isDropdown })}>
      <Label className="uppercase mb-5">{t('organizationsTitle')}</Label>
      <div className="flex flex-col gap-3">
        {organizations.map(({ id, displayName, photoUrl }) => {
          if (isDropdown) {
            return (
              <MenuItem key={id}>
                <OrganizationFilterOption
                  className="w-full ui-active:bg-black-12"
                  id={id}
                  displayName={displayName}
                  photoUrl={photoUrl ?? undefined}
                  isActive={isFilterActive(id, selectedFilter)}
                  onClick={() => handleClick(id)}
                />
              </MenuItem>
            );
          }
          return (
            <OrganizationFilterOption
              key={id}
              id={id}
              displayName={displayName}
              photoUrl={photoUrl ?? undefined}
              isActive={isFilterActive(id, selectedFilter)}
              onClick={() => handleClick(id)}
            />
          );
        })}
      </div>
    </div>
  );
};
