import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';
/**
 *
 * Merges various classes such that the last element is always given preference over all others when merging.
 * IE ensures that mergeClasses('mt-1 text-white', 'text-blue') -> 'mt-1 text-blue' regardless of the native CSS cascading order
 */
export var mergeClasses = function () {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    if (classes.length > 1) {
        var head = classes.slice(0, -1);
        var tail = classes.slice(-1);
        return twMerge(classNames(head), classNames(tail));
    }
    return classNames(classes);
};
