/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type CompletePlanCheckoutMutationVariables = Types.Exact<{
  checkoutId: Types.Scalars['String']['input'];
}>;


export type CompletePlanCheckoutMutation = { __typename: 'Mutation', completePlanCheckout: { __typename: 'Workspace', id: string } };


export const CompletePlanCheckoutDocument = `
    mutation CompletePlanCheckout($checkoutId: String!) {
  completePlanCheckout(checkoutId: $checkoutId) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    CompletePlanCheckout: build.mutation<CompletePlanCheckoutMutation, CompletePlanCheckoutMutationVariables>({
      query: (variables) => ({ document: CompletePlanCheckoutDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCompletePlanCheckoutMutation } = injectedRtkApi;

