import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';

import { SideNavBar } from './side-nav-bar';
import { ExperienceHeaderNavBar } from './experience-nav-bar';
import {
  BaseWrapperProps,
  ContentWrapper,
  HeaderWrapper,
  LayoutWrapper,
} from './main-layout.component';
import {
  setActiveCohortId,
  setActiveExperienceId,
  useExperienceInfoQuery,
} from '../data/experiences';
import { UpgradeBanner } from './upgrade-banner';
import { ExperienceAddonModal } from './experience-addon-modal';
import { useExperienceInfo } from '../hooks/use-experience-info.hook';
import { isNotFoundError } from '../data/errors';
import { NotFoundPage } from './not-found';

export type ExperienceRouteParams = {
  id: string;
};

const ContentPane = ({ children }: BaseWrapperProps) => (
  <div className="flex grow justify-center items-center w-full h-full relative">
    <div className="flex justify-center w-full h-full overflow-y-auto">
      <div className="flex flex-col grow w-full desktop:max-w-[1080px] desktop:px-0 px-4 tablet-narrow:px-6 py-4 tablet-narrow:py-6 tablet-wide:py-8">
        {children}
      </div>
    </div>
  </div>
);

type ExperienceOutletContext = { experienceId: string };

export const useExperienceOutletContext = () => {
  return useOutletContext<ExperienceOutletContext>();
};

export const ExperienceOutlet = () => {
  const { id: experienceId } = useParams<ExperienceRouteParams>() as ExperienceRouteParams;
  const dispatch = useDispatch();

  const { data } = useExperienceInfoQuery({ id: experienceId });

  const cohortId = data?.experience?.cohorts?.[0]?.id;

  useEffect(() => {
    dispatch(setActiveExperienceId(experienceId));

    return () => {
      dispatch(setActiveExperienceId(null));
    };
    // Only want to run when experienceId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceId]);

  useEffect(() => {
    if (cohortId) {
      dispatch(setActiveCohortId(cohortId));
    }

    return () => {
      dispatch(setActiveCohortId(null));
    };
    // Only want to run when cohortId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortId]);

  return <Outlet context={{ experienceId }} />;
};

const useIsExperienceNotFound = () => {
  const { data, isLoading, error } = useExperienceInfo();

  if (isLoading) {
    return false;
  }

  return data === undefined && isNotFoundError(error);
};

export const ExperienceLayout = ({ children }: BaseWrapperProps) => {
  const context = useExperienceOutletContext();

  const isExperienceNotFound = useIsExperienceNotFound();

  if (isExperienceNotFound) {
    return <NotFoundPage />;
  }

  return (
    <LayoutWrapper>
      <ExperienceAddonModal />
      <UpgradeBanner />
      <HeaderWrapper>
        <ExperienceHeaderNavBar />
      </HeaderWrapper>
      <ContentWrapper>
        <SideNavBar experienceId={context.experienceId} />
        <ContentPane>{children ? children : <Outlet context={context} />}</ContentPane>
      </ContentWrapper>
    </LayoutWrapper>
  );
};
