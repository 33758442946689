import { Headline, Text } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { useScreenSize } from 'hooks/use-screen-size';
import { Card } from 'components/card.component';
import { PageHeadline } from '../../components/page-headline.component';
import { UpdateUserProfileForm } from './update-user-profile.component';
import { useLoadMyUserQuery } from 'data/auth';
import { CurrentPlan } from './current-plan.component';

export const AccountInfoPage = () => {
  const { isMobile } = useScreenSize();
  const { t } = useTranslate('pages.accountInfo');
  const { data } = useLoadMyUserQuery();

  return (
    <div className="flex flex-col items-center w-full">
      <div className="p-4 tablet-narrow:p-8 w-full desktop:max-w-[1080px] desktop:box-content">
        <PageHeadline>{t('title')}</PageHeadline>
        <div className="mt-4 tablet-wide:flex gap-6">
          <div className="mb-4 grow">
            <Card className="p-5">
              <Headline className="mb-2" type="secondary" size={isMobile ? '2xs' : 'xs'}>
                {t('editAccount.title')}
              </Headline>
              <Text size={isMobile ? 'sm' : 'md'} className="mb-6">
                {t('editAccount.description')}
              </Text>
              <UpdateUserProfileForm
                photoUrl={data?.myUser.profile?.photoUrl}
                email={data?.myUser.profile?.email ?? ''}
              />
            </Card>
          </div>
          <div className="w-full tablet-wide:w-[344px]">
            <Card className="flex flex-col p-5 gap-3">
              <CurrentPlan />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
