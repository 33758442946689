import { Dropdown } from '@goosechase/ui';
import { useMemo } from 'react';
import { MissionTriggerTiming } from '../../data/models';
import { useTranslate } from '../../util/i18n';
import { LabelledSetting } from '../labelled-setting.component';
import {
  TriggerType,
  useMissionTriggerDropdownOptions,
} from './use-mission-trigger-dropdown-options';

interface MissionTriggerTimingDropdownProps {
  value: MissionTriggerTiming;
  onChange: (value: MissionTriggerTiming) => void;
  triggerType: TriggerType;
}

export const MissionTriggerTimingDropdown = ({
  value,
  onChange,
  triggerType,
}: MissionTriggerTimingDropdownProps) => {
  const { t } = useTranslate('missionForm.missionTrigger');

  const options = useMissionTriggerDropdownOptions({ triggerType });

  const selectedDropdownOption = useMemo(
    () => options.find((option) => option.id === value),
    [options, value],
  );

  return (
    <LabelledSetting
      label={t(`timing.${triggerType}Title`)}
      description={t(`timing.${triggerType}Description`)}>
      <Dropdown
        buttonClassName="w-full"
        label={selectedDropdownOption?.title}
        options={options}
        selectedOptionId={value}
        onSelect={(val: string) => onChange(val as MissionTriggerTiming)}
      />
    </LabelledSetting>
  );
};
