/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type ToggleLikeSubmissionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ToggleLikeSubmissionMutation = { __typename: 'Mutation', toggleLikeSubmission: { __typename: 'CameraSubmission', id: string } | { __typename: 'GpsSubmission', id: string } | { __typename: 'TextSubmission', id: string } };


export const ToggleLikeSubmissionDocument = `
    mutation ToggleLikeSubmission($id: ID!) {
  toggleLikeSubmission(id: $id) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ToggleLikeSubmission: build.mutation<ToggleLikeSubmissionMutation, ToggleLikeSubmissionMutationVariables>({
      query: (variables) => ({ document: ToggleLikeSubmissionDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useToggleLikeSubmissionMutation } = injectedRtkApi;

