/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type SubmissionArchiveQueryVariables = Types.Exact<{
  submissionArchiveId: Types.Scalars['ID']['input'];
}>;


export type SubmissionArchiveQuery = { __typename: 'Query', submissionArchive: { __typename: 'SubmissionArchive', id: string, state: Types.SubmissionArchiveState, url: string | null } };


export const SubmissionArchiveDocument = `
    query SubmissionArchive($submissionArchiveId: ID!) {
  submissionArchive(id: $submissionArchiveId) {
    id
    state
    url
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    SubmissionArchive: build.query<SubmissionArchiveQuery, SubmissionArchiveQueryVariables>({
      query: (variables) => ({ document: SubmissionArchiveDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSubmissionArchiveQuery, useLazySubmissionArchiveQuery } = injectedRtkApi;

