import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Headline, StyledAnchor, Text } from '@goosechase/ui';

import { useTranslate, type TranslateFn } from 'util/i18n';
import { setAuthState, type SignUpMutation } from 'data/auth';
import { useAppDispatch } from 'data/hooks';
import { ReactComponent as SignUpStep1 } from 'media/sign-up-step-1.svg';
import { ReactComponent as SignUpStep2 } from 'media/sign-up-step-2.svg';
import { ReactComponent as SignUpStep3 } from 'media/sign-up-step-3.svg';
import { SignUpForm } from './sign-up-form';
import { AccountFormLayout } from 'components/account-form-layout.component';
import { AppRoutes, ExternalRoutes } from 'constants/routes';
import { AppLink } from 'components/app-link.component';
import { Page } from 'components/page.component';
import { useQueryParams } from 'hooks/use-query-params';

interface PanelProps {
  t: TranslateFn;
}
interface PanelItemProps {
  title: string;
  description: string;
  children: React.ReactNode;
}

const PanelItem = ({ title, description, children }: PanelItemProps) => (
  <div className="flex flex-col items-center text-center">
    <div className="mb-2 h-[72px] w-[72px]">{children}</div>
    <Headline size="sm" className="mb-3" type="secondary">
      {title}
    </Headline>
    <Text size="lg">{description}</Text>
  </div>
);

const DefaultSignupSidePanel = ({ t }: PanelProps) => (
  <AccountFormLayout.Panel className="bg-lightYellow">
    <PanelItem
      title={t('panel.default.step-1.title')}
      description={t('panel.default.step-1.description')}>
      <SignUpStep1 />
    </PanelItem>
    <PanelItem
      title={t('panel.default.step-2.title')}
      description={t('panel.default.step-2.description')}>
      <SignUpStep2 />
    </PanelItem>
    <PanelItem
      title={t('panel.default.step-3.title')}
      description={t('panel.default.step-3.description')}>
      <SignUpStep3 />
    </PanelItem>
  </AccountFormLayout.Panel>
);

const EduSignupSidePanel = ({ t }: PanelProps) => (
  <AccountFormLayout.Panel className="bg-cyan">
    <PanelItem title={t('panel.edu.step-1.title')} description={t('panel.edu.step-1.description')}>
      <SignUpStep1 />
    </PanelItem>
    <PanelItem title={t('panel.edu.step-2.title')} description={t('panel.edu.step-2.description')}>
      <SignUpStep2 />
    </PanelItem>
    <PanelItem title={t('panel.edu.step-3.title')} description={t('panel.edu.step-3.description')}>
      <SignUpStep3 />
    </PanelItem>
  </AccountFormLayout.Panel>
);

export const SignUpPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslate('pages.signUp');
  const queryParams = useQueryParams();

  const isEdu = useMemo(() => queryParams.get('type') === 'edu', [queryParams]);
  const redirectTo = queryParams.get('redirectTo');

  const handleSignUpSuccess = ({ userId, token, intercomHmac }: SignUpMutation['register']) => {
    dispatch(setAuthState({ accessToken: token, intercomHmac, userId }));
    if (redirectTo) {
      navigate(redirectTo);
    } else if (isEdu) {
      navigate(AppRoutes.EDUCATOR_INFO, { replace: true });
    } else {
      navigate(AppRoutes.USE_CASE_SELECTION, { replace: true });
    }
  };

  return (
    <Page title={t('meta.title')} description={t('meta.description') as string}>
      <AccountFormLayout>
        <AccountFormLayout.Body>
          <AccountFormLayout.Title>{t('header')}</AccountFormLayout.Title>
          <AccountFormLayout.Description>
            <Trans
              t={t}
              i18nKey={isEdu ? 'eduDescription' : 'description'}
              components={{ Link: <AppLink page="LOGIN" queryParams={queryParams} /> }}
            />
            {isEdu ? (
              <>
                <br />
                <Trans
                  t={t}
                  i18nKey="edu.defaultSignupCta"
                  components={{ Link: <AppLink page="SIGNUP" /> }}
                />
              </>
            ) : null}
          </AccountFormLayout.Description>
          <SignUpForm onSignUpSuccess={handleSignUpSuccess} />
          <Text className="mt-6 text-paragraph-sm desktop:text-paragraph-md">
            <Trans
              t={t}
              i18nKey="TOSDescription"
              components={[
                <StyledAnchor
                  href={ExternalRoutes.TERMS_OF_SERVICE}
                  key={ExternalRoutes.TERMS_OF_SERVICE}
                />,
                <StyledAnchor
                  href={ExternalRoutes.PRIVACY_POLICY}
                  key={ExternalRoutes.PRIVACY_POLICY}
                />,
              ]}
            />
          </Text>
        </AccountFormLayout.Body>
        {isEdu ? <EduSignupSidePanel t={t} /> : <DefaultSignupSidePanel t={t} />}
      </AccountFormLayout>
    </Page>
  );
};
