import { useTranslate } from 'util/i18n';
import { useMyPersonalExperiencesQuery } from 'data/experiences';
import { LoadingSpinner } from 'components/loading-spinner';
import { EmptyState } from 'components/empty-state/empty-state.component';
import { Card } from 'components/card.component';
import { ErrorState } from 'components/error-state';
import { Button } from '@goosechase/ui';
import { PageHeadline } from '../../components/page-headline.component';
import { useNavigate } from 'react-router';
import { AppRoutes } from '../../constants/routes';
import { transformExperiencesDataToExperiencesList } from './util';
import { ExperienceList } from './experience-list.component';

const MyPersonalExperiencesList = () => {
  const { data, isLoading, isError } = useMyPersonalExperiencesQuery();
  const { t } = useTranslate('pages.experiences.myPersonalExperiences');

  const experiences = transformExperiencesDataToExperiencesList(data?.myPersonalExperiences ?? []);

  if (isLoading) {
    return (
      <Card>
        <div className="flex justify-center py-16 px-8">
          <LoadingSpinner />
        </div>
      </Card>
    );
  }

  if (isError) {
    return (
      <Card>
        <div className="flex justify-center py-16 px-8">
          <ErrorState />
        </div>
      </Card>
    );
  }

  if (!experiences || experiences.length === 0) {
    return (
      <Card className="p-12">
        <EmptyState text={t('emptyState.text')} subtext={t('emptyState.subtext')} />
      </Card>
    );
  }

  return <ExperienceList experiences={experiences} />;
};

export const MyPersonalExperiences = () => {
  const { t } = useTranslate('pages.experiences.myPersonalExperiences');
  const navigate = useNavigate();

  const navigateToCreateNew = () => {
    navigate(AppRoutes.CREATE_EXPERIENCE);
  };

  return (
    <>
      <div className="flex justify-between w-full mb-4 tablet-narrow:mb-6 flex-col tablet-narrow:flex-row gap-4">
        <PageHeadline>{t('title')}</PageHeadline>
        <Button size="sm" label={t('createExperience')} onClick={navigateToCreateNew} />
      </div>
      <MyPersonalExperiencesList />
    </>
  );
};
