import { mergeClasses, Text } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';

type DownloadSubmissionsProgressBarProps = {
  isAvailable: boolean;
  isProcessing: boolean;
};

export const DownloadSubmissionsProgressBar = ({
  isAvailable,
  isProcessing,
}: DownloadSubmissionsProgressBarProps) => {
  const { t } = useTranslate('downloadSubmissions');

  return (
    <div>
      {isProcessing || isAvailable ? (
        <div className="w-full h-7 bg-vibrantBlue-10 rounded-full mt-5 mb-3 overflow-hidden relative">
          <div
            className={mergeClasses('h-7 bg-vibrantBlue  rounded-full absolute bottom-0 top-0', {
              'bg-vibrantGreen w-full': isAvailable,
              'animate-[progress_2s_infinite] w-1/2': isProcessing,
            })}
          />
        </div>
      ) : null}
      {isProcessing ? <Text size="sm">{t('modal.downloading')}</Text> : null}
      {isAvailable ? <Text size="sm">{t('modal.downloadReady')}</Text> : null}
    </div>
  );
};
