/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type ActiveWorkspaceQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ActiveWorkspaceQuery = { __typename: 'Query', myActiveWorkspace: { __typename: 'Workspace', displayName: string, id: string, photoUrl: string | null, myWorkspaceMember: { __typename: 'WorkspaceMember', userId: string, roleId: string } | null } | null };


export const ActiveWorkspaceDocument = `
    query ActiveWorkspace {
  myActiveWorkspace {
    displayName
    id
    myWorkspaceMember {
      userId
      roleId
    }
    photoUrl
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ActiveWorkspace: build.query<ActiveWorkspaceQuery, ActiveWorkspaceQueryVariables | void>({
      query: (variables) => ({ document: ActiveWorkspaceDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useActiveWorkspaceQuery, useLazyActiveWorkspaceQuery } = injectedRtkApi;

