import { Avatar, Text, Tooltip } from '@goosechase/ui';
import { Link } from '../../components/link.component';
import { MissionPointsPill } from '../../components/points-pill';
import { AppRoutes, EXPERIENCE_PATH } from 'constants/routes';
import { MissionTypeName, TeamMode } from 'data/models';
import { format } from 'date-fns';
import { useTranslate } from 'util/i18n';

type FeedSubmissionCardHeaderProps = {
  experienceId: string;
  teamId: string;
  teamImageUrl?: string;
  teamMode: TeamMode;
  teamName: string;
  submissionDate: Date;
  missionName: string;
  missionDescription: string;
  missionId: string;
  missionPoints: number;
  missionType: MissionTypeName;
  submissionCaption?: string;
};

const DateDisplay = ({ date }: { date: Date }) => {
  const { t } = useTranslate('common');

  return (
    <Text className="truncate" size="xs">
      {format(date, t('dateFormat'))}
    </Text>
  );
};

export const FeedSubmissionCardHeader = ({
  submissionDate,
  teamId,
  teamMode,
  teamName,
  teamImageUrl,
  experienceId,
  missionName,
  missionDescription,
  missionId,
  missionPoints,
  missionType,
  submissionCaption,
}: FeedSubmissionCardHeaderProps) => {
  const { t } = useTranslate('pages.feed.feedSubmissionCard');

  return (
    <div className="flex gap-5">
      <Avatar
        alt={teamName}
        src={teamImageUrl}
        placeholder={teamMode === TeamMode.Solo ? 'single' : 'team-duo'}
        size="sm"
      />
      <div className="flex flex-col gap-1">
        <DateDisplay date={submissionDate} />
        <Text>
          <Link
            className="font-bold text-vibrantBlue truncate"
            useRouter
            href={`${EXPERIENCE_PATH}/${experienceId}/${AppRoutes.SUB_PATHS.TEAM_SUBMISSIONS}/${teamId}`}>
            {teamName}
          </Link>
          {` ${t('completed')} `}
          <div className="inline-block">
            <Tooltip
              body={
                <span>
                  <strong>
                    <Text size="sm">{missionName}</Text>
                  </strong>
                  <Text size="xs">{missionDescription}</Text>
                </span>
              }
              variant="light"
              placement="top"
              className="mx-3 tablet-narrow:mx-0 tablet-narrow:w-76 text-left"
              offset={10}>
              <Link
                className="font-bold text-vibrantBlue truncate"
                useRouter
                href={`${EXPERIENCE_PATH}/${experienceId}/${AppRoutes.SUB_PATHS.MISSION_SUBMISSIONS}/${missionId}`}>
                {missionName}
              </Link>
            </Tooltip>
          </div>
        </Text>
        <MissionPointsPill points={missionPoints} type={missionType} />
        {submissionCaption ? <Text>{submissionCaption}</Text> : null}
      </div>
    </div>
  );
};
