/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { BaseMissionFragmentFragmentDoc } from './base-mission.fragment.generated';
import { GpsMissionFieldsFragmentFragmentDoc } from './gps-mission-fields.fragment.generated';
import { rootApi } from 'data/api';
module.hot?.accept();
export type CreateGpsMissionMutationVariables = Types.Exact<{
  params: Types.CreateGpsMissionInput;
}>;


export type CreateGpsMissionMutation = { __typename: 'Mutation', createGpsMission: { __typename: 'GpsMission', position: number | null, draft: boolean, id: string, displayName: string, description: string, points: number, image: string | null, link: string | null, feedVisibility: Types.FeedVisibility, creatorId: string, createdAt: string, updatedAt: string, missionType: Types.MissionType, locationName: string | null, latitude: string, longitude: string, radius: number, releaseTrigger: { __typename: 'MissionTrigger', createdAt: string, id: string, missionId: string, relativeAnchor: Types.TriggerTimeAnchorV1 | null, relativeDuration: number | null, relativeUnit: Types.TriggerTimeUnit | null, specificDay: number | null, specificTime: string | null, taskType: Types.MissionTaskType, timing: Types.MissionTriggerTiming, updatedAt: string } | null, expireTrigger: { __typename: 'MissionTrigger', createdAt: string, id: string, missionId: string, relativeAnchor: Types.TriggerTimeAnchorV1 | null, relativeDuration: number | null, relativeUnit: Types.TriggerTimeUnit | null, specificDay: number | null, specificTime: string | null, taskType: Types.MissionTaskType, timing: Types.MissionTriggerTiming, updatedAt: string } | null } };


export const CreateGpsMissionDocument = `
    mutation CreateGpsMission($params: CreateGpsMissionInput!) {
  createGpsMission(params: $params) {
    ...BaseMissionFragment
    ...GpsMissionFieldsFragment
    position
    draft
  }
}
    ${BaseMissionFragmentFragmentDoc}
${GpsMissionFieldsFragmentFragmentDoc}`;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    CreateGpsMission: build.mutation<CreateGpsMissionMutation, CreateGpsMissionMutationVariables>({
      query: (variables) => ({ document: CreateGpsMissionDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateGpsMissionMutation } = injectedRtkApi;

