import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authReducer } from './auth';
import { preferencesReducer } from './preferences';
import { missionsReducer } from './missions';
import { rootApi } from 'data/api';
import { authMiddleware, rehydrateAuthState } from './auth/auth.slice';
import { onboardingSlice } from './onboarding/onboarding.slice';
import { preferencesMiddleware, rehydratePreferencesState } from './preferences/preferences.slice';
import { experiencesReducer } from './experiences';
import { experiencesMiddleware, rehydrateExperiencesState } from './experiences/experiences.slice';
import { teamsReducer } from './teams';
import { submissionsReducer } from './submissions';
import { leaderboardReducer } from './leaderboard';
import { broadcastsReducer } from './broadcasts';
import { workspacesReducer } from './workspaces';

export const dataStore = configureStore({
  reducer: {
    auth: authReducer,
    broadcasts: broadcastsReducer,
    onboarding: onboardingSlice.reducer,
    preferences: preferencesReducer,
    missions: missionsReducer,
    experiences: experiencesReducer,
    teams: teamsReducer,
    submissions: submissionsReducer,
    leaderboard: leaderboardReducer,
    workspaces: workspacesReducer,
    [rootApi.reducerPath]: rootApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      rootApi.middleware,
      authMiddleware.middleware,
      preferencesMiddleware.middleware,
      experiencesMiddleware.middleware,
    ),
  preloadedState: {
    auth: rehydrateAuthState(),
    preferences: rehydratePreferencesState(),
    experiences: rehydrateExperiencesState(),
  },
});

// Needed for some RTK query functionality
// src: https://redux-toolkit.js.org/rtk-query/overview#configure-the-store
setupListeners(dataStore.dispatch);

export type AppDispatch = typeof dataStore.dispatch;
export type AppState = ReturnType<typeof dataStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
