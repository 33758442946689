import { Button, Modal, Text, Headline } from '@goosechase/ui';
import { useActiveCohortId } from 'data/experiences';
import {
  type SubmissionArchive,
  SubmissionArchiveGroupingInput,
  SubmissionArchiveState,
} from 'data/models';
import {
  useCohortSubmissionArchivesQuery,
  useCreateSubmissionArchiveMutation,
  useSubmissionArchiveQuery,
} from 'data/submissions';
import { useEffect, useState } from 'react';
import { displayToast } from 'services/toast';
import { useTranslate } from 'util/i18n';
import { DownloadSubmissionsProgressBar } from './download-submissions-progress-bar.component';
import { GroupSubmissionsDropdown } from './group-submissions-dropdown.component';
import { SubmissionArchivesList } from './submission-archives-list.component';

export const DownloadSubmissions = () => {
  const [show, setShow] = useState(false);
  const [currentSubmissionArchive, setCurrentSubmissionArchive] =
    useState<Partial<SubmissionArchive>>();
  const { t } = useTranslate('downloadSubmissions');
  const [groupBy, setGroupBy] = useState<SubmissionArchiveGroupingInput>(
    SubmissionArchiveGroupingInput.ByTeam,
  );
  const [createSubmissionArchive, createSubmissionArchiveMutation] =
    useCreateSubmissionArchiveMutation();
  const activeCohortId = useActiveCohortId();
  const { data, refetch } = useCohortSubmissionArchivesQuery(
    { cohortId: activeCohortId ?? '', state: SubmissionArchiveState.Available },
    { skip: !activeCohortId },
  );
  const submissionArchiveQuery = useSubmissionArchiveQuery(
    { submissionArchiveId: createSubmissionArchiveMutation.data?.createSubmissionArchive.id ?? '' },
    {
      skip: !createSubmissionArchiveMutation.data?.createSubmissionArchive.id,
      pollingInterval: createSubmissionArchiveMutation.data?.createSubmissionArchive.id ? 1000 : 0,
    },
  );

  const handleChange = (value: SubmissionArchiveGroupingInput) => {
    setGroupBy(value);
    setCurrentSubmissionArchive(undefined);
  };

  const handleClose = () => {
    setShow(false);
    setCurrentSubmissionArchive(undefined);
  };

  const handleOpen = () => {
    setShow(true);
  };

  const handleCancel = () => {
    setCurrentSubmissionArchive(undefined);
  };

  const handleSubmit = async () => {
    try {
      if (activeCohortId) {
        const payload = await createSubmissionArchive({
          params: { cohortId: activeCohortId, grouping: groupBy },
        }).unwrap();
        setCurrentSubmissionArchive(payload.createSubmissionArchive);
      }
    } catch {
      displayToast({
        body: t('modal.toasts.error.body'),
        id: activeCohortId ?? '',
        title: t('modal.toasts.error.title'),
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (
      currentSubmissionArchive &&
      submissionArchiveQuery.data?.submissionArchive.state === 'AVAILABLE'
    ) {
      setCurrentSubmissionArchive(submissionArchiveQuery.data.submissionArchive);
      createSubmissionArchiveMutation.reset();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refetch();
    } else if (submissionArchiveQuery.data?.submissionArchive.state === 'FAILED') {
      setCurrentSubmissionArchive(undefined);
      createSubmissionArchiveMutation.reset();
      displayToast({
        body: t('modal.toasts.error.body'),
        id: activeCohortId ?? '',
        title: t('modal.toasts.error.title'),
        type: 'error',
      });
    } else if (currentSubmissionArchive && !submissionArchiveQuery.isUninitialized) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionArchiveQuery.data?.submissionArchive.state]);

  const isProcessing =
    Boolean(currentSubmissionArchive) &&
    (submissionArchiveQuery.data?.submissionArchive.state === SubmissionArchiveState.Requested ||
      submissionArchiveQuery.data?.submissionArchive.state === SubmissionArchiveState.Processing);

  const isAvailable = currentSubmissionArchive?.state === SubmissionArchiveState.Available;
  return (
    <>
      <Button label={t('downloadButton')} leftIcon="Save" onClick={handleOpen} />
      <Modal
        show={show}
        onClose={handleClose}
        className="w-full tablet-narrow:w-[498px] tablet-wide:w-[600px] desktop:w-[709px] overflow-visible"
        overlayClassName="p-4">
        <Modal.Header title={t('modal.title') ?? undefined} />
        <Modal.Body className="py-5">
          <Text className="mb-3">{t('modal.label')}</Text>
          <GroupSubmissionsDropdown onChange={handleChange} value={groupBy} />
          <DownloadSubmissionsProgressBar isAvailable={isAvailable} isProcessing={isProcessing} />
          <div className="flex justify-end pt-8">
            {isAvailable ? (
              <a href={currentSubmissionArchive?.url ?? ''} target="_blank" rel="noreferrer">
                <Button label={t('downloadButton')} leftIcon="Save" />
              </a>
            ) : null}
            {!currentSubmissionArchive ? (
              <Button label={t('modal.startDownload')} onClick={handleSubmit} />
            ) : null}
            {isProcessing ? (
              <Button
                label={t('modal.cancelDownload')}
                onClick={handleCancel}
                buttonType="DESTRUCTIVE"
              />
            ) : null}
          </div>
        </Modal.Body>
        {data?.cohortSubmissionArchives.length ? (
          <Modal.Footer className="justify-start flex-col gap-0">
            <Headline type="secondary" size="sm" className="mb-4">
              {t('modal.previousDownloads')}
            </Headline>
            <SubmissionArchivesList
              submissionArchives={data.cohortSubmissionArchives}
              currentSubmissionArchiveId={currentSubmissionArchive?.id}
            />
          </Modal.Footer>
        ) : null}
      </Modal>
    </>
  );
};
