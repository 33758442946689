import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

import type { MissionCardProps } from '../mission-card';
import type {
  CameraMission,
  GpsMission,
  MediaType,
  FeedVisibility,
  TextMission,
  PublicCameraMission,
  PublicGpsMission,
  PublicTextMission,
} from '../../data/models';

import { answersAreEligibleForApproximation } from '../../util/mission-util';

const getInfoTitle = (t: TFunction, missionType: MissionTypeName): string | undefined => {
  if (missionType === 'PublicTextMission' || missionType === 'TextMission') {
    return t('acceptedAnswers') ?? undefined;
  } else if (missionType === 'PublicGpsMission' || missionType === 'GpsMission') {
    return t('acceptedLocation') ?? undefined;
  }
  return undefined;
};

const getTextMissionInfoTags = (t: TFunction, answers: string[]): MissionCardProps['infoTags'] => {
  if (answers.length === 0) {
    return [{ label: t('infoTags.allAnswersAccepted') }];
  } else {
    return answers.map((answer) => ({ label: answer }));
  }
};

const getGpsMissionInfoTags = (
  t: TFunction,
  locationName: GpsMission['locationName'],
  latitude: GpsMission['latitude'],
  longitude: GpsMission['longitude'],
): MissionCardProps['infoTags'] => {
  if (locationName) {
    return [{ label: locationName, icon: 'GPS' }];
  }

  return [{ label: `${latitude},${longitude}`, icon: 'GPS' }];
};

const MEDIA_TYPE_I18N_KEY: Record<MediaType, string> = {
  PHOTO: 'secondaryInfoTags.mediaType.photo',
  VIDEO: 'secondaryInfoTags.mediaType.video',
  PHOTO_AND_VIDEO: 'secondaryInfoTags.mediaType.photoAndVideo',
};

const getCameraMissionSecondaryInfoTags = (
  t: TFunction,
  mediaType: CameraMission['mediaType'],
  feedVisibility: FeedVisibility,
  allowCameraRollUploads: CameraMission['allowCameraRollUploads'],
) => {
  /* Defaults:
    - Photos and Videos
    - Shown in Feed
    - Live Capture and Library
  */
  const result: MissionCardProps['secondaryInfoTags'] = [];
  if (mediaType === 'VIDEO') {
    result.push({
      label: t('secondaryInfoTags.videoOnly.label'),
      icon: 'VideoCam',
      tooltip: t('secondaryInfoTags.videoOnly.tooltip'),
    });
  } else if (mediaType === 'PHOTO') {
    result.push({
      label: t('secondaryInfoTags.photoOnly.label'),
      icon: 'VideoCam',
      tooltip: t('secondaryInfoTags.photoOnly.tooltip'),
    });
  }
  if (feedVisibility === 'ALWAYS_PRIVATE') {
    result.push({
      label: t('secondaryInfoTags.feedVisibilityChanged.hiddenInFeedLabel'),
      icon: 'HiddenEye',
      tooltip: (
        <Trans
          t={t}
          i18nKey="secondaryInfoTags.feedVisibilityChanged.tooltip"
          values={{
            type: t(MEDIA_TYPE_I18N_KEY[mediaType]),
            default: t('secondaryInfoTags.feedVisibilityChanged.shown'),
            override: t('secondaryInfoTags.feedVisibilityChanged.hidden'),
          }}
          components={{ i: <i /> }}
        />
      ),
    });
  }
  if (!allowCameraRollUploads) {
    result.push({
      label: t('secondaryInfoTags.liveCaptureOnly.label'),
      icon: 'SmartphoneCapture',
      tooltip: (
        <Trans
          t={t}
          i18nKey="secondaryInfoTags.liveCaptureOnly.tooltip"
          values={{ mediaType: t(MEDIA_TYPE_I18N_KEY[mediaType]) }}
          components={{ i: <i /> }}
        />
      ),
    });
  }

  return result;
};

const getTextMissionSecondaryInfoTags = (
  t: TFunction,
  feedVisibility: FeedVisibility,
  answers: string[],
  allowApproximateAnswers: boolean,
) => {
  /* Defaults:
    - Hidden in Feed
  */
  const result: MissionCardProps['secondaryInfoTags'] = [];
  if (answersAreEligibleForApproximation(answers)) {
    const subKey = allowApproximateAnswers ? 'approximate' : 'exact';
    result.push({
      label: t(`secondaryInfoTags.approximateAnswers.${subKey}`),
      tooltip: t(`secondaryInfoTags.approximateAnswers.${subKey}Tooltip`),
    });
  }
  if (feedVisibility === 'ALWAYS_PUBLIC') {
    result.push({
      label: t('secondaryInfoTags.feedVisibilityChanged.shownInFeedLabel'),
      icon: 'ShownEye',
      tooltip: (
        <Trans
          t={t}
          i18nKey="secondaryInfoTags.feedVisibilityChanged.tooltip"
          values={{
            type: t('secondaryInfoTags.missionType.text'),
            default: t('secondaryInfoTags.feedVisibilityChanged.hidden'),
            override: t('secondaryInfoTags.feedVisibilityChanged.shown'),
          }}
          components={{ i: <i /> }}
        />
      ),
    });
  }
  return result;
};

const getGpsMissionSecondaryInfoTags = (t: TFunction, feedVisibility: FeedVisibility) => {
  /* Defaults:
    - Hidden in Feed
  */
  const result: MissionCardProps['secondaryInfoTags'] = [];
  if (feedVisibility === 'ALWAYS_PUBLIC') {
    result.push({
      label: t('secondaryInfoTags.feedVisibilityChanged.shownInFeedLabel'),
      icon: 'ShownEye',
      tooltip: (
        <Trans
          t={t}
          i18nKey="secondaryInfoTags.feedVisibilityChanged.tooltip"
          values={{
            type: t('secondaryInfoTags.missionType.gps'),
            default: t('secondaryInfoTags.feedVisibilityChanged.hidden'),
            override: t('secondaryInfoTags.feedVisibilityChanged.shown'),
          }}
          components={{ i: <i /> }}
        />
      ),
    });
  }
  return result;
};

interface AdditionalMissionInfo {
  infoTitle?: string;
  infoTags: MissionCardProps['infoTags'];
  secondaryInfoTags: MissionCardProps['secondaryInfoTags'];
}

type MissionTypeName =
  | CameraMission['__typename']
  | PublicCameraMission['__typename']
  | TextMission['__typename']
  | PublicTextMission['__typename']
  | GpsMission['__typename']
  | PublicGpsMission['__typename'];

export type InformationTagsMission =
  | {
      __typename: CameraMission['__typename'] | PublicCameraMission['__typename'];
      mediaType: CameraMission['mediaType'];
      feedVisibility: CameraMission['feedVisibility'];
      allowCameraRollUploads: CameraMission['allowCameraRollUploads'];
      description: string;
    }
  | {
      __typename: TextMission['__typename'] | PublicTextMission['__typename'];
      answers: TextMission['answers'];
      feedVisibility: TextMission['feedVisibility'];
      allowApproximateAnswers: TextMission['allowApproximateAnswers'];
      description: string;
    }
  | {
      __typename: GpsMission['__typename'] | PublicGpsMission['__typename'];
      locationName: GpsMission['locationName'];
      latitude: GpsMission['latitude'];
      longitude: GpsMission['longitude'];
      feedVisibility: GpsMission['feedVisibility'];
      description: string;
    };

export const getAdditionalMissionInfo = (
  t: TFunction,
  mission: InformationTagsMission,
): AdditionalMissionInfo => {
  const infoTitle = getInfoTitle(t, mission.__typename);

  if (mission.__typename === 'CameraMission' || mission.__typename === 'PublicCameraMission') {
    return {
      infoTitle,
      infoTags: [],
      secondaryInfoTags: getCameraMissionSecondaryInfoTags(
        t,
        mission.mediaType,
        mission.feedVisibility,
        mission.allowCameraRollUploads,
      ),
    };
  } else if (mission.__typename === 'TextMission' || mission.__typename === 'PublicTextMission') {
    return {
      infoTitle,
      infoTags: getTextMissionInfoTags(t, mission.answers),
      secondaryInfoTags: getTextMissionSecondaryInfoTags(
        t,
        mission.feedVisibility,
        mission.answers,
        mission.allowApproximateAnswers,
      ),
    };
  } else if (mission.__typename === 'GpsMission' || mission.__typename === 'PublicGpsMission') {
    // Only GPS mission type left
    return {
      infoTitle,
      infoTags: getGpsMissionInfoTags(t, mission.locationName, mission.latitude, mission.longitude),
      secondaryInfoTags: getGpsMissionSecondaryInfoTags(t, mission.feedVisibility),
    };
  }

  throw new Error('Mission type name not found');
};
