/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type ExperienceManagersQueryVariables = Types.Exact<{
  experienceId: Types.Scalars['ID']['input'];
}>;


export type ExperienceManagersQuery = { __typename: 'Query', experienceManagers: Array<{ __typename: 'ExperienceManager', user: { __typename: 'User', id: string, profile: { __typename: 'UserProfile', email: string, photoUrl: string | null } | null }, role: { __typename: 'ExperienceManagerRole', id: string } }> };


export const ExperienceManagersDocument = `
    query ExperienceManagers($experienceId: ID!) {
  experienceManagers(experienceId: $experienceId) {
    user {
      id
      profile {
        email
        photoUrl
      }
    }
    role {
      id
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ExperienceManagers: build.query<ExperienceManagersQuery, ExperienceManagersQueryVariables>({
      query: (variables) => ({ document: ExperienceManagersDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useExperienceManagersQuery, useLazyExperienceManagersQuery } = injectedRtkApi;

