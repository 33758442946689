import { useDispatch } from 'react-redux';
import { SidePanel } from '../../components/side-panel';
import {
  closeAndResetMissionPanel,
  useInitialMissionType,
  useMissionPanelOpen,
  useSelectedMission,
} from '../../data/missions';
import { CreateMissionForm } from './create-mission-form';
import { EditMissionForm } from './edit-mission-form';

export const MissionSidePanel = () => {
  const open = useMissionPanelOpen();
  const initialMissionType = useInitialMissionType();
  const selectedMission = useSelectedMission();
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeAndResetMissionPanel());
  };

  return (
    <SidePanel visible={open} onClickBackdrop={handleClose}>
      {open && (
        <>
          {selectedMission !== null ? (
            <EditMissionForm
              initialMission={selectedMission}
              onCancel={handleClose}
              onSubmitSuccess={handleClose}
            />
          ) : (
            <CreateMissionForm
              initialType={initialMissionType}
              onCancel={handleClose}
              onSubmitSuccess={handleClose}
            />
          )}
        </>
      )}
    </SidePanel>
  );
};
