import { Button, Modal } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { Trans } from 'react-i18next';

interface SaveConfirmationModalProps {
  show: boolean;
  header?: string;
  body: string;
  buttonText?: string;
  onClose: () => void;
  onSubmit: () => void;
}

export const SaveConfirmationModal = ({
  show,
  header,
  body,
  buttonText,
  onClose,
  onSubmit,
}: SaveConfirmationModalProps) => {
  const { t } = useTranslate('broadcastForm.saveConfirmationModal');

  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[498px] desktop:w-[766px]"
      show={show}
      onClose={onClose}>
      {header ? <Modal.Header title={header} /> : null}
      <Modal.Body>
        <Trans>{body}</Trans>
      </Modal.Body>
      <Modal.Footer>
        <Button label={buttonText ?? t('confirm')} onClick={onSubmit} />
        <Button label={t('cancel')} onClick={onClose} outlined />
      </Modal.Footer>
    </Modal>
  );
};
