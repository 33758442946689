import { type SubmissionFocusedViewProps } from '../../components/submission-focused-view';
import type { PublicSubmissionQuery } from '../../data/submissions';

export const convertPublicSubmissionToComponentProps = (
  submission: NonNullable<PublicSubmissionQuery['publicSubmission']>,
): SubmissionFocusedViewProps['submission'] => {
  const commonFields = {
    missionName: submission.mission.displayName,
    missionPoints: submission.mission.points,
    bonusPoints: submission.bonusPoints,
    submissionId: submission.id,
    isLiked: submission.liked,
    numLikes: submission.numLikes,
    teamId: submission.team.id,
    teamMode: submission.team.mode,
    teamName: submission.team.displayName,
    submissionDate: new Date(submission.createdAt),
    submissionBonuses: submission.bonuses.map((bonus) => ({
      ...bonus,
      bonusId: bonus.id,
      createdAt: new Date(bonus.createdAt),
    })),
    missionDescription: submission.mission.description,
    feedVisibility: submission.mission.feedVisibility,
    informationTagsMission: submission.mission,
  };

  if (submission.__typename === 'PublicCameraSubmission') {
    return {
      ...commonFields,
      missionType: 'CameraMission',
      submissionImageSrc: submission.assets.find((asset) => asset.role === 'PHOTO')?.url,
      submissionVideoSrc: submission.assets.find((asset) => asset.role === 'VIDEO')?.url,
      submissionCaption: submission.caption ?? undefined,
    };
  } else if (submission.__typename === 'PublicTextSubmission') {
    return { ...commonFields, missionType: 'TextMission', submissionAnswer: submission.answer };
  } else {
    return {
      ...commonFields,
      missionType: 'GpsMission',
      submissionCaption: submission.caption ?? undefined,
      submissionLatitude: submission.latitude,
      submissionLongitude: submission.longitude,
    };
  }
};
