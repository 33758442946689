import { BroadcastsQuery } from 'data/broadcasts';
import { MissionTriggersQuery } from 'data/missions';
import { BroadcastTrigger, MissionTaskType } from 'data/models';
import { MissionTrigger } from 'util/time-util';

export const convertGqlMissionTriggersToUIMissionTriggers = (
  missionTriggersData?: MissionTriggersQuery,
): MissionTrigger[] => {
  if (!missionTriggersData) {
    return [];
  }
  return missionTriggersData?.experienceMissions.flatMap((mission) => {
    const triggers: MissionTrigger[] = [];
    if (mission.releaseTrigger) {
      triggers.push({
        status: mission.status,
        type: MissionTaskType.ReleaseMission,
        triggerAt: mission.releaseAt ? new Date(mission.releaseAt) : null,
        draft: mission.draft,
        ...mission.releaseTrigger,
      });
    }
    if (mission.expireTrigger) {
      triggers.push({
        status: mission.status,
        type: MissionTaskType.ExpireMission,
        triggerAt: mission.expireAt ? new Date(mission.expireAt) : null,
        draft: mission.draft,
        ...mission.expireTrigger,
      });
    }
    return triggers;
  });
};

export const convertGqlBroadcastsToUIBroadcastTriggers = (
  data?: BroadcastsQuery,
): BroadcastTrigger[] => {
  if (data?.cohort?.broadcasts) {
    return data.cohort.broadcasts.flatMap((broadcast) => {
      const triggers = [];
      if (broadcast.trigger) {
        triggers.push(broadcast.trigger);
      }
      return triggers;
    });
  } else {
    return [];
  }
};
