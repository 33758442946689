import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useTranslate } from '../../util/i18n';
import { TeamMode, TEAM_MODES } from '../../data/models';
import { Nullable } from '../../types/util';
import { transformEmptyInputToNull } from '../../util/form-util';

export interface TeamFormInputData {
  id: string;
  displayName: string;
  passcode: string;
  mode: TeamMode;
  assetId: string;
  maxSize: Nullable<number>;
}

export type TeamFormOutputData = {
  id: string;
  displayName: string;
  passcode: string;
  mode: TeamMode;
  assetId: Nullable<string>;
  maxSize: Nullable<number>;
};

export interface UseTeamFormArgs {
  defaultValues: Partial<TeamFormInputData>;
  numParticipants: number;
}

export const useTeamForm = ({ defaultValues, numParticipants }: UseTeamFormArgs) => {
  const { t } = useTranslate('pages.participants.teamForm');

  const teamFormSchema = z
    .object({
      id: z.string().optional(),
      displayName: z.string().min(1, { message: t('displayName.errors.required') ?? undefined }),
      passcode: z.string().transform(transformEmptyInputToNull),
      mode: z.enum(TEAM_MODES),
      assetId: z.string().optional().transform(transformEmptyInputToNull),
      maxSize: z
        .number()
        .gte(numParticipants, {
          message: t('maxSize.errors.numParticipants', { count: numParticipants }) ?? undefined,
        })
        .nullable(),
    })
    .superRefine((values, context) => {
      if (values.mode === TeamMode.Solo && numParticipants > 1) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('maxSize.errors.mode', { count: numParticipants }) ?? undefined,
          path: ['mode'],
        });
      }
    });

  return useForm<TeamFormInputData, unknown, TeamFormOutputData>({
    mode: 'all',
    defaultValues: {
      id: defaultValues.id ?? undefined,
      displayName: defaultValues.displayName,
      passcode: defaultValues.passcode ?? '',
      maxSize: defaultValues.maxSize,
      mode: defaultValues.mode ?? TeamMode.Team,
      assetId: defaultValues.assetId,
    },
    resolver: zodResolver(teamFormSchema),
  });
};
