import { Menu } from '@headlessui/react';
import { Icon, IconName, mergeClasses, Text } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
interface DropdownOptionProps {
  className?: string;
  label: string;
  icon: IconName;
  isDestructive?: boolean;
  onClick: () => void;
}

const DropdownOption = ({
  className,
  label,
  icon,
  isDestructive = false,
  onClick,
}: DropdownOptionProps) => {
  return (
    <Menu.Item>
      {({ close }) => {
        const handleOnClick: React.HTMLAttributes<HTMLButtonElement>['onClick'] = () => {
          onClick();
          close();
        };

        return (
          <button
            className={mergeClasses(
              'w-full flex flex-row gap-2 items-center px-3 py-1 outline-0 hover:bg-black-4 ui-active:bg-black-4',
              className,
            )}
            onClick={handleOnClick}>
            <Icon color={isDestructive ? 'vibrantRed' : 'black'} name={icon} size={16} />
            <Text
              size="md"
              className={mergeClasses('whitespace-nowrap', {
                'text-vibrantRed': isDestructive,
              })}>
              {label}
            </Text>
          </button>
        );
      }}
    </Menu.Item>
  );
};

export interface SubmissionCardDropdownProps {
  onCopy: () => void;
  onDownload?: () => void;
  onDelete: () => void;
  onBonus: () => void;
}

export const SubmissionCardDropdown = ({
  onCopy,
  onDownload,
  onDelete,
  onBonus,
}: SubmissionCardDropdownProps) => {
  const { t } = useTranslate('submissionCard.dropdown');

  return (
    <Menu as="div" className="relative">
      <Menu.Button
        aria-label={t('ariaIconLabel') ?? undefined}
        className="flex justify-center items-center shrink-0 rounded-full hover:bg-black-12 outline-black outline-2 outline-offset-4 focus:outline ui-open:outline">
        <Icon name="More" color="black" />
      </Menu.Button>
      <Menu.Items className="absolute top-full right-0 cursor-pointer bg-white border border-black-24 rounded-lg outline-0 overflow-hidden z-10">
        <DropdownOption
          className="pt-3"
          label={t('dropdownOptions.bonus')}
          icon="AwardedPoints"
          onClick={onBonus}
        />
        <DropdownOption label={t('dropdownOptions.copy')} icon="CopyLink" onClick={onCopy} />
        {onDownload && (
          <DropdownOption label={t('dropdownOptions.download')} icon="Save" onClick={onDownload} />
        )}
        <DropdownOption
          className="pb-3"
          isDestructive
          label={t('dropdownOptions.delete')}
          icon="Delete"
          onClick={onDelete}
        />
      </Menu.Items>
    </Menu>
  );
};
