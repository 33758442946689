import { Navigate, Routes, Route } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { LoginPage } from 'pages/login';
import { SignUpPage } from 'pages/sign-up';
import { EducatorInfoPage, OnboardingPage, UseCaseSelectionPage } from 'pages/onboarding';
import { AppRoutes } from 'constants/routes';
import { getConfig } from 'constants/config';
import { ExperiencesPageVariant } from 'pages/experiences/experiences.page';
import { CreateExperiencePage } from 'pages/create-experience/create-experience.page';
import { UpdateExperiencePage } from 'pages/update-experience/update-experience.page';
import { AccountInfoPage } from 'pages/account-info';
import { AuthGate } from 'components/auth-gate.component';
import { MainLayout } from 'components/main-layout.component';
import { ExperienceOutlet, ExperienceLayout } from './components/experience-layout';
import { FallbackHelmet } from 'components/helmet-fallback.component';
import { ChangePasswordPage } from 'pages/change-password';
import { LogoutPage } from 'pages/logout';
import { MissionsPage } from 'pages/missions';
import { PlansPageVariant } from './pages/plans';
import { SentryIdentifier } from './components/sentry-identifier';
import { BroadcastsPage } from './pages/broadcasts';
import { BrandingPage } from './pages/branding';
import { ParticipantsPage } from './pages/participants';
import { StartEndPage } from './pages/start-end';
import { ActivityFeedPage } from './pages/activity-feed';
import { LeaderboardPage } from './pages/leaderboard';
import { SubmissionsPage } from './pages/submissions';
import { StatsPage } from './pages/stats';
import { MissionSubmissionsPage } from './pages/mission-submissions';
import { TeamSubmissionsPage } from './pages/team-submissions';
import { PublicSubmissionPage } from './pages/public-submission';
import { WorkspacePage, NewWorkspacePage } from './pages/workspace';
import { JoinWorkspacePage } from 'pages/join-workspace';
import { JoinExperiencePage } from 'pages/join-experience';
import { ForgotPasswordDonePage, ForgotPasswordPage } from './pages/forgot-password';
import { ResetPasswordDonePage, ResetPasswordPage } from './pages/reset-password';
import { SharedExperiencePage } from 'pages/share-experience';
import { ImpersonationWrapper } from './components/impersonation-wrapper.component';
import { usePageView } from 'hooks/use-page-view';

const RootRouter = () => {
  return (
    <Routes>
      <Route path={AppRoutes.LOGIN} element={<LoginPage />} />
      <Route path={AppRoutes.SIGNUP} element={<SignUpPage />} />
      <Route path={AppRoutes.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
      <Route path={AppRoutes.FORGOT_PASSWORD_DONE} element={<ForgotPasswordDonePage />} />
      <Route path={AppRoutes.RESET_PASSWORD} element={<ResetPasswordPage />} />
      <Route path={AppRoutes.RESET_PASSWORD_DONE} element={<ResetPasswordDonePage />} />
      <Route element={<AuthGate />}>
        <Route element={<OnboardingPage />}>
          <Route path={AppRoutes.USE_CASE_SELECTION} element={<UseCaseSelectionPage />} />
          <Route path={AppRoutes.EDUCATOR_INFO} element={<EducatorInfoPage />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route
            path={AppRoutes.HOME}
            element={<Navigate to={AppRoutes.MY_EXPERIENCES} replace />}
          />
          <Route path={AppRoutes.MY_EXPERIENCES} element={<ExperiencesPageVariant />} />
          <Route path={AppRoutes.CREATE_EXPERIENCE} element={<CreateExperiencePage />} />
          <Route path={AppRoutes.ACCOUNT_INFO} element={<AccountInfoPage />} />
          <Route path={AppRoutes.CHANGE_PASSWORD} element={<ChangePasswordPage />} />
          <Route path={AppRoutes.PLANS} element={<PlansPageVariant />} />
          <Route path={AppRoutes.WORKSPACE} element={<WorkspacePage />} />
          <Route path={AppRoutes.NEW_WORKSPACE} element={<NewWorkspacePage />} />
          {/* Fallback to My Experiences for empty or non-matching paths */}
          <Route path="" element={<Navigate to={AppRoutes.MY_EXPERIENCES} replace />} />
          <Route path="*" element={<Navigate to={AppRoutes.MY_EXPERIENCES} replace />} />
        </Route>
        <Route path={AppRoutes.EXPERIENCE_DETAILS} element={<ExperienceOutlet />}>
          <Route path={AppRoutes.EXPERIENCE_DETAILS} element={<ExperienceLayout />}>
            <Route
              path={`${AppRoutes.EXPERIENCE_DETAILS}/${AppRoutes.SUB_PATHS.EDIT}`}
              element={<UpdateExperiencePage />}
            />
            <Route
              path={`${AppRoutes.EXPERIENCE_DETAILS}/${AppRoutes.SUB_PATHS.MISSIONS}`}
              element={<MissionsPage />}
            />
            <Route
              path={`${AppRoutes.EXPERIENCE_DETAILS}/${AppRoutes.SUB_PATHS.BROADCASTS}`}
              element={<BroadcastsPage />}
            />
            <Route
              path={`${AppRoutes.EXPERIENCE_DETAILS}/${AppRoutes.SUB_PATHS.BRANDING}`}
              element={<BrandingPage />}
            />
            <Route
              path={`${AppRoutes.EXPERIENCE_DETAILS}/${AppRoutes.SUB_PATHS.PARTICIPANTS}`}
              element={<ParticipantsPage />}
            />
            <Route
              path={`${AppRoutes.EXPERIENCE_DETAILS}/${AppRoutes.SUB_PATHS.START_END}`}
              element={<StartEndPage />}
            />
            <Route
              path={`${AppRoutes.EXPERIENCE_DETAILS}/${AppRoutes.SUB_PATHS.FEED}`}
              element={<ActivityFeedPage />}
            />
            <Route
              path={`${AppRoutes.EXPERIENCE_DETAILS}/${AppRoutes.SUB_PATHS.LEADERBOARD}`}
              element={<LeaderboardPage />}
            />
            <Route path={`${AppRoutes.EXPERIENCE_DETAILS}/${AppRoutes.SUB_PATHS.SUBMISSIONS}`}>
              <Route
                path={`${AppRoutes.EXPERIENCE_DETAILS}/${AppRoutes.SUB_PATHS.MISSION_SUBMISSIONS}/:missionId`}
                element={<MissionSubmissionsPage />}
              />
              <Route
                path={`${AppRoutes.EXPERIENCE_DETAILS}/${AppRoutes.SUB_PATHS.TEAM_SUBMISSIONS}/:teamId`}
                element={<TeamSubmissionsPage />}
              />
              <Route path="" element={<SubmissionsPage />} />
            </Route>
            <Route
              path={`${AppRoutes.EXPERIENCE_DETAILS}/${AppRoutes.SUB_PATHS.STATS}`}
              element={<StatsPage />}
            />
            {/* Fallback to update experience for empty or non-matching paths */}
            <Route path="" element={<Navigate to={AppRoutes.SUB_PATHS.EDIT} replace />} />
            <Route path="*" element={<Navigate to={AppRoutes.SUB_PATHS.EDIT} replace />} />
          </Route>
        </Route>
      </Route>

      <Route element={<MainLayout />}>
        <Route
          path={`${AppRoutes.PUBLIC.SUBMISSION}/:submissionId`}
          element={<PublicSubmissionPage />}
        />
        <Route path={AppRoutes.JOIN_WORKSPACE} element={<JoinWorkspacePage />} />
        <Route path={AppRoutes.JOIN_EXPERIENCE} element={<JoinExperiencePage />} />
        <Route path={AppRoutes.SHARE_EXPERIENCE} element={<SharedExperiencePage />} />
      </Route>
      <Route path={AppRoutes.LOGOUT} element={<LogoutPage />} />
      {/* Catch non-matching paths below. Needs to be last in list. Navigates to HOME if logged in, otherwise redirects to LoginPage */}
      <Route path="*" element={<Navigate to={AppRoutes.HOME} replace />} />
    </Routes>
  );
};

const App = () => {
  usePageView();

  return (
    // text-black defaults all text colors to our custom
    // black while still allowing for simple overrides
    <div id="App" className="text-black bg-offWhite">
      <ImpersonationWrapper>
        <FallbackHelmet />
        <RootRouter />
        <SentryIdentifier />
      </ImpersonationWrapper>
    </div>
  );
};

export default withLDProvider({
  clientSideID: getConfig().LAUNCH_DARKLY_CLIENT_ID,
})(App);
