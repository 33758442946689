import { Text, Avatar, SecondaryHeadline } from '@goosechase/ui';
import { Link } from 'components/link.component';
import { AppRoutes } from 'constants/routes';
import { TeamMode } from 'data/models';
import { Nullable } from 'types/util';
import { useTranslate } from 'util/i18n';

interface LeaderboardItemProps {
  id: string;
  name: string;
  teamMode: TeamMode;
  photoUrl: Nullable<string>;
  points: number;
  rank: number;
}

export const LeaderboardItem = (props: LeaderboardItemProps) => {
  const { t } = useTranslate('pages.leaderboard.teams.item');

  return (
    <div className="flex flex-row py-3 px-4 ">
      <div className="mr-3">
        <Avatar
          size="xs2"
          placeholder={props.teamMode === 'TEAM' ? 'team-duo' : 'single'}
          src={props.photoUrl ?? undefined}
          alt={t('teamPicAlt', { teamName: props.name }) ?? undefined}
        />
      </div>
      <div className="flex justify-between flex-1">
        <div className="flex flex-col justify-center">
          <Link
            className="text-vibrantBlue hover:text-[#0013c1]"
            href={`${AppRoutes.SUB_PATHS.TEAM_SUBMISSIONS}/${props.id}`}>
            <Text className="cursor-pointer font-semibold hover:underline">{props.name}</Text>
          </Link>
          <Text size="sm">{t('points', { count: props.points })}</Text>
        </div>
        <div className="flex items-center">
          <SecondaryHeadline size="2xs">
            {t('rank', { count: props.rank, ordinal: true })}
          </SecondaryHeadline>
        </div>
      </div>
    </div>
  );
};
