import { AppState } from 'data/store';
import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { api as _experienceTeamsApi } from './graphql/__generated__/load-teams-for-experience.query.generated';
import { api as _createTeamApi } from './graphql/__generated__/create-team.mutation.generated';
import { api as _updateTeamApi } from './graphql/__generated__/update-team.mutation.generated';
import { api as _deleteTeamApi } from './graphql/__generated__/delete-team.mutation.generated';
import { api as _addTeamBonusApi } from './graphql/__generated__/add-team-bonus.mutation.generated';
import { api as _loadTeamApi } from './graphql/__generated__/load-team.query.generated';
import { api as _updateTeamBonusApi } from './graphql/__generated__/update-team-bonus.mutation.generated';
import { api as _deleteTeamBonusApi } from './graphql/__generated__/delete-team-bonus.mutation.generated';

export const experienceTeamsApi = _experienceTeamsApi.enhanceEndpoints({
  endpoints: {
    LoadTeamsForExperience: {
      providesTags: ['ExperienceTeams'],
    },
  },
});

export const createTeamApi = _createTeamApi.enhanceEndpoints({
  endpoints: {
    CreateTeam: {
      invalidatesTags: ['ExperienceTeams', 'Leaderboard'],
    },
  },
});

export const deleteTeamApi = _deleteTeamApi.enhanceEndpoints({
  endpoints: {
    DeleteTeam: {
      invalidatesTags: ['ExperienceTeams', 'Broadcasts', 'Leaderboard'],
    },
  },
});

export const updateTeamApi = _updateTeamApi.enhanceEndpoints({
  endpoints: {
    UpdateTeam: {
      invalidatesTags: ['ExperienceTeams', 'Leaderboard'],
    },
  },
});

export const addTeamBonusApi = _addTeamBonusApi.enhanceEndpoints({
  endpoints: {
    AddTeamBonus: {
      invalidatesTags: ['Leaderboard', 'Team'],
    },
  },
});

export const loadTeamApi = _loadTeamApi.enhanceEndpoints({
  endpoints: {
    LoadTeam: {
      providesTags: ['Team'],
    },
  },
});

export const updateTeamBonusApi = _updateTeamBonusApi.enhanceEndpoints({
  endpoints: {
    UpdateTeamBonus: {
      invalidatesTags: ['Leaderboard', 'Team'],
      async onQueryStarted(
        { params: { bonusId, value, note } },
        { dispatch, getState, queryFulfilled },
      ) {
        const selectedTeamId = (getState() as AppState).leaderboard.selectedTeamId;

        const patchCollection: PatchCollection[] = [];

        if (selectedTeamId) {
          const patchResult = dispatch(
            loadTeamApi.util.updateQueryData('LoadTeam', { teamId: selectedTeamId }, (draft) => {
              if (draft?.team) {
                return {
                  ...draft,
                  team: {
                    ...draft.team,
                    bonuses: draft.team.bonuses.map((bonus) => {
                      if (bonus.id === bonusId) {
                        return {
                          ...bonus,
                          value,
                          note: note ?? null,
                        };
                      }
                      return bonus;
                    }),
                  },
                };
              }
              return draft;
            }),
          );
          patchCollection.push(patchResult);
        }

        try {
          await queryFulfilled;
        } catch {
          patchCollection.forEach((patch) => patch.undo());
        }
      },
    },
  },
});

export const deleteTeamBonusApi = _deleteTeamBonusApi.enhanceEndpoints({
  endpoints: {
    DeleteTeamBonus: {
      invalidatesTags: ['Leaderboard', 'Team'],
      async onQueryStarted({ id }, { dispatch, getState, queryFulfilled }) {
        const selectedTeamId = (getState() as AppState).leaderboard.selectedTeamId;

        const patchCollection: PatchCollection[] = [];

        if (selectedTeamId) {
          const patchResult = dispatch(
            loadTeamApi.util.updateQueryData('LoadTeam', { teamId: selectedTeamId }, (draft) => {
              if (draft?.team) {
                return {
                  ...draft,
                  team: {
                    ...draft.team,
                    bonuses: draft.team.bonuses.filter((bonus) => bonus.id !== id),
                  },
                };
              }
              return draft;
            }),
          );
          patchCollection.push(patchResult);
        }

        try {
          await queryFulfilled;
        } catch {
          patchCollection.forEach((patch) => patch.undo());
        }
      },
    },
  },
});
