import { useMemo } from 'react';
import { mergeClasses } from '@goosechase/ui';
import {
  closeAndResetTeamModal,
  useLoadTeamsForExperienceQuery,
  useSelectedTeam,
} from '../../data/teams';
import { TeamListItem } from './team-list-item';
import { ReactComponent as HatchedEgg } from 'media/hatched-egg.svg';
import { useTranslate } from '../../util/i18n';
import { LoadingSpinner } from '../loading-spinner';
import { EmptyState } from '../empty-state';
import { EditTeamModal } from './edit-team-modal';
import { useDispatch } from 'react-redux';
import { UpgradeExperienceBanner } from './upgrade-experience-banner.component';

interface TeamListProps {
  experienceId: string;
  searchValue: string;
  shouldDisableIndividualMode?: boolean;
  showUpgradeExperienceAction?: boolean;
}

export const TeamList = ({
  experienceId,
  shouldDisableIndividualMode,
  showUpgradeExperienceAction,
  searchValue,
}: TeamListProps) => {
  const { data, isLoading } = useLoadTeamsForExperienceQuery({ experienceId });
  const { t } = useTranslate('pages.participants.teams');
  const dispatch = useDispatch();
  const selectedTeam = useSelectedTeam();

  const handleClose = () => {
    dispatch(closeAndResetTeamModal());
  };

  const cohort = data?.experience?.cohorts[0]; // For now we assume 1 cohort
  const plan = data?.experience?.workspace.subscription.plan;
  const experience = data?.experience;
  const teams = useMemo(() => cohort?.teams ?? [], [cohort?.teams]);
  const filteredTeams = useMemo(
    () =>
      teams.filter((team) =>
        team.displayName.toLowerCase().includes(searchValue.trim().toLowerCase()),
      ),
    [teams, searchValue],
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <UpgradeExperienceBanner
        maxParticipantsPerExperience={experience?.maxParticipantsPerExperience}
        maxTeamsPerExperience={experience?.maxTeamsPerExperience}
        numberOfParticipants={cohort?.numberOfParticipants}
        shouldDisableIndividualMode={shouldDisableIndividualMode}
        showUpgradeBanner={plan?.showUpgradeBanner}
        showUpgradeExperienceAction={showUpgradeExperienceAction}
      />
      {!(teams.length || isLoading) || !cohort || !filteredTeams.length ? (
        <div className="flex justify-center items-center flex-col">
          <EmptyState
            renderGraphic={() => <HatchedEgg width="323" className="mb-6" />}
            text={t(teams.length ? 'noResults.title' : 'emptyState.title')}
            subtext={t(teams.length ? 'noResults.body' : 'emptyState.body')}
          />
        </div>
      ) : (
        <div
          className={mergeClasses('rounded-lg bg-white divide-y divide-black-12', {
            'border border-black-12': filteredTeams.length,
          })}>
          {filteredTeams.map((team) => (
            <TeamListItem
              key={team.id}
              id={team.id}
              name={team.displayName}
              teamMaxSize={team.maxSize}
              numParticipants={team.participants.length}
              passcode={team.passcode}
              isPreCreated={team.isPreCreated}
              teamMode={team.mode}
              photoUrl={team.photoUrl}
            />
          ))}
        </div>
      )}

      {selectedTeam && (
        <EditTeamModal
          defaultValues={{
            ...selectedTeam,
            passcode: selectedTeam.passcode ?? '',
          }}
          numParticipants={selectedTeam.numParticipants}
          photoUrl={selectedTeam.photoUrl ?? undefined}
          onClose={handleClose}
          onSubmitSuccess={handleClose}
          shouldDisableIndividualMode={shouldDisableIndividualMode}
        />
      )}
    </>
  );
};
