import { Dropdown, Text } from '@goosechase/ui';
import { Controller, Control, useFormState } from 'react-hook-form';

import type { BroadcastFormInputData } from '../use-broadcast-form';
import { NumberInput } from '../../number-input';
import { useTranslate } from 'util/i18n';
import { BROADCAST_TRIGGER_TIME_UNITS } from '../../../data/models';
import { TIME_UNIT_TO_TRANSLATION_KEY_MAP, type BroadcastTriggerTimeUnit } from '../util';
import { SubfieldArrow } from 'components/subfield-arrow';
import { Trans } from 'react-i18next';

interface AfterExperienceTimingFieldsProps {
  control: Control<BroadcastFormInputData>;
  isInFuture: boolean;
}

export const AfterExperienceTimingFields = ({
  control,
  isInFuture,
}: AfterExperienceTimingFieldsProps) => {
  const { t } = useTranslate('broadcastForm.broadcastTrigger');
  const { errors } = useFormState({ control });

  const relativeUnitOptions = BROADCAST_TRIGGER_TIME_UNITS.map((timeUnit) => {
    return {
      id: timeUnit,
      title: t(`relativeUnit.${TIME_UNIT_TO_TRANSLATION_KEY_MAP[timeUnit]}`),
    };
  });

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex gap-2 items-center w-full">
        <SubfieldArrow />
        <Controller
          control={control}
          name={`afterExperienceDuration`}
          render={({ field: { name, value, onChange, onBlur }, fieldState: { error } }) => (
            <NumberInput
              label=""
              placeholder={t('relativeDuration.placeholder') ?? undefined}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              value={value ?? null}
              min={1}
              hideSpinButtons
              containerClassName="flex-1"
              error={Boolean(error) || Boolean(errors.relativeDuration?.message)}
            />
          )}
        />
        <Controller
          name={`afterExperienceUnit`}
          control={control}
          render={({ field: { onChange, value } }) => {
            const selectedDropdownOption = {
              id: value,
              title: t(
                `relativeUnit.${
                  TIME_UNIT_TO_TRANSLATION_KEY_MAP[value as BroadcastTriggerTimeUnit]
                }`,
              ),
            };

            return (
              <Dropdown
                buttonClassName="w-full"
                label={selectedDropdownOption.title}
                options={relativeUnitOptions}
                selectedOptionId={value ?? undefined}
                onSelect={(val: string) => onChange(val)}
                containerClassName="flex-1"
              />
            );
          }}
        />
      </div>
      <div className="font-soleil ml-12 text-paragraph-xs text-black-48">
        {t('afterExperience.hint')}
      </div>
      {isInFuture ? null : (
        <div className="bg-yellowOrange-10 border border-yellowOrange rounded-lg p-4">
          <Trans>{t('warnings.past')}</Trans>
        </div>
      )}
      {errors.afterExperienceDuration?.message || errors.afterExperienceUnit?.message ? (
        <div className="bg-vibrantRed-10 border border-vibrantRed rounded-lg p-4 ml-12">
          <Text size="md">
            <span
              dangerouslySetInnerHTML={{
                __html:
                  errors.afterExperienceDuration?.message ||
                  errors.afterExperienceUnit?.message ||
                  '',
              }}
            />
          </Text>
        </div>
      ) : null}
    </div>
  );
};
