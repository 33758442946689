import { Button, Headline, Text } from '@goosechase/ui';
import { Link } from 'components/link.component';
import { AppRoutes } from 'constants/routes';
import { ReactComponent as Subscriptions } from 'media/subscriptions.svg';
import { useTranslate } from 'util/i18n';

export const SubscriptionBanner = () => {
  const { t } = useTranslate('upgradeExperience.subscriptions');

  return (
    <div className="bg-white gap-6 tablet-narrow:gap-10 px-5 py-6 rounded-lg flex flex-col tablet-narrow:flex-row items-start tablet-narrow:items-center">
      <div className="max-w-[100px] w-full">
        <Subscriptions />
      </div>
      <div>
        <Headline type="secondary" size="sm" className="mb-2">
          {t('title')}
        </Headline>
        <Text>{t('description')}</Text>
      </div>
      <Link href={AppRoutes.PLANS} target="_blanl">
        <Button label={t('getStarted')} outlined />
      </Link>
    </div>
  );
};
