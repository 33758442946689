import { Menu } from '@headlessui/react';
import { Icon, Text, mergeClasses } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';

import { displayToast } from 'services/toast';
import { Trans } from 'react-i18next';

import { useDeleteSavedMissionMutation } from 'data/missions';

interface DropdownOptionProps {
  label: string;
  onClick: () => void;
  className?: string;
}

const DropdownOption = ({ label, onClick, className }: DropdownOptionProps) => {
  return (
    <Menu.Item>
      {({ close }) => {
        const handleOnClick: React.HTMLAttributes<HTMLButtonElement>['onClick'] = (e) => {
          e.preventDefault();
          onClick();
          close();
        };

        return (
          <button
            className={mergeClasses(
              'w-full outline-0 pt-2 pb-2 text-left hover:bg-black-4 ui-active:bg-black-4 flex justify-start items-center',
              className,
            )}
            onClick={handleOnClick}>
            <Text className="px-3 py-1 pl-2 whitespace-nowrap font-semibold">{label}</Text>
          </button>
        );
      }}
    </Menu.Item>
  );
};

export interface SavedMissionDropdownProps {
  missionId: string;
  displayName: string;
}

export const SavedMissionDropdown = ({ missionId, displayName }: SavedMissionDropdownProps) => {
  const { t } = useTranslate('missionLibrary');
  const [deleteSavedMission] = useDeleteSavedMissionMutation();

  const handleDelete = async () => {
    await deleteSavedMission({ missionId }).unwrap();
    displayToast({
      id: missionId,
      title: t('toasts.deleteSuccess.title'),
      type: 'success',
      body: (
        <Trans
          t={t}
          i18nKey="toasts.deleteSuccess.body"
          values={{
            displayName,
          }}
        />
      ),
    });
  };

  return (
    <Menu as="div" className="relative" onClick={(event) => event.stopPropagation()}>
      <Menu.Button
        aria-label={t('ariaIconLabel') ?? ''}
        className="group flex items-center justify-center rounded-full h-6 w-6 shrink-0 hover:bg-black-12">
        <Icon
          name="More"
          color="black"
          className="group-hover:fill-black group-hover:stroke-black"
          size={24}
        />
      </Menu.Button>
      <Menu.Items className="absolute top-6 right-0 cursor-pointer bg-white border border-black-24 rounded-lg outline-0 overflow-hidden z-10 text-vibrantRed">
        <DropdownOption label={t('remove')} onClick={handleDelete} />
      </Menu.Items>
    </Menu>
  );
};
