import { teamsSlice } from './teams.slice';
import {
  createTeamApi,
  deleteTeamApi,
  experienceTeamsApi,
  updateTeamApi,
  addTeamBonusApi,
  updateTeamBonusApi,
  loadTeamApi,
  deleteTeamBonusApi,
} from './teams.api';

export const { useCreateTeamMutation } = createTeamApi;
export const { useLoadTeamsForExperienceQuery } = experienceTeamsApi;
export const { useDeleteTeamMutation } = deleteTeamApi;
export const { useUpdateTeamMutation } = updateTeamApi;
export const { useAddTeamBonusMutation } = addTeamBonusApi;
export const { useLoadTeamQuery } = loadTeamApi;
export const { useUpdateTeamBonusMutation } = updateTeamBonusApi;
export const { useDeleteTeamBonusMutation } = deleteTeamBonusApi;

export const { selectTeam, closeAndResetTeamModal } = teamsSlice.actions;
export const teamsReducer = teamsSlice.reducer;
export * from './teams.hooks';
