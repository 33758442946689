import { Button, Modal } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';

interface EditEndConfirmationModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const EditEndConfirmationModal = ({
  show,
  onClose,
  onSubmit,
}: EditEndConfirmationModalProps) => {
  const { t } = useTranslate('pages.startEnd.editEndConfirmation');

  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[498px] desktop:w-[766px]"
      show={show}
      onClose={onClose}>
      <Modal.Header title={t('header') ?? undefined} />
      <Modal.Body>{t('body')}</Modal.Body>
      <Modal.Footer className="flex flex-col tablet-narrow:flex-row">
        <Button label={t('confirm')} onClick={onSubmit} />
        <Button label={t('cancel')} onClick={onClose} outlined />
      </Modal.Footer>
    </Modal>
  );
};
