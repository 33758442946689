import { Text } from '@goosechase/ui';
import { Control, useWatch } from 'react-hook-form';
import {
  useDisplayMissionTriggerHint,
  MissionTriggerHint,
} from '../mission-form/mission-trigger-hint.component';
import { MissionFormInputData } from '../mission-form/use-mission-form';
import { MissionTriggerTime, useMissionTriggerTime } from './mission-trigger-time.component';

interface MissionTriggerInfoProps {
  control: Control<MissionFormInputData>;
  triggerType: 'releaseTrigger' | 'expireTrigger';
}

export const MissionTriggerInfo = ({ control, triggerType }: MissionTriggerInfoProps) => {
  const timing = useWatch({ control, name: `${triggerType}.timing` });
  const relativeDuration = useWatch({ control, name: `${triggerType}.relativeDuration` });
  const relativeUnit = useWatch({ control, name: `${triggerType}.relativeUnit` });
  const relativeAnchor = useWatch({ control, name: `${triggerType}.relativeAnchor` });
  const specificDay = useWatch({ control, name: `${triggerType}.specificDay` });
  const specificTime = useWatch({ control, name: `${triggerType}.specificTime` });
  const displayMissionTriggerHint = useDisplayMissionTriggerHint({ control, triggerType });
  const displayMissionTriggerTime = useMissionTriggerTime({
    timing,
    relativeAnchor,
    relativeDuration,
    relativeUnit,
    specificDay,
    specificTime,
  });

  if (!displayMissionTriggerTime && displayMissionTriggerHint) {
    return (
      <div className="pl-12">
        <MissionTriggerHint />
      </div>
    );
  }

  if (displayMissionTriggerTime) {
    return (
      <Text size="sm" className="pl-12 text-right text-black-64">
        <MissionTriggerTime
          timing={timing}
          relativeAnchor={relativeAnchor}
          relativeDuration={relativeDuration}
          relativeUnit={relativeUnit}
          specificDay={specificDay}
          specificTime={specificTime}
        />
      </Text>
    );
  }

  return null;
};
