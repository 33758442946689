import { AppRoutes, EXPERIENCE_PATH } from '../../constants/routes';

import { useTranslate } from '../../util/i18n';
import { Breadcrumbs } from '../breadcrumbs.component';

export interface SubmissionBreadcrumbsProps {
  experienceId: string;
  activeBreadcrumbLabel: string;
}

export const SubmissionBreadcrumbs = ({
  experienceId,
  activeBreadcrumbLabel,
}: SubmissionBreadcrumbsProps) => {
  const { t } = useTranslate('submissionBreadcrumbs');

  const previousBreadcrumbs = [
    {
      label: t('allSubmissions'),
      href: `${EXPERIENCE_PATH}/${experienceId}/${AppRoutes.SUB_PATHS.SUBMISSIONS}`,
    },
  ];

  return (
    <Breadcrumbs
      activeBreadcrumbLabel={activeBreadcrumbLabel}
      previousBreadcrumbs={previousBreadcrumbs}
    />
  );
};
