/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type DeleteSubmissionMutationVariables = Types.Exact<{
  params: Types.DeleteSubmissionInput;
}>;


export type DeleteSubmissionMutation = { __typename: 'Mutation', deleteSubmission: { __typename: 'CameraSubmission', id: string } | { __typename: 'GpsSubmission', id: string } | { __typename: 'TextSubmission', id: string } };


export const DeleteSubmissionDocument = `
    mutation DeleteSubmission($params: DeleteSubmissionInput!) {
  deleteSubmission(params: $params) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    DeleteSubmission: build.mutation<DeleteSubmissionMutation, DeleteSubmissionMutationVariables>({
      query: (variables) => ({ document: DeleteSubmissionDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteSubmissionMutation } = injectedRtkApi;

