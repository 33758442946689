import { Label, TextField, Button, Toggle, Text, mergeClasses } from '@goosechase/ui';
import { HelpCircle } from 'components/help-circle.component';
import { useShareExperienceMutation, useUnshareExperienceMutation } from 'data/experiences';
import { displayToast } from 'services/toast';
import { Nullable } from 'types/util';
import { useTranslate } from 'util/i18n';

const rootId = 'share-experience-container';

interface ShareExperienceProps {
  experienceId: string;
  experienceShareLink: Nullable<string>;
}

export const ShareExperience = ({ experienceId, experienceShareLink }: ShareExperienceProps) => {
  const { t } = useTranslate('shareExperience');
  const [shareExperience] = useShareExperienceMutation();
  const [unshareExperience] = useUnshareExperienceMutation();

  const toggleShareExperience = async () => {
    if (experienceShareLink) {
      await unshareExperience({ experienceId }).unwrap();
    } else {
      await shareExperience({ experienceId }).unwrap();
    }
  };

  const generateNewLink = async () => {
    const response = await shareExperience({ experienceId }).unwrap();
    displayToast({
      body: t('generateToast.body'),
      id: response.shareExperience.id,
      title: t('generateToast.title'),
      type: 'success',
    });
  };

  const copyToClipboard = async () => {
    if (experienceShareLink) {
      await navigator.clipboard.writeText(experienceShareLink);
      displayToast({
        body: t('copyToast.body'),
        id: experienceId,
        title: t('copyToast.title'),
        type: 'success',
      });
    }
  };

  return (
    <div id={rootId}>
      <div className="flex flex-col gap-5">
        <div
          className={mergeClasses(
            'border border-black-24 rounded-md p-4 flex gap-2 relative justify-between items-center',
            {
              'border-2 border-vibrantBlue bg-vibrantBlue-10': Boolean(experienceShareLink),
            },
          )}>
          <div>
            <div className="flex gap-1 mb-1">
              <Label size="lg" className="uppercase">
                {t('toggle.label')}
              </Label>
            </div>
            <Text size="sm">{t('toggle.hint')}</Text>
          </div>
          <Toggle
            onChange={toggleShareExperience}
            checked={Boolean(experienceShareLink)}
            label={t('label') ?? undefined}
          />
        </div>
        {experienceShareLink ? (
          <div className="flex flex-col gap-2">
            <Label id="share-link-label" className="block uppercase" size="lg">
              {t('link.label')}
            </Label>
            <div className="flex items-center gap-5">
              <TextField
                type="text"
                className="flex-1"
                value={experienceShareLink}
                disabled
                aria-labelledby="share-link-label"
              />
              <Button label={t('link.copy')} onClick={copyToClipboard} />
            </div>
            <div className="flex gap-2 items-center">
              <button onClick={generateNewLink} type="button">
                <Text className="font-bold text-vibrantBlue" size="sm">
                  {t('link.generate')}
                </Text>
              </button>
              <HelpCircle
                tooltipText={t('link.tooltip')}
                id="share-tooltip"
                customRootId={rootId}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
