import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { AccountFormLayout } from 'components/account-form-layout.component';
import { ForgotPasswordPanel } from './forgot-password-panel.component';
import { AppLink } from '../../components/app-link.component';

export const ForgotPasswordDonePage = () => {
  const { t } = useTranslate('pages.forgotPassword');

  return (
    <Page title={t('meta.title')} description={t('meta.description') as string}>
      <AccountFormLayout>
        <AccountFormLayout.Body>
          <AccountFormLayout.Title>{t('headingDone')}</AccountFormLayout.Title>
          <AccountFormLayout.Description>{t('subheadingDone')}</AccountFormLayout.Description>
          <div className="tablet-narrow:text-paragraph-lg">
            <AppLink page="LOGIN">{t('backToLogIn')}</AppLink>
          </div>
        </AccountFormLayout.Body>
        <ForgotPasswordPanel />
      </AccountFormLayout>
    </Page>
  );
};
