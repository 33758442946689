import { Toast, ToastProps } from '@goosechase/ui';
import { toast } from 'sonner';
import { useTranslate } from '../util/i18n';

export const displayToast = (props: Omit<ToastProps, 'onClose'> & { id: string }) =>
  toast.custom(
    () => (
      <Toast
        key={props.id}
        type={props.type}
        title={props.title}
        body={props.body}
        onClose={() => {
          toast.dismiss(props.id);
        }}
      />
    ),
    {
      id: props.id,
    },
  );

export const useGenericErrorToast = () => {
  const { t } = useTranslate('genericErrorToast');

  const displayGenericErrorToast = () =>
    displayToast({
      id: 'generic-error-toast',
      type: 'error',
      title: t('title'),
      body: t('body'),
    });

  return displayGenericErrorToast;
};
