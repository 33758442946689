import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface LinkProps {
  className?: string;
  href: string;
  target?: string;
  useRouter?: boolean;
  children: React.ReactNode;
}

/*
 * An unstyled Link/anchor tag depending on the useRouter prop.
 * If you need styling, consider using a <StyledAnchor />  or <AppLink /> instead.
 * Note: The use of forwardRef and prop spreading is present to satisfy HeadlessUI props and functionality
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  { className, href, target, useRouter = false, children, ...props },
  ref,
) {
  if (useRouter) {
    return (
      <RouterLink ref={ref} to={href} target={target} className={className} {...props}>
        {children}
      </RouterLink>
    );
  }

  return (
    <a ref={ref} href={href} className={className} target={target} {...props}>
      {children}
    </a>
  );
});
