var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getDataTestIDProp, getTestIDProp } from '../../util/test-id';
import { ButtonIcon, ButtonLabel } from '../components';
import { getBaseButtonClassNames, getButtonIconProps, getButtonLabelClassNames, } from '../button-utils';
import { mergeClasses } from '../../util/merge-classes';
export var TextButton = function (_a) {
    var label = _a.label, leftIcon = _a.leftIcon, rightIcon = _a.rightIcon, iconClassName = _a.iconClassName, _b = _a.size, size = _b === void 0 ? 'lg' : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, className = _a.className, testID = _a.testID, htmlProps = __rest(_a, ["label", "leftIcon", "rightIcon", "iconClassName", "size", "disabled", "className", "testID"]);
    var baseClassNames = getBaseButtonClassNames(disabled);
    var _d = getButtonIconProps(false), iconColor = _d.iconColor, iconDisabledColor = _d.iconDisabledColor, iconHoverClassNames = _d.iconHoverClassNames;
    var labelClassNames = getButtonLabelClassNames(false, disabled);
    var sizeClassNames = ['py-1.5', 'px-3.5', 'rounded-button-text'];
    return (_jsxs("button", __assign({ className: mergeClasses(baseClassNames, sizeClassNames, labelClassNames, className), disabled: disabled }, getDataTestIDProp(testID), htmlProps, { children: [!!leftIcon && (_jsx(ButtonIcon, __assign({ name: leftIcon, className: mergeClasses('mr-1.5', iconClassName), size: 24, disabled: disabled, iconColor: iconColor, iconDisabledColor: iconDisabledColor, iconHoverClassNames: iconHoverClassNames }, getTestIDProp(testID, 'left-icon')))), _jsx(ButtonLabel, __assign({ text: label, size: size, disabled: disabled }, getTestIDProp(testID, 'label'))), !!rightIcon && (_jsx(ButtonIcon, __assign({ name: rightIcon, className: mergeClasses('ml-1.5', iconClassName), size: 24, disabled: disabled, iconColor: iconColor, iconDisabledColor: iconDisabledColor, iconHoverClassNames: iconHoverClassNames }, getTestIDProp(testID, 'right-icon'))))] })));
};
