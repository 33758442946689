/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type UpdateTeamMutationVariables = Types.Exact<{
  params: Types.UpdateTeamParams;
}>;


export type UpdateTeamMutation = { __typename: 'Mutation', updateTeam: { __typename: 'Team', id: string, displayName: string, mode: Types.TeamMode, passcode: string | null, maxSize: number | null, isPreCreated: boolean, createdAt: string, updatedAt: string, photoUrl: string | null } };


export const UpdateTeamDocument = `
    mutation UpdateTeam($params: UpdateTeamParams!) {
  updateTeam(params: $params) {
    id
    displayName
    mode
    passcode
    maxSize
    isPreCreated
    createdAt
    updatedAt
    photoUrl
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    UpdateTeam: build.mutation<UpdateTeamMutation, UpdateTeamMutationVariables>({
      query: (variables) => ({ document: UpdateTeamDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateTeamMutation } = injectedRtkApi;

