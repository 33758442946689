import { Avatar, Dropdown, Text, mergeClasses } from '@goosechase/ui';
import { Nullable } from 'types/util';
import { useTranslate } from 'util/i18n';

type MemberProps = {
  email: string;
  photoUrl?: Nullable<string>;
  currentUser: boolean;
  roleId: string;
  canUpdateMembers: boolean;
  workspaceMemberUserId?: string;
  workspaceInvitationId?: string;
  onUpdateWorkspaceMember: (params: {
    workspaceMemberUserId: string;
    roleId: string;
    email: string;
  }) => Promise<void>;
  onDeleteWorkspaceMember: (params: {
    workspaceMemberUserId: string;
    action: string;
    email: string;
  }) => void;
  onUpdateWorkspaceInvitation: (params: {
    workspaceInvitationId: string;
    roleId: string;
    email: string;
  }) => Promise<void>;
  onCancelWorkspaceInvitation: (params: {
    workspaceInvitationId: string;
    email: string;
  }) => Promise<void>;
};

export const Member = (props: MemberProps) => {
  const { t } = useTranslate('pages.workspace.tabs.members');

  const leave = { id: 'leave', label: t('leave') };
  const remove = { id: 'remove', label: t('remove') };
  const cancel = { id: 'cancel', label: t('cancel') };

  const options = props.canUpdateMembers
    ? [
        { id: 'admin', label: t('roles.admin') },
        { id: 'member', label: t('roles.member') },
        props.currentUser ? leave : props.workspaceMemberUserId ? remove : cancel,
      ]
    : [{ id: props.roleId, label: t(`roles.${props.roleId}`) }, leave];

  const selected = options.find((option) => option.id === props.roleId);
  const actions = ['leave', 'remove', 'cancel'];

  const customRenderOption = (option: { id: string; label: string }) =>
    actions.includes(option.id) ? (
      <Text className="p-3 text-vibrantRed">{t(option.id)}</Text>
    ) : (
      <Text className={mergeClasses('p-3', { 'font-bold': props.roleId === option.id })}>
        {t(`roles.${option.id}`)}
      </Text>
    );

  const handleDropDownSelect = (roleId: string) => {
    if (props.workspaceMemberUserId) {
      return actions.includes(roleId)
        ? props.onDeleteWorkspaceMember({
            action: roleId,
            workspaceMemberUserId: props.workspaceMemberUserId,
            email: props.email,
          })
        : props.onUpdateWorkspaceMember({
            roleId,
            workspaceMemberUserId: props.workspaceMemberUserId,
            email: props.email,
          });
    } else if (props.workspaceInvitationId) {
      return actions.includes(roleId)
        ? props.onCancelWorkspaceInvitation({
            workspaceInvitationId: props.workspaceInvitationId,
            email: props.email,
          })
        : props.onUpdateWorkspaceInvitation({
            workspaceInvitationId: props.workspaceInvitationId,
            roleId,
            email: props.email,
          });
    }
  };

  return (
    <div className="px-4 py-2 border border-black-1 flex gap-3 items-center first:rounded-t-lg last:rounded-b-lg -mb-px">
      <Avatar placeholder="single" alt={props.email} size="xs2" src={props.photoUrl ?? undefined} />
      <div>
        <Text className="font-bold">
          {props.email} {props.currentUser ? t('you') : ''}
        </Text>
        {!props.workspaceMemberUserId ? <Text size="xs">{t('pending')}</Text> : null}
      </div>
      {props.roleId === 'owner' ? (
        <Text className="ml-auto font-semibold" size="md">
          {t(`roles.${props.roleId}`)}
        </Text>
      ) : (
        <Dropdown
          label={selected?.label ?? ''}
          onSelect={handleDropDownSelect}
          options={options}
          customRenderOption={customRenderOption}
          buttonClassName="h-[48px] w-[133px]"
          containerClassName="ml-auto"
          disabled={!props.canUpdateMembers && !props.currentUser}
        />
      )}
    </div>
  );
};
