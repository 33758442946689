/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type MyWorkspacesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyWorkspacesQuery = { __typename: 'Query', myWorkspaces: Array<{ __typename: 'Workspace', id: string, displayName: string, scope: Types.WorkspaceScope, type: Types.WorkspaceType, photoUrl: string | null, subscription: { __typename: 'Subscription', plan: { __typename: 'WorkspacePlan', id: string, chargebeeSku: string | null, maxConcurrentExperiences: number | null, upgradable: boolean, showUpgradeBanner: Types.ShowUpgradeBanner } }, myWorkspaceMember: { __typename: 'WorkspaceMember', roleId: string } | null }> };


export const MyWorkspacesDocument = `
    query MyWorkspaces {
  myWorkspaces {
    id
    displayName
    scope
    type
    photoUrl
    subscription {
      plan {
        id
        chargebeeSku
        maxConcurrentExperiences
        upgradable
        showUpgradeBanner
      }
    }
    myWorkspaceMember {
      roleId
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    MyWorkspaces: build.query<MyWorkspacesQuery, MyWorkspacesQueryVariables | void>({
      query: (variables) => ({ document: MyWorkspacesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useMyWorkspacesQuery, useLazyMyWorkspacesQuery } = injectedRtkApi;

