import { missionsSlice } from './missions.slice';
import {
  experienceMissionsApi,
  createCameraMissionApi,
  createTextMissionApi,
  createGpsMissionApi,
  updateCameraMissionApi,
  updateTextMissionApi,
  updateGpsMissionApi,
  deleteMissionApi,
  updateMissionCustomOrderPositionApi,
  missionTriggersApi,
  type MissionTriggersQuery,
  myPreviousMissionsApi,
  addMissionFromMissionLibraryApi,
  mySavedMissionsApi,
  deleteSavedMissionApi,
  saveMissionApi,
} from './missions.api';

export const { useExperienceMissionsQuery } = experienceMissionsApi;
export const { useCreateCameraMissionMutation } = createCameraMissionApi;
export const { useCreateTextMissionMutation } = createTextMissionApi;
export const { useCreateGpsMissionMutation } = createGpsMissionApi;
export const { useUpdateCameraMissionMutation } = updateCameraMissionApi;
export const { useUpdateTextMissionMutation } = updateTextMissionApi;
export const { useUpdateGpsMissionMutation } = updateGpsMissionApi;
export const { useDeleteMissionMutation } = deleteMissionApi;
export const { useUpdateMissionCustomOrderPositionMutation } = updateMissionCustomOrderPositionApi;
export const { useMissionTriggersQuery } = missionTriggersApi;
export const { useMyPreviousMissionsQuery } = myPreviousMissionsApi;
export const { useAddMissionFromMissionLibraryMutation } = addMissionFromMissionLibraryApi;
export const { useMySavedMissionsQuery } = mySavedMissionsApi;
export const { useDeleteSavedMissionMutation } = deleteSavedMissionApi;
export const { useSaveMissionMutation } = saveMissionApi;

export type { MissionTriggersQuery };

export const { selectMission, closeAndResetMissionPanel, openCreateMissionPanel } =
  missionsSlice.actions;

export const missionsReducer = missionsSlice.reducer;

export * from './missions.hooks';
