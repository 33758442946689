import { useExperienceOutletContext } from '../components/experience-layout';
import { useExperienceInfoQuery } from '../data/experiences';

export const useExperienceInfo = () => {
  const { experienceId } = useExperienceOutletContext();
  const experienceInfoQuery = useExperienceInfoQuery({ id: experienceId });
  return {
    ...experienceInfoQuery,
    experienceId,
  };
};
