import { TFunction } from 'i18next';
import { Dropdown, IconName } from '@goosechase/ui';

import { useTranslate } from '../../util/i18n';
import { SubmissionSource, SUBMISSION_SOURCES } from '../../data/models';
import { DropdownOptionType, renderSelectedLeftIcon } from './dropdown-option.component';
import { LabelledSetting } from '../labelled-setting.component';

const SUBMISSION_SOURCE_TO_TRANSLATION_KEY_MAP: Record<SubmissionSource, string> = {
  LIVE_AND_LIBRARY: 'liveAndLibrary',
  LIVE_ONLY: 'liveOnly',
};

const SUBMISSION_SOURCE_TYPE_TO_ICON_MAP: Record<SubmissionSource, IconName> = {
  LIVE_AND_LIBRARY: 'SmartphoneLiveLib',
  LIVE_ONLY: 'SmartphoneCapture',
};

const convertSubmissionSourceToDropdownOption = (
  t: TFunction,
  submissionSource: SubmissionSource,
): DropdownOptionType => ({
  id: submissionSource,
  icon: SUBMISSION_SOURCE_TYPE_TO_ICON_MAP[submissionSource],
  title: t(`${SUBMISSION_SOURCE_TO_TRANSLATION_KEY_MAP[submissionSource]}.title`),
  description: t(`${SUBMISSION_SOURCE_TO_TRANSLATION_KEY_MAP[submissionSource]}.description`),
});

interface SubmissionSourceDropdownProps {
  value: SubmissionSource;
  onChange: (value: SubmissionSource) => void;
}

export const SubmissionSourceDropdown = ({ value, onChange }: SubmissionSourceDropdownProps) => {
  const { t } = useTranslate('missionForm.submissionSource');

  const options = SUBMISSION_SOURCES.map((submissionSource) =>
    convertSubmissionSourceToDropdownOption(t, submissionSource),
  );

  const selectedDropdownOption = convertSubmissionSourceToDropdownOption(t, value);

  return (
    <LabelledSetting label={t('title')} description={t('description')}>
      <Dropdown
        buttonClassName="w-full"
        label={selectedDropdownOption.title}
        options={options}
        selectedOptionId={value}
        renderLeft={renderSelectedLeftIcon(selectedDropdownOption)}
        onSelect={(val: string) => onChange(val as SubmissionSource)}
      />
    </LabelledSetting>
  );
};
