import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, UseFormSetValue, Controller, UseFormSetError } from 'react-hook-form';
import { useState } from 'react';
import { Button, NotificationBanner, Label, TextField, Text } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { LabelledField } from 'components/labelled-field.component';
import { LabelledTextArea } from 'components/labelled-text-area';
import { LocationFields, LocationAutocomplete } from 'components/location-autocomplete';
import { Nullable } from 'types/util';
import { PhotoUploadField, type PhotoUploadUIProps } from 'components/photo-upload';
import { PhotoUploadUI } from './photo-upload-ui.component';
import { SearchVisibility } from './search-visibility-input.component';
import { HelpCircle } from 'components/help-circle.component';
import { transformEmptyInputToNull } from 'util/form-util';

export interface ExperienceFormUIProps {
  serverError?: string;
  onSubmit: (data: ExperienceData) => void;
  experienceData?: ExperienceData;
}

export interface ExperienceData {
  id: Nullable<string>;
  photoId: Nullable<string>;
  photoUrl: Nullable<string>;
  displayName: string;
  description: string;
  location: Nullable<string>;
  latitude: Nullable<string>;
  longitude: Nullable<string>;
  password: Nullable<string>;
  defaultVisible: 'visible' | 'hidden';
}

const useExperienceForm = (defaultData?: ExperienceData) => {
  const { t } = useTranslate('pages.createExperience.createExperienceForm');

  return useForm<ExperienceData>({
    mode: 'onTouched',
    defaultValues: {
      photoId: defaultData?.photoId ?? '',
      displayName: defaultData?.displayName ?? '',
      description: defaultData?.description ?? '',
      location: defaultData?.location ?? '',
      latitude: defaultData?.latitude ?? '',
      longitude: defaultData?.longitude ?? '',
      password: defaultData?.password ?? '',
      defaultVisible: defaultData?.defaultVisible ?? 'hidden',
    },
    resolver: zodResolver(
      z.object({
        displayName: z
          .string()
          .min(1, { message: t('displayName.errors.required') as string })
          .max(60, { message: t('displayName.errors.maxLength', { max: 60 }) as string }),
        description: z
          .string()
          .min(1, { message: t('description.errors.required') as string })
          .max(200, { message: t('description.errors.maxLength', { max: 200 }) as string }),
        location: z.string().transform(transformEmptyInputToNull),
        latitude: z.string(),
        longitude: z.string(),
        photoId: z.string(),
        password: z.string().transform(transformEmptyInputToNull),
        defaultVisible: z.enum(['hidden', 'visible']),
      }),
    ),
  });
};

const setLocationValue =
  (setValue: UseFormSetValue<ExperienceData>) =>
  <K extends keyof LocationFields>(field: K, value: LocationFields[K]) => {
    setValue(field.toString() as keyof LocationFields, value);
  };

const setPhotoValue = (setValue: UseFormSetValue<ExperienceData>) => (value?: string) => {
  setValue('photoId', value ?? null);
};

const setPhotoError =
  (setError: UseFormSetError<ExperienceData>) => (errorType: string, message: string) => {
    setError('photoId', { type: errorType, message });
  };

export const ExperienceFormUI = ({
  serverError,
  onSubmit,
  experienceData,
}: ExperienceFormUIProps) => {
  const { t } = useTranslate('pages.createExperience.createExperienceForm');
  const { control, handleSubmit, formState, setValue, setError } =
    useExperienceForm(experienceData);
  const [uploading, setUploading] = useState<boolean>(false);

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="photoId"
        render={({ fieldState: { error } }) => (
          <PhotoUploadField
            label={t('photoId.label')}
            setValue={setPhotoValue(setValue)}
            setError={setPhotoError(setError)}
            errorMessage={error?.message}
            defaultSrc={experienceData?.photoUrl ?? ''}
            setUploading={setUploading}
            uploading={uploading}
            photoUploadUI={(data: PhotoUploadUIProps) => <PhotoUploadUI {...data} />}
          />
        )}
      />
      <Controller
        control={control}
        name="displayName"
        render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
          <LabelledField
            name={name}
            label={t('displayName.label')}
            placeholder={t('displayName.placeholder') ?? ''}
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            errorMessage={error?.message}
            maxCharacters={60}
            defaultValue={value}
          />
        )}
      />
      <Controller
        control={control}
        name="description"
        render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
          <LabelledTextArea
            name={name}
            label={t('description.label')}
            placeholder={t('description.placeholder') ?? ''}
            onChange={onChange}
            onBlur={onBlur}
            errorMessage={error?.message}
            maxCharacters={200}
            defaultValue={value}
            rows={3}
          />
        )}
      />
      <LocationAutocomplete
        setLocationValue={setLocationValue(setValue)}
        defaultLocation={experienceData?.location}
        label={t('location.label')}
        placeholder={t('location.placeholder')}
        tooltipText={t('location.help') ?? ''}
        name="location"
      />
      <Controller
        control={control}
        name="password"
        render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
          <div className="flex flex-col gap-1.5">
            <div className="flex gap-2">
              <Label size="sm" className="block" htmlFor={name}>
                {t('password.label')?.toUpperCase()}
              </Label>
              <HelpCircle id="experience-password-help" tooltipText={t('password.help')} />
            </div>
            <Text className="text-black-64">{t('password.hint')}</Text>
            <TextField
              aria-label={t('password.label') ?? ''}
              onChange={onChange}
              onBlur={onBlur}
              responseState={error?.message ? 'error' : undefined}
              name={name}
              placeholder={t('password.label') ?? ''}
              type="text"
              defaultValue={value ?? ''}
            />
          </div>
        )}
      />
      <Controller
        control={control}
        name="defaultVisible"
        render={({ field: { onChange, name, value } }) => (
          <SearchVisibility name={name} onChange={onChange} value={value} />
        )}
      />
      {serverError && (
        <NotificationBanner
          className="mb-6 desktop:mb-8"
          type="error"
          title={t('generalErrorTitle') as string}
          body={serverError}
          showIcon
        />
      )}
      <Button className="w-fit" label={t('submit')} disabled={!formState.isValid || uploading} />
    </form>
  );
};
