var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Camera = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M14.6172 4H9.61719L7.11719 7H4.11719C3.58676 7 3.07805 7.21071 2.70297 7.58579C2.3279 7.96086 2.11719 8.46957 2.11719 9V18C2.11719 18.5304 2.3279 19.0391 2.70297 19.4142C3.07805 19.7893 3.58676 20 4.11719 20H20.1172C20.6476 20 21.1563 19.7893 21.5314 19.4142C21.9065 19.0391 22.1172 18.5304 22.1172 18V9C22.1172 8.46957 21.9065 7.96086 21.5314 7.58579C21.1563 7.21071 20.6476 7 20.1172 7H17.1172L14.6172 4Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M12.1763 16C13.8331 16 15.1763 14.6569 15.1763 13C15.1763 11.3431 13.8331 10 12.1763 10C10.5194 10 9.17627 11.3431 9.17627 13C9.17627 14.6569 10.5194 16 12.1763 16Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
