/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type AvailableAddonsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AvailableAddonsQuery = { __typename: 'Query', availableAddons: Array<{ __typename: 'Addon', id: string, chargebeeSku: string | null, displayName: string, maxTeamsPerExperience: number | null, priceUsdCents: number }> };


export const AvailableAddonsDocument = `
    query AvailableAddons {
  availableAddons {
    id
    chargebeeSku
    displayName
    maxTeamsPerExperience
    priceUsdCents
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    AvailableAddons: build.query<AvailableAddonsQuery, AvailableAddonsQueryVariables | void>({
      query: (variables) => ({ document: AvailableAddonsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAvailableAddonsQuery, useLazyAvailableAddonsQuery } = injectedRtkApi;

