/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type ActiveWorkspaceWithSubscriptionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ActiveWorkspaceWithSubscriptionQuery = { __typename: 'Query', myActiveWorkspace: { __typename: 'Workspace', displayName: string, id: string, type: Types.WorkspaceType, photoUrl: string | null, myWorkspaceMember: { __typename: 'WorkspaceMember', userId: string, roleId: string } | null, subscription: { __typename: 'Subscription', id: string, plan: { __typename: 'WorkspacePlan', chargebeeSku: string | null, id: string, displayName: string, upgradable: boolean, maxConcurrentExperiences: number | null, maxTeamsPerExperience: number | null, maxParticipantsPerExperience: number | null, individualModeEnabled: boolean, maxWorkspaceMembers: number | null, showUpgradeBanner: Types.ShowUpgradeBanner } } } | null };


export const ActiveWorkspaceWithSubscriptionDocument = `
    query ActiveWorkspaceWithSubscription {
  myActiveWorkspace {
    displayName
    id
    type
    myWorkspaceMember {
      userId
      roleId
    }
    photoUrl
    subscription {
      id
      plan {
        chargebeeSku
        id
        displayName
        upgradable
        maxConcurrentExperiences
        maxTeamsPerExperience
        maxParticipantsPerExperience
        individualModeEnabled
        maxWorkspaceMembers
        showUpgradeBanner
      }
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ActiveWorkspaceWithSubscription: build.query<ActiveWorkspaceWithSubscriptionQuery, ActiveWorkspaceWithSubscriptionQueryVariables | void>({
      query: (variables) => ({ document: ActiveWorkspaceWithSubscriptionDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useActiveWorkspaceWithSubscriptionQuery, useLazyActiveWorkspaceWithSubscriptionQuery } = injectedRtkApi;

