import { Headline, Icon, mergeClasses, type ThemeColor } from '@goosechase/ui';

import { MissionTypeName } from '../../data/models';
import { useTranslate } from '../../util/i18n';

const MISSION_TYPE_CLASSNAME: Record<MissionTypeName, string> = {
  CameraMission: '',
  TextMission: 'bg-cyan-50 text-vibrantBlue',
  GpsMission: 'bg-lilac-50 text-lilac-dark',
};

const MISSION_TYPE_ICON_COLOR: Record<MissionTypeName, ThemeColor> = {
  CameraMission: 'black',
  TextMission: 'vibrantBlue',
  GpsMission: 'lilac-dark',
};

const renderContent = (content: string, missionType: MissionTypeName): string => {
  if (missionType === 'TextMission') {
    // Wraps text answers in quotes
    return `"${content}"`;
  }

  return content;
};

interface SubmissionContentProps {
  missionType: MissionTypeName;
  imageSrc?: string;
  content?: string;
}

export const SubmissionContent = ({ imageSrc, content, missionType }: SubmissionContentProps) => {
  const { t } = useTranslate('submissionCard');

  if (imageSrc) {
    return (
      <img
        src={imageSrc}
        className="w-full h-full object-contain"
        alt={t('submissionPicAlt') ?? undefined}
      />
    );
  }

  return (
    <div
      className={mergeClasses(
        'relative w-full h-full p-5 text-left',
        MISSION_TYPE_CLASSNAME[missionType],
      )}>
      <Icon
        className="absolute right-2 top-2"
        name="Expand"
        size={16}
        color={MISSION_TYPE_ICON_COLOR[missionType]}
      />
      {content && (
        <Headline className="line-clamp-3" type="secondary" size="xs">
          {renderContent(content, missionType)}
        </Headline>
      )}
    </div>
  );
};
