/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type ShareExperienceMutationVariables = Types.Exact<{
  experienceId: Types.Scalars['ID']['input'];
}>;


export type ShareExperienceMutation = { __typename: 'Mutation', shareExperience: { __typename: 'Experience', id: string } };


export const ShareExperienceDocument = `
    mutation ShareExperience($experienceId: ID!) {
  shareExperience(experienceId: $experienceId) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ShareExperience: build.mutation<ShareExperienceMutation, ShareExperienceMutationVariables>({
      query: (variables) => ({ document: ShareExperienceDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useShareExperienceMutation } = injectedRtkApi;

