import { Avatar, Headline, Text, mergeClasses } from '@goosechase/ui';
import { Card } from 'components/card.component';
import { Tag } from 'components/information-tags';
import { MissionIcon } from 'components/mission-icon';
import { MissionPointsPill } from 'components/points-pill';
import { MissionType } from 'data/models';
import { Nullable } from 'types/util';
import { useTranslate } from 'util/i18n';

type PublicExperienceProps = {
  displayName: string;
  description: string;
  photoUrl: Nullable<string>;
  missions: {
    displayName: string;
    description: string;
    points: number;
    missionType: MissionType;
    answers?: string[];
    locationName?: Nullable<string>;
    latitude?: string;
    longitude?: string;
  }[];
};

export const PublicExperience = (props: PublicExperienceProps) => {
  const { t } = useTranslate('pages.sharedExperience');

  return (
    <Card className="w-full overflow-hidden">
      <div className="h-[220px] flex items-end justify-center relative">
        <Avatar
          alt={props.displayName}
          src={props.photoUrl ?? undefined}
          placeholder="experience"
          squared
          size="xl"
          className="z-10"
        />
        <div className="w-full absolute h-[160px] self-start z-0 overflow-hidden">
          <div
            className={mergeClasses('h-[160px] bg-center bg-cover blur-xl', {
              'bg-black-12': !props.photoUrl,
            })}
            style={{ backgroundImage: props.photoUrl ? `url(${props.photoUrl})` : undefined }}
          />
        </div>
      </div>
      <div className="flex flex-col px-6 py-5 gap-6">
        <Headline type="secondary" size="md" className="font-bold text-center">
          {props.displayName}
        </Headline>
        <Text size="lg" className="text-center">
          {props.description}
        </Text>
      </div>
      <div>
        {props.missions.map((mission) => (
          <div key={mission.displayName} className="flex gap-5 p-5 border-black-24 border-t">
            <MissionIcon type={mission.missionType} />
            <div className="flex flex-col grow gap-2">
              <Headline type="secondary" size="xs">
                {mission.displayName}
              </Headline>
              <Text>{mission.description}</Text>
              <div className="flex flex-col gap-2">
                {mission.answers?.length ? (
                  <>
                    <Text className="font-bold">{t('acceptedAnswers')}</Text>
                    <div className="flex gap-1">
                      {mission.answers.map((answer) => (
                        <Tag key={answer} label={answer} />
                      ))}
                    </div>
                  </>
                ) : null}
                <div className="flex flex-col gap-2">
                  {mission.longitude && mission.latitude ? (
                    <>
                      <Text className="font-bold">{t('acceptedAnswers')}</Text>
                      <div className="flex">
                        <Tag
                          label={
                            mission.locationName ?? `${mission.latitude}, ${mission.longitude}`
                          }
                          icon="GPS"
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <MissionPointsPill points={mission.points} type={mission.missionType} />
          </div>
        ))}
      </div>
    </Card>
  );
};
