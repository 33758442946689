import { Icon, Text, mergeClasses } from '@goosechase/ui';
import { useTranslate } from '../../util/i18n';
import { DeleteConfirmationModal } from '../delete-confirmation-modal';
import { useState } from 'react';
import { Menu } from '@headlessui/react';

interface MoreButtonProps {
  handleEdit: () => void;
  handleDelete: () => void;
}
export const MoreButton = ({ handleEdit, handleDelete }: MoreButtonProps) => {
  const { t } = useTranslate('pages.broadcasts.list');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button aria-label={t('meatball') ?? ''}>
              <Icon
                name="More"
                className={mergeClasses('rounded-full hover:bg-black-12', {
                  'bg-black-12': open,
                })}
              />
            </Menu.Button>
            <Menu.Items className="bg-white border border-black-24 rounded-lg shadow-md shadow-black-24 absolute z-20 top-7 right-0 w-[97px] font-semibold">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={mergeClasses(
                      'cursor-pointer text-left w-full px-3 py-2 flex flex-row items-center rounded-t-lg',
                      {
                        'bg-black-4': active,
                      },
                    )}
                    type="button"
                    onClick={handleEdit}>
                    <Icon className="shrink-0" name="EditBold" size={16} />
                    <Text className="ml-2">{t('editButton')}</Text>
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={mergeClasses(
                      'text-vibrantRed cursor-pointer text-left w-full px-3 py-2 flex flex-row items-center rounded-b-lg',
                      {
                        'bg-black-4': active,
                      },
                    )}
                    type="button"
                    onClick={() => setShowDeleteModal(true)}>
                    <Icon className="shrink-0" name="DeleteBold" size={16} color="vibrantRed" />
                    <Text className="ml-2">{t('deleteButton')}</Text>
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </>
        )}
      </Menu>
      <DeleteConfirmationModal
        show={showDeleteModal}
        header={t('deleteConfirmationModal.header') ?? undefined}
        body={t('deleteConfirmationModal.body')}
        buttonText={t('deleteConfirmationModal.button') ?? undefined}
        onSubmit={handleDelete}
        onClose={() => setShowDeleteModal(false)}
      />
    </>
  );
};
