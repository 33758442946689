var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { format as formatDate, parse as parseDate, isValid as isValidDate } from 'date-fns';
import { TextField } from '../text-field';
export var DateInput = function (_a) {
    var name = _a.name, value = _a.value, onChangeValue = _a.onChangeValue, isDirty = _a.isDirty, _b = _a.format, format = _b === void 0 ? 'MM/dd/yyyy' : _b, _c = _a.className, className = _c === void 0 ? '' : _c, rightIcon = _a.rightIcon, error = _a.error, _d = _a.disabled, disabled = _d === void 0 ? false : _d, placeholder = _a.placeholder, htmlProps = __rest(_a, ["name", "value", "onChangeValue", "isDirty", "format", "className", "rightIcon", "error", "disabled", "placeholder"]);
    var _e = React.useState(value ? formatDate(value, format) : ''), fieldValue = _e[0], setFieldValue = _e[1];
    var isValid = React.useMemo(function () { return (fieldValue ? isValidDate(parseDate(fieldValue, format, new Date())) : false); }, [fieldValue, format]);
    React.useEffect(function () {
        if (value) {
            setFieldValue(formatDate(value, format));
        }
    }, [value, format]);
    var handleChange = function (e) {
        setFieldValue(e.target.value);
    };
    var handleBlur = function () {
        if (fieldValue) {
            try {
                var parsed = parseDate(fieldValue, format, new Date());
                setFieldValue(formatDate(parsed, format));
                onChangeValue(parsed);
            }
            catch (e) {
                setFieldValue('');
            }
        }
    };
    return (_jsx(TextField, __assign({ name: name, value: fieldValue, onChange: handleChange, onBlur: handleBlur, responseState: (isDirty && !isValid) || error !== '' ? 'error' : undefined, className: className, rightIcon: rightIcon, placeholder: placeholder, disabled: disabled }, htmlProps)));
};
