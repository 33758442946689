/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type IsEducatorProfileRequiredQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IsEducatorProfileRequiredQuery = { __typename: 'Query', isEducatorProfileRequired: boolean };


export const IsEducatorProfileRequiredDocument = `
    query IsEducatorProfileRequired {
  isEducatorProfileRequired
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    IsEducatorProfileRequired: build.query<IsEducatorProfileRequiredQuery, IsEducatorProfileRequiredQueryVariables | void>({
      query: (variables) => ({ document: IsEducatorProfileRequiredDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useIsEducatorProfileRequiredQuery, useLazyIsEducatorProfileRequiredQuery } = injectedRtkApi;

