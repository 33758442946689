import { Label, Tooltip, Icon } from '@goosechase/ui';
import { Link } from 'components/link.component';
import { AppRoutes } from 'constants/routes';
import { useTranslate } from 'util/i18n';

type UpgradedPillProps = {
  addonId: string;
};

const BASIC_ADDON_IDS = [
  'starter',
  'professional',
  'starter-to-professional',
  'legacy-enterprise',
  'starter-npo',
  'professional-npo',
];

const TooltipBody = ({ addonId }: UpgradedPillProps) => {
  const { t } = useTranslate('upgradedPill');

  const isCustomAddon = !BASIC_ADDON_IDS.includes(addonId);

  if (isCustomAddon) {
    return <span>{t(`tooltip.custom-sxl`)}</span>;
  }

  return (
    <span>
      {t(`tooltip.${addonId}`)}
      <Link href={AppRoutes.PLANS} target="_blank" className="underline">
        {t('tooltip.needMore')}
      </Link>
    </span>
  );
};

export const UpgradedPill = ({ addonId }: UpgradedPillProps) => {
  const { t } = useTranslate('upgradedPill');

  return (
    <div className="w-min">
      <Tooltip
        body={<TooltipBody addonId={addonId} />}
        className="max-w-[266px]"
        onClick={(event) => event.stopPropagation()}>
        <div className="inline-flex px-2 py-1 bg-yellowOrange-10 rounded gap-2">
          <Icon name="YellowOrangeZap" size={16} />
          <Label className="uppercase" size="sm">
            {t('upgraded')}
          </Label>
        </div>
      </Tooltip>
    </div>
  );
};
