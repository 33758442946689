import { Avatar, Headline, Button, Modal, Text } from '@goosechase/ui';
import { useAppDispatch } from 'data/hooks';
import {
  toggleCopyExperienceModal,
  useCopyExperienceModalOpen,
  useSelectedExperience,
} from 'data/experiences';

import { useTranslate } from 'util/i18n';

export const CopyExperienceModal = () => {
  const { t } = useTranslate('copyExperienceModal');
  const show = useCopyExperienceModalOpen();
  const experience = useSelectedExperience();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(toggleCopyExperienceModal());
  };

  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[592px]"
      show={show}
      onClose={handleClose}>
      <Modal.Body>
        <div className="flex flex-col gap-6 items-center justify-center text-center">
          <Avatar
            alt={experience?.displayName ?? 'Experience avatar'}
            placeholder="experience"
            src={experience?.photoUrl ?? undefined}
            size="xl2"
            squared
          />
          <Headline type="primary" size="sm">
            {t('headline', { displayName: experience?.displayName })}
          </Headline>
          <Text>{t('body')}</Text>
          <Button label={t('gotIt')} onClick={handleClose} />
        </div>
      </Modal.Body>
    </Modal>
  );
};
