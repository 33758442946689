import { useState } from 'react';

export const useToggle = (initialValue = false): [boolean, (value?: boolean) => void] => {
  const [on, setOn] = useState(() => {
    if (typeof initialValue === 'boolean') {
      return initialValue;
    }

    return Boolean(initialValue);
  });

  const handleToggle = (value?: boolean) => {
    if (typeof value === 'boolean') {
      return setOn(value);
    }

    return setOn((v) => !v);
  };

  return [on, handleToggle];
};
