import { createListenerMiddleware, createSlice } from '@reduxjs/toolkit';
import { authSlice } from 'data/auth/auth.slice';

const WELCOME_BANNER_STORAGE_KEY = 'gc_welcome_banner';

export interface PreferencesState {
  showWelcomeBanner: boolean;
}

const initialState: PreferencesState = {
  showWelcomeBanner: true,
};

export const rehydratePreferencesState = (): PreferencesState => ({
  ...initialState,
  showWelcomeBanner: localStorage.getItem(WELCOME_BANNER_STORAGE_KEY) !== 'false',
});

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    dismissWelcomeBanner: (state) => {
      state.showWelcomeBanner = false;
    },
    reset: (state) => {
      state.showWelcomeBanner = initialState.showWelcomeBanner;
    },
  },
});

export const preferencesMiddleware = createListenerMiddleware();
// Automatically persist welcome banner dismissal to local storage when set in state
preferencesMiddleware.startListening({
  actionCreator: preferencesSlice.actions.dismissWelcomeBanner,
  effect: () => {
    localStorage.setItem(WELCOME_BANNER_STORAGE_KEY, 'false');
  },
});

// Logout listener
preferencesMiddleware.startListening({
  actionCreator: authSlice.actions.logout,
  effect: (action, listenerApi) => {
    // Clear local storage
    localStorage.removeItem(WELCOME_BANNER_STORAGE_KEY);

    // Reset state
    listenerApi.dispatch(preferencesSlice.actions.reset);
  },
});
