import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '../../types/util';

export interface SubmissionsState {
  activeMissionId: Nullable<string>;
  activeTeamId: Nullable<string>;
}

const initialState: SubmissionsState = {
  // active Ids are based on route params.
  // These values are stored in state so they can be accessed via getState in our endpoints
  activeMissionId: null,
  activeTeamId: null,
};

export const submissionsSlice = createSlice({
  name: 'submissions',
  initialState,
  reducers: {
    setActiveMissionId: (state, action: PayloadAction<Nullable<string>>) => {
      state.activeMissionId = action.payload;
    },
    setActiveTeamId: (state, action: PayloadAction<Nullable<string>>) => {
      state.activeTeamId = action.payload;
    },
  },
});
