/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type SubmissionInfoStatisticsPaginatedQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  orderBy: Types.SubmissionInfoOrderBy;
  orderDirection: Types.SortOrder;
  first: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after: Types.InputMaybe<Types.Scalars['String']['input']>;
  last: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SubmissionInfoStatisticsPaginatedQuery = { __typename: 'Query', submissionInfoPaginated: { __typename: 'SubmissionInfoConnection', totalCount: number, edges: Array<{ __typename: 'SubmissionInfoEdge', cursor: string, node: { __typename: 'SubmissionInfo', id: string, type: string, createdAt: string, missionName: string, teamName: string, submissionDataString: string, likes: number, points: number, bonusPoints: number } }>, pageInfo: { __typename: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor: string | null, endCursor: string | null } } };


export const SubmissionInfoStatisticsPaginatedDocument = `
    query SubmissionInfoStatisticsPaginated($id: ID!, $orderBy: SubmissionInfoOrderBy!, $orderDirection: SortOrder!, $first: Int, $after: String, $last: Int, $before: String) {
  submissionInfoPaginated(
    params: {experienceId: $id, orderBy: $orderBy, orderDirection: $orderDirection, first: $first, after: $after, last: $last, before: $before}
  ) {
    edges {
      node {
        id
        type
        createdAt
        missionName
        teamName
        submissionDataString
        likes
        points
        bonusPoints
      }
      cursor
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    SubmissionInfoStatisticsPaginated: build.query<SubmissionInfoStatisticsPaginatedQuery, SubmissionInfoStatisticsPaginatedQueryVariables>({
      query: (variables) => ({ document: SubmissionInfoStatisticsPaginatedDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSubmissionInfoStatisticsPaginatedQuery, useLazySubmissionInfoStatisticsPaginatedQuery } = injectedRtkApi;

