import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { useTranslate } from '../../util/i18n';
import { LocationAutocomplete, LocationFields } from '../location-autocomplete';
import { GpsLocationFormData } from './use-gps-location-form';

const setLocationValue =
  (setValue: UseFormSetValue<GpsLocationFormData>) =>
  <K extends keyof LocationFields>(field: K, value: LocationFields[K]) => {
    if (field === 'location') {
      // Can't call setValue directly as the field name differs for locationName
      setValue('locationName', value ?? '');
    } else {
      setValue(field, value ?? '');
    }
  };

const setZoom = (setValue: UseFormSetValue<GpsLocationFormData>) => (value?: number) => {
  setValue('zoom', value ?? undefined);
};

interface LocationInputProps {
  control: Control<GpsLocationFormData>;
  setValue: UseFormSetValue<GpsLocationFormData>;
}

export const LocationInput = ({ control, setValue }: LocationInputProps) => {
  const { t } = useTranslate('gpsLocationForm.locationInput');

  const locationName = useWatch({
    control,
    name: 'locationName',
  });

  return (
    <LocationAutocomplete
      setLocationValue={setLocationValue(setValue)}
      setZoom={setZoom(setValue)}
      label={t('label')}
      placeholder={t('placeholder')}
      defaultLocation={locationName}
    />
  );
};
