/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type AcceptWorkspaceInvitationMutationVariables = Types.Exact<{
  invitationId: Types.Scalars['ID']['input'];
}>;


export type AcceptWorkspaceInvitationMutation = { __typename: 'Mutation', acceptWorkspaceInvitation: { __typename: 'WorkspaceInvitation', id: string } };


export const AcceptWorkspaceInvitationDocument = `
    mutation AcceptWorkspaceInvitation($invitationId: ID!) {
  acceptWorkspaceInvitation(invitationId: $invitationId) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    AcceptWorkspaceInvitation: build.mutation<AcceptWorkspaceInvitationMutation, AcceptWorkspaceInvitationMutationVariables>({
      query: (variables) => ({ document: AcceptWorkspaceInvitationDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAcceptWorkspaceInvitationMutation } = injectedRtkApi;

