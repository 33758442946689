import { Headline } from '@goosechase/ui';
import { useScreenSize } from '../../hooks/use-screen-size';

interface ExperienceTitleProps {
  title: string;
}
export const ExperienceTitle = ({ title }: ExperienceTitleProps) => {
  const { isTabletWide } = useScreenSize();

  return (
    <Headline
      // Arbitrary max-width values to allow longer names to truncate nicely on smaller devices
      className="truncate max-w-[130px] tablet-narrow:max-w-[400px]"
      type="secondary"
      size={isTabletWide ? 'xs' : '2xs'}>
      {title}
    </Headline>
  );
};
