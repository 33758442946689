import { useState } from 'react';
import { Combobox } from '@headlessui/react';
import {
  getResponseStateBorderClassname,
  Label,
  mergeClasses,
  Text,
  TextContainer,
} from '@goosechase/ui';
import { useTranslate } from '../../util/i18n';
import classNames from 'classnames';

const DEFAULT_POINTS = ['400', '500', '600', '1000', ''];

const getNumberFromQuery = (query: string) => {
  if (query === '') {
    return null;
  }

  // Replace non numbers
  const numbersOnly = query.replace(/\D/g, '');
  if (isNaN(parseInt(numbersOnly))) {
    return null;
  }

  return numbersOnly;
};

const getFilteredSuggestions = (query: string) => {
  // queryNumString is the a string of only number characters -> eg. "123"
  const queryNumString = getNumberFromQuery(query);

  // Do not render queryNumString if it cannot be parsed to an integer.
  // This prevents the user from inputing invalid points.
  if (!queryNumString || queryNumString === '') {
    return DEFAULT_POINTS;
  }

  const uniqueValues = new Set([queryNumString, ...DEFAULT_POINTS]);

  return Array.from(uniqueValues).filter((points) =>
    points.toLowerCase().includes(queryNumString.toLowerCase()),
  );
};

interface PointsInputProps {
  value: string;
  errorMessage?: string;
  disabled?: boolean;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onChange: (value: string) => void;
}

// TODO: Add state of recently used numbers (redux)
export const PointsInput = ({
  value,
  errorMessage,
  disabled = false,
  onBlur,
  onChange,
}: PointsInputProps) => {
  const [query, setQuery] = useState('');
  const { t } = useTranslate('missionForm.points');

  const suggestions = getFilteredSuggestions(query);

  const handleComboboxOnChange = (newValue: string) => {
    setQuery('');
    onChange(newValue);
  };

  const displayValue = (points: string | null) => {
    return points && points.length > 0 ? t('formattedPoints', { count: Number(points) }) : '';
  };

  return (
    <div className="flex flex-col content-start">
      <Label size="sm" className="block mb-2">
        {t('label').toUpperCase()}
      </Label>
      <TextContainer
        disabled={disabled}
        className={mergeClasses(
          'relative grow overflow-auto overflow-visible',
          getResponseStateBorderClassname(errorMessage ? 'error' : undefined),
        )}>
        <Combobox immediate disabled={disabled} value={value} onChange={handleComboboxOnChange}>
          <Combobox.Input
            className="w-full pl-5 pr-12 py-4 outline-0 focus-visible:outline-none text-ellipsis w-full placeholder:text-black/[.76] focus:placeholder:text-black-64 caret-black bg-inherit"
            onBlur={(e) => {
              const newValue = getNumberFromQuery(e.target.value);
              if (newValue) {
                onChange(newValue);
              } else {
                onChange('');
              }
              onBlur(e);
            }}
            onFocus={(e) => {
              // Highlight all text on focus
              e.target.select();
            }}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            displayValue={displayValue}
            placeholder={t('placeholder')}
          />
          <Combobox.Options className="absolute top-full left-0 mt-1 max-h-80 w-full cursor-pointer bg-white border border-black-24 rounded-dropdown overflow-auto outline-0">
            <div className="outline-0 text-left text-black-64 py-3 px-5">
              <Label size="sm">{t('suggestions').toUpperCase()}</Label>
            </div>
            {suggestions.map((suggestion) => (
              <Combobox.Option
                key={suggestion}
                value={suggestion}
                className={mergeClasses(
                  'outline-0 text-left hover:bg-black-4 ui-active:bg-black-4 py-3 px-5 border-t border-black-24',
                  {
                    'bg-black-4': suggestion === value,
                  },
                )}>
                <Text className={classNames({ italic: suggestion === '' })}>
                  {suggestion === ''
                    ? t('customInput')
                    : t('formattedPoints', { count: Number(suggestion) })}
                </Text>
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Combobox>
      </TextContainer>
      <div className="flex mt-2 h-4">
        <Text size="xs" className="text-vibrantRed">
          {errorMessage ?? ''}
        </Text>
      </div>
    </div>
  );
};
