/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type DeleteBroadcastMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteBroadcastMutation = { __typename: 'Mutation', deleteBroadcast: { __typename: 'Broadcast', id: string } };


export const DeleteBroadcastDocument = `
    mutation DeleteBroadcast($id: ID!) {
  deleteBroadcast(id: $id) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    DeleteBroadcast: build.mutation<DeleteBroadcastMutation, DeleteBroadcastMutationVariables>({
      query: (variables) => ({ document: DeleteBroadcastDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteBroadcastMutation } = injectedRtkApi;

