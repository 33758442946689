import { Trans } from 'react-i18next';
import { Button, Text, Headline, Label, Icon, mergeClasses } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';

type DepartmentAnnualPlanProps = {
  priceUsdCents: number;
  selected: boolean;
  onClick: () => void;
};

const ANNUAL_PRICE_WITHOUT_DISCOUNT = 1_020_000;

const formatCurrency = (value: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(value);

export const DepartmentAnnualPlan = (props: DepartmentAnnualPlanProps) => {
  const { t } = useTranslate('plans');

  return (
    <div
      className={mergeClasses(
        'bg-white rounded-lg px-5 py-9 pt-5 border-t-[16px] border-cyan w-full max-w-[390px] justify-self-center',
        {
          'bg-black-12': props.selected,
        },
      )}>
      <Headline type="secondary" size="sm" className="mb-6">
        {t(`plan.departmentAnnual.title`)}
      </Headline>
      <div className="flex items-end mb-8 h-[66px]">
        <Headline type="secondary" size="md">
          $
        </Headline>
        <Headline type="secondary" size="xl">
          {(ANNUAL_PRICE_WITHOUT_DISCOUNT / 100 / 12).toFixed(0)}
        </Headline>
        <Text className="ml-3">
          USD {t('perMonth')}
          <br />
          <Text size="sm">
            <Trans
              t={t}
              i18nKey={'billedAnnually'}
              values={{
                regularPrice: formatCurrency(ANNUAL_PRICE_WITHOUT_DISCOUNT / 100),
                discountPrice: formatCurrency(props.priceUsdCents / 100),
              }}
              components={{ s: <s /> }}
            />
          </Text>
        </Text>
      </div>
      <div className="flex min-h-[52px] mb-6">
        {props.selected ? (
          <div className="flex gap-1 items-center">
            <Icon name="GooseFeet" />
            <Label size="lg" className="uppercase">
              {t('currentPlan')}
            </Label>
          </div>
        ) : (
          <Button label={t('getStarted')} onClick={props.onClick} />
        )}
      </div>
      <div className="flex flex-col gap-4 pb-4">
        {(t(`plan.departmentAnnual.subtitle`, { returnObjects: true }) as string[]).map(
          (subtitle) => (
            <Text key={subtitle} className="font-bold">
              {subtitle}
            </Text>
          ),
        )}
      </div>
      <div className="flex flex-col gap-4">
        <div className="h-px bg-black-24 w-full" />
        {(t(`plan.departmentAnnual.features`, { returnObjects: true }) as string[]).map(
          (features) => (
            <Text key={features}>{features}</Text>
          ),
        )}
      </div>
    </div>
  );
};
