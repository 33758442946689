import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { Card } from 'components/card.component';
import { displayToast } from 'services/toast';

import { UpdateExperienceForm } from './update-experience-form';
import { getExperienceSubPageTitleOptions } from '../utils';
import { PageHeadline } from '../../components/page-headline.component';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { AcceptedExperienceInvitationModal } from 'components/accepted-invitation-modal';
import { CopyExperienceModal } from './copy-experience-success-modal.component';

export const UpdateExperiencePage = () => {
  const { t } = useTranslate('pages.editExperience');

  const { experienceId, data } = useExperienceInfo();
  const displayName = data?.experience?.displayName;

  const handleUpdateExperienceSuccess = async (id: string) => {
    displayToast({
      title: t('successToast.title'),
      body: t('successToast.body'),
      type: 'success',
      id,
    });
  };

  return (
    <Page title={t('meta.title', getExperienceSubPageTitleOptions(displayName))}>
      <AcceptedExperienceInvitationModal />
      <CopyExperienceModal />
      <div className="flex flex-col gap-6 pb-6 desktop:max-w-[840px] desktop:max-w-[712px mx-auto">
        <PageHeadline>{t('title')}</PageHeadline>
        <Card className="p-6 w-full">
          <UpdateExperienceForm
            onUpdateExperienceSuccess={handleUpdateExperienceSuccess}
            experienceId={experienceId}
          />
        </Card>
      </div>
    </Page>
  );
};
