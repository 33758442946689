import { UseFormSetValue } from 'react-hook-form';
import { Icon, Label, TextField } from '@goosechase/ui';

import { useTranslate } from '../../util/i18n';
import { MissionFormInputData } from './use-mission-form';
import { formatMissionLink, removeWhitespace } from './util';

interface LinkInputProps {
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  setShowLink: (show: boolean) => void;
  setFormValue: UseFormSetValue<MissionFormInputData>;
}

export const LinkInput = ({ value, onChange, setShowLink, setFormValue }: LinkInputProps) => {
  const { t } = useTranslate('missionForm.linkInput');

  const handleCancel = () => {
    // Clears the value and hides the input
    setFormValue('link', '');
    setShowLink(false);
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    // Formats the link on blur
    setFormValue('link', formatMissionLink(e.target.value));
  };

  return (
    <div className="flex flex-col w-full">
      <Label size="sm" className="block mb-2">
        {t('label').toUpperCase()}
      </Label>
      <div className="flex flex-row">
        <TextField
          className="grow rounded-r-none"
          value={removeWhitespace(value)}
          placeholder={t('placeholder') ?? undefined}
          onChange={onChange}
          onBlur={handleBlur}
        />
        <button
          type="button"
          aria-label={t('removeLink') ?? undefined}
          className="flex w-[66px] bg-offWhite hover:bg-black-12 rounded-r-lg border border-l-0 border-black-24 justify-center items-center"
          onClick={handleCancel}>
          <Icon name="CloseBold" color="vibrantRed" size={14} />
        </button>
      </div>
    </div>
  );
};
