import { IconButton } from '@goosechase/ui';
import { useExperienceOutletContext } from 'components/experience-layout';
import { useActiveCohortId, useExperienceInfoQuery } from 'data/experiences';
import { useExperienceMissionsQuery } from 'data/missions';
import { FeedVisibility } from 'data/models';

export const DownloadMissions = () => {
  const cohortId = useActiveCohortId();
  const { experienceId } = useExperienceOutletContext();

  const { data: experienceInfoData } = useExperienceInfoQuery({ id: experienceId });
  const { data: experienceMissionsData } = useExperienceMissionsQuery(
    {
      id: experienceId,
      cohortId: cohortId ?? '',
    },
    { skip: cohortId === null },
  );

  const handleDownloadMissionsClick = () => {
    // build csv content
    const header = [
      'Type',
      'Name',
      'Description',
      'Points',
      'Image',
      'URL',
      'Visible in feed?',
      'Draft mode?',
      'Accepted submission types',
      'Camera Library Upload Allowed?',
      'Accepted Answers',
      'Accept Approximate Answers?',
      'GPS Latitude',
      'GPS Longitude',
      'GPS Location',
      'GPS Accepted Radius',
    ];

    const missions = experienceMissionsData?.experienceMissions.map((mission) =>
      [
        mission.missionType,
        // text fields that may contain commas must be wrapped in double quotes to not break the csv file
        `"${mission.displayName}"`,
        `"${mission.description}"`,
        mission.points,
        mission.image ?? '',
        mission.link ?? '',
        mission.feedVisibility === FeedVisibility.AlwaysPublic,
        mission.draft,
        mission.__typename === 'CameraMission' ? mission.mediaType : '',
        mission.__typename === 'CameraMission' ? mission.allowCameraRollUploads : '',
        mission.__typename === 'TextMission' ? `"${mission.answers.join(', ')}"` : '',
        mission.__typename === 'TextMission' ? mission.allowApproximateAnswers : '',
        mission.__typename === 'GpsMission' ? mission.latitude : '',
        mission.__typename === 'GpsMission' ? mission.longitude : '',
        mission.__typename === 'GpsMission' ? `"${mission.locationName}"` : '',
        mission.__typename === 'GpsMission' ? mission.radius : '',
      ].map(encodeURIComponent),
    );

    const csvContent = `data:text/csv;charset=utf-8,${header
      .map(encodeURIComponent)
      .join()}\n${missions?.join('\n')}`;

    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', `${experienceInfoData?.experience?.displayName}'s missions.csv`);
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
    link.remove();
  };

  return (
    <IconButton
      icon="Save"
      iconColor={experienceMissionsData?.experienceMissions?.length ? 'black' : undefined}
      disabled={!experienceMissionsData?.experienceMissions?.length}
      onClick={handleDownloadMissionsClick}
      aria-label="Download missions"
    />
  );
};
