/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type ExperienceInvitationQueryVariables = Types.Exact<{
  invitationId: Types.Scalars['ID']['input'];
}>;


export type ExperienceInvitationQuery = { __typename: 'Query', experienceInvitation: { __typename: 'ExperienceInvitation', id: string, email: string, roleId: string, status: string, lastSentAt: string, experience: { __typename: 'PublicExperience', id: string, displayName: string, photoUrl: string | null } } };


export const ExperienceInvitationDocument = `
    query ExperienceInvitation($invitationId: ID!) {
  experienceInvitation(invitationId: $invitationId) {
    id
    email
    roleId
    status
    lastSentAt
    experience {
      id
      displayName
      photoUrl
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ExperienceInvitation: build.query<ExperienceInvitationQuery, ExperienceInvitationQueryVariables>({
      query: (variables) => ({ document: ExperienceInvitationDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useExperienceInvitationQuery, useLazyExperienceInvitationQuery } = injectedRtkApi;

