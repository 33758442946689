var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { format as formatDate, parse as parseDate } from 'date-fns';
import { Text } from '../typography';
import { mergeClasses } from '../util/merge-classes';
export var TimeInput = function (_a) {
    var value = _a.value, name = _a.name, onChangeValue = _a.onChangeValue, isDirty = _a.isDirty, _b = _a.displayFormat, displayFormat = _b === void 0 ? 'HH:mm' : _b, error = _a.error, _c = _a.className, className = _c === void 0 ? '' : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, responseState = _a.responseState, htmlProps = __rest(_a, ["value", "name", "onChangeValue", "isDirty", "displayFormat", "error", "className", "disabled", "responseState"]);
    // This is the actual value of the underlying <input>
    var _e = React.useState(value ? formatDate(value, displayFormat) : '12:00'), fieldValue = _e[0], setFieldValue = _e[1];
    useEffect(function () {
        var formattedDate = value ? formatDate(value, displayFormat) : '';
        setFieldValue(formattedDate);
    }, [value, displayFormat]);
    var isFieldValid = React.useMemo(function () { return fieldValue !== ''; }, [fieldValue]);
    var handleChange = function (e) {
        setFieldValue(e.target.value);
    };
    var handleBlur = function (e) {
        if (e.target.validity.valid && e.target.value != '') {
            setFieldValue(e.target.value);
            var parsedDate = parseDate(e.target.value, 'HH:mm', new Date());
            onChangeValue(parsedDate);
        }
        else {
            setFieldValue('');
            onChangeValue(undefined);
        }
    };
    return (_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("input", __assign({ type: "time", name: name, value: fieldValue, onChange: handleChange, onBlur: handleBlur, className: mergeClasses(className, 'py-3.75 px-4 border border-black-24 rounded-lg', {
                    'border-vibrantRed focus-within:border-vibrantRed': (isDirty && !isFieldValid) || error !== '',
                    'text-black-64 placeholder:text-black-64': disabled,
                }), disabled: disabled }, htmlProps)), error ? (_jsx("div", __assign({ className: "mt-2 h-4" }, { children: _jsx(Text, __assign({ size: "xs", className: "text-vibrantRed" }, { children: error })) }))) : null] })));
};
