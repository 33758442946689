import type { Mission } from '../../data/models';

export const filterMissionBySearchValue =
  (searchValue: string) =>
  ({ displayName, description }: Pick<Mission, 'displayName' | 'description'>) => {
    const caseInsensitiveSearchValue = searchValue.toLowerCase();

    return (
      displayName.toLowerCase().includes(caseInsensitiveSearchValue) ||
      description.toLowerCase().includes(caseInsensitiveSearchValue)
    );
  };
