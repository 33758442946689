import { useDispatch } from 'react-redux';
import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { getExperienceSubPageTitleOptions } from '../utils';
import { PageHeadline } from '../../components/page-headline.component';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { Card } from 'components/card.component';
import { BroadcastList } from '../../components/broadcast-list';
import { InformationBanner } from 'components/information-banner';
import { CreateBroadcastButton } from '../../components/create-broadcast-button.component';
import { openCreateBroadcastPanel } from '../../data/broadcasts';
import { BroadcastSidePanel } from '../../components/broadcast-side-panel/broadcast-side-panel.component';
import { useActiveCohortId } from 'data/experiences';

export const BroadcastsPage = () => {
  const dispatch = useDispatch();
  const activeCohortId = useActiveCohortId();

  const { t } = useTranslate('pages.broadcasts');
  const { data } = useExperienceInfo();

  const displayName = data?.experience?.displayName;

  const onClickCreate = () => {
    dispatch(openCreateBroadcastPanel());
  };

  return (
    <Page title={t('meta.title', getExperienceSubPageTitleOptions(displayName))}>
      <div className="flex justify-start w-full mb-4 tablet-narrow:mb-5 flex-col tablet-narrow:flex-row gap-4">
        <PageHeadline>{t('title')}</PageHeadline>
        <CreateBroadcastButton onClick={onClickCreate} />
      </div>
      <div className="flex flex-col tablet-wide:flex-row gap-6 pb-6">
        <Card className="flex flex-col w-full justify-start h-fit">
          <BroadcastList cohortId={activeCohortId ?? ''} />
        </Card>
        <InformationBanner
          className="basis-1/2 self-baseline w-full tablet-wide:max-w-[344px]"
          body={t('guide')}
          boldBody={t('guide-intro') ?? ''}
          icon="Announcement"
        />
      </div>
      <BroadcastSidePanel />
    </Page>
  );
};
