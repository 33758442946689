import { EducatorPosition, SchoolLevel } from '../models';

export const BUSINESS_VERTICALS = [
  'company',
  'post-secondary',
  'non-profit',
  'event-facilitation',
  'k-12',
  'special-occasion',
] as const;

export type BusinessVertical = (typeof BUSINESS_VERTICALS)[number];

export const SUPPORTED_USE_CASES = new Set<SupportedUseCase>([
  'community-engagement',
  'conferences',
  'education',
  'fundraising',
  'onboarding',
  'virtual-engagement',
  'team-building',
  'interactive-tours',
]);

export type SupportedUseCase =
  | 'community-engagement'
  | 'conferences'
  | 'education'
  | 'fundraising'
  | 'onboarding'
  | 'virtual-engagement'
  | 'team-building'
  | 'interactive-tours';

export const POSITIONS = [
  EducatorPosition.Teacher,
  EducatorPosition.Administrator,
  EducatorPosition.Principal,
  EducatorPosition.InstructionalTechnologist,
  EducatorPosition.Other,
] as const;
export const SCHOOL_LEVELS = [
  SchoolLevel.Elementary,
  SchoolLevel.Middle,
  SchoolLevel.High,
  SchoolLevel.District,
  SchoolLevel.Other,
] as const;

export interface EducatorProfile {
  firstName: string;
  lastName: string;
  position: EducatorPosition;
  schoolDistrict: string;
  schoolName: string;
  schoolLevel: SchoolLevel;
}
