// removeWhitespace trims whitespace at ends and removes whitespaces within the string
export const removeWhitespace = (value: string): string => value.trim().replace(/\s/g, '');

export const formatMissionLink = (link: string): string => {
  const linkWithoutWhitespace = removeWhitespace(link);

  if (linkWithoutWhitespace === '') {
    return '';
  }

  if (!linkWithoutWhitespace.includes('://')) {
    return `http://${linkWithoutWhitespace}`;
  }

  return removeWhitespace(linkWithoutWhitespace);
};
