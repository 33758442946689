import { Headline } from '@goosechase/ui';
import { TeamBonusForm, TeamBonusFormOutputData } from 'components/team-bonus-form';
import { useActiveCohortId } from 'data/experiences';
import { selectTeamId, useLeaderboardQuery } from 'data/leaderboard';
import { useAddTeamBonusMutation } from 'data/teams';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'util/i18n';
import { TeamBonusHistoryModal } from './team-bonus-history-modal.component';

export const TeamBonus = () => {
  const activeCohortId = useActiveCohortId();
  const { data } = useLeaderboardQuery(
    { cohortId: activeCohortId || '' },
    { skip: !activeCohortId },
  );
  const [addTeamBonusMutation] = useAddTeamBonusMutation();
  const { t } = useTranslate('pages.leaderboard.teamBonus');
  const dispatch = useDispatch();

  const options =
    data?.leaderboard?.map(({ team }) => ({
      id: team.id,
      title: team.displayName,
    })) || [];

  const addTeamBonus = async (params: Extract<TeamBonusFormOutputData, { teamId: string }>) => {
    await addTeamBonusMutation({ params: { ...params, value: Number(params.value) } }).unwrap();
  };

  const handleSelectTeam = (teamId: string) => {
    dispatch(selectTeamId(teamId));
  };

  return (
    <div className="flex gap-2 flex-col">
      <Headline type="secondary" size="xs">
        {t('title')}
      </Headline>
      <TeamBonusForm
        addTeamBonus={addTeamBonus}
        options={options}
        onSecondaryButtonClick={handleSelectTeam}
        secondaryButtonLabel={t('history')}
      />
      <TeamBonusHistoryModal />
    </div>
  );
};
