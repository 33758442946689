import { Dropdown } from '@goosechase/ui';
import type { TFunction } from 'i18next';

import { BROADCAST_TRIGGER_TIMINGS, BroadcastTriggerTiming } from 'data/models';
import { useTranslate } from 'util/i18n';
import type { DropdownOptionType } from '../util';
import { Control, Controller, UseFormTrigger } from 'react-hook-form';
import { BroadcastFormInputData } from '../use-broadcast-form';
import { BroadcastTriggerRelativeFields } from './broadcast-trigger-relative-fields.component';
import { BroadcastTriggerSpecificFields } from './broadcast-trigger-specific-fields.component';

const BROADCAST_TRIGGER_TIMING_TO_TRANSLATION_KEY_MAP: Record<
  BroadcastTriggerTiming.Relative | BroadcastTriggerTiming.Specific | 'DEFAULT',
  string
> = {
  DEFAULT: 'default',
  RELATIVE: 'relative',
  SPECIFIC: 'specific',
};

const convertBroadcastTriggerTimingToDropdownOption = (
  t: TFunction,
  broadcastTriggerTiming:
    | BroadcastTriggerTiming.Relative
    | BroadcastTriggerTiming.Specific
    | 'DEFAULT',
): DropdownOptionType => ({
  id: broadcastTriggerTiming,
  title: t(
    `timing.options.${BROADCAST_TRIGGER_TIMING_TO_TRANSLATION_KEY_MAP[broadcastTriggerTiming]}.label`,
  ),
  description: t(
    `timing.options.${BROADCAST_TRIGGER_TIMING_TO_TRANSLATION_KEY_MAP[broadcastTriggerTiming]}.description`,
  ),
});

interface DuringExperienceTimingFieldsProps {
  control: Control<BroadcastFormInputData>;
  isInFuture: boolean;
  trigger: UseFormTrigger<BroadcastFormInputData>;
}

export const DuringExperienceTimingFields = ({
  control,
  isInFuture,
  trigger,
}: DuringExperienceTimingFieldsProps) => {
  const { t } = useTranslate('broadcastForm.broadcastTrigger');

  const options = ['DEFAULT' as const, ...BROADCAST_TRIGGER_TIMINGS].map((broadcastTriggerTiming) =>
    convertBroadcastTriggerTimingToDropdownOption(t, broadcastTriggerTiming),
  );

  return (
    <Controller
      control={control}
      name={`duringExperienceTiming`}
      render={({ field: { value, onChange } }) => {
        const selectedDropdownOption = convertBroadcastTriggerTimingToDropdownOption(
          t,
          value ?? 'DEFAULT',
        );
        return (
          <div className="flex flex-col w-full gap-y-2">
            <Dropdown
              buttonClassName="w-full"
              containerClassName="w-full"
              label={selectedDropdownOption.title}
              options={options}
              selectedOptionId={value ?? 'DEFAULT'}
              onSelect={(val: string) => onChange(val as BroadcastTriggerTiming)}
            />

            {value === BroadcastTriggerTiming.Relative ? (
              <BroadcastTriggerRelativeFields
                control={control}
                isInFuture={isInFuture}
                trigger={trigger}
              />
            ) : null}
            {value === BroadcastTriggerTiming.Specific ? (
              <BroadcastTriggerSpecificFields
                control={control}
                isInFuture={isInFuture}
                trigger={trigger}
              />
            ) : null}
          </div>
        );
      }}
    />
  );
};
