var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Icon } from '../../icon';
import { mergeClasses } from '../../util/merge-classes';
import { getDataTestIDProp, getTestIDProp } from '../../util/test-id';
var getDefaultIconColor = function (_a) {
    var disabled = _a.disabled, background = _a.background;
    if (!disabled) {
        return 'vibrantBlue';
    }
    if (background) {
        return 'black';
    }
    return 'black-24';
};
var getIconClassnames = function (_a) {
    var disabled = _a.disabled, background = _a.background;
    // No icon styling if it is disabled
    if (disabled) {
        return '';
    }
    if (background) {
        return 'group-hover:fill-highlighterYellow group-hover:stroke-highlighterYellow';
    }
    return 'group-hover:fill-black group-hover:stroke-black';
};
var getButtonClassnames = function (_a) {
    var disabled = _a.disabled, background = _a.background;
    // No wrapper styling if there is no background
    if (!background) {
        return '';
    }
    if (disabled) {
        return 'bg-black-12';
    }
    return 'bg-highlighterYellow hover:bg-black';
};
export var IconButton = function (_a) {
    var icon = _a.icon, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.background, background = _c === void 0 ? false : _c, iconSize = _a.iconSize, iconColor = _a.iconColor, className = _a.className, testID = _a.testID, iconClassName = _a.iconClassName, htmlProps = __rest(_a, ["icon", "disabled", "background", "iconSize", "iconColor", "className", "testID", "iconClassName"]);
    return (_jsx("button", __assign({ className: mergeClasses('group flex items-center justify-center rounded-full h-10 w-10', getButtonClassnames({ disabled: disabled, background: background }), className), disabled: disabled }, getDataTestIDProp(testID), htmlProps, { children: _jsx(Icon, __assign({ className: mergeClasses(getIconClassnames({ disabled: disabled, background: background }), iconClassName), name: icon, color: iconColor !== null && iconColor !== void 0 ? iconColor : getDefaultIconColor({ disabled: disabled, background: background }), size: iconSize }, getTestIDProp(testID, 'icon'))) })));
};
