import classNames from 'classnames';
import { Text, Checkbox } from '@goosechase/ui';

import { ReactComponent as CommunityIcon } from 'media/icon-community-use-case.svg';
import { ReactComponent as ConferencesIcon } from 'media/icon-conferences-use-case.svg';
import { ReactComponent as EducationIcon } from 'media/icon-education-use-case.svg';
import { ReactComponent as FundraisingIcon } from 'media/icon-fundraising-use-case.svg';
import { ReactComponent as OnboardingIcon } from 'media/icon-onboarding-use-case.svg';
import { ReactComponent as RemoteIcon } from 'media/icon-remote-use-case.svg';
import { ReactComponent as TeamBuildingIcon } from 'media/icon-team-building-use-case.svg';
import { ReactComponent as ToursIcon } from 'media/icon-tours-use-case.svg';

import { type SupportedUseCase } from 'data/onboarding/types';

const IconMap: Record<SupportedUseCase, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
  'community-engagement': CommunityIcon,
  conferences: ConferencesIcon,
  education: EducationIcon,
  fundraising: FundraisingIcon,
  onboarding: OnboardingIcon,
  'virtual-engagement': RemoteIcon,
  'team-building': TeamBuildingIcon,
  'interactive-tours': ToursIcon,
};

export interface UseCaseCardProps {
  name: string;
  label: string;
  value: SupportedUseCase;
  selected: boolean;
  onSelect: React.ChangeEventHandler<HTMLInputElement>;
}

export const UseCaseCard = ({ name, label, value, selected, onSelect }: UseCaseCardProps) => {
  const Icon = IconMap[value];

  return (
    <label className="cursor-pointer">
      <div
        className={classNames(
          'w-[132px] flex flex-col gap-2 items-center mx-2.5 my-1.5 px-2 py-4 border border-black-64 rounded-lg',
          selected && 'outline outline-2 outline-vibrantBlue',
        )}>
        <Icon height="36px" width="36px" />
        <Text className="mb-4">{label}</Text>
        <Checkbox name={name} value={value} checked={selected} onChange={onSelect} />
      </div>
    </label>
  );
};
