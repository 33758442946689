import { Avatar, Headline, Text, Button } from '@goosechase/ui';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { LoadingSpinner } from '../../components/loading-spinner';
import { Card } from '../../components/card.component';
import {
  setSelectedExperience,
  toggleAcceptedExperienceInvitationModal,
  useAcceptExperienceInvitationMutation,
  useExperienceInvitationQuery,
} from 'data/experiences';
import { AppRoutes, EXPERIENCE_PATH } from 'constants/routes';
import { Trans } from 'react-i18next';
import { useLoadMyUserQuery } from 'data/auth';
import { displayToast } from 'services/toast';
import { useAppDispatch } from 'data/hooks';
import { LoadingModal } from './loading-modal.component';

const NotFound = () => {
  const { t } = useTranslate('pages.joinExperience.notFound');
  return (
    <Card className="p-6 flex gap-4 flex-col items-center text-center max-w-[663px]">
      <Avatar placeholder="experience" alt="Experience" size="xl2" squared />
      <Headline type="primary" size="sm">
        {t('headline')}
      </Headline>
      <Text>{t('body')}</Text>
    </Card>
  );
};

const InviteDoesNotMatch = (props: {
  experienceInvitation: {
    email: string;
    experience: { displayName: string; photoUrl: string | null };
  };
}) => {
  const { t } = useTranslate('pages.joinExperience.inviteDoesNotMatch');

  return (
    <Card className="p-6 flex gap-4 flex-col items-center text-center max-w-[663px]">
      <Avatar
        placeholder="experience"
        alt={props.experienceInvitation.experience.displayName}
        size="xl2"
        src={props.experienceInvitation.experience.photoUrl ?? undefined}
        squared
      />
      <Headline type="primary" size="sm">
        {t('headline')}
      </Headline>
      <Text>
        <Trans
          t={t}
          i18nKey="body"
          values={{
            email: props.experienceInvitation.email,
          }}
        />
      </Text>
      <a href={AppRoutes.LOGOUT}>
        <Button label={t('logOut')} />
      </a>
    </Card>
  );
};

export const JoinExperiencePage = () => {
  const { t } = useTranslate('pages.joinExperience');
  const { invitationId } = useParams();
  const { data, isLoading, isError } = useExperienceInvitationQuery(
    { invitationId: invitationId ?? '' },
    { skip: !invitationId },
  );
  const { data: userData } = useLoadMyUserQuery();
  const location = useLocation();
  const [acceptExperienceInvitation, { isLoading: isLoadingAccept }] =
    useAcceptExperienceInvitationMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  if (isLoading) {
    return (
      <div className="flex grow justify-center items-center p-4">
        <Card className="p-6 flex gap-4 flex-col items-center justify-center text-center max-w-[663px] h-[364px] w-full">
          <LoadingSpinner />
        </Card>
      </div>
    );
  }

  if (isError || !data || !data?.experienceInvitation) {
    return (
      <div className="flex grow justify-center items-center p-4">
        <Card className="p-6 flex gap-4 flex-col items-center justify-center text-center max-w-[663px] w-full">
          <NotFound />
        </Card>
      </div>
    );
  }

  const experience = {
    id: data.experienceInvitation.experience.id,
    displayName: data.experienceInvitation.experience.displayName,
    photoUrl: data.experienceInvitation.experience.photoUrl,
  };

  if (userData && userData?.myUser.profile?.email !== data.experienceInvitation.email) {
    return (
      <div className="flex grow justify-center items-center p-4">
        <Card className="p-6 flex gap-4 flex-col items-center justify-center text-center max-w-[663px] w-full">
          <InviteDoesNotMatch
            experienceInvitation={{ email: data.experienceInvitation.email, experience }}
          />
        </Card>
      </div>
    );
  }

  const handleJoinExperience = async () => {
    try {
      await acceptExperienceInvitation({ invitationId: invitationId ?? '' }).unwrap();
      dispatch(setSelectedExperience(experience));
      dispatch(toggleAcceptedExperienceInvitationModal());
      navigate(`${EXPERIENCE_PATH}/${experience.id}/${AppRoutes.SUB_PATHS.EDIT}`);
    } catch {
      displayToast({
        id: invitationId ?? '',
        body: t('toasts.joinError.body'),
        title: t('toasts.joinError.title'),
        type: 'error',
      });
    }
  };

  return (
    <Page title={t('meta.title')}>
      <div className="flex grow justify-center items-center p-4">
        <Card className="p-6 flex gap-4 flex-col items-center text-center w-full max-w-[663px]">
          <Avatar
            placeholder="experience"
            alt={experience.displayName}
            size="xl2"
            src={experience.photoUrl ?? undefined}
            squared
          />
          <Headline type="primary" size="sm">
            {t('joinExperience.headline')}
          </Headline>
          <Text>
            <Trans
              t={t}
              i18nKey={'joinExperience.body'}
              values={{
                displayName: experience.displayName,
              }}
            />
          </Text>
          {userData?.myUser.profile?.email ? (
            <Button label={t('joinExperience.accept')} onClick={handleJoinExperience} />
          ) : (
            <a href={`${AppRoutes.LOGIN}?redirectTo=${location.pathname}`}>
              <Button label={t('joinExperience.logIn')} />
            </a>
          )}
        </Card>
      </div>
      <LoadingModal show={isLoadingAccept} photoUrl={experience.photoUrl} />
    </Page>
  );
};
