import { Headline, Text } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import { Card } from '../../components/card.component';
import { useUpdateExperienceMutation } from '../../data/experiences';
import { useExperienceOutletContext } from '../../components/experience-layout';
import { MissionOrderDropdown } from './mission-order-dropdown.component';
import { MissionOrder } from 'data/models';
import { displayToast } from 'services/toast';

type MissionOrderCardProps = {
  missionOrder?: MissionOrder;
};

export const MissionOrderCard = ({ missionOrder }: MissionOrderCardProps) => {
  const { t } = useTranslate('pages.missions.missionOrder');
  const { experienceId } = useExperienceOutletContext();
  const [updateExperience] = useUpdateExperienceMutation();

  const handleChange = async (value: MissionOrder) => {
    try {
      await updateExperience({ params: { id: experienceId, missionOrder: value } });
      displayToast({
        body: t('toast.success.body'),
        id: experienceId,
        title: t('toast.success.title'),
        type: 'success',
      });
    } catch (error) {
      displayToast({
        body: t('toast.error.body'),
        id: experienceId,
        title: t('toast.error.title'),
        type: 'error',
      });
    }
  };

  return (
    <Card className="p-7 w-full flex flex-col gap-3">
      <Headline type="secondary" size="xs">
        {t('title')}
      </Headline>
      <Text className="text-black-64" size="sm">
        {t('description')}
      </Text>
      <MissionOrderDropdown onChange={handleChange} value={missionOrder} />
    </Card>
  );
};
