import { useState } from 'react';
import { Button } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';

import { OnboardingFormLayout } from '../onboarding-form-layout';
import { UseCaseCard } from './use-case-card';
import { type SupportedUseCase, SUPPORTED_USE_CASES } from 'data/onboarding/types';

export interface UseCaseFormProps {
  serverUseCases: string[];
  onSubmit: (useCases: SupportedUseCase[]) => void;
}

export const UseCaseForm = ({ serverUseCases, onSubmit }: UseCaseFormProps) => {
  const { t } = useTranslate('pages.onboarding');
  const [selectedUseCases, setSelectedUseCases] = useState<SupportedUseCase[]>([]);

  const useCases = serverUseCases.filter((uc) =>
    SUPPORTED_USE_CASES.has(uc as SupportedUseCase),
  ) as SupportedUseCase[];

  const handleSelectUseCase: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const selected = e.target.value as SupportedUseCase;
    if (selectedUseCases.includes(selected)) {
      setSelectedUseCases((curr) => curr.filter((value) => value !== selected));
    } else {
      setSelectedUseCases((curr) => [...curr, selected]);
    }
  };

  const handleSubmit = () => {
    if (selectedUseCases.length) {
      onSubmit(selectedUseCases);
    }
  };

  return (
    <OnboardingFormLayout title={t('useCaseForm.title')} subtext={t('subtext')}>
      <div className="flex flex-col items-center">
        <div className="mb-14 flex flex-wrap justify-center">
          {useCases.map((useCase) => (
            <UseCaseCard
              key={useCase}
              name="useCase"
              label={t(`useCaseForm.options.${useCase}`)}
              value={useCase}
              selected={selectedUseCases.includes(useCase)}
              onSelect={handleSelectUseCase}
            />
          ))}
        </div>
        <Button
          label={t('useCaseForm.button')}
          disabled={selectedUseCases.length === 0}
          onClick={handleSubmit}></Button>
      </div>
    </OnboardingFormLayout>
  );
};
