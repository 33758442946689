import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { getExperienceSubPageTitleOptions } from '../utils';
import { PageHeadline } from '../../components/page-headline.component';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { Feed } from './feed.component';

export const ActivityFeedPage = () => {
  const { t } = useTranslate('pages.feed');
  const { data } = useExperienceInfo();
  const displayName = data?.experience?.displayName;

  return (
    <Page title={t('meta.title', getExperienceSubPageTitleOptions(displayName))}>
      <div className="flex flex-col gap-6 pb-12 max-w-[696px] mx-auto w-full">
        <PageHeadline>{t('title')}</PageHeadline>
        <Feed />
      </div>
    </Page>
  );
};
