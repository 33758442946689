var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Label } from '../typography';
import { mergeClasses } from '../util/merge-classes';
import { getDimensions, getBorder, getFontSize, getBorderRadius, } from './avatar-utils';
import { PlaceholderAvatar } from './placeholders';
var AvatarBody = function (_a) {
    var size = _a.size, src = _a.src, initials = _a.initials, placeholder = _a.placeholder, alt = _a.alt;
    if (src) {
        return _jsx("img", { className: "h-full w-full object-cover", src: src, alt: alt });
    }
    else if (initials) {
        return _jsx(Label, __assign({ className: getFontSize(size) }, { children: initials.toUpperCase() }));
    }
    return _jsx(PlaceholderAvatar, { placeholder: placeholder });
};
export var Avatar = function (_a) {
    var alt = _a.alt, _b = _a.size, size = _b === void 0 ? 'base' : _b, _c = _a.bordered, bordered = _c === void 0 ? false : _c, _d = _a.squared, squared = _d === void 0 ? false : _d, className = _a.className, src = _a.src, initials = _a.initials, placeholder = _a.placeholder;
    return (_jsx("div", __assign({ className: mergeClasses('overflow-hidden flex items-center justify-around bg-offWhite', getBorderRadius(squared, size), getDimensions(size), bordered && getBorder(size), className) }, { children: _jsx(AvatarBody, { size: size, src: src, initials: initials, placeholder: placeholder, alt: alt }) })));
};
