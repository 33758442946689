var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Flag = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M4 15.25C4 15.25 5 14.25 8 14.25C11 14.25 13 16.25 16 16.25C19 16.25 20 15.25 20 15.25V3.25C20 3.25 19 4.25 16 4.25C13 4.25 11 2.25 8 2.25C5 2.25 4 3.25 4 3.25V15.25Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M4 22.25V15.25", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
