import { useEffect, useState } from 'react';

import { mergeClasses, Icon, IconButton, Label, Text } from '@goosechase/ui';
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import type { Nullable } from 'types/util';
import { useGoogleMapsApi } from '../../hooks/use-google-maps-api';
import { HelpCircle } from 'components/help-circle.component';

export interface LocationFields {
  location: Nullable<string>;
  latitude: Nullable<string>;
  longitude: Nullable<string>;
}

export type LocationAutocompleteProps = {
  errorMessage?: string;
  setLocationValue: <K extends keyof LocationFields>(field: K, value: LocationFields[K]) => void;
  setZoom?: (value?: number) => void;
  defaultLocation?: LocationFields['location'];
  label: string;
  placeholder: string;
  tooltipText?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

type SearchOption = {
  mainText: string;
  fullName: string;
  secondaryText: string;
  placeId: string;
};

const LOCATION_ZOOM = 15;

export const LocationAutocomplete = ({
  errorMessage,
  setLocationValue,
  setZoom,
  defaultLocation,
  label,
  placeholder,
  tooltipText,
  ...htmlProps
}: LocationAutocompleteProps) => {
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService>();
  const [options, setOptions] = useState<SearchOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<SearchOption | undefined>(
    defaultLocation
      ? { mainText: defaultLocation, fullName: defaultLocation, secondaryText: '', placeId: '' }
      : undefined,
  );

  const { isLoaded } = useGoogleMapsApi();

  useEffect(() => {
    if (defaultLocation === '') {
      // Clear the dropdown list and select location if the defaultLocation prop is cleared
      setOptions([]);
      setSelectedOption(undefined);
    }
  }, [defaultLocation]);

  useEffect(() => {
    if (isLoaded) {
      const token = new google.maps.places.AutocompleteSessionToken();
      setSessionToken(token);
      const autocompleter = new google.maps.places.AutocompleteService();
      setAutocompleteService(autocompleter);
    }
  }, [isLoaded]);

  const onInputChanged = (inputValue: string) => {
    if (!inputValue || !autocompleteService || !sessionToken) {
      return;
    }

    const autocompletionRequestObject: google.maps.places.AutocompletionRequest = {
      sessionToken,
      input: inputValue,
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    autocompleteService.getPlacePredictions(autocompletionRequestObject, (predictions, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        const items = predictions?.map((prediction) => {
          return {
            mainText: prediction.structured_formatting.main_text,
            fullName: prediction.description,
            secondaryText: prediction.structured_formatting.secondary_text,
            placeId: prediction.place_id,
          };
        });
        if (items) {
          setOptions(items);
        }
      }
    });
  };

  const onPlaceChanged = (selected: SearchOption) => {
    if (!selected) {
      return;
    }
    setSelectedOption(selected);

    // Now that we have a place selected, need to geocode it and update our under-the-hood data
    const geocoder = new google.maps.Geocoder();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    geocoder.geocode(
      { placeId: selected.placeId },
      (results: google.maps.GeocoderResult[] | null, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results?.length) {
            setZoom?.(LOCATION_ZOOM);
            setLocationValue('location', selected.fullName ?? '');
            setLocationValue('latitude', results[0].geometry.location.lat().toString() ?? '');
            setLocationValue('longitude', results[0].geometry.location.lng().toString() ?? '');
          }
        }
      },
    );
  };

  const clearLocation = (e: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedOption(undefined);
    setZoom?.();
    setLocationValue('location', '');
    setLocationValue('latitude', '');
    setLocationValue('longitude', '');
    e.preventDefault();
  };

  return (
    <div className="relative">
      <div className="relative flex flex-col content-start">
        <div className="flex gap-2 mb-2">
          <Label size="sm" className="block uppercase">
            {label}
          </Label>
          {tooltipText && <HelpCircle id={`${htmlProps.name}-help`} tooltipText={tooltipText} />}
        </div>
        <Combobox defaultValue={selectedOption} onChange={onPlaceChanged} nullable>
          <div className="relative w-full">
            <ComboboxInput
              className={
                'flex w-full py-4 pl-5 pr-3 items-center border focus:border-black rounded-dropdown outline-0 ui-open:border-black ui-not-open:border-black-24 focus:ui-not-open:border-black font-soleil'
              }
              placeholder={placeholder}
              disabled={!isLoaded}
              onChange={(event) => onInputChanged(event.target.value)}
              displayValue={(option) => (option as unknown as SearchOption)?.fullName}
              {...htmlProps}
            />
            <div className="absolute top-0 right-1 h-full flex justify-between items-center gap-2 pr-2">
              <IconButton
                type="button"
                className={mergeClasses(selectedOption ? '' : 'invisible')}
                icon="Close"
                iconSize={9}
                iconColor="black"
                aria-label="toggle location search"
                onClick={clearLocation}
              />
              <Icon className="" name="DropdownArrow" size={24} />
            </div>
          </div>
          <ComboboxOptions className="absolute bg-[url('media/powered_by_google_on_white.png')] bg-no-repeat bg-[right_5px_bottom_5px] top-full max-h-64 w-full bg-white border border-black-24 rounded-lg overflow-x-visible overflow-y-auto outline-0 pb-7 z-10">
            {options.map((option, index) => (
              <ComboboxOption
                key={`${option.fullName}${index}`}
                value={option}
                className="cursor-pointer hover:bg-offWhite font-soleil text-paragraph-sm p-1 flex items-center ui-active:bg-black-4">
                <Icon name="GPS" size={20} color="black-64" className="inline-block p2 mr-1" />
                {option.mainText}
                <span className="text-paragraph-xs pl-2">{option.secondaryText}</span>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Combobox>
        <div className="flex justify-between mt-2">
          <Text size="xs" className="text-vibrantRed">
            {errorMessage ?? ''}
          </Text>
        </div>
      </div>
    </div>
  );
};
