var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var AfterExperience = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M19.1665 6.88889C19.1665 6.39797 18.4203 6 17.4998 6L16.2498 6L16.2498 14L17.4998 14C18.4203 14 19.1665 13.602 19.1665 13.1111L19.1665 6.88889Z", fill: "#0C0A0C", stroke: "#0C0A0C", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M3.75 14L2.5 14C1.57953 14 0.833332 13.602 0.833332 13.1111L0.833333 6.88889C0.833333 6.39797 1.57953 6 2.5 6L3.75 6", stroke: "#757474", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M13 4L13 16L7 16L7 4L13 4Z", stroke: "#757474", strokeWidth: "1.25", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
