var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var GooseFeet = function () { return (_jsx("g", __assign({ fill: "none" }, { children: _jsx("path", { d: "M10.0158 5.46744C11.0756 5.75127 11.8798 5.06066 12.8489 4.26684C13.9242 3.38996 14.5816 2.91737 15.2981 3.30148C15.956 3.65494 15.9397 4.41565 15.6682 5.86574C15.4343 7.11305 15.399 8.39749 16.1803 9.08502C16.8851 9.70637 17.7571 9.68251 18.4384 9.60542C19.2654 9.51146 19.8195 9.47451 20.1793 9.86351C20.5677 10.2841 20.6167 11.3659 18.8147 13.0993C17.0126 14.8326 13.613 17.3886 11.374 18.7005C8.83052 20.1915 6.71499 21.4472 4.99699 20.4715C3.30248 19.5086 3.41286 16.9588 3.51848 14.9791C3.64913 12.516 4.24516 7.99905 5.04412 5.03748C5.82052 2.15861 6.72162 1.73867 7.33843 1.81162C8.08283 1.8996 8.25671 2.92653 8.44244 3.57119C8.65248 4.28807 9.04079 5.20593 10.0158 5.46744Z", stroke: "inherit", strokeWidth: "1.5", strokeMiterlimit: "10" }) }))); };
