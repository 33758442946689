import { MissionType, MissionTypeName } from '../data/models';

const ALPHABET_REGEX = /[a-zA-z]/;

// Only answer sets where every answer contains at least one alphabetical character are eligible for approximation/fuzzy matching.
// If an answer without an alphabetical character exists OR the list is empty, then it is not eligible for approximation/fuzzy matching.
export const answersAreEligibleForApproximation = (answers: string[]): boolean =>
  answers.length > 0 && answers.every((answer) => ALPHABET_REGEX.test(answer));

export const MISSION_TYPENAME_TO_MISSION_TYPE_MAP: Record<MissionTypeName, MissionType> = {
  CameraMission: 'CAMERA',
  TextMission: 'TEXT',
  GpsMission: 'GPS',
};
