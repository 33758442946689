import { Text } from '@goosechase/ui';
import { Control, useWatch } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EXPERIENCE_PATH, AppRoutes } from '../../constants/routes';
import { MissionTriggerTiming } from '../../data/models';
import { useTranslate } from '../../util/i18n';
import { useExperienceOutletContext } from '../experience-layout';
import { MissionFormInputData } from './use-mission-form';

type UseDisplayMissionTriggerHintProps = {
  control: Control<MissionFormInputData>;
  triggerType: 'releaseTrigger' | 'expireTrigger';
};

export const useDisplayMissionTriggerHint = ({
  control,
  triggerType,
}: UseDisplayMissionTriggerHintProps) => {
  const timing = useWatch({ control, name: `${triggerType}.timing` });
  const relativeDuration = useWatch({
    control,
    name: `${triggerType}.relativeDuration`,
  });
  const relativeUnit = useWatch({
    control,
    name: `${triggerType}.relativeUnit`,
  });
  const relativeAnchor = useWatch({
    control,
    name: `${triggerType}.relativeAnchor`,
  });
  const specificDay = useWatch({ control, name: `${triggerType}.specificDay`, defaultValue: null });
  const specificTime = useWatch({
    control,
    name: `${triggerType}.specificTime`,
  });
  const isRelative = timing === MissionTriggerTiming.Relative;
  const isSpecific = timing === MissionTriggerTiming.Specific;

  return Boolean(
    (isRelative && typeof relativeDuration === 'number' && relativeUnit && relativeAnchor) ||
      (isSpecific && typeof specificDay === 'number' && specificTime),
  );
};

export const MissionTriggerHint = () => {
  const { experienceId } = useExperienceOutletContext();
  const { t } = useTranslate('missionTriggerTime');

  return (
    <Text size="sm">
      <Trans
        i18nKey="hint"
        t={t}
        components={[
          <Link
            key="experience-start-end"
            className="font-bold text-vibrantBlue"
            to={`${EXPERIENCE_PATH}/${experienceId}/${AppRoutes.SUB_PATHS.START_END}`}
          />,
        ]}
      />
    </Text>
  );
};
