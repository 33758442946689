/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type UpdateWorkspaceMutationVariables = Types.Exact<{
  params: Types.UpdateWorkspaceParams;
}>;


export type UpdateWorkspaceMutation = { __typename: 'Mutation', updateWorkspace: { __typename: 'Workspace', id: string } };


export const UpdateWorkspaceDocument = `
    mutation UpdateWorkspace($params: UpdateWorkspaceParams!) {
  updateWorkspace(params: $params) {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    UpdateWorkspace: build.mutation<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>({
      query: (variables) => ({ document: UpdateWorkspaceDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateWorkspaceMutation } = injectedRtkApi;

