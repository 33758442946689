import * as Sentry from '@sentry/react';

interface SentryConfig {
  dsn: string;
  environment: string;
}

export const initSentry = (config: SentryConfig) => {
  Sentry.init({
    dsn: config.dsn,
    integrations: [new Sentry.BrowserTracing({}), new Sentry.Replay()],
    environment: config.environment,
    ignoreErrors: [
      'Failed to load https://www.googletagmanager.com',
      'Event `CustomEvent` (type=unhandledrejection) captured as promise rejection',
      "undefined is not an object (evaluating 'media.duration')",
    ],
  });
};

export const identifyUser = (userId: string) => {
  Sentry.setUser({ id: userId });
};

export const clearUser = () => {
  Sentry.setUser(null);
};
