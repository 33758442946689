var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Share = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M4 12.5558V20.5558C4 21.0862 4.21071 21.5949 4.58579 21.97C4.96086 22.3451 5.46957 22.5558 6 22.5558H18C18.5304 22.5558 19.0391 22.3451 19.4142 21.97C19.7893 21.5949 20 21.0862 20 20.5558V12.5558", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M16 6.55579L12 2.55579L8 6.55579", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M12 2.55579V15.5558", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
