import { Button, Modal, NotificationBanner } from '@goosechase/ui';
import { AppRoutes } from 'constants/routes';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useTranslate } from 'util/i18n';

interface DeleteAccountWarningProps {
  show: boolean;
  onClose: () => void;
  workspaceName: string;
}

export const DeleteAccountWarning = ({
  show,
  onClose,
  workspaceName,
}: DeleteAccountWarningProps) => {
  const { t } = useTranslate('deleteAccountModal.warning');

  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[498px] desktop:w-[766px]"
      show={show}
      onClose={onClose}>
      <Modal.Header title={t('title') ?? undefined} />
      <Modal.Body>
        <NotificationBanner
          className="mb-6 desktop:mb-8"
          type="warning"
          title={t('warningTitle')}
          body={
            <span>
              <Trans
                t={t}
                i18nKey="warningBody"
                values={{ workspaceName }}
                components={[
                  <Link
                    key={AppRoutes.WORKSPACE}
                    to={AppRoutes.WORKSPACE}
                    className="text-vibrantBlue"
                  />,
                ]}
              />
            </span>
          }
          showIcon
        />
        <span dangerouslySetInnerHTML={{ __html: t('body') ?? '' }} />
      </Modal.Body>
      <Modal.Footer>
        <Button label={t('confirm')} onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
};
