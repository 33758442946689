import { mergeClasses } from '@goosechase/ui';
import { useTranslate } from '../../util/i18n';
import { RadioBlockSelector } from 'components/radio-block-selector/radio-block-selector.component';
import { ReactComponent as GooseWithFlag } from 'media/goose-with-flag.svg';
import { ReactComponent as GeeseTrio } from 'media/geese-trio.svg';
import { TeamCreationMode } from 'data/models';

export type TeamCreationModeInputProps = {
  value: string;
  name: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  shouldDisableIndividualMode?: boolean;
};

export const TeamCreationModeInput = ({
  value,
  name,
  onChange,
  shouldDisableIndividualMode,
}: TeamCreationModeInputProps) => {
  const { t } = useTranslate('pages.participants.participantsOptions.teamCreationMode');

  const options = [
    {
      name: 'team',
      value: TeamCreationMode.Team,
      optionLabel: t('options.team.label'),
      description: t('options.team.description'),
      image: <GeeseTrio className="w-[100px]" />,
    },
    {
      name: 'solo',
      value: TeamCreationMode.Solo,
      optionLabel: t('options.solo.label'),
      description: shouldDisableIndividualMode
        ? t('options.solo.disabledDescription')
        : t('options.solo.description'),
      image: (
        <GooseWithFlag
          className={mergeClasses('w-[100px]', {
            'opacity-40': shouldDisableIndividualMode,
          })}
        />
      ),
      disabled: shouldDisableIndividualMode,
    },
  ];

  return (
    <div className="max-w-[676px]">
      <RadioBlockSelector options={options} name={name} onSelect={onChange} selectedValue={value} />
    </div>
  );
};
