var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Notifications = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M17.4655 8.78628C17.4655 7.25167 16.8559 5.7799 15.7707 4.69476C14.6856 3.60962 13.2138 3 11.6792 3C10.1446 3 8.67283 3.60962 7.5877 4.69476C6.50256 5.7799 5.89293 7.25167 5.89293 8.78628C5.89293 15.5369 3 18.9175 3 18.9175H20.3588C20.3588 18.9175 17.4655 15.5369 17.4655 8.78628Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M14.0927 19.5867C14.0927 20.9196 13.0123 22 11.6794 22C10.3466 22 9.26611 20.9196 9.26611 19.5867", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round" })] }))); };
