import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { Card } from 'components/card.component';

import { ChangePasswordForm, type ChangePasswordSubmissionData } from './change-password-form';
import { PageHeadline } from '../../components/page-headline.component';
import { useChangePasswordMutation } from '../../data/auth';
import { displayToast } from '../../services/toast';

export const ChangePasswordPage = () => {
  const { t } = useTranslate('pages.changePassword');
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const handleChangePassword = async (data: ChangePasswordSubmissionData) => {
    try {
      await changePassword({
        params: {
          currentPassword: data.currentPassword,
          newPassword: data.newPassword,
        },
      }).unwrap();
      displayToast({
        id: 'update-password',
        body: t('successToast.body'),
        title: t('successToast.title'),
        type: 'success',
      });
    } catch {
      displayToast({
        id: 'update-password',
        body: t('errorToast.body'),
        title: t('errorToast.title'),
        type: 'error',
      });
    }
  };

  return (
    <Page title={t('meta.title')}>
      <div className="flex justify-center w-full">
        <div className="flex flex-col w-full tablet-narrow:max-w-[600px] p-3.5 tablet-narrow:p-8">
          <PageHeadline>{t('title')}</PageHeadline>
          <Card className="mt-4 p-5 w-full">
            <ChangePasswordForm onSubmit={handleChangePassword} loading={isLoading} />
          </Card>
        </div>
      </div>
    </Page>
  );
};
