import { useEffect } from 'react';
import { useJsApiLoader, Libraries } from '@react-google-maps/api';

import { getConfig } from 'constants/config';

const LIBRARIES: Libraries = ['places', 'maps'];

export const useGoogleMapsApi = () => {
  const loader = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: getConfig().GOOGLE_MAPS_API_KEY,
    libraries: LIBRARIES,
  });

  useEffect(() => {
    if (loader.loadError !== undefined) {
      throw loader.loadError;
    }
  }, [loader.loadError]);

  return loader;
};
