var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var PhotosVideos = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M7 7H4C3.46957 7 2.96086 7.21071 2.58579 7.58579C2.21071 7.96086 2 8.46957 2 9V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20" }), _jsx("path", { d: "M7 7H4C3.46957 7 2.96086 7.21071 2.58579 7.58579C2.21071 7.96086 2 8.46957 2 9V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M14.1209 15.121C13.8453 15.4123 13.5142 15.6454 13.147 15.8066C12.7798 15.9677 12.3841 16.0536 11.9831 16.0592C11.5822 16.0648 11.1842 15.9899 10.8127 15.839C10.4411 15.6882 10.1036 15.4644 9.82009 15.1808C9.53654 14.8973 9.31272 14.5598 9.16185 14.1882C9.01098 13.8167 8.93612 13.4187 8.9417 13.0178C8.94727 12.6168 9.03317 12.2211 9.19432 11.8539C9.35546 11.4867 9.58858 11.1556 9.8799 10.88", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M9.66016 4H13.0002C13.5306 4 14.0393 4.21071 14.4144 4.58579C14.7894 4.96086 15.0002 5.46957 15.0002 6V9.34L16.0002 10.34L22.0002 6V16", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2 2L22 22", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
