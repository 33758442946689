import { Button, Text } from '@goosechase/ui';
import { Link } from 'components/link.component';
import { AppRoutes } from 'constants/routes';

import { useTranslate } from 'util/i18n';

export const UpgradePlanBanner = () => {
  const { t } = useTranslate('upgradePlanBanner');

  return (
    <div className="w-full p-2 bg-vibrantBlue text-white flex items-center justify-center gap-3">
      <Text className="font-bold">{t('body')}</Text>
      <Link href={AppRoutes.PLANS} target="_blank">
        <Button label={t('cta')} leftIcon="YellowOrangeZap" outlined size="sm" />
      </Link>
    </div>
  );
};
