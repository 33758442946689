var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { getDataTestIDProp } from '../util/test-id';
import { mergeClasses } from '../util/merge-classes';
import { Text } from '../typography';
import { Icon } from '../icon';
import { DefaultRenderOption } from './default-dropdown-option';
export var Dropdown = function (_a) {
    var containerClassName = _a.containerClassName, buttonClassName = _a.buttonClassName, optionsClassName = _a.optionsClassName, label = _a.label, options = _a.options, selectedOptionId = _a.selectedOptionId, _b = _a.disabled, disabled = _b === void 0 ? false : _b, clearable = _a.clearable, renderLeft = _a.renderLeft, _c = _a.customRenderOption, customRenderOption = _c === void 0 ? DefaultRenderOption : _c, onSelect = _a.onSelect, testID = _a.testID;
    var handleOptionClick = function (id) {
        return function () {
            onSelect(id);
        };
    };
    return (_jsxs(Menu, __assign({ as: "div", className: mergeClasses('relative', containerClassName) }, { children: [_jsxs(MenuButton, __assign({ disabled: disabled, className: mergeClasses('flex p-4 h-[58px] items-center border focus:border-black rounded-dropdown outline-0 ui-open:border-black ui-not-open:border-black-24 focus:ui-not-open:border-black', buttonClassName, {
                    'bg-black-4': disabled,
                }) }, getDataTestIDProp(testID, 'button'), { children: [renderLeft && _jsx("div", __assign({ className: "mr-3" }, { children: renderLeft() })), _jsx("div", __assign({ className: mergeClasses('overflow-hidden', {
                            'text-black': !disabled,
                            'text-black-64': disabled,
                        }) }, { children: _jsx(Text, __assign({ className: "truncate", size: "md" }, { children: label })) })), _jsx("div", __assign({ className: "flex grow justify-end ml-3" }, { children: clearable && selectedOptionId ? (_jsx("span", __assign({ onClick: function (event) {
                                event.stopPropagation();
                                onSelect('');
                            } }, { children: _jsx(Icon, { name: "Close", color: "black", size: 16 }) }))) : (_jsx(Icon, { name: "CaretDown", color: "black" })) }))] })), _jsx(MenuItems, __assign({ className: mergeClasses('absolute top-full left-0 mt-1 max-h-64 w-full flex flex-col cursor-pointer bg-white border border-black-24 rounded-lg overflow-auto outline-0 z-10', optionsClassName) }, getDataTestIDProp(testID, 'list'), { children: options.map(function (option, index) { return (_jsx(MenuItem, __assign({}, getDataTestIDProp(testID, 'item'), { disabled: option === null || option === void 0 ? void 0 : option.disabled }, { children: _jsx("button", __assign({ type: "button", className: mergeClasses('outline-0 text-left', {
                            'bg-black-4': option.id === selectedOptionId,
                            'border-t border-black-24': index !== 0,
                            'hover:bg-black-4 ui-active:bg-black-4': !option.disabled,
                        }), onClick: handleOptionClick(option.id), disabled: option.disabled }, { children: customRenderOption(option) })) }), option.id)); }) }))] })));
};
