import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Nullable } from 'types/util';
import { transformEmptyInputToNull } from 'util/form-util';
import { useTranslate } from 'util/i18n';
import { z } from 'zod';

export type BrandingFormData = {
  termsOfServiceUrl: Nullable<string>;
  splashScreenPhotoId: Nullable<string>;
};

export const useBrandingForm = (defaultValues: BrandingFormData) => {
  const { t } = useTranslate('pages.branding.form');

  const schema = z.object({
    termsOfServiceUrl: z
      .string()
      .url({
        message: t('termsOfServiceUrl.errors.invalid') ?? undefined,
      })
      .or(z.string().max(0).nullable().transform(transformEmptyInputToNull)),
    splashScreenPhotoId: z.string().nullable().optional().transform(transformEmptyInputToNull),
  });

  return useForm<BrandingFormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
    resolver: zodResolver(schema),
  });
};
