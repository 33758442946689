import { Text, IconButton, mergeClasses } from '@goosechase/ui';
import { MissionSummaryCard } from '../mission-summary-card';
import { type BonusPointsCardProps, BonusPointsCard } from '../bonus-points-card';
import { SubmissionBonusForm, SubmissionBonusFormInputData } from '../submission-bonus-form';
import { SubmissionTeamHeader } from '../../components/submission-team-header';
import { SubmissionContent } from '../submission-content';
import { useTranslate } from 'util/i18n';
import {
  getAdditionalMissionInfo,
  type InformationTagsMission,
} from '../../components/information-tags/information-tags.util';
import { Submission } from '../../components/submission-grid';
import { LikeButton } from '../../components/like-button';

export type FocusedSubmission = {
  submissionCaption?: string;
  submissionVideoSrc?: string;
  submissionBonuses: BonusPointsCardProps[];
  missionDescription: string;
  submissionLatitude?: string;
  submissionLongitude?: string;
  submissionAnswer?: string;
  informationTagsMission: InformationTagsMission;
};

export interface SubmissionFocusedViewProps {
  submission: Submission & FocusedSubmission;
  experienceId?: string;
  sidePanelClassName?: string;
  footerClassName?: string;
  onDeleteSubmission?: () => void;
  onDeleteSubmissionBonus?: (submissionBonusId: string) => void;
  onAddSubmissionBonus?: (values: SubmissionBonusFormInputData) => void;
  onNextSubmission?: () => void;
  onPreviousSubmission?: () => void;
  onLikeSubmission?: () => void;
  onCopySubmissionLink?: () => void;
  onDownloadSubmission?: () => void;
  renderCustomFooter?: () => React.ReactNode;
  autoFocusBonus?: boolean;
}

// eslint-disable-next-line complexity
export const SubmissionFocusedView = ({
  submission,
  experienceId,
  sidePanelClassName,
  footerClassName,
  onDeleteSubmission,
  onDeleteSubmissionBonus,
  onAddSubmissionBonus,
  onNextSubmission,
  onPreviousSubmission,
  onLikeSubmission,
  onCopySubmissionLink,
  onDownloadSubmission,
  renderCustomFooter,
  autoFocusBonus,
}: SubmissionFocusedViewProps) => {
  const { t } = useTranslate('submissionFocusedView');
  const { t: t2 } = useTranslate('missionInformationTags');

  if (!submission) {
    return null;
  }

  const additionalMissionInfo = getAdditionalMissionInfo(t2, submission.informationTagsMission);

  const isCameraMission = submission.missionType === 'CameraMission';

  return (
    <>
      <div className="flex flex-col tablet-wide:flex-row overflow-y-auto tablet-wide:overflow-hidden">
        <div className="flex flex-col tablet-wide:flex-row">
          <div className="aspect-square w-full tablet-wide:w-[552px] desktop:w-[714px] relative flex items-center z-[1]">
            <SubmissionContent
              missionType={submission.missionType}
              submissionAnswer={submission.submissionAnswer}
              submissionCaption={submission.submissionCaption}
              submissionImageSrc={submission.submissionImageSrc}
              submissionLatitude={submission.submissionLatitude}
              submissionLongitude={submission.submissionLongitude}
              submissionVideoSrc={submission.submissionVideoSrc}
            />
            {onNextSubmission && onPreviousSubmission ? (
              <>
                <IconButton
                  iconColor={isCameraMission ? 'white' : 'black'}
                  icon="CaretDown"
                  className="absolute"
                  iconClassName={mergeClasses('rotate-90 drop-shadow-md', {
                    'group-hover:stroke-white': isCameraMission,
                  })}
                  iconSize={32}
                  onClick={onPreviousSubmission}
                  aria-label={t('ariaPreviousSubmissionLabel') ?? undefined}
                  type="button"
                />
                <IconButton
                  iconColor={isCameraMission ? 'white' : 'black'}
                  icon="CaretDown"
                  className="absolute right-0"
                  iconClassName={mergeClasses('-rotate-90 drop-shadow-md', {
                    'group-hover:stroke-white': isCameraMission,
                  })}
                  iconSize={32}
                  onClick={onNextSubmission}
                  aria-label={t('ariaNextSubmissionLabel') ?? undefined}
                  type="button"
                />
              </>
            ) : null}
          </div>
          <div
            className={mergeClasses(
              'flex flex-col gap-4 p-6 tablet-wide:pb-[88px] overflow-x-hidden tablet-wide:overflow-y-auto w-full tablet-wide:w-[280px] desktop:w-[368px]',
              sidePanelClassName,
            )}>
            <SubmissionTeamHeader
              experienceId={experienceId}
              teamId={submission.teamId}
              teamImageUrl={submission.teamImageUrl ?? undefined}
              teamMode={submission.teamMode}
              teamName={submission.teamName}
              submissionDate={new Date(submission.submissionDate)}
            />
            {submission.submissionCaption && <Text size="md">{submission.submissionCaption}</Text>}
            <LikeButton
              isLiked={submission.isLiked}
              numLikes={submission.numLikes}
              onClick={onLikeSubmission}
            />

            <MissionSummaryCard
              title={submission.missionName}
              points={submission.missionPoints}
              type={submission.missionType}
              description={submission.missionDescription}
              infoTags={additionalMissionInfo.infoTags}
              secondaryInfoTags={additionalMissionInfo.secondaryInfoTags}
              infoTitle={additionalMissionInfo.infoTitle}
            />
            <div className="w-full h-px bg-black-12 shrink-0" />
            {submission.submissionBonuses.map((bonus) => (
              <BonusPointsCard
                key={bonus.bonusId}
                bonusId={bonus.bonusId}
                value={bonus.value}
                note={bonus.note ?? null}
                createdAt={new Date(bonus.createdAt)}
                onDelete={onDeleteSubmissionBonus}
              />
            ))}
            {typeof onAddSubmissionBonus === 'function' ? (
              <SubmissionBonusForm
                onSubmit={onAddSubmissionBonus}
                autoFocusBonus={autoFocusBonus}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div
        className={mergeClasses(
          'w-full tablet-wide:w-[280px] desktop:w-[368px] h-[72px] bg-white p-6 border-t border-black-24 flex items-center shadow-[0px_-4px_8px_0px_rgba(0,0,0,0.1)] tablet-wide:absolute tablet-wide:right-0 tablet-wide:bottom-0 desktop:absolute desktop:right-0 desktop:bottom-0 flex',
          footerClassName,
        )}>
        {renderCustomFooter ? (
          renderCustomFooter()
        ) : (
          <>
            <div className="flex flex-1 gap-4">
              {onCopySubmissionLink ? (
                <IconButton
                  icon="CopyLink"
                  iconColor="black"
                  aria-label={t('ariaCopyLinkLabel') ?? undefined}
                  onClick={onCopySubmissionLink}
                />
              ) : null}
              {onDownloadSubmission ? (
                <IconButton
                  icon="Save"
                  iconColor="black"
                  aria-label={t('ariaDownloadSubmissionLabel') ?? undefined}
                  onClick={onDownloadSubmission}
                />
              ) : null}
            </div>
            {onDeleteSubmission ? (
              <IconButton
                icon="Delete"
                iconColor="black"
                onClick={onDeleteSubmission}
                aria-label={t('ariaDeleteSubmissionLabel') ?? undefined}
              />
            ) : null}
          </>
        )}
      </div>
    </>
  );
};
