var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { sub as subDate, add as addDate, set as setDate, lastDayOfMonth, format, isSameDay, isPast, isToday, } from 'date-fns';
import { mergeClasses } from '../util/merge-classes';
import { IconButton } from '../buttons';
var DAYS_OF_WEEK_IDX = [0, 1, 2, 3, 4, 5, 6];
export var MonthView = function (_a) {
    var selectedDate = _a.selectedDate, getDayLabel = _a.getDayLabel, onClickDate = _a.onClickDate, _b = _a.disablePastDates, disablePastDates = _b === void 0 ? true : _b;
    var _c = React.useState(selectedDate !== null && selectedDate !== void 0 ? selectedDate : new Date()), visibleDate = _c[0], setVisibleDate = _c[1];
    var onClickPrev = function () {
        setVisibleDate(subDate(visibleDate, { months: 1 }));
    };
    var onClickNext = function () {
        setVisibleDate(addDate(visibleDate, { months: 1 }));
    };
    var daysToShow = React.useMemo(function () {
        var lastDate = lastDayOfMonth(visibleDate);
        var firstDayIdx = setDate(lastDate, { date: 1 }).getDay();
        return {
            firstDayIdx: firstDayIdx,
            dates: Array.from(Array(lastDate.getDate()).keys())
                .map(function (n) { return n + 1; })
                .map(function (date) {
                return setDate(lastDate, {
                    date: date,
                });
            }),
        };
    }, [visibleDate]);
    return (_jsxs("div", __assign({ className: "calendar-container" }, { children: [_jsxs("div", __assign({ className: " flex flex-row justify-between px-2 py-6 items-center" }, { children: [_jsx(IconButton, { "aria-label": "Previous month", icon: "BackCaret", onClick: onClickPrev }), _jsx("span", __assign({ className: "font-semibold" }, { children: format(visibleDate, 'MMMM yyyy').toUpperCase() })), _jsx(IconButton, { "aria-label": "Next month", icon: "ForwardCaret", onClick: onClickNext })] })), _jsxs("div", __assign({ className: "grid grid-cols-7" }, { children: [DAYS_OF_WEEK_IDX.map(function (idx) { return (_jsx("div", __assign({ className: "text-center text-paragraph-sm" }, { children: getDayLabel(idx) }), getDayLabel(idx))); }), daysToShow.dates.map(function (date) {
                        var shouldDisableDate = disablePastDates && !isToday(date) && isPast(date);
                        var isSelected = selectedDate && isSameDay(date, selectedDate);
                        return (_jsx("button", __assign({ disabled: shouldDisableDate, className: mergeClasses('aspect-square flex justify-center items-center rounded-full text-paragraph-md', {
                                'bg-vibrantBlue text-white': isSelected,
                            }, {
                                'bg-highlighterYellow': isToday(date) && !isSelected,
                            }, {
                                'text-black-48': shouldDisableDate,
                            }), style: {
                                gridColumnStart: date.getDate() === 1 ? daysToShow.firstDayIdx + 1 : undefined,
                            }, onClick: function () { return onClickDate === null || onClickDate === void 0 ? void 0 : onClickDate(date); } }, { children: date.getDate() }), date.getDate()));
                    })] }))] })));
};
