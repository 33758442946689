/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type DeleteExperienceManagerMutationVariables = Types.Exact<{
  experienceId: Types.Scalars['ID']['input'];
  experienceManagerUserId: Types.Scalars['ID']['input'];
}>;


export type DeleteExperienceManagerMutation = { __typename: 'Mutation', deleteExperienceManager: { __typename: 'ExperienceManager', createdAt: string } };


export const DeleteExperienceManagerDocument = `
    mutation DeleteExperienceManager($experienceId: ID!, $experienceManagerUserId: ID!) {
  deleteExperienceManager(
    experienceId: $experienceId
    experienceManagerUserId: $experienceManagerUserId
  ) {
    createdAt
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    DeleteExperienceManager: build.mutation<DeleteExperienceManagerMutation, DeleteExperienceManagerMutationVariables>({
      query: (variables) => ({ document: DeleteExperienceManagerDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteExperienceManagerMutation } = injectedRtkApi;

