import { useState } from 'react';
import { useResetPasswordMutation } from '../../../data/auth';
import { isRequestExpiredError } from '../../../data/errors';
import { useTranslate } from '../../../util/i18n';
import { usePasswordResetIdParam } from '../use-validate-password-reset-id.hook';
import { ResetPasswordData, ResetPasswordFormUI, ServerErrors } from './reset-password-form-ui';

export interface ResetPasswordFormProps {
  onSubmitSuccess: () => void;
  redirectFromExpiredLink: () => void;
}

export const ResetPasswordForm = ({
  onSubmitSuccess,
  redirectFromExpiredLink,
}: ResetPasswordFormProps) => {
  const [serverErrors, setServerErrors] = useState<ServerErrors | undefined>();
  const { t } = useTranslate('pages.resetPassword.resetPasswordForm');
  // Casting passwordResetId to string as this component won't load if it's undefined
  const passwordResetId = usePasswordResetIdParam() as string;
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const handleGenericError = () => {
    setServerErrors({ general: t('generalError') as string });
  };

  const clearServerErrors = () => {
    setServerErrors(undefined);
  };

  const handleResetPassword = async ({ newPassword }: ResetPasswordData) => {
    try {
      await resetPassword({ passwordResetId, newPassword }).unwrap();
      onSubmitSuccess();
    } catch (e) {
      if (isRequestExpiredError(e)) {
        redirectFromExpiredLink();
      } else {
        handleGenericError();
      }
    }
  };

  return (
    <ResetPasswordFormUI
      onSubmit={handleResetPassword}
      loading={isLoading}
      serverErrors={serverErrors}
      clearServerErrors={clearServerErrors}
    />
  );
};
