import { Button, Modal, NotificationBanner, TextField } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { Trans } from 'react-i18next';
import { ChangeEvent, useState } from 'react';

interface DeleteAccountConfirmationProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const DeleteAccountConfirmation = ({
  show,
  onClose,
  onSubmit,
}: DeleteAccountConfirmationProps) => {
  const { t } = useTranslate('deleteAccountModal.confirmation');
  const [disableDelete, setDisableDelete] = useState(true);
  const confirmMessage = t('confirmMessage') ?? 'DELETE ACCOUNT';

  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[498px] desktop:w-[766px]"
      show={show}
      onClose={onClose}>
      <Modal.Header title={t('title') ?? undefined} />
      <Modal.Body>
        <NotificationBanner
          className="mb-6 desktop:mb-8"
          type="error"
          title=""
          body={<Trans>{t('warning')}</Trans>}
          showIcon={false}
        />
        <Trans i18nKey="body">{t('body')}</Trans>
        <TextField
          placeholder={t('placeholder') ?? undefined}
          className="mt-6"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value === confirmMessage) {
              setDisableDelete(false);
            } else {
              setDisableDelete(true);
            }
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          label={t('confirm')}
          onClick={onSubmit}
          buttonType={'DESTRUCTIVE'}
          disabled={disableDelete}
        />
        <Button label={t('cancel')} onClick={onClose} outlined={true} />
      </Modal.Footer>
    </Modal>
  );
};
