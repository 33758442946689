var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { set } from 'date-fns';
import { DateInput } from './date-input';
import { MonthView } from './month-view';
import { Label, Text } from '../typography';
var getDayLabel = function (idx) {
    return ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'][idx];
};
export var DatePicker = function (_a) {
    var date = _a.date, isDirty = _a.isDirty, onChange = _a.onChange, error = _a.error, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.disablePastDates, disablePastDates = _c === void 0 ? true : _c;
    var updateDateOnly = function (newDate) {
        if (!date) {
            date = newDate;
        }
        onChange(set(date, {
            year: newDate.getFullYear(),
            date: newDate.getDate(),
            month: newDate.getMonth(),
        }));
    };
    return (_jsxs(Popover, __assign({ className: "relative" }, { children: [_jsxs("div", { children: [_jsx(PopoverButton, __assign({ as: "div", role: "application", className: "w-full tablet-wide:w-76", disabled: disabled }, { children: _jsx(DateInput, { value: date, onChangeValue: updateDateOnly, isDirty: isDirty, name: "date", format: "EEEE, LLLL d yyyy", className: "w-full", rightIcon: "Calendar", error: error, disabled: disabled, placeholder: "Select date" }) })), error && (_jsx("div", __assign({ className: "mt-2 h-4" }, { children: _jsx(Text, __assign({ size: "xs", className: "text-vibrantRed" }, { children: error !== null && error !== void 0 ? error : '' })) })))] }), _jsxs(PopoverPanel, __assign({ className: "absolute bg-white top-15 p-4 border border-black-24 rounded-lg w-76 z-10", focus: true }, { children: [_jsx(Label, __assign({ size: "sm", className: "block mb-2" }, { children: 'Date'.toUpperCase() })), _jsx(DateInput, { value: date, onChangeValue: updateDateOnly, isDirty: isDirty, name: "date", format: "yyyy-MM-dd", error: error, placeholder: "YYYY-MM-DD" }), _jsx(MonthView, { selectedDate: date !== null && date !== void 0 ? date : undefined, getDayLabel: getDayLabel, onClickDate: updateDateOnly, disablePastDates: disablePastDates })] }))] })));
};
