var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Locked = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("circle", { className: "fill-offWhite", cx: "10", cy: "10", r: "10" }), _jsx("path", { d: "M13.1371 8.53577H6.86266C6.36762 8.53577 5.96631 8.94377 5.96631 9.44706V12.6366C5.96631 13.1399 6.36762 13.5479 6.86266 13.5479H13.1371C13.6322 13.5479 14.0335 13.1399 14.0335 12.6366V9.44706C14.0335 8.94377 13.6322 8.53577 13.1371 8.53577Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M7.75879 8.53625V6.71366C7.75879 6.10943 7.99488 5.52996 8.41513 5.1027C8.83538 4.67545 9.40536 4.43542 9.99968 4.43542C10.594 4.43542 11.164 4.67545 11.5842 5.1027C12.0045 5.52996 12.2406 6.10943 12.2406 6.71366V8.53625", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }))); };
