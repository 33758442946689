/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type MyUseCasesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyUseCasesQuery = { __typename: 'Query', myUseCases: Array<{ __typename: 'UseCase', id: string }> };


export const MyUseCasesDocument = `
    query MyUseCases {
  myUseCases {
    id
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    MyUseCases: build.query<MyUseCasesQuery, MyUseCasesQueryVariables | void>({
      query: (variables) => ({ document: MyUseCasesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useMyUseCasesQuery, useLazyMyUseCasesQuery } = injectedRtkApi;

