import { getConfig } from './config';

const config = getConfig();

export const EXPERIENCE_PATH = '/experience';

export const WORKSPACE_PATH = '/workspace';

const SUBMISSIONS_PATH = 'submissions';

export const AppRoutes = {
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/sign-up',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_DONE: '/forgot-password/done',
  RESET_PASSWORD: '/reset/:passwordResetId',
  RESET_PASSWORD_DONE: '/reset/done',
  MY_EXPERIENCES: '/experiences',
  CREATE_EXPERIENCE: '/new-experience',
  EXPERIENCE_DETAILS: `${EXPERIENCE_PATH}/:id`,
  USE_CASE_SELECTION: '/onboarding/use-cases',
  EDUCATOR_INFO: '/onboarding/educator-info',
  ACCOUNT_INFO: '/account-info',
  CHANGE_PASSWORD: '/change-password',
  LOGOUT: '/logout',
  PLANS: '/plans',
  WORKSPACE: '/workspace',
  NEW_WORKSPACE: `/${WORKSPACE_PATH}/:workspaceId`,
  JOIN_WORKSPACE: '/join-workspace/:invitationId',
  JOIN_EXPERIENCE: '/join-experience/:invitationId',
  SHARE_EXPERIENCE: '/share-experience/:experienceShareId',
  // Sub paths below (no leading slash /)
  SUB_PATHS: {
    EDIT: 'edit',
    MISSIONS: 'missions',
    BROADCASTS: 'broadcasts',
    BRANDING: 'branding',
    PARTICIPANTS: 'participants',
    START_END: 'start-end',
    FEED: 'feed',
    LEADERBOARD: 'leaderboard',
    SUBMISSIONS: SUBMISSIONS_PATH,
    MISSION_SUBMISSIONS: `${SUBMISSIONS_PATH}/mission`,
    TEAM_SUBMISSIONS: `${SUBMISSIONS_PATH}/team`,
    STATS: 'stats',
  },
  PUBLIC: {
    SUBMISSION: '/p',
  },
};

const MARKETING_SITE = config.MARKETING_SITE;

export const ExternalRoutes = {
  MARKETING_SITE,
  TERMS_OF_SERVICE: `${MARKETING_SITE}/terms-of-service`,
  PRIVACY_POLICY: `${MARKETING_SITE}/privacy`,
  TEMPLATE_LIBRARY: `${MARKETING_SITE}/experience-library`,
  SHOP: 'https://shop.goosechase.com/',
};
