import { Avatar, Headline, Modal } from '@goosechase/ui';

import { useTranslate } from 'util/i18n';
import { Nullable } from 'types/util';

interface LoadingModalProps {
  show: boolean;
  photoUrl: Nullable<string>;
}

export const LoadingModal = ({ show, photoUrl }: LoadingModalProps) => {
  const { t } = useTranslate('pages.joinExperience.loadingModal');

  return (
    <Modal className="w-[calc(100vw-16px)] tablet-narrow:w-[592px]" show={show} onClose={() => {}}>
      <Modal.Body className="p-7">
        <div className="flex flex-col gap-6 items-center justify-center">
          <Avatar
            alt="Experience photo"
            squared
            placeholder="experience"
            src={photoUrl ?? undefined}
            size="xl2"
          />
          <Headline type="primary" size="sm" className="text-center">
            {t('title')}
          </Headline>
        </div>
      </Modal.Body>
    </Modal>
  );
};
