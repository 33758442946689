import { ReactComponent as DesertIslandGoose } from 'media/desert-island-goose.svg';
import { Card } from 'components/card.component';
import { EmptyState } from 'components/empty-state';
import { useTranslate } from 'util/i18n';

export const SubmissionsEmptyState = () => {
  const { t } = useTranslate('submissionsEmptyState');

  return (
    <Card className="p-12">
      <EmptyState
        text={t('text')}
        subtext={t('subtext')}
        renderGraphic={() => <DesertIslandGoose width="275" />}
      />
    </Card>
  );
};
