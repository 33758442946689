import { Label, Avatar, Text, mergeClasses } from '@goosechase/ui';
import { useLoadMyUserQuery } from 'data/auth';
import {
  ExperienceManagersQuery,
  useExperienceManagersQuery,
  usePendingExperienceInvitationsQuery,
} from 'data/experiences';
import { Nullable } from 'types/util';
import { useTranslate } from 'util/i18n';
import { formatDistanceToNow } from 'date-fns';
import { CollaboratorDropdown } from './collaborator-dropdown.component';
import { ArrayElement } from '../../global';

type CollaboratorsListProps = {
  experienceId: string;
};

type CollaboratorProps = {
  experienceManagerUserId?: string;
  roleId?: string;
  photoUrl?: Nullable<string>;
  currentUserId?: string;
  lastSentAt?: string;
  invitationId?: string;
  email: string;
  experienceId: string;
};

const Collaborator = (props: CollaboratorProps) => {
  const { t } = useTranslate('experienceCard.inviteCollaboratorsModal');
  const isCurrentUser =
    typeof props.currentUserId === 'string' &&
    props.currentUserId === props.experienceManagerUserId;

  return (
    <div className="flex gap-3 items-center relative">
      <Avatar alt={props.email} src={props.photoUrl ?? undefined} placeholder="single" size="xs2" />
      <div
        className={mergeClasses('flex flex-col gap-1 flex-1 items-stretch', {
          'text-black-48': isCurrentUser,
        })}>
        <Text className="font-bold">{props.email}</Text>
        {props.roleId ? (
          <Text size="xs">
            {t(`roles.${props.roleId}`)} {isCurrentUser ? `${t('collaborators.you')}` : null}
          </Text>
        ) : null}
        {props.lastSentAt ? (
          <Text size="xs">
            {t('collaborators.pending')} ({t('collaborators.lastSent')}{' '}
            {formatDistanceToNow(new Date(props.lastSentAt), { addSuffix: true })})
          </Text>
        ) : null}
      </div>{' '}
      {!isCurrentUser && props.roleId !== 'creator' ? (
        <div className="self-start">
          <CollaboratorDropdown
            experienceId={props.experienceId}
            experienceManagerUserId={props.experienceManagerUserId}
            invitationId={props.invitationId}
            email={props.email}
          />
        </div>
      ) : null}
    </div>
  );
};

interface ExperienceManagerWithProfile
  extends ArrayElement<ExperienceManagersQuery['experienceManagers']> {
  user: ArrayElement<ExperienceManagersQuery['experienceManagers']>['user'] & {
    profile: NonNullable<
      ArrayElement<ExperienceManagersQuery['experienceManagers']>['user']['profile']
    >;
  };
}

const experienceManagerWithProfile = (
  experienceManager: ArrayElement<ExperienceManagersQuery['experienceManagers']>,
): experienceManager is ExperienceManagerWithProfile => experienceManager.user.profile !== null;

export const CollaboratorsList = ({ experienceId }: CollaboratorsListProps) => {
  const { t } = useTranslate('experienceCard.inviteCollaboratorsModal');
  const { data: experienceManagersData } = useExperienceManagersQuery({ experienceId });
  const { data: experienceInvitationsData } = usePendingExperienceInvitationsQuery({
    experienceId,
  });
  const { data: myUserData } = useLoadMyUserQuery();

  const collaborators = [
    ...(experienceManagersData?.experienceManagers.filter(experienceManagerWithProfile).map(
      (experienceManager): CollaboratorProps => ({
        email: experienceManager.user.profile?.email.toLowerCase(),
        experienceManagerUserId: experienceManager.user.id,
        roleId: experienceManager.role.id,
        currentUserId: myUserData?.myUser.id,
        photoUrl: experienceManager.user.profile?.photoUrl,
        experienceId,
      }),
    ) ?? []),
    ...(experienceInvitationsData?.pendingExperienceInvitations.map(
      (experienceInvitation): CollaboratorProps => ({
        email: experienceInvitation.email.toLowerCase(),
        invitationId: experienceInvitation.id,
        lastSentAt: experienceInvitation.lastSentAt,
        experienceId,
      }),
    ) ?? []),
  ].sort((a, b) => a.email.localeCompare(b.email));

  return (
    <div className="flex flex-col gap-3">
      <Label className="uppercase" size="lg">
        {t('collaborators.label')}
      </Label>
      <div className="flex flex-col gap-3 max-h-[232px] overflow-auto pr-3">
        {collaborators.map((collaborator) => {
          return (
            <Collaborator
              key={collaborator.email}
              currentUserId={collaborator.currentUserId}
              email={collaborator.email}
              experienceManagerUserId={collaborator.experienceManagerUserId}
              roleId={collaborator.roleId}
              photoUrl={collaborator.photoUrl}
              invitationId={collaborator.invitationId}
              lastSentAt={collaborator.lastSentAt}
              experienceId={collaborator.experienceId}
            />
          );
        })}
      </div>
    </div>
  );
};
