type AppEnv = 'development' | 'production' | 'staging' | 'ci';
interface AppConfig {
  API_BASE_URL: string;
  GOOGLE_MAPS_API_KEY: string;
  CHARGEBEE_SITE: string;
  CHARGEBEE_PUBLISHABLE_KEY: string;
  SENTRY_DSN: string;
  APP_ENV: 'development' | 'production' | 'staging' | 'ci';
  PUBLIC_SUBMISSION_BASE_URL: string;
  SEGMENT_WRITE_KEY: string;
  MARKETING_SITE: string;
  COMMUNICATIONS_DISABLED: boolean;
  LAUNCH_DARKLY_CLIENT_ID: string;
}

const isAppEnv = (val: string): val is AppEnv =>
  ['development', 'production', 'staging', 'ci'].includes(val);

const validateAppEnv = (val: string) => {
  if (isAppEnv(val)) {
    return val;
  }
  throw new Error('Invalid app environment');
};

const requireVar = (name: string) => {
  if (!window.goosechaseConfig) {
    throw new Error('Missing manifest.js');
  }
  const value = window.goosechaseConfig[name];
  if (typeof value === 'undefined') {
    throw new Error(`Missing value for required config variable: ${name}`);
  }
  return value;
};

export const getConfig = (): AppConfig => ({
  API_BASE_URL: requireVar('API_BASE_URL'),
  GOOGLE_MAPS_API_KEY: requireVar('GOOGLE_MAPS_API_KEY'),
  CHARGEBEE_SITE: requireVar('CHARGEBEE_SITE'),
  CHARGEBEE_PUBLISHABLE_KEY: requireVar('CHARGEBEE_PUBLISHABLE_KEY'),
  SENTRY_DSN: requireVar('SENTRY_DSN'),
  APP_ENV: validateAppEnv(requireVar('APP_ENV')),
  PUBLIC_SUBMISSION_BASE_URL: requireVar('PUBLIC_SUBMISSION_BASE_URL'),
  SEGMENT_WRITE_KEY: requireVar('SEGMENT_WRITE_KEY'),
  MARKETING_SITE: requireVar('MARKETING_SITE'),
  COMMUNICATIONS_DISABLED: requireVar('COMMUNICATIONS_DISABLED') === 'true',
  LAUNCH_DARKLY_CLIENT_ID: requireVar('LAUNCH_DARKLY_CLIENT_ID'),
});
