import { format } from 'date-fns';
import { Avatar, mergeClasses, Text } from '@goosechase/ui';

import { AppRoutes, EXPERIENCE_PATH } from '../../constants/routes';
import { TeamMode } from '../../data/models';
import { Link } from '../link.component';
import { useTranslate } from '../../util/i18n';

interface SubmissionTeamHeaderUIProps {
  className?: string;
  teamImageUrl?: string;
  teamMode: TeamMode;
  teamName: string;
  submissionDate: Date;
}

const SubmissionTeamHeaderUI = ({
  className,
  teamImageUrl,
  teamMode,
  teamName,
  submissionDate,
}: SubmissionTeamHeaderProps) => {
  const { t: commonT } = useTranslate('common');
  const { t: submissionCardT } = useTranslate('submissionCard');

  return (
    <div className={mergeClasses('flex flex-row gap-3 text-black', className)}>
      <Avatar
        alt={submissionCardT('teamPicAlt', { teamName }) ?? undefined}
        src={teamImageUrl}
        placeholder={teamMode === TeamMode.Solo ? 'single' : 'team-duo'}
        className="self-center shrink-0"
        size="xs4"
      />
      <div className="flex flex-col truncate">
        <Text className="font-bold truncate" size="sm">
          {teamName}
        </Text>
        <Text className="truncate text-black" size="xs">
          {format(submissionDate, commonT('dateFormat'))}
        </Text>
      </div>
    </div>
  );
};

export interface SubmissionTeamHeaderProps extends SubmissionTeamHeaderUIProps {
  experienceId?: string;
  teamId?: string;
}

export const SubmissionTeamHeader = ({
  experienceId,
  teamId,
  teamImageUrl,
  teamMode,
  teamName,
  submissionDate,
}: SubmissionTeamHeaderProps) => {
  if (experienceId && teamId) {
    return (
      <Link
        className="outline-black"
        useRouter
        href={`${EXPERIENCE_PATH}/${experienceId}/${AppRoutes.SUB_PATHS.TEAM_SUBMISSIONS}/${teamId}`}>
        <SubmissionTeamHeaderUI
          className="text-vibrantBlue"
          teamImageUrl={teamImageUrl}
          teamMode={teamMode}
          teamName={teamName}
          submissionDate={submissionDate}
        />
      </Link>
    );
  }

  return (
    <SubmissionTeamHeaderUI
      teamImageUrl={teamImageUrl}
      teamMode={teamMode}
      teamName={teamName}
      submissionDate={submissionDate}
    />
  );
};
