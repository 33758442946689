import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { isBefore } from 'date-fns';

import {
  ScheduleCohortParams,
  ExperienceTriggerTiming,
  TriggerTimeUnit,
  TRIGGER_TIME_UNITS,
  StartCohortParams,
  UpdateCohortEndParams,
} from '../../data/models';
import { calculateRelativeTriggerTime, mergeDateAndTime } from 'util/time-util';
import { useTranslate } from 'util/i18n';
import { Nullable } from 'types/util';

export const buildScheduleParams = (cohortId: string, data: StartEndFormInputData) => {
  const params: ScheduleCohortParams = {
    cohortId,
    triggerStartAt: new Date().toISOString(),
    endTiming: data.endTiming as ExperienceTriggerTiming,
  };

  if (data.startTiming === 'ABSOLUTE') {
    const triggerStart = mergeDateAndTime(data.startDate, data.startTime);
    params.triggerStartAt = triggerStart.toISOString();
  }

  if (data.endTiming === 'ABSOLUTE') {
    const triggerEnd = mergeDateAndTime(data.endDate, data.endTime);
    params.triggerEndAt = triggerEnd.toISOString();
  } else {
    params.relativeDuration = data.relativeDuration;
    params.relativeUnit = data.relativeUnit;
  }

  return params;
};

export const buildBaseParams = (cohortId: string, data: StartEndFormInputData) => {
  const params: StartCohortParams | UpdateCohortEndParams = {
    cohortId,
    endTiming: data.endTiming as ExperienceTriggerTiming,
  };

  if (data.endTiming === 'ABSOLUTE') {
    const triggerEnd = mergeDateAndTime(data.endDate, data.endTime);
    params.triggerEndAt = triggerEnd.toISOString();
  } else {
    params.relativeDuration = data.relativeDuration;
    params.relativeUnit = data.relativeUnit;
  }

  return params;
};

export interface StartEndFormInputData {
  startTiming: string;
  startDate: Date;
  startTime: Date;
  endTiming: string;
  endDate: Date;
  endTime: Date;
  relativeDuration: number;
  relativeUnit: TriggerTimeUnit;
}

export type StartEndFormOutputData = StartEndFormInputData;

const checkIfDateIsBefore = (date1: Date, date2: Date) => isBefore(date1, date2);

export interface UseStartEndFormProps {
  defaultValues: Partial<StartEndFormInputData>;
  editingLive: boolean;
  startDate: Nullable<Date>;
  overlapStart?: boolean;
  overlapEnd?: boolean;
}

export const useStartEndForm = ({
  defaultValues,
  editingLive,
  startDate,
  overlapStart = false,
  overlapEnd = false,
}: UseStartEndFormProps) => {
  const { t } = useTranslate('pages.startEnd.form');

  const startNowSchema = z.object({
    startTiming: z.literal('NOW'),
  });

  const startAbsoluteSchema = z.object({
    startTiming: z.literal('ABSOLUTE'),
    startDate: z.date(),
    startTime: z.date(),
  });

  const startSchema = z
    .discriminatedUnion('startTiming', [startNowSchema, startAbsoluteSchema])
    .superRefine((data, ctx) => {
      const startDateTime =
        editingLive && startDate
          ? startDate
          : data.startTiming === 'ABSOLUTE'
          ? mergeDateAndTime(data.startDate, data.startTime)
          : new Date();

      if (data.startTiming === 'ABSOLUTE') {
        const now = new Date();
        if (!editingLive && checkIfDateIsBefore(startDateTime, now)) {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_date,
            message: t('startDate.errors.inFuture') ?? 'Must be in the future',
            path: ['startDate'],
          });
        } else if (!editingLive && startDateTime <= now) {
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_date,
            message: t('startDate.errors.inFuture') ?? 'Must be in the future',
            path: ['startTime'],
          });
        }
      }
    });

  const endRelativeSchema = z.object({
    endTiming: z.literal('RELATIVE'),
    relativeDuration: z.number({ invalid_type_error: 'Required' }).int().min(1),
    relativeUnit: z.enum(TRIGGER_TIME_UNITS),
  });

  const endAbsoluteSchema = z.object({
    endTiming: z.literal('ABSOLUTE'),
    endDate: z.date(),
    endTime: z.date(),
  });
  const endSchema = z.discriminatedUnion('endTiming', [endRelativeSchema, endAbsoluteSchema]);

  // eslint-disable-next-line complexity
  const formSchema = startSchema.and(endSchema).superRefine((data, ctx) => {
    const startDateTime =
      editingLive && startDate
        ? startDate
        : data.startTiming === 'ABSOLUTE'
        ? mergeDateAndTime(data.startDate, data.startTime)
        : new Date();

    if (data.startTiming === 'ABSOLUTE') {
      const now = new Date();
      if (!editingLive && checkIfDateIsBefore(startDateTime, now)) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message: t('startDate.errors.inFuture') ?? 'Must be in the future',
          path: ['startDate'],
        });
      } else if (!editingLive && startDateTime <= now) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message: t('startDate.errors.inFuture') ?? 'Must be in the future',
          path: ['startTime'],
        });
      }

      if (overlapStart) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message:
            t('startDate.errors.freeLiveExperienceLimit') ??
            "You've reached the free live Experience limit",
          path: ['startDate'],
        });
      }
    } else if (overlapStart) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        message:
          t('startDate.errors.freeLiveExperienceLimit') ??
          "You've reached the free live Experience limit",
        path: ['startTiming'],
      });
    }

    if (data.endTiming === 'RELATIVE') {
      const endDateTime = calculateRelativeTriggerTime(
        startDateTime,
        data.relativeUnit,
        data.relativeDuration,
      );

      if (endDateTime < new Date()) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message: t('endDate.errors.inFuture') ?? 'Must be in the future',
          path: ['relativeDuration'],
        });
      }

      if (!overlapStart && overlapEnd) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message:
            t('endDate.errors.freeLiveExperienceLimit') ??
            "You've reached the free live Experience limit",
          path: ['relativeDuration'],
        });
      }
    }

    if (data.endTiming === 'ABSOLUTE') {
      const endDateTime = mergeDateAndTime(data.endDate, data.endTime);

      if (checkIfDateIsBefore(endDateTime, startDateTime)) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message: t('endDate.errors.afterStart') ?? 'Must end after the start date',
          path: ['endDate'],
        });
      } else if (endDateTime <= startDateTime) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message: t('endTime.errors.afterStart') ?? 'Must end after the start time',
          path: ['endTime'],
        });
      }

      if (!overlapStart && overlapEnd) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message:
            t('endDate.errors.freeLiveExperienceLimit') ??
            "You've reached the free live Experience limit",
          path: ['endDate'],
        });
      }
    }
  });

  return useForm<StartEndFormInputData, unknown, StartEndFormOutputData>({
    mode: 'all',
    defaultValues,
    resolver: zodResolver(formSchema),
  });
};
