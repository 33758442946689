import { Avatar, Button, Headline, mergeClasses } from '@goosechase/ui';
import { Breadcrumbs } from 'components/breadcrumbs.component';
import { Card } from 'components/card.component';
import { ErrorState } from 'components/error-state';
import { LoadingSpinner } from 'components/loading-spinner';
import { ManageSubscriptionRedirect } from 'components/manage-subscription-redirect.component';
import { PlanBadge } from 'components/plans/plan-badge.component';
import { TabsRow } from 'components/tabs-row';
import { AppRoutes } from 'constants/routes';
import { useWorkspaceQuery } from 'data/workspaces';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router';
import { useTranslate } from 'util/i18n';
import { WorkspaceScope } from '../../data/models';
import { Members } from './members.component';
import { Settings } from './settings.component';

const WORKSPACE_TABS = ['members', 'settings'];

const allowWorkspaceInvites = (maxWorkspaceMembers: number | null): boolean => {
  if (maxWorkspaceMembers === null) {
    // null means there is no limit
    return true;
  }
  return maxWorkspaceMembers > 1;
};

export const NewWorkspacePage = () => {
  const { t } = useTranslate('pages.workspace');
  const { workspaceId } = useParams();
  const { data, isFetching, isError } = useWorkspaceQuery(
    { workspaceId: workspaceId ?? '' },
    { skip: !workspaceId },
  );
  const [selectedTab, setSelectedTab] = useState(0);

  if (isFetching) {
    return (
      <Card className="flex p-8 self-start mx-auto my-8">
        <LoadingSpinner />
      </Card>
    );
  }

  if (isError || !data?.workspace) {
    return (
      <Card className="flex  p-8 self-start mx-auto">
        <ErrorState />
      </Card>
    );
  }

  const workspace = data.workspace;

  // Non-organizational workspaces should not see this page
  if (workspace.scope !== WorkspaceScope.Organizational) {
    return <Navigate to={AppRoutes.ACCOUNT_INFO} />;
  }

  const tabs = WORKSPACE_TABS.map((tab) => ({ name: tab, label: t(`tabs.${tab}.label`) }));

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col p-4 tablet-narrow:p-6 w-full desktop:max-w-[1080px] gap-6">
        <Breadcrumbs
          activeBreadcrumbLabel={t('title')}
          previousBreadcrumbs={[{ href: AppRoutes.ACCOUNT_INFO, label: t('account') }]}
        />
        <div className="flex flex-col items-start gap-4 tablet-wide:flex-row tablet-wide:items-center flex-wrap">
          <div className="flex gap-4 items-center flex-wrap">
            <Avatar
              placeholder="workspace"
              alt={workspace?.displayName ?? ''}
              size="xs"
              src={workspace.photoUrl ?? undefined}
              className={mergeClasses({ 'bg-black-12 border border-white': !workspace.photoUrl })}
            />
            <Headline type="primary" size="sm" className="leading-10">
              {workspace.displayName}
            </Headline>
            <div className="h-10 tablet-narrow:h-6 self-center">
              <PlanBadge
                planId={workspace.subscription.plan.id}
                maxConcurrentExperiences={workspace.subscription.plan.maxConcurrentExperiences}
                maxParticipantsPerExperience={
                  workspace.subscription.plan.maxParticipantsPerExperience
                }
                maxTeamsPerExperience={workspace.subscription.plan.maxTeamsPerExperience}
              />
            </div>
          </div>
          {workspace.myWorkspaceMember?.roleId === 'owner' ? (
            <div className="ml-0 tablet-wide:ml-auto">
              <ManageSubscriptionRedirect>
                <Button label={t('manageSubscription')} outlined className="!bg-transparent" />
              </ManageSubscriptionRedirect>
            </div>
          ) : null}
        </div>
        <Card>
          <div className="px-6 py-4">
            <TabsRow tabs={tabs} selectedTab={selectedTab} onSelectTab={setSelectedTab} />
          </div>
          <div className="w-full h-px bg-black-24" />
          <div className="p-6">
            {WORKSPACE_TABS[selectedTab] === 'settings' ? (
              <Settings
                workspaceId={workspace.id}
                displayName={workspace.displayName}
                photoUrl={workspace.photoUrl}
                currentWorkspaceMember={data.workspace.myWorkspaceMember}
              />
            ) : null}
            {WORKSPACE_TABS[selectedTab] === 'members' ? (
              <Members
                workspaceId={workspace.id}
                displayName={workspace.displayName}
                currentWorkspaceMember={data.workspace.myWorkspaceMember}
                allowInvites={allowWorkspaceInvites(
                  data.workspace.subscription.plan.maxWorkspaceMembers,
                )}
              />
            ) : null}
          </div>
        </Card>
      </div>
    </div>
  );
};
