import { Dialog, DialogPanel } from '@headlessui/react';
interface SidePanelProps {
  visible: boolean;
  onClickBackdrop: () => void;
  children: React.ReactNode;
  side?: 'left' | 'right';
}

export const SidePanel = ({ visible, onClickBackdrop, children }: SidePanelProps) => {
  return (
    <Dialog open={visible} onClose={onClickBackdrop} className="fixed z-20">
      <div className="fixed inset-0" />

      <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
        <DialogPanel
          transition
          className="pointer-events-auto w-screen tablet-narrow:w-[491px] transform transition duration-150 ease-in-out data-[closed]:translate-x-full">
          <div className="flex h-full flex-col bg-white shadow-xl overflow-y-auto">
            <div className="relative flex-1">{children}</div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};
