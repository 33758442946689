/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type MyExperiencesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyExperiencesQuery = { __typename: 'Query', myManagedExperiences: Array<{ __typename: 'Experience', id: string, displayName: string, description: string, shareLink: string | null, status: Types.CohortState, startDate: string | null, endDate: string | null, createdAt: string, assets: Array<{ __typename: 'ExperienceAsset', id: string, url: string, role: Types.ExperienceAssetRole }>, addons: Array<{ __typename: 'Addon', id: string, chargebeeSku: string | null, maxTeamsPerExperience: number | null }>, myExperienceManager: { __typename: 'ExperienceManager', role: { __typename: 'ExperienceManagerRole', id: string } } | null, workspace: { __typename: 'Workspace', id: string, subscription: { __typename: 'Subscription', plan: { __typename: 'WorkspacePlan', id: string } } } }> };


export const MyExperiencesDocument = `
    query MyExperiences {
  myManagedExperiences {
    id
    displayName
    description
    shareLink
    status
    assets {
      id
      url
      role
    }
    startDate
    endDate
    createdAt
    addons {
      id
      chargebeeSku
      maxTeamsPerExperience
    }
    myExperienceManager {
      role {
        id
      }
    }
    workspace {
      id
      subscription {
        plan {
          id
        }
      }
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    MyExperiences: build.query<MyExperiencesQuery, MyExperiencesQueryVariables | void>({
      query: (variables) => ({ document: MyExperiencesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useMyExperiencesQuery, useLazyMyExperiencesQuery } = injectedRtkApi;

