import { Button, Modal } from '@goosechase/ui';

import { Trans } from 'react-i18next';

interface DestructiveConfirmationModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  headerText: string;
  bodyText: string;
  confirmText: string;
  cancelText: string;
}

export const DestructiveConfirmationModal = ({
  show,
  onClose,
  onSubmit,
  headerText,
  bodyText,
  cancelText,
  confirmText,
}: DestructiveConfirmationModalProps) => {
  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[498px] desktop:w-[766px]"
      show={show}
      onClose={onClose}>
      <Modal.Header title={headerText} />
      <Modal.Body>
        <Trans>{bodyText}</Trans>
      </Modal.Body>
      <Modal.Footer>
        <Button label={confirmText} onClick={onSubmit} buttonType={'DESTRUCTIVE'} />
        <Button label={cancelText} onClick={onClose} outlined />
      </Modal.Footer>
    </Modal>
  );
};
