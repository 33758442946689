var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { getDataTestIDProp } from '../util/test-id';
import { IconsMap } from './icons';
// See https://tailwindcss.com/docs/content-configuration#dynamic-class-names
var colorVariants = {
    black: 'fill-black stroke-black',
    'black-64': 'fill-black-64 stroke-black-64',
    'black-48': 'fill-black-48 stroke-black-48',
    'black-32': 'fill-black-32 stroke-black-32',
    'black-24': 'fill-black-24 stroke-black-24',
    'black-12': 'fill-black-12 stroke-black-12',
    'black-4': 'fill-black-4 stroke-black-4',
    orange: 'fill-orange stroke-orange',
    'orange-50': 'fill-orange-50 stroke-orange-50',
    'orange-10': 'fill-orange-10 stroke-orange-10',
    offWhite: 'fill-offWhite stroke-offWhite',
    white: 'fill-white stroke-white',
    lightYellow: 'fill-lightYellow stroke-lightYellow',
    yellowOrange: 'fill-yellowOrange stroke-yellowOrange',
    'yellowOrange-50': 'fill-yellowOrange-50 stroke-yellowOrange-50',
    'yellowOrange-10': 'fill-yellowOrange-10 stroke-yellowOrange-10',
    lilac: 'fill-lilac stroke-lilac',
    'lilac-dark': 'fill-lilac-dark stroke-lilac-dark',
    'lilac-50': 'fill-lilac-50 stroke-lilac-50',
    limeGreen: 'fill-limeGreen stroke-limeGreen',
    cyan: 'fill-cyan stroke-cyan',
    'cyan-50': 'fill-cyan-50 stroke-cyan-50',
    'cyan-20': 'fill-cyan-20 stroke-cyan-20',
    paleGreen: 'fill-paleGreen stroke-paleGreen',
    vibrantBlue: 'fill-vibrantBlue stroke-vibrantBlue',
    'vibrantBlue-50': 'fill-vibrantBlue-50 stroke-vibrantBlue-50',
    'vibrantBlue-10': 'fill-vibrantBlue-10 stroke-vibrantBlue-10',
    highlighterYellow: 'fill-highlighterYellow stroke-highlighterYellow',
    vibrantRed: 'fill-vibrantRed stroke-vibrantRed',
    'vibrantRed-80': 'fill-vibrantRed-80 stroke-vibrantRed-80',
    'vibrantRed-50': 'fill-vibrantRed-50 stroke-vibrantRed-50',
    'vibrantRed-10': 'fill-vibrantRed-10 stroke-vibrantRed-10',
    vibrantGreen: 'fill-vibrantGreen stroke-vibrantGreen',
    notificationGreen: 'fill-notificationGreen stroke-notificationGreen',
    darkGreen: 'fill-darkGreen stroke-darkGreen',
    deepBlue: 'fill-deepBlue stroke-deepBlue',
    lightBlue: 'fill-lightBlue stroke-lightBlue',
};
export var Icon = function (_a) {
    var name = _a.name, className = _a.className, _b = _a.color, color = _b === void 0 ? 'black' : _b, _c = _a.size, size = _c === void 0 ? 24 : _c, testID = _a.testID;
    var _d = IconsMap[name], SVGIcon = _d.component, viewBox = _d.viewBox;
    return (_jsx("svg", __assign({ 
        // strokeWidth set to 0 fixes an issue where strokes appear blurred.
        // This does not appear to have any negative results.
        className: classNames('stroke-0', colorVariants[color], className), height: size, width: size, viewBox: viewBox }, getDataTestIDProp(testID), { children: _jsx(SVGIcon, {}) })));
};
