import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useTranslate } from '../../util/i18n';
import { GPS_LOCATION_FORM_DEFAULTS } from './constants';

export interface GpsLocationFormData {
  locationName: string;
  latitude: string;
  longitude: string;
  radius: number;
  zoom?: number;
}

export const refineLatOrLngValue = (variant: 'lat' | 'lng') => (val: string) => {
  const parsed = parseFloat(val);
  if (isNaN(parsed)) {
    return false;
  }

  if (variant === 'lat') {
    return parsed >= -90 && parsed <= 90;
  } else {
    return parsed >= -180 && parsed <= 180;
  }
};

const transformLatOrLngValue = (val: string) => parseFloat(val).toFixed(6);

export interface UseGpsLocationFormProps {
  defaultValues: Partial<GpsLocationFormData>;
}

export const useGpsLocationForm = ({ defaultValues }: UseGpsLocationFormProps) => {
  const { t } = useTranslate('gpsLocationForm');

  const schema = z.object({
    locationName: z.string(),
    latitude: z
      .string()
      .min(1, { message: t('latitude.errors.required') ?? undefined })
      .refine(refineLatOrLngValue('lat'), { message: t('latitude.errors.number') ?? undefined })
      .transform(transformLatOrLngValue),
    longitude: z
      .string()
      .min(1, { message: t('longitude.errors.required') ?? undefined })
      .refine(refineLatOrLngValue('lng'), { message: t('longitude.errors.number') ?? undefined })
      .transform(transformLatOrLngValue),
    radius: z.number().int(),
  });

  return useForm<GpsLocationFormData>({
    mode: 'onTouched',
    defaultValues: {
      locationName: defaultValues.locationName ?? GPS_LOCATION_FORM_DEFAULTS.LOCATION_NAME,
      latitude: defaultValues.latitude ?? GPS_LOCATION_FORM_DEFAULTS.LATITUDE,
      longitude: defaultValues.longitude ?? GPS_LOCATION_FORM_DEFAULTS.LONGITUDE,
      radius: defaultValues.radius ?? GPS_LOCATION_FORM_DEFAULTS.RADIUS,
    },
    resolver: zodResolver(schema),
  });
};
