import { useTranslate } from 'util/i18n';
import { Page } from 'components/page.component';
import { getExperienceSubPageTitleOptions } from '../utils';
import { PageHeadline } from '../../components/page-headline.component';
import { StartEndForm } from './start-end-form';
import { useExperienceInfo } from '../../hooks/use-experience-info.hook';
import { Trans } from 'react-i18next';
import { systemTimezoneAbbreviation } from '../../util/time-util';

export const StartEndPage = () => {
  const { t } = useTranslate('pages.startEnd');
  const { data } = useExperienceInfo();
  const displayName = data?.experience?.displayName;

  return (
    <Page title={t('meta.title', getExperienceSubPageTitleOptions(displayName))}>
      <div className="flex flex-col pb-6">
        <div className="flex flex-col tablet-narrow:flex-row justify-between tablet-narrow:items-end mb-2">
          <PageHeadline>{t('title')}</PageHeadline>
          <div className="text-paragraph-md text-black-64 mt-2 tablet-narrow:mt-0">
            <Trans>{t('form.timezoneWarning', { timezone: systemTimezoneAbbreviation })}</Trans>
          </div>
        </div>
        <StartEndForm />
      </div>
    </Page>
  );
};
