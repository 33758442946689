import { Button, Dropdown, Text, TextAreaField, TextButton } from '@goosechase/ui';
import { Controller } from 'react-hook-form';
import { useTranslate } from 'util/i18n';
import {
  useTeamBonusForm,
  type TeamBonusFormOutputData,
  TEAM_BONUS_FORM_DEFAULT_VALUES,
} from './use-team-bonus-form';
import { NumberInput } from 'components/number-input';
import { displayToast } from 'services/toast';
import { Trans } from 'react-i18next';

export interface TeamBonusFormProps {
  addTeamBonus: (data: Extract<TeamBonusFormOutputData, { teamId: string }>) => Promise<void>;
  onSecondaryButtonClick?: (teamId: string) => void;
  secondaryButtonLabel: string;
  options: {
    id: string;
    title: string;
  }[];
}

export const TeamBonusForm = ({
  options,
  addTeamBonus,
  onSecondaryButtonClick,
  secondaryButtonLabel,
}: TeamBonusFormProps) => {
  const { formState, control, handleSubmit, reset, setValue, watch } = useTeamBonusForm();
  const { t } = useTranslate('teamBonusForm');

  const teamId = watch('teamId');
  const selectedOption = options.find((option) => option.id === teamId);
  const error = Object.entries(formState.errors)[0];

  const onSubmit = async (params: Extract<TeamBonusFormOutputData, { teamId: string }>) => {
    try {
      await addTeamBonus(params);
      reset(TEAM_BONUS_FORM_DEFAULT_VALUES);
      displayToast({
        id: params.teamId,
        title: t(`toasts.addSuccess.title`),
        type: 'success',
        body: (
          <Trans
            t={t}
            i18nKey="toasts.addSuccess.body"
            values={{
              value: params.value,
              teamName: selectedOption?.title,
            }}
          />
        ),
      });
    } catch {
      displayToast({
        id: params.teamId,
        title: t(`toasts.addError.title`),
        type: 'error',
        body: t(`toasts.addError.body`, { value: params.value }),
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
      {error?.[1].message ? (
        <Text size="xs" className="text-vibrantRed">
          {error[1].message}
        </Text>
      ) : null}
      <div className="flex gap-2">
        <Controller
          control={control}
          name="value"
          render={({ field: { value } }) => (
            <Dropdown
              label={selectedOption?.title ?? t('teamId.placeholder')}
              onSelect={(selectedItemId: string) => setValue('teamId', selectedItemId)}
              options={options}
              selectedOptionId={value}
              containerClassName="flex-1 max-w-[168px]"
              buttonClassName="w-full"
            />
          )}
        />
        <div className="flex items-center gap-2 w-[124px]">
          <Text>{t('by')}</Text>
          <Controller
            control={control}
            name="value"
            render={({ field: { onChange, onBlur, name, value }, fieldState }) => (
              <NumberInput
                label=""
                placeholder={t('value.placeholder') ?? undefined}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                error={Boolean(fieldState.error?.message)}
                hideSpinButtons
                value={value}
                className="w-full"
              />
            )}
          />
        </div>
      </div>
      <Controller
        control={control}
        name="note"
        render={({ field: { onChange, onBlur, name, value }, fieldState }) => (
          <TextAreaField
            name={name}
            placeholder={t('note.placeholder') ?? undefined}
            onChange={onChange}
            onBlur={onBlur}
            responseState={fieldState.error?.message ? 'error' : undefined}
            value={value}
          />
        )}
      />
      <div className="flex justify-between mt-2">
        <Button label={t('submit')} disabled={!formState.isValid} />
        {teamId && onSecondaryButtonClick ? (
          <TextButton
            type="button"
            label={secondaryButtonLabel}
            onClick={() => onSecondaryButtonClick(teamId)}
          />
        ) : null}
      </div>
    </form>
  );
};
