import { Button, Modal } from '@goosechase/ui';
import { useTranslate } from 'util/i18n';
import { Trans } from 'react-i18next';

interface StartConfirmationModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  startDateTime?: string;
  endDateTime: string;
  timeZone: string;
  totalTime?: string;
  scheduled: boolean;
  disableConfirm: boolean;
}

export const StartConfirmationModal = ({
  show,
  onClose,
  onSubmit,
  startDateTime = '',
  endDateTime,
  timeZone,
  totalTime = '',
  scheduled,
  disableConfirm,
}: StartConfirmationModalProps) => {
  const { t } = useTranslate(
    scheduled ? 'pages.startEnd.scheduleConfirmation' : 'pages.startEnd.startConfirmation',
  );

  return (
    <Modal
      className="w-[calc(100vw-16px)] tablet-narrow:w-[498px] desktop:w-[766px]"
      show={show}
      onClose={onClose}>
      <Modal.Header title={t('header') ?? undefined} />
      <Modal.Body>
        <Trans>{t('body', { startDateTime, endDateTime, timeZone, totalTime })}</Trans>
      </Modal.Body>
      <Modal.Footer className="flex flex-col tablet-narrow:flex-row">
        <Button label={t('confirm')} onClick={onSubmit} leftIcon="Play" disabled={disableConfirm} />
        <Button label={t('cancel')} onClick={onClose} outlined />
      </Modal.Footer>
    </Modal>
  );
};
