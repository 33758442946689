import { Headline, mergeClasses, PrimaryHeadlineProps } from '@goosechase/ui';

type PageHeadlineProps = Pick<PrimaryHeadlineProps, 'children'> & {
  className?: string;
};

export const PageHeadline = ({ children, className }: PageHeadlineProps) => (
  <Headline className={mergeClasses('text-ellipsis', className)} type="primary" size="sm">
    {children}
  </Headline>
);
