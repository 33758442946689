import {
  BroadcastSendOption,
  BroadcastTriggerTiming,
  TriggerTimeAnchor,
  TriggerTimeUnit,
} from '../../data/models';

export const MESSAGE_MAX_CHARS = 2000;

export const BASE_BROADCAST_DEFAULTS = {
  ID: undefined,
  MESSAGE: '',
  SEND_TO: 'ALL',
  TEAM_NAME: '',
  TIMING: 'NOW' as BroadcastTriggerTiming,
  TIME_UNIT: TriggerTimeUnit.Hours,
  ANCHOR: TriggerTimeAnchor.AfterStart,
  SEND_TIMING: BroadcastSendOption.Now,
};
