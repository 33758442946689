var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var Smartphone = function () { return (_jsxs("g", __assign({ fill: "none" }, { children: [_jsx("path", { d: "M16.3992 2.32178H7.60075C6.6289 2.32178 5.84106 3.1884 5.84106 4.25743V19.7427C5.84106 20.8117 6.6289 21.6783 7.60075 21.6783H16.3992C17.371 21.6783 18.1589 20.8117 18.1589 19.7427V4.25743C18.1589 3.1884 17.371 2.32178 16.3992 2.32178Z", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M9.80029 5.58643H14.1995", stroke: "inherit", strokeWidth: "1.5", strokeLinecap: "round" })] }))); };
