import { useExperienceListFilter } from '../../data/experiences';
import { MyPersonalExperiences } from './my-personal-experiences.component';
import { MySharedExperiences } from './my-shared-experiences.component';
import { MyOrganizationExperiences } from './my-organization-experiences.component';

export const Experiences = () => {
  const filter = useExperienceListFilter();

  if (filter.type === 'personal' && filter.value === 'shared') {
    return <MySharedExperiences />;
  }

  if (filter.type === 'organization') {
    return <MyOrganizationExperiences workspaceId={filter.id} />;
  }

  return <MyPersonalExperiences />;
};
