/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `yarn xm run graphql-codegen`
 * for this file to be re-created
 *
 * DO NOT IMPORT DIRECTLY FROM THIS FILE (outside of src/data)
 */

import * as Types from '../../../__generated__/graphql';

import { rootApi } from 'data/api';
module.hot?.accept();
export type LoadTeamQueryVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
}>;


export type LoadTeamQuery = { __typename: 'Query', team: { __typename: 'Team', bonuses: Array<{ __typename: 'TeamBonus', id: string, value: number, note: string | null, createdAt: string }> } | null };


export const LoadTeamDocument = `
    query LoadTeam($teamId: ID!) {
  team(id: $teamId) {
    bonuses {
      id
      value
      note
      createdAt
    }
  }
}
    `;

const injectedRtkApi = rootApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    LoadTeam: build.query<LoadTeamQuery, LoadTeamQueryVariables>({
      query: (variables) => ({ document: LoadTeamDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLoadTeamQuery, useLazyLoadTeamQuery } = injectedRtkApi;

